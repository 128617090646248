import { EOECustomerOrderDetails } from '../../../../../components/CustomerOrderDetails/EOECustomerOrderDetails';
import { EmployeeMyOrdersWrapper } from '../../../../../components/EmployeeMyOrdersWrapper/EmployeeMyOrdersWrapper.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findItemById, stripActions } from '../../../../../common/utils/stateUtils.js';
import { generatePath, useParams } from 'react-router-dom';
import { getOrderContactPerson, getOrdererSubscription } from '../../../../../common/utils/employeeUtils.js';
import { loadCompanyInfo, loadEmployeeCustomerOrders } from '../../../../../selfservice/actions/index.js';
import { myOrdersMsg, omaElisaForEmployeeMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const OwnOrderPath = () => {
  const dispatch = useDispatch();
  const { subscriptionId } = useParams<SubscriptionId>();
  const featureFlags = useStore<State>().getState().config.featureFlags;
  const { customerType, empCustomerOrders } = useSelector(
    (state: State) => ({
      customerType: state.selfservice?.companyInfo?.customerType,
      empCustomerOrders: stripActions(state.user?.authenticated?.employeeCustomerOrders),
    }),
    deepEqual
  );
  const empCustomerOrder = findItemById('customerOrderDisplayId', subscriptionId, empCustomerOrders);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.EG6F(myOrdersMsg), empCustomerOrder?.customerOrderDisplayId ?? '']);

  useEffect(() => {
    dispatch(loadCompanyInfo());
    dispatch(
      loadEmployeeCustomerOrders(subscriptionId, undefined, undefined, undefined, undefined, undefined, undefined)
    );
  }, [dispatch, featureFlags, subscriptionId]);

  return (
    <EmployeeMyOrdersWrapper>
      <EOECustomerOrderDetails
        buildDeliveryOrderHref={(cid: string, did: string) =>
          generatePath(paths.EMPLOYEE_OWN_ORDER_ITEM, { subscriptionId: cid, deliveryId: did })
        }
        ordererContact={getOrderContactPerson(
          empCustomerOrders?.additionalInfo,
          empCustomerOrder?.customerOrderDisplayId
        )}
        customerOrder={empCustomerOrder}
        deviceSubscriptions={getOrdererSubscription(
          empCustomerOrders?.additionalInfo,
          empCustomerOrder?.customerOrderDisplayId
        )}
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.EG6F(myOrdersMsg), path: paths.EMPLOYEE_OWN_ORDERS },
          { name: empCustomerOrder?.customerOrderDisplayId || '…' },
        ]}
        customerType={customerType}
        isEmployee={true}
      />
    </EmployeeMyOrdersWrapper>
  );
};
