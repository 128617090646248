import * as CL from '@design-system/component-library';
import {
  DEFAULT_DELIVERY_MODE,
  DeliveryModeType,
  useDeliveryValidation,
} from './DeviceCheckoutDeliveryDetailsUtils.js';
import { DeliveryType, OnlineOrder } from '../../generated/api/models.js';
import { DeviceCheckoutAddressConfirmation } from './DeviceCheckoutAddressConfirmation';
import { DeviceCheckoutDeliveryAddressSection } from './components/DeviceCheckoutDeliveryAddressSection.js';
import { DeviceCheckoutDeliveryMethodsSelectionList } from './components/DeviceCheckoutDeliveryMethodsSelectionList.js';
import { DeviceCheckoutOrderShipmentTypeSelection } from './components/DeviceCheckoutOrderShipmentTypeSelection.js';
import { Icon } from '../Icon/Icon';
import { POBoxWarningModal } from '../Modal/POBoxWarningModal.js';
import {
  VAK_PRODUCT_TYPE,
  getProductDeliveryTypesInCart,
  isEppDevicePresentInCart,
} from '../../common/utils/checkoutUtils.js';
import { cartContainsDirectDeliveryItems, isAnyCartItemBackordered } from '../DeviceCheckout/deviceCheckoutUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses';
import { isPOBoxAddress } from '../../common/utils/validationUtils.js';
import { nextMsg, submitOrderMsg, t } from '../../common/i18n/index.js';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type {
  DeliveryMode,
  DeviceCheckoutDeliveryDetailsBaseProps,
  DeviceCheckoutDeliveryDetailsType,
} from './DeviceCheckoutDeliveryDetailsUtils.js';
import type { State } from '../../selfservice/common/store.js';

import './DeviceCheckoutDeliveryDetails.scss';

export interface DeviceCheckoutDeliveryDetailsProps extends DeviceCheckoutDeliveryDetailsBaseProps {
  saveDeliveryDetailsAndNext: (value: DeviceCheckoutDeliveryDetailsType) => void;
  onSubmitOrder?: (deliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  deliveryAddressNeeded?: boolean;
  showConfirmAddress?: boolean;
  isDeviceChangeReturn?: boolean;
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
}

const isPickupPointRequired = (details: DeviceCheckoutDeliveryDetailsType): boolean => {
  return [
    DeliveryType.MATKAHUOLTO_LAHELLA_PAKETTI,
    DeliveryType.POSTI_SMARTPOST,
    DeliveryType.POSTI_SMARTPOST_EXPRESS,
  ].includes(details.deliveryMethodType);
};

export const DeviceCheckoutDeliveryDetails = (props: DeviceCheckoutDeliveryDetailsProps) => {
  const {
    saveDeliveryDetailsToState,
    deliveryDetails,
    onSubmitOrder,
    skipDeliveryMethods,
    isEmployee,
    showConfirmAddress,
    isDeviceChangeReturn,
    setRefCallback,
  } = props;
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [shipmentType, setShipmentType] = useState<OnlineOrder.ShipmentTypeEnum>(
    OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY
  );
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(DEFAULT_DELIVERY_MODE);
  const [showPOBoxWarningModal, setShowPOBoxWarningModal] = useState(false);

  const deviceCheckout = useSelector((state: State) => state.deviceCheckout, deepEqual);
  const isOrderInProgress = useSelector((state: State) => Boolean(state.selfservice?.onlineOrders?.saving), deepEqual);
  const onlineModels = useSelector((state: State) => state.selfservice?.onlineModels || undefined, deepEqual);
  const cartItems = useSelector((state: State) => state.deviceCheckout?.cartItems || [], deepEqual);
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo || undefined, deepEqual);

  const errors = deviceCheckout?.errors;
  const listOfDeliveryMethods = deviceCheckout?.deliveryMethods?.lisOfDeliveryMethods;
  const isDirectDelivery = cartContainsDirectDeliveryItems(cartItems, onlineModels);
  const showShipmentType = cartItems?.length > 1 && isAnyCartItemBackordered(cartItems, onlineModels);
  const companyName = companyInfo ? companyInfo.companyName : '';
  const eppDevicePresentInCart = isEppDevicePresentInCart(cartItems);
  const discountedBasketLevelPrices = companyInfo?.discountedBasketLevelPrices;

  const { validationErrors, validateBeforeSubmit } = useDeliveryValidation(
    selectedDeliveryMode,
    listOfDeliveryMethods,
    isEditingAddress,
    deliveryDetails.pickupPoint,
    skipDeliveryMethods,
    isPickupPointRequired(deliveryDetails)
  );

  useEffect(() => {
    if (isDirectDelivery && deliveryDetails.shipmentType !== OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY) {
      setShipmentType(OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY);
      saveDeliveryDetailsToState({
        ...deliveryDetails,
        shipmentType: OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY,
      });
    }
  }, [cartItems, onlineModels, isDirectDelivery, saveDeliveryDetailsToState, deliveryDetails]);

  const handleDeliveryModeSelected = useCallback(
    (deliveryMode: DeliveryMode) => {
      const methods = deliveryMode?.getDeliveryMethods(listOfDeliveryMethods, isEmployee || false);
      const selectedMethod = methods?.[0];
      if (methods && selectedMethod) {
        saveDeliveryDetailsToState({
          ...deliveryDetails,
          deliveryMethodDescription: selectedMethod.createDescription(),
          deliveryMethodType: selectedMethod.type,
          deliveryPrice: selectedMethod.price,
          pickupPoint:
            selectedMethod.deliveryModeType === DeliveryModeType.ADDRESS ? undefined : deliveryDetails.pickupPoint,
        });
      }
    },
    [deliveryDetails, isEmployee, listOfDeliveryMethods, saveDeliveryDetailsToState]
  );

  return (
    <div className="of-device-checkout-delivery-details">
      {showPOBoxWarningModal && <POBoxWarningModal setShowModal={setShowPOBoxWarningModal} />}
      {((!isDeviceChangeReturn && showShipmentType) || isDirectDelivery) && (
        <DeviceCheckoutOrderShipmentTypeSelection
          isEmployee={isEmployee}
          shipmentType={shipmentType}
          isDirectDelivery={isDirectDelivery}
          backOrdered={showShipmentType}
          onShipmentTypeSelection={(orderType: OnlineOrder.ShipmentTypeEnum) => {
            setShipmentType(orderType);
            props.saveDeliveryDetailsToState({
              ...deliveryDetails,
              shipmentType: orderType,
            });
          }}
        />
      )}
      <DeviceCheckoutDeliveryAddressSection
        deliveryDetails={deliveryDetails}
        deliveryAddressNeeded={props.deliveryAddressNeeded}
        saveDeliveryDetailsToState={props.saveDeliveryDetailsToState}
        companyName={companyName}
        setIsEditingAddress={setIsEditingAddress}
        errors={errors}
      />

      {showConfirmAddress && <DeviceCheckoutAddressConfirmation setRefCallback={setRefCallback} />}

      {!skipDeliveryMethods && (
        <DeviceCheckoutDeliveryMethodsSelectionList
          eppDevicePresentInCart={eppDevicePresentInCart}
          isEmployee={isEmployee}
          discountedBasketLevelPrices={discountedBasketLevelPrices}
          deliveryDetails={deliveryDetails}
          footerNote={props.footerNote}
          saveDeliveryDetailsToState={props.saveDeliveryDetailsToState}
          deliveryMode={selectedDeliveryMode}
          onDeliveryModeChosen={(deliveryModeSelection: DeliveryMode) => {
            setSelectedDeliveryMode(deliveryModeSelection);
            handleDeliveryModeSelected(deliveryModeSelection);
          }}
          listOfDeliveryMethods={listOfDeliveryMethods}
          isDirectDelivery={isDirectDelivery}
          hasVakProduct={getProductDeliveryTypesInCart(cartItems, onlineModels).includes(VAK_PRODUCT_TYPE)}
        />
      )}
      <CL.Button
        size="l"
        disabled={isOrderInProgress}
        className={onSubmitOrder ? 'of-confirm-button' : 'of-next-button'}
        onClick={() => {
          if (
            isPOBoxAddress(deliveryDetails.address?.postalCode) &&
            selectedDeliveryMode.type === DeliveryModeType.ADDRESS
          ) {
            setShowPOBoxWarningModal(true);
          } else if (validateBeforeSubmit()) {
            props.saveDeliveryDetailsAndNext(deliveryDetails);
            if (onSubmitOrder) {
              onSubmitOrder(deliveryDetails);
            }
          }
        }}
      >
        {onSubmitOrder ? t.RZU4(submitOrderMsg) : t.F0MY(nextMsg)}
      </CL.Button>
      {Object.entries(validationErrors).map(([key, errorMsg]) => (
        <div key={key} className="of-validation-error-messages">
          <div className={dsClass.INPUTERROR} style={{ marginLeft: dsClass.MARGIN_2 }}>
            <Icon icon="error" size="s" type="regular" className={dsClass.INPUTERROR} color="red-600" /> {errorMsg}
          </div>
        </div>
      ))}
    </div>
  );
};
