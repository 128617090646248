import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../../DialogWrapper';
import { FormProvider, useForm } from 'react-hook-form';
import { PhoneNumber } from '../../../common/react-hook-form/fields';
import { TextAreaComponent } from '../../../common/react-hook-form/components/TextAreaComponent.js';
import { cancelMsg, submitMsg, t, terminateSubscriptionMsg } from '../../../common/i18n';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatTimestampToUTCDDMMYYYY } from '../../../common/utils/dateUtils';
import { isCommitmentPeriodActive } from '../../../common/utils/subscriptionUtils';

export interface TerminateNettiLiteForm {
  msisdn: number;
  message?: string;
}

interface TerminateNettiLiteDialogProps {
  isSubmitting: boolean;
  onCloseDialog: () => void;
  fixedTermEndDate?: number;
  onTerminateNettiLiteSubscription: (values: TerminateNettiLiteForm) => void;
}

export const TerminateNettiLiteDialog = ({
  isSubmitting,
  onCloseDialog,
  fixedTermEndDate,
  onTerminateNettiLiteSubscription,
}: TerminateNettiLiteDialogProps) => {
  const methods = useForm<TerminateNettiLiteForm>();

  return (
    <DialogWrapper onCloseDialog={onCloseDialog} header={t.M1TW(terminateSubscriptionMsg)} closeable>
      <div className={`${dsClass.MARGIN_TOP_4} support-case-dialog`}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onTerminateNettiLiteSubscription)} noValidate>
            <div className="ds-margin-top--4">
              {t.KWE5(
                'You can terminate the subscription by sending a message to our customer service. Please leave your phone number and our customer service will contact you.'
              )}
            </div>
            {isCommitmentPeriodActive(fixedTermEndDate) && (
              <div className="ds-margin-top--2">
                {t.D259(
                  'Subscription is fixed term until {}. The subscription can only be terminated after the final invoice is issued, covering the remaining monthly charges.',
                  formatTimestampToUTCDDMMYYYY(fixedTermEndDate)!
                )}
              </div>
            )}
            <div className="ds-margin-top--4">
              <PhoneNumber name="msisdn" label={`${t.QIKB('Enter your phone number')} *`} required />
            </div>
            <div className="ds-margin-top--4">
              <TextAreaComponent
                label={t.WVHG('Leave a message to customer service')}
                name="message"
                disabled={false}
                maxLength={1024}
              />
            </div>
            <div className="of-modal__buttons">
              <CL.Button
                type="button"
                onClick={onCloseDialog}
                color="link"
                size="l"
                aria-busy={isSubmitting}
                disabled={isSubmitting}
              >
                {t.B2V1(cancelMsg)}
              </CL.Button>
              <CL.Button type="submit" size="l" loading={isSubmitting}>
                {t.FSWU(submitMsg)}
              </CL.Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </DialogWrapper>
  );
};
