import * as CL from '@design-system/component-library';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy';

const CTAButton = () => {
  // Frosmo stuff gets loaded asynchronously, so we need to scroll to the element instead of using a ref
  const handleScroll = () => {
    const element = document.getElementById('fr-liittyma-wrapper');
    if (element) {
      element.scrollIntoView();
    }
  };
  return (
    <>
      <CL.Button
        onClick={handleScroll}
        size="s"
        className="of-public-mobile-voice-subscriptions-campaign__submit of-public-mobile-voice-subscriptions-campaign__submit--mobile"
      >
        Tutustu ja tilaa
      </CL.Button>
      <CL.Button
        onClick={handleScroll}
        size="m"
        className="of-public-mobile-voice-subscriptions-campaign__submit of-public-mobile-voice-subscriptions-campaign__submit--desktop"
      >
        Tutustu ja tilaa
      </CL.Button>
    </>
  );
};

type CarouselItemProps = {
  imgSrc: string;
  heading: string;
  subheading: string;
  description: string;
};

const CarouselItem = ({ imgSrc, heading, subheading, description }: CarouselItemProps) => (
  <div className="of-public-mobile-voice-subscriptions-campaign__item">
    <img src={imgSrc} className="of-public-mobile-voice-subscriptions-campaign__image" alt="Campaign hero" />
    <div className="of-public-mobile-voice-subscriptions-campaign__content">
      <h3 className="of-public-mobile-voice-subscriptions-campaign__heading">{heading}</h3>
      <h4 className="of-public-mobile-voice-subscriptions-campaign__subheading">{subheading}</h4>
      <div className="of-public-mobile-voice-subscriptions-campaign__description">
        <p>{description}</p>
      </div>
      <CTAButton />
    </div>
  </div>
);

export const PublicMobileVoiceSubscriptionsCampaign = () => {
  return (
    <section className="of-public-mobile-voice-subscriptions-campaign">
      {/* for now, ignore ts as Carousel is missing in cl types */}
      {/* @ts-ignore */}
      <CL.Carousel
        buttons="primary"
        i18n_next_button_label="Seuraava"
        i18n_next_button_loop_label="Ensimmäinen"
        i18n_previous_button_label="Edellinen"
        i18n_previous_button_loop_label="Viimeinen"
        indicators
        loop
      >
        {[
          <CarouselItem
            imgSrc={`${pictureStorageBaseUrl}3gQhBikXweFPshd41S3lFf/892670_Elisa_YA_SME_Liittymat_Suomen_Yrittajat_3_25_Landing_page_2560x1014_1.jpg?w=1920`}
            heading="Rehellisesti kotimainen liittymä kevyeen käyttöön"
            subheading="Yritysliittymä 5G 100M, hintaan 23,99€/kk"
            description="Perustason liittymä, kun on tarve saada laadukas yhteys ja minimoida kustannuksia"
            key="1"
          />,

          <CarouselItem
            imgSrc={`${pictureStorageBaseUrl}1Per1N8Fm1g9UZ3mxGKsBp/892670_Elisa_YA_SME_Liittymat_Suomen_Yrittajat_3_25_Landing_page_2560x1014_2.jpg?w=1920`}
            heading="Rehellisesti kotimainen liittymä aktiivikäyttöön"
            subheading="Yritysliittymä 5G+ 300M, hintaan 28,99€/kk"
            description="Kun käytät paljon kännykkää ja sovelluksia arjessa. Yhteyden toimittava saumattomasti myös ruuhka-aikoina ja ulkomailla"
            key="2"
          />,
        ]}
        {/* @ts-ignore */}
      </CL.Carousel>
    </section>
  );
};
