import { fetchNumberRange } from '../fetch';
import { useEffect, useState } from 'react';
import { useSearchParams } from './useSearchParams';
import type { NumberRangeData } from '../loaders';

export const useNumberRanges = (corporateNumberRangeId?: string, extensionRangeId?: string) => {
  const [corporateNumberRange, setCorporateNumberRange] = useState<NumberRangeData>();
  const [extensionNumberRange, setExtensionNumberRange] = useState<NumberRangeData>();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  useEffect(() => {
    if (corporateNumberRangeId) {
      fetchNumberRange(corporateNumberRangeId, mdmId).then(res => setCorporateNumberRange(res));
    }
    if (extensionRangeId) {
      fetchNumberRange(extensionRangeId, mdmId).then(res => setExtensionNumberRange(res));
    }
  }, [corporateNumberRangeId, extensionRangeId, mdmId]);

  return { corporateNumberRange, extensionNumberRange };
};
