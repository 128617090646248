import { Grid } from '../Grid/Grid.js';
import { Table } from '../Table';
import { addressMsg, businessIdMsg, companyMsg, informantBISMsg, phoneNumberMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatAddress } from '../../common/utils/accountUtils.js';
import type * as CL from '@design-system/component-library';
import type { CompanyInfoState } from '../../common/types/states.js';

export interface CompanyInfoProps {
  companyInfos?: CompanyInfoState[];
}
export const CompanyInfo = ({ companyInfos }: CompanyInfoProps): JSX.Element => {
  const columns: CL.Column[] = [
    { key: 'company', label: t.KJTS(companyMsg), sortable: false },
    { key: 'address', label: t.GWZ6(addressMsg), sortable: false },
    { key: 'businessId', label: t.MPA5(businessIdMsg), sortable: false },
    { key: 'phone', label: t.AGIJ(phoneNumberMsg), sortable: false },
  ];
  return (
    <Grid>
      <p className={dsClass.TEXT_S}>{t.V9OQ(informantBISMsg)}</p>
      <div className={`of-company-info ${dsClass.MARGIN_TOP_4}`}>
        <Table
          columns={columns}
          rows={
            companyInfos?.map(ci => ({
              company: ci.companyName,
              address: formatAddress(ci.address),
              phone: ci.phoneNumber,
              businessId: ci.businessId,
            })) || []
          }
        />
      </div>
    </Grid>
  );
};
