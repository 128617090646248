import * as CL from '@design-system/component-library';
import { HeroHeading, HeroHeadingType } from '../HeroHeading';
import { Link } from 'react-router-dom';
import { SubscriptionsList } from './SubscriptionsList';
import {
  addDeviceOrSubscriptionMsg,
  checkRoamingUsageContentMsg,
  checkRoamingUsageTitleMsg,
  connectionProblemsMsg,
  deviceShopMsg,
  employeeOmaElisaWelcomeMsg,
  employeeOmaElisaWelcomeSubtitleMsg,
  getYourDeviceFixedContentMsg,
  getYourDeviceFixedTitleMsg,
  myDevicesMsg,
  orderNewDeviceContentMsg,
  orderNewDeviceTitleMsg,
  selectSubscriptionMsg,
  startTroubleshootingMsg,
  subscriptionTroubleshootingMsg,
  t,
  viewPukCodeContentMsg,
  viewPukCodeTitleMsg,
  whatDoYouWantToDoMsg,
} from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { EmployeeSubscriptions } from '../../common/types/subscription';
import type { SubscriptionAction } from '../../generated/api/subscriptionAction';

import './EmployeeHomeScene.scss';

export interface EmployeeHomeSceneProps {
  subscriptions?: EmployeeSubscriptions;
  subscriptionActions?: SubscriptionAction[];
}

export const EmployeeHomeScene = ({ subscriptions, subscriptionActions }: EmployeeHomeSceneProps) => {
  useTitle();

  const combinedSubscriptions = [
    ...(subscriptions?.device || []),
    ...(subscriptions?.voice || []),
    ...(subscriptions?.broadband || []),
  ];

  return (
    <div className="of-employee-home-scene">
      <HeroHeading
        fullScreen={true}
        center={true}
        heroHeadingType={HeroHeadingType.BLANK}
        title={t.I9G8(employeeOmaElisaWelcomeMsg)}
        subtitle={t.JTDS(employeeOmaElisaWelcomeSubtitleMsg)}
        smallSubtitle={true}
      />
      <h2 className="of-employee-home-scene-subheading">{t.KQE6('My services and troubleshooting')}</h2>
      <div className="of-employee-home-scene-content">
        <CL.Grid>
          <CL.GridRow>
            <CL.GridCol colWidth={6}>
              <CL.Card
                id="my-services-and-subscriptions-card"
                className="of-employee-home-scene-card"
                heading={
                  combinedSubscriptions?.length === 0 && (
                    <div className="ea-picto--broadband of-employee-home-scene-card-icon" />
                  )
                }
                content={
                  <SubscriptionsList subscriptions={combinedSubscriptions} subscriptionActions={subscriptionActions} />
                }
                footer={<Link to={paths.EMPLOYEE_SUBSCRIPTIONS}>{t.RWE3(addDeviceOrSubscriptionMsg)}</Link>}
              />
            </CL.GridCol>
            <CL.GridCol colWidth={6}>
              <CL.Card
                id="connection-problems-card"
                className="of-employee-home-scene-card"
                heading={<div className="ea-picto--magnifier of-employee-home-scene-card-icon" />}
                content={
                  <span>
                    <h3>{t.OTF5(connectionProblemsMsg)}</h3>
                    <p>{t.TLA6(subscriptionTroubleshootingMsg)}</p>
                  </span>
                }
                footer={<Link to={paths.EMPLOYEE_SUBSCRIPTIONS}>{t.A239(startTroubleshootingMsg)}</Link>}
              />
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
        <h2 className="of-employee-home-scene-subheading">{t.GNM7(whatDoYouWantToDoMsg)}</h2>
        <CL.Grid>
          <CL.GridRow>
            <CL.CardContainer>
              <CL.Card
                id="order-new-device-card"
                className="of-employee-home-scene-card"
                heading={<div className="ea-picto--epp of-employee-home-scene-card-icon" />}
                content={
                  <span>
                    <h3>{t.W2NJ(orderNewDeviceTitleMsg)}</h3>
                    <p>{t.SEB2(orderNewDeviceContentMsg)}</p>
                  </span>
                }
                footer={<Link to={paths.EMPLOYEE_DEVICE_STORE}>{t.KUXE(deviceShopMsg)}</Link>}
                type="dark"
              />
              <CL.Card
                id="repair-device-card"
                className="of-employee-home-scene-card"
                heading={<div className="ea-picto--maintenance of-employee-home-scene-card-icon" />}
                content={
                  <span>
                    <h3>{t.A5G1(getYourDeviceFixedTitleMsg)}</h3>
                    <p>{t.T1H4(getYourDeviceFixedContentMsg)}</p>
                  </span>
                }
                footer={<Link to={paths.EMPLOYEE_DEVICES}>{t.L1HT(myDevicesMsg)}</Link>}
                type="dark"
              />
              <CL.Card
                id="check-roaming-usage-card"
                className="of-employee-home-scene-card"
                heading={<div className="ea-picto--money of-employee-home-scene-card-icon" />}
                content={
                  <span>
                    <h3>{t.I053(checkRoamingUsageTitleMsg)}</h3>
                    <p>{t.MRD6(checkRoamingUsageContentMsg)}</p>
                  </span>
                }
                footer={<Link to={paths.EMPLOYEE_SUBSCRIPTIONS}>{t.V16Q(selectSubscriptionMsg)}</Link>}
                type="dark"
              />
              <CL.Card
                id="view-puk-codes-card"
                className="of-employee-home-scene-card"
                heading={<div className="ea-picto--puk-code of-employee-home-scene-card-icon" />}
                content={
                  <span>
                    <h3>{t.CV0S(viewPukCodeTitleMsg)}</h3>
                    <p>{t.B3IH(viewPukCodeContentMsg)}</p>
                  </span>
                }
                footer={<Link to={paths.EMPLOYEE_SUBSCRIPTIONS}>{t.V16Q(selectSubscriptionMsg)}</Link>}
                type="dark"
              />
            </CL.CardContainer>
          </CL.GridRow>
        </CL.Grid>
      </div>
    </div>
  );
};
