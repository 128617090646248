import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Form, Formik } from 'formik';
import { Message } from '../../common/formik/Fields/index.js';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import type { CommonError } from '../../common/types/errors.js';
import type { SupportCase } from '../../generated/api/models.js';

import './SupportCaseDialog.scss';

export interface SupportCaseDialogProps {
  action: string;
  body: string | JSX.Element;
  featureName: SupportCase.FeatureEnum;
  featureItemId?: string;
  header: string;
  placeholder: string;
  isSubmitInProgress?: boolean;
  errors?: CommonError[];
  saveButtonLabel?: string;

  onCloseDialog: () => void;
  onSubmitDialog: (supportCase: SupportCase) => void;
}

export function getErrorsFromSupportCaseDialog(supportCase: SupportCase): CommonError[] | undefined {
  let errors: CommonError[] | undefined;
  if (!supportCase.description || supportCase.description.length < 1) {
    if (!errors) {
      errors = [];
    }
    errors.push({
      type: CommonErrorType.VALIDATION,
      field: 'description',
      message: t.JSP4('The message field must not be left empty.'),
    });
  }
  return errors;
}

const SupportCaseDialogForm = ({
  obj,
  placeholder,
  onCloseDialog,
  onSubmitDialog,
  isSubmitInProgress,
  saveButtonLabel,
}: {
  obj: SupportCase;
  placeholder: string;
  onCloseDialog: () => void;
  onSubmitDialog: (supportCase: SupportCase) => void;
  isSubmitInProgress?: boolean;
  saveButtonLabel?: string;
}) => {
  return (
    <Formik
      initialValues={obj}
      onSubmit={values => {
        const supportCase: SupportCase = { ...obj, description: values.description };
        onSubmitDialog(supportCase);
        onCloseDialog();
      }}
    >
      <Form className="of-support-case-dialog" noValidate>
        <Message name="description" placeholder={placeholder} required={true} maxLength={29999} label="" />
        <ModalCloseAndSubmitButtons
          submitButtonText={saveButtonLabel}
          onCancel={onCloseDialog}
          onSubmit={() => {}}
          submitting={isSubmitInProgress}
        />
      </Form>
    </Formik>
  );
};

export const SupportCaseDialog = ({
  action,
  featureName,
  featureItemId,
  header,
  body,
  placeholder,
  onCloseDialog,
  onSubmitDialog,
  isSubmitInProgress,
  errors,
  saveButtonLabel,
}: SupportCaseDialogProps) => {
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname, search } = useLocation();
  const absoluteUrl = `${siteBaseUrl}${pathname}${search}`;
  const generalErrors = errors && errors.filter(error => !error.field);
  const obj: SupportCase = {
    action,
    feature: featureName,
    featureItemId,
    url: absoluteUrl,
    description: '',
  };

  return (
    <DialogWrapper closeable header={header} onCloseDialog={onCloseDialog}>
      <div className={`${dsClass.MARGIN_TOP_4} support-case-dialog`}>
        {body}
        {generalErrors &&
          generalErrors.map((error, i) => (
            <CL.Disclaimer className={dsClass.TEXT_M} icon={<CL.Icon icon="warning" />} key={i} text={error.message} />
          ))}
      </div>
      <SupportCaseDialogForm
        obj={obj}
        placeholder={placeholder}
        onCloseDialog={onCloseDialog}
        onSubmitDialog={onSubmitDialog}
        isSubmitInProgress={isSubmitInProgress}
        saveButtonLabel={saveButtonLabel}
      />
    </DialogWrapper>
  );
};
