import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses';
import type { StatusColor } from '../../common/enums';

import './Status.scss';

export interface StatusProps {
  color?: StatusColor;
  textInBadge?: string;
  textAfterBadge?: string | JSX.Element;
  inTable?: boolean;
  smallBadge?: boolean;
}

export const Status = ({ color, textAfterBadge, textInBadge, inTable, smallBadge }: StatusProps) => {
  return (
    <div className={`status-wrapper ${inTable ? 'status-column' : ''} ${smallBadge ? 'small-badge' : ''}`}>
      <div className={textInBadge ? 'status-badge-with-text' : 'status-badge'}>
        {color && <CL.Badge type={textInBadge ? 'text' : 'status'} color={color} text={textInBadge} />}
      </div>
      {textAfterBadge && <span className={`status-text ${inTable ? dsClass.TEXT_S : ''}`}>{textAfterBadge}</span>}
    </div>
  );
};
