import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { listedNumberMsg, numberDirectoryStatusMsg, t, unlistedMsg } from '../../common/i18n/index.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';

import './NumberPrivacy.scss';

export interface NumberPrivacyProps {
  cartItemInstanceId: string;
  hideHeader?: boolean;
  selectedCommercialProduct: ConfiguredCommercialProduct;
}

export interface NumberPrivacyModel {
  numberPublicity: boolean;
}

export const NumberPrivacy = ({ cartItemInstanceId, hideHeader }: NumberPrivacyProps) => {
  return (
    <div className="of-number-privacy" id="number-privacy">
      {hideHeader || <h4 className="ea-h4">{t.L8XT(numberDirectoryStatusMsg)}</h4>}
      <SelectRadio
        name={`configuration.${cartItemInstanceId}.numberPublicity`}
        defaultValue="true"
        items={[
          {
            label: t.SCL4(listedNumberMsg),
            value: 'true',
          },
          {
            label: t.ZGKE(unlistedMsg),
            value: 'false',
          },
        ]}
      />
    </div>
  );
};
