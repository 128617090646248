import {
  CommercialProductType,
  CreditCheckDecision,
  EppRedeemTerminateRequestType,
} from '../../generated/api/models.js';
import { DeviceChangeOption, WizardType } from '../../common/enums.js';
import {
  TypeKeys,
  pollForCreditCheck,
  pollForCreditCheckFailed,
  pollForCreditCheckV2Fulfilled,
  showDialog,
  startNotification,
  submitOnlineOrder,
  submitOnlineOrderFailed,
  submitOnlineOrderFulfilled,
  upsertPersonBillingAccountFailed,
} from '../actions/index.js';
import {
  UI_API_PRIVATE_BASE_PATH,
  actionToActionState,
  getMdmIdHeaderFromUrlParams,
  isNewDeviceCheckout,
} from './epicUtils.js';
import { callUiApi, getFromUiApiWithRetry, getPrivateUiApiHeaders, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import {
  contactUserContactEquals,
  createContacts,
  getDefaultAddOns,
  getNewContacts,
  getSalesProductsOrderItems,
  insertContactIdsIntoOffers,
  mapCommercialProductToOnlineOrderItemUnit,
} from './onlineOrderEpicUtils.js';
import { createBa, upsertPersonBillingAccount } from './billingAccountEpic.js';
import { createOnlineOrderPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { getCatalogCode, getShoppingCartId } from '../common/localStorageUtils.js';
import {
  getConflictingErrors,
  getDuplicateContactDialogForCheckoutParams,
  getDuplicateContactDialogParams,
} from './authenticatedUserEpic.js';
import {
  getDuplicateContactInformationDialogParams,
  getPossibleDuplicateContactInformationDialogParams,
} from '../../common/utils/contactUtils.js';
import { getMdmIdFromUrlParams } from '../../common/utils/urlUtils';
import { getTotalSums } from '../../common/utils/commercialProductUtils.js';
import { isCampaignPresent } from '../../common/utils/campaignUtils.js';
import { isEmployeePortal } from '../../common/utils/browserUtils.js';
import { isOmaElisa } from '../common/index.js';
import { of } from 'rxjs';
import { paths } from '../../common/constants/pathVariables.js';
import { postEppRedeemTerminate } from './subscriptionActionEpic.js';
import { push } from 'redux-first-history';
import { t, yourOrderHasBeenTransferredForProcessingMsg } from '../../common/i18n/index.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  Contact,
  CreditCheckResponse,
  OnlineOrder,
  OnlineOrderItem,
  SalesProductOrderItem,
} from '../../generated/api/models.js';
import type {
  ErrorAction,
  PollForCreditCheckAction,
  PostEppRedeemTerminateAction,
  PostEppRedeemTerminateFailedAction,
  PostEppRedeemTerminateFulfilledAction,
  SelfServiceActionTypes,
  SubmitOnlineOrderAction,
  SubmitOrderFulfilledAction,
  UpsertBillingAccountFailedAction,
  UpsertBillingAccountFulfilledAction,
  UpsertContactAction,
  UpsertContactFailedAction,
  UpsertContactFulfilledAction,
  UpsertPersonBillingAccountFailedAction,
  UpsertPersonBillingAccountFulfilledAction,
} from '../actions/index.js';
import type { State } from '../common/store.js';

const insertContactIdsIntoOrder = (
  orderAction: SubmitOnlineOrderAction,
  contacts: Contact[]
): SubmitOnlineOrderAction => {
  return {
    ...orderAction,
    items: insertContactIdsIntoOffers(orderAction.items, contacts),
  };
};

export const createOnlineOrder: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SUBMIT_ONLINE_ORDER)), (action: SubmitOnlineOrderAction) =>
    actionToActionState(action, state$, 'onlineOrders')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const submitOrderAction = actionAndState.action as SubmitOnlineOrderAction;
      const useApiVersion2 = !!submitOrderAction.apiVersion;

      // Create new contacts if included the order
      const newContacts = getNewContacts(submitOrderAction.items);
      if (newContacts.length > 0) {
        return createContacts(submitOrderAction.items, state$, epicDependencies).pipe(
          concatMap(
            (
              actions: (
                | UpsertContactFulfilledAction
                | UpsertContactAction
                | UpsertContactFailedAction
                | ErrorAction<TypeKeys>
              )[]
            ): Action[] => {
              const location = window.location.pathname;
              const duplicateContactAction: UpsertContactFailedAction | undefined = actions.filter(
                (e): e is UpsertContactFailedAction =>
                  e.type === TypeKeys.UPSERT_CONTACT_FAILED &&
                  e.errors?.[0].source?.error?.duplicateContact !== undefined
              )[0];
              const duplicateContact = duplicateContactAction?.errors?.[0].source?.error?.duplicateContact;
              if (duplicateContact && (isOmaElisa(location) || isNewDeviceCheckout(location))) {
                return [
                  showDialog(
                    duplicateContactAction?.errors?.[0].source?.error?.errorType === 'POSSIBLE_CONTACT_DUPLICATE'
                      ? getPossibleDuplicateContactInformationDialogParams(duplicateContact)
                      : getDuplicateContactInformationDialogParams(duplicateContact)
                  ),
                  submitOnlineOrderFailed('Failed to create order', 500),
                ];
              }

              if (
                actions.some(
                  action => action.type === TypeKeys.UPSERT_CONTACT_FAILED && getConflictingErrors(action.errors)
                ) &&
                (isOmaElisa(location) || isNewDeviceCheckout(location))
              ) {
                return [
                  showDialog(getDuplicateContactDialogParams()),
                  submitOnlineOrderFailed('Failed to create order', 500),
                ];
              }

              // Fail the device orders (submitted from checkout page) if contact creation fails
              // (mobile voice and mbb orders falls back to POU instead of contact in such cases)
              if (
                actions.find(action => action.type === TypeKeys.UPSERT_CONTACT_FAILED) &&
                (isEmployeePortal(location) || isNewDeviceCheckout(location))
              ) {
                return [submitOnlineOrderFailed('Failed to create Contact', 500)];
              }

              const createdContacts = actions
                .filter(action => action.type === TypeKeys.UPSERT_CONTACT_FULFILLED)
                .map((action: UpsertContactFulfilledAction) => action.contact);

              const updatedOrder = insertContactIdsIntoOrder(submitOrderAction, createdContacts);

              submitOrderAction.items.map(item =>
                item.selectedCommercialProducts.map(selectedCp => ({
                  ...selectedCp,
                  purposeOfUseOrContact:
                    selectedCp.purposeOfUseOrContact!.newContact !== undefined &&
                    actions
                      .filter(action => action.type === TypeKeys.UPSERT_CONTACT_FULFILLED)
                      .find((action: UpsertContactFulfilledAction) => {
                        contactUserContactEquals(
                          (action.contact.person || action.contact.userContact)!,
                          selectedCp.purposeOfUseOrContact!.newContact!
                        );
                      }),
                }))
              );
              return [
                ...actions,
                submitOnlineOrder(
                  updatedOrder.items,
                  updatedOrder.deliveryAddress,
                  submitOrderAction.contactPerson,
                  submitOrderAction.contactPersonValidationErrors,
                  submitOrderAction.customerType,
                  true,
                  updatedOrder.deliveryAddressValidationErrors,
                  updatedOrder.personAccountValidationErrors,
                  updatedOrder.billingAccountId,
                  updatedOrder.newBillingAccount,
                  updatedOrder.newBillingAccountValidationErrors,
                  updatedOrder.addOns,
                  true,
                  updatedOrder.deliveryMethodType,
                  updatedOrder.approverContact,
                  updatedOrder.approverContactValidationErrors,
                  undefined,
                  undefined,
                  undefined,
                  submitOrderAction.deviceChangeRequest,
                  submitOrderAction.shipmentType
                ),
              ];
            }
          )
        );
      }

      if (submitOrderAction.newBillingAccount) {
        return createBa(submitOrderAction, state$, epicDependencies, {
          selectedOffers: submitOrderAction.items,
          type: WizardType.NEW_MOBILE_BROADBAND,
        }).pipe(
          concatMap(
            (
              action: UpsertBillingAccountFulfilledAction | UpsertBillingAccountFailedAction | UpsertContactFailedAction
            ): Action[] => {
              if (action.type === TypeKeys.UPSERT_CONTACT_FAILED) {
                return [
                  showDialog(getDuplicateContactDialogForCheckoutParams()),
                  submitOnlineOrderFailed('Failed to create BA', 500),
                ];
              } else if (action.type === TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED) {
                return [submitOnlineOrderFailed('Failed to create BA', 500)];
              } else {
                return [
                  action,
                  submitOnlineOrder(
                    submitOrderAction.items,
                    submitOrderAction.deliveryAddress,
                    submitOrderAction.contactPerson,
                    submitOrderAction.contactPersonValidationErrors,
                    submitOrderAction.customerType,
                    true,
                    submitOrderAction.deliveryAddressValidationErrors,
                    submitOrderAction.personAccountValidationErrors,
                    action.billingAccount.billingAccountId,
                    undefined,
                    submitOrderAction.newBillingAccountValidationErrors,
                    submitOrderAction.addOns,
                    true,
                    submitOrderAction.deliveryMethodType,
                    submitOrderAction.approverContact,
                    submitOrderAction.approverContactValidationErrors,
                    undefined,
                    undefined,
                    undefined,
                    submitOrderAction.deviceChangeRequest,
                    submitOrderAction.shipmentType
                  ),
                ];
              }
            }
          )
        );
      }

      if (submitOrderAction.personBillingAddress && submitOrderAction.personBillingEmail) {
        return of(submitOrderAction)
          .pipe(
            concatMap((action: SubmitOnlineOrderAction | ErrorAction<TypeKeys>) => {
              if (action.type === TypeKeys.SUBMIT_ONLINE_ORDER) {
                const submitOnlineOrderAction = action as SubmitOnlineOrderAction;
                return upsertPersonBillingAccount(
                  {
                    address: submitOnlineOrderAction.personBillingAddress!,
                    email: submitOnlineOrderAction.personBillingEmail!,
                  },
                  epicDependencies,
                  state$,
                  submitOnlineOrderAction.personBillingAccountId
                );
              } else {
                return of(upsertPersonBillingAccountFailed('creating Personal Billing account failed', 500));
              }
            })
          )
          .pipe(
            concatMap(
              (
                action: UpsertPersonBillingAccountFulfilledAction | UpsertPersonBillingAccountFailedAction
              ): Action[] => {
                if (action.type === TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FAILED) {
                  return [submitOnlineOrderFailed('Failed to create Person billing account', 500)];
                } else {
                  return [
                    action,
                    submitOnlineOrder(
                      submitOrderAction.items,
                      submitOrderAction.deliveryAddress,
                      submitOrderAction.contactPerson,
                      submitOrderAction.contactPersonValidationErrors,
                      submitOrderAction.customerType,
                      true,
                      submitOrderAction.deliveryAddressValidationErrors,
                      submitOrderAction.personAccountValidationErrors,
                      submitOrderAction.billingAccountId,
                      undefined,
                      submitOrderAction.newBillingAccountValidationErrors,
                      submitOrderAction.addOns,
                      true,
                      submitOrderAction.deliveryMethodType,
                      submitOrderAction.approverContact,
                      submitOrderAction.approverContactValidationErrors,
                      action.response.billingAccountId,
                      undefined,
                      undefined,
                      submitOrderAction.deviceChangeRequest,
                      submitOrderAction.shipmentType
                    ),
                  ];
                }
              }
            )
          );
      }

      // Make EPP Redeem API call
      if (submitOrderAction?.deviceChangeRequest?.deviceChangeOption === DeviceChangeOption.REDEEM) {
        const redeemRequestType = submitOrderAction?.deviceChangeRequest?.redeemRequestType;
        if (!redeemRequestType) {
          return [submitOnlineOrderFailed('Failed to redeem a device, redeem request type missing', 500)];
        }
        const eppRedeemPayload = {
          request: {
            requestType: redeemRequestType,
            employeeRedeem:
              redeemRequestType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM
                ? {
                    personBillingAccountId:
                      submitOrderAction?.deviceChangeRequest?.deviceChangeRedeemPrice !== 0
                        ? submitOrderAction.personBillingAccountId
                        : undefined,
                    approverContact: submitOrderAction.approverContact,
                  }
                : undefined,
            companyRedeem:
              redeemRequestType === EppRedeemTerminateRequestType.COMPANY_REDEEM
                ? {
                    billingAccountId: submitOrderAction?.deviceChangeRequest?.replacedSubscriptionBillingAccountId,
                  }
                : undefined,
          },
          subscriptionId: submitOrderAction?.deviceChangeRequest?.replacedSubscriptionId,
        } as PostEppRedeemTerminateAction;

        return postEppRedeemTerminate(eppRedeemPayload, state$, epicDependencies).pipe(
          concatMap((action: PostEppRedeemTerminateFulfilledAction | PostEppRedeemTerminateFailedAction): Action[] => {
            if (action.type === TypeKeys.POST_EPP_REDEEM_TERMINATE_FAILED) {
              return [submitOnlineOrderFailed('Failed to redeem a device', 500)];
            } else {
              return [
                action,
                submitOnlineOrder(
                  submitOrderAction.items,
                  submitOrderAction.deliveryAddress,
                  submitOrderAction.contactPerson,
                  submitOrderAction.contactPersonValidationErrors,
                  submitOrderAction.customerType,
                  submitOrderAction.isCompanyNameRequired,
                  submitOrderAction.deliveryAddressValidationErrors,
                  submitOrderAction.personAccountValidationErrors,
                  submitOrderAction.billingAccountId,
                  undefined,
                  submitOrderAction.newBillingAccountValidationErrors,
                  submitOrderAction.addOns,
                  true,
                  submitOrderAction.deliveryMethodType,
                  submitOrderAction.approverContact,
                  submitOrderAction.approverContactValidationErrors,
                  submitOrderAction.personBillingAccountId,
                  submitOrderAction.shipmentType
                ),
              ];
            }
          })
        );
      }

      // Create OnlineOrder stuff
      const orderItems: OnlineOrderItem[] = submitOrderAction.items
        .filter(
          item =>
            item.selectedCommercialProducts[0].commercialProduct.productType !== CommercialProductType.SALES_PRODUCT
        )
        .map(item => {
          const campaignAssociations = item.selectedCommercialProducts[0].commercialProduct.campaignAssociations;
          const campaignAssociationCode = item.selectedCampaignAssociation
            ? item.selectedCampaignAssociation.campaignAssociationCode
            : isCampaignPresent(campaignAssociations)
              ? campaignAssociations![0]?.campaignAssociationCode
              : undefined;
          const enrollmentProgramAlias =
            item.selectedCommercialProducts[0]?.enrollmentProgramAlias !== ''
              ? {
                  enrollmentProgramAlias: item.selectedCommercialProducts[0].enrollmentProgramAlias,
                }
              : undefined;

          /**
           * Some AddOns are added to the order without them being present on the CommercialProduct.
           * We need to add these to order separately so they won't be left out.
           * For example Yrityspuhe voice sub "minute ordering" needs this.
           * Some other places are adding AddOns to the CP by mutating it such as Laitenetti product.
           */
          const extraAddOns =
            item.selectedCommercialProducts[0].addedAddOns
              ?.filter(
                addedAddOn =>
                  item.selectedCommercialProducts[0].commercialProduct.addOnAssociations?.find(
                    addOnAssociation => addOnAssociation.addOnCode === addedAddOn.addOnCode
                  ) === undefined
              )
              .map(addedAddOn => {
                return {
                  addOnAssociationCode: addedAddOn.addOnAssociationCode,
                  addOnCode: addedAddOn.addOnCode,
                  campaignAssociationCode: undefined,
                  addOnAttributes: addedAddOn.addOnAttributes,
                };
              }) || [];

          let orderItem: OnlineOrderItem = {
            // Add default addons (by_default + selected) and also add extra addons
            addOns: [
              ...getDefaultAddOns(item.selectedCommercialProducts[0], submitOrderAction.addOns, campaignAssociations),
              ...extraAddOns,
            ],
            // only supporting one selected commercial product per selected offer (as does the UI) for now
            commercialProductCode: item.selectedCommercialProducts[0].commercialProduct.commercialProductCode,
            commercialProductName: item.selectedCommercialProducts[0].commercialProduct.commercialProductName,
            campaignAssociationCode,
            offerCode: item.offer.offerCode,
            orderItemQuantity: item.selectedCommercialProducts.length,
            orderItemUnits: item.selectedCommercialProducts.map((product, index) =>
              mapCommercialProductToOnlineOrderItemUnit(
                product,
                index,
                submitOrderAction.deliveryMethodType,
                submitOrderAction.contactPerson
              )
            ),
            enrollmentProgramSelected: item.selectedCommercialProducts[0].enrollmentProgramConsent,
            ...enrollmentProgramAlias,
          };

          if (item.selectedCommercialProducts[0].replacedSubscriptionId) {
            orderItem = {
              ...orderItem,
              replacedSubscriptionId: item.selectedCommercialProducts[0].replacedSubscriptionId,
            };
          }
          return orderItem;
        });

      const salesProductsOrderItems: SalesProductOrderItem[] = getSalesProductsOrderItems(
        submitOrderAction.items,
        submitOrderAction.deliveryMethodType
      );

      const totalSums = getTotalSums(
        submitOrderAction.items
          .map(configuredOffer => configuredOffer.selectedCommercialProducts)
          .reduce((prev, next) => prev.concat(next), []) // flattens
      );

      const catalogCode = isEmployeePortal(window.location.pathname) ? getCatalogCode() || undefined : undefined;

      const isPersonBaRequired = totalSums.totalMonthlyRecurringCharge > 0 || totalSums.totalOneTimeCharge > 0;
      // We cannot utilize PostOnlineOrderRequest here because it contains a lot of attributes
      // that are populated in chameleon-rest or sfdc
      const onlineOrder: Partial<OnlineOrder> = {
        approverContact: submitOrderAction.approverContact,
        billingAccountId: submitOrderAction.billingAccountId,
        catalogCode: catalogCode,
        deliveryAddress: submitOrderAction.deliveryAddress,
        orderItems: orderItems,
        salesProductsOrderItems: salesProductsOrderItems,
        personBillingAccountId: isPersonBaRequired ? submitOrderAction.personBillingAccountId : undefined,
        totalFixedTermCharge: totalSums.totalFixedTermCharge,
        totalMonthlyRecurringCharge: totalSums.totalMonthlyRecurringCharge,
        totalOneTimeCharge: totalSums.totalOneTimeCharge,
        totalOneTimeChargeVat: totalSums.totalOneTimeChargeVat,
        shoppingCartId: getShoppingCartId(),
        shipmentType: submitOrderAction.shipmentType,
      };
      return callUiApi({
        epicDependencies,
        failureAction: submitOnlineOrderFailed,
        method: createOnlineOrderPrivateMethod(),
        payload: {
          onlineOrder: onlineOrder,
        },
        state$,
        successAction: submitOnlineOrderFulfilled,
        headers: getMdmIdHeaderFromUrlParams(),
      }).pipe(
        concatMap(action => {
          const submitOrderFulfilledAction = action as SubmitOrderFulfilledAction;
          const mdmId = getMdmIdFromUrlParams();
          const attachRingRedirect = mdmId
            ? `${submitOrderAction.attachRingRedirect}?mdmId=${mdmId}`
            : submitOrderAction.attachRingRedirect;
          if (useApiVersion2 && submitOrderFulfilledAction.type === TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED) {
            return [
              action,
              startNotification(t.FDSO(yourOrderHasBeenTransferredForProcessingMsg)),
              push(attachRingRedirect ? attachRingRedirect : paths.SELF_SERVICE_HOME),
            ];
          }
          if (submitOrderFulfilledAction.type === TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED) {
            return [action, pollForCreditCheck(submitOrderFulfilledAction.orderId)];
          }
          return of(action);
        })
      );
    })
  );
};

const pollCreditCheckV2 = (
  actionAndState: ActionAndState,
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  const pollForCreditCheckAction = actionAndState.action as PollForCreditCheckAction;
  const queryParams: { [s: string]: string } = {};
  if (pollForCreditCheckAction.orderId) {
    queryParams.onlineOrderId = pollForCreditCheckAction.orderId;
  }
  const request = {
    basePath: UI_API_PRIVATE_BASE_PATH,
    epicDependencies,
    failureAction: pollForCreditCheckFailed,
    path: '/online-orders/credit-check',
    queryParams: queryParams,
    state$,
    successAction: (response: CreditCheckResponse) => {
      const fulfilledAction = pollForCreditCheckV2Fulfilled(response, pollForCreditCheckAction.orderId);
      if (response.decision === CreditCheckDecision.PASS) {
        const pathname = window.location.pathname;
        if (
          pathname &&
          [
            paths.PS_BROADBAND_SUBSCRIPTION_DELIVERY_INFO,
            paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M_DELIVERY_INFO,
            paths.PS_MOBILE_SUBSCRIPTION_NEW_DELIVERY_INFO,
          ].includes(decodeURIComponent(pathname))
        ) {
          return [
            fulfilledAction,
            startNotification(t.FDSO(yourOrderHasBeenTransferredForProcessingMsg)),
            push(paths.SELF_SERVICE_HOME),
          ];
        } else {
          return [
            fulfilledAction,
            push(
              (isEmployeePortal(window.location.pathname) ? paths.EMPLOYEE_HOME : '') + paths.DEVICE_CHECKOUT_THANK_YOU
            ),
          ];
        }
      }
      return [fulfilledAction];
    },
  };
  // TODO: Use uiApiUtils.callGetUiApiWithRetry instead
  return getFromUiApiWithRetry(request, undefined, 2000, action$, {
    ...getPrivateUiApiHeaders(request.state$),
    ...getMdmIdHeaderFromUrlParams(),
  });
};

export const pollCreditCheck: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.POLL_FOR_CREDIT_CHECK)), (action: PollForCreditCheckAction) =>
    actionToActionState(action, state$, 'onlineOrders')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => pollCreditCheckV2(actionAndState, action$, state$, epicDependencies))
  );

export const onlineOrdersEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  createOnlineOrder,
  pollCreditCheck
);
