import { StatusColor, SupportCaseFields } from '../enums.js';
import { SupportCase, SupportCasesSearchResponse } from '../../generated/api/models.js';
import {
  additionalInformationReceivedDescriptionMsg,
  additionalInformationReceivedMsg,
  billingEOperatorChangesMsg,
  cancelledMsg,
  cancelledStatusDescriptionMsg,
  caseCreatedMsg,
  closedMsg,
  closedStatusDescriptionMsg,
  companyMsg,
  coverageInterruptionsIncidentsMsg,
  emailAddressMsg,
  firstNameMsg,
  inProgressMsg,
  inProgressStatusDescriptionMsg,
  invoiceNumberMsg,
  invoicingAndCustomerDetailsMsg,
  invoicingMsg,
  lastNameMsg,
  messageMsg,
  movedElsewhereDescriptionMsg,
  movedElsewhereMsg,
  myServicesMsg,
  newOrdersAndOfferRequestsMsg,
  openOrdersMsg,
  openStatusDescriptionMsg,
  otherTopicMsg,
  pukCodeMsg,
  reopenedDescriptionMsg,
  reopenedMsg,
  resolutionMsg,
  resolvedMsg,
  resolvedStatusDescriptionMsg,
  t,
  technicalAdviceMsg,
  terminationMsg,
  topicMsg,
  unknownMsg,
  waitingForActionMsg,
  waitingForInputStatusDescriptionMsg,
  waitingForProcessingMsg,
} from '../i18n/index.js';
import type { AddedAddon } from '../types/addon';
import type { PurposeOfUseOrContact } from '../types/subscription';

export interface StepperDisplayData {
  name: string;
  description?: string;
  icon?: string;
  lastStatusColor?: StatusColor;
}

export const featureTranslation = (name?: SupportCase.FeatureEnum) => {
  switch (name) {
    case SupportCase.FeatureEnum.INVOICES:
      return t.AUI8(invoicingMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_OFFER_REQUEST:
      return t.J923(newOrdersAndOfferRequestsMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_OPEN_ORDERS:
      return t.NC0D(openOrdersMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_BILLING:
      return t.WWSI(invoicingAndCustomerDetailsMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_SERVICES:
      return t.CG4F(myServicesMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_TECHNICAL:
      return t.V7EA(technicalAdviceMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_COVERAGE:
      return t.DKUZ(coverageInterruptionsIncidentsMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_CANCEL:
      return t.MN35(terminationMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_PUK:
      return t.K5TL(pukCodeMsg);
    case SupportCase.FeatureEnum.CONTACT_INFO_OTHER:
      return t.CHWD(otherTopicMsg);
    case SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES:
      return t.TZYQ(billingEOperatorChangesMsg);
    default:
      return t.QWC8(unknownMsg);
  }
};

export const statusTranslation = (status?: SupportCase.StatusEnum) => {
  switch (status) {
    case SupportCase.StatusEnum.IN_PROGRESS:
      return t.Y72F(inProgressMsg);
    case SupportCase.StatusEnum.WAITING_FOR_INPUT:
      return t.WG63(waitingForActionMsg);
    case SupportCase.StatusEnum.RESOLVED:
      return t.YMED(resolvedMsg);
    case SupportCase.StatusEnum.CANCELLED:
      return t.UF3P(cancelledMsg);
    case SupportCase.StatusEnum.CLOSED:
      return t.UFGV(closedMsg);
    case SupportCase.StatusEnum.OPEN:
      return t.L7ML(waitingForProcessingMsg);
    case SupportCase.StatusEnum.MOVED_ELSEWHERE:
      return t.SZ6N(movedElsewhereMsg);
    default:
      return t.QWC8(unknownMsg);
  }
};

export const fieldIsInContentFilter = (field: string) => {
  return (
    field === SupportCasesSearchResponse.MatchedFieldsEnum.DESCRIPTION ||
    field === SupportCasesSearchResponse.MatchedFieldsEnum.INVOICE_NUMBER ||
    field === SupportCasesSearchResponse.MatchedFieldsEnum.RESOLUTION
  );
};

export const hasMatchedFieldsInContent = (matchedFields: Array<string> | undefined) => {
  return matchedFields !== undefined && matchedFields.filter(fieldIsInContentFilter).length > 0;
};

export const getColumnTranslation = (columnKey: SupportCaseFields) => {
  switch (columnKey) {
    case SupportCaseFields.SUPPORT_CASE_DISPLAY_ID:
      return t.G0NE('Case Number');
    case SupportCaseFields.CREATED_AT:
      return t.HQ41('Opened');
    case SupportCaseFields.FEATURE:
      return t.SF9V('Topic');
    case SupportCaseFields.CONTACT_DETAILS:
      return t.VHFX('Requestor');
    case SupportCaseFields.LAST_MODIFIED:
      return t.RTJW('Last Modified');
    case SupportCaseFields.STATUS:
      return t.UNK4('Status');
    case SupportCaseFields.FEATURE_TYPE:
      return t.X5AB(topicMsg);
    case SupportCaseFields.INVOICE_NUMBER:
      return t.BRFX(invoiceNumberMsg);
    case SupportCaseFields.DESCRIPTION:
      return t.J0O8(messageMsg);
    case SupportCaseFields.FIRST_NAME:
      return t.AIK7(firstNameMsg);
    case SupportCaseFields.LAST_NAME:
      return t.Y41S(lastNameMsg);
    case SupportCaseFields.EMAIL:
      return t.OKYY(emailAddressMsg);
    case SupportCaseFields.RESOLUTION:
      return t.YOW5(resolutionMsg);
    case SupportCaseFields.COMPANY:
      return t.KJTS(companyMsg);
  }
};

export const statusData = (name: SupportCase.StatusEnum | undefined): { text: string; color: StatusColor } => {
  switch (name) {
    case SupportCase.StatusEnum.IN_PROGRESS:
      return { text: statusTranslation(name), color: StatusColor.YELLOW };
    case SupportCase.StatusEnum.WAITING_FOR_INPUT:
      return { text: statusTranslation(name), color: StatusColor.SALMON };
    case SupportCase.StatusEnum.RESOLVED:
      return { text: statusTranslation(name), color: StatusColor.GREEN };
    case SupportCase.StatusEnum.CANCELLED:
    case SupportCase.StatusEnum.OPEN:
      return { text: statusTranslation(name), color: StatusColor.GREY };
    case SupportCase.StatusEnum.CLOSED:
    case SupportCase.StatusEnum.MOVED_ELSEWHERE:
      return { text: statusTranslation(name), color: StatusColor.DARK_BLUE };
    default:
      return { text: t.QWC8(unknownMsg), color: StatusColor.GREY };
  }
};

export const getStepperDisplayDataForStatus = (value: string): StepperDisplayData => {
  switch (value.toLowerCase()) {
    case 'case created':
      return {
        name: t.EIC5(caseCreatedMsg),
        description: t.ENVW(openStatusDescriptionMsg),
        icon: 'clock',
        lastStatusColor: StatusColor.GREY,
      };
    case 'in progress':
    case 'waiting for input from elisa':
      return {
        name: t.Y72F(inProgressMsg),
        description: t.D7L8(inProgressStatusDescriptionMsg),
        icon: 'update',
        lastStatusColor: StatusColor.YELLOW,
      };
    case 'potential sales':
    case 'waiting for input':
      return {
        name: t.WG63(waitingForActionMsg),
        description: t.BCB6(waitingForInputStatusDescriptionMsg),
        icon: 'clock',
        lastStatusColor: StatusColor.SALMON,
      };
    case 'resolved':
      return {
        name: t.YMED(resolvedMsg),
        description: t.UU5X(resolvedStatusDescriptionMsg),
        lastStatusColor: StatusColor.GREEN,
      };
    case 'cancelled':
      return {
        name: t.UF3P(cancelledMsg),
        description: t.ZMRO(cancelledStatusDescriptionMsg),
        icon: 'block',
        lastStatusColor: StatusColor.GREY,
      };
    case 'closed':
      return {
        name: t.UFGV(closedMsg),
        description: t.CRIM(closedStatusDescriptionMsg),
        lastStatusColor: StatusColor.DARK_BLUE,
      };
    case 'input received':
      return {
        name: t.HA0A(additionalInformationReceivedMsg),
        description: t.L0FO(additionalInformationReceivedDescriptionMsg),
        icon: 'clock',
        lastStatusColor: StatusColor.YELLOW,
      };
    case 'reopened':
    case 'escalated':
      return {
        name: t.LR7P(reopenedMsg),
        description: t.R13J(reopenedDescriptionMsg),
        icon: 'clock',
        lastStatusColor: StatusColor.YELLOW,
      };
    case 'moved elsewhere':
      return {
        name: t.SZ6N(movedElsewhereMsg),
        description: t.JCSR(movedElsewhereDescriptionMsg),
        icon: 'arrow-right-alt',
        lastStatusColor: StatusColor.DARK_BLUE,
      };
    case 'open': // 'open' never shown in stepper
    default:
      return { name: t.QWC8(unknownMsg) };
  }
};

export const resolveSort = (sort?: string): string => {
  if (sort === SupportCaseFields.FEATURE) {
    return `${SupportCaseFields.FEATURE},${SupportCaseFields.FEATURE_TYPE}`;
  } else if (sort === SupportCaseFields.CONTACT_DETAILS) {
    return `${SupportCaseFields.LAST_NAME},${SupportCaseFields.FIRST_NAME}`;
  } else if (sort === undefined) {
    return SupportCaseFields.CREATED_AT;
  }
  return sort;
};

export const createRowForDescription = (header: string, value: string | undefined | boolean) => {
  if (!value) {
    return '';
  }
  return `${header}: ${value}`;
};

export const getAddonsText = (addedAddOns: AddedAddon[]) => {
  const uniqueAddOns = Object.values(
    addedAddOns.reduce((addOns: { [k: string]: AddedAddon }, addOn) => {
      if (!addOns[addOn.addOnCode]) {
        addOns[addOn.addOnCode] = addOn;
      }
      return addOns;
    }, {})
  );

  return `Lisäpalvelut:

  ${uniqueAddOns.map(addon => addon?.addOnAttributes?.displayNameOverride ?? addon.displayName).join('\n\n')}`;
};

export const getContactOrPurposeOfUse = (purposeOfUseOrContact: PurposeOfUseOrContact, isLiikkuvaWifi = false) => {
  const orderType = isLiikkuvaWifi ? 'Liikkuva WiFi' : 'Netti Lite';

  if (purposeOfUseOrContact.purposeOfUse) {
    return `${orderType} käyttötarkoitus: ${purposeOfUseOrContact.purposeOfUse}`;
  }

  const { firstName, lastName, phoneNumber, email } = purposeOfUseOrContact;

  return `${orderType} käyttäjäyhteyshenkilö:
    Etunimi: ${firstName}
    Sukunimi: ${lastName}
    Puhelinnumero: ${phoneNumber}
    Sähköpostiosoite: ${email}`;
};
