import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { OnboardingRequestList } from '../../../../../components/OnboardingRequestList/OnboardingRequestList.js';
import { TypeKeys } from '../../../../../selfservice/exports.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { useEffect } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { OnboardingRequestsResponse } from '../../../../../generated/api/onboardingRequestsResponse.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoInvitesPath = () => {
  const lastAction = useSelector((state: State) => state.lastAction, deepEqual);
  const revalidator = useRevalidator();
  const { onboardingRequests } = useLoaderData() as OnboardingRequestsResponse;

  useEffect(() => {
    if (lastAction.type === TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED) {
      revalidator.revalidate();
    }
  }, [revalidator, lastAction]);

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.INVITES}>
      <OnboardingRequestList onboardingRequests={onboardingRequests} />
    </CompanyInfoContainer>
  );
};
