import { BillingAccountSubscriptionFields } from '../../common/enums';
import { CATEGORY_URL } from '../../common/utils/categoryUtils';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table } from '../Table';
import { DomainSubscriptionLink } from './DomainSubscriptionLink';
import { HighlightFormattedPhoneNumber } from '../Highlight/HighlightFormattedPhoneNumber';
import { Status } from '../Status/Status.js';
import { SubscriptionLink } from './SubscriptionLink';
import { SubscriptionType } from '../../generated/api/subscriptionType';
import { dsClass } from '../../common/constants/dsClasses';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import { formatSum } from '../../common/utils/priceUtils';
import { getCategoryBySubscriptionType } from '../../public/common/util/category';
import {
  getRecurringPriceAsText,
  getSubscriptionStatusColor,
  getSubscriptionStatusText,
} from '../SubscriptionDetails/subscriptionDetailsCommon';
import { highlightKeyword } from '../../common/utils/searchFieldUtils';
import {
  identifierMsg,
  monthlyChargeMsg,
  noProductsMsg,
  oneTimePaymentMsg,
  productMsg,
  statusMsg,
  t,
} from '../../common/i18n';
import { isYttSubscription } from '../../common/utils/subscriptionUtils';
import { paths } from '../../common/constants/pathVariables';
import { setDefaultItemsPerPage } from '../Table/tableUtils';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import type * as CL from '@design-system/component-library';
import type { DefaultListSearchParams } from '../Table';
import type { SubscriptionHeader } from '../../generated/api/subscriptionHeader';
import type { SubscriptionSearchResponse } from '../../generated/api/subscriptionSearchResponse';

import './BillingAccountSubscriptionList.scss';

export interface BillingAccountSubscriptionListProps {
  subscriptions: SubscriptionSearchResponse[];
  totalItems: number;
}

const getSubscriptionIdentifierAdditionalText = (subscription: SubscriptionHeader, search?: string): JSX.Element => {
  if (subscription.serialNumber) {
    return <>IMEI: {highlightKeyword(subscription.serialNumber, search)}</>;
  }
  if (subscription.subscriptionUserFriendlyId) {
    return (
      <HighlightFormattedPhoneNumber
        formattedPhoneNumber={formatPhoneNumber(subscription.subscriptionUserFriendlyId)}
        search={search}
      />
    );
  }
  // Prevent displaying subscriptionDisplayId or subscriptionId if it is already shown in top of the additional text
  return (
    <>
      {subscription.subscriptionPurposeOfUse || subscription.subscriptionContactName
        ? subscription.subscriptionDisplayId || subscription.subscriptionId
        : ''}
    </>
  );
};

const getSubscriptionRows = (search?: string, searchResults: SubscriptionSearchResponse[] = []) =>
  searchResults.map(({ result: subscription }) => ({
    [BillingAccountSubscriptionFields.NAME]:
      subscription.subscriptionType === SubscriptionType.DOMAIN ? (
        <DomainSubscriptionLink subscription={subscription} search={search} />
      ) : (
        <SubscriptionLink
          subscription={subscription}
          path={`${paths.PS_HOME}/${CATEGORY_URL[getCategoryBySubscriptionType(subscription.subscriptionType!)]}/:subscriptionId`}
          search={search}
        />
      ),
    [BillingAccountSubscriptionFields.IDENTIFIER]: (
      <>
        <div>
          {highlightKeyword(
            subscription.subscriptionPurposeOfUse ||
              subscription.subscriptionContactName ||
              subscription.subscriptionDisplayId ||
              subscription.subscriptionId,
            search
          )}
        </div>
        <div
          className={`${dsClass.FONT_WEIGHT_400} ${dsClass.FONT_SIZE_SMALL} ${dsClass.FONT_STYLE_NORMAL} ${dsClass.COLOR_NEUTRAL_500}`}
        >
          {getSubscriptionIdentifierAdditionalText(subscription, search)}
        </div>
      </>
    ),
    [BillingAccountSubscriptionFields.MONTHLY_RECURRING_CHARGE]:
      subscription.monthlyRecurringCharge && !isYttSubscription(subscription.subscriptionSubType)
        ? getRecurringPriceAsText(subscription.monthlyRecurringCharge)
        : '',
    [BillingAccountSubscriptionFields.ONE_TIME_CHARGE]:
      subscription.oneTimeCharge && !subscription.monthlyRecurringCharge ? formatSum(subscription.oneTimeCharge) : '',
    [BillingAccountSubscriptionFields.STATUS]: (
      <Status
        key={subscription?.subscriptionId}
        color={getSubscriptionStatusColor(subscription?.subscriptionStatus)}
        textAfterBadge={getSubscriptionStatusText(subscription?.subscriptionStatus)}
        inTable={true}
      />
    ),
  }));

export const BillingAccountSubscriptionList = ({ subscriptions, totalItems }: BillingAccountSubscriptionListProps) => {
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;

  const columns: CL.Column[] = [
    { key: BillingAccountSubscriptionFields.NAME, label: t.C84H(productMsg), sortable: true, width: '40%' },
    { key: BillingAccountSubscriptionFields.IDENTIFIER, label: t.L9QG(identifierMsg), width: '30%' },
    { key: BillingAccountSubscriptionFields.MONTHLY_RECURRING_CHARGE, label: t.P0PS(monthlyChargeMsg) },
    { key: BillingAccountSubscriptionFields.ONE_TIME_CHARGE, label: t.ASEI(oneTimePaymentMsg) },
    { key: BillingAccountSubscriptionFields.STATUS, label: t.ASQT(statusMsg), sortable: true },
  ];
  const rows = getSubscriptionRows(search, subscriptions);

  return (
    <div className="of-billing-account-subscription-list">
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)} />
      <Table columns={columns} noItemsText={t.GXAQ(noProductsMsg)} rows={rows || []} />
      <ListPagination totalItems={totalItems || 0} />
    </div>
  );
};
