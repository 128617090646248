import { CustomFormType, createPrice, updatePrices } from '../CustomFormFields.js';
import { QuantityComponent } from '../../../common/react-hook-form/components/QuantityComponent.js';
import { dsClass } from '../../../common/constants/dsClasses';
import { useFormContext } from 'react-hook-form';
import type { CustomFormField } from '../CustomFormFields.js';
import type { Price } from '../../../generated/api/models.js';
import type { QuantityData } from '../../../common/react-hook-form/components/QuantityComponent.js';

interface PriceWithQuantity extends Price {
  quantity: number;
}

interface PriceGroups {
  onetimePrice?: number;
  periodicPrice?: number;
}

export const CustomFormQuantity = ({ title, quantityFields }: { title: string; quantityFields: CustomFormField[] }) => {
  const { register, setValue, getValues } = useFormContext();

  const calculateQuantityPrices = (data: Record<string, object>) => {
    const { onetimePrice, periodicPrice }: PriceGroups = Object.values(data).reduce(
      (
        acc: { onetimePrice: number; periodicPrice: number },
        { monthlyRecurringCharge = 0, oneTimeCharge = 0, quantity = 0 }: PriceWithQuantity
      ) => ({
        onetimePrice: acc.onetimePrice + oneTimeCharge * quantity,
        periodicPrice: acc.periodicPrice + monthlyRecurringCharge * quantity,
      }),
      { onetimePrice: 0, periodicPrice: 0 }
    );
    const periodicPriceMap = getValues('periodicPriceMap');
    const onetimePriceMap = getValues('onetimePriceMap');
    periodicPriceMap.set(CustomFormType.M365, periodicPrice);
    onetimePriceMap.set(CustomFormType.M365, onetimePrice);
    updatePrices(setValue, periodicPriceMap, onetimePriceMap);
  };

  const onUpdate = (data: QuantityData) => {
    const values = getValues(CustomFormType.M365);
    values[data.id] = data;
    setValue(CustomFormType.M365, values);
    calculateQuantityPrices(values);
  };

  return (
    <div className="of-custom-form__quantities">
      <input type="hidden" {...register(CustomFormType.M365)} />
      <h3 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_3}`}>{title}</h3>
      {quantityFields.map((field: CustomFormField) => {
        return (
          <div key={field.id} className="of-custom-form__quantities-quantity">
            <div>
              {field.value!} ({createPrice(field)})
            </div>
            <QuantityComponent
              onUpdate={onUpdate}
              data={{
                id: field.id!,
                name: CustomFormType.M365,
                value: field.value,
                monthlyRecurringCharge: field.monthlyRecurringCharge,
                oneTimeCharge: field.oneTimeCharge,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
