import { AutoComplete } from '../AutoComplete/AutoComplete.js';
import { BillingAccountInfo } from './BillingAccountInfo.js';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  billingAccountNonVisibleFields,
  getDefaultBillingAccountId,
  isBillingAccountInSfdc,
} from '../../common/utils/billingAccountUtils.js';
import { CreateBillingAccount } from '../CreateBillingAccount/CreateBillingAccount.js';
import { Loading } from '../Loading/index.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  billingDetailsMsg,
  loadingMsg,
  loadingWithThreePeriodsMsg,
  newBillingAgreementMsg,
  noSearchResults,
  noSelectionMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fetchBillingAccount, fetchBillingAccounts } from '../../common/fetch.js';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils';
import { getHighlightedFields } from '../../common/utils/searchFieldUtils.js';
import { useEffect, useState } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useSelector } from 'react-redux';
import type { BasicAutoCompleteItem } from '../AutoComplete/AutoComplete.js';
import type {
  BillChannel,
  BillingAccount,
  BillingAccountSearchResponse,
  Contact,
  ContactHeader,
} from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

import './AddOrSelectBillingAccounts.scss';

export interface AddOrSelectBillingAccountsProps {
  billingAccounts: BillingAccountSearchResponse[];
  billChannels?: BillChannel[];
  contacts: Contact[] | ContactHeader[];
  companyInfo?: CompanyInfoState;
  addNewBA: boolean;
  getBillingAccountSaveValuesFn: (getSelectedBAData: () => SelectedBAData) => void;
  isSaving: boolean;
  setRefCallBack?: (key: string, ref: HTMLElement | null) => void;
  title?: string;
  additionalInformation?: JSX.Element;
  selectedBaId?: string;
  disabled?: boolean;
  createBillingAccountErrors?: CommonError[];
}

interface BASelectedBase {
  error?: string;
}

interface ExistingBASelected extends BASelectedBase {
  selectedBaId: string;
  prepareNewBillingAccountSaveValues?: never;
}

interface NewBASelected extends BASelectedBase {
  selectedBaId?: never;
  prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier;
}

export type SelectedBAData = ExistingBASelected | NewBASelected;

// Ignore these fields, since they are always shown with BA in the dropdown.
const baSearchIgnoreFields = ['billingAccountName', 'billingAccountExtensionName'];

const filterMatchedFields = (matchedFields?: Array<string>) => {
  return matchedFields?.filter(field => !baSearchIgnoreFields.includes(field));
};

export const getBillingAccountListItems = (
  addNewBA: boolean,
  baHeaders?: Array<BillingAccountSearchResponse>,
  searchText?: string
) => {
  const baItems: Array<BasicAutoCompleteItem> = [];
  if (addNewBA) {
    baItems.push({
      label: t.NCIJ(newBillingAgreementMsg),
      html: (
        <div className="of-billing-account-search-result">
          <span>{t.NCIJ(newBillingAgreementMsg)}</span>
        </div>
      ),
      value: CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
    });
  }
  if (!baHeaders) {
    baItems.push({
      label: t.P1Y9(loadingWithThreePeriodsMsg),
      html: (
        <div className="of-billing-account-search-result">
          <h4 className="ea-h4">{t.P1Y9(loadingWithThreePeriodsMsg)}</h4>
        </div>
      ),
      value: 'Loading',
    });
  } else {
    baHeaders.forEach(baHeader => {
      const matchedFields = filterMatchedFields(baHeader.matchedFields);
      const {
        billingAccountDisplayId,
        payerName,
        payerNameExtension,
        billingAccountName,
        billingAccountExtensionName,
      } = baHeader.result;
      const label = [
        billingAccountDisplayId,
        payerName,
        payerNameExtension,
        billingAccountName,
        billingAccountExtensionName,
      ]
        .filter(Boolean)
        .join(' ');
      baItems.push({
        label,
        html: (
          <div className="of-billing-account-search-result" key={baHeader.result.billingAccountDisplayId}>
            <h4 className="ea-h4">{label}</h4>
            <div>
              {t.RH6T(billingAccountNameMsg)}: {baHeader.result.billingAccountName}
            </div>
            {baHeader.result.billingAccountExtensionName && (
              <div>
                {t.KUTS(billingAccountExtensionNameMsg)}: {baHeader.result.billingAccountExtensionName}
              </div>
            )}
            <div>
              {matchedFields &&
                searchText &&
                getHighlightedFields(baHeader.result, matchedFields, searchText, billingAccountNonVisibleFields)}
            </div>
          </div>
        ),
        value: baHeader.result.billingAccountId || '',
      });
    });
  }
  return baItems;
};

const getSelectedBaData = (
  prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier,
  selectedBaId?: string
): SelectedBAData => {
  if (prepareNewBillingAccountSaveValues) {
    return { prepareNewBillingAccountSaveValues };
  } else {
    return { selectedBaId };
  }
};

export const AddOrSelectBillingAccounts = ({
  billingAccounts,
  billChannels,
  contacts,
  companyInfo,
  additionalInformation,
  addNewBA,
  getBillingAccountSaveValuesFn,
  isSaving,
  setRefCallBack,
  title,
  selectedBaId,
  disabled,
  createBillingAccountErrors,
}: AddOrSelectBillingAccountsProps) => {
  const billingAccountErrorsFromRedux = useSelector((state: State) => state.selfservice?.billingAccounts?.errors);
  const companyNameFromRedux = useSelector((state: State) => state.selfservice?.companyInfo?.companyName);
  const mdmId = useSearchParams<{ mdmId: string }>().mdmId || getActiveAccountMasterId();
  const filteredBas = billingAccounts.filter(e => isBillingAccountInSfdc(e.result));
  const defaultSelectedBaId = selectedBaId || getDefaultBillingAccountId(filteredBas.map(e => e.result));
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<BillingAccount | undefined>(undefined);
  const [prepareNewBaSaveValuesFn, setPrepareNewBaSaveValuesFn] = useState<BillingAccountOrErrorSupplier | undefined>(
    undefined
  );
  const [selectedBillingAccountId, setSelectedBillingAccountId] = useState(defaultSelectedBaId);

  useEffect(() => {
    getBillingAccountSaveValuesFn(() => getSelectedBaData(prepareNewBaSaveValuesFn, selectedBillingAccountId));
  }, [selectedBillingAccountId, prepareNewBaSaveValuesFn]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fn = async () => {
      const baDisplayId = billingAccounts.find(e => e.result.billingAccountId === selectedBillingAccountId)?.result
        .billingAccountDisplayId;
      if (selectedBillingAccountId && baDisplayId) {
        const res = await fetchBillingAccount(baDisplayId, mdmId!);
        setSelectedBillingAccount(res.billingAccounts?.[0]);
      }
    };
    void fn();
  }, [billingAccounts, selectedBillingAccountId, mdmId]);

  const options = getBillingAccountListItems(addNewBA, filteredBas).map(baAutoCompleteItem => ({
    ...baAutoCompleteItem,
    id: baAutoCompleteItem.value,
  }));
  const selectedBillingAccountOption = options.find(o => o.value === selectedBillingAccountId);
  return (
    <div className="of-add-or-select-billing-accounts-v2">
      <h3 className={`${dsClass.MARGIN_BOTTOM_1} ${dsClass.MARGIN_TOP_0}`}>{title ?? t.RPMR(billingDetailsMsg)}</h3>
      {additionalInformation}
      <div>
        <AutoComplete<BasicAutoCompleteItem>
          async={{
            fetchFn: async (search: string) => {
              const bas = await fetchBillingAccounts({ search: search, useSearchService: true }, { mdmId });
              return getBillingAccountListItems(addNewBA, bas.searchResults ?? [], search);
            },
            loadingMsg: t.KW12(loadingMsg),
            noResultsMsg: t.L7CZ(noSearchResults),
          }}
          config={{
            isSortable: false,
          }}
          defaultOption={selectedBillingAccountOption}
          disabled={disabled}
          getDisplayHtml={i => i.html}
          getDisplayValue={i => i.label}
          getUniqueId={i => i.value}
          id="billingAccounts"
          label={t.HVS2('Selected billing account')}
          noOptionsMsg={t.ZW5W(noSelectionMsg)}
          onInputChange={(_e, item) => {
            if (item.id) {
              if (item.id !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
                setPrepareNewBaSaveValuesFn(undefined);
              }
              setSelectedBillingAccountId(item.id);
            }
          }}
          options={options}
        />
        {selectedBillingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && (
          <CreateBillingAccount
            billChannels={billChannels}
            contacts={contacts || []}
            commonErrors={createBillingAccountErrors || billingAccountErrorsFromRedux}
            getPrepareSaveValuesFn={(prepareSaveValues: BillingAccountOrErrorSupplier) => {
              // `prepareSaveValues` is a function, we have to use the function form of `setState` here to set the value, instead of giving setState a function to evaluate.
              setPrepareNewBaSaveValuesFn(() => prepareSaveValues);
            }}
            payerName={companyInfo?.companyName ?? companyNameFromRedux!}
            isSaving={isSaving}
            setRefCallback={setRefCallBack}
          />
        )}
        {selectedBillingAccountId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && (
          <div className={dsClass.MARGIN_TOP_4} key={selectedBillingAccountId}>
            {selectedBillingAccount ? <BillingAccountInfo billingAccount={selectedBillingAccount} /> : <Loading />}
          </div>
        )}
      </div>
    </div>
  );
};
