import { dsClass } from '../../common/constants/dsClasses.js';
import {
  getAddedAddonRowItem,
  getCampaignDiscountRowItem,
  getCommercialProductRowItem,
  getDeliveryChargesRow,
  getTotalRows,
} from './OrderSummaryUtils.js';
import { groupBy } from '../../common/utils/arrayUtils.js';
import { isSalesProduct } from '../../common/utils/subscriptionUtils';
import { monthlyChargesMsg, oneTimePaymentMsg, quantityMsg, t } from '../../common/i18n/index.js';
import { selectNonOpeningFeeCommercialProductCode } from '../../common/utils/commercialProductUtils.js';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { OrderSummaryProps } from './OrderSummary.js';
import type { RowItem } from './OrderSummaryUtils.js';
import type { SalesType } from '../../generated/api/salesType.js';
import type { TotalSumsItem } from '../../common/utils/commercialProductUtils.js';

import './DetailedSummary.scss';

export interface DetailedSummaryProps extends Omit<OrderSummaryProps, 'salesType'> {
  totalSums: TotalSumsItem;
  salesType: SalesType;
}

interface Column {
  column: 'name' | 'amount' | 'onetimeCharge' | 'monthlyCharge';
  heading?: boolean;
  label: string;
  numeric?: boolean;
}

const getColumns = (): Column[] => [
  { column: 'name', heading: true, label: t.C84H('Product') },
  { column: 'amount', label: t.M0W7(quantityMsg) },
  { column: 'onetimeCharge', label: t.GOBY(oneTimePaymentMsg), numeric: true },
  {
    column: 'monthlyCharge',
    label: t.P6BC(monthlyChargesMsg),
    numeric: true,
  },
];

const createItemRows = (
  commercialProducts: ConfiguredCommercialProduct[],
  salesType: SalesType,
  campaignAssociation?: CampaignAssociation,
  campaignDescription?: string
) => {
  // Group commercial products by the code, so we show only one line for each of them
  const cpMap = groupBy(commercialProducts, cp => {
    // Sales product might include opening fee, which has the same code for all products,
    // so that cannot be used as grouping key
    if (isSalesProduct(cp.commercialProduct)) {
      return selectNonOpeningFeeCommercialProductCode(cp?.commercialProductCodes) as string;
    }
    return cp.commercialProduct.commercialProductCode;
  });

  // For each commercialProduct line, we show all its discounts, addons, etc. directly under it.
  // These will also be summarized as one line for each of the type with amount being 1...n
  return Object.entries(cpMap).flatMap(([cpCode, cpList]) => {
    const commercialProductRow = getCommercialProductRowItem(cpList[0], cpList.length);

    const discounts = cpList.flatMap(
      cp =>
        getCampaignDiscountRowItem(
          cp.commercialProduct,
          salesType,
          campaignDescription || cp.campaignDescription,
          campaignAssociation
        ) || []
    );

    // Filter displayable addons from the CP to be shown to the user in the details section
    const addedAddons = cpList.flatMap(cp => cp.addedAddOns?.filter(addOn => addOn.display ?? true) || []);
    const addedAddonMap = groupBy(addedAddons, addedAddOn => addedAddOn.addOnCode);
    const addedAddOnRows = Object.values(addedAddonMap).flatMap(addedAddOnArray =>
      addedAddOnArray.length ? [getAddedAddonRowItem(cpCode, addedAddOnArray[0], addedAddOnArray.length)] : []
    );

    return [commercialProductRow, ...discounts, ...addedAddOnRows];
  });
};

const TableRows = ({ columns, data }: { columns: Column[]; data: RowItem[] }) => (
  <>
    {data.map(pd => (
      <tr key={pd.key}>
        {columns.map(({ column, heading, label, numeric }) => {
          const Tag = heading ? 'th' : 'td';
          const value = pd[column];
          return (
            <Tag
              className={`${numeric ? dsClass.TABLE_CELL_ALIGN_RIGHT : ''} of-detailed-summary-table__${column} ${
                value === '' ? 'of-detailed-summary-table__cell--no-value' : ''
              }`}
              key={column}
            >
              <span aria-hidden="true">{label}</span>
              {value}
            </Tag>
          );
        })}
      </tr>
    ))}
  </>
);

export const DetailedSummary = (props: DetailedSummaryProps) => {
  const {
    campaignAssociation,
    commercialProducts,
    deliveryCharges,
    hideZeroDeliveryCharges,
    priceIncludesVAT,
    totalSums,
    rowItem,
    campaignDescription,
    salesType,
  } = props;

  const orderContainsBillingPeriodItem = commercialProducts?.some(cp => cp.commercialProduct.billingPeriod);

  const columns = getColumns();
  let bodyData: RowItem[];
  if (commercialProducts) {
    // Add Delivery charges row only once, after all the commercial products and their addons
    bodyData = createItemRows(commercialProducts || [], salesType, campaignAssociation, campaignDescription).concat(
      getDeliveryChargesRow(deliveryCharges, hideZeroDeliveryCharges)
    );
  } else {
    bodyData = rowItem ? [rowItem] : [];
  }
  // In the end of the table, we include a separate section for "total" rows
  const footerData = commercialProducts
    ? getTotalRows(totalSums, priceIncludesVAT, orderContainsBillingPeriodItem)
    : [];

  return (
    <table className={`${dsClass.TABLE} ${dsClass.TABLE_BORDERED} of-detailed-summary-table`}>
      <thead>
        <tr>
          {columns.map(({ column, label, numeric }) => (
            <th className={numeric ? dsClass.TABLE_CELL_ALIGN_RIGHT : ''} key={column}>
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <TableRows columns={columns} data={bodyData} />
      </tbody>
      <tfoot>
        <TableRows columns={columns} data={footerData} />
      </tfoot>
    </table>
  );
};
