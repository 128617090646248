import { Input } from '../Input/Input';
import { dsClass } from '../../common/constants/dsClasses';
import { endTimeMsg, fieldCantBeEmptyMsg, t } from '../../common/i18n';
import { getTextError } from '../../common/react-hook-form/utils';
import { useController, useFormContext } from 'react-hook-form';

import './ScheduledWorkingHours.scss';

export interface WorkingHoursProps {
  namePrefix?: string;
  title: string;
  start?: string;
  end?: string;
  edit?: boolean;
  error?: string;
}
interface TimeInputProps {
  className?: string;
  name: string;
  required: boolean;
  label?: string;
}

const TimeInput = (props: TimeInputProps) => {
  const { control } = useFormContext();
  const { required, name, className, label } = props;
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } },
  });

  return (
    <Input
      className={className}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      ref={field.ref}
      required={required}
      type="time"
      value={field.value || ''}
      error={getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error)}
      label={label}
    />
  );
};

export const WorkingHours = ({ namePrefix = '', title, start, end, edit = false }: WorkingHoursProps) => (
  <>
    <h5 className={`${dsClass.PADDING_BOTTOM_2} ${dsClass.MARGIN_0}`}>{title}</h5>
    <div className="of-pbx-work-hours__time_range">
      <div className="of-pbx-work-hours__time_range__item">
        {edit ? (
          <TimeInput
            required={false}
            name={`${namePrefix}Start`}
            className={`${dsClass.PADDING_RIGHT_2}`}
            label={t.WOWC('Start')}
          />
        ) : (
          <>
            <div className={`${dsClass.PADDING_BOTTOM_2}`}>
              <label className={dsClass.INPUT_LABELAREA_LABEL}>{t.WOWC('Start')}</label>
            </div>
            {start}
          </>
        )}
      </div>
      <div className="of-pbx-work-hours__time_range__item">
        {edit ? (
          <TimeInput
            required={false}
            name={`${namePrefix}End`}
            className={`${dsClass.PADDING_LEFT_2}`}
            label={t.LQ6I('End')}
          />
        ) : (
          <>
            <div className={`${dsClass.PADDING_BOTTOM_2}`}>
              <label className={dsClass.INPUT_LABELAREA_LABEL}>{t.LQ6I(endTimeMsg)}</label>
            </div>
            {end}
          </>
        )}
      </div>
    </div>
  </>
);
