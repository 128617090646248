import * as CL from '@design-system/component-library';
import { RespondentList } from './RespondentList';
import { ScheduledWorkingHours } from './ScheduledWorkingHours';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails';
import { activeChainItems, numberConversionOptions, resolveBusyActionLocalization } from './PbxAccordionUtils';
import { dsClass } from '../../common/constants/dsClasses';
import { editMsg, extensionMsg, numberConversionToBeDisplayedMsg, numberToDisplayMsg, t } from '../../common/i18n';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import type { PbxSettingFormData } from './PbxAccordionUtils';

export interface PbxDetailsViewProps {
  formValues: PbxSettingFormData;
  onEdit: () => void;
}

interface PbxDetailsViewFieldProps {
  label: string;
  value?: string;
}

const PbxDetailsViewField = ({ label, value }: PbxDetailsViewFieldProps) => (
  <>
    <label className={dsClass.INPUT_LABELAREA_LABEL}> {label}</label>
    <div className={`${dsClass.PADDING_TOP_1} ${dsClass.PADDING_BOTTOM_1}`}>{value}</div>
  </>
);

export const PbxDetailsView = ({ formValues, onEdit }: PbxDetailsViewProps) => {
  const outOfOfficeNumbers = formValues?.offWorkReachabilityChain || [];
  const workingTimeNumbers = formValues.workingHoursReachabilityChain || [];

  const showCallerLineIdNumber =
    formValues.callerLineIdMasking !== SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE;
  const showWorkingHours = formValues.currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR;

  return (
    <div className="of-pbx-accordion-content">
      <CL.Grid className={dsClass.PADDING_TOP_4}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <PbxDetailsViewField
              label={t.O1MZ(numberConversionToBeDisplayedMsg)}
              value={
                numberConversionOptions(formValues.pbxType).find(
                  option => option.value === formValues.callerLineIdMasking
                )?.label ?? '-'
              }
            />
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            {showCallerLineIdNumber && (
              <PbxDetailsViewField
                label={t.FL34(numberToDisplayMsg)}
                value={formValues.callerLineIdNumber ? formatPhoneNumber(formValues.callerLineIdNumber, true) : '-'}
              />
            )}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_7}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12}>
            <h4 className={`${dsClass.MARGIN_BOTTOM_0} ${dsClass.MARGIN_TOP_0}`}>
              {t.YN14('Reachability of company number')}
            </h4>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol className={dsClass.PADDING_TOP_4} colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <PbxDetailsViewField
              label={t.MM8Z('Active state')}
              value={activeChainItems().find(item => item.value === formValues.currentActiveChain)?.label ?? '-'}
            />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      {showWorkingHours && (
        <CL.Grid className={dsClass.PADDING_TOP_6}>
          <CL.GridRow>
            <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12}>
              <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER}`}>
                <h4 className={`${dsClass.MARGIN_TOP_0} ${dsClass.MARGIN_BOTTOM_0} ${dsClass.PADDING_RIGHT_2}`}>
                  {t.Z40V('Scheduled office hours for company number')}
                </h4>
                <CL.Tooltip
                  triggerElement={
                    <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                  }
                  placement="top"
                  i18n_tooltip_contentText={t.NKFF(
                    'Select if the active state for the company number should be during office hours, outside of office hours, or according to scheduled office hours. ' +
                      'If you select the last option, you can set the time range when office-hour respondents are used. Outside of these hours, out-of-office respondents are used. ' +
                      'If you want to use the out-of-office respondents for the entire weekend, set the time to 00.00 - 00.00.'
                  )}
                />
              </div>
            </CL.GridCol>
          </CL.GridRow>
          <CL.GridRow className={dsClass.PADDING_TOP_6}>
            <ScheduledWorkingHours formValues={formValues} />
          </CL.GridRow>
        </CL.Grid>
      )}

      <CL.Grid className={dsClass.PADDING_TOP_8}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <div className={`${dsClass.PADDING_BOTTOM_3} ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} `}>
              <h5 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_RIGHT_2}`}>{t.L6G9('Office-hour respondents')}</h5>
              <CL.Tooltip
                triggerElement={
                  <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                }
                placement="top"
                i18n_tooltip_contentText={t.XGRM(
                  'The company number has two available chains (for office hours and out-of-office hours). You can add four respondents to both chains.'
                )}
              />
            </div>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <RespondentList numbers={workingTimeNumbers} />
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_8}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <div className={`${dsClass.PADDING_BOTTOM_3} ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} `}>
              <h5 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_RIGHT_2}`}>
                {t.D14J('Out-of-office respondents')}
              </h5>
              <CL.Tooltip
                triggerElement={
                  <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                }
                placement="top"
                i18n_tooltip_contentText={t.XGRM(
                  'The company number has two available chains (for office hours and out-of-office hours). You can add four respondents to both chains.'
                )}
              />
            </div>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <RespondentList numbers={outOfOfficeNumbers} />
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_6}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <PbxDetailsViewField
              label={t.MW58('When respondent is busy')}
              value={resolveBusyActionLocalization(formValues.busyAction, formValues.connectToNumber)}
            />
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <div className={`${dsClass.PADDING_BOTTOM_1} ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} `}>
              <label className={dsClass.INPUT_LABELAREA_LABEL}>{t.NG9B('Callout time')}</label>
              <CL.Tooltip
                triggerElement={
                  <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                }
                placement="top"
                i18n_tooltip_contentText={t.P04G('Define how long a call rings on a single respondent.')}
              />
            </div>
            <div className={`${dsClass.PADDING_TOP_1} ${dsClass.PADDING_BOTTOM_1}`}>
              {`${formValues.delayForChainCalls} s`}
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_7}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12}>
            <h4 className={`${dsClass.MARGIN_BOTTOM_1}  ${dsClass.MARGIN_TOP_0}`}>{t.AN17('Number settings')}</h4>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow className={`${dsClass.PADDING_TOP_4}`}>
          <CL.GridCol colsS={6} colsM={3} colsL={6} colsXL={6}>
            <PbxDetailsViewField
              label={t.D9TG('Company number')}
              value={formatPhoneNumber(formValues.corporateNumber, true)}
            />
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <PbxDetailsViewField label={t.NO66(extensionMsg)} value={formValues.extensionNumber} />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      <div className={dsClass.PADDING_TOP_8}>
        <CL.Link
          className={dsClass.FONT_SIZE_MEDIUM}
          linkTarget="_blank"
          linkHref="https://yrityksille.elisa.fi/ohjeet/vakio-vinkit"
        >
          {t.JT5W('See Puheratkaisu Vakio info and tips from here')}
          <CL.Icon icon="external-link" />
        </CL.Link>
      </div>

      <div className={`${dsClass.PADDING_BOTTOM_3} ${dsClass.PADDING_TOP_6} ${dsClass.PADDING_LEFT_0}`}>
        <CL.Button
          color="light"
          onClick={() => {
            onEdit();
          }}
        >
          {t.NVPK(editMsg)}
        </CL.Button>
      </div>
    </div>
  );
};
