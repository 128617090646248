import * as CL from '@design-system/component-library';
import { AuthenticatedUserRole } from '../../../generated/api/authenticatedUserRole';
import { BillingAccount } from '../../../common/react-hook-form/fields/BillingAccount';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  getDefaultBillingContactId,
  getDefaultDeliveryMethod,
} from '../../../common/utils/billingAccountUtils';
import { ChangeOwnerError } from '../../../generated/api/changeOwnerError';
import { CompanySelector } from '../../../components/CompanySelector/CompanySelector';
import { Contact as ContactInput } from '../../../common/react-hook-form/fields/Contact';
import { DialogType, SubscriptionCategory } from '../../../common/enums';
import { FormProvider, useForm } from 'react-hook-form';
import { GenericInfoDialog } from '../../../components/Dialogs/GenericInfoDialog';
import {
  I18nTextLinkEmbedded,
  billingAccountChangeCompletedMsg,
  billingAccountChangeFailedMsg,
  billingAccountMsg,
  cancelMsg,
  confirmMsg,
  editMsg,
  t,
  userTextMsg,
} from '../../../common/i18n';
import { LinkableAccordion } from '../../../components/LinkableAccordion';
import { PurposeOfUse } from '../../../common/react-hook-form/fields/PurposeOfUse';
import { SourceSystem } from '../../../generated/api/sourceSystem';
import { SubscriptionPbxDetails } from '../../../generated/api/subscriptionPbxDetails';
import { SubscriptionType } from '../../../generated/api/subscriptionType';
import {
  changeSubscriptionBillingAccount,
  fetchBillingAccounts,
  fetchContacts,
  postChangeOwnerAccount,
} from '../../../common/fetch';
import { dsClass } from '../../../common/constants/dsClasses';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionTypes } from '../../../public/common/util/category';
import { getUserAccounts } from '../../../components/Header/dynamic/headerFunctions';
import { isOneTimeFeeDevice } from '../subscriptionDetailsUtils';
import { isSubscriptionInSfdc } from '../../../common/utils/subscriptionUtils';
import { paths } from '../../../common/constants/pathVariables';
import { showDialog, startNotification } from '../../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import type { Accordion } from '../../../common/utils/accordionUtils';
import type { BillingAccountsResponse } from '../../../generated/api/billingAccountsResponse';
import type { CompanyInfoState, RealAuthenticatedUserState } from '../../../common/types/states';
import type { Contact } from '../../../generated/api/contact';
import type { GenericError } from '../../../generated/api/genericError';
import type { Subscription } from '../../../generated/api/subscription';
import type { SubscriptionStatus } from '../../../common/types/subscription';

import './ChangeContractOwnerAccordion.scss';

export interface ChangeContractOwnerAccordionProps {
  authenticatedUser?: RealAuthenticatedUserState;
  companyInfo?: CompanyInfoState;
  subscription: Subscription;
  billingAccounts?: BillingAccountsResponse;
  contacts?: Contact[];
  subscriptionStatus?: SubscriptionStatus;
}

type ChangeContractOwnerAccordionEditorProps = Pick<
  ChangeContractOwnerAccordionProps,
  'authenticatedUser' | 'billingAccounts' | 'subscription' | 'contacts'
> & { closeEditor: () => void };

const isAdmin = (user?: RealAuthenticatedUserState) =>
  user && (user.userRole === AuthenticatedUserRole.ADMIN || user.userRole === AuthenticatedUserRole.KEY_USER);

const loadBillingAccountsContacts = async (targetAccount: string) => {
  const [basForCompany, contactsForCompany] = await Promise.all([
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId: targetAccount }),
    fetchContacts({ offset: 0 }, { mdmId: targetAccount }),
  ]);
  return {
    basForCompany,
    contactsForCompany: contactsForCompany.contacts,
  };
};

const postChangeBillingAccount = async (targetBillingAccount: string, subscriptionId: string, mdmId: string) => {
  try {
    const subscriptionAction = await changeSubscriptionBillingAccount(subscriptionId, targetBillingAccount, mdmId);
    return {
      subscriptionAction,
    };
  } catch (error) {
    const errorResponse = await error.json();
    return {
      changeBillingAccountError: {
        status: error.status,
        ...(errorResponse[0] as GenericError),
      },
    };
  }
};

const postChangeContractOwner = async (
  targetAccount: string,
  targetBillingAccount: string,
  targetContact: Contact | undefined,
  purposeOfUse: string | undefined,
  subscriptionId: string,
  mdmId: string
) => {
  try {
    const subscriptionAction = await postChangeOwnerAccount(
      subscriptionId,
      {
        targetAccount,
        targetBillingAccount,
        targetContactDetails: {
          contactId: targetContact?.contactId,
          purposeOfUse,
        },
        subscriptionReference: targetContact?.person?.employeeNumber,
        costCenter: targetContact?.person?.costCenter,
      },
      mdmId
    );

    return {
      subscriptionAction,
    };
  } catch (error) {
    const errorResponse = await error.json();
    if (errorResponse[0].reason) {
      return {
        changeOwnerErrorResponse: errorResponse[0] as ChangeOwnerError,
      };
    }
    return {
      commonErrorResponse: errorResponse[0] as GenericError,
    };
  }
};

const ChangeContractOwnerErrorDialog = (props: { header: string; body: JSX.Element }) => {
  const [open, setOpen] = useState(true);
  return open ? (
    <GenericInfoDialog onCloseDialog={() => setOpen(false)} header={props.header} body={props.body} />
  ) : null;
};

interface ChangeOwnerErrorMessageElementProps {
  error: ChangeOwnerError | undefined | null;
  search: string;
}

const ChangeOwnerErrorMessageElement = ({ error, search }: ChangeOwnerErrorMessageElementProps) => {
  switch (error?.reason) {
    case ChangeOwnerError.ReasonEnum.INVALID_EPP_AGREEMENT:
      return (
        <ChangeContractOwnerErrorDialog
          key={error.reason}
          header={t.HJJ3('Contracting company can not be changed')}
          body={
            <>
              <p>
                {t.TYVC('Contracting company can not be changed, because: ')}
                <>
                  <li className={`${dsClass.MARGIN_LEFT_2}`}>
                    {t.WKIN(
                      'The target company does not have a corresponding Elisa Palvelupäätelaite (EPP) service agreement'
                    )}
                  </li>
                </>
              </p>
              <p>
                <I18nTextLinkEmbedded
                  translation={t.UJ8H('Create a [support case], if needed.')}
                  linkTo={paths.SUPPORT_CASE_NEW + search}
                />
              </p>
            </>
          }
        />
      );
    case ChangeOwnerError.ReasonEnum.EPP_OMA_BA:
      return (
        <ChangeContractOwnerErrorDialog
          key={error.reason}
          header={t.HJJ3('Contracting company can not be changed')}
          body={
            <>
              <p>
                {t.L9E5(
                  'Contracting company cannot be changed, because the user of the device has an active Elisa Oma Laitelasku agreement.'
                )}
              </p>
              <p>
                <I18nTextLinkEmbedded
                  translation={t.KOO8(
                    'If you want to transfer the user to another company, you can do that on [Users] page.'
                  )}
                  linkTo={paths.COMPANY_INFO_CONTACTS}
                />
              </p>
            </>
          }
        />
      );
    case ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING:
      return (
        <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
          <CL.GridCol colWidth={12}>
            <CL.Disclaimer
              title={t.HJJ3('Contracting company can not be changed')}
              icon={<CL.Icon icon="information" size="s" />}
              visible={true}
            >
              <span>
                <I18nTextLinkEmbedded
                  translation={t.SX7R(
                    'Contracting company can not be changed, because additional service prevents the change. Create a [support case], if needed.'
                  )}
                  linkTo={paths.SUPPORT_CASE_NEW + search}
                />
              </span>
            </CL.Disclaimer>
          </CL.GridCol>
        </CL.GridRow>
      );
    case ChangeOwnerError.ReasonEnum.COLLECTION:
      return (
        <ChangeContractOwnerErrorDialog
          key={error.reason}
          header={t.HJJ3('Contracting company can not be changed')}
          body={
            <p>
              <I18nTextLinkEmbedded
                translation={t.W7H8('Please contact our [customer service] if needed.')}
                linkTo={paths.CONTACT_INFO}
              />
            </p>
          }
        />
      );
    default:
      return null;
  }
};

const validateSubscriptionForChangeOwner = (subscription: Subscription): ChangeOwnerError | null => {
  if (SubscriptionType.MOBILE === subscription.subscriptionType) {
    if (
      SubscriptionPbxDetails.PbxTypeEnum.VAKIO ===
        subscription.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails?.pbxType &&
      !!subscription.details?.mobile?.pbxConfiguration?.corporateNumber &&
      !!subscription.details?.mobile?.pbxConfiguration?.extensionNumber
    ) {
      return {
        reason: ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING,
      };
    } else if (
      SubscriptionPbxDetails.PbxTypeEnum.RING ===
      subscription.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails?.pbxType
    ) {
      return {
        reason: ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING,
      };
    }
  } else if (SubscriptionType.SPECIAL_NUMBER === subscription.subscriptionType) {
    return {
      reason: ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING,
    };
  } else if (SubscriptionType.MOBILE_PBX_LITE === subscription.subscriptionType) {
    return {
      reason: ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING,
    };
  } else if (SubscriptionType.MOBILE_PBX === subscription.subscriptionType) {
    return {
      reason: ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING,
    };
  }
  return null;
};

const shouldDisableField = (error?: ChangeOwnerError | null) => {
  return ChangeOwnerError.ReasonEnum.VOICE_VAKIO_OR_RING === error?.reason;
};

const ChangeContractOwnerAccordionEditor = ({
  authenticatedUser,
  billingAccounts,
  subscription,
  contacts,
  closeEditor,
}: ChangeContractOwnerAccordionEditorProps) => {
  const dispatch = useDispatch();
  const [targetAccountId, setTargetAccountId] = useState<string>();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = useSearchParams<{ mdmId?: string }>();
  const [baResponseForTargetCompany, setBaResponseForTargetCompany] = useState<BillingAccountsResponse>();
  const [contactsForTargetCompnay, setContactsForTargetCompnay] = useState<Contact[]>();
  const [changeOwnerError, setChangeOwnerError] = useState<ChangeOwnerError>();

  const methods = useForm({
    defaultValues: {
      changeContractOwner: {
        targetBillingAccount: subscription.billingAccountId || '',
        targetContact: '',
        targetPurposeOfUse: subscription.subscriptionPurposeOfUse,
      },
      key: 0,
    },
  });

  const key = methods.watch('key');

  useEffect(() => {
    const { unsubscribe } = methods.watch(({ changeContractOwner }) => {
      if (changeContractOwner?.targetBillingAccount === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
        const params = new URLSearchParams(searchParams);
        params.append('subscriptionId', subscription.subscriptionId);
        const baResults = billingAccounts?.searchResults?.map(sr => sr.result);
        navigate(generatePath(`${paths.BILLING_ACCOUNTS_CREATE_NEW_BA}?${params}`), {
          state: {
            redirectPath: pathname + search,
            hideDisclaimerText: true,
            defaultBillingContactId: getDefaultBillingContactId(baResults),
            defaultDeliveryMethod: getDefaultDeliveryMethod(baResults),
          },
        });
      }
    });
    return () => unsubscribe();
  }, [methods, navigate, pathname, search, searchParams, subscription.subscriptionId, billingAccounts?.searchResults]);

  const isChangeContractOwner = !!targetAccountId && targetAccountId !== searchParams.mdmId;
  const isPurposeOfUseSubscription = !subscription.subscriptionContactName && !subscription.subscriptionContactId;
  const subscriptionValidationErrors = validateSubscriptionForChangeOwner(subscription);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(async values => {
          if (isChangeContractOwner) {
            const selectedContact = contactsForTargetCompnay?.find(
              c => c.contactId === values.changeContractOwner.targetContact
            );
            const { changeOwnerErrorResponse, commonErrorResponse } = await postChangeContractOwner(
              targetAccountId,
              values.changeContractOwner.targetBillingAccount,
              selectedContact,
              isPurposeOfUseSubscription ? values.changeContractOwner.targetPurposeOfUse : undefined,
              subscription.subscriptionId,
              searchParams.mdmId || ''
            );
            if (changeOwnerErrorResponse) {
              setChangeOwnerError(changeOwnerErrorResponse);
            } else if (commonErrorResponse) {
              dispatch(startNotification(t.ORRM('Submit change order failed'), 'error'));
            } else {
              dispatch(startNotification(t.E8T5('Submit change order successful'), 'success'));
              navigate(`?mdmId=${searchParams.mdmId}`, { replace: true });
              closeEditor();
            }
          } else {
            const { changeBillingAccountError } = await postChangeBillingAccount(
              values.changeContractOwner.targetBillingAccount,
              subscription.subscriptionId,
              searchParams.mdmId || ''
            );
            if (changeBillingAccountError) {
              dispatch(startNotification(t.S3DX(billingAccountChangeFailedMsg), 'error'));
            } else {
              dispatch(startNotification(t.CTBU(billingAccountChangeCompletedMsg), 'success'));
              navigate(`?mdmId=${searchParams.mdmId}`, { replace: true });
              closeEditor();
            }
          }
        })}
      >
        <CL.Grid>
          <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
            <CL.GridCol colWidth={6}>
              <CompanySelector
                labelText={t.HCIE('Contract party')}
                disabled={shouldDisableField(changeOwnerError || subscriptionValidationErrors)}
                userAccounts={getUserAccounts(authenticatedUser)}
                initialMdmId={searchParams.mdmId}
                onInputChange={async (_, option) => {
                  setTargetAccountId(option.accountMasterId);
                  methods.setValue('changeContractOwner.targetBillingAccount', '');
                  methods.setValue('changeContractOwner.targetContact', '');
                  methods.setValue('key', key + 1);
                  const { basForCompany, contactsForCompany } = await loadBillingAccountsContacts(
                    option.accountMasterId || ''
                  );
                  setBaResponseForTargetCompany(basForCompany);
                  setContactsForTargetCompnay(contactsForCompany);
                }}
              />
            </CL.GridCol>
          </CL.GridRow>
          <ChangeOwnerErrorMessageElement error={changeOwnerError || subscriptionValidationErrors} search={search} />
          {isChangeContractOwner && (
            <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
              <CL.GridCol colWidth={12}>
                <CL.Disclaimer icon={<CL.Icon icon="information" size="s" />} visible={true}>
                  <span>
                    {isPurposeOfUseSubscription ? (
                      t.M16R(
                        'You are changing the contracting company. The billing account must also be updated according to the new contracting company.'
                      )
                    ) : (
                      <I18nTextLinkEmbedded
                        translation={t.CPDO(
                          'You are changing the contracting company. The billing account and user must also be updated according to the new contracting company. If you want to move the user to another company, you can do so on the [Users] page.'
                        )}
                        linkTo={paths.COMPANY_INFO_CONTACTS}
                      />
                    )}
                  </span>
                </CL.Disclaimer>
              </CL.GridCol>
            </CL.GridRow>
          )}
          <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
            <CL.GridCol colWidth={6}>
              <BillingAccount
                name="changeContractOwner.targetBillingAccount"
                enableAddNewBa={!isChangeContractOwner}
                billingAccounts={
                  (isChangeContractOwner && baResponseForTargetCompany?.searchResults) ||
                  billingAccounts?.searchResults ||
                  []
                }
              />
            </CL.GridCol>
          </CL.GridRow>
          {isChangeContractOwner && !isPurposeOfUseSubscription && (
            <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
              <CL.GridCol colWidth={6}>
                <ContactInput
                  labelText={t.U4MA(userTextMsg)}
                  enableAddNewContact={false}
                  reference=""
                  name="changeContractOwner.targetContact"
                  contacts={(isChangeContractOwner && contactsForTargetCompnay) || contacts || []}
                />
              </CL.GridCol>
            </CL.GridRow>
          )}
          {isChangeContractOwner && isPurposeOfUseSubscription && (
            <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
              <CL.GridCol colWidth={6}>
                <PurposeOfUse name="changeContractOwner.targetPurposeOfUse" />
              </CL.GridCol>
            </CL.GridRow>
          )}
        </CL.Grid>
        <CL.ButtonGroup align="left">
          <CL.Button type="submit">{t.QVYK(confirmMsg)}</CL.Button>
          <CL.Button
            type="reset"
            color="light"
            onClick={() => {
              methods.reset();
              setTargetAccountId(undefined);
              closeEditor();
            }}
          >
            {t.B2V1(cancelMsg)}
          </CL.Button>
        </CL.ButtonGroup>
      </form>
    </FormProvider>
  );
};

const ChangeContractOwnerAccordionContent = ({
  authenticatedUser,
  companyInfo,
  subscription,
  billingAccounts,
  contacts,
  subscriptionStatus,
}: ChangeContractOwnerAccordionProps) => {
  const dispatch = useDispatch();
  const [isEditing, setEditing] = useState(false);

  if (isEditing) {
    return (
      <ChangeContractOwnerAccordionEditor
        authenticatedUser={authenticatedUser}
        billingAccounts={billingAccounts}
        subscription={subscription}
        contacts={contacts}
        closeEditor={() => setEditing(!isEditing)}
      />
    );
  }

  return (
    <>
      <CL.Grid>
        <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
          <CL.GridCol colWidth={12}>
            <div>
              <span className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
                {t.HCIE('Contract party')}
              </span>
            </div>
            <div>
              <span>{companyInfo?.companyName ?? '—'}</span>
            </div>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow gutter={false} className="of-change-owner-accordion--data-row">
          <CL.GridCol colWidth={12}>
            <div>
              <span className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
                {t.IFT9(billingAccountMsg)}
              </span>
            </div>
            <div>
              <span>{subscription.billingAccountDisplayId ?? '—'}</span>
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
      {isSubscriptionInSfdc(subscription) &&
        !isOneTimeFeeDevice(subscription) &&
        !getSubscriptionTypes(SubscriptionCategory.SERVICE).includes(subscription.subscriptionType) && (
          <CL.Button
            color="light"
            onClick={() => {
              if (subscriptionStatus?.pendingActions) {
                dispatch(showDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING }));
              } else {
                setEditing(!isEditing);
              }
            }}
          >
            {t.NVPK(editMsg)}
          </CL.Button>
        )}
    </>
  );
};

export const ChangeContractOwnerAccordion = ({
  authenticatedUser,
  companyInfo,
  subscription,
  billingAccounts,
  contacts,
  subscriptionStatus,
}: ChangeContractOwnerAccordionProps) => {
  if (isAdmin(authenticatedUser)) {
    return (
      <LinkableAccordion
        heading={t.Q9D2('Contract party and billing account')}
        id="change-contract-owner"
        headingLevel="h3"
      >
        <ChangeContractOwnerAccordionContent
          authenticatedUser={authenticatedUser}
          companyInfo={companyInfo}
          subscription={subscription}
          billingAccounts={billingAccounts}
          contacts={contacts}
          subscriptionStatus={subscriptionStatus}
        />
      </LinkableAccordion>
    );
  }

  return null;
};

export const getChangeContractOwnerAccordion = ({
  authenticatedUser,
  companyInfo,
  subscription,
  billingAccounts,
  contacts,
  subscriptionStatus,
}: ChangeContractOwnerAccordionProps): Accordion => ({
  headerName: t.Q9D2('Contract party and billing account'),
  id: 'change-contract-owner',
  includedData: (
    <ChangeContractOwnerAccordionContent
      authenticatedUser={authenticatedUser}
      companyInfo={companyInfo}
      subscription={subscription}
      billingAccounts={billingAccounts}
      contacts={contacts}
      subscriptionStatus={subscriptionStatus}
    />
  ),
  displayed: isAdmin(authenticatedUser) || false,
});
