import { TextArea } from '../../../components/TextArea/TextArea.js';
import { fieldCantBeEmptyMsg, t } from '../../i18n/index.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import type { ReactNode } from 'react';

interface TextAreaComponentProps {
  children?: ReactNode;
  disabled: boolean;
  hint?: string;
  label?: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  rows?: number;
  validate?: (value: string, values?: object) => string | undefined;
  wrapperClassName?: string;
}

export const TextAreaComponent = (props: TextAreaComponentProps) => {
  const { control } = useFormContext();
  const { validate, ...rest } = props;
  const {
    disabled,
    hint,
    label,
    maxLength,
    name,
    placeholder,
    readonly,
    required = false,
    rows = 3,
    wrapperClassName,
  } = rest;
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: { required: { value: required, message: t.VPVR(fieldCantBeEmptyMsg) }, validate },
  });

  return (
    <TextArea
      disabled={disabled}
      error={getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error)}
      hint={hint}
      label={label}
      maxLength={maxLength}
      name={name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      placeholder={placeholder}
      readOnly={readonly}
      ref={field.ref}
      rows={rows}
      value={field.value}
      wrapperClassName={wrapperClassName}
    />
  );
};
