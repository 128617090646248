import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Input } from '../../../components/Input/Input.js';
import {
  checkAvailabilityMsg,
  chooseOneProductMsg,
  domainCheckFailedMsg,
  domainErrorMsg,
  domainLabelMsg,
  domainTitleMsg,
  domainsMsg,
  fieldCantBeEmptyMsg,
  freeMsg,
  reservedMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fetchDomains } from '../../../common/fetch.js';
import { formatPrice } from '../../../components/ProductDetails/utils/formatters.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { getTextError } from '../../../common/react-hook-form/utils.js';
import { updatePrices } from '../CustomFormFields.js';
import { useController, useFormContext } from 'react-hook-form';
import { useDeepEffect, usePrevious } from '../../../common/formik/utils.js';
import { useState } from 'react';
import type { CommercialProduct, Domain, Price } from '../../../generated/api/models.js';

export const DOMAINS_KEY = 'domains';
export const DOMAIN_SEARCH_KEY = 'domainSearch';

interface CustomFormDomainSearchProps {
  price: Price;
}

interface DomainCheckboxState {
  checked: boolean;
  disabled: boolean;
  value: string;
}

interface DomainCheckboxProps extends DomainCheckboxState {
  children: string | JSX.Element;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DomainSelectionProps {
  domainSearchResult: Domain[];
  handleChange: (value: boolean) => void;
  price?: Price;
}

export const DomainCheckbox = (componentProps: DomainCheckboxProps) => {
  const { checked, children, disabled, value, onChange } = componentProps;
  return (
    <div
      className={`of-formik-checkbox of-formik-checkbox-${disabled ? 'inactive' : 'active'} ${dsClass.MARGIN_BOTTOM_3}`}
    >
      <CL.Checkbox id={value} value={value} disabled={disabled} checked={checked} onChange={onChange}>
        {children}
      </CL.Checkbox>
    </div>
  );
};

export const DomainSelection = ({ domainSearchResult, price, handleChange }: DomainSelectionProps) => {
  const {
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [domains, setDomains] = useState<Array<DomainCheckboxState>>([]);
  const ref = usePrevious(domains);

  /**
   * When new search is made and results change
   * use ref to filter checked domains
   * remove those checked values from new results
   * combine and the new list of results is ready
   */
  useDeepEffect(() => {
    const previous = ref?.filter(item => item.checked) || [];
    const current = domainSearchResult
      ?.map(({ domainName, isAvailable }) => ({
        checked: false,
        disabled: !isAvailable,
        value: domainName,
      }))
      .filter(i => !previous.find(j => j.value === i.value));
    setDomains([...previous, ...current]);
  }, [domainSearchResult]);

  /**
   * Set domains field, which is comma separated list of selected domains
   * Field doesn't need validation because user can't break it
   */
  const setDomainsField = (domainList: Array<DomainCheckboxState>) => {
    const value = domainList
      .filter(item => item.checked)
      .map(item => item.value)
      .join(', ');
    setValue(DOMAINS_KEY, value);
    if (value) {
      clearErrors(DOMAINS_KEY);
    }
  };

  /**
   * Toggle checked value and call create new array with filter
   */
  const toggleDomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainsField(
      [...domains]
        .map(item => {
          if (item.value === e.target.value) {
            item.checked = !item.checked;
          }
          return item;
        })
        .filter(item => item.checked)
    );
  };

  if (domains.length === 0) {
    return null;
  }

  return (
    <>
      <div className={dsClass.MARGIN_VERTICAL_3}>
        <h3 className={dsClass.H4}>{t.WGE3(domainsMsg)}</h3>
      </div>
      <input type="hidden" {...register(DOMAINS_KEY, { required: true })} />
      <div>
        {domains.map(({ checked, disabled, value }, i) => {
          return (
            <DomainCheckbox
              key={value + i}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                toggleDomain(e);
                handleChange(!checked);
              }}
              checked={checked}
              disabled={disabled}
              value={value}
            >
              <div className="checkbox-content">
                <span>{value}</span>
                <span>
                  <span>{disabled ? t.SST5(reservedMsg) : t.BWTQ(freeMsg)}</span>
                  {price?.monthlyRecurringCharge ? <span>{` ${formatPrice(price as CommercialProduct)}`}</span> : null}
                  {price?.oneTimeCharge ? <span>{` ${formatSum(price.oneTimeCharge)}`}</span> : null}
                </span>
              </div>
            </DomainCheckbox>
          );
        })}
        {errors.domains && <span className={dsClass.COLOR_RED_600}>{t.OW64(chooseOneProductMsg)}</span>}
      </div>
    </>
  );
};

export const CustomFormDomainSearch = ({ price }: CustomFormDomainSearchProps) => {
  const translation = t.Q6TC(domainLabelMsg);
  const { setValue, getValues, control } = useFormContext();
  const [domains, setDomains] = useState<Array<Domain>>([]);
  const [loading, setLoading] = useState(false);
  const [searchFailed, setSearchFailed] = useState(false);

  /**
   * Set domain input touched and validate domain
   *
   * Make domain search if domain input value is valid
   */
  const searchDomain = async () => {
    const searchString = getValues(DOMAIN_SEARCH_KEY);
    setSearchFailed(false);
    const result = await fetchDomains(searchString);
    if (result.error) {
      setSearchFailed(true);
    } else {
      setDomains(result);
    }
    setLoading(false);
  };

  const handleChange = (checked: boolean) => {
    const periodicPriceMap = getValues('periodicPriceMap');
    const onetimePriceMap = getValues('onetimePriceMap');
    const incrementOrDecrementFn = checked ? (a = 0, b = 0) => a + b : (a = 0, b = 0) => a - b;
    const periodicPrice = incrementOrDecrementFn(periodicPriceMap.get(DOMAINS_KEY), price.monthlyRecurringCharge);
    const onetimePrice = incrementOrDecrementFn(onetimePriceMap.get(DOMAINS_KEY), price.oneTimeCharge);
    if (price.monthlyRecurringCharge) {
      periodicPriceMap.set(DOMAINS_KEY, periodicPrice);
    }
    if (price.oneTimeCharge) {
      onetimePriceMap.set(DOMAINS_KEY, onetimePrice);
    }
    updatePrices(setValue, periodicPriceMap, onetimePriceMap);
  };

  const validate = (value: string) => {
    const domainParts = value.replace('www.', '').split('.');
    if (!(domainParts.length === 2 && domainParts[0].length > 0 && domainParts[1].length > 0)) {
      return t.R3RM(domainErrorMsg);
    }
    return undefined;
  };

  const { field, fieldState, formState } = useController({
    name: DOMAIN_SEARCH_KEY,
    defaultValue: '',
    control,
    rules: { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) }, validate },
  });
  return (
    <div className="of-formik__domain-search">
      <h3 className={dsClass.H4}>{t.WPEL(domainTitleMsg)}</h3>
      <div>
        <Input
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          ref={field.ref}
          placeholder=""
          name={DOMAIN_SEARCH_KEY}
          type="text"
          label={translation}
          error={getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        {searchFailed && <span className={dsClass.COLOR_RED_600}>{t.IP2H(domainCheckFailedMsg)}</span>}
        <CL.Button
          disabled={false}
          loading={loading}
          onClick={() => {
            setLoading(true);
            searchDomain().then();
          }}
        >
          {t.U1QE(checkAvailabilityMsg)}
        </CL.Button>
      </div>
      <DomainSelection handleChange={handleChange} price={price} domainSearchResult={domains}></DomainSelection>
    </div>
  );
};
