import { Input } from '../Input/Input.js';
import { convertValueFormatToDisplayFormat } from './pbxWorkingHoursUtil';
import { dsClass } from '../../common/constants/dsClasses.js';
import { endTimeMsg, fieldCantBeEmptyMsg, t } from '../../common/i18n/index.js';
import { getTextError } from '../../common/react-hook-form/utils.js';
import { useController, useFormContext } from 'react-hook-form';
import type { CommonError } from '../../common/types/errors.js';

export interface TimeRangeProps {
  title: string;
  namePrefix: string;
  editing?: boolean;
  start?: string;
  end?: string;
  errors?: CommonError[];
}

interface TimeInputProps {
  className?: string;
  name: string;
  required: boolean;
}

const TimeInput = (props: TimeInputProps) => {
  const { control } = useFormContext();
  const { required, name, className } = props;
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } },
  });

  return (
    <Input
      className={className}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      ref={field.ref}
      required={required}
      type="time"
      value={field.value || ''}
      error={getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error)}
    />
  );
};

export const TimeRange = ({ title, namePrefix, editing, start, end, errors }: TimeRangeProps) => (
  <div>
    <div className="of-pbx-work-hours__range-name">{title}</div>
    <div className="of-pbx-work-hours__range">
      <div className="of-pbx-work-hours__range-element">
        <span className={`${dsClass.TEXT_EXTRA_SMALL} ${dsClass.COLOR_NEUTRAL_500} of-pbx-work-hours__disclaimer`}>
          {t.WOWC('Start')}
        </span>
        {editing ? (
          <TimeInput required={false} name={`${namePrefix}Start`} />
        ) : (
          <div>{convertValueFormatToDisplayFormat(start)}</div>
        )}
      </div>
      <div
        className={`of-pbx-work-hours__range-delimiter ${editing ? 'of-pbx-work-hours__range-delimiter__editing' : ''}`}
      >
        &mdash;
      </div>
      <div className="of-pbx-work-hours__range-element">
        <span className={`${dsClass.TEXT_EXTRA_SMALL} ${dsClass.COLOR_NEUTRAL_500} of-pbx-work-hours__disclaimer`}>
          {t.LQ6I(endTimeMsg)}
        </span>
        {editing ? (
          <TimeInput required={false} name={`${namePrefix}End`} />
        ) : (
          <div>{convertValueFormatToDisplayFormat(end)}</div>
        )}
      </div>
    </div>
    <div className="of-pbx-work-hours__range-errors">
      {Array.isArray(errors) && errors?.length > 0 && errors?.[0]?.message}
    </div>
  </div>
);
