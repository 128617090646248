import { DialogWrapper } from '../DialogWrapper/index.js';
import { DuplicateContact } from '../../generated/api/duplicateContact.js';
import {
  I18nTextLinkEmbedded,
  chooseThisContactMsg,
  closeMsg,
  contactAlreadyExistsMsg,
  contactCanBeFoundInCompanyListingMsg,
  duplicateContactHeaderMsg,
  t,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { paths } from '../../common/constants/pathVariables';
import { useLocation } from 'react-router-dom';
import type { DialogWrapperButton } from '../DialogWrapper/index.js';
import type { GenericErrorDuplicateContact } from '../../generated/api/genericErrorDuplicateContact.js';

export interface DuplicateContactDialogProps {
  onCloseDialog: () => void;
  onSelectDuplicateContact: (contactId: string) => void;
  duplicateContact: GenericErrorDuplicateContact;
  duplicateContactType?: string;
  hideUseContactDuplicateButton?: boolean;
}

export const DuplicateContactDialog = ({
  duplicateContact,
  duplicateContactType,
  onCloseDialog,
  onSelectDuplicateContact,
  hideUseContactDuplicateButton,
}: DuplicateContactDialogProps) => {
  const { search } = useLocation();
  const buttons: DialogWrapperButton[] = [
    {
      color: hideUseContactDuplicateButton ? 'primary' : 'link',
      onClick: () => {
        onCloseDialog();
      },
      text: hideUseContactDuplicateButton ? t.WOYD(closeMsg) : t.B2V1('Cancel'),
    },
    ...(!hideUseContactDuplicateButton
      ? [
          {
            onClick: () => {
              onSelectDuplicateContact(duplicateContact.contactId);
              onCloseDialog();
            },
            text: t.T51P(chooseThisContactMsg),
          },
        ]
      : []),
  ];

  return (
    <DialogWrapper
      buttons={buttons}
      onCloseDialog={onCloseDialog}
      header={
        !duplicateContactType || duplicateContactType === DuplicateContact.DuplicateTypeEnum.POSSIBLE_CONTACT_DUPLICATE
          ? t.CVJF(duplicateContactHeaderMsg)
          : t.USHS(contactAlreadyExistsMsg)
      }
    >
      <p>
        {duplicateContactType === DuplicateContact.DuplicateTypeEnum.POSSIBLE_CONTACT_DUPLICATE
          ? t.AR17('Is this the user you are looking for? Select the found user or create a new user.')
          : t.HFUO(contactCanBeFoundInCompanyListingMsg)}
      </p>
      <div>
        <div>
          <strong>{t.VA44('Found contact')}:</strong>
        </div>
        <div>
          {duplicateContact.firstName} {duplicateContact.lastName}
        </div>
        <div>{duplicateContact.email}</div>
        <div>{formatPhoneNumber(duplicateContact.phoneNumber)}</div>
      </div>
      {hideUseContactDuplicateButton && (
        <p>
          <I18nTextLinkEmbedded
            translation={t.UJ8H('Create a [support case], if needed.')}
            linkTo={paths.SUPPORT_CASE_NEW + search}
          />
        </p>
      )}
    </DialogWrapper>
  );
};
