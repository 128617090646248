import { getCustomerLevelDiscountedPrices } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from './useSearchParams.js';

export const useDiscountedPrices = () => {
  const dispatch = useDispatch();
  const { ssoSessionValid } = useAuth();
  const { mdmId } = useSearchParams<{ mdmId: string }>();

  useEffect(() => {
    if (ssoSessionValid) {
      dispatch(getCustomerLevelDiscountedPrices());
    }
  }, [dispatch, ssoSessionValid, mdmId]);
};
