import { CallCategory } from '../../generated/api/callCategory.js';
import { Locale, getLocale, pcsMsg, t } from '../../common/i18n/index.js';
import { addSeconds, formatDuration, intervalToDuration } from 'date-fns';
import { enGB, fi, sv } from 'date-fns/locale';
import type { MonthlyUsageDetailsResponse } from '../../generated/api/monthlyUsageDetailsResponse.js';
import type { UsageItemDetail } from '../../generated/api/usageItemDetail.js';

const getUsageItemDetails = (monthlyUsageDetails: MonthlyUsageDetailsResponse, callCategory: CallCategory) => {
  switch (callCategory) {
    case CallCategory.CALL:
      return monthlyUsageDetails.voiceCalls;
    case CallCategory.SMS:
      return monthlyUsageDetails.textMessages;
    case CallCategory.DATA:
      return monthlyUsageDetails.data;
    default:
      return [];
  }
};

export const getUsageDetails = (
  monthlyUsageDetails: MonthlyUsageDetailsResponse,
  callCategory: CallCategory,
  isLocal: boolean,
  isIncludedInQuota: boolean
): UsageItemDetail[] => {
  const details = getUsageItemDetails(monthlyUsageDetails, callCategory);

  return details.filter(d => d.isLocal === isLocal && d.isIncludedInQuota === isIncludedInQuota);
};

const getDateFnsLocale = () => {
  switch (getLocale()) {
    case Locale.FI:
      return fi;
    case Locale.SV:
      return sv;
    default:
      return enGB;
  }
};

export const toDuration = (seconds?: number) => {
  if (seconds === undefined) {
    return '-';
  } else if (seconds === 0) {
    return '0 min';
  }

  const now = new Date();

  return formatDuration(intervalToDuration({ start: now, end: addSeconds(now, seconds) }), {
    locale: getDateFnsLocale(),
  });
};

export const toBytes = (kiloBytes?: number) => {
  if (kiloBytes === undefined) {
    return '-';
  }
  const kiloByte = 1024;
  const megaByte = 1024 * kiloByte;
  const gigaByte = 1024 * megaByte;
  const bytes = kiloBytes * kiloByte;

  if (bytes > gigaByte) {
    return `${(bytes / gigaByte).toFixed(1)} ${t.C4YQ('GB')}`;
  }

  if (bytes > megaByte) {
    return `${(bytes / megaByte).toFixed(0)} ${t.PZOF('MB')}`;
  }

  return `${kiloBytes} ${t.YBFC('kB')}`;
};

export const toPieces = (pieces: number) => {
  return `${pieces} ${t.B3MG(pcsMsg)}`;
};
