import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { changeOfDeviceMsg, t } from '../../common/i18n/index.js';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { isEppDeviceSubscriptionExpiring } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { isInExtensionPeriod } from '../../common/utils/subscriptionUtils.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { EppDeviceChangeDialogParams } from '../../common/types/dialog.js';
import type { Subscription } from '../../generated/api/models.js';

const textNormalState = `The device can be changed to a new device two weeks prior to the contract period's ending date. In case there no device change is completed or the contract is not terminated, the device will automatically transfer to a six month extension period.`;
const textContractEnding =
  'The contract period for the device is ending. From the device store, you can order a new device and also redeem the old device. In case no device change is completed or the contract is not terminated, the device will automatically transfer into a 6 month extension period.';
const textExtensionPeriod =
  'The device is in an extension period. From the device store, you can order a new device and also redeem this old device, if wanted. In case no device change is completed or the contract is not terminated during the extension period, the device will automatically become company property.';
const textFooter = 'Please note, if the device is damaged, destroyed or missing this shall be reported separately.';
const textConfirm = 'Go to device store';

const getDeviceChangeText = (subscription: Subscription) => {
  if (isInExtensionPeriod(subscription)) {
    return t.VYGS(textExtensionPeriod);
  } else if (isEppDeviceSubscriptionExpiring(subscription)) {
    return t.EAA8(textContractEnding);
  } else {
    return t.YPMQ(textNormalState);
  }
};

const disableConfirm = (subscription: Subscription) =>
  !isInExtensionPeriod(subscription) && !isEppDeviceSubscriptionExpiring(subscription);

export interface EppDeviceChangeDialogProps {
  onCloseDialog: () => void;
  params: EppDeviceChangeDialogParams;
}

export const EppDeviceChangeDialog = (props: EppDeviceChangeDialogProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { authenticatedUser } = useAuth();

  return (
    <ConfirmationDialog
      body={
        <div>
          <p>{getDeviceChangeText(props.params.subscription)}</p>
          <p>{t.OP5R(textFooter)}</p>
        </div>
      }
      confirmButtonText={t.LHCF(textConfirm)}
      disableConfirm={disableConfirm(props.params.subscription)}
      header={t.ZHBT(changeOfDeviceMsg)}
      hideClosingCross={true}
      onCloseDialog={props.onCloseDialog}
      onConfirm={() => {
        const mdmId = queryParams.get('mdmId');
        const subscriptionInfoForDeviceChange = {
          contactId: props.params.subscription.subscriptionContactId,
          subscriptionId: props.params.subscription.subscriptionId,
        };
        const targetPath = `/puhelimet?${queryParams}`;
        sessionStorage.setItem(
          'subscription-detail-for-device-change',
          JSON.stringify(subscriptionInfoForDeviceChange)
        );
        if (mdmId && getActiveAccountMasterId() !== mdmId) {
          dispatch(setActiveAccount(mdmId, false, targetPath, getCompanyName(authenticatedUser, mdmId)));
        } else {
          navigate(targetPath);
        }
      }}
    />
  );
};
