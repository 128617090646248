import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { Contract } from '../../generated/api/models.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import {
  I18nTextLinkEmbedded,
  cancelMsg,
  confirmMsg,
  confirmOrderMsg,
  contractsMsg,
  mobiilivarmenneForEmployeeMsg,
  mobiilivarmennePricePerUserMsg,
  omaElisaForCompaniesMsg,
  orderMsg,
  priceMsg,
  t,
} from '../../common/i18n/index.js';
import { MobileIdDisabled } from './MobileIdDisabled.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { submitMobileIdContract } from '../../selfservice/actions/mobileIdContractActions.js';
import { useDispatch, useStore } from 'react-redux';
import type { GenericConfirmationDialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';

import './MobileId.scss';

export interface MobileIdNewProps {
  onCancel?: () => void;
  mobileIdContracts: Contract[];
}

export const MobileIdNew = (props: MobileIdNewProps) => {
  const { onCancel, mobileIdContracts } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeContract: Contract | undefined = mobileIdContracts.find(
    contract => contract.contractStatus === Contract.ContractStatusEnum.ACTIVATED
  );

  const confirmationDialogParams: GenericConfirmationDialogParams = {
    type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    header: t.RZU4(confirmOrderMsg),
    hideClosingCross: true,
    confirmButtonText: t.QVYK(confirmMsg),
    onConfirm: () => dispatch(submitMobileIdContract()),
    body: (
      <>
        <div className={`${dsClass.MARGIN_VERTICAL_2} ${dsClass.FONT_WEIGHT_BOLD} ${dsClass.PADDING_TOP_1}`}>
          {t.T425('It may take few minutes for the service to activate.')}
        </div>
        <div className={dsClass.MARGIN_VERTICAL_2}>
          {t.VJI9(
            'Note that all the employees who have Elisa mobile subscription can take Elisa Mobiilivarmenne into use without separate approval.'
          )}
        </div>
        <div className={dsClass.MARGIN_VERTICAL_2}>
          {t.GQBC('By confirming you will accept the ')}
          <a href="https://oma.elisa.fi/yrityksille/tilaukset">{t.BV5F('terms and conditions')}</a>.
        </div>
      </>
    ),
  };

  const onClickShowActiveContract = () => {
    if (activeContract?.contractNumber) {
      navigate(generatePath(paths.PS_CONTRACT_MOBILE_ID, { contractDisplayId: activeContract.contractNumber }));
    }
  };

  return (
    <>
      <div className="of-mobileid__title">{t.WFXG('Order Elisa Mobiilivarmenne for Employee service')}</div>
      <div className={dsClass.MARGIN_VERTICAL_4}>
        <I18nTextLinkEmbedded
          translation={t.N8T5(
            'By providing [Elisa Mobiilivarmenne], for your employees you will enable strong authentication to be used in different services.'
          )}
          linkHref="https://elisa.fi/mobiilivarmenne"
        />
      </div>
      <div className={dsClass.MARGIN_VERTICAL_4}>
        <span>{t.V72N(priceMsg)}</span>
        {': '}
        <span className={dsClass.FONT_WEIGHT_BOLD}>{t.BJ25(mobiilivarmennePricePerUserMsg)}</span>
      </div>
      <div className={dsClass.MARGIN_VERTICAL_4}>
        {t.JTZL('The service is charged in the same invoice than the actual subscription.')}
      </div>

      {activeContract ? (
        <>
          <hr className={dsClass.MARGIN_VERTICAL_4} />
          <div className={`${dsClass.MARGIN_BOTTOM_2} ${dsClass.DISPLAY_FLEX}`}>
            <CL.Icon icon="check" size="l" color="success" />{' '}
            <div className={`${dsClass.MARGIN_TOP_1} ${dsClass.FONT_WEIGHT_BOLD}`}>
              {t.EKK1('The service is active')}{' '}
            </div>
          </div>
          <CL.Button className="of-mobileid__thick-button" onClick={() => onClickShowActiveContract()} type="button">
            {t.MIL5('See details')} {'\u27f6'}
          </CL.Button>
        </>
      ) : (
        <div className={`of-mobileid__buttons ${dsClass.DISPLAY_FLEX}`}>
          <CL.Button
            className={dsClass.MARGIN_RIGHT_1}
            id="mobileid-confirmation-confirm"
            onClick={() => dispatch(showDialog(confirmationDialogParams))}
          >
            {t.AQOL(orderMsg)}
          </CL.Button>
          <CL.Button
            className={dsClass.MARGIN_LEFT_2}
            id="mobileid-confirmation-cancel"
            onClick={onCancel}
            color="link"
          >
            {t.B2V1(cancelMsg)}
          </CL.Button>
        </div>
      )}
    </>
  );
};

export const MobileIdNewContent = ({ mobileIdContracts }: MobileIdNewProps) => {
  const navigate = useNavigate();
  const { featureFlags } = useStore<State>().getState().config;

  if (!isFeatureEnabled(featureFlags.mobileId)) {
    return <MobileIdDisabled />;
  }

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.SW0J(contractsMsg), path: paths.PS_CONTRACTS },
        { name: 'Mobiilivarmenne' },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={breadCrumbs}
      id="mobileid-confirmation"
      heading={t.XX5A(mobiilivarmenneForEmployeeMsg)}
      headingBottom={t.DA7N('Provide strong authentication tool for your employees')}
      heroIcon="mobiilivarmenne-hero"
    >
      <MobileIdNew onCancel={() => navigate(paths.PS_CONTRACTS)} mobileIdContracts={mobileIdContracts} />
    </DetailsWrapper>
  );
};
