import { ActionPhase } from '../common/storeUtils.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { SubscriptionDetailsMobile } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import {
  addToActionHistory,
  getPreviousActionState,
  getPreviousSuccessfulItemsQuery,
  updateActionStatePhase,
} from '../common/index.js';
import {
  buildHeaderOnlyItemsQueryV2,
  combineArrays,
  mergeArrays,
  reduceCrudAction,
  reduceDisplayItemsLoadAction,
  validateLoadActionFulfilledResponseCounts,
} from './reducerUtils.js';
import { loadSubscriptionsUseSearchService } from '../common/elasticsearchUtils.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import type { ActionsHistory, ItemsQuery } from '../common/store.js';
import type { Contract, Subscription, SubscriptionDetails } from '../../generated/api/models.js';
import type { GetMobileIdContractsAction } from '../actions/mobileIdContractActions.js';
import type { SelfServiceActionTypes } from '../exports.js';
import type { SubscriptionsState } from '../../common/types/states.js';

/**
 * Checks if this subscription is to be processed
 * @param state union type of SubscriptionsState & ActionsHistory
 * @returns Subscription if this action contains subscription
 */
const hasSubscription = (
  state: SubscriptionsState & ActionsHistory,
  subscriptionId: string
): Subscription | undefined => state && state.items && state.items.find(item => item.subscriptionId === subscriptionId);

/**
 * Create subscriptionReducer for a certain type of subscriptions. The given tab parameter
 * determines the type of subscriptions that the created reducer will handle.
 *
 * @param subscriptionTypes type of subscriptions to handle
 * @returns a new reducer function for the subscription type
 */
function subscriptionReducer(
  state: SubscriptionsState & ActionsHistory,
  action: SelfServiceActionTypes,
  category: SubscriptionCategory
): (SubscriptionsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_SUBSCRIPTIONS: {
      if (action.category !== category) {
        return state; // Not a call to this reducer
      }
      if (loadSubscriptionsUseSearchService(action)) {
        const query: ItemsQuery | undefined = buildHeaderOnlyItemsQueryV2(action);

        return {
          ...state,
          actions: query
            ? addToActionHistory(state, {
                phase: ActionPhase.IN_PROGRESS,
                query,
                value: action,
              })
            : state?.actions,
          filter: action.filter,
          search: query?.search,
          sort:
            query?.sort && query?.order
              ? {
                  columnId: query.sort,
                  order: query.order,
                }
              : state?.sort,
          loading: true,
        };
      }
      // This is needed since by default we get SF data only for NEXT 30 rows and if user jumps to suppose 5 pages
      // ahead of current page then list view remains empty
      const { offset = 0, limit = 0 } =
        getPreviousSuccessfulItemsQuery(action.type, state, 'displayId', action.displayId) || {};
      const requestedOffset = action.offset || 0;
      const requestedLimit = action.limit || 0;
      const defaultLimit = offset + limit;
      const updatedLimit = requestedOffset - offset - limit + requestedLimit;
      const computedLimit = defaultLimit < requestedOffset ? updatedLimit : requestedLimit;

      const getAllItems = Boolean(
        action.getAllItems || action.filter || action.sort || action.search || action.sortColumn
      ); // If filter is in place need to get all so that table search works as expected
      const itemState = reduceDisplayItemsLoadAction<TypeKeys.LOAD_SUBSCRIPTIONS, Subscription>(
        {
          ...action,
          limit: computedLimit,
        },
        state,
        'subscriptionDisplayId',
        getAllItems,
        action.reporting && action.forceLoad ? 0 : undefined,
        action.forceLoad
      );

      return {
        ...itemState,
        items: action.forceLoad ? undefined : state ? state.items : undefined,
        total: action.forceLoad ? undefined : state ? state.total : undefined,
        reporting: action.reporting ?? false,
        loading: itemState.actions?.some(act => act.phase === ActionPhase.IN_PROGRESS) ?? false,
      };
    }

    case TypeKeys.LOAD_SUBSCRIPTIONS_FULFILLED: {
      if (action.category !== category) {
        return state; // Not a call to this reducer
      }

      const actionState = getPreviousActionState(TypeKeys.LOAD_SUBSCRIPTIONS, state, ActionPhase.IN_PROGRESS);
      const { subscriptions } = action;

      const validateLoadActionResponseCounts = action.useSearchService
        ? validateLoadActionFulfilledResponseCounts(
            actionState!.query!,
            action.totalSubscriptions,
            action.searchResults?.map(res => res.result),
            state.errors
          )
        : validateLoadActionFulfilledResponseCounts(
            actionState!.query!,
            action.totalSubscriptions,
            action.subscriptions,
            state.errors
          );
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: action.contactId ? state.errors : validateLoadActionResponseCounts,
        items: sortArray(
          mergeArrays<Subscription>('subscriptionId', 'lastModified', state.items, subscriptions),
          'created',
          'desc'
        ),
        searchResults: action.searchResults,
        total: action.totalSubscriptions,
        loading: false,
      };
    }

    case TypeKeys.LOAD_SUBSCRIPTIONS_FAILED: {
      if (action.params && action.params.category && action.params.category !== category) {
        return state; // Not a call to this reducer
      }
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        loading: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED: {
      if (!state || !state.actions || !state.items || !action.subscriptionAction) {
        return { ...state };
      }
      const items = state.items.map((item: Subscription) => {
        if (item.subscriptionId === action.subscriptionAction.subscriptionId) {
          const { billingAccountId, billingAccountDisplayId } = action;
          return { ...item, billingAccountId, billingAccountDisplayId };
        }
        return item;
      });
      return { ...state, items };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED: {
      if (!state || !state.actions || !state.items) {
        return { ...state };
      }
      const items = state.items.map((item: Subscription) => {
        if (item.subscriptionId === action.subscriptionId) {
          const contactId = action.parameters.contactDetails.contactId || undefined;
          const purposeOfUse = action.parameters.contactDetails.purposeOfUse || undefined;
          const details: SubscriptionDetails = { ...item.details };
          const enumMapping = {
            CUSTOM: 'CUSTOM',
            NOT_TO_DIRECTORY: 'NOTTODIRECTORY',
            PUBLIC_DIRECTORY: 'PUBLICDIRECTORY',
            SECRET: 'SECRET',
          };
          if (details.mobile && action.parameters.numberDirectory) {
            const numberDirectoryString: string = action.parameters.numberDirectory
              ? enumMapping[action.parameters.numberDirectory]
              : '';
            // @ts-ignore
            details.mobile.directoryListing = SubscriptionDetailsMobile.DirectoryListingEnum[numberDirectoryString];
            details.mobile.directoryAddress = action.parameters.address;
            details.mobile.companyName = action.parameters.companyName;
            details.mobile.recipientName = action.parameters.recipientName;
          } else {
            if (
              action.parameters.pbxDirectoryDetails &&
              details.mobile?.pbxConfiguration?.pbxConfigurationDetails.corporateNumberDirectoryDetails
            ) {
              details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails = {
                numberDirectory: action.parameters.pbxDirectoryDetails.numberDirectory,
                address: action.parameters.pbxDirectoryDetails.address,
                companyName: action.parameters.pbxDirectoryDetails.companyName,
                recipientName: action.parameters.pbxDirectoryDetails.recipientName,
              };
            }
            if (action.parameters.voiceDirectoryDetails && details.mobile) {
              details.mobile.directoryListing = action.parameters.voiceDirectoryDetails.numberDirectory;
              details.mobile.directoryAddress = action.parameters.voiceDirectoryDetails.address;
              details.mobile.companyName = action.parameters.voiceDirectoryDetails.companyName;
              details.mobile.recipientName = action.parameters.voiceDirectoryDetails.recipientName;
            }
          }
          return {
            details,
            ...item,
            subscriptionContactId: contactId,
            subscriptionPurposeOfUse: purposeOfUse,
            costCenter: action.parameters.costCenter,
            subscriptionReference: action.parameters.subscriptionReference,
          };
        }
        return item;
      });
      return { ...state, items };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    case TypeKeys.CHANGE_ADDONS_FULFILLED: {
      if (hasSubscription(state, action.subscriptionAction.subscriptionId)) {
        return {
          ...state,
          items: state.items!.map((item: Subscription) => {
            if (item.subscriptionId === action.subscriptionAction.subscriptionId && item.details) {
              const newAddons = item.details.selectedAddOns
                ? item.details.selectedAddOns.filter(addon => !action.addOnsToRemove.includes(addon.addOnCode))
                : [];

              action.addOnsToAdd.forEach(addOnCodeToAdd => {
                if (newAddons.find(addon => addon.addOnCode === addOnCodeToAdd) === undefined) {
                  newAddons.push({
                    addOnCode: addOnCodeToAdd,
                    // We don't have information on the following fields. They're not used in the UI either ATM.
                    addOnGroup: '',
                    addOnProductName: '',
                    addOnType: '',
                  });
                }
              });

              return {
                ...item,
                details: {
                  ...item.details,
                  selectedAddOns: newAddons,
                },
              };
            } else {
              return item;
            }
          }),
        };
      }
      return state;
    }

    case TypeKeys.POST_EPP_MAINTENANCE_FAILED: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED: {
      return {
        ...state,
        search: undefined,
        sort: undefined,
        filter: undefined,
      };
    }

    case TypeKeys.GET_MOBILEID_CONTRACTS: {
      const previousActionState = getPreviousActionState(TypeKeys.GET_MOBILEID_CONTRACTS, state, ActionPhase.SUCCESS);
      const mobileIdContractAction = previousActionState
        ? (previousActionState.value as GetMobileIdContractsAction)
        : undefined;
      if (
        previousActionState &&
        mobileIdContractAction &&
        mobileIdContractAction.contractNumber === action.contractNumber
      ) {
        return { ...state };
      }
      return {
        ...state,
        ...reduceCrudAction(action, state),
        loadingContracts: true,
      };
    }

    case TypeKeys.GET_MOBILEID_CONTRACTS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_MOBILEID_CONTRACTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        mobileIdContracts: combineArrays<Contract>(
          'contractNumber',
          state.mobileIdContracts || [],
          action.mobileidContracts || []
        ),
        loadingContracts: false,
      };
    }

    case TypeKeys.GET_MOBILEID_CONTRACTS_FAILED: {
      return {
        ...state,
        errors: action.errors,
        loadingContracts: false,
      };
    }

    case TypeKeys.SUBMIT_MOBILEID_CONTRACT_FULFILLED:
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT_FULFILLED: {
      return null;
    }

    case TypeKeys.SUBMIT_MOBILEID_CONTRACT_FAILED:
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT_FAILED: {
      return {
        ...state,
        errors: action.errors,
        loadingContracts: false,
      };
    }

    case TypeKeys.GET_SERVICE_FEE: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.GET_SERVICE_FEE_FULFILLED: {
      const serviceFees = state.serviceFees || [];
      serviceFees.push({
        subscriptionDisplayId: action.subscriptionDisplayId,
        price: action.price,
      });
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.GET_SERVICE_FEE, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        serviceFees,
      };
    }

    case TypeKeys.GET_SERVICE_FEE_FAILED: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }
    default:
      return state;
  }
}

export interface SubscriptionsReducersWrapper {
  broadband: (state: SubscriptionsState, action: SelfServiceActionTypes) => SubscriptionsState | null;
  device: (state: SubscriptionsState, action: SelfServiceActionTypes) => SubscriptionsState | null;
  domain: (state: SubscriptionsState, action: SelfServiceActionTypes) => SubscriptionsState | null;
  service: (state: SubscriptionsState, action: SelfServiceActionTypes) => SubscriptionsState | null;
  voice: (state: SubscriptionsState, action: SelfServiceActionTypes) => SubscriptionsState | null;
  contract: (state: SubscriptionsState, action: SelfServiceActionTypes) => SubscriptionsState | null;
}

export const subscriptionsReducers: SubscriptionsReducersWrapper = {
  broadband: (state: SubscriptionsState, action: SelfServiceActionTypes) =>
    subscriptionReducer(state, action, SubscriptionCategory.BROADBAND),
  device: (state: SubscriptionsState, action: SelfServiceActionTypes) =>
    subscriptionReducer(state, action, SubscriptionCategory.DEVICE),
  domain: (state: SubscriptionsState, action: SelfServiceActionTypes) =>
    subscriptionReducer(state, action, SubscriptionCategory.DOMAIN),
  service: (state: SubscriptionsState, action: SelfServiceActionTypes) =>
    subscriptionReducer(state, action, SubscriptionCategory.SERVICE),
  voice: (state: SubscriptionsState, action: SelfServiceActionTypes) =>
    subscriptionReducer(state, action, SubscriptionCategory.VOICE),
  contract: (state: SubscriptionsState, action: SelfServiceActionTypes) =>
    subscriptionReducer(state, action, SubscriptionCategory.CONTRACT),
};
