import { HeaderText, InfoText, Separator } from './InfoText.js';
import { Status } from '../Status/Status.js';
import type { StatusColor } from '../../common/enums.js';

export interface SectionContentProps {
  left: string | JSX.Element;
  right?: string | number | JSX.Element;
  statusColor?: StatusColor;
}

interface SectionContentElementProps extends SectionContentProps {
  isHeader: boolean;
}

const SectionContentElement = ({ left, right, isHeader, statusColor }: SectionContentElementProps) => {
  const LeftPart = () => {
    if (statusColor) {
      return <Status color={statusColor} textAfterBadge={left} smallBadge={true} />;
    }
    return isHeader ? <HeaderText info={left} /> : <InfoText info={left} />;
  };

  return (
    <div>
      {left && <LeftPart />}
      {left && right && (
        <>
          <Separator />
          <InfoText info={right} styleClass="right" />
        </>
      )}
      {right && !left && (isHeader ? <HeaderText info={right} /> : <InfoText info={right} />)}
    </div>
  );
};

export const HeaderSectionContent = ({ left, right, statusColor }: SectionContentProps) => (
  <SectionContentElement left={left} right={right} statusColor={statusColor} isHeader={true} />
);

export const SectionContent = ({ left, right, statusColor }: SectionContentProps) => (
  <SectionContentElement left={left} right={right} statusColor={statusColor} isHeader={false} />
);
