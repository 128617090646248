import * as CL from '@design-system/component-library';
import { BILLING_ACCOUNT_REFERENCE_TEXT_MAX_LENGTH } from '../../common/utils/validationUtils';
import { GridColumn } from '../BillingAccountDetails/components/GridColumn.js';
import { Reference } from '../../common/react-hook-form/fields/Reference.js';
import { referenceMsg, t } from '../../common/i18n/index.js';
import { validateBillingAccountReferenceMaxLength } from '../../common/utils/billingAccountUtils';

import './BillingAccountReferenceNumbers.scss';

const EditField = ({ i, disabled = false }: { i: number; disabled?: boolean }) => (
  <GridColumn className="of-billing-account-details form-column edit-field">
    <Reference
      name={`billingAccount.customerReference${i}`}
      type="text"
      label={`${t.ZLAU(referenceMsg)} ${i}`}
      placeholder={`${t.ZLAU(referenceMsg)} ${i}`}
      hint=""
      disabled={disabled}
      maxLength={BILLING_ACCOUNT_REFERENCE_TEXT_MAX_LENGTH}
      validate={validateBillingAccountReferenceMaxLength}
    />
  </GridColumn>
);
export const BillingAccountReferenceNumbersForm = ({ disabled = false }: { disabled?: boolean }) => {
  return (
    <div className="of-billing-account-reference-numbers">
      <CL.GridRow>
        <EditField i={1} disabled={disabled} />
        <EditField i={2} disabled={disabled} />
      </CL.GridRow>
      <CL.GridRow>
        <EditField i={3} disabled={disabled} />
        <EditField i={4} disabled={disabled} />
      </CL.GridRow>
    </div>
  );
};
