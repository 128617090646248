import { NumberRange } from '../../generated/api/models.js';
import {
  companyNumberMsg,
  languageMsg,
  mobilePhoneNumberMsg,
  selectedExtensionNumberingsMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses';
import { getLanguageDisplayValue } from '../../common/utils/languageUtils.js';
import type * as CL from '@design-system/component-library';
import type { CallerLineIdType, SubscriptionDetailsPbxSolution } from '../../generated/api/models.js';

const callerLineIdMap = () => ({
  PSTN: t.ZX32(companyNumberMsg),
  MOBILE: t.FRYN(mobilePhoneNumberMsg),
});

const getMappedCallerLineId = (callerLineIdType: CallerLineIdType): string => callerLineIdMap()[callerLineIdType];

export const getPbxFields = (
  numberRange: NumberRange[] = [],
  numberFormatter: (numberRanges: NumberRange[], filterCondition: NumberRange.RangeTypeEnum) => JSX.Element[]
): CL.DescriptionItem[] => [
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.I9BN('Reserved corporate numbering space')}
          </label>
        </div>
        <div>{numberFormatter(numberRange, NumberRange.RangeTypeEnum.PSTN)}</div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.FIS5(selectedExtensionNumberingsMsg)}
          </label>
        </div>
        <div>{numberFormatter(numberRange, NumberRange.RangeTypeEnum.EXTENSION)}</div>
      </>
    ),
  },
];

export const getPbxSettingsFields = (pbxDetails: SubscriptionDetailsPbxSolution): CL.DescriptionItem[] => [
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>{t.L3C5(languageMsg)}</label>
        </div>
        <div>{pbxDetails.solutionLanguage && getLanguageDisplayValue(pbxDetails.solutionLanguage)}</div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.SE27('Number to display for calls')}
          </label>
        </div>
        <div>{pbxDetails.callerLineIdForCalls && getMappedCallerLineId(pbxDetails.callerLineIdForCalls)}</div>
      </>
    ),
  },
  {
    description: (
      <>
        <div>
          <label className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD}`}>
            {t.AGM7('Number to display for SMS messages')}
          </label>
        </div>
        <div>{pbxDetails.callerLineIdForSms && getMappedCallerLineId(pbxDetails.callerLineIdForSms)}</div>
      </>
    ),
  },
];
