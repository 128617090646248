import * as CL from '@design-system/component-library';
import { omaElisaClassicMsg, omaElisaForCompaniesMsg, t } from '../../../common/i18n';
import { paths } from '../../../common/constants/pathVariables.js';
import type * as CLT from '@design-system/component-library';
import type { NavigateFunction } from 'react-router-dom';

export const personalUserMenuLinks = (
  navigate: NavigateFunction,
  classicSiteUrl: string
): CLT.HeaderUserMenuProps['notifications'] => [
  {
    icon: <CL.Icon icon="home" />,
    onClick: e => {
      e.preventDefault();
      navigate(paths.SELF_SERVICE_HOME);
    },
    text: t.LOUS(omaElisaForCompaniesMsg),
    url: paths.SELF_SERVICE_HOME,
  },
  {
    icon: <CL.Icon icon="share" />,
    onClick: () => {},
    text: t.SLBP(omaElisaClassicMsg),
    url: classicSiteUrl,
  },
];
