import { BillingAccountSubscriptionList } from '../../../../../components/SubscriptionLists/BillingAccountSubscriptionList';
import { useLoaderData } from 'react-router-dom';
import type { SubscriptionsResponse } from '../../../../../generated/api/subscriptionsResponse';

export const BillingAccountSubscriptionsPath = () => {
  const subscriptions = useLoaderData() as SubscriptionsResponse;
  return (
    <BillingAccountSubscriptionList
      subscriptions={subscriptions.searchResults || []}
      totalItems={subscriptions.total}
    />
  );
};
