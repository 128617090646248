import { BILLING_ACCOUNT_REFERENCE_TEXT_MAX_LENGTH } from '../../utils/validationUtils';
import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { referenceMsg, t } from '../../i18n/index.js';
import { validateBillingAccountReferenceMaxLength } from '../../utils/billingAccountUtils';

interface BillingAccountReferenceProps {
  number: number;
}
export const BillingAccountReference = (props: BillingAccountReferenceProps) => {
  const { number } = props;
  const label = `${t.ZLAU(referenceMsg)} ${number}`;
  const name = `customerReference${number}`;
  return (
    <Field
      {...{
        label,
        name,
        maxLength: BILLING_ACCOUNT_REFERENCE_TEXT_MAX_LENGTH,
        validate: validateBillingAccountReferenceMaxLength,
      }}
      component={InputComponent}
    />
  );
};
