import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { OpenFormNotice } from '../../OpenFormComponents/OpenFormNotifications.js';
import { OpenFormQuestionBillingAccount } from './OpenFormQuestionBillingAccount.js';
import { OpenFormQuestionContact } from './OpenFormQuestionContact.js';
import { OpenFormQuestionDate } from './OpenFormQuestionDate.js';
import { OpenFormQuestionInput } from './OpenFormQuestionInput.js';
import { OpenFormQuestionListOfObjects } from './OpenFormQuestionListOfObjects.js';
import { OpenFormQuestionSelectGroup } from './OpenFormQuestionSelectGroup.js';
import type { OpenFormQuestion } from '../../../../generated/api/openFormQuestion.js';

export const OpenFormQuestionType = ({
  question,
  answers,
  disabled,
}: {
  question: OpenFormQuestion;
  answers: OpenFormAnswers;
  disabled: boolean;
}) => {
  try {
    switch (question.type) {
      case 'LIST_OF_OBJECTS_MULTI_SELECT':
      case 'LIST_OF_OBJECTS_SINGLE_SELECT':
        return (
          <OpenFormQuestionListOfObjects
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            headers={question.listColumns}
            multiselect={question.type.endsWith('MULTI_SELECT')}
            choices={question.choices.filter(answers.visible)}
            context={answers.get(question)}
            available={answers.getAvailable(question)}
          />
        );
      case 'CONTRACT_PERIOD':
      case 'MULTI_SELECT':
      case 'SINGLE_SELECT':
        return (
          <OpenFormQuestionSelectGroup
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            multiselect={question.type.endsWith('MULTI_SELECT')}
            choices={question.choices.filter(answers.visible)}
            context={answers.get(question)}
            available={answers.getAvailable(question)}
          />
        );
      case 'BA_SELECTION':
        return (
          <OpenFormQuestionBillingAccount
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            context={answers.get(question)}
          />
        );
      case 'DATE_OF_DELIVERY':
        return (
          <OpenFormQuestionDate
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            offset={question.offset}
            selected={answers.get(question)?.choices}
          />
        );
      case 'INSTALLATION_ADDRESS':
        return <OpenFormNotice text="Use AVAILABILITY_CHECK instead of INSTALLATION_ADDRESS" type="warning" />;
      case 'INSTALLATION_CONTACT':
      case 'DELIVERY_CONTACT':
      case 'FAULT_INCIDENT_CONTACT':
      case 'ORDERING_CONTACT':
      case 'TECHNICAL_CONTACT':
        return (
          <OpenFormQuestionContact
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            choices={answers.get(question)?.choices}
          />
        );
      case 'TIME_OF_DELIVERY':
        return (
          <OpenFormQuestionInput
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            type="time"
          />
        );
      case 'ADDITIONAL_INFORMATION_BACKOFFICE':
      case 'ADDITIONAL_INFORMATION_FIELD_ENGINEER':
        return (
          <OpenFormQuestionInput
            disabled={disabled}
            required={question.isMandatory}
            label={question.label}
            name={question.guid}
            type="textarea"
          />
        );
      case 'FREE_TEXT':
        switch (question.dataType) {
          case 'DATE_FIELD':
            return (
              <OpenFormQuestionDate
                disabled={disabled}
                required={question.isMandatory}
                label={question.label}
                name={question.guid}
                offset={question.offset}
                selected={answers.get(question)?.choices}
              />
            );
          case 'EMAIL':
            return (
              <OpenFormQuestionInput
                disabled={disabled}
                required={question.isMandatory}
                label={question.label}
                name={question.guid}
                type="email"
              />
            );
          case 'NUMBER_INTEGER':
            return (
              <OpenFormQuestionInput
                disabled={disabled}
                required={question.isMandatory}
                label={question.label}
                name={question.guid}
                type="int"
              />
            );
          case 'PHONE':
            return (
              <OpenFormQuestionInput
                disabled={disabled}
                required={question.isMandatory}
                label={question.label}
                name={question.guid}
                type="tel"
              />
            );
          case 'TIME_FIELD':
            return (
              <OpenFormQuestionInput
                disabled={disabled}
                required={question.isMandatory}
                label={question.label}
                name={question.guid}
                type="time"
              />
            );
          case 'TEXT':
            return (
              <OpenFormQuestionInput
                disabled={disabled}
                required={question.isMandatory}
                label={question.label}
                name={question.guid}
                type="text"
              />
            );
        }
    }
  } catch (error) {
    return <OpenFormNotice text={error.message} type="error" />;
  }
};
