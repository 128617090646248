export const translateFn = (translate: (eng: string, fin: string, ...val: string[]) => string) => ({

  A00L: (en: string, ...val: string[]) => translate(en,
    'Asiakasnumero',
    ...val),

  A00M: (en: string, ...val: string[]) => translate(en,
    'jota muutos koskee',
    ...val),

  A00N: (en: string, ...val: string[]) => translate(en,
    'Asiakasnumero löytyy viimeisimmältä laskulta. Jos yrityksellä on useampi asiakasnumero, voit ilmoittaa niistä minkä tahansa.',
    ...val),

  A00O: (en: string, ...val: string[]) => translate(en,
    'Valitse kyllä, jos olet esimerkiksi yrityksen kirjanpitäjä tai asunto-osakeyhtiön isännöitsijä.',
    ...val),

  A00P: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti muutos usealle yritykselle samanaikaisesti ei ole vielä mahdollista palvelussamme. Voit kuitenkin tehdä muutoslomakkeen jokaiselle yritykselle erikseen.',
    ...val),

  A00Q: (en: string, ...val: string[]) => translate(en,
    'Asiakasnumero sisältää vähintään kolme (3) ja enintään yhdeksän (9) numeroa',
    ...val),

  A00R: (en: string, ...val: string[]) => translate(en,
    'Sinulta vaaditaan tunnistautumista',
    ...val),

  A00S: (en: string, ...val: string[]) => translate(en,
    'Näet tulokset kaikista yrityksistä, joihin sinulla on pääkäyttäjäoikeudet.',
    ...val),

  A00T: (en: string, ...val: string[]) => translate(en,
    'Lähetä uusi koodi',
    ...val),

  A00U: (en: string, ...val: string[]) => translate(en,
    'Etkö saanut tekstiviestiä? Varmista yrityksenne pääkäyttäjältä, että puhelinnumerosi on varmasti oikein. ',
    ...val),

  A00V: (en: string, ...val: string[]) => translate(en,
    'Tunnistaudu',
    ...val),

  A00W: (en: string, ...val: string[]) => translate(en,
    'Koodi on väärin',
    ...val),

  A00X: (en: string, ...val: string[]) => translate(en,
    'Palvelun tietoturvallisen käytön takaamiseksi lähetimme sinulle varmistuskoodin tekstiviestillä numeroon {}. Täytä varmistuskoodi alla oleviin kenttiin.',
    ...val),

  A00Y: (en: string, ...val: string[]) => translate(en,
    'Odottamaton virhe',
    ...val),

  A0OH: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, johon haluat lisätä uuden laitelistan.',
    ...val),

  A0OI: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, johon haluat lisätä uuden käyttäjän.',
    ...val),

  A0OJ: (en: string, ...val: string[]) => translate(en,
    'alv. {} %',
    ...val),

  A0OK: (en: string, ...val: string[]) => translate(en,
    'Ilmoitatko toisen yrityksen tai asunto-osakeyhtiön puolesta',
    ...val),

  A0OR: (en: string, ...val: string[]) => translate(en,
    'Näytä kaikki yritykset',
    ...val),

  A145: (en: string, ...val: string[]) => translate(en,
    'Valitulla yrityksellä ei ole vastaavaa Elisa Palvelupäätelaite -sopimusta, joten laitetta ei voida siirtää',
    ...val),

  A1Q5: (en: string, ...val: string[]) => translate(en,
    'Yritystiedot ja asetukset',
    ...val),

  A239: (en: string, ...val: string[]) => translate(en,
    'Aloita vianmääritys valitsemalla liittymä',
    ...val),

  A2WA: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää ei voi muuttaa toiseksi henkilöksi',
    ...val),

  A2WB: (en: string, ...val: string[]) => translate(en,
    'Olet muuttamassa henkilön etu- ja sukunimeä. Mikäli henkilö on vaihtumassa toiseksi, poistathan nykyisen käyttäjän ja ',
    ...val),

  A2WC: (en: string, ...val: string[]) => translate(en,
    'luo uusi käyttäjä',
    ...val),

  A2WD: (en: string, ...val: string[]) => translate(en,
    'Mikäli henkilön molemmat nimet ovat virheelliset, mutta kyseessä on sama henkilö ota yhteyttä ',
    ...val),

  A30I: (en: string, ...val: string[]) => translate(en,
    'Kun aktivoit kaksivaiheisen tunnistautumisen, se tulee käyttöön kaikilla palvelusopimukseen kuuluvilla yrityksillä',
    ...val),

  A37D: (en: string, ...val: string[]) => translate(en,
    'Ei',
    ...val),

  A3GB: (en: string, ...val: string[]) => translate(en,
    'Sisältyy',
    ...val),

  A47Y: (en: string, ...val: string[]) => translate(en,
    'Tervetuloa hoitamaan yrityksesi asioita',
    ...val),

  A48T: (en: string, ...val: string[]) => translate(en,
    'Pyydä käyttäjää tekemään numeronsiirtotilaus hyvissä ajoin ettei hän menetä numeroaan. Liittymä irtisanotaan automaattisesti ilmoittamanasi päivänä mikäli henkilö ei ole ottanut numeroa omaan käyttöönsä.',
    ...val),

  A4V5: (en: string, ...val: string[]) => translate(en,
    'Muut laitteet',
    ...val),

  A54G: (en: string, ...val: string[]) => translate(en,
    'Etsi laskua...',
    ...val),

  A575: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista Netti Lite -liittymät',
    ...val),

  A5B3: (en: string, ...val: string[]) => translate(en,
    'Alle',
    ...val),

  A5G1: (en: string, ...val: string[]) => translate(en,
    'Huollata laite',
    ...val),

  A5G4: (en: string, ...val: string[]) => translate(en,
    'Liittymän operaattori',
    ...val),

  A6M3: (en: string, ...val: string[]) => translate(en,
    'Kohde',
    ...val),

  A6ZP: (en: string, ...val: string[]) => translate(en,
    'Jos yritit muokata äskettäin luotua laskutussopimusta, odota hetki ja yritä myöhemmin uudelleen',
    ...val),

  A7CA: (en: string, ...val: string[]) => translate(en,
    'Laitenetti',
    ...val),

  A7CB: (en: string, ...val: string[]) => translate(en,
    'Laitenetti sopii esimerkiksi yrityksesi valvontakameroihin, hälytysjärjestelmään tai lämmityslaitteisiin.',
    ...val),

  A7CC: (en: string, ...val: string[]) => translate(en,
    'Kuvien lähettäminen, SMS/puheohjattavat laitteet, kiinteistöjen hälyttimet, ilmalämpöpumput',
    ...val),

  A7CD: (en: string, ...val: string[]) => translate(en,
    'Valvonta -ja riistakamerat (kuva & video), hälytyslaitteet, GPS-paikantimet, olosuhdesensorit',
    ...val),

  A7CE: (en: string, ...val: string[]) => translate(en,
    'Valvonta -ja riistakamerat (live-video), mobiilireitittimet ja verkkolaitteet, hälytyslaitteet',
    ...val),

  A7CF: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin koko',
    ...val),

  A7CG: (en: string, ...val: string[]) => translate(en,
    'Laitenetin päivitys',
    ...val),

  A7CH: (en: string, ...val: string[]) => translate(en,
    'Uusi Laitenetti',
    ...val),

  A7CI: (en: string, ...val: string[]) => translate(en,
    'EU-dataa {0} Gt/kk',
    ...val),

  A7CJ: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi (Mini + Micro)',
    ...val),

  A7CK: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi (Nano)',
    ...val),

  A7CL: (en: string, ...val: string[]) => translate(en,
    'Tarkastathan, mikä SIM-kortti koko sopii laitteeseesi. Valittavissa on yhdistelmäkortti Mini (2FF) + Micro (3FF) tai Nano (4FF).',
    ...val),

  A7DR: (en: string, ...val: string[]) => translate(en,
    'Laskun kieli',
    ...val),

  A86X: (en: string, ...val: string[]) => translate(en,
    'Laite palautetaan Elisalle',
    ...val),

  A8DZ: (en: string, ...val: string[]) => translate(en,
    'Elisa YritysWIFI Lite, laajempi WIFI-verkko toimipisteeseesi.',
    ...val),

  A8VA: (en: string, ...val: string[]) => translate(en,
    'Valitse kaikki',
    ...val),

  A8Y9: (en: string, ...val: string[]) => translate(en,
    'eIP Exchange O365 -lisäpalvelu vaatii käyttäjän Office 365-sähköpostin toimiakseen. Tämä sähköposti tulee olemaan jatkossa myös käyttäjän Ring-käyttäjätunnus.',
    ...val),

  A9V3: (en: string, ...val: string[]) => translate(en,
    'Työaikaketjun voimassaoloaika',
    ...val),

  A9VQ: (en: string, ...val: string[]) => translate(en,
    'Yrityksen toimipiste',
    ...val),

  ABI6: (en: string, ...val: string[]) => translate(en,
    'Korvaavaa laitetta ei lähetetä automaattisesti.',
    ...val),

  ABRO: (en: string, ...val: string[]) => translate(en,
    'Jos laskun viitenumero on 80-alkuinen vakioviitteellinen lasku, maksut kohdistuvat aina vanhimpaan avoimeen laskuun.',
    ...val),

  ABTG: (en: string, ...val: string[]) => translate(en,
    'Tervetuloa Työntekijän OmaElisaan!',
    ...val),

  ABUM: (en: string, ...val: string[]) => translate(en,
    'Siirry muokkaamaan tietoja',
    ...val),

  ACGC: (en: string, ...val: string[]) => translate(en,
    'Työntekijälle',
    ...val),

  ACH1: (en: string, ...val: string[]) => translate(en,
    'laitteen',
    ...val),

  AD92: (en: string, ...val: string[]) => translate(en,
    'Muutoksen tekijä',
    ...val),

  ADV9: (en: string, ...val: string[]) => translate(en,
    'Sisältää pelkän liittymän ja päätelaite myydään erikseen.',
    ...val),

  AEOA: (en: string, ...val: string[]) => translate(en,
    'Valitse vaihtoehto, joka kuvaa parhaiten laitteesi nykyistä kuntoa',
    ...val),

  AFW3: (en: string, ...val: string[]) => translate(en,
    'SM-DP+ -osoite',
    ...val),

  AFXP: (en: string, ...val: string[]) => translate(en,
    'Olemme sinuun yhteydessä mahdollisimman pian.',
    ...val),

  AG7I: (en: string, ...val: string[]) => translate(en,
    'Jos laite vioittuu, saat välittömästi uuden tilalle.',
    ...val),

  AGIJ: (en: string, ...val: string[]) => translate(en,
    'Puhelinnumero',
    ...val),

  AGIK: (en: string, ...val: string[]) => translate(en,
    'Työpuhelinnumero',
    ...val),

  AGM7: (en: string, ...val: string[]) => translate(en,
    'Näytettävä numero tekstiviestille',
    ...val),

  AHEQ: (en: string, ...val: string[]) => translate(en,
    'Valitse 4G-liittymä',
    ...val),

  AHL9: (en: string, ...val: string[]) => translate(en,
    'Tilaamalla OmaLasku Palvelunumerot -ominaisuuden, vastaat itse seuraavien palveluiden käytöstä aiheutuvista kuluista:',
    ...val),

  AHYR: (en: string, ...val: string[]) => translate(en,
    'Osaa käsittelyssä olevista tilauksistasi voit seurata',
    ...val),

  AIK7: (en: string, ...val: string[]) => translate(en,
    'Etunimi',
    ...val),

  AIUJ: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että muutoksen jälkeen laskutus näkyy kahdella eri laskulla vielä seuraavassa laskussa. Mahdolliset lisäpalvelut siirtyvät päätuotteen mukana.',
    ...val),

  AJ93: (en: string, ...val: string[]) => translate(en,
    'Yrityksen nimi',
    ...val),

  AJAM: (en: string, ...val: string[]) => translate(en,
    '8K-leffat ja -sarjat ilman katkoksia',
    ...val),

  AJVW: (en: string, ...val: string[]) => translate(en,
    'että laite tulee tyhjentää ja palauttaa tehdasasetuksille ennen kuin otan sen henkilökohtaiseen käyttööni, sekä en palauta laitteeseen yrityksen tietoja ilman erillistä työnantajan lupaa. Jos tarvitset tukea tyhjennyksen suorittamisessa, ota yhteyttä Elisan yritysasiakaspalveluun.',
    ...val),

  AKD6: (en: string, ...val: string[]) => translate(en,
    'Tuotteiden toimitus',
    ...val),

  AKN3: (en: string, ...val: string[]) => translate(en,
    'Tunnistaudu',
    ...val),

  AKW8: (en: string, ...val: string[]) => translate(en,
    'Contact customer service',
    ...val),

  AL0L: (en: string, ...val: string[]) => translate(en,
    'Tilausta Käsitellään',
    ...val),

  ALQT: (en: string, ...val: string[]) => translate(en,
    'PIN-koodi',
    ...val),

  AM3R: (en: string, ...val: string[]) => translate(en,
    'Kysy laskusta',
    ...val),

  AMRD: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen tiedot',
    ...val),

  AMXZ: (en: string, ...val: string[]) => translate(en,
    'Valitse yritykset, joille haluat kopioida laitelistan. Muista viimeistellä ja julkaista laitelista erikseen.',
    ...val),

  AN17: (en: string, ...val: string[]) => translate(en,
    'Numeroasetukset',
    ...val),

  ANG6: (en: string, ...val: string[]) => translate(en,
    'Lähetä kutsulinkki',
    ...val),

  AOIV: (en: string, ...val: string[]) => translate(en,
    'Käyttäjätiedot päivitetty tuotteelle.',
    ...val),

  AOQB: (en: string, ...val: string[]) => translate(en,
    'Aika',
    ...val),

  AP5F: (en: string, ...val: string[]) => translate(en,
    'Elisan asiakaspalveluun',
    ...val),

  APHP: (en: string, ...val: string[]) => translate(en,
    'Tallenna tiedosto',
    ...val),

  AQF9: (en: string, ...val: string[]) => translate(en,
    'Mitä tilaamiseen tarvitaan?',
    ...val),

  AQOL: (en: string, ...val: string[]) => translate(en,
    'Tilaa',
    ...val),

  AR0B: (en: string, ...val: string[]) => translate(en,
    'Puhelinliittymät',
    ...val),

  AR17: (en: string, ...val: string[]) => translate(en,
    'Onko tämä etsimäsi käyttäjä? Valitse löytynyt käyttäjä tai luo uusi.',
    ...val),

  ARDH: (en: string, ...val: string[]) => translate(en,
    'Jotain meni pieleen QR-koodin luomisessa. Ole hyvä ja ota yhteyttä asiakaspalveluumme.',
    ...val),

  ARDI: (en: string, ...val: string[]) => translate(en,
    'Paketoimme arkea helpottavat ',
    ...val),

  ARDJ: (en: string, ...val: string[]) => translate(en,
    'lisäpalvelut yritysliittymiimme',
    ...val),

  ARDK: (en: string, ...val: string[]) => translate(en,
    '. Mobiiliturva, Matkapuhelinvastaaja ja Reissunetti tekevät arjestasi sujuvampaa ja turvallisempaa. Valitse itsellesi sopivista liittymistä Premium, Perus tai Mini ja liitä mukaan itsellesi sopivat palvelut.',
    ...val),

  ARE0: (en: string, ...val: string[]) => translate(en,
    'Puhelinliittymät yrityksille',
    ...val),

  ARE1: (en: string, ...val: string[]) => translate(en,
    'Elisan 5G+ ja 5G-yritysliittymillä kaikki vaan toimii paremmin. Tehokkaat ja huippunopeat puhelinliittymät yrityskäyttöön varmistavat sujuvan työnteon. Nopea 5G-verkko takaa toimintavarman yhteyden ja tuo vauhtia yrityksesi arkeen.',
    ...val),

  ARE2: (en: string, ...val: string[]) => translate(en,
    'Elisa on myös ainoa operaattori, jonka mobiili- ja kiinteän verkon liittymille on myönnetty Avainlippu suomalaisen palvelun merkiksi. Elisan liittymä on oiva valinta sinulle, kun haluat suosia kotimaista.',
    ...val),

  ARE3: (en: string, ...val: string[]) => translate(en,
    'Huippunopea suomalainen 5G-verkko tekee työnteosta sujuvaa',
    ...val),

  ARE4: (en: string, ...val: string[]) => translate(en,
    'Elisan 5G-verkko on ',
    ...val),

  ARE5: (en: string, ...val: string[]) => translate(en,
    'tutkitusti kattava',
    ...val),

  ARE6: (en: string, ...val: string[]) => translate(en,
    '. Tee töitä vaikka mökin laiturilla ja nauti toimintavarmasta yhteydestä!',
    ...val),

  ARE7: (en: string, ...val: string[]) => translate(en,
    'Katso alueesi 5G-kuuluvuus kuuluvuuskartasta.',
    ...val),

  ARE8: (en: string, ...val: string[]) => translate(en,
    'Tarkista alueesi kuuluvuus.',
    ...val),

  ARE9: (en: string, ...val: string[]) => translate(en,
    'Usein kysytyt kysymykset yritysliittymistä',
    ...val),

  AREA: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisassa',
    ...val),

  AREB: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisasta',
    ...val),

  AREC: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu Yritysten OmaElisaan',
    ...val),

  ARED: (en: string, ...val: string[]) => translate(en,
    'Työntekijän OmaElisasta',
    ...val),

  AREE: (en: string, ...val: string[]) => translate(en,
    'Toimivatko 5G+ ja 5G-liittymät 4G-verkossa?',
    ...val),

  AREF: (en: string, ...val: string[]) => translate(en,
    'Liittymät toimivat myös 4G-verkossa. Kun menet Elisa Yritysliittymä 5G+ tai 5G -liittymällä 5G-alueen ulkopuolelle, käytössäsi on 4G-verkko.',
    ...val),

  AREG: (en: string, ...val: string[]) => translate(en,
    'Jos puhelimesi tukee vain 4G-verkkoa, käyttää liittymäsi myös silloin 4G-verkkoa.',
    ...val),

  AREH: (en: string, ...val: string[]) => translate(en,
    'Kuka voi tilata yritysliittymän?',
    ...val),

  AREI: (en: string, ...val: string[]) => translate(en,
    'Voit tilata Elisan yritysliittymän, kun sinulla on yrityksesi ',
    ...val),

  AREJ: (en: string, ...val: string[]) => translate(en,
    'nimenkirjoitusoikeus',
    ...val),

  AREK: (en: string, ...val: string[]) => translate(en,
    '',
    ...val),

  AREL: (en: string, ...val: string[]) => translate(en,
    'Tilaamiseen tarvitset Yritysten OmaElisa -tunnukset. Jos sinulla ei ole vielä tunnuksia, ',
    ...val),

  AREM: (en: string, ...val: string[]) => translate(en,
    'luo itsellesi uudet tunnukset',
    ...val),

  AREN: (en: string, ...val: string[]) => translate(en,
    'Miten yritysliittymä tilataan?',
    ...val),

  AREO: (en: string, ...val: string[]) => translate(en,
    'Uuden yritysliittymän tilaaminen: ',
    ...val),

  AREP: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi puhelinliittymä yrityksellesi tältä sivulta tai ',
    ...val),

  AREQ: (en: string, ...val: string[]) => translate(en,
    '. Voit tilata liittymän uudella numerolla tai siirtää vanhan numerosi uudelle liittymälle.',
    ...val),

  ARER: (en: string, ...val: string[]) => translate(en,
    'Nykyisen liittymän päivittäminen toiseen liittymäpakettiin:',
    ...val),

  ARES: (en: string, ...val: string[]) => translate(en,
    ', jossa pääset päivittämään nykyisen Elisan puhelinliittymäsi.',
    ...val),

  ARET: (en: string, ...val: string[]) => translate(en,
    'Kuinka paljon saan käyttää dataa ulkomailla?',
    ...val),

  AREU: (en: string, ...val: string[]) => translate(en,
    'Elisan Yritysliittymät sisältävät rajattoman datan Ruotsissa, Norjassa, Tanskassa, Virossa, Latviassa ja Liettuassa.',
    ...val),

  AREV: (en: string, ...val: string[]) => translate(en,
    'Lisäksi jokaiseen liittymäpakettiin kuuluu reilusti dataa EU- ja ETA-maihin.',
    ...val),

  AREW: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjä voi tarkistaa liittymän EU- ja ETA-datamäärän ',
    ...val),

  AREX: (en: string, ...val: string[]) => translate(en,
    ' ja työntekijä näkee sen ',
    ...val),

  AREY: (en: string, ...val: string[]) => translate(en,
    'Muissa maissa hinta vaihtelee kohdemaan mukaan. Lisäksi voit hankkia edullisen Reissaaja-datapaketin kaukomaille.',
    ...val),

  AREZ: (en: string, ...val: string[]) => translate(en,
    'Lue puhelimen käytöstä ulkomailla',
    ...val),

  ARF0: (en: string, ...val: string[]) => translate(en,
    'Voinko pitää nykyisen numeroni, jos siirrän liittymäni Elisalle?',
    ...val),

  ARF1: (en: string, ...val: string[]) => translate(en,
    'Saat säilytettyä nykyisen numerosi, kun vaihdat kännykkäliittymäsi Elisalle. Tilausvaiheessa valitse vain ',
    ...val),

  ARF2: (en: string, ...val: string[]) => translate(en,
    '"Säilytä numerosi"',
    ...val),

  ARF3: (en: string, ...val: string[]) => translate(en,
    'Pitääkö minun itse irtisanoa vanha liittymäni?',
    ...val),

  ARF4: (en: string, ...val: string[]) => translate(en,
    'Kun vaihdat toiselta operaattorilta liittymäsi, sinun ei tarvitse olla yhteydessä vanhaan operaattoriisi. Me hoidamme liittymän siirron Elisalle puolestasi.',
    ...val),

  ARF5: (en: string, ...val: string[]) => translate(en,
    'Miten liittymän omistussuhde muutetaan?',
    ...val),

  ARF6: (en: string, ...val: string[]) => translate(en,
    'Lue ohjeet ',
    ...val),

  ARF7: (en: string, ...val: string[]) => translate(en,
    'liittymän omistussuhteen muutoksesta',
    ...val),

  ARF8: (en: string, ...val: string[]) => translate(en,
    'Miten saan puhelinliittymän käyttöön?',
    ...val),

  ARF9: (en: string, ...val: string[]) => translate(en,
    'Tilauksen jälkeen saat tilauksesta tilausvahvistuksen.',
    ...val),

  ARFA: (en: string, ...val: string[]) => translate(en,
    'Toimitamme SIM-kortin etukäteen ja lähetämme käyttäjän puhelimeen ',
    ...val),

  ARFB: (en: string, ...val: string[]) => translate(en,
    'tarkan siirtoajan',
    ...val),

  ARFC: (en: string, ...val: string[]) => translate(en,
    ', milloin uusi liittymä on toiminnassa ja SIM-kortin voi vaihtaa puhelimeen.',
    ...val),

  ARFD: (en: string, ...val: string[]) => translate(en,
    'Jos olet vaihtanut liittymän toiselta operaattorilta Elisalle, sinun ei tarvitse olla yhteydessä vanhaan operaattoriisi. Me hoidamme liittymän siirron puolestasi.',
    ...val),

  ARFE: (en: string, ...val: string[]) => translate(en,
    'Lue ohjeet liittymän käyttöönottoon',
    ...val),

  ARFF: (en: string, ...val: string[]) => translate(en,
    'Jos sinulla on kysyttävää, voit kysyä apua myös ',
    ...val),

  ARFG: (en: string, ...val: string[]) => translate(en,
    'asiakaspalvelustamme',
    ...val),

  ARFH: (en: string, ...val: string[]) => translate(en,
    'Mistä löydän PIN- ja PUK-koodin?',
    ...val),

  ARFI: (en: string, ...val: string[]) => translate(en,
    'Oletus PIN-koodi Elisan yritysliittymissä on 1234.',
    ...val),

  ARFJ: (en: string, ...val: string[]) => translate(en,
    'Puhelimen asetuksissa voit vaihtaa PIN-koodin toiseen.',
    ...val),

  ARFK: (en: string, ...val: string[]) => translate(en,
    'Kun olet työntekijä, löydät PUK-koodisi ',
    ...val),

  ARFL: (en: string, ...val: string[]) => translate(en,
    'Kun olet yrittäjä tai yrityksesi nimenkirjoitusoikeudellinen henkilö, löydät PUK-koodin ',
    ...val),

  ARFM: (en: string, ...val: string[]) => translate(en,
    'Lue ohjeet PIN- ja PUK-koodista',
    ...val),

  ARFN: (en: string, ...val: string[]) => translate(en,
    'Miten tilaan uusia SIM-kortteja ja aktivoin ne?',
    ...val),

  ARFO: (en: string, ...val: string[]) => translate(en,
    'Voit tilata ',
    ...val),

  ARFP: (en: string, ...val: string[]) => translate(en,
    'uusia SIM-kortteja verkosta',
    ...val),

  ARFQ: (en: string, ...val: string[]) => translate(en,
    ' tai ',
    ...val),

  ARFR: (en: string, ...val: string[]) => translate(en,
    ' Lue tarkemmat ohjeet, ',
    ...val),

  ARFS: (en: string, ...val: string[]) => translate(en,
    'miten SIM-kortin tilaus tapahtuu',
    ...val),

  ARFT: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin voi aktivoida yrityksen pääkäyttäjä tai työntekijä.',
    ...val),

  ARFV: (en: string, ...val: string[]) => translate(en,
    'Uusi numero',
    ...val),

  ARFW: (en: string, ...val: string[]) => translate(en,
    'Säilytä numerosi',
    ...val),

  ARFX: (en: string, ...val: string[]) => translate(en,
    '– valitse tämä, kun haluat uuden numeron liittymääsi.',
    ...val),

  ARFY: (en: string, ...val: string[]) => translate(en,
    '– valitse tämä, kun haluat siirtää nykyisen numerosi toiselta operaattorilta Elisalle.',
    ...val),

  ARG2: (en: string, ...val: string[]) => translate(en,
    '5G-alueen ulkopuolella saat käyttöösi nopean 4G-verkon valitun liittymänopeuden mukaisesti.',
    ...val),

  AS6H: (en: string, ...val: string[]) => translate(en,
    'Henkilön tiedot',
    ...val),

  AS6N: (en: string, ...val: string[]) => translate(en,
    'Sinulla on käytössä Elisa Ring -tunnus.',
    ...val),

  ASEI: (en: string, ...val: string[]) => translate(en,
    'Kertamaksu',
    ...val),

  ASQT: (en: string, ...val: string[]) => translate(en,
    'Tila',
    ...val),

  ASTA: (en: string, ...val: string[]) => translate(en,
    ', ohjeet löytyvät ',
    ...val),

  ASXB: (en: string, ...val: string[]) => translate(en,
    'Toiminnot',
    ...val),

  AT96: (en: string, ...val: string[]) => translate(en,
    'Virheellinen postinumero',
    ...val),

  ATD5: (en: string, ...val: string[]) => translate(en,
    'Lisätietoja:',
    ...val),

  ATDL: (en: string, ...val: string[]) => translate(en,
    'Salasana on pakollinen',
    ...val),

  AUA3: (en: string, ...val: string[]) => translate(en,
    'Tilaukseni',
    ...val),

  AUI8: (en: string, ...val: string[]) => translate(en,
    'Laskutus',
    ...val),

  AURX: (en: string, ...val: string[]) => translate(en,
    'Palaamme asiaan niin pian kuin mahdollista.',
    ...val),

  AV0P: (en: string, ...val: string[]) => translate(en,
    'Yritystietoturva',
    ...val),

  AWEN: (en: string, ...val: string[]) => translate(en,
    'Peruskausi',
    ...val),

  AWNU: (en: string, ...val: string[]) => translate(en,
    'Laitteiden sopimuskaudet',
    ...val),

  AWTH: (en: string, ...val: string[]) => translate(en,
    'EPP lunastuspyyntö',
    ...val),

  AX9O: (en: string, ...val: string[]) => translate(en,
    'Liikasuoritus',
    ...val),

  AXSD: (en: string, ...val: string[]) => translate(en,
    'Alla näet ajastetut muutokset, jotka julkaistaan {}. Voit poistaa muutospyynnön tai ajastaa sen uudelleen. Jos haluat muokata näitä muutoksia, poista ajastettu muutos ja luo kokonaan uusi muutospyyntö.',
    ...val),

  AYKR: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä asiakaspalveluun',
    ...val),

  AYSW: (en: string, ...val: string[]) => translate(en,
    'Elisa Yritystietoturva',
    ...val),

  AZ26: (en: string, ...val: string[]) => translate(en,
    'Liikkuva netti kulkee mukana etätöissä ja reissussa',
    ...val),

  B0GP: (en: string, ...val: string[]) => translate(en,
    'Laitelistan avulla voit määrittää yrityksesi laitevalikoiman. Laitelistoja voi olla useampi ja voit valita listalle yhden tai useamman laitteen. Tilaukselle ei voi valita tuotteita eri katalogeista, joten huomioithan tämän luodessasi uusia katalogeja. Voit myös asettaa listalle hintarajan, jonka yritys maksaa laitteesta kuukaudessa. OmaLasku -palvelun ollessa käytössä, työntekijä voi valita myös hintarajan ylittävän laitteen, jolloin hän maksaa erotuksen itse.',
    ...val),

  B0GQ: (en: string, ...val: string[]) => translate(en,
    'Näet hakutulokset kaikista yrityksistä, joihin sinulla on pääkäyttäjäoikeudet.',
    ...val),

  B0M1: (en: string, ...val: string[]) => translate(en,
    'Luo salasana',
    ...val),

  B0MA: (en: string, ...val: string[]) => translate(en,
    'Tarkenna hakua tai siirry katsomaan kaikki hakutulokset alla olevan napin kautta.',
    ...val),

  B0MB: (en: string, ...val: string[]) => translate(en,
    'Lue lisätietoja hinnoista ja liittymän käytöstä ulkomailla',
    ...val),

  B100: (en: string, ...val: string[]) => translate(en,
    'Vai oletko kuluttaja?',
    ...val),

  B101: (en: string, ...val: string[]) => translate(en,
    'Katso Laitenetin vaihtoehdot kuluttajalle',
    ...val),

  B102: (en: string, ...val: string[]) => translate(en,
    'Paikannuslaitteet',
    ...val),

  B103: (en: string, ...val: string[]) => translate(en,
    'Tutustu myös muihin IoT-laitteisiin:',
    ...val),

  B104: (en: string, ...val: string[]) => translate(en,
    'Laitenettiliittymien tarkemmat ominaisuudet',
    ...val),

  B105: (en: string, ...val: string[]) => translate(en,
    'Puhelut ja tekstarit',
    ...val),

  B106: (en: string, ...val: string[]) => translate(en,
    'Puhelut normaalihintaisiin kotimaan lanka- ja matkapuhelinliittymiin {} €/min',
    ...val),

  B107: (en: string, ...val: string[]) => translate(en,
    'Liittymän kk-hintaan sisältyy {} kpl SMS/MMS-viestejä',
    ...val),

  B108: (en: string, ...val: string[]) => translate(en,
    'Ylimenevät tekstiviestit {} €/kpl',
    ...val),

  B109: (en: string, ...val: string[]) => translate(en,
    'Ylimenevät multimediaviestit {} €/kpl',
    ...val),

  B10A: (en: string, ...val: string[]) => translate(en,
    'Puhelu-, tekstiviesti- ja mobiilimaksuestoista hyötyesto (P1) on oletuksena käytössä.',
    ...val),

  B10B: (en: string, ...val: string[]) => translate(en,
    'Nettiyhteys',
    ...val),

  B10C: (en: string, ...val: string[]) => translate(en,
    'Rajaton netti kotimaassa',
    ...val),

  B10D: (en: string, ...val: string[]) => translate(en,
    'Toimii 2G- ja 4G-verkoissa, Laitenetti 5G toimii myös 5G-verkossa ',
    ...val),

  B10E: (en: string, ...val: string[]) => translate(en,
    'Elisa Laitenetti -liittymät on tarkoitettu vain tilapäisiin, korkeintaan {} kuukautta kestäviin verkkovierailuihin ulkomailla. Elisalla on oikeus seurata liittymien käyttöä ja tarvittaessa estää pysyvä käyttö ulkomailla. ',
    ...val),

  B10F: (en: string, ...val: string[]) => translate(en,
    'Ehdot',
    ...val),

  B10G: (en: string, ...val: string[]) => translate(en,
    'Ei määräaikaisuutta',
    ...val),

  B10H: (en: string, ...val: string[]) => translate(en,
    'Voit irtisanoa liittymän milloin vain',
    ...val),

  B152: (en: string, ...val: string[]) => translate(en,
    'Sisäiset ja ulkoiset puhelut',
    ...val),

  B1SI: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä myyntiimme',
    ...val),

  B2B9: (en: string, ...val: string[]) => translate(en,
    'Arkisto',
    ...val),

  B2V1: (en: string, ...val: string[]) => translate(en,
    'Peruuta',
    ...val),

  B3FX: (en: string, ...val: string[]) => translate(en,
    'Miten palvelu maksetaan?',
    ...val),

  B3IH: (en: string, ...val: string[]) => translate(en,
    'Liittymäni-näkymästä voit tarkastaa liittymiesi PUK-koodit koska tahansa. Klikkaamalla liittymää näet myös PUK2-koodin.',
    ...val),

  B3MG: (en: string, ...val: string[]) => translate(en,
    'kpl',
    ...val),

  B4NE: (en: string, ...val: string[]) => translate(en,
    'Toiminto epäonnistui',
    ...val),

  B4ZV: (en: string, ...val: string[]) => translate(en,
    'Tuotteen tiedot',
    ...val),

  B5AC: (en: string, ...val: string[]) => translate(en,
    'ReCAPTCHA-varmennus on pakollinen',
    ...val),

  B5VB: (en: string, ...val: string[]) => translate(en,
    'Kotiosoite rivi 1',
    ...val),

  B69Q: (en: string, ...val: string[]) => translate(en,
    '{} Kbit/s',
    ...val),

  B7E8: (en: string, ...val: string[]) => translate(en,
    'Sopii sinulle, jos teet toisinaan etätöitä esim. mökillä. Reitittimeen, mobiilireitittimeen tai tablettiin sopiva SIM-kortti on helposti siirrettävissä laitteesta toiseen.',
    ...val),

  B7GD: (en: string, ...val: string[]) => translate(en,
    'tarkastaa ulkomaan puheluiden ja datan käyttörajat',
    ...val),

  B7GO: (en: string, ...val: string[]) => translate(en,
    'Haluatko varmasti poistaa omat pääkäyttäjäoikeudet?',
    ...val),

  B8A4: (en: string, ...val: string[]) => translate(en,
    '300 Mbit/s • 5000 min • 100 kpl',
    ...val),

  B8KE: (en: string, ...val: string[]) => translate(en,
    'Sinulla on käytössäsi Elisa Yritystietoturva -tilaus, joka mahdollistaa tietojen palauttamisen, jos laitteesi tai tiedostot ovat vaurioituneet. Luethan linkistä löytyvät ohjeet huolellisesti.',
    ...val),

  B951: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyyntö',
    ...val),

  B9A9: (en: string, ...val: string[]) => translate(en,
    'Valikoimastamme poistuneet tuotteet',
    ...val),

  B9EG: (en: string, ...val: string[]) => translate(en,
    'Laitteen lunastaminen',
    ...val),

  B9GC: (en: string, ...val: string[]) => translate(en,
    'Tietue poistettu',
    ...val),

  B9LR: (en: string, ...val: string[]) => translate(en,
    'Palautettava laite',
    ...val),

  BA64: (en: string, ...val: string[]) => translate(en,
    'Sinulla ei ole liittymiä.',
    ...val),

  BBNI: (en: string, ...val: string[]) => translate(en,
    'täältä',
    ...val),

  BBPX: (en: string, ...val: string[]) => translate(en,
    'Ohjelmistoversio',
    ...val),

  BC0X: (en: string, ...val: string[]) => translate(en,
    'Ei saatavuustietoa',
    ...val),

  BC3P: (en: string, ...val: string[]) => translate(en,
    'Sinulla ei ole eSIMiä tilattuna tälle liittymälle.',
    ...val),

  BCAG: (en: string, ...val: string[]) => translate(en,
    'Tekstiviestipaketti 5000',
    ...val),

  BCB6: (en: string, ...val: string[]) => translate(en,
    'Olemme pyytäneet lisätietoa tukipyynnön avanneelta käyttäjältä sähköpostitse. Vastaattehan mahdollisimman pian, niin pääsemme jatkamaan asianne käsittelyä.',
    ...val),

  BCFF: (en: string, ...val: string[]) => translate(en,
    'Sinulla on käytössä useampi käyttäjätunnus OmaElisaan. Valitse tunnus jota haluat käyttää kirjautuessasi OmaElisaan. Tietoturvasyistä, suosittelemme, että valitset sähköpostimuotoisen tunnuksen.',
    ...val),

  BCPB: (en: string, ...val: string[]) => translate(en,
    'Sopimusehdot',
    ...val),

  BCWX: (en: string, ...val: string[]) => translate(en,
    'Elisa Päätelaitepalvelu, {} kk sopimus',
    ...val),

  BDCT: (en: string, ...val: string[]) => translate(en,
    'Sallitut tiedostomuodot: {}',
    ...val),

  BDHS: (en: string, ...val: string[]) => translate(en,
    'Palveluun kuuluu 500Mt datakäyttöä ja se on voimassa 24 tunnin ajan, tai kunnes 500Mt käyttö on täynnä. Elisa Reissaaja -palvelu aktivoituu, kun datakäyttö ulkomaisessa verkossa alkaa. Palvelun käytöstä lähetetään ilmoituksia, kun paketista on käytetty 80%, 95% ja 100%. Uusi paketti aktivoituu automaattisesti.',
    ...val),

  BDQD: (en: string, ...val: string[]) => translate(en,
    'Työntekijöiden pääsy yritysten OmaElisaan on estetty. Mikäli olet työsuhdeliittymääsi tai muita palveluitasi hallinnoimassa, voit tehdä sen',
    ...val),

  BE0G: (en: string, ...val: string[]) => translate(en,
    'Ohjeita laskutuskysymyksiin',
    ...val),

  BE4A: (en: string, ...val: string[]) => translate(en,
    'Käyttäjät',
    ...val),

  BE8Q: (en: string, ...val: string[]) => translate(en,
    'Ostoskori',
    ...val),

  BEKE: (en: string, ...val: string[]) => translate(en,
    'Huomioitavaa',
    ...val),

  BF36: (en: string, ...val: string[]) => translate(en,
    'Maksu keskeytetty',
    ...val),

  BFH0: (en: string, ...val: string[]) => translate(en,
    'Hyväksymämme maksutavat ovat luottokortti (Visa, Mastercard) ja MobilePay.',
    ...val),

  BFV5: (en: string, ...val: string[]) => translate(en,
    'Kutsun lähetys työntekijälle onnistui.',
    ...val),

  BG4W: (en: string, ...val: string[]) => translate(en,
    'Muokkaa lisäpalveluita',
    ...val),

  BHBQ: (en: string, ...val: string[]) => translate(en,
    'Siirry kontaktin tietoihin',
    ...val),

  BHMU: (en: string, ...val: string[]) => translate(en,
    'Arkipäivisin ennen klo 12 tehdyt tilaukset ehtivät pääsääntöisesti seuraavan arkipaivan toimitukseen.',
    ...val),

  BHWT: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että tulet saamaan loppulaskun irtisanotusta liittymästä, mikäli käyttö- tai kuukausimaskuja on vielä laskuttamatta.',
    ...val),

  BIS7: (en: string, ...val: string[]) => translate(en,
    'Tuotteet tilauksella',
    ...val),

  BJ25: (en: string, ...val: string[]) => translate(en,
    '1,60 €/kk/käyttäjä (alv. 0 %)',
    ...val),

  BJ43: (en: string, ...val: string[]) => translate(en,
    'Lähetimme vahvistuslinkin sähköpostiosoitteeseesi {} otsikolla "Tervetuloa Työntekijän OmaElisaan". Tarkista myös mahdollinen roskapostisi. \nKlikkaa "Vahvista linkkiä" vahvistaaksesi sähköpostiosoitteesi. Tämän jälkeen voit käyttää Työntekijän Oma Elisaa.',
    ...val),

  BJ8A: (en: string, ...val: string[]) => translate(en,
    'Muut asiointikanavat',
    ...val),

  BJRZ: (en: string, ...val: string[]) => translate(en,
    'Asiakkaan pääkäyttäjä luo aliasprofiilin KME Admin Portaalissa ja toimittaa sen tilauksen yhteydessä jälleenmyyjälle. Alias on aina jälleenmyyjäkohtainen, samaa aliasnimeä ei voi käyttää eri jälleenmyyjillä',
    ...val),

  BK58: (en: string, ...val: string[]) => translate(en,
    'Kirjoita viestisi, liittymän numero ja irtisanomispäivämäärä tähän...',
    ...val),

  BKNH: (en: string, ...val: string[]) => translate(en,
    'Maksuerien määrä',
    ...val),

  BKPE: (en: string, ...val: string[]) => translate(en,
    'Muuttuvan sopimuksen numero',
    ...val),

  BLLL: (en: string, ...val: string[]) => translate(en,
    'Vain ulkopuheluilla',
    ...val),

  BLQP: (en: string, ...val: string[]) => translate(en,
    'Elisa Ring tuo matkapuhelimet luontevasti osaksi yrityksen puhelinjärjestelmää.',
    ...val),

  BMK4: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän puhelinnumero',
    ...val),

  BN1I: (en: string, ...val: string[]) => translate(en,
    'Jos virhe toistuu, ota yhteyttä asiakaspalveluun',
    ...val),

  BN1U: (en: string, ...val: string[]) => translate(en,
    'Mobiililiittymän lisänopeus tulee käyttöösi heti tilauksen jälkeen. Ilmoitamme tekstiviestillä, kun valitsemasi paketti on päättymässä.',
    ...val),

  BNAZ: (en: string, ...val: string[]) => translate(en,
    'Maksupäivän siirto veloitetaan',
    ...val),

  BO8J: (en: string, ...val: string[]) => translate(en,
    'Vahvista tilauksen hyväksyminen',
    ...val),

  BOIN: (en: string, ...val: string[]) => translate(en,
    'Ei laskutukseen liittyviä kirjeitä',
    ...val),

  BON5: (en: string, ...val: string[]) => translate(en,
    'Luo uusi salasana',
    ...val),

  BPM8: (en: string, ...val: string[]) => translate(en,
    'Suosituimmat lisätarvikkeet',
    ...val),

  BPVG: (en: string, ...val: string[]) => translate(en,
    'Laskusi toimitetaan sähköpostilla. Löydät laskukopion ##henkilöasiakkaiden Oma Elisasta##, jonka kautta voit halutessasi myös maksaa laskun.',
    ...val),

  BQ9Y: (en: string, ...val: string[]) => translate(en,
    'Katso tarkemmat tiedot',
    ...val),

  BQK6: (en: string, ...val: string[]) => translate(en,
    'Oletko soittanut palvelunumeroihin?',
    ...val),

  BQMC: (en: string, ...val: string[]) => translate(en,
    'Julkaisemattomia muutoksia',
    ...val),

  BQO3: (en: string, ...val: string[]) => translate(en,
    'Tarkista, että luot uuden henkilökontaktin sen yrityksen alle, jossa henkilö työskentelee. Yritysvalinta määritetään Yritysten OmaElisan etusivun oikeasta yläkulmasta.',
    ...val),

  BQS1: (en: string, ...val: string[]) => translate(en,
    'Elisalta löydät luotettavat nettiyhteydet myös isompiin tiloihin ja vaativampiin tarpeisiin.',
    ...val),

  BR9A: (en: string, ...val: string[]) => translate(en,
    'Valitse ensin yritys',
    ...val),

  BRFX: (en: string, ...val: string[]) => translate(en,
    'Laskunumero',
    ...val),

  BRHY: (en: string, ...val: string[]) => translate(en,
    'Tee uusi tilaus vasta kuin avoin tilaus on valmis. ',
    ...val),

  BRWO: (en: string, ...val: string[]) => translate(en,
    'Valitse toivomasi siirron ajankohta',
    ...val),

  BS5C: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän aiempi siirto uuteen yritykseen on edelleen käsiteltävänä.',
    ...val),

  BSE3: (en: string, ...val: string[]) => translate(en,
    'Toimitus',
    ...val),

  BSHJ: (en: string, ...val: string[]) => translate(en,
    'Pyydä uusi vahvistuskoodi',
    ...val),

  BTEU: (en: string, ...val: string[]) => translate(en,
    'Oma myyjäsi',
    ...val),

  BTJ9: (en: string, ...val: string[]) => translate(en,
    'tukipyynnön',
    ...val),

  BTMU: (en: string, ...val: string[]) => translate(en,
    'Ei asetettu',
    ...val),

  BTQA: (en: string, ...val: string[]) => translate(en,
    'Katalogin julkaisu onnistui',
    ...val),

  BTTL: (en: string, ...val: string[]) => translate(en,
    'Työntekijä voi nähdä liittymän perustiedot ja käyttää laajakaistakorjainta vian määrittämiseen.',
    ...val),

  BUMO: (en: string, ...val: string[]) => translate(en,
    'rajaton',
    ...val),

  BV5F: (en: string, ...val: string[]) => translate(en,
    'toimitusehdot',
    ...val),

  BVO5: (en: string, ...val: string[]) => translate(en,
    'Laskutuskausi',
    ...val),

  BW68: (en: string, ...val: string[]) => translate(en,
    'Portti',
    ...val),

  BWTQ: (en: string, ...val: string[]) => translate(en,
    'Vapaa',
    ...val),

  BX0B: (en: string, ...val: string[]) => translate(en,
    'Syötä 5 numeroinen postinumero',
    ...val),

  BXJH: (en: string, ...val: string[]) => translate(en,
    'Numerosi siirretään uudelle omistajalle.',
    ...val),

  BZAA: (en: string, ...val: string[]) => translate(en,
    'Vahvista ja maksa',
    ...val),

  BZGP: (en: string, ...val: string[]) => translate(en,
    'Kauppa',
    ...val),

  BZWY: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että jos olet lisännyt uuden laskutussopimuksen se on käytettävissä heti.',
    ...val),

  BZYZ: (en: string, ...val: string[]) => translate(en,
    'Verkkokauppa',
    ...val),

  C001: (en: string, ...val: string[]) => translate(en,
    'Tilaus',
    ...val),

  C02I: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteiden elinkaari',
    ...val),

  C0L4: (en: string, ...val: string[]) => translate(en,
    'Jos haluat lisätietoja Netti Litestä, ota yhteyttä myyntiimme:',
    ...val),

  C0QM: (en: string, ...val: string[]) => translate(en,
    'Lähetä irtisanomispyyntö',
    ...val),

  C0WF: (en: string, ...val: string[]) => translate(en,
    'Saat digiturvaa yleisintä tietoturvauhkaa eli tietojen kalastelua (phishing) vastaan käyttäessäsi nettiä 4G- tai 5G-mobiiliverkossa.',
    ...val),

  C1R6: (en: string, ...val: string[]) => translate(en,
    'Raportin luomisessa tapahtui virhe. Pyydä raportti uudelleen.',
    ...val),

  C2KQ: (en: string, ...val: string[]) => translate(en,
    'Vähennä',
    ...val),

  C2SK: (en: string, ...val: string[]) => translate(en,
    'Vahvista omistajanvaihto',
    ...val),

  C3XX: (en: string, ...val: string[]) => translate(en,
    'Voit aina olla yhteydessä asiakaspalveluumme',
    ...val),

  C499: (en: string, ...val: string[]) => translate(en,
    'Ole hyvä ja valitse toimitusmuoto.',
    ...val),

  C49W: (en: string, ...val: string[]) => translate(en,
    'Liittymätyypin päivitys',
    ...val),

  C4YQ: (en: string, ...val: string[]) => translate(en,
    'Gt',
    ...val),

  C6OR: (en: string, ...val: string[]) => translate(en,
    'Laitekauppa',
    ...val),

  C6WY: (en: string, ...val: string[]) => translate(en,
    'Muu',
    ...val),

  C7PN: (en: string, ...val: string[]) => translate(en,
    'Tee-tukipyynto',
    ...val),

  C7R5: (en: string, ...val: string[]) => translate(en,
    'OK, JATKA',
    ...val),

  C84H: (en: string, ...val: string[]) => translate(en,
    'Tuote',
    ...val),

  C8BR: (en: string, ...val: string[]) => translate(en,
    'Linkki on vanhentunut tai se on virheellinen.',
    ...val),

  C8DA: (en: string, ...val: string[]) => translate(en,
    'PopUp -viesti työntekijälle',
    ...val),

  C8I6: (en: string, ...val: string[]) => translate(en,
    'Sopimus',
    ...val),

  C9DH: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä ei ole laitteita.',
    ...val),

  C9XG: (en: string, ...val: string[]) => translate(en,
    'Odota hetki...',
    ...val),

  CAA9: (en: string, ...val: string[]) => translate(en,
    'Sopimuksen kesto',
    ...val),

  CACI: (en: string, ...val: string[]) => translate(en,
    'Edellinen',
    ...val),

  CACT: (en: string, ...val: string[]) => translate(en,
    'Tuleeko muutos heti voimaan',
    ...val),

  CAQ7: (en: string, ...val: string[]) => translate(en,
    'QR-koodi',
    ...val),

  CATY: (en: string, ...val: string[]) => translate(en,
    'https://elisa.fi/yhtiotieto/',
    ...val),

  CBBN: (en: string, ...val: string[]) => translate(en,
    'Valitse Maksutapa',
    ...val),

  CBCC: (en: string, ...val: string[]) => translate(en,
    'Kiitos viestistä',
    ...val),

  CC7C: (en: string, ...val: string[]) => translate(en,
    'Voit seurata tilausta ',
    ...val),

  CCB1: (en: string, ...val: string[]) => translate(en,
    'Numero',
    ...val),

  CCR4: (en: string, ...val: string[]) => translate(en,
    'Liittymä ei ole yhteensopiva Vakio-ratkaisun kanssa.',
    ...val),

  CCRE: (en: string, ...val: string[]) => translate(en,
    'Kaikkiin Netti Lite 4G/5G-liittymiin kuuluu oletuksena tietoturvallinen, ei-julkinen IP-osoite. Jos tarvitset julkisen IP-osoitteen, saat tilattua sen lisäpalveluna.',
    ...val),

  CCZ4: (en: string, ...val: string[]) => translate(en,
    'Näytä kaikki laskutussopimukset',
    ...val),

  CD0S: (en: string, ...val: string[]) => translate(en,
    'Ei laitteita',
    ...val),

  CD11: (en: string, ...val: string[]) => translate(en,
    'Ei laskun viitetietoja',
    ...val),

  CDCD: (en: string, ...val: string[]) => translate(en,
    'Henkilöasiakkaat',
    ...val),

  CDME: (en: string, ...val: string[]) => translate(en,
    'Palvelu',
    ...val),

  CEHN: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä yrityksesi pääkäyttäjään.',
    ...val),

  CEQ2: (en: string, ...val: string[]) => translate(en,
    'Yhteensä',
    ...val),

  CF93: (en: string, ...val: string[]) => translate(en,
    '{} kk sopimus',
    ...val),

  CFD2: (en: string, ...val: string[]) => translate(en,
    'lisäpalvelut yritysliittymiimme',
    ...val),

  CFVF: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi ei onnistunut, koska liittymäsi kotimaan datakäyttö on estetty. Eston voi poistaa OmaElisa -palvelussa tai soittamalla asiakaspalveluun.',
    ...val),

  CFX9: (en: string, ...val: string[]) => translate(en,
    'Latausnopeus max. {0}M, lähetysnopeus max. {1}M',
    ...val),

  CG4F: (en: string, ...val: string[]) => translate(en,
    'Omat palvelut',
    ...val),

  CH87: (en: string, ...val: string[]) => translate(en,
    'Palvelunumerot',
    ...val),

  CHUM: (en: string, ...val: string[]) => translate(en,
    'Voit nyt kirjautua Yritysten OmaElisaan uudella salasanallasi.',
    ...val),

  CHWD: (en: string, ...val: string[]) => translate(en,
    'Muu aihe',
    ...val),

  CI5R: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän sähköpostiosoite',
    ...val),

  CJOF: (en: string, ...val: string[]) => translate(en,
    'eSIMiä ei voi tilata tälle liittymälle.',
    ...val),

  CJT5: (en: string, ...val: string[]) => translate(en,
    'Luo tunnukset ja kirjaudu',
    ...val),

  CKF5: (en: string, ...val: string[]) => translate(en,
    'Rekisteröidy Yritysten OmaElisaan',
    ...val),

  CL9N: (en: string, ...val: string[]) => translate(en,
    'Sopimus päättynyt',
    ...val),

  CL9O: (en: string, ...val: string[]) => translate(en,
    'Liittymän kuukausimaksut tilauksen jälkeen {0}/kk',
    ...val),

  CLAY: (en: string, ...val: string[]) => translate(en,
    ' että vahvistettuasi tilauksen, et voi enää muuttaa mieltäsi ja lunastaa vanhaa laitettasi.',
    ...val),

  CLD7: (en: string, ...val: string[]) => translate(en,
    'Yhdistelmä SIM-kortissa samaan korttipohjaan on yhdistetty kolme SIM-korttikokoa: Mini, Macro ja Nano. SIM-korttien PIN-koodi oletuksena 1234. Muistathan kuitenkin vaihtaa sen, kun otat liittymän käyttöön.',
    ...val),

  CLP6: (en: string, ...val: string[]) => translate(en,
    'Tarkista käyttäjätunnus ja salasana.',
    ...val),

  CMGJ: (en: string, ...val: string[]) => translate(en,
    'Valitse yksi vaihtoehdoista',
    ...val),

  CNNS: (en: string, ...val: string[]) => translate(en,
    'Vertaile ominaisuuksia',
    ...val),

  CNY8: (en: string, ...val: string[]) => translate(en,
    'Laitteella on käytössä Elisa Oma Laitelasku',
    ...val),

  CO0D: (en: string, ...val: string[]) => translate(en,
    'jätä tyhjäksi jos haluat uuden',
    ...val),

  CO7W: (en: string, ...val: string[]) => translate(en,
    'Estä kaikki datasiirto',
    ...val),

  COBB: (en: string, ...val: string[]) => translate(en,
    'Laitelistat',
    ...val),

  COPO: (en: string, ...val: string[]) => translate(en,
    'Maksu ei sisällä laitteen lunastushintaa, mikäli haluat lunastaa laitteen itsellesi sopimuskauden päätyttyä.',
    ...val),

  COTT: (en: string, ...val: string[]) => translate(en,
    'Siirry aktivoimaan',
    ...val),

  CPDO: (en: string, ...val: string[]) => translate(en,
    'Olet vaihtamassa sopijayritystä. Laskutussopimus ja käyttäjä on päivitettävä myös uuden sopijayrityksen mukaan. Mikäli haluat siirtää käyttäjän toiseen yritykseen, voit tehdä sen [Käyttäjät]-sivulta.',
    ...val),

  CQQ7: (en: string, ...val: string[]) => translate(en,
    'Lähetysnopeus',
    ...val),

  CRIM: (en: string, ...val: string[]) => translate(en,
    'Olemme ratkaisseet ja sulkeneet tukipyynnön.',
    ...val),

  CRW1: (en: string, ...val: string[]) => translate(en,
    'Hyväksy OmaLasku -palvelun {}',
    ...val),

  CSI7: (en: string, ...val: string[]) => translate(en,
    'Uusi salasana',
    ...val),

  CSK0: (en: string, ...val: string[]) => translate(en,
    'En muista sähköpostiosoitettani',
    ...val),

  CSOC: (en: string, ...val: string[]) => translate(en,
    'Huomaa, että jos yritykselläsi on Samsung KME-laiterekisteriohjelmassa useampi alias, jokaiselle aliakselle täytyy luoda oma laitelista.',
    ...val),

  CSTF: (en: string, ...val: string[]) => translate(en,
    'Lisää kontakti',
    ...val),

  CTBU: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen vaihto onnistui',
    ...val),

  CTMX: (en: string, ...val: string[]) => translate(en,
    'Luo tunnus',
    ...val),

  CU9Z: (en: string, ...val: string[]) => translate(en,
    'Dataestot',
    ...val),

  CUDY: (en: string, ...val: string[]) => translate(en,
    'Kohde soittaessa alanumeroon',
    ...val),

  CUE7: (en: string, ...val: string[]) => translate(en,
    'Muutoshistoria',
    ...val),

  CUJ5: (en: string, ...val: string[]) => translate(en,
    'Laite on jatkokaudella',
    ...val),

  CUQU: (en: string, ...val: string[]) => translate(en,
    'Näytä erittely',
    ...val),

  CV0S: (en: string, ...val: string[]) => translate(en,
    'Katso PUK-koodit',
    ...val),

  CV2A: (en: string, ...val: string[]) => translate(en,
    'Mobiilireititin',
    ...val),

  CV5H: (en: string, ...val: string[]) => translate(en,
    'Kategoria',
    ...val),

  CVAH: (en: string, ...val: string[]) => translate(en,
    'Hinta käyttäjälle',
    ...val),

  CVJF: (en: string, ...val: string[]) => translate(en,
    'Löysimme käyttäjän, jolla on samoja yhteystietoja',
    ...val),

  CVON: (en: string, ...val: string[]) => translate(en,
    'Tilaa SIM',
    ...val),

  CVSQ: (en: string, ...val: string[]) => translate(en,
    'Suurten tiedostojen nopea siirto, live-striimaus ja vaativa etätyö',
    ...val),

  CW9X: (en: string, ...val: string[]) => translate(en,
    'Laitteen lunastaminen vaatii työnantajasi hyväksynnän. Lähetämme lunastuspyynnön työnantajallesi automaattisesti. Hyväksynnän jälkeen saat laskun sähköpostiisi.',
    ...val),

  CWR5: (en: string, ...val: string[]) => translate(en,
    'Viite- tai lisätieto',
    ...val),

  CYVE: (en: string, ...val: string[]) => translate(en,
    'Rajaton netin käyttö kotimaassa',
    ...val),

  CYY2: (en: string, ...val: string[]) => translate(en,
    'Ruotsi',
    ...val),

  CYZV: (en: string, ...val: string[]) => translate(en,
    'Ostoskorissa 1 tuote',
    ...val),

  CZ89: (en: string, ...val: string[]) => translate(en,
    'Saatavuus',
    ...val),

  CZNY: (en: string, ...val: string[]) => translate(en,
    'Luo uusi viesti tai tukipyyntö',
    ...val),

  CZS5: (en: string, ...val: string[]) => translate(en,
    'Apple laiterekisteröintiohjelma',
    ...val),

  D030: (en: string, ...val: string[]) => translate(en,
    'Jätä yhteydenottopyyntö',
    ...val),

  D0GE: (en: string, ...val: string[]) => translate(en,
    'Vakio-asetukset',
    ...val),

  D14J: (en: string, ...val: string[]) => translate(en,
    'Vastaajat työajan ulkopuolella',
    ...val),

  D1E9: (en: string, ...val: string[]) => translate(en,
    'Takaisin',
    ...val),

  D1IS: (en: string, ...val: string[]) => translate(en,
    'Päätä sopimus',
    ...val),

  D1RH: (en: string, ...val: string[]) => translate(en,
    'Ei laitelistoja. Ota yhteyttä työnantajasi pääkäyttäjään.',
    ...val),

  D1YH: (en: string, ...val: string[]) => translate(en,
    'henkilöasiakaspuolella',
    ...val),

  D205: (en: string, ...val: string[]) => translate(en,
    'Liittymän päivitys epäonnistui',
    ...val),

  D259: (en: string, ...val: string[]) => translate(en,
    'Liittymässä on määräaikainen sopimus {} saakka. Sopimuksen irtisanominen ei ole mahdollista ilman loppulaskua, jossa veloitetaan laskuttamatta jääneet kuukausiveloitukset.',
    ...val),

  D2O8: (en: string, ...val: string[]) => translate(en,
    'Power Book -ominaisuus',
    ...val),

  D2PS: (en: string, ...val: string[]) => translate(en,
    'Viestiestot',
    ...val),

  D2UO: (en: string, ...val: string[]) => translate(en,
    'liittymän',
    ...val),

  D2W8: (en: string, ...val: string[]) => translate(en,
    'Varaa aika',
    ...val),

  D2W9: (en: string, ...val: string[]) => translate(en,
    'Etätapaaminen yritysmyyjän kanssa',
    ...val),

  D30R: (en: string, ...val: string[]) => translate(en,
    'Palaa liittymäsivulle',
    ...val),

  D3IO: (en: string, ...val: string[]) => translate(en,
    'Ei kutsuja',
    ...val),

  D3MU: (en: string, ...val: string[]) => translate(en,
    'Liittymällä on käytössä lisäpalvelu ({}), joka estää palvelun käyttöönoton.',
    ...val),

  D467: (en: string, ...val: string[]) => translate(en,
    'Latausliittimen pohjaan voi pakkaantua likaa ja sen vuoksi lataus ei toimi. Tarkista latausliitin ja puhdista tarvittaessa esimerkiksi hammastikulla',
    ...val),

  D4FU: (en: string, ...val: string[]) => translate(en,
    'Toimitus kotiosoitteeseen',
    ...val),

  D4L5: (en: string, ...val: string[]) => translate(en,
    'Yritysnumeron ajastetulla työajalla käytössä',
    ...val),

  D4Y7: (en: string, ...val: string[]) => translate(en,
    'Tunnistaudu henkilökohtaisilla verkkopankkitunnuksilla tai Mobiilivarmenteella vahvistaaksesi liittymänumeron',
    ...val),

  D52K: (en: string, ...val: string[]) => translate(en,
    'Etusivu',
    ...val),

  D55A: (en: string, ...val: string[]) => translate(en,
    'Mikäli toivottu asennuspäivä jätetään täyttämättä, niin liittymä toimitetaan mahdollisimman pian. Toimitusaika vahvistetaan toimitusvahvistuksessa.',
    ...val),

  D55M: (en: string, ...val: string[]) => translate(en,
    'Sopimuskausi',
    ...val),

  D61P: (en: string, ...val: string[]) => translate(en,
    'Entistä tasalaatuisemmat yhteydet. Jopa 20 % parempi mobiililaitteen akun kesto.',
    ...val),

  D689: (en: string, ...val: string[]) => translate(en,
    'Anna osoite, johon lähetämme laskun. Saat ensimmäisen laskun postitse. Suosittelemme tekemään e-laskusopimuksen, jolloin saat laskut suoraan verkkopankkiisi.',
    ...val),

  D6IE: (en: string, ...val: string[]) => translate(en,
    'Voit hallinnoida palvelua henkilöasiakkaiden OmaElisassa.',
    ...val),

  D6Q4: (en: string, ...val: string[]) => translate(en,
    'Liittymä on väliaikaisesti suljettu',
    ...val),

  D7L8: (en: string, ...val: string[]) => translate(en,
    'Käsittelemme tukipyyntöä.',
    ...val),

  D7Y7: (en: string, ...val: string[]) => translate(en,
    'Laitteelle on tehty muutospyyntö, jonka käsittely on vielä kesken. Uusia muutoksia on mahdollista tehdä vasta, kun edellinen muutospyyntö on käsitelty. Huomaathan myös, että käsittelyssä oleva muutos tulee näkyviin vasta, kun muutospyyntö on käsitelty.',
    ...val),

  D8IX: (en: string, ...val: string[]) => translate(en,
    'Ohjeita käyttäjähallintaan',
    ...val),

  D8WR: (en: string, ...val: string[]) => translate(en,
    'Muutospyynnön lähetys onnistui. Muutoksen päivittyminen voi kestää noin vuorokauden.',
    ...val),

  D99O: (en: string, ...val: string[]) => translate(en,
    'Arkipäivisin ennen klo 12 tehdyt tilaukset ehtivät pääsääntöisesti seuraavan arkipäivän toimitukseen.',
    ...val),

  D9LE: (en: string, ...val: string[]) => translate(en,
    'Irtisano palvelu',
    ...val),

  D9NS: (en: string, ...val: string[]) => translate(en,
    'Voit lunastaa laitteen yritykselle tai tehdä lunastuspyynnön työntekijän puolesta, mikäli työntekijä on ottanut Omalaitelaskun käyttöönsä. Lunastushinta lasketaan reaaliaikaisesti. Laitteen lunastuksen työntekijä voi tehdä myös itse Työntekijän OmaElisasta yrityksille.elisa.fi/työntekijä.',
    ...val),

  D9PT: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikkalasku',
    ...val),

  D9TG: (en: string, ...val: string[]) => translate(en,
    'Yritysnumero',
    ...val),

  DA7N: (en: string, ...val: string[]) => translate(en,
    'Tarjoa työntekijöille vahva sähköinen tunnistusväline',
    ...val),

  DB2P: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisassa pystyt lähettämään liitteitä tukipyynnön mukana sekä seuraamaan tukipyyntöjen etenemistä.',
    ...val),

  DBJG: (en: string, ...val: string[]) => translate(en,
    'Yritykselläsi ei ole enää yritysnumeroita vapaana. Voit tilata lisää numeroita',
    ...val),

  DBQJ: (en: string, ...val: string[]) => translate(en,
    'Liittymänumero',
    ...val),

  DC0T: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä on tuotteita, joiden tilausta ei ole vielä käsitelty',
    ...val),

  DCA8: (en: string, ...val: string[]) => translate(en,
    'Jatka kirjautumatta',
    ...val),

  DCMM: (en: string, ...val: string[]) => translate(en,
    'Poissaoloketjuun vaaditaan ensimmäinen kohdenumero kun aikaohjaus on valittuna',
    ...val),

  DD38: (en: string, ...val: string[]) => translate(en,
    'Katuosoite',
    ...val),

  DD3Q: (en: string, ...val: string[]) => translate(en,
    'Tilaa eSIM',
    ...val),

  DDHX: (en: string, ...val: string[]) => translate(en,
    'Näytä vähemmän',
    ...val),

  DDU5: (en: string, ...val: string[]) => translate(en,
    'Rekisteröityminen epäonnistui',
    ...val),

  DFUR: (en: string, ...val: string[]) => translate(en,
    'Mahdollisimman pian',
    ...val),

  DHHN: (en: string, ...val: string[]) => translate(en,
    'omissa tiedoissasi',
    ...val),

  DHQ9: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka voi olla enintään 40 merkkiä pitkä',
    ...val),

  DI4V: (en: string, ...val: string[]) => translate(en,
    'Asennusosoite',
    ...val),

  DIBP: (en: string, ...val: string[]) => translate(en,
    'Roaming Datavartija',
    ...val),

  DILB: (en: string, ...val: string[]) => translate(en,
    'Tekstiviesti- ja mobiilimaksuestot',
    ...val),

  DIPB: (en: string, ...val: string[]) => translate(en,
    'Supernopea netti päivittäiseen työntekoon ja vapaa-ajan viihteeseen',
    ...val),

  DJ1Y: (en: string, ...val: string[]) => translate(en,
    'Odota hetki sulkematta selainta, tilaustasi aktivoidaan...',
    ...val),

  DJO2: (en: string, ...val: string[]) => translate(en,
    'Valittu lista',
    ...val),

  DKN6: (en: string, ...val: string[]) => translate(en,
    'viikon',
    ...val),

  DKUZ: (en: string, ...val: string[]) => translate(en,
    'Kuuluvuus, häiriöt ja viat',
    ...val),

  DKXU: (en: string, ...val: string[]) => translate(en,
    'Takaisin ostoskoriin',
    ...val),

  DL3L: (en: string, ...val: string[]) => translate(en,
    'Dataa ei löytynyt!',
    ...val),

  DLRT: (en: string, ...val: string[]) => translate(en,
    'Lomakkeen lähetyksessä tapahtui virhe. Ole hyvä ja yritä uudelleen tai ota yhteyttä yritysasiakaspalveluumme.',
    ...val),

  DMD6: (en: string, ...val: string[]) => translate(en,
    'Estää tekstiviesti- ja mobiilimaksamisen esim. pizza- ja matkalipputilauksissa. Estää myös osallistumisen puhelinlahjoituksiin sekä numerotiedustelujen ja tilauspalveluiden käytön (esim. juoma- tai elintarvikeautomaatit). Sisältää eston viihde- ja ajanvietenumeroihin.',
    ...val),

  DMJV: (en: string, ...val: string[]) => translate(en,
    'Luo uusi tietue',
    ...val),

  DMM5: (en: string, ...val: string[]) => translate(en,
    'Verkkolaitteet',
    ...val),

  DN45: (en: string, ...val: string[]) => translate(en,
    'Muut liittymätyypit',
    ...val),

  DNMO: (en: string, ...val: string[]) => translate(en,
    'Kiitos! eSIMin tilaus onnistui.',
    ...val),

  DO9X: (en: string, ...val: string[]) => translate(en,
    'Yhteensä {} osumaa',
    ...val),

  DOXL: (en: string, ...val: string[]) => translate(en,
    'Kohde',
    ...val),

  DP44: (en: string, ...val: string[]) => translate(en,
    'Liittymän numero',
    ...val),

  DPT5: (en: string, ...val: string[]) => translate(en,
    'Irroita ensin laitteen näytön päällä mahdollisesti oleva suojalasi ja/tai suojamuovi, tarkista onko näyttö rikki myös sen alla tai jatkuuko kosketusongelmat',
    ...val),

  DQ1Z: (en: string, ...val: string[]) => translate(en,
    'Vakio-ratkaisu liittymälle',
    ...val),

  DQDK: (en: string, ...val: string[]) => translate(en,
    'Jälkitoimituksena tilattavat tuotteet toimitetaan sitä mukaan, kun tuotteita on saatavilla varastossa. Toimitusmaksu veloitetaan vain kerran.',
    ...val),

  DQHY: (en: string, ...val: string[]) => translate(en,
    'Viite',
    ...val),

  DQQ1: (en: string, ...val: string[]) => translate(en,
    'Sopimustyyppi',
    ...val),

  DR5T: (en: string, ...val: string[]) => translate(en,
    'Ladataan lomaketta',
    ...val),

  DREM: (en: string, ...val: string[]) => translate(en,
    'Tila kertoo onko laskutussopimus aktiivinen eli käytössä vai passiivinen eli päättynyt.',
    ...val),

  DRHV: (en: string, ...val: string[]) => translate(en,
    'Hups… järjestelmässä tapahtui virhe.',
    ...val),

  DRVT: (en: string, ...val: string[]) => translate(en,
    'Voimassa 7 vrk',
    ...val),

  DRYH: (en: string, ...val: string[]) => translate(en,
    'Laskulle tulostuva maksajan postiosoite.',
    ...val),

  DS9O: (en: string, ...val: string[]) => translate(en,
    'Palvelu sisältää WIFI Mesh-laitteiden ({}) asennuksen optimaalisesti sijoiteltuna, jotta WIFI-verkko kattaa koko kodin/toimiston',
    ...val),

  DT3P: (en: string, ...val: string[]) => translate(en,
    'Siirtyminen portaaliin epäonnistui',
    ...val),

  DTLM: (en: string, ...val: string[]) => translate(en,
    'Sisältää 500kpl viestiä Pohjolassa ja Baltiassa, ylimenevät viestit 0,079 €/kpl',
    ...val),

  DTYT: (en: string, ...val: string[]) => translate(en,
    'Hälytysaika',
    ...val),

  DUFL: (en: string, ...val: string[]) => translate(en,
    'Laitteesi palvelupyyntö lähetettiin onnistuneesti',
    ...val),

  DUPA: (en: string, ...val: string[]) => translate(en,
    'Kentän arvo on virheellinen',
    ...val),

  DV41: (en: string, ...val: string[]) => translate(en,
    'Etkö löydä laitettasi täältä?',
    ...val),

  DVM1: (en: string, ...val: string[]) => translate(en,
    'Paikallista käyttöä ei löytynyt!',
    ...val),

  DVXR: (en: string, ...val: string[]) => translate(en,
    'Valitse alta laitelistat, joihin haluat kopioida tämän laitelistan tuotevalikoiman. Huomioithan, että tuotevalikoiman kopiointi korvaa kaikki valituissa laitelistoissa olevat tuotteet. Tämä ei vaikuta laitelistan asetuksiin.',
    ...val),

  DVYV: (en: string, ...val: string[]) => translate(en,
    'Elisa Oma Laitelasku sopimusehdot',
    ...val),

  DW0G: (en: string, ...val: string[]) => translate(en,
    'Tyhjennä kenttä',
    ...val),

  DW3T: (en: string, ...val: string[]) => translate(en,
    'Yksityisille',
    ...val),

  DXK6: (en: string, ...val: string[]) => translate(en,
    'Piilota kuukausikohtainen erittely',
    ...val),

  DYKC: (en: string, ...val: string[]) => translate(en,
    'Avaajan sähköposti',
    ...val),

  DYMN: (en: string, ...val: string[]) => translate(en,
    'Tuote on poistunut valikoimastamme.',
    ...val),

  DZA1: (en: string, ...val: string[]) => translate(en,
    'Työaikaketjun voimassaoloajat vaaditaan, kun aikaohjaus on käytössä.',
    ...val),

  DZMK: (en: string, ...val: string[]) => translate(en,
    'Siirry OmaElisa Classiciin',
    ...val),

  DZN3: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu Työntekijän OmaElisaan',
    ...val),

  DZQF: (en: string, ...val: string[]) => translate(en,
    'Kirjautuminen uudistuu',
    ...val),

  E0FK: (en: string, ...val: string[]) => translate(en,
    'https://elisa.fi/tietosuoja/',
    ...val),

  E0K4: (en: string, ...val: string[]) => translate(en,
    'Kk-maksut yhteensä, alv. 0 %',
    ...val),

  E0KX: (en: string, ...val: string[]) => translate(en,
    'Oletko työntekijä?',
    ...val),

  E0RW: (en: string, ...val: string[]) => translate(en,
    'Lisäturva laitteelle',
    ...val),

  E0TK: (en: string, ...val: string[]) => translate(en,
    'Kopioi laitelistan tuotevalikoima muihin kertamaksullisiin listoihin',
    ...val),

  E112: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjä',
    ...val),

  E12S: (en: string, ...val: string[]) => translate(en,
    'Voimassa 24h',
    ...val),

  E12Y: (en: string, ...val: string[]) => translate(en,
    'Työnantaja kustantaa tämän laitteen kokonaan',
    ...val),

  E1N1: (en: string, ...val: string[]) => translate(en,
    'Hyväksy vielä ehdot.',
    ...val),

  E23N: (en: string, ...val: string[]) => translate(en,
    'Työaikana käytössä',
    ...val),

  E2HY: (en: string, ...val: string[]) => translate(en,
    'Nettiliittymän ja langattoman MESH-verkon asennus',
    ...val),

  E35R: (en: string, ...val: string[]) => translate(en,
    'Toimii myös ulkomailla',
    ...val),

  E45A: (en: string, ...val: string[]) => translate(en,
    'Liittymätietojen näyttäminen Työntekijän OmaElisassa',
    ...val),

  E4TT: (en: string, ...val: string[]) => translate(en,
    '(alv. {} %)',
    ...val),

  E51E: (en: string, ...val: string[]) => translate(en,
    'Lue lisää ja ota käyttöön',
    ...val),

  E54X: (en: string, ...val: string[]) => translate(en,
    'Lunastuksesta lähetetään lasku käyttäjälle.',
    ...val),

  E6DY: (en: string, ...val: string[]) => translate(en,
    'Posti Express aamu',
    ...val),

  E74C: (en: string, ...val: string[]) => translate(en,
    'Gt aktivoituu automaattisesti kohdemaissa ja mahdollistaa mobiilipäätelaitteella tai kannettavalla tietokoneella käytettävän nopean tiedonsiirtoyhteyden edulliseen hintaan EU/ETA-alueen ulkopuolisissa maissa. Palvelussa ei ole kuukausimaksua, vaan hinnoittelu aktivoituu käytön mukaan 26,90 € / 14 vrk.',
    ...val),

  E7S2: (en: string, ...val: string[]) => translate(en,
    'Huolto- tai vikailmoitus',
    ...val),

  E82U: (en: string, ...val: string[]) => translate(en,
    'Suosittelemme seuraavia vaihtoehtoja:',
    ...val),

  E83U: (en: string, ...val: string[]) => translate(en,
    'Suosittelemme myös',
    ...val),

  E86A: (en: string, ...val: string[]) => translate(en,
    'Valitse 5G-yritysliittymä',
    ...val),

  E86B: (en: string, ...val: string[]) => translate(en,
    'Ei määräaikaa',
    ...val),

  E86C: (en: string, ...val: string[]) => translate(en,
    'Valitse 5G-mobiililaajakaista',
    ...val),

  E8T5: (en: string, ...val: string[]) => translate(en,
    'Muutoksen lähetys onnistui',
    ...val),

  E90X: (en: string, ...val: string[]) => translate(en,
    'Voit palauttaa käyttäjätunnuksesi',
    ...val),

  E9BD: (en: string, ...val: string[]) => translate(en,
    'Muutoksen tiedot',
    ...val),

  E9GS: (en: string, ...val: string[]) => translate(en,
    'Alkamisaika ei voi olla suurempi kuin päättymisaika',
    ...val),

  E9I4: (en: string, ...val: string[]) => translate(en,
    'Lähetetty',
    ...val),

  E9IF: (en: string, ...val: string[]) => translate(en,
    'Puhelimet',
    ...val),

  E9LB: (en: string, ...val: string[]) => translate(en,
    'Hanki entistä toimivampi netti työntekoon Elisa YritysWIFI Lite -palvelulla. Sen sisältämien wifi-vahvistinten avulla netti toimii kaikissa huoneissa ja kerroksissa.',
    ...val),

  EAA8: (en: string, ...val: string[]) => translate(en,
    'Laitteen sopimuskausi on päättymässä. Laitekaupasta voit tilata uuden laitteen ja halutessasi myös lunastaa tämän vanhan laitteen. Mikäli laitevaihtoa ei tehdä tai sopimusta päätetä, laite siirtyy automaattisesti 6 kk jatkokaudelle.',
    ...val),

  EAAJ: (en: string, ...val: string[]) => translate(en,
    'Liittymän tiedot',
    ...val),

  EADV: (en: string, ...val: string[]) => translate(en,
    'Lähetä kaikki tuotteet yhdessä',
    ...val),

  EAFO: (en: string, ...val: string[]) => translate(en,
    'Unohtuiko salasana?',
    ...val),

  EAJM: (en: string, ...val: string[]) => translate(en,
    'Ilmoittaudu',
    ...val),

  EAVJ: (en: string, ...val: string[]) => translate(en,
    'Kirjaamme sinut ulos tunnistautumista varten',
    ...val),

  EB6F: (en: string, ...val: string[]) => translate(en,
    'Luo uudestaan',
    ...val),

  EBAT: (en: string, ...val: string[]) => translate(en,
    'Arkisin',
    ...val),

  EBJW: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen lisääminen epäonnistui. Yritä myöhemmin uudelleen.',
    ...val),

  EBNB: (en: string, ...val: string[]) => translate(en,
    'Hylkäämisen lisätiedot työntekijälle',
    ...val),

  EBUW: (en: string, ...val: string[]) => translate(en,
    'Laite on peruskaudella',
    ...val),

  EBX4: (en: string, ...val: string[]) => translate(en,
    'Tämän laitteen osalta summa on ',
    ...val),

  ECMJ: (en: string, ...val: string[]) => translate(en,
    'Laitteen malli',
    ...val),

  EDOH: (en: string, ...val: string[]) => translate(en,
    'Jos sinulla on ongelmia kirjautumisessa, salasana hukassa, Elisa tunnus puuttuu tai se on unohtunut, mene sivustolle: https://yrityksille.elisa.fi/työntekijä.',
    ...val),

  EE4N: (en: string, ...val: string[]) => translate(en,
    'Maksutiedot',
    ...val),

  EE7J: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi sisältää tuotteita, joita ei juuri nyt ole saatavilla.',
    ...val),

  EEE3: (en: string, ...val: string[]) => translate(en,
    'Huom!',
    ...val),

  EEYF: (en: string, ...val: string[]) => translate(en,
    'Siirrä liittymä toiseen laskutussopimukseen',
    ...val),

  EFC1: (en: string, ...val: string[]) => translate(en,
    'Muutos astuu voimaan valitsemanasi päivänä. Tukipyynnön tila ja ratkaisu on nähtävillä viestikeskuksessa. Ilmoitus muutetuista sopimuksista lähetetään viestikeskuksen lisäksi myös yhteyshenkilön sähköpostiin.',
    ...val),

  EFXH: (en: string, ...val: string[]) => translate(en,
    'Sopimuksen irtisanominen',
    ...val),

  EG02: (en: string, ...val: string[]) => translate(en,
    'Verkkolaite',
    ...val),

  EG6F: (en: string, ...val: string[]) => translate(en,
    'Omat tilaukset',
    ...val),

  EGFA: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti emme voi käsitellä pyyntöäsi, koska järjestelmästämme löytyy mahdollinen samanlainen yhteyshenkilö. Ole ystävällinen ja ota yhteyttä Elisan asiakaspalveluun.',
    ...val),

  EGKZ: (en: string, ...val: string[]) => translate(en,
    'Et ole vielä tehnyt tilauksia',
    ...val),

  EGQX: (en: string, ...val: string[]) => translate(en,
    'Puhelimen paikannus',
    ...val),

  EGRR: (en: string, ...val: string[]) => translate(en,
    'Siirrä seuraavaan numeroon ketjussa',
    ...val),

  EH18: (en: string, ...val: string[]) => translate(en,
    'Matkahuolto Lähellä-paketti',
    ...val),

  EHOL: (en: string, ...val: string[]) => translate(en,
    'Yrityksen tiedot',
    ...val),

  EI6P: (en: string, ...val: string[]) => translate(en,
    'Tekstiviestipaketti 500',
    ...val),

  EIC5: (en: string, ...val: string[]) => translate(en,
    'Tukipyyntö avattu',
    ...val),

  EITS: (en: string, ...val: string[]) => translate(en,
    'Aktivoi',
    ...val),

  EIYK: (en: string, ...val: string[]) => translate(en,
    'Ei lisäpalveluita',
    ...val),

  EJPD: (en: string, ...val: string[]) => translate(en,
    'Lähetä viesti myyntiimme',
    ...val),

  EJQF: (en: string, ...val: string[]) => translate(en,
    'Asetukset',
    ...val),

  EJTJ: (en: string, ...val: string[]) => translate(en,
    'Reportin luonti on jo käynnissä',
    ...val),

  EK6P: (en: string, ...val: string[]) => translate(en,
    'Suojakuoret',
    ...val),

  EKAI: (en: string, ...val: string[]) => translate(en,
    'Palvelun irtisanominen onnistui',
    ...val),

  EKG9: (en: string, ...val: string[]) => translate(en,
    'Tukipyyntö',
    ...val),

  EKK1: (en: string, ...val: string[]) => translate(en,
    'Palvelu käytössä',
    ...val),

  EKYF: (en: string, ...val: string[]) => translate(en,
    'Vahvista, että olet tarkistanut pääkäyttäjien tiedot',
    ...val),

  EL7N: (en: string, ...val: string[]) => translate(en,
    'Virheellinen kokonaisluku',
    ...val),

  EL9W: (en: string, ...val: string[]) => translate(en,
    'Linkki lähetetty.',
    ...val),

  ELIW: (en: string, ...val: string[]) => translate(en,
    'Etsi sähköpostiosoitteella',
    ...val),

  ELJA: (en: string, ...val: string[]) => translate(en,
    'Olen uusi asiakas',
    ...val),

  ELJB: (en: string, ...val: string[]) => translate(en,
    'Oletko jo Elisan asiakas?',
    ...val),

  ELQI: (en: string, ...val: string[]) => translate(en,
    'Valitse tiedosto',
    ...val),

  EM2Q: (en: string, ...val: string[]) => translate(en,
    'Maksuaika',
    ...val),

  EMCQ: (en: string, ...val: string[]) => translate(en,
    'Anna salasana',
    ...val),

  EMYP: (en: string, ...val: string[]) => translate(en,
    'Lisää käyttäjä tai käyttötarkoitus',
    ...val),

  EMZV: (en: string, ...val: string[]) => translate(en,
    'Kopioi laitelistan tuotevalikoima',
    ...val),

  EN2Q: (en: string, ...val: string[]) => translate(en,
    'Ei datapakettia',
    ...val),

  ENOC: (en: string, ...val: string[]) => translate(en,
    'Ei tiedossa',
    ...val),

  ENVW: (en: string, ...val: string[]) => translate(en,
    'Olemme vastaanottaneet tukipyynnön ja aloitamme sen käsittelyn mahdollisimman pian.',
    ...val),

  EO6B: (en: string, ...val: string[]) => translate(en,
    'Elisan tietosuojaperiaatteet',
    ...val),

  EO7W: (en: string, ...val: string[]) => translate(en,
    'Muilla kuin puhelimilla toimi laiteohjeiden mukaisesti eSIM käyttöönotossa.',
    ...val),

  EPHQ: (en: string, ...val: string[]) => translate(en,
    'Tarkista ja korjaa seuraava kohta:',
    ...val),

  EPSL: (en: string, ...val: string[]) => translate(en,
    'Entistä tasalaatuisemmat yhteydet.',
    ...val),

  EPUZ: (en: string, ...val: string[]) => translate(en,
    'Ring-liittymän poistaminen',
    ...val),

  EPWH: (en: string, ...val: string[]) => translate(en,
    'Hae kaikista yrityksistä',
    ...val),

  EQI1: (en: string, ...val: string[]) => translate(en,
    'Laitteen kuntoluokka',
    ...val),

  EQID: (en: string, ...val: string[]) => translate(en,
    'Mikäli yksi tai useampi lasku on ulkoisessa perinnässä, maksusopimusta ei voi tehdä itsepalvelussa.',
    ...val),

  EQK0: (en: string, ...val: string[]) => translate(en,
    'Osa yhteyshenkilön tiedoista puuttuu',
    ...val),

  EQKJ: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista',
    ...val),

  ER2E: (en: string, ...val: string[]) => translate(en,
    'Käyttäjätiedot lisätty tuotteelle.',
    ...val),

  ERXV: (en: string, ...val: string[]) => translate(en,
    'Siirry hallintaportaaliin',
    ...val),

  ES5D: (en: string, ...val: string[]) => translate(en,
    'Verkkotunnus',
    ...val),

  ESF8: (en: string, ...val: string[]) => translate(en,
    'Sinut kirjataan ulos automaattisesti käyttäjätunnuksen valinnan jälkeen.',
    ...val),

  ESQW: (en: string, ...val: string[]) => translate(en,
    'Voimmeko yhdistää selailutietoja asiakastietoihisi?',
    ...val),

  ESVA: (en: string, ...val: string[]) => translate(en,
    'Alanumerosarja',
    ...val),

  ETV2: (en: string, ...val: string[]) => translate(en,
    'Vaihda vastaanottaja',
    ...val),

  EV5V: (en: string, ...val: string[]) => translate(en,
    'Lisätarvikeet',
    ...val),

  EWI0: (en: string, ...val: string[]) => translate(en,
    'Yritykselläsi ei ole aktiivista Ring-ratkaisua.',
    ...val),

  EWIN: (en: string, ...val: string[]) => translate(en,
    'Mikä tämä palvelu on?',
    ...val),

  EXXX: (en: string, ...val: string[]) => translate(en,
    'Suomi',
    ...val),

  EY5B: (en: string, ...val: string[]) => translate(en,
    'Toimitamme postitse osoitteeseen sopivan langattoman laajakaistareitittimen.',
    ...val),

  EY5Q: (en: string, ...val: string[]) => translate(en,
    'Laskutuksen yhteyshenkilö',
    ...val),

  EY6A: (en: string, ...val: string[]) => translate(en,
    'Kaikki',
    ...val),

  EY7T: (en: string, ...val: string[]) => translate(en,
    'Laskutustiedot ja vahvistaminen',
    ...val),

  EYBW: (en: string, ...val: string[]) => translate(en,
    'Liittymäsi ei ole yhteensopiva tilaamasi palvelun kanssa.',
    ...val),

  EYS6: (en: string, ...val: string[]) => translate(en,
    'Työnantajasi ei kustanna tätä laitetta kokonaan. Voit kuitenkin tilata laitteen osallistumalla itse kustannuksiin.',
    ...val),

  EZ2Q: (en: string, ...val: string[]) => translate(en,
    'Yhteystietojen päivitys onnistui',
    ...val),

  EZYP: (en: string, ...val: string[]) => translate(en,
    'uudelleen. Tunnuksesi on voinut vanhentua.',
    ...val),

  F09D: (en: string, ...val: string[]) => translate(en,
    'Näytä salasana',
    ...val),

  F0CY: (en: string, ...val: string[]) => translate(en,
    'Muut lisätarvikkeet',
    ...val),

  F0MY: (en: string, ...val: string[]) => translate(en,
    'Seuraava',
    ...val),

  F0OO: (en: string, ...val: string[]) => translate(en,
    'Tilausnumero',
    ...val),

  F12I: (en: string, ...val: string[]) => translate(en,
    'Puhelut ulkomaille',
    ...val),

  F1CR: (en: string, ...val: string[]) => translate(en,
    'Luo uusi tukipyyntö',
    ...val),

  F1G3: (en: string, ...val: string[]) => translate(en,
    'Ei laitelistoja joihin kopioida',
    ...val),

  F1JW: (en: string, ...val: string[]) => translate(en,
    'Toistaiseksi voimassa',
    ...val),

  F1NC: (en: string, ...val: string[]) => translate(en,
    'Tilauspäivämäärä',
    ...val),

  F1YQ: (en: string, ...val: string[]) => translate(en,
    'Osaa laitteistasi voit hallita',
    ...val),

  F25D: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä on päättymässä oleva Elisa palvelupäätelaite sopimus',
    ...val),

  F2BF: (en: string, ...val: string[]) => translate(en,
    'Rivit',
    ...val),

  F30X: (en: string, ...val: string[]) => translate(en,
    ', numerosi on salainen ja sen tiedot eivät löydy numeropalveluista',
    ...val),

  F3LL: (en: string, ...val: string[]) => translate(en,
    'Laturit',
    ...val),

  F3ZQ: (en: string, ...val: string[]) => translate(en,
    'Hups… sinulla ei ole oikeuksia tähän toimintoon',
    ...val),

  F43F: (en: string, ...val: string[]) => translate(en,
    'ja',
    ...val),

  F4L3: (en: string, ...val: string[]) => translate(en,
    'Ennen laitevaihdon tilaamista ',
    ...val),

  F514: (en: string, ...val: string[]) => translate(en,
    'Ladataan laskutustietoja',
    ...val),

  F54R: (en: string, ...val: string[]) => translate(en,
    'Otamme Yritysten OmaElisassa käyttöön uuden henkilökohtaisen Elisa Tunnuksen.',
    ...val),

  F5BJ: (en: string, ...val: string[]) => translate(en,
    'Tekniikka',
    ...val),

  F5M7: (en: string, ...val: string[]) => translate(en,
    'Yhteydenotto myyntiin',
    ...val),

  F5YW: (en: string, ...val: string[]) => translate(en,
    'Muutosten julkaiseminen:',
    ...val),

  F6BI: (en: string, ...val: string[]) => translate(en,
    'Jos vaihdat päätelaitetta, sinun tulee tilata uusi eSIM SIM-kortinvaihdon avulla ja asentaa se uuteen laitteeseen. Vanha QR-koodi ei toimi uudessa laitteessa.',
    ...val),

  F6H1: (en: string, ...val: string[]) => translate(en,
    'Lähetä tukipyyntö',
    ...val),

  F6OD: (en: string, ...val: string[]) => translate(en,
    'Aktiivinen versio',
    ...val),

  F7YS: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, johon haluat kutsua työntekijöitä',
    ...val),

  F8DF: (en: string, ...val: string[]) => translate(en,
    'Estopalveluiden muuttamisessa tapahtui odottamaton virhe.',
    ...val),

  F8VB: (en: string, ...val: string[]) => translate(en,
    'Saunalahti yritysasiakas',
    ...val),

  F8Y3: (en: string, ...val: string[]) => translate(en,
    'Tarkista kuuluvuus alueellasi',
    ...val),

  F97B: (en: string, ...val: string[]) => translate(en,
    'Haluatko varmasti poistaa viimeisen tuotteen ostoskorista?',
    ...val),

  F9G1: (en: string, ...val: string[]) => translate(en,
    'Tyyppi',
    ...val),

  F9HL: (en: string, ...val: string[]) => translate(en,
    'Yrityksen vaihtaminen päivittää ostoskorin',
    ...val),

  FA5L: (en: string, ...val: string[]) => translate(en,
    'Laitelistat ja tuotevalikoima',
    ...val),

  FA9S: (en: string, ...val: string[]) => translate(en,
    'Myyntipalvelumme voi päivittää uuden liittymän sinulle. Soita 0800 04411 (arkisin klo 8:00-16:00)',
    ...val),

  FAZM: (en: string, ...val: string[]) => translate(en,
    'Laitteesi palvelupyyntö lähetettiin onnistuneesti',
    ...val),

  FBDO: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä on pääkäyttäjäoikeudet',
    ...val),

  FBHJ: (en: string, ...val: string[]) => translate(en,
    'Elisa Oma Lasku',
    ...val),

  FCH5: (en: string, ...val: string[]) => translate(en,
    'Hyvityslasku',
    ...val),

  FCOR: (en: string, ...val: string[]) => translate(en,
    'Työntekijä voi maksaa lisänopeudesta itse tai sen voi myös ostaa yrityksen toimesta työntekijälle.',
    ...val),

  FD6H: (en: string, ...val: string[]) => translate(en,
    'Lisää pääkäyttäjäoikeuksia muihin yrityksiin',
    ...val),

  FD7W: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä ei ole palveluita',
    ...val),

  FDE2: (en: string, ...val: string[]) => translate(en,
    'Maksajan nimi ja Y-tunnus',
    ...val),

  FDK6: (en: string, ...val: string[]) => translate(en,
    'Lue lisää tietosuojastamme',
    ...val),

  FDQN: (en: string, ...val: string[]) => translate(en,
    'IMEI-koodi tai sarjanumero',
    ...val),

  FDSO: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi on siirretty käsittelyyn',
    ...val),

  FDUX: (en: string, ...val: string[]) => translate(en,
    'Elisa DNS -hallinta vaatii kaksivaiheisen tunnistautumisen. Saat aktivoitua kaksivaiheisen tunnistautumisen käyttöön Yrityksen tiedot ja asetukset sivulla.',
    ...val),

  FF2X: (en: string, ...val: string[]) => translate(en,
    'Säännölliseen työntekoon ja vapaa-aikaan',
    ...val),

  FFJ2: (en: string, ...val: string[]) => translate(en,
    'Muutokset tallennettiin onnistuneesti',
    ...val),

  FFQZ: (en: string, ...val: string[]) => translate(en,
    'Näytä PopUp -viesti työntekijälle.',
    ...val),

  FG0H: (en: string, ...val: string[]) => translate(en,
    'Voimassa 24 h. Sisältää alv. {} %',
    ...val),

  FGE3: (en: string, ...val: string[]) => translate(en,
    'Sinun maksettava osuus kuukausimaksusta',
    ...val),

  FGVX: (en: string, ...val: string[]) => translate(en,
    'Laitelistan tuotevalikoiman kopiointi onnistui',
    ...val),

  FHD7: (en: string, ...val: string[]) => translate(en,
    'Saat työntekijän laitesopimuksesta vahvistuksen, joka sinun tulee tarkastaa ja hyväksyä.',
    ...val),

  FHIZ: (en: string, ...val: string[]) => translate(en,
    'Maksulliset viestit',
    ...val),

  FI1N: (en: string, ...val: string[]) => translate(en,
    'Tarkista antamasi vahvistuskoodi',
    ...val),

  FI29: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että laskutussopimus on voimassa 14 päivää. Jos laskutussopimukselle ei tilata tuotteita tai palveluita tämän ajanjakson aikana, poistuu se automaattisesti käytöstä.',
    ...val),

  FIAV: (en: string, ...val: string[]) => translate(en,
    'Lisää puhelinliittymä',
    ...val),

  FINQ: (en: string, ...val: string[]) => translate(en,
    'Laite on tuhoutunut',
    ...val),

  FIRO: (en: string, ...val: string[]) => translate(en,
    'Hyväksyn sopimusehdot',
    ...val),

  FIS5: (en: string, ...val: string[]) => translate(en,
    'Valitut alanumeroavaruudet',
    ...val),

  FJLH: (en: string, ...val: string[]) => translate(en,
    'Viesti',
    ...val),

  FJOL: (en: string, ...val: string[]) => translate(en,
    'OmaLasku -palvelut',
    ...val),

  FK2F: (en: string, ...val: string[]) => translate(en,
    'Tilauksen lisäpalvelut',
    ...val),

  FK90: (en: string, ...val: string[]) => translate(en,
    'Voit käyttää nettiä Euroopassa kotimaan hinnoin liittymäsi kohtuukäytön rajaan asti.',
    ...val),

  FKO8: (en: string, ...val: string[]) => translate(en,
    '(esim. henkilönumero)',
    ...val),

  FKVB: (en: string, ...val: string[]) => translate(en,
    '6,37 €/kk (alv. 0%)',
    ...val),

  FL34: (en: string, ...val: string[]) => translate(en,
    'Näytettävä numero',
    ...val),

  FLKQ: (en: string, ...val: string[]) => translate(en,
    'Haetaan sisältöä...',
    ...val),

  FLLT: (en: string, ...val: string[]) => translate(en,
    'Vahinkopalvelu korjaa omavastuumaksua vastaan Käyttäjän itse aiheuttamat, takuun ulkopuoliset viat. Vahinkopalvelu ei kata laitteen varkaus- tai katoamistapauksia. Omavastuut ovat kategoriassa Business Pro 30 € / vahinko ja kategoriassa Business Premium 50 € / vahinko. Vaurioituneen laitteen Käyttäjälle toimitetaan kuntoluokitusta vastaava laite.',
    ...val),

  FM7B: (en: string, ...val: string[]) => translate(en,
    'Elisa Oyj',
    ...val),

  FMUV: (en: string, ...val: string[]) => translate(en,
    'Elisa KelloSIM-palvelu',
    ...val),

  FOAX: (en: string, ...val: string[]) => translate(en,
    'Applen tukisivuilta.',
    ...val),

  FOHO: (en: string, ...val: string[]) => translate(en,
    '{},{}',
    ...val),

  FOKG: (en: string, ...val: string[]) => translate(en,
    'avoimia',
    ...val),

  FQ21: (en: string, ...val: string[]) => translate(en,
    'Voit valita tarpeisiisi sopivimman ajanjakson ja saat aina parhaan pakettiin sisältyvän yhteyden ',
    ...val),

  FR97: (en: string, ...val: string[]) => translate(en,
    'Jotta liittymän tiedot näkyvät työntekijän OmaElisassa tarkista, että liittymän käyttäjänä on henkilö ja että käyttäjätiedoissa on työntekijän sähköpostiosoite ja puhelinnumero.',
    ...val),

  FRYN: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelinnumero',
    ...val),

  FSWU: (en: string, ...val: string[]) => translate(en,
    'Lähetä',
    ...val),

  FT13: (en: string, ...val: string[]) => translate(en,
    '1000 Mbit/s • 5000 min • 100 kpl',
    ...val),

  FT2S: (en: string, ...val: string[]) => translate(en,
    'Supernopea netti päivittäiseen työntekoon ja pienelle toimistolle',
    ...val),

  FT2U: (en: string, ...val: string[]) => translate(en,
    'Tai tunnistaudu',
    ...val),

  FT4V: (en: string, ...val: string[]) => translate(en,
    'Valittu kieli',
    ...val),

  FTA1: (en: string, ...val: string[]) => translate(en,
    'SIM-kortit toimitetaan kirjeenä valitsemaasi osoitteeseen.',
    ...val),

  FTZT: (en: string, ...val: string[]) => translate(en,
    'Siirry avoimiin laskuihin',
    ...val),

  FUAJ: (en: string, ...val: string[]) => translate(en,
    'Lataa tästä csv-pohja sähköpostiosoitteille.',
    ...val),

  FUDM: (en: string, ...val: string[]) => translate(en,
    'Nykyisen salasanan ja uuden salasanan ei pitäisi olla samat',
    ...val),

  FUTC: (en: string, ...val: string[]) => translate(en,
    'Liittymän avaus onnistui.',
    ...val),

  FV55: (en: string, ...val: string[]) => translate(en,
    'Yhteystietosi',
    ...val),

  FV8F: (en: string, ...val: string[]) => translate(en,
    'Poista ja palaa kauppaan',
    ...val),

  FV8X: (en: string, ...val: string[]) => translate(en,
    'Kirjautumisessa ongelmia?',
    ...val),

  FVG8: (en: string, ...val: string[]) => translate(en,
    'Saat uuden laitteen mukana ohjeet sekä palautuspussin laitteen palauttamista varten. Palauttamattomasta laitteesta veloitetaan maksu.',
    ...val),

  FVI5: (en: string, ...val: string[]) => translate(en,
    'Kallein ensin',
    ...val),

  FVJ4: (en: string, ...val: string[]) => translate(en,
    'Sisältää vähintään kaksi numeroa tai erikoismerkkiä',
    ...val),

  FVU9: (en: string, ...val: string[]) => translate(en,
    'Katso estetyt numerot',
    ...val),

  FW1W: (en: string, ...val: string[]) => translate(en,
    'Tilattavien lisenssien (käyttäjien) määrä',
    ...val),

  FWV1: (en: string, ...val: string[]) => translate(en,
    'Laajakaistan tekniikka',
    ...val),

  FX59: (en: string, ...val: string[]) => translate(en,
    'Tuotteiden toimitus yhdessä ei ole mahdollista, koska tilaus sisältää tuotteita jotka toimitetaan suoraan toimittajan varastolta.',
    ...val),

  FXG6: (en: string, ...val: string[]) => translate(en,
    'Elisa-tunnuksen',
    ...val),

  FXKU: (en: string, ...val: string[]) => translate(en,
    'löytyi',
    ...val),

  FXKW: (en: string, ...val: string[]) => translate(en,
    ' ei löytynyt yhtään hakutulosta.',
    ...val),

  FY9Z: (en: string, ...val: string[]) => translate(en,
    'Tilinumero',
    ...val),

  FZAU: (en: string, ...val: string[]) => translate(en,
    'Saat F-Securelta sähköpostitse uudet kirjautumistunnukset, joilla voit kirjautua takaisin sisään.',
    ...val),

  FZNW: (en: string, ...val: string[]) => translate(en,
    'Palvelun asennus ei ole mahdollista',
    ...val),

  FZU3: (en: string, ...val: string[]) => translate(en,
    'Etkö näe tilaustasi täällä?',
    ...val),

  G013: (en: string, ...val: string[]) => translate(en,
    'Käyttääksesi lisenssien hallintaportaalia, täydennä seuraavat tiedot',
    ...val),

  G07Y: (en: string, ...val: string[]) => translate(en,
    'ADSL',
    ...val),

  G089: (en: string, ...val: string[]) => translate(en,
    'Katso kaikki tukipyynnöt',
    ...val),

  G090: (en: string, ...val: string[]) => translate(en,
    'Katso kaikki',
    ...val),

  G0EZ: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaistat',
    ...val),

  G0NE: (en: string, ...val: string[]) => translate(en,
    'Tunniste',
    ...val),

  G0QN: (en: string, ...val: string[]) => translate(en,
    'Toimitustapa',
    ...val),

  G0XR: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteiden vuokravastuut',
    ...val),

  G16D: (en: string, ...val: string[]) => translate(en,
    'Mikä on Työntekijän OmaElisa?',
    ...val),

  G188: (en: string, ...val: string[]) => translate(en,
    'Lisää rajaus',
    ...val),

  G18X: (en: string, ...val: string[]) => translate(en,
    'Lisää laskutussopimus',
    ...val),

  G24H: (en: string, ...val: string[]) => translate(en,
    'Etusivulle',
    ...val),

  G304: (en: string, ...val: string[]) => translate(en,
    'Salasanan on oltava vähintään 8 merkkiä pitkä ja siinä on oltava vähintään yksi pieni kirjain, yksi iso kirjain sekä vähintään kaksi numeroa tai erikoismerkkiä.',
    ...val),

  G39I: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoite tarvitaan',
    ...val),

  G3WD: (en: string, ...val: string[]) => translate(en,
    'Etsi lunastuspyyntöä',
    ...val),

  G3YU: (en: string, ...val: string[]) => translate(en,
    'Avausmaksut yhteensä',
    ...val),

  G42E: (en: string, ...val: string[]) => translate(en,
    'Haluan irroittaa Ring- ja matkapuhelinliittymät toisistaan.',
    ...val),

  G4ZD: (en: string, ...val: string[]) => translate(en,
    'Lopetuspyyntösi lähetettiin onnistuneesti',
    ...val),

  G51L: (en: string, ...val: string[]) => translate(en,
    'Elisa Reissaajalla turvallinen käyttö EU:n ulkopuolella',
    ...val),

  G5EQ: (en: string, ...val: string[]) => translate(en,
    'Näytä kuukausikohtainen erittely',
    ...val),

  G5HB: (en: string, ...val: string[]) => translate(en,
    'Mobiilivarmenne -palvelu ei ole yhteensopiva eSIM:in kanssa.',
    ...val),

  G5NF: (en: string, ...val: string[]) => translate(en,
    'Voit kirjautua samoilla tunnuksilla Oma Elisaan.',
    ...val),

  G664: (en: string, ...val: string[]) => translate(en,
    'Yrityksen osuus',
    ...val),

  G6IO: (en: string, ...val: string[]) => translate(en,
    'Elisan yleiset sopimusehdot',
    ...val),

  G7RQ: (en: string, ...val: string[]) => translate(en,
    'valinnainen',
    ...val),

  G83G: (en: string, ...val: string[]) => translate(en,
    'Siirry rekisteröintisivulle',
    ...val),

  G85J: (en: string, ...val: string[]) => translate(en,
    'Apua laskutuskysymyksiin',
    ...val),

  G87R: (en: string, ...val: string[]) => translate(en,
    'Käytetty',
    ...val),

  G8FE: (en: string, ...val: string[]) => translate(en,
    'Yhteistoiminne',
    ...val),

  G91V: (en: string, ...val: string[]) => translate(en,
    'Sopimuksesta on jo tehty irtisanomispyyntö',
    ...val),

  G9OG: (en: string, ...val: string[]) => translate(en,
    'Windows Autopilot laiterekisteröintiohjelma',
    ...val),

  G9OH: (en: string, ...val: string[]) => translate(en,
    'Tiedonsiirtonopeus vaihtelee päätelaitteen ominaisuuksien, sijainnin ja verkon kuormituksen mukaan.',
    ...val),

  G9QF: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu palveluihin',
    ...val),

  G9UR: (en: string, ...val: string[]) => translate(en,
    'Haluatko poistaa ajastetun muutoksen?',
    ...val),

  G9X4: (en: string, ...val: string[]) => translate(en,
    'Reskontran saldo',
    ...val),

  G9X8: (en: string, ...val: string[]) => translate(en,
    'Jos sinulla ei ole nimenkirjoitusoikeutta voi yrityksesi OmaElisa-pääkäyttäjä palauttaa tunnuksesi OmaElisa-palvelussa.',
    ...val),

  GAJ8: (en: string, ...val: string[]) => translate(en,
    'Säännölliseen etätyöhön ja aktiiviseen vapaa-ajan surffailuun',
    ...val),

  GAML: (en: string, ...val: string[]) => translate(en,
    'Tarkista ja korjaa seuraavat {} kohtaa:',
    ...val),

  GBFN: (en: string, ...val: string[]) => translate(en,
    '{} asti',
    ...val),

  GBHA: (en: string, ...val: string[]) => translate(en,
    'Lue lisää laiterekisteriohjelmista',
    ...val),

  GCDV: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti tilausta ei voida käsitellä. Otathan yhteyttä yrityksesi yhteyshenkilöön.',
    ...val),

  GCMD: (en: string, ...val: string[]) => translate(en,
    'Miten voimme auttaa?',
    ...val),

  GCO3: (en: string, ...val: string[]) => translate(en,
    'Tilaushistoria',
    ...val),

  GDFF: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä Elisan asiakaspalveluun.',
    ...val),

  GDH2: (en: string, ...val: string[]) => translate(en,
    ', sopimus on voimassa toistaiseksi.',
    ...val),

  GE17: (en: string, ...val: string[]) => translate(en,
    'Unohtunut salasana',
    ...val),

  GE4D: (en: string, ...val: string[]) => translate(en,
    'Omat edut',
    ...val),

  GF1O: (en: string, ...val: string[]) => translate(en,
    'Hyvä perusliittymä työntekoon ja vapaa-ajan surffailuun',
    ...val),

  GFN5: (en: string, ...val: string[]) => translate(en,
    '+ Luo uusi',
    ...val),

  GFZQ: (en: string, ...val: string[]) => translate(en,
    'Jos laite ei käynnisty tai ei lataa',
    ...val),

  GG1D: (en: string, ...val: string[]) => translate(en,
    'Valitse 5G+ -yritysliittymä',
    ...val),

  GGLB: (en: string, ...val: string[]) => translate(en,
    'Etsi...',
    ...val),

  GHE2: (en: string, ...val: string[]) => translate(en,
    'sopimushinta',
    ...val),

  GIIE: (en: string, ...val: string[]) => translate(en,
    'Tilaa tästä',
    ...val),

  GIRL: (en: string, ...val: string[]) => translate(en,
    'Sis. 0,5 Gt datan käyttöä kotimaassa. Paketin ylimenevä datankäyttö 1,50 € / alkava 0,5 Gt.',
    ...val),

  GJ0P: (en: string, ...val: string[]) => translate(en,
    'Laitteen lunastaminen työntekijän puolesta ei ole mahdollista. Tämä voi johtua siitä, että yrityksellä ei ole käytössä Työntekijän OmaElisaa tai työntekijä ei ole ottanut käyttöönsä. Elisa Oma Laskua. Työntekijä voi tehdä laitteen lunastamisen myös itse Työntekijän OmaElisasta.',
    ...val),

  GJJF: (en: string, ...val: string[]) => translate(en,
    'Lue lisää',
    ...val),

  GJLT: (en: string, ...val: string[]) => translate(en,
    'Estää puhelut aikuisviihdepalveluihin.',
    ...val),

  GJS9: (en: string, ...val: string[]) => translate(en,
    'Yhteystiedot',
    ...val),

  GKQW: (en: string, ...val: string[]) => translate(en,
    'Arvioitu toimitusaika',
    ...val),

  GKWF: (en: string, ...val: string[]) => translate(en,
    'Liittymä on jo liitetty Vakio-ratkaisuun.',
    ...val),

  GL4R: (en: string, ...val: string[]) => translate(en,
    'Katuosoite, esim. Ritarikatu 42 C 6',
    ...val),

  GLO4: (en: string, ...val: string[]) => translate(en,
    'Toimitusajankohta',
    ...val),

  GMAP: (en: string, ...val: string[]) => translate(en,
    'Avoimet',
    ...val),

  GMD4: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimus on valittava',
    ...val),

  GMKG: (en: string, ...val: string[]) => translate(en,
    'Arkipäivisin ennen klo 12 tehdyt tilaukset ehtivät päänsääntöisesti seuraavan arkipäivän toimitukseen.',
    ...val),

  GMMI: (en: string, ...val: string[]) => translate(en,
    'Muuttuuko maksajan nimi tai postiosoite',
    ...val),

  GMNB: (en: string, ...val: string[]) => translate(en,
    'Vahvista poisto',
    ...val),

  GNM7: (en: string, ...val: string[]) => translate(en,
    'Mitä haluat tehdä?',
    ...val),

  GNSO: (en: string, ...val: string[]) => translate(en,
    'Lue lisää Reissunetti ja Reissaaja -palveluista',
    ...val),

  GO7L: (en: string, ...val: string[]) => translate(en,
    'Suuret tiedonsiirrot ja 8K-leffat',
    ...val),

  GOBY: (en: string, ...val: string[]) => translate(en,
    'Kertamaksut',
    ...val),

  GOH2: (en: string, ...val: string[]) => translate(en,
    'Alennuskoodi',
    ...val),

  GOPL: (en: string, ...val: string[]) => translate(en,
    'https://elisa.fi/asiakaspalvelu/aihe/sopimusehdot/ohje/sopimusehdot/',
    ...val),

  GOS2: (en: string, ...val: string[]) => translate(en,
    'Viesti',
    ...val),

  GOZ7: (en: string, ...val: string[]) => translate(en,
    'Valittu hyväksyjä',
    ...val),

  GQAX: (en: string, ...val: string[]) => translate(en,
    'Estoluokka',
    ...val),

  GQBC: (en: string, ...val: string[]) => translate(en,
    'Vahvistamalla hyväksyt ',
    ...val),

  GQLC: (en: string, ...val: string[]) => translate(en,
    'Voit olla yhteydessä liittymän nopeuden vaihdosta myyntipalveluumme',
    ...val),

  GQLW: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen numero',
    ...val),

  GR8T: (en: string, ...val: string[]) => translate(en,
    'Lisätietoa laskun poikkeavasta loppusummasta',
    ...val),

  GRAL: (en: string, ...val: string[]) => translate(en,
    'Sähköposti-, verkko- tai paperilasku',
    ...val),

  GSAD: (en: string, ...val: string[]) => translate(en,
    'Y-tunnus tai yrityksen nimi',
    ...val),

  GSYE: (en: string, ...val: string[]) => translate(en,
    'Luotu',
    ...val),

  GUAE: (en: string, ...val: string[]) => translate(en,
    '{} kustannuspaikka ja viitenumero',
    ...val),

  GUWZ: (en: string, ...val: string[]) => translate(en,
    'Kopioi käyttäjätiedot kaikkiin tuotteisiin',
    ...val),

  GV78: (en: string, ...val: string[]) => translate(en,
    'Merkki',
    ...val),

  GVGJ: (en: string, ...val: string[]) => translate(en,
    'Haluatko irtisanoa Ring-liittymän vai vain irroittaa Ring- ja matkapuhelinliittymät toisistaan?',
    ...val),

  GVIF: (en: string, ...val: string[]) => translate(en,
    'Mikäli tarvitset oikeudet hallinnoidaksesi yrityksesi Elisa Palvelupäätelaite -asioita, ota yhteyttä',
    ...val),

  GVMV: (en: string, ...val: string[]) => translate(en,
    'Avoinna {0}.',
    ...val),

  GVYP: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että käyttäjällä on',
    ...val),

  GWCA: (en: string, ...val: string[]) => translate(en,
    'Mobiilinumerot',
    ...val),

  GWJI: (en: string, ...val: string[]) => translate(en,
    'Domain eli verkkotunnus',
    ...val),

  GWZ6: (en: string, ...val: string[]) => translate(en,
    'Osoite',
    ...val),

  GX72: (en: string, ...val: string[]) => translate(en,
    'Netin käyttö',
    ...val),

  GXAQ: (en: string, ...val: string[]) => translate(en,
    'Ei tuotteita',
    ...val),

  GXHR: (en: string, ...val: string[]) => translate(en,
    'Kontaktin luonti epäonnistui',
    ...val),

  GY5T: (en: string, ...val: string[]) => translate(en,
    'asioit',
    ...val),

  GY75: (en: string, ...val: string[]) => translate(en,
    'Estää mobiilidatan ja multimediaviestien käytön EU- ja ETA-maiden ulkopuolella. Estolla varmistat, ettet käytä vahingossa mobiilidataa.',
    ...val),

  GYOL: (en: string, ...val: string[]) => translate(en,
    'Kun vaihdat yritystä, ostoskori päivittyy. Ostoskorissa olevien tuotteiden hinnat voivat muuttua.',
    ...val),

  GZ1X: (en: string, ...val: string[]) => translate(en,
    'Päivä',
    ...val),

  GZ5O: (en: string, ...val: string[]) => translate(en,
    'Liittymäsi ei ole liitetty tietoihisi Työntekijän OmaElisassa',
    ...val),

  GZ8L: (en: string, ...val: string[]) => translate(en,
    'Muutosten tallentaminen epäonnistui',
    ...val),

  GZUK: (en: string, ...val: string[]) => translate(en,
    'Lisätiedot',
    ...val),

  GZUL: (en: string, ...val: string[]) => translate(en,
    'Annan Elisalle luvan olla minuun yhteydessä sähköpostitse ja puhelimitse sekä yhdistää verkosta saatavia tietoja, kuten päätelaite- ja sijaintitietoa asiakastietojeni kanssa. Elisa voi hyödyntää tietoja viestinnän ja markkinoinnin kohdentamisessa.',
    ...val),

  GZUM: (en: string, ...val: string[]) => translate(en,
    'Lisätietoja viestinnästä ja markkinoinnista saat ',
    ...val),

  GZUN: (en: string, ...val: string[]) => translate(en,
    'Elisan tietosuojaperiaatteesta',
    ...val),

  GZUO: (en: string, ...val: string[]) => translate(en,
    '. Voit muuttaa valintojasi tai kieltää markkinoinnin OmaElisassa tai asiakaspalvelussamme.',
    ...val),

  GZZ9: (en: string, ...val: string[]) => translate(en,
    'Domainlistaus',
    ...val),

  H041: (en: string, ...val: string[]) => translate(en,
    'Säilytä valittujen listojen tila ennallaan',
    ...val),

  H094: (en: string, ...val: string[]) => translate(en,
    'Yritysnumeroavaruus',
    ...val),

  H0EZ: (en: string, ...val: string[]) => translate(en,
    'Laite ei kuulu Elisan palvelupäätelaite-valikoimaan. Ole hyvä ja valitse toinen laite.',
    ...val),

  H0K6: (en: string, ...val: string[]) => translate(en,
    'Ulkomaan käyttö',
    ...val),

  H0N7: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että kampanjatilaus "{}" on määräaikainen, eikä sitä voi irtisanoa kesken sopimuskauden.',
    ...val),

  H0XT: (en: string, ...val: string[]) => translate(en,
    'Muutospyynnön käsittely on kesken',
    ...val),

  H103: (en: string, ...val: string[]) => translate(en,
    'Maksusopimusvahvistus',
    ...val),

  H272: (en: string, ...val: string[]) => translate(en,
    'Laskun toimitustapa',
    ...val),

  H2HC: (en: string, ...val: string[]) => translate(en,
    'Ota palvelu käyttöön',
    ...val),

  H2ZI: (en: string, ...val: string[]) => translate(en,
    'Luo kopio laitelistasta',
    ...val),

  H32M: (en: string, ...val: string[]) => translate(en,
    'Annoin väärän puhelinnumeron',
    ...val),

  H37G: (en: string, ...val: string[]) => translate(en,
    '€/kk',
    ...val),

  H3AZ: (en: string, ...val: string[]) => translate(en,
    'Ring-käyttäjätunnus',
    ...val),

  H3ZA: (en: string, ...val: string[]) => translate(en,
    'Uusi omistaja',
    ...val),

  H41V: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen vaihtaminen',
    ...val),

  H45W: (en: string, ...val: string[]) => translate(en,
    'Vanha arvo',
    ...val),

  H4F0: (en: string, ...val: string[]) => translate(en,
    'Tukipyynnöt',
    ...val),

  H4JG: (en: string, ...val: string[]) => translate(en,
    'Irtisanomispäivä',
    ...val),

  H4LW: (en: string, ...val: string[]) => translate(en,
    'Luo tukipyyntö laskusta',
    ...val),

  H4ZY: (en: string, ...val: string[]) => translate(en,
    '4K-videopuhelut ja pilvipalvelut',
    ...val),

  H51P: (en: string, ...val: string[]) => translate(en,
    'Kotiosoite rivi 2',
    ...val),

  H5I1: (en: string, ...val: string[]) => translate(en,
    'Muutoksen voimaantulopäivän täytyy olla tulevaisuudessa eikä se saa osua viikonlopulle',
    ...val),

  H6JV: (en: string, ...val: string[]) => translate(en,
    'OmaElisa työntekijälle',
    ...val),

  H6OA: (en: string, ...val: string[]) => translate(en,
    'Näytä lisää',
    ...val),

  H6X9: (en: string, ...val: string[]) => translate(en,
    'Omaelisa tabletilla',
    ...val),

  H8Q4: (en: string, ...val: string[]) => translate(en,
    'Yrityksen maksuosuus laitteen kuukausimaksusta',
    ...val),

  H9L5: (en: string, ...val: string[]) => translate(en,
    'Etkö näe käytössäsi olevaa liittymää? ',
    ...val),

  H9TQ: (en: string, ...val: string[]) => translate(en,
    'Ryhmän',
    ...val),

  H9V6: (en: string, ...val: string[]) => translate(en,
    'Lataa toimivalla laturilla vähintään 30 minuuttia jonka jälkeen kokeile käynnistää se uudelleen. Joskus akun lataustaso pääsee niin alas, että lataus käynnistyy viiveellä',
    ...val),

  HA0A: (en: string, ...val: string[]) => translate(en,
    'Lisätietoa vastaanotettu',
    ...val),

  HA3D: (en: string, ...val: string[]) => translate(en,
    'Lasku maksettu onnistuneesti.',
    ...val),

  HAA2: (en: string, ...val: string[]) => translate(en,
    'Myymälät kartalla',
    ...val),

  HAN0: (en: string, ...val: string[]) => translate(en,
    'Tietojen yhdistämisen perusteella tarjoamme Elisan verkkosivuilla juuri sinulle soveltuvaa sisältöä ja tarjouksia. Voit milloin tahansa muuttaa valintaasi.',
    ...val),

  HAWX: (en: string, ...val: string[]) => translate(en,
    'Estä kaikki käyttö ulkomailla',
    ...val),

  HBBD: (en: string, ...val: string[]) => translate(en,
    'Viite ei päivity automaattisesti käyttäjän palveluille. Tarkastathan viitteen tuotteen tai palvelun tiedoista.',
    ...val),

  HBFB: (en: string, ...val: string[]) => translate(en,
    'Pakettiin kuuluvat puhelut',
    ...val),

  HBS2: (en: string, ...val: string[]) => translate(en,
    'Uusi maksupäivä ei näy Yritysten OmaElisassa, mutta saat vahvistuksen sähköpostiisi käsiteltyämme maksusopimuspyynnön. Mikäli et ole saanut vahvistusviestiä sähköpostiisi 5 arkipäivän jälkeen, olethan yhteydessä Yritysasiakaspalveluumme.',
    ...val),

  HBUV: (en: string, ...val: string[]) => translate(en,
    'Puhelutiedot ovat muutostöiden vuoksi tilapäisesti pois käytöstä. Pahoittelemme tilannetta.',
    ...val),

  HBW3: (en: string, ...val: string[]) => translate(en,
    'Kuvaus',
    ...val),

  HC1K: (en: string, ...val: string[]) => translate(en,
    'Peruskaudella olevat',
    ...val),

  HC61: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti tilausta ei voida käsitellä. Työnantajasi asettama sallittujen puhelimien määrän raja on ylitetty. Otathan tarvittaessa yhteyttä yrityksesi yhteyshenkilöön.',
    ...val),

  HCCZ: (en: string, ...val: string[]) => translate(en,
    'Salasana',
    ...val),

  HCIE: (en: string, ...val: string[]) => translate(en,
    'Sopijayritys',
    ...val),

  HD6M: (en: string, ...val: string[]) => translate(en,
    'Hakutuloksia on liikaa.',
    ...val),

  HDNG: (en: string, ...val: string[]) => translate(en,
    'Ei nettiliittymiä',
    ...val),

  HDQI: (en: string, ...val: string[]) => translate(en,
    'DNS-tietueen päivittäminen epäonnistui!',
    ...val),

  HE85: (en: string, ...val: string[]) => translate(en,
    'Seuraa laskutuksesi tilannetta, tulosta takuukuitit ja laskut. Muokkaa ja yhdistä laskutussopimuksiasi.',
    ...val),

  HEKT: (en: string, ...val: string[]) => translate(en,
    'Laskumallin nimi, määrittää laskun ulkoasun ja erittelytason.',
    ...val),

  HF21: (en: string, ...val: string[]) => translate(en,
    'Rajaton kotimaassa',
    ...val),

  HFD9: (en: string, ...val: string[]) => translate(en,
    'Kutsut',
    ...val),

  HFUO: (en: string, ...val: string[]) => translate(en,
    'Tämä henkilö löytyy jo yrityksen käyttäjälistalta.',
    ...val),

  HGEL: (en: string, ...val: string[]) => translate(en,
    'Tilapäisesti loppu, toimitetaan jälkitoimituksena',
    ...val),

  HH00: (en: string, ...val: string[]) => translate(en,
    'Tyhjennä',
    ...val),

  HHCQ: (en: string, ...val: string[]) => translate(en,
    'Voit siirtää käyttäjän vain niiden yritysten välillä, joihin sinulla on käyttöoikeus. Myös käyttäjän sopimukset siirtyvät. Pyydä tarvittaessa käyttöoikeus toisen yrityksen pääkäyttäjältä.',
    ...val),

  HHDQ: (en: string, ...val: string[]) => translate(en,
    'Anna saamasi vahvistuskoodi',
    ...val),

  HHOH: (en: string, ...val: string[]) => translate(en,
    'Kerro, mitä muutoksia tarvitset?',
    ...val),

  HHRE: (en: string, ...val: string[]) => translate(en,
    'Määrittelee työaikaketjun voimassaoloajan. Muina aikoina käytössä on poissaoloketju. Jos haluat poissaoloketjun koko viikonlopulle, aseta ajaksi 00.00 - 00.00.',
    ...val),

  HI56: (en: string, ...val: string[]) => translate(en,
    'Ei',
    ...val),

  HIM5: (en: string, ...val: string[]) => translate(en,
    'tarkastaa liittymäsi PUK-koodin',
    ...val),

  HIOW: (en: string, ...val: string[]) => translate(en,
    'Tilaa tarvittaessa uusi laite tai ohjeista työntekijää tilaamaan se Työntekijän OmaElisasta.',
    ...val),

  HJ9V: (en: string, ...val: string[]) => translate(en,
    'Yritystilin voi luoda vain henkilö, jolla on yrityksen nimenkirjoitusoikeus.',
    ...val),

  HJJ3: (en: string, ...val: string[]) => translate(en,
    'Sopijayrityksen vaihtaminen ei ole mahdollista',
    ...val),

  HKFZ: (en: string, ...val: string[]) => translate(en,
    'Muuta laskutusosoitetta',
    ...val),

  HM82: (en: string, ...val: string[]) => translate(en,
    'Liittymän käyttäjä',
    ...val),

  HMZZ: (en: string, ...val: string[]) => translate(en,
    'Sopimushinta yritykselle, alv. 0 %',
    ...val),

  HNN2: (en: string, ...val: string[]) => translate(en,
    ' mukaisen maksun.',
    ...val),

  HOPN: (en: string, ...val: string[]) => translate(en,
    'Ylläpitäjän sähköposti',
    ...val),

  HP5L: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä on oltava nimi, puhelinnumero ja sähköposti, jotta käyttöoikeuksia voidaan lisätä.',
    ...val),

  HP5R: (en: string, ...val: string[]) => translate(en,
    'Valitut tiedostot',
    ...val),

  HPH9: (en: string, ...val: string[]) => translate(en,
    'Koulutukseen ilmoittautumisen yhteydessä, sen järjestämisessä ja toteuttamisessa käsitellään henkilötietoja Elisan tietosuojaperiaatteiden mukaisesti. Elisa on käsiteltävien henkilötietojen osalta tietosuojalainsäädännössä tarkoitettu henkilötietojen rekisterinpitäjä. Elisalla on oikeus käyttää palveluiden tuottamisessa ja henkilötietojen käsittelyssä myös alihankkijoita.',
    ...val),

  HPJ2: (en: string, ...val: string[]) => translate(en,
    'Ei saatavilla Oulua pohjoisemmille paikkakunnille',
    ...val),

  HPMO: (en: string, ...val: string[]) => translate(en,
    'Sis. {} Gt datan käyttöä Pohjola & Baltia sekä muun EU:n alueella. Paketin ylimenevä datankäyttö 1,50 € / alkava 0,5 Gt.',
    ...val),

  HPSN: (en: string, ...val: string[]) => translate(en,
    'Toimitamme sinulle palautuspussin nykyistä laitetta varten uuden laitteen mukana. Tilauksen yhteydessä voit halutessasi myös lunastaa nykyisen laitteen omaksesi hintaan {}.',
    ...val),

  HPT9: (en: string, ...val: string[]) => translate(en,
    'Muuta hyllyliittymäksi',
    ...val),

  HPTO: (en: string, ...val: string[]) => translate(en,
    'Liian monta tiedostoa ({}/{})',
    ...val),

  HQ41: (en: string, ...val: string[]) => translate(en,
    'Avattu',
    ...val),

  HQ5P: (en: string, ...val: string[]) => translate(en,
    'Liittymä kannattaa päivittää hyllyliittymäksi silloin, jos liittymää ei tarvita pidempään aikaan mutta sen numerosta ei haluta luopua.',
    ...val),

  HQ7C: (en: string, ...val: string[]) => translate(en,
    'tietoihin',
    ...val),

  HQC3: (en: string, ...val: string[]) => translate(en,
    'Kuukausimaksu sinulle',
    ...val),

  HQTV: (en: string, ...val: string[]) => translate(en,
    'Max. 500 merkkiä. Ei html -tageja.',
    ...val),

  HQWU: (en: string, ...val: string[]) => translate(en,
    'Ma-Pe 8:00-16:30',
    ...val),

  HQWV: (en: string, ...val: string[]) => translate(en,
    'arkisin klo 8:00-16:30',
    ...val),

  HQWW: (en: string, ...val: string[]) => translate(en,
    'mpm/pvm',
    ...val),

  HQWX: (en: string, ...val: string[]) => translate(en,
    'Käytä toista sähköpostiosoitetta tunnuksen luomiseen tai ota yhteyttä asiakaspalveluun {0} ({1}). Yritysten OmaElisaan pääset kirjautumaan ',
    ...val),

  HRG7: (en: string, ...val: string[]) => translate(en,
    'Huomioithan,',
    ...val),

  HRWW: (en: string, ...val: string[]) => translate(en,
    'Laskunumero:',
    ...val),

  HS3T: (en: string, ...val: string[]) => translate(en,
    'Tilaajan tiedot',
    ...val),

  HS4R: (en: string, ...val: string[]) => translate(en,
    'Ei',
    ...val),

  HS6F: (en: string, ...val: string[]) => translate(en,
    'Yhdistä numeroon',
    ...val),

  HSCA: (en: string, ...val: string[]) => translate(en,
    'Valittu hyväksyjä',
    ...val),

  HSJ0: (en: string, ...val: string[]) => translate(en,
    'Skannaa QR-koodi',
    ...val),

  HSJH: (en: string, ...val: string[]) => translate(en,
    'Uusi arvo',
    ...val),

  HT0V: (en: string, ...val: string[]) => translate(en,
    '10 Mbit/s • 5000 min • 100 kpl',
    ...val),

  HT1M: (en: string, ...val: string[]) => translate(en,
    'ja sen, että Elisa tarkistaa luottotietoni laskutustietoja varten',
    ...val),

  HTQL: (en: string, ...val: string[]) => translate(en,
    'Ostoskorissa on nyt yksi tuote',
    ...val),

  HUJX: (en: string, ...val: string[]) => translate(en,
    'Maksupäivän siirto lähetetty käsittelyyn',
    ...val),

  HUN9: (en: string, ...val: string[]) => translate(en,
    'Yhteyspuhelinnumero',
    ...val),

  HUVS: (en: string, ...val: string[]) => translate(en,
    'Vastaavia tuotteita ei löytynyt',
    ...val),

  HV0T: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisa-tunnus on henkilökohtainen.',
    ...val),

  HV3Q: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista',
    ...val),

  HV3R: (en: string, ...val: string[]) => translate(en,
    'Yritysliittymät',
    ...val),

  HV47: (en: string, ...val: string[]) => translate(en,
    'Yrityksemme',
    ...val),

  HVG8: (en: string, ...val: string[]) => translate(en,
    'Näytössä ja rungossa naarmuja tai kulumaa',
    ...val),

  HVK9: (en: string, ...val: string[]) => translate(en,
    'Lisää jokainen sähköpostiosoite omalle rivilleen',
    ...val),

  HVS2: (en: string, ...val: string[]) => translate(en,
    'Valittu laskutussopimus',
    ...val),

  HVWH: (en: string, ...val: string[]) => translate(en,
    'Lähetä uusi',
    ...val),

  HW60: (en: string, ...val: string[]) => translate(en,
    'Löydät laskutussopimuksen numeron viimeisimmältä laskulta, oikeasta yläkulmasta. Jos laskutussopimusnumeroa ei löydy laskulta, ilmoita laskulla näkyvä asiakasnumero.\n\nLisätäksesi laskutussopimuksia, paina “Ilmoita laskutussopimus” painiketta. Voit lisätä enintään 10 sopimusta.',
    ...val),

  HWDL: (en: string, ...val: string[]) => translate(en,
    'Salasana ei täsmää toisen salasanan kanssa.',
    ...val),

  HWDR: (en: string, ...val: string[]) => translate(en,
    'Avausmaksu',
    ...val),

  HWDT: (en: string, ...val: string[]) => translate(en,
    'Säästä',
    ...val),

  HWDU: (en: string, ...val: string[]) => translate(en,
    'Avausmaksu {0}',
    ...val),

  HWDV: (en: string, ...val: string[]) => translate(en,
    'Avausmaksu {0}/kpl',
    ...val),

  HWZF: (en: string, ...val: string[]) => translate(en,
    'Voit antaa pääkäyttäjäoikeuksia myös muihin yrityksiin, joihin sinulla on käyttöoikeus.',
    ...val),

  HXOW: (en: string, ...val: string[]) => translate(en,
    'ollessa varattu',
    ...val),

  HYGU: (en: string, ...val: string[]) => translate(en,
    'Kentässä voi olla enintään {} sähköpostiosoitetta.',
    ...val),

  HYV2: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu sisään',
    ...val),

  HZ90: (en: string, ...val: string[]) => translate(en,
    'Vahingon sattuessa, ota yhteyttä SquareTraden asiakaspalveluun. Puh 0800 915 801, ma-pe klo 9-19, la klo 10-16:30. Voit tehdä vahinkoilmoituksen myös internet-sivuilla',
    ...val),

  I00R: (en: string, ...val: string[]) => translate(en,
    'Sisältää mobiilireitittimen',
    ...val),

  I053: (en: string, ...val: string[]) => translate(en,
    'Tarkasta datasaldo',
    ...val),

  I1GC: (en: string, ...val: string[]) => translate(en,
    'Tarjoushinta on voimassa 24kk, jonka jälkeen liittymä muuttuu listahintaiseksi.',
    ...val),

  I1LH: (en: string, ...val: string[]) => translate(en,
    'Tarjoukset',
    ...val),

  I23L: (en: string, ...val: string[]) => translate(en,
    'Korissa on 1 tuote',
    ...val),

  I281: (en: string, ...val: string[]) => translate(en,
    'Kiitos, vahvistaminen onnistui!',
    ...val),

  I2EO: (en: string, ...val: string[]) => translate(en,
    'Kertamaksullisten tuotteiden tietojen muokkaus ei ole mahdollista.',
    ...val),

  I2Y9: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti tilausta ei voida käsitellä luottopäätöksen vuoksi. ',
    ...val),

  I2YS: (en: string, ...val: string[]) => translate(en,
    'Tärkeää tietoa',
    ...val),

  I3A5: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti kirjautumisessa tapahtui virhe. Auttaisiko jokin alla olevista?',
    ...val),

  I3DO: (en: string, ...val: string[]) => translate(en,
    'Vahvista irtisanominen',
    ...val),

  I4ME: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksella',
    ...val),

  I5RB: (en: string, ...val: string[]) => translate(en,
    'Kenttä tarvitsee vähintään kolme merkkiä',
    ...val),

  I62A: (en: string, ...val: string[]) => translate(en,
    'Jatka',
    ...val),

  I62X: (en: string, ...val: string[]) => translate(en,
    'Tietue poistettu',
    ...val),

  I6FA: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että muutosten aktivoinnissa saattaa kestää useita minuutteja.',
    ...val),

  I7P6: (en: string, ...val: string[]) => translate(en,
    'Voimassa 7 vrk. Sisältää alv. {} %',
    ...val),

  I8GJ: (en: string, ...val: string[]) => translate(en,
    'Käynnistä laitteesi kortin aktivoinnin jälkeen uudelleen. Uuden SIM-kortin PIN-koodi on aluksi 1234. Vanha korttisi lakkaa toimimasta aktivoinnin jälkeen.',
    ...val),

  I8LJ: (en: string, ...val: string[]) => translate(en,
    'Julkinen IP-osoite',
    ...val),

  I9BN: (en: string, ...val: string[]) => translate(en,
    'Varattu yritysnumeroavaruus',
    ...val),

  I9C0: (en: string, ...val: string[]) => translate(en,
    'Tilaukset',
    ...val),

  I9DB: (en: string, ...val: string[]) => translate(en,
    'Uusia muutoksia on mahdollista tehdä vasta, kun edellinen muutostilaus on käsitelty.',
    ...val),

  I9G8: (en: string, ...val: string[]) => translate(en,
    'Tervetuloa Työntekijän OmaElisaan',
    ...val),

  I9KT: (en: string, ...val: string[]) => translate(en,
    'Muutostilaus kesken',
    ...val),

  I9NH: (en: string, ...val: string[]) => translate(en,
    'Muuta tiedot ja yritä käyttäjän siirtoa uudelleen. Voit myös jättää',
    ...val),

  I9QZ: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoiteella löytyi jo tunnus',
    ...val),

  I9RX: (en: string, ...val: string[]) => translate(en,
    'Laitelistat kopioitu. Viimeistele ja julkaise listat yritysten omalla laitelista-sivulla.',
    ...val),

  IATD: (en: string, ...val: string[]) => translate(en,
    'Sulje väliaikaisesti',
    ...val),

  IBNP: (en: string, ...val: string[]) => translate(en,
    'Kevyt perusliittymä työntekoon ja vapaa-ajan surffailuun',
    ...val),

  IBOB: (en: string, ...val: string[]) => translate(en,
    'Ymmärrän ja hyväksyn ehdon,',
    ...val),

  IBVP: (en: string, ...val: string[]) => translate(en,
    'Laiterekisteröinnin alias',
    ...val),

  IBZN: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaistan päivitys',
    ...val),

  ICAD: (en: string, ...val: string[]) => translate(en,
    'Sinulla on vain lukuoikeus tietueisiin. Jos oikeuksiasi tulisi laajentaa, ota yhteyttä',
    ...val),

  IDBW: (en: string, ...val: string[]) => translate(en,
    'SIM-kortti toimitetaan kirjepostina. Voit aktivoida SIM-kortin täällä OmaElisassa heti saatuasi sen. Nykyinen SIM-kortti lakkaa toimimasta, kun aktivoit uuden kortin käyttöön. ',
    ...val),

  IEC8: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti seuraava puhelinnumero tai -numerot eivät ole enää vapaana',
    ...val),

  IEFA: (en: string, ...val: string[]) => translate(en,
    'Supernopea netti vaativaan työntekoon ja pilvipalveluihin',
    ...val),

  IF4R: (en: string, ...val: string[]) => translate(en,
    'Tarkista kaapelin ja virtalähteen toimivuus',
    ...val),

  IFIJ: (en: string, ...val: string[]) => translate(en,
    'Ei kohdetta',
    ...val),

  IFKT: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen yhteystiedot',
    ...val),

  IFT9: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimus',
    ...val),

  IG52: (en: string, ...val: string[]) => translate(en,
    'tai kopioi aktivointikoodi. Nykyinen SIM-korttisi lakkaa toimimasta, kun saat toimitusvahvistuksen uudesta liittymästä.',
    ...val),

  IGKK: (en: string, ...val: string[]) => translate(en,
    'Siirrytään yrityksen',
    ...val),

  IGOI: (en: string, ...val: string[]) => translate(en,
    'Sähköpostit ja kevyt surffaaminen',
    ...val),

  IGS3: (en: string, ...val: string[]) => translate(en,
    'Osoitteeseen sopivat nettiyhteydet',
    ...val),

  IH0Z: (en: string, ...val: string[]) => translate(en,
    'Muutos astuu voimaan valitsemanasi päivänä. Saat sähköpostiisi ilmoituksen muutetuista sopimuksista.',
    ...val),

  IH2K: (en: string, ...val: string[]) => translate(en,
    'Estää tekstiviestit ja mobiilimaksut aikuisviihdepalveluihin.',
    ...val),

  IH3X: (en: string, ...val: string[]) => translate(en,
    'Kassa',
    ...val),

  IHET: (en: string, ...val: string[]) => translate(en,
    'Yksityisasiakkaat',
    ...val),

  IHO6: (en: string, ...val: string[]) => translate(en,
    'Toimitusosoite',
    ...val),

  IHRV: (en: string, ...val: string[]) => translate(en,
    'Laskutusyhteyshenkilön tiedot',
    ...val),

  IHS4: (en: string, ...val: string[]) => translate(en,
    'Tietokone',
    ...val),

  IHW3: (en: string, ...val: string[]) => translate(en,
    'Toistaiseksi voimassa oleva',
    ...val),

  IJNQ: (en: string, ...val: string[]) => translate(en,
    'EU- JA ETA-maat',
    ...val),

  IJNR: (en: string, ...val: string[]) => translate(en,
    'EU ja ETA: {} Gt/kk',
    ...val),

  IJNS: (en: string, ...val: string[]) => translate(en,
    '{} Mbit/s',
    ...val),

  IJNT: (en: string, ...val: string[]) => translate(en,
    'maks. {} Mbit/s',
    ...val),

  IK1D: (en: string, ...val: string[]) => translate(en,
    'Verkkolasku',
    ...val),

  IKLM: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että liittymän kausimaksut veloitetaan normaalisti sinä aikana, kun liittymän käyttö on estettynä.',
    ...val),

  IKPV: (en: string, ...val: string[]) => translate(en,
    'Voit myös jatkaa kirjautumatta',
    ...val),

  IKVP: (en: string, ...val: string[]) => translate(en,
    'Liittymätyypin muutosmaksu {}',
    ...val),

  IL1L: (en: string, ...val: string[]) => translate(en,
    'Asiointikieli',
    ...val),

  IL9T: (en: string, ...val: string[]) => translate(en,
    'Ei puhelinliittymiä',
    ...val),

  ILJ6: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän nimi',
    ...val),

  ILLN: (en: string, ...val: string[]) => translate(en,
    ' tilalle.',
    ...val),

  IM77: (en: string, ...val: string[]) => translate(en,
    'Liittymän Vakio-asetukset',
    ...val),

  IMA5: (en: string, ...val: string[]) => translate(en,
    '{} tuotetta lisättiin ostoskoriin',
    ...val),

  IN3U: (en: string, ...val: string[]) => translate(en,
    'Jos syötät tiedot käsin',
    ...val),

  INTO: (en: string, ...val: string[]) => translate(en,
    'Tarkemmat henkilötiedot',
    ...val),

  INV3: (en: string, ...val: string[]) => translate(en,
    'Avoimet laskut',
    ...val),

  IP2H: (en: string, ...val: string[]) => translate(en,
    'Verkkotunnuksen tarkistuksessa tapahtui virhe. Yritä myöhemmin uudelleen.',
    ...val),

  IPFH: (en: string, ...val: string[]) => translate(en,
    'Mistä löydän SIM-kortin numeron',
    ...val),

  IQAF: (en: string, ...val: string[]) => translate(en,
    'Alennuskoodia ei löytynyt.',
    ...val),

  IR4H: (en: string, ...val: string[]) => translate(en,
    'Liittymälläsi on Elisa Lankanumero -palvelu, joka estää palvelun käyttöönoton.',
    ...val),

  IRQS: (en: string, ...val: string[]) => translate(en,
    'Yrityksen laskutustiedot',
    ...val),

  IRUO: (en: string, ...val: string[]) => translate(en,
    'Voit olla yhteydessä Netti Liten nopeuden vaihdosta myyntipalveluumme',
    ...val),

  IRZJ: (en: string, ...val: string[]) => translate(en,
    'Lataa',
    ...val),

  ISNF: (en: string, ...val: string[]) => translate(en,
    'Yritys on valittava',
    ...val),

  IT7Q: (en: string, ...val: string[]) => translate(en,
    'Tilaus vahvistettu onnistuneesti. Mikäli yrityksesi on sallinut tilausvahvistusten vastaanottamisen, saat vahvistuksen sähköpostiisi {}, kun tilaus on hyväksytty.',
    ...val),

  ITD0: (en: string, ...val: string[]) => translate(en,
    'Ei raportteja',
    ...val),

  ITR5: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, jonka lisenssejä haluat hallinnoida.',
    ...val),

  IU7C: (en: string, ...val: string[]) => translate(en,
    'Kutsut Työntekijän OmaElisaan',
    ...val),

  IVHU: (en: string, ...val: string[]) => translate(en,
    'Palvelunumerot -ominaisuuden poistaminen',
    ...val),

  IVJT: (en: string, ...val: string[]) => translate(en,
    'Näytönsuojat',
    ...val),

  IVJX: (en: string, ...val: string[]) => translate(en,
    'Estää mobiilidatan käytön. Puhelut ja tekstiviestit toimivat normaalisti.',
    ...val),

  IVZR: (en: string, ...val: string[]) => translate(en,
    'Sopimus irtisanotaan muutamassa minuutissa päättämisen jälkeen ja liittymä poistuu käytöstä.',
    ...val),

  IWD6: (en: string, ...val: string[]) => translate(en,
    'kuuluvuuskartta',
    ...val),

  IWHO: (en: string, ...val: string[]) => translate(en,
    'Liittymä on määräaikainen {} asti, jonka aikana sitä ei voi irtisanoa.',
    ...val),

  IWHR: (en: string, ...val: string[]) => translate(en,
    '{}kk määräaikainen sopimus',
    ...val),

  IWQK: (en: string, ...val: string[]) => translate(en,
    'Ole ystävällinen ja ja ota tarvittaessa yhteyttä',
    ...val),

  IWVK: (en: string, ...val: string[]) => translate(en,
    'Tyhjennä rajaus',
    ...val),

  IX1T: (en: string, ...val: string[]) => translate(en,
    'Määräaikaista liittymää ei voi irtisanoa.',
    ...val),

  IXED: (en: string, ...val: string[]) => translate(en,
    'Laitelistan laskutustiedot',
    ...val),

  IY1W: (en: string, ...val: string[]) => translate(en,
    '50 € saldoraja datakäytölle ulkomailla',
    ...val),

  IY4Z: (en: string, ...val: string[]) => translate(en,
    'Tuotteet',
    ...val),

  IY5V: (en: string, ...val: string[]) => translate(en,
    'Tilaaja',
    ...val),

  IYBA: (en: string, ...val: string[]) => translate(en,
    'Seuraa tukipyynnön etenemistä',
    ...val),

  J07S: (en: string, ...val: string[]) => translate(en,
    'Voit tehdä muutoksia, kun liittymä on avattu.',
    ...val),

  J083: (en: string, ...val: string[]) => translate(en,
    'Huom.',
    ...val),

  J0MG: (en: string, ...val: string[]) => translate(en,
    'Katso myös',
    ...val),

  J0O8: (en: string, ...val: string[]) => translate(en,
    'Viesti',
    ...val),

  J0YE: (en: string, ...val: string[]) => translate(en,
    'Kaupunki',
    ...val),

  J1F7: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista yrityksille',
    ...val),

  J1JB: (en: string, ...val: string[]) => translate(en,
    '{}G-liittymät',
    ...val),

  J1ZO: (en: string, ...val: string[]) => translate(en,
    'Toimitamme korvaavan laiteen rikkoutuneen tilalle. Valitse toimitusosoite, johon laite toimitetaan. Saat korvaavan laitteen mukana ohjeet vanhan laitteen palauttamista varten.',
    ...val),

  J23G: (en: string, ...val: string[]) => translate(en,
    'Käyttäjätunnus',
    ...val),

  J378: (en: string, ...val: string[]) => translate(en,
    'Ota käyttöön OmaElisa',
    ...val),

  J3XJ: (en: string, ...val: string[]) => translate(en,
    'Tulet saamaan loppulaskun irtisanotusta palvelusta, mikäli käyttö- tai kuukausimaksuja on vielä laskuttamatta.',
    ...val),

  J3YS: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä lunastaa',
    ...val),

  J4WW: (en: string, ...val: string[]) => translate(en,
    'Kausimaksut',
    ...val),

  J5SL: (en: string, ...val: string[]) => translate(en,
    'Varattusoittoääni',
    ...val),

  J5VG: (en: string, ...val: string[]) => translate(en,
    'Palvelun muokkaaminen ei ole mahdollista OmaElisassa. Tarvittaessa ota yhteyttä asiakaspalveluun.',
    ...val),

  J6KQ: (en: string, ...val: string[]) => translate(en,
    'Palvelu ei ole saatavilla liittymällesi',
    ...val),

  J6P7: (en: string, ...val: string[]) => translate(en,
    'Syötä koko osoite mukaan lukien rapun numero',
    ...val),

  J7JN: (en: string, ...val: string[]) => translate(en,
    'Luo uusi viesti ',
    ...val),

  J8VE: (en: string, ...val: string[]) => translate(en,
    'Poistaaksesi eston, sinun tulee sallia',
    ...val),

  J923: (en: string, ...val: string[]) => translate(en,
    'Uusi tilaus ja tarjouspyyntö',
    ...val),

  J954: (en: string, ...val: string[]) => translate(en,
    'Lisätarvikkeet',
    ...val),

  J9AR: (en: string, ...val: string[]) => translate(en,
    'Laskun viitetiedot',
    ...val),

  J9DM: (en: string, ...val: string[]) => translate(en,
    'Tuotteita',
    ...val),

  J9TT: (en: string, ...val: string[]) => translate(en,
    'Tunnistautuminen epäonnistui.',
    ...val),

  JA5V: (en: string, ...val: string[]) => translate(en,
    'Mobiilireitittimen avulla säästät puhelimesi akkua, kun sinun ei tarvitse jakaa nettiä puhelimesta. 5G+ -tekniikan ansiosta mobiilireitittimen akun kulutusta on saatu minimoitua jopa 20 % verrattuna vanhaan 4G/5G -verkossa toimivaan vaihtoehtoon.',
    ...val),

  JAQQ: (en: string, ...val: string[]) => translate(en,
    'seurata liittymäsi viimeaikaista käyttöä ja katsoa erittelyn soitetuista puheluista sekä lähetetyistä tekstiviesteistä',
    ...val),

  JB5H: (en: string, ...val: string[]) => translate(en,
    'Liittymän käyttäjän vaihtaminen ei onnistu',
    ...val),

  JB8R: (en: string, ...val: string[]) => translate(en,
    'Estää tekstiviestit ja mobiilimaksut esim. erilaisiin TV-chatteihin ja -visailuihin sekä sovelluskaupasta puhelinlaskulle tehtävät ostot Android- ja Windows-laitteilla. Estää myös osallistumisen puhelinäänestyksiin ja markkinointikampanjoihin. Sisältää eston viihdenumeroihin.',
    ...val),

  JBEM: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka ja viite',
    ...val),

  JBMJ: (en: string, ...val: string[]) => translate(en,
    'Tilauksen hyväksymisen jälkeen ostotapahtumaa ei voi enää peruuttaa.',
    ...val),

  JC06: (en: string, ...val: string[]) => translate(en,
    'Julkaise',
    ...val),

  JC42: (en: string, ...val: string[]) => translate(en,
    'Ostolaitteet',
    ...val),

  JCEA: (en: string, ...val: string[]) => translate(en,
    'Kirje',
    ...val),

  JCJF: (en: string, ...val: string[]) => translate(en,
    'Vakion ohjaustietojen tallennuksessa tapahtui virhe, yritä jonkin ajan kuluttua uudestaan.',
    ...val),

  JCSR: (en: string, ...val: string[]) => translate(en,
    'Tukipyyntö on siirretty jatkokäsittelyyn. Olemme lähettäneet kuittauksen tukipyynnön avanneen käyttäjän sähköpostiin.',
    ...val),

  JCT8: (en: string, ...val: string[]) => translate(en,
    'Ennen vahinkoilmoituksen tekemistä, tarkista ja tee seuraavat asiat:',
    ...val),

  JDTO: (en: string, ...val: string[]) => translate(en,
    'Jokin meni pieleen. Ole hyvä ja yritä uudelleen.',
    ...val),

  JE74: (en: string, ...val: string[]) => translate(en,
    'Muista tarkistaa yhteystietosi',
    ...val),

  JE8S: (en: string, ...val: string[]) => translate(en,
    'Virheellinen Kustannuspaikka. Syötä 4-numeroinen kustannuspaikka.',
    ...val),

  JE92: (en: string, ...val: string[]) => translate(en,
    'Puhelut ja viestit ajanvietepalvelunumeroihin, esim. keskustelulinjoille, äänestyksiin, peleihin ja markkinointikampanjoihin',
    ...val),

  JEHN: (en: string, ...val: string[]) => translate(en,
    'Kiinteä netti - mobiililaajakaista',
    ...val),

  JF65: (en: string, ...val: string[]) => translate(en,
    'Lasku',
    ...val),

  JFDK: (en: string, ...val: string[]) => translate(en,
    'Raportin valmistumisessa voi kestää useita minuutteja. Voit tarvittaessa sulkea selaimen ja palata myöhemmin katsomaan onko raportti valmistunut. Etkö löydä tilaustasi raportilta? Osa tilaustiedoista löytyy vielä vanhasta OmaElisasta.',
    ...val),

  JFNL: (en: string, ...val: string[]) => translate(en,
    'Maksettava osuutesi uuden laitteen kuukausimaksusta',
    ...val),

  JFOS: (en: string, ...val: string[]) => translate(en,
    'Lisää koriin',
    ...val),

  JG2T: (en: string, ...val: string[]) => translate(en,
    'Muokkaa henkilötietoja',
    ...val),

  JGP6: (en: string, ...val: string[]) => translate(en,
    'Hae uudestaan',
    ...val),

  JGSO: (en: string, ...val: string[]) => translate(en,
    'Laitekaupan 0,00€-hintaiset tuotteet ovat kokonaan työnantajasi kustantamia. Voit valita kalliimman laitteen osallistumalla itse kustannuksiin. Sopimuskauden pituus vaikuttaa laitteesta itse maksamaasi kokonaishintaan, kuukausimaksun suuruuteen sekä puhelimen vaihtoväliin.',
    ...val),

  JGV8: (en: string, ...val: string[]) => translate(en,
    'Kopioi SM-DP+ -osoite',
    ...val),

  JHAG: (en: string, ...val: string[]) => translate(en,
    'Muutos astuu voimaan',
    ...val),

  JHFF: (en: string, ...val: string[]) => translate(en,
    '-sivulla. Pyrimme vastaamaan arkisin 24h kuluessa.',
    ...val),

  JIMY: (en: string, ...val: string[]) => translate(en,
    'palveluehdot',
    ...val),

  JIP2: (en: string, ...val: string[]) => translate(en,
    'Valitse sopimuskausi',
    ...val),

  JIXE: (en: string, ...val: string[]) => translate(en,
    'Liittymä on suljettu luotonhallinnan toimesta. Ota tarvittaessa yhteyttä asiakaspalveluun {0} ({1})',
    ...val),

  JJRJ: (en: string, ...val: string[]) => translate(en,
    'Laskulle tulostuva maksajan postiosoite. Osoitteeseen lähetetään laskutukseen liittyvät kirjeet, esimerkiksi maksukehoitukset.',
    ...val),

  JJTM: (en: string, ...val: string[]) => translate(en,
    'Laitteet',
    ...val),

  JJTN: (en: string, ...val: string[]) => translate(en,
    'Verkko- ja tietoturvapalvelut',
    ...val),

  JJTO: (en: string, ...val: string[]) => translate(en,
    'IT-palvelut',
    ...val),

  JJTP: (en: string, ...val: string[]) => translate(en,
    'Asiakaspalveluratkaisut',
    ...val),

  JJTQ: (en: string, ...val: string[]) => translate(en,
    'Asiantuntijapalvelut / koulutus',
    ...val),

  JJTR: (en: string, ...val: string[]) => translate(en,
    'Muu',
    ...val),

  JJTS: (en: string, ...val: string[]) => translate(en,
    'Aihe',
    ...val),

  JJWP: (en: string, ...val: string[]) => translate(en,
    'Tiedotteet',
    ...val),

  JK3C: (en: string, ...val: string[]) => translate(en,
    'Ladataan kalenterivapaita',
    ...val),

  JKG2: (en: string, ...val: string[]) => translate(en,
    'Tietueen arvoa muokattu',
    ...val),

  JKJJ: (en: string, ...val: string[]) => translate(en,
    'Pakollinen laskutussopimukselle annettu nimitieto. Ei tulostu laskulle.',
    ...val),

  JKJY: (en: string, ...val: string[]) => translate(en,
    'Maksusopimuksella voit siirtää maksupäivää maksimissaan 30 päivän päähän laskun alkuperäisestä eräpäivästä.',
    ...val),

  JL3U: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä työnantajasi tilaajayhteyshenkilöön.',
    ...val),

  JLB0: (en: string, ...val: string[]) => translate(en,
    'Palautetaan Elisalle',
    ...val),

  JM44: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteiden vuokravastuulaskenta',
    ...val),

  JM7U: (en: string, ...val: string[]) => translate(en,
    'Muut yrityspalvelut',
    ...val),

  JN25: (en: string, ...val: string[]) => translate(en,
    'Julkaistu',
    ...val),

  JN29: (en: string, ...val: string[]) => translate(en,
    'Vanha laite',
    ...val),

  JNCH: (en: string, ...val: string[]) => translate(en,
    'Lomaketta lähetetään',
    ...val),

  JNNJ: (en: string, ...val: string[]) => translate(en,
    'Muutokset asetukseen tulee tehdä Elisan asiakaspalvelun kautta. Jätä viesti asiakaspalvelulle.',
    ...val),

  JNQG: (en: string, ...val: string[]) => translate(en,
    'Aloita tilaaminen valitsemalla laitelista, jolta haluat tilata tuotteita. Voit tehdä tilauksen vain yhdeltä laitelistalta kerrallaan. Jos et tiedä miltä listalta tuotteita valitset, kysy neuvoa esihenkilöltäsi tai työnantajasi OmaElisa-pääkäyttäjältä.',
    ...val),

  JNTB: (en: string, ...val: string[]) => translate(en,
    'Lue OmaLasku -palvelun sopimusehdot',
    ...val),

  JOM2: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu Yritysten OmaElisaan',
    ...val),

  JOM3: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu OmaElisaan',
    ...val),

  JP50: (en: string, ...val: string[]) => translate(en,
    'Voit hallinnoida Yritysten OmaElisa käyttöoikeuksia',
    ...val),

  JPKP: (en: string, ...val: string[]) => translate(en,
    'Sopimushinta',
    ...val),

  JPRH: (en: string, ...val: string[]) => translate(en,
    'Liittymät',
    ...val),

  JQ1V: (en: string, ...val: string[]) => translate(en,
    'Tyhjennä lomake',
    ...val),

  JQD9: (en: string, ...val: string[]) => translate(en,
    'Korkealaatuisen live-videon lähetys, mahdollisuus hyötyä uusista teknologisista innovaatioista sekä laitteista, IoT-sovelluksista ja -ohjelmistoista.',
    ...val),

  JQNF: (en: string, ...val: string[]) => translate(en,
    'OmaLasku -palvelun käyttöönotto on estetty. Ota yhteyttä asiakaspalveluumme.',
    ...val),

  JQO5: (en: string, ...val: string[]) => translate(en,
    'Muokkaa ja hallinnoi liittymiäsi',
    ...val),

  JQSV: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi ei onnistunut, sillä liittymälläsi ei ole käytössä 4G- tai 5G-ominaisuutta.',
    ...val),

  JR24: (en: string, ...val: string[]) => translate(en,
    'Sulje väliaikaisesti',
    ...val),

  JR2G: (en: string, ...val: string[]) => translate(en,
    'Aina käytössä',
    ...val),

  JR9F: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä on laskun yhteyshenkilönä ja/tai vastaanottajana',
    ...val),

  JS1B: (en: string, ...val: string[]) => translate(en,
    'Tarkista toimitusosoite',
    ...val),

  JS80: (en: string, ...val: string[]) => translate(en,
    'OmaElisa Classic -palvelussa',
    ...val),

  JSP4: (en: string, ...val: string[]) => translate(en,
    'Viesti ei voi olla tyhjä.',
    ...val),

  JT5W: (en: string, ...val: string[]) => translate(en,
    'Katso puheratkaisu Vakion ohjeet ja vinkit täältä',
    ...val),

  JTDS: (en: string, ...val: string[]) => translate(en,
    'Hoida laite- ja liittymäasiasi joustavasti silloin kun sinulle sopii.',
    ...val),

  JTM7: (en: string, ...val: string[]) => translate(en,
    'Salasanat eivät täsmää',
    ...val),

  JTXG: (en: string, ...val: string[]) => translate(en,
    'Kopioi aktivointikoodi',
    ...val),

  JTZL: (en: string, ...val: string[]) => translate(en,
    'Palvelu samalla laskulla kuin varsinainen liittymä.',
    ...val),

  JU94: (en: string, ...val: string[]) => translate(en,
    'Ring-lisäpalvelut',
    ...val),

  JUCA: (en: string, ...val: string[]) => translate(en,
    'Kiitos viestistäsi',
    ...val),

  JUY2: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, johon haluat tehdä tilauksen',
    ...val),

  JVCB: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu mobiilivarmenteella',
    ...val),

  JVNS: (en: string, ...val: string[]) => translate(en,
    'Jälkitoimitus',
    ...val),

  JW6Y: (en: string, ...val: string[]) => translate(en,
    'Jos olet työntekijä, voit hallinnoida työsuhdeliittymääsi',
    ...val),

  JWJO: (en: string, ...val: string[]) => translate(en,
    'Haluan säilyttää Ring-liittymän',
    ...val),

  JWLU: (en: string, ...val: string[]) => translate(en,
    'Ominaisuudet',
    ...val),

  JWO2: (en: string, ...val: string[]) => translate(en,
    'Tulosta QR-koodi',
    ...val),

  JWWE: (en: string, ...val: string[]) => translate(en,
    'Jos laitteesi on vahingoittunut, toimitamme korvaavan laitteen rikkoutuneen tilalle. Saat korvaavan laitteen mukana ohjeet vanhaan laitteen palauttamista varten.',
    ...val),

  JWZQ: (en: string, ...val: string[]) => translate(en,
    'Kaikille netin käyttäjille',
    ...val),

  JX0H: (en: string, ...val: string[]) => translate(en,
    'Yli',
    ...val),

  JXN2: (en: string, ...val: string[]) => translate(en,
    'Jälkitoimitettavien tuotteiden maksutapa on aina lasku. Korttimaksu on mahdollinen vain varastosta löytyville laitteille ja lisävarusteille.',
    ...val),

  JXNJ: (en: string, ...val: string[]) => translate(en,
    'Tilaus käsitellään, kun se on hyväksytty. Arkipäivisin ennen klo 12 hyväksytyt tilaukset ehtivät pääsääntöisesti seuraavan arkipäivän toimitukseen.',
    ...val),

  JXQZ: (en: string, ...val: string[]) => translate(en,
    'Myyntipalvelu',
    ...val),

  JXVW: (en: string, ...val: string[]) => translate(en,
    'Työnantajasi on valinnut laitelistojen tuotevalikoiman yrityksenne käytäntöjen mukaan, esimerkiksi tiettyjen mallien tai hintaluokkien perusteella.',
    ...val),

  JXYZ: (en: string, ...val: string[]) => translate(en,
    'Muutospyynnön lähetys epäonnistui.',
    ...val),

  JYLX: (en: string, ...val: string[]) => translate(en,
    'Jokin meni pieleen, ole hyvä ja yritä kirjautumista uudelleen. Jos ongelma toistuu soita asiakaspalveluumme {0} ({1}). Asiakaspalvelumme on avoinna {2}.',
    ...val),

  JYZO: (en: string, ...val: string[]) => translate(en,
    'Sähköposti (työ)',
    ...val),

  JZ8R: (en: string, ...val: string[]) => translate(en,
    '(alv. 0 %)',
    ...val),

  JZHA: (en: string, ...val: string[]) => translate(en,
    'Saat laskun lunastuksesta sähköpostiisi {} kun lunastuspyyntö on hyväksytty.',
    ...val),

  JZUK: (en: string, ...val: string[]) => translate(en,
    'Hoidat asiat nopeasti jonottamatta',
    ...val),

  JZYE: (en: string, ...val: string[]) => translate(en,
    'Valitsemasi sopimuskausi ei ole saatavilla. Valitse toinen sopimuskausi.',
    ...val),

  K0AG: (en: string, ...val: string[]) => translate(en,
    'Jos maksettava osuutesi uuden laitteen kuukausimaksusta jää alle 5 €/kk, saat laskun, kun maksettavaa on vähintään 5 € tai 3 kk välein. Voit muuttaa laskun myöhemmin e-laskuksi verkkopankissasi.',
    ...val),

  K1BD: (en: string, ...val: string[]) => translate(en,
    'Seuraa laitteen antamia ohjeita',
    ...val),

  K1J3: (en: string, ...val: string[]) => translate(en,
    'ja odota hetki, että eSIM-tiedot latautuvat laitteellesi.',
    ...val),

  K1TA: (en: string, ...val: string[]) => translate(en,
    'Vaihdathan tarvittaessa liittymän käyttäjätiedot sekä numeron julkisuustiedon tämän muutoksen jälkeen.',
    ...val),

  K2D6: (en: string, ...val: string[]) => translate(en,
    'Ole hyvä ja valitse toimitustapa.',
    ...val),

  K31E: (en: string, ...val: string[]) => translate(en,
    'Liittymäni',
    ...val),

  K3GT: (en: string, ...val: string[]) => translate(en,
    'Uusi sopimus',
    ...val),

  K3GX: (en: string, ...val: string[]) => translate(en,
    'Alla näet nykyisen version. Ajastettu muutos julkaistaan',
    ...val),

  K3VK: (en: string, ...val: string[]) => translate(en,
    'Tiedostotyyppi {} ei sallittu',
    ...val),

  K3VL: (en: string, ...val: string[]) => translate(en,
    'Word-dokumentti',
    ...val),

  K3VM: (en: string, ...val: string[]) => translate(en,
    'PDF-dokumentti',
    ...val),

  K3VN: (en: string, ...val: string[]) => translate(en,
    'Tekstidokumentti',
    ...val),

  K3VO: (en: string, ...val: string[]) => translate(en,
    'Excel-dokumentti',
    ...val),

  K3VP: (en: string, ...val: string[]) => translate(en,
    'Kuva',
    ...val),

  K3VQ: (en: string, ...val: string[]) => translate(en,
    'Tiedosto',
    ...val),

  K4LF: (en: string, ...val: string[]) => translate(en,
    'tai',
    ...val),

  K4T1: (en: string, ...val: string[]) => translate(en,
    'Omat ja yrityksen tiedot',
    ...val),

  K5E2: (en: string, ...val: string[]) => translate(en,
    'Lisää arvo',
    ...val),

  K5TL: (en: string, ...val: string[]) => translate(en,
    'PUK-koodi',
    ...val),

  K6HI: (en: string, ...val: string[]) => translate(en,
    'Postitusosoite',
    ...val),

  K6XH: (en: string, ...val: string[]) => translate(en,
    'Voit tallentaa listan myös luonnoksena ja julkaista sen myöhemmin.',
    ...val),

  K755: (en: string, ...val: string[]) => translate(en,
    'Kuukausimaksullinen',
    ...val),

  K7KB: (en: string, ...val: string[]) => translate(en,
    '. Mobiiliturva, Matkapuhelinvastaaja ja Reissunetti tekevät arjestasi sujuvampaa ja turvallisempaa. Valitse itsellesi sopivista liittymistä Premium, Perus tai Mini ja liitä mukaan itsellesi sopivat palvelut.',
    ...val),

  K7TH: (en: string, ...val: string[]) => translate(en,
    'Ajanviete-esto',
    ...val),

  K84B: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyynnön tunnistenumero',
    ...val),

  K8DS: (en: string, ...val: string[]) => translate(en,
    'Tilaustyyppi',
    ...val),

  K8NA: (en: string, ...val: string[]) => translate(en,
    'Hyviä syitä käyttää itsepalvelua',
    ...val),

  K9K7: (en: string, ...val: string[]) => translate(en,
    'Avaa',
    ...val),

  KA37: (en: string, ...val: string[]) => translate(en,
    'https://elisa.fi/attachment/content/Elisan_yleiset_sopimusehdot_kuluttaja-asiakkaille.pdf_',
    ...val),

  KB1F: (en: string, ...val: string[]) => translate(en,
    'Valitse itsellesi parhaiten sopivat välineet työntekoon. OmaLaskulla saat parhaan mahdollisen laitteen.',
    ...val),

  KB2E: (en: string, ...val: string[]) => translate(en,
    'liittymän käyttäjälle lähetetään välittömästi tekstiviesti. Käyttäjän on tehtävä numeronsiirtotilaus minimissään viikkoa ennen valitsemaasi irtisanomispäivää.',
    ...val),

  KB82: (en: string, ...val: string[]) => translate(en,
    'Lisätään',
    ...val),

  KBLX: (en: string, ...val: string[]) => translate(en,
    'Yrityksille',
    ...val),

  KC2Q: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi käsittely ei onnistunut. Soita asiakaspalveluumme 010 80 8022, niin saat asian hoidettua. Asiakaspalvelumme auttaa arkisin 8-16:30. Puhelun hinta on 8,35 snt/puh + 16,69 snt/min (alv. {} %).',
    ...val),

  KC2R: (en: string, ...val: string[]) => translate(en,
    'Numeroa käytetään esimerkiksi tilauksiisi liittyvissä asioissa ja salasanasi palauttamisessa.',
    ...val),

  KC4V: (en: string, ...val: string[]) => translate(en,
    'Oletko varma, että haluat jatkaa?',
    ...val),

  KC80: (en: string, ...val: string[]) => translate(en,
    'Ei luetteloon',
    ...val),

  KCZ6: (en: string, ...val: string[]) => translate(en,
    'Vahvista Hyväksy lunastuspyyntö',
    ...val),

  KD2L: (en: string, ...val: string[]) => translate(en,
    'Annan luvan siirtää numeron, vaikka siinä olisi määräaikainen sopimus kesken',
    ...val),

  KD2N: (en: string, ...val: string[]) => translate(en,
    'Ilmoitetut hinnat alv.',
    ...val),

  KDIV: (en: string, ...val: string[]) => translate(en,
    'Tilauksen tiedot',
    ...val),

  KDKZ: (en: string, ...val: string[]) => translate(en,
    'Kuinka tilaan?',
    ...val),

  KDZD: (en: string, ...val: string[]) => translate(en,
    'Olet kirjautuneena OmaElisa tunnuksilla. Kirjaudu ulos ja luo itsellesi Elisa Tunnus (jos sinulla ei vielä ole sitä). Elisa Tunnus toimii jatkossa myös OmaElisaan kirjautuessa etkä tarvitse kirjautua aina erikseen eri palveluihin.',
    ...val),

  KE7V: (en: string, ...val: string[]) => translate(en,
    'Julkaise',
    ...val),

  KES0: (en: string, ...val: string[]) => translate(en,
    'Aktivoi eSIM',
    ...val),

  KF0J: (en: string, ...val: string[]) => translate(en,
    'Elisa KelloSIM',
    ...val),

  KFGF: (en: string, ...val: string[]) => translate(en,
    'Laite on kadonnut',
    ...val),

  KFRW: (en: string, ...val: string[]) => translate(en,
    'Kaipaatko apua?',
    ...val),

  KG04: (en: string, ...val: string[]) => translate(en,
    'Tämä matkapuhelinnumero kuuluu toiselle organisaatiolle',
    ...val),

  KG30: (en: string, ...val: string[]) => translate(en,
    'Uusi tietue luotu',
    ...val),

  KGKV: (en: string, ...val: string[]) => translate(en,
    'Liittymälle ei voi lisätä enempää laitteita.',
    ...val),

  KIEG: (en: string, ...val: string[]) => translate(en,
    'Sopimuksen pituus',
    ...val),

  KIRR: (en: string, ...val: string[]) => translate(en,
    'TARKISTA',
    ...val),

  KJ47: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen tiedot',
    ...val),

  KJAE: (en: string, ...val: string[]) => translate(en,
    'Vahvistamisen jälkeen, et pysty enään näkemään vanhan työnantajasi aikaisia liittymä- tai käyttötietoja.\nLinkitys on tällöin tehtävä uudestaan.',
    ...val),

  KJP5: (en: string, ...val: string[]) => translate(en,
    'Uusia muutoksia on mahdollista tehdä vasta, kun edellinen muutospyyntö on käsitelty. Huomaathan myös, että käsittelyssä oleva muutos tulee näkyviin vasta, kun muutospyyntö on käsitelty.',
    ...val),

  KJTS: (en: string, ...val: string[]) => translate(en,
    'Yritys',
    ...val),

  KK9D: (en: string, ...val: string[]) => translate(en,
    'Laitteen lunastaminen',
    ...val),

  KLNT: (en: string, ...val: string[]) => translate(en,
    'Vahvista sopimuksen irtisanominen',
    ...val),

  KM1R: (en: string, ...val: string[]) => translate(en,
    'Elisa Mobiilivarmenteen',
    ...val),

  KM32: (en: string, ...val: string[]) => translate(en,
    'PIN-koodilla',
    ...val),

  KMG2: (en: string, ...val: string[]) => translate(en,
    'Arvioitu asennusaika',
    ...val),

  KMSU: (en: string, ...val: string[]) => translate(en,
    'Valmis!',
    ...val),

  KMZY: (en: string, ...val: string[]) => translate(en,
    'Nopea tiedonsiirto, 4K-videot ja pilvipalvelut',
    ...val),

  KN84: (en: string, ...val: string[]) => translate(en,
    'Varmista aluksi, että laitteesi on yhteydessä nettiin',
    ...val),

  KNS9: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyynnön lähetys onnistui',
    ...val),

  KNX3: (en: string, ...val: string[]) => translate(en,
    'Vahinko- ja huoltoilmoitus',
    ...val),

  KOL4: (en: string, ...val: string[]) => translate(en,
    'Mikäli et halua vastaanottaa enää muistutuksia maksamattomista laskuista, voit estää SMS-muistutuksen. Esto koskee kaikkia yrityksen laskutussopimuksia.',
    ...val),

  KOO8: (en: string, ...val: string[]) => translate(en,
    'Jos haluat siirtää käyttäjän toiseen yritykseen, voit tehdä sen [Käyttäjät]-sivulla.',
    ...val),

  KP7E: (en: string, ...val: string[]) => translate(en,
    'Asiakastili',
    ...val),

  KQ91: (en: string, ...val: string[]) => translate(en,
    'Etkö löydä tilaustasi täältä?',
    ...val),

  KQ9B: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteiden elinkaari',
    ...val),

  KQE6: (en: string, ...val: string[]) => translate(en,
    'Omat palvelut ja vianmääritys',
    ...val),

  KRDZ: (en: string, ...val: string[]) => translate(en,
    'Lauantai',
    ...val),

  KRTP: (en: string, ...val: string[]) => translate(en,
    'Estää soittamisen ulkomaille sekä ulkomaan numerotiedusteluihin ja yhdistämispalvelunumeroihin.',
    ...val),

  KSKI: (en: string, ...val: string[]) => translate(en,
    'Tekstiviestitiedot ovat muutostöiden vuoksi tilapäisesti pois käytöstä. Pahoittelemme tilannetta.',
    ...val),

  KSRZ: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoite tai matkapuhelinnumero',
    ...val),

  KSXW: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän vaihto vaikuttaa {} laskutustietoihin',
    ...val),

  KU6Y: (en: string, ...val: string[]) => translate(en,
    'Muokkaa tietoja',
    ...val),

  KUKS: (en: string, ...val: string[]) => translate(en,
    'Lähetimme sinulle kertakäyttösalasanan. Kirjaudu sillä palveluun.',
    ...val),

  KUTS: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen nimen lisäosa',
    ...val),

  KUXE: (en: string, ...val: string[]) => translate(en,
    'Kauppaan',
    ...val),

  KUZQ: (en: string, ...val: string[]) => translate(en,
    'Raportti sisältää Elisa Palvelupäätelaitteiden vuokravastuulaskennan. Laskennassa huomioidaan peruskaudella olevien laitteiden yrityksen osuus sekä mahdollisen vahinkopalvelun kuukausiveloitukset. Laskenta suoritetaan raportin luontipäivän perusteella.',
    ...val),

  KV0F: (en: string, ...val: string[]) => translate(en,
    'Elisa Reissunetti 2 Gt on yritysasiakkaille tarkoitettu mobiililiittymän lisäpalvelu. Palvelu aktivoituu automaattisesti kohdemaissa ja mahdollistaa mobiilipäätelaitteella tai kannettavalla tietokoneella käytettävän nopean tiedonsiirtoyhteyden edulliseen hintaan EU/ETA-alueen ulkopuolisissa maissa. Palvelussa ei ole kuukausimaksua, vaan hinnoittelu aktivoituu käytön mukaan 26,90 € / 14 vrk / 2 Gt paketti.',
    ...val),

  KVCU: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjä saa oikeuden kirjautua Yritysten OmaElisaan. Pääkäyttäjällä on mm. oikeus hyväksyä ja hylätä tilauksia, tehdä tilauksia, hyväksyä laskuja sekä nähdä kaikki yrityksen liittymä- ja yritystiedot. Käyttäjän työsähköpostisoite toimii tunnuksena ja salasana toimitetaan tekstiviestillä.',
    ...val),

  KVG8: (en: string, ...val: string[]) => translate(en,
    'Videopuhelut, pilvipalveluiden käyttö ja kevyt etätyöskentely',
    ...val),

  KVG9: (en: string, ...val: string[]) => translate(en,
    'Kevyt netinkäyttö ja sähköpostien lukeminen sekä lähetys',
    ...val),

  KW12: (en: string, ...val: string[]) => translate(en,
    'Ladataan',
    ...val),

  KWE5: (en: string, ...val: string[]) => translate(en,
    'Voit irtisanoa liittymän lähettämällä yhteydenottopyynnön asiakaspalveluumme. Jätä puhelinnumerosi, niin asiakaspalvelustamme otetaan yhteyttä.',
    ...val),

  KZ4H: (en: string, ...val: string[]) => translate(en,
    'Mitä Työntekijän OmaElisassa voi tehdä?',
    ...val),

  KZ5L: (en: string, ...val: string[]) => translate(en,
    'Liittymään on liitetty Mobiilivarmenne, joka lakkaa toimimasta muutoksen myötä. Liittymän käyttäjän on henkilökohtaisesti aktivoitava mobiilivarmenne uudelleen.',
    ...val),

  KZ5M: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteet',
    ...val),

  KZ6N: (en: string, ...val: string[]) => translate(en,
    'Salainen numero',
    ...val),

  KZ8Y: (en: string, ...val: string[]) => translate(en,
    'Sisällön haku epäonnistui...',
    ...val),

  KZJT: (en: string, ...val: string[]) => translate(en,
    'Tyhjennä lomakkeet',
    ...val),

  L07L: (en: string, ...val: string[]) => translate(en,
    'Estä datasiirto ulkomailla',
    ...val),

  L0B1: (en: string, ...val: string[]) => translate(en,
    'Tarkoititko:',
    ...val),

  L0FO: (en: string, ...val: string[]) => translate(en,
    'Olemme vastaanottaneet tukipyynnölle lisätietoa. Jatkamme käsittelyä mahdollisimman pian. ',
    ...val),

  L0X1: (en: string, ...val: string[]) => translate(en,
    'Katalogin poistaminen epäonnistui',
    ...val),

  L1HT: (en: string, ...val: string[]) => translate(en,
    'Valitse laite',
    ...val),

  L1QB: (en: string, ...val: string[]) => translate(en,
    'Tiedostoa ei pystytty lukemaan tai se on tyhjä.',
    ...val),

  L1YX: (en: string, ...val: string[]) => translate(en,
    'Varmistetaan KelloSIM:n saatavuus',
    ...val),

  L2MX: (en: string, ...val: string[]) => translate(en,
    'Tekniset tiedot',
    ...val),

  L2OG: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka',
    ...val),

  L3C5: (en: string, ...val: string[]) => translate(en,
    'Kieli',
    ...val),

  L3TD: (en: string, ...val: string[]) => translate(en,
    'Esto käytössä',
    ...val),

  L62R: (en: string, ...val: string[]) => translate(en,
    'kuukauden',
    ...val),

  L6G9: (en: string, ...val: string[]) => translate(en,
    'Vastaajat työajalla',
    ...val),

  L6NC: (en: string, ...val: string[]) => translate(en,
    'Soita yritysasiakaspalveluun',
    ...val),

  L75K: (en: string, ...val: string[]) => translate(en,
    'Valmis',
    ...val),

  L78N: (en: string, ...val: string[]) => translate(en,
    'Linkin avulla työntekijä pääsee kirjautumaan laitekauppaan sekä valitsemaan itsellensä laitteen.',
    ...val),

  L7BV: (en: string, ...val: string[]) => translate(en,
    'Tietojen päivitys epäonnistui, kokeile hetken kuluttua uudestaan.',
    ...val),

  L7CX: (en: string, ...val: string[]) => translate(en,
    'Hakutuloksia on liikaa. Tarkenna hakua.',
    ...val),

  L7CZ: (en: string, ...val: string[]) => translate(en,
    'Ei hakutuloksia',
    ...val),

  L7DX: (en: string, ...val: string[]) => translate(en,
    'Voit hakea useammalla hakusanalla erottelemalla sanat välilyönnillä.',
    ...val),

  L7HY: (en: string, ...val: string[]) => translate(en,
    'Kuinka saan lisää maksuaikaa',
    ...val),

  L7ML: (en: string, ...val: string[]) => translate(en,
    'Odottaa käsittelyä',
    ...val),

  L7QB: (en: string, ...val: string[]) => translate(en,
    'Yritystiedot',
    ...val),

  L80Q: (en: string, ...val: string[]) => translate(en,
    'Toimi nopeasti, tuotetta jäljellä enää rajoitettu erä',
    ...val),

  L866: (en: string, ...val: string[]) => translate(en,
    'Lisenssien hallintaportaali',
    ...val),

  L88I: (en: string, ...val: string[]) => translate(en,
    'Elisan kuluttajaliittymien yritysasiakkaana (entinen Saunalahden yritysasiakas) ',
    ...val),

  L8A5: (en: string, ...val: string[]) => translate(en,
    'Ei julkaistu',
    ...val),

  L8XT: (en: string, ...val: string[]) => translate(en,
    'Numeron julkisuus',
    ...val),

  L9E5: (en: string, ...val: string[]) => translate(en,
    'Sopijayritystä ei voi vaihtaa, koska laitteen käyttäjällä on aktiivinen Elisa Oma Laitelasku.',
    ...val),

  L9HF: (en: string, ...val: string[]) => translate(en,
    'Tuote ei ole saatavilla',
    ...val),

  L9OE: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä asiakaspalveluumme 010 80 8088 niin saat asian hoidettua. Asiakaspalvelumme auttaa arkisin klo 8.00-16.30). Pahoittelemme häiriötä.',
    ...val),

  L9QG: (en: string, ...val: string[]) => translate(en,
    'Tunniste',
    ...val),

  LA93: (en: string, ...val: string[]) => translate(en,
    'Eräpäivä',
    ...val),

  LBBW: (en: string, ...val: string[]) => translate(en,
    'lähettää vahinko- tai huoltoilmoituksen laitteestasi',
    ...val),

  LBWF: (en: string, ...val: string[]) => translate(en,
    'Liittymän käyttäjän tai käyttötarkoituksen vaihtaminen ei onnistu, koska liittymällä on käytössä Ring-palvelu.',
    ...val),

  LCCX: (en: string, ...val: string[]) => translate(en,
    'Sis. {} Gt datan käyttöä Pohjola & Baltia sekä muun EU:n alueella. Paketin ylimenevä datankäyttö 6,50 € / alkava 10 Gt.',
    ...val),

  LDM3: (en: string, ...val: string[]) => translate(en,
    'Mikäli sinulla on vain yksi eSIM-profiili, on se heti käytössäsi. Löydät ohjeet ja QR-koodin jatkossa liittymäsi tiedoista. Voit käyttää QR-koodia samalla laitteella rajattomasti. Huomioithan, että eSIM toimii vain laitteella, johon se on ensimmäisellä kerralla ladattu.',
    ...val),

  LDN7: (en: string, ...val: string[]) => translate(en,
    'Muistathan tehdä erillisen myyntikirjauksen Salesforceen',
    ...val),

  LDYA: (en: string, ...val: string[]) => translate(en,
    'Valitse toimitilasi koko:',
    ...val),

  LEEP: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi ei onnistunut, sillä liittymäsi ei ole Elisan yritysasiakasliittymä.',
    ...val),

  LEO9: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi laite ja lisätarvikkeet',
    ...val),

  LFBC: (en: string, ...val: string[]) => translate(en,
    'Etsitkö nettiä isommalle toimistolle?',
    ...val),

  LFU7: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen nimen lisäosa',
    ...val),

  LFZM: (en: string, ...val: string[]) => translate(en,
    'Irtisano Elisa Mobiilivarmenne Työntekijälle -palvelu',
    ...val),

  LG1W: (en: string, ...val: string[]) => translate(en,
    'Puheratkaisu Vakio alueellisella numerolla, Elisa matkapuhelinvastaaja, Elisa WiFi-puhelu, Elisa Lankanumero, Puhelun edelleenyhdistäminen, Kaikkien lähtevien ulkomaanpuheluiden esto (BOIC),Kaikkien lähtevien puheluiden esto (BAOC), Lähtevien ulkomaan puheluiden esto sallittu vain Suomeen (BOIH), Kaikkien saapuvien puheluiden esto (BAIC), Kaikkien saapuvien puheluiden esto ulkomaisessa verkossa (BIRO), Sopimusasiointi, Vakio käyttöhinnat, Puheratkaisu Vakio valtakunnallisella yritysnumerolla, Yritysnumerotekstiviesti',
    ...val),

  LG3L: (en: string, ...val: string[]) => translate(en,
    'VDSL',
    ...val),

  LG40: (en: string, ...val: string[]) => translate(en,
    'Tarkista pääkäyttäjien tiedot',
    ...val),

  LGDG: (en: string, ...val: string[]) => translate(en,
    'Vain numeroita',
    ...val),

  LGPO: (en: string, ...val: string[]) => translate(en,
    'Poista käytöstä',
    ...val),

  LHCF: (en: string, ...val: string[]) => translate(en,
    'Siirry laitekauppaan',
    ...val),

  LHJ4: (en: string, ...val: string[]) => translate(en,
    'Sopimuksen päättymispäivä',
    ...val),

  LIHP: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti tilausta ei voida käsitellä luottopäätöksen vuoksi. Ota yhteyttä asiakaspalveluun.',
    ...val),

  LIU7: (en: string, ...val: string[]) => translate(en,
    'Viestit',
    ...val),

  LIUF: (en: string, ...val: string[]) => translate(en,
    'Päätä sopimus ja palauta laite',
    ...val),

  LJ4Y: (en: string, ...val: string[]) => translate(en,
    'Myyntipalvelumme tavoitat arkisin 8-16:00.',
    ...val),

  LJQ3: (en: string, ...val: string[]) => translate(en,
    'Numeroavaruudet',
    ...val),

  LJTY: (en: string, ...val: string[]) => translate(en,
    'Rekisteröidy',
    ...val),

  LK2D: (en: string, ...val: string[]) => translate(en,
    'Tarkemmat tiedot jäljellä olevista maksueristä löydät laskulta.',
    ...val),

  LK73: (en: string, ...val: string[]) => translate(en,
    'Maksa',
    ...val),

  LK7B: (en: string, ...val: string[]) => translate(en,
    'Osoite tai numero',
    ...val),

  LKBV: (en: string, ...val: string[]) => translate(en,
    'Laite lunastetaan',
    ...val),

  LKVJ: (en: string, ...val: string[]) => translate(en,
    'Tilaa mobiililaajakaistaliittymä yhdessä Netti Liten kanssa.',
    ...val),

  LLHD: (en: string, ...val: string[]) => translate(en,
    'Estä kaikki käyttö EU:n ja ETA:n ulkopuolella',
    ...val),

  LLQR: (en: string, ...val: string[]) => translate(en,
    'Kuljetus toimitusosoitteeseen. Perillä seuraavana työpäivänä klo 16 mennessä',
    ...val),

  LM60: (en: string, ...val: string[]) => translate(en,
    'Olet kirjautuneena Työntekijän OmaElisaan. Voit hoitaa omia työliittymä- ja laiteasioitasi',
    ...val),

  LMKE: (en: string, ...val: string[]) => translate(en,
    'Kun kaikki tuotteet on saatavilla',
    ...val),

  LN7R: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä on lähettänyt lunastuspyynnön laitteesta',
    ...val),

  LNHO: (en: string, ...val: string[]) => translate(en,
    '{0} viite- sekä kustannuspaikka tiedot päivittyvät automaattisesti uuden henkilön tietojen mukaan. Tarkastathan {1} laskutustiedot tarvittaessa.',
    ...val),

  LNZ8: (en: string, ...val: string[]) => translate(en,
    'Ymmärrän, että viestit näkyvät kaikille yritykseni OmaElisa-käyttäjille.',
    ...val),

  LO5I: (en: string, ...val: string[]) => translate(en,
    'Liittymä ei ole liitetty yrityksesi Ring-ratkaisuun',
    ...val),

  LO81: (en: string, ...val: string[]) => translate(en,
    'Ladataan raporttia',
    ...val),

  LOUS: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisa',
    ...val),

  LQ3X: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu ulos',
    ...val),

  LQ5X: (en: string, ...val: string[]) => translate(en,
    'Tuotetyypit',
    ...val),

  LQ6I: (en: string, ...val: string[]) => translate(en,
    'Päättyy',
    ...val),

  LR7P: (en: string, ...val: string[]) => translate(en,
    'Otettu uudelleen käsittelyyn',
    ...val),

  LRAQ: (en: string, ...val: string[]) => translate(en,
    'norm.',
    ...val),

  LROP: (en: string, ...val: string[]) => translate(en,
    'Liittymän tila',
    ...val),

  LRT3: (en: string, ...val: string[]) => translate(en,
    'Ei laitteita',
    ...val),

  LRZV: (en: string, ...val: string[]) => translate(en,
    'Sisältää laitteen',
    ...val),

  LSIF: (en: string, ...val: string[]) => translate(en,
    'Jos haluat luoda uuden Microsoft tilin (tenant) odota hetki ja ilmoitamme kun kaikki on valmista.',
    ...val),

  LSQ3: (en: string, ...val: string[]) => translate(en,
    'Tällä laskutusopimuksella on ajastettu muutospyyntö',
    ...val),

  LSWI: (en: string, ...val: string[]) => translate(en,
    'Hanki nopea netti etätöihin',
    ...val),

  LT6X: (en: string, ...val: string[]) => translate(en,
    'Kirjoita kommenttisi tähän..',
    ...val),

  LTEZ: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä tilaajayhteyshenkilöön',
    ...val),

  LUAI: (en: string, ...val: string[]) => translate(en,
    'Liian suuri tiedostokoko {} MB',
    ...val),

  LUCJ: (en: string, ...val: string[]) => translate(en,
    'Asennuspalvelu',
    ...val),

  LUFX: (en: string, ...val: string[]) => translate(en,
    'Liittymän aktivointi kesken',
    ...val),

  LUOE: (en: string, ...val: string[]) => translate(en,
    'Haluatko lunastaa vai palauttaa vanhan laitteesi?',
    ...val),

  LUW8: (en: string, ...val: string[]) => translate(en,
    'Vahvista ja maksa',
    ...val),

  LUZM: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä',
    ...val),

  LVJT: (en: string, ...val: string[]) => translate(en,
    'Kun puhelinnumero luovutetaan liittymän käyttäjälle, on ensimmäinen mahdollinen irtisanomispäivä viikon kuluttua nykyhetkestä',
    ...val),

  LVOA: (en: string, ...val: string[]) => translate(en,
    'Miten palvelu maksetaan?',
    ...val),

  LVRN: (en: string, ...val: string[]) => translate(en,
    'Viite ',
    ...val),

  LVRQ: (en: string, ...val: string[]) => translate(en,
    'Valitse alta toimitusosoite johon toimitamme palautuspussin. Tuhoutunut laite tulee palauttaa Elisalle viikon kuluessa.',
    ...val),

  LW2K: (en: string, ...val: string[]) => translate(en,
    'Hups… järjestelmässä tapahtui virhe',
    ...val),

  LXFL: (en: string, ...val: string[]) => translate(en,
    'Yritys, johon käyttäjä siirretään',
    ...val),

  LXSR: (en: string, ...val: string[]) => translate(en,
    'Lisäpalvelut',
    ...val),

  LYDG: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän siirto toiseen yritykseen epäonnistui',
    ...val),

  LYF6: (en: string, ...val: string[]) => translate(en,
    'Työntekijän osuus',
    ...val),

  LYSP: (en: string, ...val: string[]) => translate(en,
    'Palvelumuutokset',
    ...val),

  LYW5: (en: string, ...val: string[]) => translate(en,
    'Liittymän väliaikainen sulku',
    ...val),

  LZF8: (en: string, ...val: string[]) => translate(en,
    'Olen lukenut huoltoilmoituksen ohjeet',
    ...val),

  LZMG: (en: string, ...val: string[]) => translate(en,
    'Muuta laskutustapaa',
    ...val),

  LZT3: (en: string, ...val: string[]) => translate(en,
    'Liittymän aktivointi on kesken, jonka aikana et voi tehdä muita muutoksia liittymälle.',
    ...val),

  M03S: (en: string, ...val: string[]) => translate(en,
    'Kyllä',
    ...val),

  M0RI: (en: string, ...val: string[]) => translate(en,
    'Lisenssien hallintaportaalissa voit hallinnoida M365:n ja Dynamics 365:n lisenssejä.',
    ...val),

  M0TS: (en: string, ...val: string[]) => translate(en,
    'Liitä vakioon',
    ...val),

  M0W7: (en: string, ...val: string[]) => translate(en,
    'Määrä',
    ...val),

  M16R: (en: string, ...val: string[]) => translate(en,
    'Olet vaihtamassa sopijayritystä. Laskutussopimus on päivitettävä uuden sopijayrityksen mukaan.',
    ...val),

  M1E7: (en: string, ...val: string[]) => translate(en,
    'Aktivoi SIM-kortti',
    ...val),

  M1RK: (en: string, ...val: string[]) => translate(en,
    'Huomaa, että jos siirrettävällä käyttäjällä avoimia hyväksyntäpyyntöjä, ne peruutetaan. Myös käyttäjän mahdolliset Yritysten OmaElisan käyttöoikeudet sekä Ring- ja Vakio- palveluiden oikeudet poistuvat. Käyttöoikeudet ja lisäpalvelut voi aktivoida uudelleen siirron jälkeen.',
    ...val),

  M1TW: (en: string, ...val: string[]) => translate(en,
    'Irtisano liittymä',
    ...val),

  M248: (en: string, ...val: string[]) => translate(en,
    'Voit antaa useamman sähköpostisoitteen kerralla tai liittää esim. sähköpostiosoitteet (max.500) sisältävän',
    ...val),

  M2FC: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän poistaminen epäonnistui',
    ...val),

  M3ZW: (en: string, ...val: string[]) => translate(en,
    'Antamaasi puhelinnumeroa tai sähköpostia ei voida päivittäää',
    ...val),

  M41V: (en: string, ...val: string[]) => translate(en,
    'Jatkokausi',
    ...val),

  M4JX: (en: string, ...val: string[]) => translate(en,
    'Netti kotimaassa, Pohjolassa ja Baltiassa',
    ...val),

  M4P9: (en: string, ...val: string[]) => translate(en,
    'Hoida liittymä- ja laskutusasiat nopeasti silloin kun se sinulle sopii. Voit esimerkiksi seurata laskujesi tilannetta ja tarkistaa PUK-koodin veloituksetta.',
    ...val),

  M59G: (en: string, ...val: string[]) => translate(en,
    'Liittymän avaamisessa tapahtui virhe',
    ...val),

  M5EN: (en: string, ...val: string[]) => translate(en,
    'Kiitos tilauksesta',
    ...val),

  M64T: (en: string, ...val: string[]) => translate(en,
    'Elisa Yritystietoturvan tietojen palautus',
    ...val),

  M6CL: (en: string, ...val: string[]) => translate(en,
    'Netti plus ja Netti pro',
    ...val),

  M6SM: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisa',
    ...val),

  M6TP: (en: string, ...val: string[]) => translate(en,
    'Listan nimi',
    ...val),

  M6V5: (en: string, ...val: string[]) => translate(en,
    'Sopimus on voimassa toistaiseksi',
    ...val),

  M76W: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu Elisa Tunnuksella',
    ...val),

  M8C7: (en: string, ...val: string[]) => translate(en,
    'Ei tekstiviestipakettia',
    ...val),

  M9H0: (en: string, ...val: string[]) => translate(en,
    'Liittymän ja käyttäjän tiedot',
    ...val),

  M9HN: (en: string, ...val: string[]) => translate(en,
    'Muutoksen voimaantulo',
    ...val),

  MA8D: (en: string, ...val: string[]) => translate(en,
    'Halvin ensin',
    ...val),

  MADI: (en: string, ...val: string[]) => translate(en,
    'Reilusti verkkokaupan varastossa',
    ...val),

  MAEZ: (en: string, ...val: string[]) => translate(en,
    'Valitse paketti',
    ...val),

  MAYV: (en: string, ...val: string[]) => translate(en,
    'KelloSIM-palvelun hallinnointi ei ole mahdollista puhelimesta.',
    ...val),

  MB11: (en: string, ...val: string[]) => translate(en,
    'Huomiothan, että sopimuksen päättäminen on sitova eikä muutos ole peruttavissa.',
    ...val),

  MB13: (en: string, ...val: string[]) => translate(en,
    '80-alkuiset viitenumerolaskut',
    ...val),

  MBG9: (en: string, ...val: string[]) => translate(en,
    'Palvelut',
    ...val),

  MBNB: (en: string, ...val: string[]) => translate(en,
    'Näytössä naarmuja tai kulumaa',
    ...val),

  MBUX: (en: string, ...val: string[]) => translate(en,
    'Täytä käyttötarkoitus',
    ...val),

  MC5L: (en: string, ...val: string[]) => translate(en,
    'eSIMiä tukevat laitteet',
    ...val),

  MCL7: (en: string, ...val: string[]) => translate(en,
    'Valitse 5G+ -yritysliittymä',
    ...val),

  MCL8: (en: string, ...val: string[]) => translate(en,
    'Valitse 5G+ -mobiililaajakaista',
    ...val),

  MCW3: (en: string, ...val: string[]) => translate(en,
    'Tietokoneet',
    ...val),

  MEHC: (en: string, ...val: string[]) => translate(en,
    'Kaikki laskut',
    ...val),

  MEHD: (en: string, ...val: string[]) => translate(en,
    'Kaikki tilaukset',
    ...val),

  MEIL: (en: string, ...val: string[]) => translate(en,
    'Poista käyttäjä',
    ...val),

  MEMF: (en: string, ...val: string[]) => translate(en,
    'Vahvistamme irtisanomisen sähköpostitse. Huomioithan, että tulet saamaan loppulaskun irtisanotusta palvelusta, mikäli käyttö- tai kuukausimaskuja on vielä laskuttamatta.',
    ...val),

  MF9P: (en: string, ...val: string[]) => translate(en,
    'eSIM on kuin perinteinen SIM-kortti, mutta täysin sähköinen. Tällä hetkellä eSIM on saatavilla uusimmille iPhone-puhelimille, iPad-tableteille ja Samsung Galaxy Watch -kelloille.',
    ...val),

  MFED: (en: string, ...val: string[]) => translate(en,
    'Yritysguru auttaa teknisissä ongelmissa',
    ...val),

  MGPN: (en: string, ...val: string[]) => translate(en,
    'Vahvista laitteen lunastaminen yritykselle',
    ...val),

  MGWG: (en: string, ...val: string[]) => translate(en,
    'Liittymän päivitys',
    ...val),

  MH00: (en: string, ...val: string[]) => translate(en,
    '4K-videot ja pilvipalvelut',
    ...val),

  MH8K: (en: string, ...val: string[]) => translate(en,
    'Lunastushinta',
    ...val),

  MI9J: (en: string, ...val: string[]) => translate(en,
    'tunnukset',
    ...val),

  MIFJ: (en: string, ...val: string[]) => translate(en,
    'Minun tietoni',
    ...val),

  MIL5: (en: string, ...val: string[]) => translate(en,
    'Siirry palvelun tietoihin',
    ...val),

  MIMF: (en: string, ...val: string[]) => translate(en,
    'Nouto postin pakettiautomaatista seuraavana työpäivänä klo 16 jälkeen',
    ...val),

  MIQC: (en: string, ...val: string[]) => translate(en,
    'Vastausryhmä',
    ...val),

  MJ63: (en: string, ...val: string[]) => translate(en,
    'Tällä laskutussopimuksella on ajastettu muutospyyntö, joten muokkaus ei ole mahdollista. Voit poistaa muutospyynnön tai ajastaa sen uudestaan.',
    ...val),

  ML02: (en: string, ...val: string[]) => translate(en,
    'Ostolaitteet',
    ...val),

  MLUL: (en: string, ...val: string[]) => translate(en,
    'Lähetä vahvistuslinkki',
    ...val),

  MM8Z: (en: string, ...val: string[]) => translate(en,
    'Aktiivinen tila',
    ...val),

  MMA5: (en: string, ...val: string[]) => translate(en,
    'painike lisäämällä oikea laskutussopimus',
    ...val),

  MMRF: (en: string, ...val: string[]) => translate(en,
    'Jälkitoimitus',
    ...val),

  MMUE: (en: string, ...val: string[]) => translate(en,
    'asiakaspalveluumme',
    ...val),

  MN35: (en: string, ...val: string[]) => translate(en,
    'Irtisanominen',
    ...val),

  MNEQ: (en: string, ...val: string[]) => translate(en,
    'Liitä laitteet laiterekisteröintiohjelmaan',
    ...val),

  MNN3: (en: string, ...val: string[]) => translate(en,
    'Luovutettava liittymä',
    ...val),

  MO41: (en: string, ...val: string[]) => translate(en,
    'Saan tietoni Elisan yritysasiakkaiden ohjeista. En välttämättä osaa vastata kaikkiin kysymyksiin, mutta kehityn jatkuvasti. Voit myös kokeilla kysyä samaa asiaa eri tavalla, niin osaan joskus antaa paremman vastauksen.',
    ...val),

  MOAZ: (en: string, ...val: string[]) => translate(en,
    'Olet poistamassa alla olevan tietueen. Tätä toimenpidettä ei ole mahdollista perua. Haluatko varmasti poistaa tietueen?',
    ...val),

  MOF8: (en: string, ...val: string[]) => translate(en,
    'Tilauksen käsittely ei onnistunut. Soita asiakaspalveluumme {0} niin saat asian hoidettua. Asiakaspalvelumme auttaa {1}. Puhelun hinta on {2}.',
    ...val),

  MOR3: (en: string, ...val: string[]) => translate(en,
    'Hyväksy sopimusehdot',
    ...val),

  MOWN: (en: string, ...val: string[]) => translate(en,
    'Ei agentteja paikalla',
    ...val),

  MPA5: (en: string, ...val: string[]) => translate(en,
    'Y-tunnus',
    ...val),

  MPFC: (en: string, ...val: string[]) => translate(en,
    'Uusi laitelista',
    ...val),

  MPKA: (en: string, ...val: string[]) => translate(en,
    'Tilauksesi lähetetään vasta, kun kaikkia tuotteita on saatavilla varastossa.',
    ...val),

  MQ9Q: (en: string, ...val: string[]) => translate(en,
    'Ole hyvä ja odota hetki.',
    ...val),

  MQHO: (en: string, ...val: string[]) => translate(en,
    'Liittymälle on tilattu muutos, jonka aikana et voi tehdä muita muutoksia liittymälle.',
    ...val),

  MQQC: (en: string, ...val: string[]) => translate(en,
    'Huomaathan, että laskun tila päivittyy n. 3 arkipäivän aikana, joten maksettu lasku voi näkyä hetken avoimena. Myöhässä maksetusta laskusta ei tarvitse ilmoittaa asiakaspalveluumme.',
    ...val),

  MRD6: (en: string, ...val: string[]) => translate(en,
    'Soita numeroon *100#, niin saat tietää liittymäsi saldotilanteen. Saat paluuviestinä tiedot datan kulutuksestasi ulkomailla. Palvelu on maksuton. ',
    ...val),

  MRGG: (en: string, ...val: string[]) => translate(en,
    'Huomaathan, että xlsx-tiedostomuoto ei kelpaa.',
    ...val),

  MS5F: (en: string, ...val: string[]) => translate(en,
    'määräaikainen',
    ...val),

  MSD6: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että käyttäjällä, jota olet poistamassa on pääkäyttäjäoikeudet Yritysten OmaElisaan. Poistamisen jälkeen häneltä poistuu myös pääkäyttäjäoikeudet.',
    ...val),

  MTNX: (en: string, ...val: string[]) => translate(en,
    'Käsittelemme irtisanomispyyntösi mahdollisimman pian. Saat vahvistuksen sähköpostilla, kun palvelu on irtisanottu.',
    ...val),

  MU1K: (en: string, ...val: string[]) => translate(en,
    'WiFi-yhteys',
    ...val),

  MUF5: (en: string, ...val: string[]) => translate(en,
    'Ei käytössä',
    ...val),

  MV0P: (en: string, ...val: string[]) => translate(en,
    'Videopuhelut ja sujuvat pilvipalvelut',
    ...val),

  MV3J: (en: string, ...val: string[]) => translate(en,
    'Microsoft 365',
    ...val),

  MVTD: (en: string, ...val: string[]) => translate(en,
    'Rajaton',
    ...val),

  MW58: (en: string, ...val: string[]) => translate(en,
    'Vastaajan ollessa varattu',
    ...val),

  MWDL: (en: string, ...val: string[]) => translate(en,
    'Raportin nimi',
    ...val),

  MWSW: (en: string, ...val: string[]) => translate(en,
    'Puhelut ja viestit asiointipalvelunumeroihin, kuten lahjoitukset hyväntekeväisyyteen, tavaroiden ja palveluiden maksaminen puheluilla tai tekstiviesteillä',
    ...val),

  MX5U: (en: string, ...val: string[]) => translate(en,
    'Katuosoite ja numero',
    ...val),

  MXPB: (en: string, ...val: string[]) => translate(en,
    'Pelkkä SIM-kortti',
    ...val),

  MXR5: (en: string, ...val: string[]) => translate(en,
    'Tekstiviestit',
    ...val),

  MY67: (en: string, ...val: string[]) => translate(en,
    'Nämä lisäpalvelut poistuvat käytöstä päivittäessäsi liittymän',
    ...val),

  MYQX: (en: string, ...val: string[]) => translate(en,
    'Liittymälläsi on Elisa MultiSIM-palvelu, joka estää palvelun käyttöönoton.',
    ...val),

  MYT5: (en: string, ...val: string[]) => translate(en,
    'Löydät SIM-kortin numeron SIM-kortista.',
    ...val),

  MYX9: (en: string, ...val: string[]) => translate(en,
    'Valitse päivä',
    ...val),

  MZ5C: (en: string, ...val: string[]) => translate(en,
    'Estää tekstiviestit ja mobiilimaksut esim. aikataulu- ja tiedotuspalveluihin sekä pankkipalveluihin. Estää myös taksin tilaamisen. Sisältää eston viihde-, ajanviete- ja asiointinumeroihin.',
    ...val),

  MZS3: (en: string, ...val: string[]) => translate(en,
    'Uusi laskutussopimus',
    ...val),

  N04E: (en: string, ...val: string[]) => translate(en,
    'Lankaliittymät',
    ...val),

  N0UD: (en: string, ...val: string[]) => translate(en,
    'Löydetty käyttäjä',
    ...val),

  N11H: (en: string, ...val: string[]) => translate(en,
    'Tarvitsetko apua?',
    ...val),

  N1WI: (en: string, ...val: string[]) => translate(en,
    'Lähetetään',
    ...val),

  N2CC: (en: string, ...val: string[]) => translate(en,
    'Vahinko- tai huoltoilmoitus',
    ...val),

  N2OE: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjä saa F-Securelta sähköpostitse uudet kirjautumistunnukset, joilla hän voi kirjautua takaisin sisään.',
    ...val),

  N2RK: (en: string, ...val: string[]) => translate(en,
    'Ajasta uudelleen',
    ...val),

  N31T: (en: string, ...val: string[]) => translate(en,
    'Kuukausikohtainen erittely',
    ...val),

  N3A8: (en: string, ...val: string[]) => translate(en,
    'Ole hyvä ja valitse noutopiste.',
    ...val),

  N3V5: (en: string, ...val: string[]) => translate(en,
    'Voi harmi',
    ...val),

  N3YX: (en: string, ...val: string[]) => translate(en,
    'lunastushinta',
    ...val),

  N41Q: (en: string, ...val: string[]) => translate(en,
    '+ Lisää uusi',
    ...val),

  N4LE: (en: string, ...val: string[]) => translate(en,
    'Ajastettu muutos',
    ...val),

  N4QO: (en: string, ...val: string[]) => translate(en,
    'Valitse uusi laite palautettavan laitteen tilalle',
    ...val),

  N4TR: (en: string, ...val: string[]) => translate(en,
    'Katso puhelintuen numero ja missä asioissa voit hyödyntää puhelintukea',
    ...val),

  N4VX: (en: string, ...val: string[]) => translate(en,
    'Käytössä oleva tavoitettavuusketju',
    ...val),

  N689: (en: string, ...val: string[]) => translate(en,
    'Lisenssien hallinta',
    ...val),

  N68W: (en: string, ...val: string[]) => translate(en,
    'Listahinta',
    ...val),

  N6BZ: (en: string, ...val: string[]) => translate(en,
    'Käynnistä laitteesi kortin aktivoinnin jälkeen uudelleen. Uuden SIM-kortin PIN-koodi on aluksi 1234. SIM-kortin vaihdosta veloitetaan {} (alv. 0 %). Vanha korttisi lakkaa toimimasta aktivoinnin jälkeen.',
    ...val),

  N6FA: (en: string, ...val: string[]) => translate(en,
    'Liittymältä poistuu lisäpalveluita',
    ...val),

  N7W5: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimus on irtisanottu, joten sen tietoja ei voi enää muokata.',
    ...val),

  N88P: (en: string, ...val: string[]) => translate(en,
    'Lunastushinta käyttäjälle',
    ...val),

  N894: (en: string, ...val: string[]) => translate(en,
    'Voit silti vahvistaa tilauksen, jolloin liittymälle valikoituu seuraava vapaana oleva puhelinnumero. Mikäli haluat valita puhelinnumeron itse, aloita tilaus alusta.',
    ...val),

  N8JO: (en: string, ...val: string[]) => translate(en,
    'Tai jättää yhteydenottopyynnön yritysasiakasmyynnille',
    ...val),

  N8T5: (en: string, ...val: string[]) => translate(en,
    'Elisa Mobiilivarmenne Työntekijälle -palvelulla tarjoat työntekijöillesi [Elisa Mobiilivarmenteen], joka on vahva sähköinen tunnistautumisväline. Elisa Mobiilivarmenteella työntekijäsi pääsevät asioimaan sähköisissä palveluissa turvallisesti ja vaivattomasti. Mobiilivarmenne kuuluu puhelinedun piiriin, joten voit tarjota sen työntekijöillesi työsuhde-etuna.',
    ...val),

  NALH: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimusta muokattu',
    ...val),

  NBNH: (en: string, ...val: string[]) => translate(en,
    'Sisältö',
    ...val),

  NC0D: (en: string, ...val: string[]) => translate(en,
    'Avoimet tilaukset',
    ...val),

  NC6Y: (en: string, ...val: string[]) => translate(en,
    'Hylkää lunastuspyyntö',
    ...val),

  NCIJ: (en: string, ...val: string[]) => translate(en,
    '+ Uusi laskutussopimus',
    ...val),

  ND39: (en: string, ...val: string[]) => translate(en,
    'Valitse oletus laskutussopimus Elisa palvelupäätelaitteille ja laitelistoille. Voit jatkossa käyttää oletus laskutussopimusta tai halutessasi esim. luoda laitelista- tai tilauskohtaisen laskutussopimuksen.',
    ...val),

  NDIM: (en: string, ...val: string[]) => translate(en,
    'Puhelimen käyttö (verkkovierailut ulkomailla)',
    ...val),

  NDRY: (en: string, ...val: string[]) => translate(en,
    'Valitse muutoksen voimaantulopäivä',
    ...val),

  NDWK: (en: string, ...val: string[]) => translate(en,
    'Postiosoite',
    ...val),

  NE0O: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää tai käyttötarkoitusta ei asetettu',
    ...val),

  NENG: (en: string, ...val: string[]) => translate(en,
    'Lisää liittymä Ringiin',
    ...val),

  NERD: (en: string, ...val: string[]) => translate(en,
    'Valintasi vaikuttaa vain siihen, minkä yrityksen käyttäjälistalla tietosi näkyvät. Pääset hallinnoimaan yrityksiä käyttöoikeuksiesi mukaisesti.',
    ...val),

  NF60: (en: string, ...val: string[]) => translate(en,
    'Tilaajan yhteystiedot',
    ...val),

  NF7W: (en: string, ...val: string[]) => translate(en,
    'Täydennä yrityksen tiedot',
    ...val),

  NFGH: (en: string, ...val: string[]) => translate(en,
    'laitevalikoimasta',
    ...val),

  NFRH: (en: string, ...val: string[]) => translate(en,
    '(ei pakollinen)',
    ...val),

  NG9B: (en: string, ...val: string[]) => translate(en,
    'Soittoaika',
    ...val),

  NH45: (en: string, ...val: string[]) => translate(en,
    'Ei verkkovierailun käyttöä!',
    ...val),

  NH72: (en: string, ...val: string[]) => translate(en,
    'Salasana on virheellinen.',
    ...val),

  NH88: (en: string, ...val: string[]) => translate(en,
    'Tietueen muokkaus kesken',
    ...val),

  NHMG: (en: string, ...val: string[]) => translate(en,
    'Laskun maksu keskeytyi, sinua ei veloitettu.',
    ...val),

  NI34: (en: string, ...val: string[]) => translate(en,
    'Syötä alennuskoodi',
    ...val),

  NI69: (en: string, ...val: string[]) => translate(en,
    'OmaElisa Henkilöasiakkaille',
    ...val),

  NID5: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoitteen vahvistaminen onnistui',
    ...val),

  NIHS: (en: string, ...val: string[]) => translate(en,
    'MIN',
    ...val),

  NIMY: (en: string, ...val: string[]) => translate(en,
    'Laitteen toimitus',
    ...val),

  NIWE: (en: string, ...val: string[]) => translate(en,
    'Tallenna',
    ...val),

  NIY2: (en: string, ...val: string[]) => translate(en,
    'Tee nykyinen tilaus loppuun ja tilaa sen jälkeen tuotteet eri laitelistalta. Jos jatkat toiseen laitelistaan, ostoskorisi tyhjennetään automaattisesti.',
    ...val),

  NJHG: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyynnön tiedot',
    ...val),

  NK9P: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän voimassa olevat laitesopimukset',
    ...val),

  NKFF: (en: string, ...val: string[]) => translate(en,
    'Valitse yritysnumeron aktviivinen tila työajalla, työajan ulkopuolella tai ajastettu. Ajastetulla tilalla voit määrittää työajan voimassaoloajan, jolloin käytetään työajan vastaajia. Muina aikoina käytetään työajan ulkopuolisia vastaajia. Jos haluat käyttää työajan ulkopuolisia vastaajia koko viikonlopulle, aseta ajaksi 00:00 - 00:00.',
    ...val),

  NKJ8: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä tai käyttötarkoitus',
    ...val),

  NKY1: (en: string, ...val: string[]) => translate(en,
    'Kirjoita kysymyksesi tai kuvaile ongelmasi...',
    ...val),

  NLB0: (en: string, ...val: string[]) => translate(en,
    'Tekstiviestien hinta 0,079 €/kpl Pohjolassa ja Baltiassa',
    ...val),

  NLKV: (en: string, ...val: string[]) => translate(en,
    'Sopimus irtisanotaan välittömästi. Tulet saamaan loppulaskun ajalta',
    ...val),

  NLR1: (en: string, ...val: string[]) => translate(en,
    'Paketoimme arkea helpottavat ',
    ...val),

  NMNN: (en: string, ...val: string[]) => translate(en,
    'Oma Laitelaskun tiedot',
    ...val),

  NMQU: (en: string, ...val: string[]) => translate(en,
    'Myyntipalvelu',
    ...val),

  NMRJ: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti järjestelmässämme tapahtui virhe. Yritä hetken kuluttua uudelleen. Mikäli ongelma jatkuu, ota yhteyttä asiakaspalveluumme. Pahoittelemme häiriötä.',
    ...val),

  NMXU: (en: string, ...val: string[]) => translate(en,
    'Tarkista verkkolaskuosoitteen muoto. Oikea muoto on 0037-, TE0037- tai FI-alkuinen. Verkkolaskuosoite voi sisältää kirjaimia A-Z ja numeroita 0-9.',
    ...val),

  NN5S: (en: string, ...val: string[]) => translate(en,
    'Ei saatavilla',
    ...val),

  NNV9: (en: string, ...val: string[]) => translate(en,
    'Sähköpostilasku',
    ...val),

  NO66: (en: string, ...val: string[]) => translate(en,
    'Alanumero',
    ...val),

  NOM4: (en: string, ...val: string[]) => translate(en,
    '2 viikon aikana',
    ...val),

  NP53: (en: string, ...val: string[]) => translate(en,
    'Laitteeni',
    ...val),

  NPKT: (en: string, ...val: string[]) => translate(en,
    'Anna käyttäjätunnuksesi, niin autamme sinua salasanasi palauttamisessa. Haluatko vastaanottaa salasanan sähköpostiin tai matkapuhelimeen?',
    ...val),

  NPSZ: (en: string, ...val: string[]) => translate(en,
    'Avoimet tukipyynnöt:',
    ...val),

  NQ09: (en: string, ...val: string[]) => translate(en,
    'Yritysnumeron työajan mukaan (ajastettu)',
    ...val),

  NQ5G: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että olet valinnut työnantajasi normikäytäntöä kalliimman laitteen josta sinun tulee maksaa ylimenevä osa itse.',
    ...val),

  NQYM: (en: string, ...val: string[]) => translate(en,
    'kuuluvuuskartasta',
    ...val),

  NSLH: (en: string, ...val: string[]) => translate(en,
    'Sisältää vaihtopalvelun',
    ...val),

  NSXY: (en: string, ...val: string[]) => translate(en,
    'Maksuvalinta tehdään puhelinnumeron ja sähköpostiosoitteen syöttämisen jälkeen.',
    ...val),

  NTUU: (en: string, ...val: string[]) => translate(en,
    'Tuotetta ei voida toimittaa postilokeroon. Muuta osoitetta ja yritä uudelleen.',
    ...val),

  NV0F: (en: string, ...val: string[]) => translate(en,
    'Tuote loppunut',
    ...val),

  NVGB: (en: string, ...val: string[]) => translate(en,
    'Ei laskutussopimuksia',
    ...val),

  NVPK: (en: string, ...val: string[]) => translate(en,
    'Muokkaa',
    ...val),

  NVVN: (en: string, ...val: string[]) => translate(en,
    'laitelistan kopiointi ei onnistunut',
    ...val),

  NW12: (en: string, ...val: string[]) => translate(en,
    'Vahvista liittymä ja käyttäjätietojen linkitys uudelle työnantajalle',
    ...val),

  NWKB: (en: string, ...val: string[]) => translate(en,
    'Tallennetaanko seuraavat muutokset?',
    ...val),

  NWSE: (en: string, ...val: string[]) => translate(en,
    'Kirjoita tarpeesi tähän...',
    ...val),

  NX0T: (en: string, ...val: string[]) => translate(en,
    'Kaikki palvelut',
    ...val),

  NXC5: (en: string, ...val: string[]) => translate(en,
    'Sujuvat videopuhelut ja pilvipalvelut',
    ...val),

  NXGI: (en: string, ...val: string[]) => translate(en,
    'Vaihdettava laite',
    ...val),

  NXHY: (en: string, ...val: string[]) => translate(en,
    'Saat vastaukset aina myös sähköpostiisi. ',
    ...val),

  NY9Q: (en: string, ...val: string[]) => translate(en,
    'Suoramaksu + paperilasku',
    ...val),

  NYPL: (en: string, ...val: string[]) => translate(en,
    'Linkin lähettäminen',
    ...val),

  NYU3: (en: string, ...val: string[]) => translate(en,
    'Ota yhteyttä',
    ...val),

  NYY5: (en: string, ...val: string[]) => translate(en,
    'Kerro millaisen liittymän tarvitset',
    ...val),

  NZE5: (en: string, ...val: string[]) => translate(en,
    'Mobiilireitittimen avulla säästät puhelimesi akkua, kun sinun ei tarvitse jakaa nettiä puhelimesta. 5G+ -tekniikan ansiosta mobiilireitittimen akun kulutusta on saatu minimoitua jopa 20 % verrattuna vanhaan 4G/5G -verkossa toimivaan vaihtoehtoon.',
    ...val),

  O0EZ: (en: string, ...val: string[]) => translate(en,
    'Lisätarvike',
    ...val),

  O0QI: (en: string, ...val: string[]) => translate(en,
    'Katuosoitteen lisätieto',
    ...val),

  O108: (en: string, ...val: string[]) => translate(en,
    '{} kk sopimus, kokonaishinta {}, alv. {} %',
    ...val),

  O109: (en: string, ...val: string[]) => translate(en,
    '{} kk sopimus',
    ...val),

  O1AQ: (en: string, ...val: string[]) => translate(en,
    'Luo uusi viesti',
    ...val),

  O1MZ: (en: string, ...val: string[]) => translate(en,
    'Näytettävän numeron muunnos',
    ...val),

  O1RJ: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän yhteystiedot',
    ...val),

  O1Y8: (en: string, ...val: string[]) => translate(en,
    'Tälle laitteelle ei löydy suositeltuja lisätarvikkeita. Koko lisätarvikevalikoiman löydät ',
    ...val),

  O21N: (en: string, ...val: string[]) => translate(en,
    'Olet poistamassa ajastetun muutospyynnön. Tätä toimenpidettä ei ole mahdollista perua.',
    ...val),

  O222: (en: string, ...val: string[]) => translate(en,
    'Uusi mobiililaajakaista',
    ...val),

  O2VG: (en: string, ...val: string[]) => translate(en,
    'Kyllä',
    ...val),

  O3L6: (en: string, ...val: string[]) => translate(en,
    'Sinulla on vain lukuoikeus',
    ...val),

  O3TY: (en: string, ...val: string[]) => translate(en,
    'Laitteen',
    ...val),

  O3X5: (en: string, ...val: string[]) => translate(en,
    'Tiedosto luotu {}, ladattavissa 24h',
    ...val),

  O4D7: (en: string, ...val: string[]) => translate(en,
    'Voit ottaa Mobiilivarmenteen käyttöön yritysliittymällesi omakustanteisesti.',
    ...val),

  O4KU: (en: string, ...val: string[]) => translate(en,
    'Yhteystietojen päivitys epäonnistui',
    ...val),

  O4R1: (en: string, ...val: string[]) => translate(en,
    'Hakusanalla',
    ...val),

  O4R6: (en: string, ...val: string[]) => translate(en,
    'Kiitos tilauksestasi!',
    ...val),

  O5CK: (en: string, ...val: string[]) => translate(en,
    'Sopimushinta, alv. 0 %',
    ...val),

  O5R1: (en: string, ...val: string[]) => translate(en,
    'Vastaanottajan puhelinnumero',
    ...val),

  O6Q1: (en: string, ...val: string[]) => translate(en,
    'Numeron nykyinen omistaja?',
    ...val),

  O6ZK: (en: string, ...val: string[]) => translate(en,
    'Valitse vähintään yksi yritys',
    ...val),

  O7AN: (en: string, ...val: string[]) => translate(en,
    'Kirjautuminen epäonnistui.',
    ...val),

  O7DE: (en: string, ...val: string[]) => translate(en,
    'Toiminto ei ole mahdollinen, sillä palvelulle on tilattu muutos, joka on tällä hetkellä käsittelyssä.',
    ...val),

  O7OC: (en: string, ...val: string[]) => translate(en,
    'Yritykselläsi ei ole aktiivista Vakio-ratkaisua.',
    ...val),

  O7PT: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin muutostilaus kesken, jonka aikana et voi tehdä muita muutoksia liittymälle.',
    ...val),

  O7WR: (en: string, ...val: string[]) => translate(en,
    'Tarkista verkkojen tämänhetkinen toimivuus [verkkoapurista].',
    ...val),

  O83E: (en: string, ...val: string[]) => translate(en,
    'Laskulle tulostuva maksajan nimen lisätieto esim. ostoreskontra',
    ...val),

  O854: (en: string, ...val: string[]) => translate(en,
    'Kun saavut ulkomaille, saat tekstarin kohtuukäytön rajastasi. Saat myös tekstarin, kun datan käyttömääräsi on täyttymässä. Tämän jälkeen maksat tiedonsiirrosta 1,30 €/Gt (gigatavua).',
    ...val),

  O948: (en: string, ...val: string[]) => translate(en,
    'Kotimaan käyttö',
    ...val),

  O9WF: (en: string, ...val: string[]) => translate(en,
    'Työajalla (manuaalinen)',
    ...val),

  OA8P: (en: string, ...val: string[]) => translate(en,
    'Ladataan tilitietoja',
    ...val),

  OAGG: (en: string, ...val: string[]) => translate(en,
    'Syöttämäsi tiedot',
    ...val),

  OB4C: (en: string, ...val: string[]) => translate(en,
    'Olet Elisa Yritystietoturvan pääkäyttäjä. Jos muutat nimeäsi, matkapuhelinnumeroasi tai sähköpostiosoitettasi, myös Elisa Yritystietoturvan kirjautumistunnuksesi muuttuvat.',
    ...val),

  OBHZ: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen nimen lisäosa, auttaa tunnistamaan laskutussopimuksen. Ei tulostu laskulle.',
    ...val),

  OBVW: (en: string, ...val: string[]) => translate(en,
    'Lisenssien hallintaportaaliin pääsy on estetty. Ota yhteyttä asiakaspalveluun tilanteen selvittämiseksi.',
    ...val),

  OD0K: (en: string, ...val: string[]) => translate(en,
    'kertamaksut',
    ...val),

  OD1L: (en: string, ...val: string[]) => translate(en,
    'Alk.',
    ...val),

  OD1M: (en: string, ...val: string[]) => translate(en,
    'Tai alk. {} €/kk',
    ...val),

  OD1N: (en: string, ...val: string[]) => translate(en,
    'Mikä on Laitenetti?',
    ...val),

  OD1O: (en: string, ...val: string[]) => translate(en,
    'Laitenetti on nettiliittymä etäohjattavien laitteiden, kuten valvontakameroiden ja paikantimien hallintaan tai esimerkiksi etäohjattavien pistorasioiden tai lämpömittareiden ohjaukseen.',
    ...val),

  OD1P: (en: string, ...val: string[]) => translate(en,
    'Laitenetti toimii kuten tavallinenkin liittymä, eli sillä voi luoda yhteyden laitteeseen. Laiteliittymän avulla voit seurata ja etäohjata laitteiden toimintaa tai tarkastella kameran lähettämää kuvaa tai videota. Laitenetti on tarkoitettu laitteiden väliseen datayhteyteen.',
    ...val),

  OD1Q: (en: string, ...val: string[]) => translate(en,
    'Mihin laitteisiin Laitenetti-liittymä sopii?',
    ...val),

  OD1R: (en: string, ...val: string[]) => translate(en,
    'Laitenetti-liittymä sopii esimerkiksi hälyttimiin, ilmalämpöpumppuihin, GPS-paikantimiin, olosuhdesensoreihin, hälytyslaitteisiin, valvonta- ja riistakameroihin sekä mobiilireitittimiin ja verkkolaitteisiin.',
    ...val),

  OD1S: (en: string, ...val: string[]) => translate(en,
    'Mikä liittymä sopii valvonta- tai riistakameraan?',
    ...val),

  OD1T: (en: string, ...val: string[]) => translate(en,
    'Riista- ja valvontakameroihin tarvitaan hieman nopeampaa lähetys- ja latausnopeutta, joten suosittelemme Elisa Laitenetti M, Laitenetti L tai Laitenetti 5G -liittymää. Laitenetti L ja 5G sopivat kameraan, joka lähettää live-videota, mutta kuvien lähetykseen riittää Laitenetti M.',
    ...val),

  OD1U: (en: string, ...val: string[]) => translate(en,
    'Mikä liittymä sopii korkealaatuisen live-videon lähetykseen? ',
    ...val),

  OD1V: (en: string, ...val: string[]) => translate(en,
    'Kun tarve on lähettää korkealaatuista live-videota, suosittelemme Laitenetti 5G -liittymää.',
    ...val),

  OD1W: (en: string, ...val: string[]) => translate(en,
    'Miten paikannan laitteeni?',
    ...val),

  OD1X: (en: string, ...val: string[]) => translate(en,
    'Elisa Laitenetti-sovelluksen avulla voit esimerkiksi tarkastella paikantimiesi sijaintia kartalla ja tarkkailla ajoneuvojen nopeustietoja. Voit halutessasi jakaa sijaintitietoja myös muille.',
    ...val),

  OD1Y: (en: string, ...val: string[]) => translate(en,
    'Laitenetti-sovellus toimii yhdessä laiteliittymän ja paikannuslaitteen kanssa, ja voit ladata sovelluksen ilmaiseksi omasta sovelluskaupastasi. Huomioi, että sovellus toimii vain paikantimiin, joissa sinulla on Elisan Laitenetti-liittymä.',
    ...val),

  OD1Z: (en: string, ...val: string[]) => translate(en,
    'Näin otat Elisan Laitenetti-liittymän käyttöösi',
    ...val),

  OD20: (en: string, ...val: string[]) => translate(en,
    'Voit tilata uuden liittymän tältä sivulta tai siirtyä tilaamaan ',
    ...val),

  OD21: (en: string, ...val: string[]) => translate(en,
    'Tilauksen jälkeen toimitamme sinulle postissa uuden sim-kortin, jonka voit asentaa älylaitteeseesi. SIM-kortin toimituksessa kestää keskimäärin 2–4 arkipäivää.',
    ...val),

  OD22: (en: string, ...val: string[]) => translate(en,
    'Laitenettiliittymän avulla voit käyttää ja hallita älylaitteitasi mistä tahansa nettiyhteydellä varustetusta laitteesta, kuten älypuhelimesta tai tietokoneesta. Voit tarkastella ja kerätä dataa netin kautta sekä ohjata ja hallita laitteitasi etäyhteyden avulla. Laitenetti yrityksille -liittymien Access Point Name (APN) on APN internet, Laitenettiin ei voi liittää muita APN:iä',
    ...val),

  OD23: (en: string, ...val: string[]) => translate(en,
    'Katso kaikki IoT-laitteet',
    ...val),

  OD24: (en: string, ...val: string[]) => translate(en,
    'Laitenetin 4G-liittymät yrityksille (alv. 0 %)',
    ...val),

  OD25: (en: string, ...val: string[]) => translate(en,
    'Laitenetin 5G-liittymä yrityksille (alv. 0 %)',
    ...val),

  OD26: (en: string, ...val: string[]) => translate(en,
    'Ei määräaikaisuutta. Voit irtisanoa liittymän milloin vain.',
    ...val),

  OD27: (en: string, ...val: string[]) => translate(en,
    'Laitenetti yrityksille – netti etäohjattaviin älylaitteisiin',
    ...val),

  OD28: (en: string, ...val: string[]) => translate(en,
    'Elisa Laitenetti mahdollistaa tiedonkeruun ja etäohjauksen verkon yli. Sen avulla saat nettiyhteyden yrityksesi etäohjattaviin älylaitteisiin, kuten valvontakameroihin, hälytysjärjestelmään tai lämmityslaitteisiin ja saat kerättyä dataa etänä.',
    ...val),

  OD29: (en: string, ...val: string[]) => translate(en,
    'Haluatko saada videokuvaa valvontakameroista tai ohjata ilmalämpöpumppua etänä?',
    ...val),

  OD30: (en: string, ...val: string[]) => translate(en,
    'Elisa Laitenetti on nopea ja helppo ottaa käyttöön. Valitse sopiva liittymä yrityksellesi ja saat laitenetin nopealla toimituksella!',
    ...val),

  OD31: (en: string, ...val: string[]) => translate(en,
    'Tutustu myös IoT-laitteisiin',
    ...val),

  OD32: (en: string, ...val: string[]) => translate(en,
    'Maksuton Elisa Laitenetti -sovellus paikantaa autosi tai omaisuutesi',
    ...val),

  OD33: (en: string, ...val: string[]) => translate(en,
    'Laitenetti-sovellus toimii yhdessä Elisa Laitenetti -liittymän ja paikannuslaitteen kanssa.',
    ...val),

  OD34: (en: string, ...val: string[]) => translate(en,
    'Tarkastele paikantimiesi sijaintia kartalla – tieto päivittyy reaaliaikaisesti puhelimeesi välimatkasta riippumatta',
    ...val),

  OD35: (en: string, ...val: string[]) => translate(en,
    'Tarkastele sijaintihistoriaa tai esimerkiksi ajoneuvojen nopeustietoja',
    ...val),

  OD36: (en: string, ...val: string[]) => translate(en,
    'Jaa sijaintitiedot halutessasi myös muille',
    ...val),

  OD37: (en: string, ...val: string[]) => translate(en,
    'Valittavissasi on useita erilaisia karttapohjia, esimerkiksi maastokartta',
    ...val),

  OD38: (en: string, ...val: string[]) => translate(en,
    'Kaikki tiedot välitetään turvallisesti Elisalle',
    ...val),

  OD39: (en: string, ...val: string[]) => translate(en,
    'Lataa sovellus ilmaiseksi omasta sovelluskaupastasi!',
    ...val),

  OD40: (en: string, ...val: string[]) => translate(en,
    'Sovellus toimii vain paikantimiin, joissa sinulla on käytössäsi Elisan Laitenetti-liittymä. Alta löydät yhteensopivat paikannuslaitteet.',
    ...val),

  ODY8: (en: string, ...val: string[]) => translate(en,
    'Voit irtisanoa liittymän lähettämällä viestin asiakaspalveluumme. Kerro viestissäsi mitä liittymää irtisanominen koskee, milloin haluat liittymän sulkeutuvan (toistaiseksi voimassaoleva sopimus päättyy viimeistään 1 kuukauden kuluttua irtisanomispyynnöstä). Mikäli käyttö- tai kuukausimaksuja on vielä laskuttamatta, tulet saamaan loppulaskun irtisanotusta liittymästä.',
    ...val),

  OE45: (en: string, ...val: string[]) => translate(en,
    'IMEI/sarjanumero',
    ...val),

  OEAI: (en: string, ...val: string[]) => translate(en,
    'Korttimaksu ei ole mahdollista valituille tuotteille.',
    ...val),

  OEZW: (en: string, ...val: string[]) => translate(en,
    'Tukipyynnöt',
    ...val),

  OF6S: (en: string, ...val: string[]) => translate(en,
    'Tietueet',
    ...val),

  OF9U: (en: string, ...val: string[]) => translate(en,
    'Kk-maksulliset laitteet',
    ...val),

  OFAN: (en: string, ...val: string[]) => translate(en,
    'Voit palata kauppaan ja jatkaa ostoksia.',
    ...val),

  OHZ1: (en: string, ...val: string[]) => translate(en,
    'Palveluni',
    ...val),

  OI7J: (en: string, ...val: string[]) => translate(en,
    'Muistutamme tekstiviestillä, kun Turbovauhtisi on päättymässä.',
    ...val),

  OIG7: (en: string, ...val: string[]) => translate(en,
    'Kopioi laitelista',
    ...val),

  OIUB: (en: string, ...val: string[]) => translate(en,
    ' helposti itse, jos sinulla on yrityksesi nimenkirjoitusoikeus.',
    ...val),

  OIVZ: (en: string, ...val: string[]) => translate(en,
    'Ongelma',
    ...val),

  OJDL: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää ei voida poistaa, koska käyttäjällä on pääkäyttäjäoikeudet Yritysten OmaElisaan. Poista pääkäyttäjäoikeudet ensin',
    ...val),

  OJPZ: (en: string, ...val: string[]) => translate(en,
    'Piilota',
    ...val),

  OK8J: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi liittymä',
    ...val),

  OK8K: (en: string, ...val: string[]) => translate(en,
    'Tilaa liittymä',
    ...val),

  OKCS: (en: string, ...val: string[]) => translate(en,
    'Jos ongelma toistuu, ole yhteydessä asiakaspalveluumme.',
    ...val),

  OKGT: (en: string, ...val: string[]) => translate(en,
    'Uuden liittymätyypin tiedot',
    ...val),

  OKLP: (en: string, ...val: string[]) => translate(en,
    'Muutosmaksut yhteensä',
    ...val),

  OKYY: (en: string, ...val: string[]) => translate(en,
    'Sähköposti',
    ...val),

  OL3F: (en: string, ...val: string[]) => translate(en,
    'Tilalle',
    ...val),

  OL6X: (en: string, ...val: string[]) => translate(en,
    'Ei sisällä roaming ominaisuuksia, toimii vain kotimaassa. Neljä datapakettia valittavissa. Normaalihintaiset kotimaan puhelut 0,0565 €/min, tekstiviestit 0,0565 €/kpl, multimediaviestit 0,156 €/kpl.',
    ...val),

  OL7B: (en: string, ...val: string[]) => translate(en,
    'Verkkolaskuosoite',
    ...val),

  OLM4: (en: string, ...val: string[]) => translate(en,
    'Kehitämme Yritysten OmaElisaa ja tässä vaiheessa näet alla "Kysy laskusta" sekä "Luo uusi" -painikkeiden kautta avaamasi tukipyynnöt.',
    ...val),

  OLP0: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin tyyppi',
    ...val),

  OLVS: (en: string, ...val: string[]) => translate(en,
    'Näytä liittymät',
    ...val),

  OLZS: (en: string, ...val: string[]) => translate(en,
    'Onko kyseessä laitevaihto vai uusi sopimus?',
    ...val),

  OM8N: (en: string, ...val: string[]) => translate(en,
    'Näytä muut vaihtoehdot',
    ...val),

  OMJ2: (en: string, ...val: string[]) => translate(en,
    'Viihde-esto',
    ...val),

  OMMT: (en: string, ...val: string[]) => translate(en,
    'Laskutukseen liittyvät kirjeet',
    ...val),

  OMNZ: (en: string, ...val: string[]) => translate(en,
    'Palautan',
    ...val),

  OMO5: (en: string, ...val: string[]) => translate(en,
    'Hyötyesto P1',
    ...val),

  OMP6: (en: string, ...val: string[]) => translate(en,
    'Irtisanomisilmoitus on vastaanotettu. Saat vielä erillisen irtisanomisvahvistuksen sähköpostiisi.',
    ...val),

  ONM3: (en: string, ...val: string[]) => translate(en,
    '4K-leffojen ja -sarjojen suoratoisto',
    ...val),

  ONRF: (en: string, ...val: string[]) => translate(en,
    'Palaa liittymäsivulle',
    ...val),

  ONSX: (en: string, ...val: string[]) => translate(en,
    'Hylkää',
    ...val),

  OOWX: (en: string, ...val: string[]) => translate(en,
    'Kun päätät Elisa Palvelupäätelaitteen sopimuksen kesken sopimuskauden, Elisa veloittaa laitteesta jäljelle jäävien kuukausimaksujen summan kertaveloituksena, tämän laitteen osalta summa on',
    ...val),

  OP4E: (en: string, ...val: string[]) => translate(en,
    'Sinun osuutesi alk. {}, yhteensä {}',
    ...val),

  OP5R: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, jos laite on vahingoittunut, tuhoutunut tai kadonnut siitä on tehtävä erillinen ilmoitus.',
    ...val),

  OPBC: (en: string, ...val: string[]) => translate(en,
    'Tilaus ei onnistunut',
    ...val),

  OPH9: (en: string, ...val: string[]) => translate(en,
    'Poista muutokset',
    ...val),

  OPI0: (en: string, ...val: string[]) => translate(en,
    'Voit tarvittaessa muuttaa laitteen laskutussopimusta vahvistaessasi muutokset.',
    ...val),

  OPKW: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, johon haluat lisätä uuden laskutussopimuksen.',
    ...val),

  OQ8K: (en: string, ...val: string[]) => translate(en,
    'Siirrä käyttäjä toiseen yritykseen',
    ...val),

  OQBT: (en: string, ...val: string[]) => translate(en,
    'Ei tuloksia haulle',
    ...val),

  OQEQ: (en: string, ...val: string[]) => translate(en,
    'Muutospyynnön lähetys epäonnistui. Yritä myöhemmin uudelleen.',
    ...val),

  OQTL: (en: string, ...val: string[]) => translate(en,
    'Vaikuttavatko liittymäni estoluokat palveluun?',
    ...val),

  OQUG: (en: string, ...val: string[]) => translate(en,
    'Siirrytään Työntekijän OmaElisaan',
    ...val),

  ORN1: (en: string, ...val: string[]) => translate(en,
    'Puhelut',
    ...val),

  ORQE: (en: string, ...val: string[]) => translate(en,
    'Kausimaksut yhteensä, alv. 0 %',
    ...val),

  ORRM: (en: string, ...val: string[]) => translate(en,
    'Muutoksen lähetys epäonnistui',
    ...val),

  ORYE: (en: string, ...val: string[]) => translate(en,
    'Palaa etusivulle',
    ...val),

  OS99: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen luonti onnistui.',
    ...val),

  OT0Z: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaistan vaihtoehdot',
    ...val),

  OT3E: (en: string, ...val: string[]) => translate(en,
    'Tilaamasi tuotteet',
    ...val),

  OTF5: (en: string, ...val: string[]) => translate(en,
    'Ongelmia yhteydessä?',
    ...val),

  OTG1: (en: string, ...val: string[]) => translate(en,
    'Liittymässäsi on määräaikainen sopimus, joten liittymän päivitys ei vielä onnistu OmaElisassa.',
    ...val),

  OTIM: (en: string, ...val: string[]) => translate(en,
    'Maksettu yhteensä (sis. alv)',
    ...val),

  OTPK: (en: string, ...val: string[]) => translate(en,
    'Osoitteeseen ei löytynyt saatavuutta, voit tehdä tarkemman saatavuusselvityksen erillisellä saatavuuskyselyllä OmaElisa Classicin kautta.',
    ...val),

  OTQ4: (en: string, ...val: string[]) => translate(en,
    'Valitse laitelista',
    ...val),

  OTX5: (en: string, ...val: string[]) => translate(en,
    'Anna salasana uudelleen',
    ...val),

  OTZV: (en: string, ...val: string[]) => translate(en,
    'Jos näyttö on rikki tai ongelmia kosketusnäytössä',
    ...val),

  OU0R: (en: string, ...val: string[]) => translate(en,
    'Tee muutospyyntö',
    ...val),

  OUCH: (en: string, ...val: string[]) => translate(en,
    'Valittu Ring-palvelu',
    ...val),

  OVGH: (en: string, ...val: string[]) => translate(en,
    'Laitteen sarjanumero',
    ...val),

  OVO4: (en: string, ...val: string[]) => translate(en,
    ' asennusosoitteeseesi 5G-kuuluvuuden. Kirjoita osoitteesi kartan hakukenttään ja valitse 5G-saatavuus.',
    ...val),

  OW3C: (en: string, ...val: string[]) => translate(en,
    'Palaa asiakkaan näkymään',
    ...val),

  OW64: (en: string, ...val: string[]) => translate(en,
    'Valitse ainakin yksi tuote.',
    ...val),

  OWF1: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelin',
    ...val),

  OWG1: (en: string, ...val: string[]) => translate(en,
    'Liittymän käyttö ajalta',
    ...val),

  OWUZ: (en: string, ...val: string[]) => translate(en,
    'Koontilasku ilman erittelyä',
    ...val),

  OXI7: (en: string, ...val: string[]) => translate(en,
    'Vahvista laitteen lunastaminen työntekijälle',
    ...val),

  OXWO: (en: string, ...val: string[]) => translate(en,
    'Tuki on avoinna klo 08.00–17.00. Voit saada palvelu suomeksi, ruotsiksi tai englanniksi. Halutessasi puhelintuki voi ottaa etäyhteyden laitteeseesi ratkaistaksesi asian.',
    ...val),

  OZCC: (en: string, ...val: string[]) => translate(en,
    'Mobiililiittymät',
    ...val),

  OZCV: (en: string, ...val: string[]) => translate(en,
    'Sopii sinulle, jos työsi on liikkuvaa, eikä sähköä ole aina helposti saatavilla. Pienikokoinen ja kestävällä akulla varustettu mobiilireititin kulkee vaivattomasti mukana.',
    ...val),

  OZEV: (en: string, ...val: string[]) => translate(en,
    'Avaa chat',
    ...val),

  OZLC: (en: string, ...val: string[]) => translate(en,
    'Palauta vanha laite Elisalle 2 viikon kuluessa. Lähetämme sinulle pussin ja ohjeet palautusta varten. Palauttamattomasta laitteesta veloitetaan maksu.',
    ...val),

  P00G: (en: string, ...val: string[]) => translate(en,
    'Käyttöjärjestelmä',
    ...val),

  P04G: (en: string, ...val: string[]) => translate(en,
    'Määritä kuinka pitkään puhelu hälyttää yksittäisellä vastaajalla.',
    ...val),

  P0AZ: (en: string, ...val: string[]) => translate(en,
    'SIM-kortissa olevat 14 numeroa',
    ...val),

  P0CE: (en: string, ...val: string[]) => translate(en,
    'Viihde-esto P4',
    ...val),

  P0PS: (en: string, ...val: string[]) => translate(en,
    'Kuukausimaksu',
    ...val),

  P111: (en: string, ...val: string[]) => translate(en,
    'Ajanviete-esto P3',
    ...val),

  P1Y9: (en: string, ...val: string[]) => translate(en,
    'Ladataan...',
    ...val),

  P2VO: (en: string, ...val: string[]) => translate(en,
    ' mukaisesti',
    ...val),

  P35B: (en: string, ...val: string[]) => translate(en,
    'Lisää liittymäsi',
    ...val),

  P3C8: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys',
    ...val),

  P3N8: (en: string, ...val: string[]) => translate(en,
    'Hetkinen',
    ...val),

  P3SF: (en: string, ...val: string[]) => translate(en,
    'MESH-laite',
    ...val),

  P4CJ: (en: string, ...val: string[]) => translate(en,
    'Ota uusi yhteys käyttöösi vaivattomasti.',
    ...val),

  P4OO: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän puhelinnumero',
    ...val),

  P4RQ: (en: string, ...val: string[]) => translate(en,
    'Summa',
    ...val),

  P4VL: (en: string, ...val: string[]) => translate(en,
    'rekisteröintisivulla',
    ...val),

  P5F7: (en: string, ...val: string[]) => translate(en,
    'Liittymää ei voi avata OmaElisan kautta.',
    ...val),

  P5H4: (en: string, ...val: string[]) => translate(en,
    'Annettuun osoitteeseen lähetetään vahvistuspyyntö. Käyttäjä hyväksyy numeronsiirron tunnistautumalla verkkopankkitunnuksillaan.',
    ...val),

  P5RQ: (en: string, ...val: string[]) => translate(en,
    'Hyväksy',
    ...val),

  P674: (en: string, ...val: string[]) => translate(en,
    'Liittymä',
    ...val),

  P6A0: (en: string, ...val: string[]) => translate(en,
    'OmaLaskun tietojen hakeminen epäonnistui. Yritä myöhemmin uudestaan.',
    ...val),

  P6BC: (en: string, ...val: string[]) => translate(en,
    'Kuukausimaksut',
    ...val),

  P6NV: (en: string, ...val: string[]) => translate(en,
    'Valitse 5G-yritysliittymä',
    ...val),

  P7JV: (en: string, ...val: string[]) => translate(en,
    'Laitelista tallennettu luonnoksena. Muista julkaista laitelista erikseen.',
    ...val),

  P7MS: (en: string, ...val: string[]) => translate(en,
    'Hienoa, nopeuden päivittäminen onnistuu!',
    ...val),

  P7PN: (en: string, ...val: string[]) => translate(en,
    'on oivallinen valinta vähän soittelevalle.',
    ...val),

  P87N: (en: string, ...val: string[]) => translate(en,
    'Viitenumero',
    ...val),

  P8B2: (en: string, ...val: string[]) => translate(en,
    'Siirrettävä käyttäjä',
    ...val),

  P9D3: (en: string, ...val: string[]) => translate(en,
    'Oletko laskutusyhteyshenkilö',
    ...val),

  P9G3: (en: string, ...val: string[]) => translate(en,
    'Jos haluat hallinnoida omia työliittymä- tai laiteasioitasi, kirjaudu',
    ...val),

  P9LC: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että muutosten aktivoinnissa saattaa kestää useita minuutteja.',
    ...val),

  P9S4: (en: string, ...val: string[]) => translate(en,
    'Järjestä valitut ensimmäiseksi',
    ...val),

  P9Z5: (en: string, ...val: string[]) => translate(en,
    'Irtisanomisilmoitus',
    ...val),

  PA84: (en: string, ...val: string[]) => translate(en,
    'Sisältää 5000 min/kk puhetta kotimaan liittymiin. Yhdeksän datapakettia valittavissa.',
    ...val),

  PA85: (en: string, ...val: string[]) => translate(en,
    '{} min puhetta ja {} tekstaria/kk',
    ...val),

  PAI8: (en: string, ...val: string[]) => translate(en,
    'Palvelun veloitus näkyy tilaajan luottokorttiveloituksissa tai MobilePay-ostoksena.',
    ...val),

  PAOF: (en: string, ...val: string[]) => translate(en,
    'Aktivoi kaksivaiheinen tunnistautuminen',
    ...val),

  PAYI: (en: string, ...val: string[]) => translate(en,
    'Voimassa 31 vrk. Sisältää alv. {} %',
    ...val),

  PB3G: (en: string, ...val: string[]) => translate(en,
    'Liittymän käyttö avoinna olevalta laskutuskaudelta',
    ...val),

  PB6S: (en: string, ...val: string[]) => translate(en,
    'Maksaja',
    ...val),

  PBJ3: (en: string, ...val: string[]) => translate(en,
    'Omat tietoni',
    ...val),

  PC3E: (en: string, ...val: string[]) => translate(en,
    'Tilapäisesti suljettu',
    ...val),

  PCB2: (en: string, ...val: string[]) => translate(en,
    'Henkilö',
    ...val),

  PCNO: (en: string, ...val: string[]) => translate(en,
    'Tiedosto sisältää yhden tai useamman virheellisen sähköpostiosoitteen.',
    ...val),

  PDVP: (en: string, ...val: string[]) => translate(en,
    'Henkilökohtaisessa käytössä',
    ...val),

  PDYE: (en: string, ...val: string[]) => translate(en,
    'Liitä liittymäsi ',
    ...val),

  PE9O: (en: string, ...val: string[]) => translate(en,
    'Laskutusväli',
    ...val),

  PEKP: (en: string, ...val: string[]) => translate(en,
    'Peruutettu',
    ...val),

  PETH: (en: string, ...val: string[]) => translate(en,
    'Jos sinulla on jo verkkotunnus, kirjoita se tähän',
    ...val),

  PFDS: (en: string, ...val: string[]) => translate(en,
    'Toimituskulut',
    ...val),

  PFI5: (en: string, ...val: string[]) => translate(en,
    'Ring pääkäyttäjät',
    ...val),

  PFQL: (en: string, ...val: string[]) => translate(en,
    'Hei! Olen Elisan tekoälyharjoittelija ja autan sinua löytämään vastauksia kysymyksiisi.',
    ...val),

  PFSQ: (en: string, ...val: string[]) => translate(en,
    'Pakettiin kuuluva data',
    ...val),

  PGK9: (en: string, ...val: string[]) => translate(en,
    'Tämän tietuetyypin muokkaus ei ole mahdollista palvelussamme',
    ...val),

  PGL0: (en: string, ...val: string[]) => translate(en,
    'Huomioithan että voimassaoleva maksusopimus estää lisämyynnin.',
    ...val),

  PGZ4: (en: string, ...val: string[]) => translate(en,
    'Liittymälläsi on Elisa Ring -palvelu, joka estää palvelun käyttöönoton.',
    ...val),

  PIGQ: (en: string, ...val: string[]) => translate(en,
    'päivän',
    ...val),

  PIZC: (en: string, ...val: string[]) => translate(en,
    'SIM-kortti',
    ...val),

  PJC3: (en: string, ...val: string[]) => translate(en,
    'Tietoa ei saatavilla...',
    ...val),

  PJIU: (en: string, ...val: string[]) => translate(en,
    'Mitä vanhalle laitteelle tehdään?',
    ...val),

  PJQL: (en: string, ...val: string[]) => translate(en,
    'Vanhentunut',
    ...val),

  PK4G: (en: string, ...val: string[]) => translate(en,
    'Jatka sivuston selailua',
    ...val),

  PKES: (en: string, ...val: string[]) => translate(en,
    'Puhelut hyötypalvelunumeroihin esim. taksin tilaus ja pankkipalvelut',
    ...val),

  PKEZ: (en: string, ...val: string[]) => translate(en,
    'Mobiilivarmenne',
    ...val),

  PKFX: (en: string, ...val: string[]) => translate(en,
    'EPP-sopimushinta varmistuu kun kirjaudut sisään',
    ...val),

  PKML: (en: string, ...val: string[]) => translate(en,
    'Kiinteän verkon Netti Lite -liittymät',
    ...val),

  PLAW: (en: string, ...val: string[]) => translate(en,
    'Palaa Kauppaan',
    ...val),

  PLAX: (en: string, ...val: string[]) => translate(en,
    'Palaa Kassalle',
    ...val),

  PLPS: (en: string, ...val: string[]) => translate(en,
    'Loppuerän viimeiset kappaleet, toimi nopeasti',
    ...val),

  PLQC: (en: string, ...val: string[]) => translate(en,
    'Matkahuolto Jakopaketti',
    ...val),

  PLW9: (en: string, ...val: string[]) => translate(en,
    'Laitetilaukseni',
    ...val),

  PM1H: (en: string, ...val: string[]) => translate(en,
    'Viimeksi muokattu',
    ...val),

  PM7G: (en: string, ...val: string[]) => translate(en,
    'Tuotteen',
    ...val),

  PMFC: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi',
    ...val),

  PN10: (en: string, ...val: string[]) => translate(en,
    'Kertamaksulliset ja kuukausimaksulliset laitteet ja lisätarvikkeet',
    ...val),

  PNS1: (en: string, ...val: string[]) => translate(en,
    'Nimi',
    ...val),

  PO7A: (en: string, ...val: string[]) => translate(en,
    'Vahinkotapaus (laitteelle käynyt vahinko, näyttö rikki, runko vääntynyt, kastunut tms.)',
    ...val),

  PODN: (en: string, ...val: string[]) => translate(en,
    'DNS-tietueen lisääminen epäonnistui!',
    ...val),

  POX2: (en: string, ...val: string[]) => translate(en,
    'Tilausten hyväksyjä',
    ...val),

  PPHJ: (en: string, ...val: string[]) => translate(en,
    'Liittymän',
    ...val),

  PQGG: (en: string, ...val: string[]) => translate(en,
    'Toimitettu',
    ...val),

  PR0A: (en: string, ...val: string[]) => translate(en,
    'Tarkista käyttäjän mukana siirtyvien sopimusten tiedot. Valitse uusi laskutussopimus uudesta yrityksestä ja määritä tarvittaessa tuotteille uudet kustannuspaikat.',
    ...val),

  PR0R: (en: string, ...val: string[]) => translate(en,
    'Raportti sisältää Elisa Palvelupäätelaitteet -palvelun matkapuhelimet, tabletit ja tietokoneet.',
    ...val),

  PRFW: (en: string, ...val: string[]) => translate(en,
    'Ostoskori on tyhjä',
    ...val),

  PRH3: (en: string, ...val: string[]) => translate(en,
    'Laitesopimuksen hylkääminen',
    ...val),

  PRK5: (en: string, ...val: string[]) => translate(en,
    'Raportti sisältää Elisa Palvelupäätelaite -palvelun palautetut ja palautusta odottavat laitteet, huoltotapahtumat, laitevaihdot, lunastetut laitteet ja palautusrikemaksutapahtumat.',
    ...val),

  PRKE: (en: string, ...val: string[]) => translate(en,
    'Lue lisää Reissunetti palvelusta.',
    ...val),

  PRLT: (en: string, ...val: string[]) => translate(en,
    'Työaikaketjuun vaaditaan ensimmäinen kohdenumero kun työaikaketju on valittuna',
    ...val),

  PRM4: (en: string, ...val: string[]) => translate(en,
    'kuuluvuusalueesi',
    ...val),

  PS1E: (en: string, ...val: string[]) => translate(en,
    'Korissa on {} tuotetta',
    ...val),

  PS5T: (en: string, ...val: string[]) => translate(en,
    'Laskutusosoite',
    ...val),

  PS8D: (en: string, ...val: string[]) => translate(en,
    'Tietojen päivitys onnistui.',
    ...val),

  PSY1: (en: string, ...val: string[]) => translate(en,
    'Lisenssien hallintaportaalin käyttö vaatii kaksivaiheisen tunnistautumisen. Saat aktivoitua kaksivaiheisen tunnistautumisen käyttöön Yrityksen tiedot ja asetukset -sivulla',
    ...val),

  PTGG: (en: string, ...val: string[]) => translate(en,
    'Ilmoita laskutussopimus',
    ...val),

  PUHZ: (en: string, ...val: string[]) => translate(en,
    'Valitse malli',
    ...val),

  PUQA: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän sähköpostiosoite',
    ...val),

  PV8D: (en: string, ...val: string[]) => translate(en,
    'Puhe',
    ...val),

  PV9I: (en: string, ...val: string[]) => translate(en,
    'Lisäämällä käyttäjätiedon helpotat tuotteiden ja palveluiden hallintaa OmaElisan Käyttäjät-näkymässä. Valitse käyttötarkoitus vain silloin, kun kyseessä on esimerkiksi ryhmä käyttäjiä.',
    ...val),

  PVHD: (en: string, ...val: string[]) => translate(en,
    'Jatkokausi',
    ...val),

  PVYK: (en: string, ...val: string[]) => translate(en,
    'Jos matkapuhelinnumero luovutetaan työntekijälle, ',
    ...val),

  PW2I: (en: string, ...val: string[]) => translate(en,
    'Varmistithan, että tilaus- ja laskutustiedot ovat oikein',
    ...val),

  PWLF: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä ei ole puhelinliittymiä.',
    ...val),

  PWYT: (en: string, ...val: string[]) => translate(en,
    'Haluatko varmasti poistaa käyttäjän?',
    ...val),

  PX2Z: (en: string, ...val: string[]) => translate(en,
    'Henkilöasiakkaan OmaElisa',
    ...val),

  PY9Q: (en: string, ...val: string[]) => translate(en,
    'Kysyttävää laskutukseen tai maksamiseen liittyvistä asioista?',
    ...val),

  PZOF: (en: string, ...val: string[]) => translate(en,
    'Mt',
    ...val),

  PZV7: (en: string, ...val: string[]) => translate(en,
    'Rajaton netti kotimaassa, Pohjolassa ja Baltiassa, muualla EU:ssa {} Gt/kk.',
    ...val),

  Q01I: (en: string, ...val: string[]) => translate(en,
    'Jokainen käytetty paketti näkyy laskulla omalla rivillään',
    ...val),

  Q03S: (en: string, ...val: string[]) => translate(en,
    'Laiterekisteröintiohjelma',
    ...val),

  Q0O6: (en: string, ...val: string[]) => translate(en,
    'Lisää tiedosto raahaamalla tai',
    ...val),

  Q1DM: (en: string, ...val: string[]) => translate(en,
    'Muutosaika',
    ...val),

  Q1I0: (en: string, ...val: string[]) => translate(en,
    'Jos sinulla on Elisan yritysliittymä, voit esimerkiksi:',
    ...val),

  Q27O: (en: string, ...val: string[]) => translate(en,
    'Liittymän päivittäminen hyllyliittymäksi',
    ...val),

  Q2NF: (en: string, ...val: string[]) => translate(en,
    'jos sinulla on yrityksesi nimenkirjoitusoikeus.',
    ...val),

  Q2QT: (en: string, ...val: string[]) => translate(en,
    'Salli tuotteiden osatoimitus',
    ...val),

  Q4GQ: (en: string, ...val: string[]) => translate(en,
    'Työnantajasi maksaa seuraavat palvelut:',
    ...val),

  Q4V8: (en: string, ...val: string[]) => translate(en,
    'Maksettavaa jäljellä',
    ...val),

  Q53K: (en: string, ...val: string[]) => translate(en,
    'Tilaa KelloSIM-lisäpalvelu',
    ...val),

  Q57Q: (en: string, ...val: string[]) => translate(en,
    'Voit asettaa työntekijälle "kassalla" pakotetusti näytettävän popUp -viestin. Viestin otsikkona on "Huomioitavaa työnantajasi pääkäyttäjältä", jota ei voi muuttaa. Voit asettaa viestin päälle ja pois katalogikohtaisesti.',
    ...val),

  Q5UU: (en: string, ...val: string[]) => translate(en,
    '{} MESH-laitetta',
    ...val),

  Q5XU: (en: string, ...val: string[]) => translate(en,
    'Olen lukenut ja hyväksyn',
    ...val),

  Q61D: (en: string, ...val: string[]) => translate(en,
    'Tukipyynnön lähetys onnistui. Päivitä sivu nähdäksesi uusimmat tukipyynnöt.',
    ...val),

  Q6TC: (en: string, ...val: string[]) => translate(en,
    'Haluttu verkkotunnus. Esim. kotisivut.fi',
    ...val),

  Q6TK: (en: string, ...val: string[]) => translate(en,
    'Turvallisuussyistä poistamme yli 12 kk käyttämättä olleet OmaElisa-tunnukset automaattisesti. Jos et ole käyttänyt tunnuksiasi pitkään aikaan, sinun on luotava uudet tunnukset',
    ...val),

  Q7MZ: (en: string, ...val: string[]) => translate(en,
    'Voimassa 31 vrk',
    ...val),

  Q8ZW: (en: string, ...val: string[]) => translate(en,
    'Sisältään akullisen liikuteltavan mobiilireitittimen markkinoiden parhaalla akunkestolla.',
    ...val),

  Q9BI: (en: string, ...val: string[]) => translate(en,
    'Elisa Reissunetti 1-1,5 Gt on yritysasiakkaille tarkoitettu mobiililiittymän lisäpalvelu. Palvelu aktivoituu automaattisesti kohdemaissa ja mahdollistaa mobiilipäätelaitteella tai kannettavalla tietokoneella käytettävän nopean tiedonsiirtoyhteyden edulliseen hintaan EU/ETA-alueen ulkopuolisissa maissa. Palvelussa ei ole kuukausimaksua, vaan hinnoittelu aktivoituu käytön mukaan 21,90 € / 14 vrk / 1-1,5 Gt paketti.',
    ...val),

  Q9D2: (en: string, ...val: string[]) => translate(en,
    'Sopijayritys ja laskutussopimus',
    ...val),

  Q9G5: (en: string, ...val: string[]) => translate(en,
    'Puhelintuki tietoturvaongelmissa',
    ...val),

  Q9YM: (en: string, ...val: string[]) => translate(en,
    'Yritä tunnistautua uudelleen.',
    ...val),

  QA66: (en: string, ...val: string[]) => translate(en,
    'Elisa Reissaaja on yritysasiakkaille tarkoitettu mobiililiittymän lisäpalvelu. Palvelu aktivoituu automaattisesti kohdemaissa ja mahdollistaa mobiilipäätelaitteella tai kannettavalla tietokoneella käytettävän nopean tiedonsiirtoyhteyden edulliseen hintaan EU/ETA-alueen ulkopuolisissa maissa. Palvelussa ei ole kuukausimaksua, vaan hinnoittelu aktivoituu käytön mukaan 10,90 € / 1 vrk / 500Mt paketti.',
    ...val),

  QAKO: (en: string, ...val: string[]) => translate(en,
    'Muutosmaksut lisätään seuraavalle laskulle',
    ...val),

  QAMU: (en: string, ...val: string[]) => translate(en,
    'Elisan Mobiiliturva yrityksille on uudenlainen tietoturvapalvelu mobiililiittymään. Palvelu tunnistaa haitalliset sivustot ja tuo digiturvaa käyttäessäsi mobiililiittymää.',
    ...val),

  QAMW: (en: string, ...val: string[]) => translate(en,
    ' on uudenlainen tietoturvapalvelu, joka parantaa mobiililiittymän selainturvallisuutta. Palvelu tunnistaa haitalliset sivustot automaattisesti ja ilmoittaa niistä käyttäjälle.',
    ...val),

  QCAF: (en: string, ...val: string[]) => translate(en,
    'Tekstiviestipaketti',
    ...val),

  QD79: (en: string, ...val: string[]) => translate(en,
    'Lähetämme ohjeen sähköpostiisi salasanan palauttamiseksi',
    ...val),

  QDTC: (en: string, ...val: string[]) => translate(en,
    'Tarkista sivun osoite.',
    ...val),

  QDU2: (en: string, ...val: string[]) => translate(en,
    'Muutos astuu voimaan noin vuorokauden kuluessa. Saat sähköpostiisi ilmoituksen muutetuista sopimuksista.',
    ...val),

  QE2O: (en: string, ...val: string[]) => translate(en,
    'henkilöasiakkaan OmaElisassa',
    ...val),

  QENF: (en: string, ...val: string[]) => translate(en,
    'Tiedostomuoto ei kelpaa. Tallenna tiedosto joko .csv tai .txt -muotoisena.',
    ...val),

  QF2O: (en: string, ...val: string[]) => translate(en,
    'Myyntilomakkeet',
    ...val),

  QF5T: (en: string, ...val: string[]) => translate(en,
    'Tilauksen yhteenveto',
    ...val),

  QFXU: (en: string, ...val: string[]) => translate(en,
    'Ei tilauksia',
    ...val),

  QG2G: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisaan, jolloin voimme antaa sinulle henkilökohtaisempaa palvelua.',
    ...val),

  QG50: (en: string, ...val: string[]) => translate(en,
    'Kaikki laitteet',
    ...val),

  QGCA: (en: string, ...val: string[]) => translate(en,
    'Tiliote',
    ...val),

  QGQK: (en: string, ...val: string[]) => translate(en,
    'Applen iPhone/iPad-laitteista tulee poistaa Find my iPhone-toiminto käytöstä',
    ...val),

  QGS0: (en: string, ...val: string[]) => translate(en,
    'Kaksivaiheinen tunnistautuminen parantaa yrityksesi tietoturvaa. Käytännössä se tarkoittaa, että tunnuksen ja salasanan lisäksi tarvitset kirjautumiseen tekstiviestillä saapuvan kertakäyttöisen tunnistautumiskoodin. Kaksivaiheinen tunnistautuminen vaaditaan jokaiselta Yritysten OmaElisa -pääkäyttäjältä. Muutos koskee myös OmaElisa Classicia.',
    ...val),

  QH8P: (en: string, ...val: string[]) => translate(en,
    'Liittymätyyppi',
    ...val),

  QHG2: (en: string, ...val: string[]) => translate(en,
    'Olen tarkistanut, että kaikkien pääkäyttäjien käyttäjätunnuksen tiedot ovat oikein ja ajan tasalla.',
    ...val),

  QHJ4: (en: string, ...val: string[]) => translate(en,
    'Kuukausimaksullisten tuotteiden maksutapana on aina lasku. Korttimaksu on mahdollinen vain kertamaksullisille laitteille ja lisävarusteille.',
    ...val),

  QI2P: (en: string, ...val: string[]) => translate(en,
    'Asetukset',
    ...val),

  QI6G: (en: string, ...val: string[]) => translate(en,
    'Yritysnumero',
    ...val),

  QIHT: (en: string, ...val: string[]) => translate(en,
    'Sisältää 5000 kpl viestiä Pohjolassa ja Baltiassa, ylimenevät viestit 0,079 €/kpl',
    ...val),

  QIK1: (en: string, ...val: string[]) => translate(en,
    'Tunnus',
    ...val),

  QIKB: (en: string, ...val: string[]) => translate(en,
    'Syötä puhelinnumerosi',
    ...val),

  QJG7: (en: string, ...val: string[]) => translate(en,
    'Laitelistan tuotevalikoiman kopiointi epäonnistui.',
    ...val),

  QJL0: (en: string, ...val: string[]) => translate(en,
    'Siirry Asennus- ja hallintapalveluun',
    ...val),

  QJUW: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka',
    ...val),

  QK7P: (en: string, ...val: string[]) => translate(en,
    'Alennuskoodi "{}" käytössä.',
    ...val),

  QKAC: (en: string, ...val: string[]) => translate(en,
    'Sis. 10 Gt datan käyttöä kotimaassa. Paketin ylimenevä datankäyttö 6,50 € / alkava 10 Gt.',
    ...val),

  QKRX: (en: string, ...val: string[]) => translate(en,
    'Nopea tiedonsiirto, 8K-leffat ilman katkoksia',
    ...val),

  QL4P: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen avoinna olevien laskujen yhteissumma ja mahdolliset liikasuoritukset.',
    ...val),

  QLJY: (en: string, ...val: string[]) => translate(en,
    'Puheluestot',
    ...val),

  QMMA: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi SIM',
    ...val),

  QMNH: (en: string, ...val: string[]) => translate(en,
    ' Seuraa yrityksesi liittymien käyttöä, muokkaa estoluokkia ja hallinnoi liittymiesi lisäpalveluja.',
    ...val),

  QNC2: (en: string, ...val: string[]) => translate(en,
    ' Näytä julkaistu versio',
    ...val),

  QNUL: (en: string, ...val: string[]) => translate(en,
    'Rungossa naarmuja tai kulumaa',
    ...val),

  QNVB: (en: string, ...val: string[]) => translate(en,
    'Tämä ei ole Elisan yritysasiakkaan omistama matkapuhelinnumero.',
    ...val),

  QOTV: (en: string, ...val: string[]) => translate(en,
    'Lähetimme numeroon {} vahvistuskoodin tekstiviestillä.',
    ...val),

  QOV2: (en: string, ...val: string[]) => translate(en,
    'Palvelun voi maksaa luottokortilla tai MobilePayllä suoraan verkkosivuilla tilauksen yhteydessä. Luottokortin tulee olla suomalainen luotto- tai debit -kortti. MobilePayn käyttäminen edellyttää palveluun rekisteröintiä suomalaisilla verkkopankkitunnuksilla.',
    ...val),

  QOWZ: (en: string, ...val: string[]) => translate(en,
    'Liittymän päivittäminen hyllyliittymäksi ei onnistu',
    ...val),

  QOZF: (en: string, ...val: string[]) => translate(en,
    ' muu tuote',
    ...val),

  QP30: (en: string, ...val: string[]) => translate(en,
    'Jos laskun viitenumero on 80-alkuinen, kyseessä on vakioviitteellinen lasku. Maksusuoritukset kohdistuvat aina vanhimpaan avoimeen laskuun.',
    ...val),

  QPHB: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän {} Ring-palvelu säilytetään. Jotta Ring-liittymää voi käyttää ilman matkapuhelinta, on silloin otettava lisäpalveluksi ohjelmisto- tai IP-puhelin.',
    ...val),

  QPYC: (en: string, ...val: string[]) => translate(en,
    'Huomioithan että seuraavat lisäpalvelut ovat epäyhteensopivia Elisa Ring -palvelun kanssa ja ne suljetaan automaattisesti. Ring -palveluun sisältyy korvaava lisäpalvelu.',
    ...val),

  QQ80: (en: string, ...val: string[]) => translate(en,
    'Liittymään sisältyy',
    ...val),

  QQFY: (en: string, ...val: string[]) => translate(en,
    'Kaksivaiheinen tunnistautuminen',
    ...val),

  QQGQ: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin tilaus',
    ...val),

  QQGR: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin vaihto ja tilaus',
    ...val),

  QQJX: (en: string, ...val: string[]) => translate(en,
    'Liitä Elisa KelloSIM liittymääsi kirjautumalla Työntekijöiden OmaElisaan',
    ...val),

  QQSJ: (en: string, ...val: string[]) => translate(en,
    'Lisää uusi käyttäjä',
    ...val),

  QRH4: (en: string, ...val: string[]) => translate(en,
    'Esto ei käytössä',
    ...val),

  QRON: (en: string, ...val: string[]) => translate(en,
    'Rekisteröidy yritysasiakkaaksi',
    ...val),

  QRYV: (en: string, ...val: string[]) => translate(en,
    'Valitse',
    ...val),

  QRYW: (en: string, ...val: string[]) => translate(en,
    'Valittu',
    ...val),

  QSG9: (en: string, ...val: string[]) => translate(en,
    'Kaikille kodin netinkäyttäjille ja laitteille',
    ...val),

  QSXP: (en: string, ...val: string[]) => translate(en,
    'Vahinkopalvelu',
    ...val),

  QTLF: (en: string, ...val: string[]) => translate(en,
    'Palvelun hinta 4 €/kk yritysnumeroille (010-, 020- jne.) ja muille numeroille 6,90 €/kk.',
    ...val),

  QTYF: (en: string, ...val: string[]) => translate(en,
    'Numeron julkisuus vaihtoehdot',
    ...val),

  QUDL: (en: string, ...val: string[]) => translate(en,
    'Tilaus onnistui',
    ...val),

  QULB: (en: string, ...val: string[]) => translate(en,
    'Palaa tuotesivulle',
    ...val),

  QUPI: (en: string, ...val: string[]) => translate(en,
    'Tuotteen toimitus',
    ...val),

  QUWJ: (en: string, ...val: string[]) => translate(en,
    'Tilausvahvistus on lähetetty sähköpostilla.',
    ...val),

  QV5K: (en: string, ...val: string[]) => translate(en,
    'Avaa liittymä',
    ...val),

  QVCE: (en: string, ...val: string[]) => translate(en,
    'maksettuja',
    ...val),

  QVYK: (en: string, ...val: string[]) => translate(en,
    'Vahvista',
    ...val),

  QWC8: (en: string, ...val: string[]) => translate(en,
    'Tuntematon',
    ...val),

  QXQ7: (en: string, ...val: string[]) => translate(en,
    'Kiinteä laajakaista',
    ...val),

  QXUN: (en: string, ...val: string[]) => translate(en,
    'Sis. {} Gt datan käyttöä Pohjola & Baltia sekä muun EU:n alueella. Paketin ylimenevä datankäyttö 4,50 € / alkava 5 Gt.',
    ...val),

  QYMV: (en: string, ...val: string[]) => translate(en,
    'Jatkokausi',
    ...val),

  QYSL: (en: string, ...val: string[]) => translate(en,
    'Kertamaksullinen',
    ...val),

  QYXA: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi SIM-kortti',
    ...val),

  QZ2C: (en: string, ...val: string[]) => translate(en,
    'Sunnuntai',
    ...val),

  QZM5: (en: string, ...val: string[]) => translate(en,
    'Nykyisessä liittymässäsi on määräaikainen sopimus vielä kesken, minkä vuoksi liittymän päivitys onnistuu vain myyntimme kautta. Soita numeroon 0800 04411 (ma-pe 8-16), niin katsotaan yhdessä liittymäasiasi kuntoon!',
    ...val),

  QZVD: (en: string, ...val: string[]) => translate(en,
    'Varattu numeroavaruus',
    ...val),

  QZZR: (en: string, ...val: string[]) => translate(en,
    'Jos teit juuri tilauksen, niin ei huolta. Osa tilauksista tulee näkyviin viiveellä.',
    ...val),

  R0BH: (en: string, ...val: string[]) => translate(en,
    'Viikko',
    ...val),

  R0EY: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää ei voida siirtää toiseen yritykseen, koska:',
    ...val),

  R0LP: (en: string, ...val: string[]) => translate(en,
    'Aktivointikoodi',
    ...val),

  R0OK: (en: string, ...val: string[]) => translate(en,
    'Maksullinen data',
    ...val),

  R13J: (en: string, ...val: string[]) => translate(en,
    'Jatkamme asian käsittelyä mahdollisimman pian.',
    ...val),

  R1D7: (en: string, ...val: string[]) => translate(en,
    'Katso 4K-leffoja ja -sarjoja',
    ...val),

  R1PB: (en: string, ...val: string[]) => translate(en,
    'Englanti',
    ...val),

  R28M: (en: string, ...val: string[]) => translate(en,
    'Liittymän avaaminen ei onnistu',
    ...val),

  R28N: (en: string, ...val: string[]) => translate(en,
    'Laajakaistakorjaimen käynnistäminen epäonnistui',
    ...val),

  R2DK: (en: string, ...val: string[]) => translate(en,
    'Ei toimitettavaa.',
    ...val),

  R2MM: (en: string, ...val: string[]) => translate(en,
    'Vaihda salasana',
    ...val),

  R2NG: (en: string, ...val: string[]) => translate(en,
    'Lue lisää {} palvelusta',
    ...val),

  R37K: (en: string, ...val: string[]) => translate(en,
    'Palvelun tilaaminen onnistui',
    ...val),

  R39E: (en: string, ...val: string[]) => translate(en,
    'Yksikköhinta',
    ...val),

  R3RM: (en: string, ...val: string[]) => translate(en,
    'Anna domain muodossa kotisivut.fi',
    ...val),

  R3VE: (en: string, ...val: string[]) => translate(en,
    'Poista',
    ...val),

  R488: (en: string, ...val: string[]) => translate(en,
    'Kaapeli',
    ...val),

  R4EV: (en: string, ...val: string[]) => translate(en,
    'Tuotteiden tiedot',
    ...val),

  R4O4: (en: string, ...val: string[]) => translate(en,
    'Latausnopeus',
    ...val),

  R51P: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjä',
    ...val),

  R58C: (en: string, ...val: string[]) => translate(en,
    'Maksun palautus',
    ...val),

  R69E: (en: string, ...val: string[]) => translate(en,
    'Vahvistus',
    ...val),

  R6AR: (en: string, ...val: string[]) => translate(en,
    'Puhelinliittymä',
    ...val),

  R6GH: (en: string, ...val: string[]) => translate(en,
    'Valittu Ring-palvelu',
    ...val),

  R6HP: (en: string, ...val: string[]) => translate(en,
    'Tuotetyyppi',
    ...val),

  R7VE: (en: string, ...val: string[]) => translate(en,
    'Asennus',
    ...val),

  R805: (en: string, ...val: string[]) => translate(en,
    'Käyttö ulkomailla',
    ...val),

  R89M: (en: string, ...val: string[]) => translate(en,
    'Sinulle on lähetetty vahvistusviesti tilauksen vastaanottamisesta',
    ...val),

  R8HE: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti tilausta ei voida käsitellä luottopäätöksen vuoksi. Tiesithän, että kertamaksullisia laitteita ja lisävarusteita voit maksaa korttimaksulla.',
    ...val),

  R8IE: (en: string, ...val: string[]) => translate(en,
    'Poistetaan',
    ...val),

  R8ND: (en: string, ...val: string[]) => translate(en,
    'Datan käyttö (3G 21M) 0,20€/Mt',
    ...val),

  R9HM: (en: string, ...val: string[]) => translate(en,
    'Lomavastaajan avulla voit erotella saapuvat työ- ja yksityispuhelut toisistaan ja varmistaa, että saapuva puhelu tavoittaa haluamasi henkilön poissaolon aikana.',
    ...val),

  RA9V: (en: string, ...val: string[]) => translate(en,
    'Maksuaika {} kk',
    ...val),

  RAO4: (en: string, ...val: string[]) => translate(en,
    'Suoratoimituslaite. Emme voi taata toimitusta seuraavaksi arkipäiväksi.',
    ...val),

  RAQ0: (en: string, ...val: string[]) => translate(en,
    'Löysimme käyttäjän, jolla on samoja yhteystietoja',
    ...val),

  RAS9: (en: string, ...val: string[]) => translate(en,
    '-välilehdeltä',
    ...val),

  RBCF: (en: string, ...val: string[]) => translate(en,
    'Siirrä suuria tiedostoja hetkessä',
    ...val),

  RCF3: (en: string, ...val: string[]) => translate(en,
    'Vahvista eSIM-tilaus',
    ...val),

  RCIG: (en: string, ...val: string[]) => translate(en,
    'Mikäli laitevaihtoa ei tehdä tai sopimusta päätetä jatkokauden aikana, laite siirtyy automaattisesti yrityksen omaisuudeksi.',
    ...val),

  RCPW: (en: string, ...val: string[]) => translate(en,
    'Ladataan laskutustilejä',
    ...val),

  RCVR: (en: string, ...val: string[]) => translate(en,
    'avulla',
    ...val),

  RD4R: (en: string, ...val: string[]) => translate(en,
    'Ohjeet',
    ...val),

  RD75: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoite tai osoitteet',
    ...val),

  RDAU: (en: string, ...val: string[]) => translate(en,
    'Elisan 5G+ ja 5G-yritysliittymillä kaikki vaan toimii paremmin. Tehokkaat ja huippunopeat puhelinliittymät yrityskäyttöön varmistavat sujuvan työnteon. Nopea 5G-verkko takaa toimintavarman yhteyden ja tuo vauhtia yrityksesi arkeen. ',
    ...val),

  RDSM: (en: string, ...val: string[]) => translate(en,
    'Poissaoloaikana',
    ...val),

  RDZA: (en: string, ...val: string[]) => translate(en,
    'Hyväksy lunastuspyyntö',
    ...val),

  RE0N: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaite -palvelun matkapuhelimet ja tietokoneet',
    ...val),

  RE1O: (en: string, ...val: string[]) => translate(en,
    'Jopa 20 % parempi mobiililaitteen akun kesto.',
    ...val),

  REA8: (en: string, ...val: string[]) => translate(en,
    'Sopimusnumero',
    ...val),

  REM3: (en: string, ...val: string[]) => translate(en,
    'Kirjoita vähintään 3 merkkiä. Jatka kirjoittamista jos yrityksesi ei näy heti hakutuloksissa. Esim. Yritys Oy 1234567-8. ',
    ...val),

  RF14: (en: string, ...val: string[]) => translate(en,
    'Liittymän toivottu asennuspäivä',
    ...val),

  RFH9: (en: string, ...val: string[]) => translate(en,
    'Yksi SIM-kortti voidaan kytkeä vain yhteen liittymään.',
    ...val),

  RFSE: (en: string, ...val: string[]) => translate(en,
    'Jätä viesti myynnille',
    ...val),

  RG74: (en: string, ...val: string[]) => translate(en,
    'Yhteyshenkilön luonti epäonnistui.',
    ...val),

  RH6T: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen nimi',
    ...val),

  RHLM: (en: string, ...val: string[]) => translate(en,
    'liittymän käyttö ulkomailla',
    ...val),

  RHLU: (en: string, ...val: string[]) => translate(en,
    'Liittymän Ring-asetukset',
    ...val),

  RI4Z: (en: string, ...val: string[]) => translate(en,
    'Salasanan on oltava vähintään 8 merkkiä pitkä.',
    ...val),

  RJ27: (en: string, ...val: string[]) => translate(en,
    'Maksettu',
    ...val),

  RJT5: (en: string, ...val: string[]) => translate(en,
    'Valitse listalta yhteyshenkilö tai luo uusi.',
    ...val),

  RKNP: (en: string, ...val: string[]) => translate(en,
    'Etsitkö henkilöasiakkaan asiointia?',
    ...val),

  RL8F: (en: string, ...val: string[]) => translate(en,
    'Lisää liittymä Vakioon',
    ...val),

  RLGI: (en: string, ...val: string[]) => translate(en,
    'Mahdolliset lisätiedot',
    ...val),

  RM4V: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän siirto toiseen yritykseen onnistui',
    ...val),

  RMXD: (en: string, ...val: string[]) => translate(en,
    'Osta lisäpalveluna',
    ...val),

  RMXE: (en: string, ...val: string[]) => translate(en,
    'Saatavilla OmaElisassa',
    ...val),

  RN1O: (en: string, ...val: string[]) => translate(en,
    'Helpompi arki',
    ...val),

  RN44: (en: string, ...val: string[]) => translate(en,
    'Tukipyynnöt-sivulta',
    ...val),

  ROBH: (en: string, ...val: string[]) => translate(en,
    'Tee tukipyyntö',
    ...val),

  ROMH: (en: string, ...val: string[]) => translate(en,
    'Tarkista sähköpostisoite. Sähköpostiosoitteessa ei voi olla ääkkösiä.',
    ...val),

  RP6P: (en: string, ...val: string[]) => translate(en,
    'Yhteiskäyttölaitteet',
    ...val),

  RPL9: (en: string, ...val: string[]) => translate(en,
    'Arvo',
    ...val),

  RPMR: (en: string, ...val: string[]) => translate(en,
    'Laskutustiedot',
    ...val),

  RPVC: (en: string, ...val: string[]) => translate(en,
    'Valitse SIM-kortin tyyppi',
    ...val),

  RQ67: (en: string, ...val: string[]) => translate(en,
    'Liittymällä on määräaikaisuus joka estää sen päivittämisen hyllyliittymäksi.',
    ...val),

  RQA1: (en: string, ...val: string[]) => translate(en,
    'Tietojen tallentaminen epäonnistui. Yritä uudelleen',
    ...val),

  RQMS: (en: string, ...val: string[]) => translate(en,
    'Tietojen muokkaus ei onnistu',
    ...val),

  RR0O: (en: string, ...val: string[]) => translate(en,
    'OmaElisa Classicissa.',
    ...val),

  RREM: (en: string, ...val: string[]) => translate(en,
    'työ',
    ...val),

  RSDJ: (en: string, ...val: string[]) => translate(en,
    'Seuraavassa vaiheessa voit esikatsella ja tarkastaa, että lomake on täytetty oikein. Tämän jälkeen voit lähettää lomakkeen käsiteltäväksi.',
    ...val),

  RTJW: (en: string, ...val: string[]) => translate(en,
    'Muokattu',
    ...val),

  RTLQ: (en: string, ...val: string[]) => translate(en,
    'Tarkista ja täydennä käyttäjän tiedot. Muista käyttää uuden yrityksen tietoja.',
    ...val),

  RTNX: (en: string, ...val: string[]) => translate(en,
    'Tilauksen lähetys onnistui',
    ...val),

  RUAW: (en: string, ...val: string[]) => translate(en,
    'Postinumero',
    ...val),

  RUFL: (en: string, ...val: string[]) => translate(en,
    'Muokattu',
    ...val),

  RUU7: (en: string, ...val: string[]) => translate(en,
    'Kuukausimaksusi',
    ...val),

  RV74: (en: string, ...val: string[]) => translate(en,
    'Etuliitteet soittaessa',
    ...val),

  RVRZ: (en: string, ...val: string[]) => translate(en,
    'Jos ääni ei kuulu kunnolla tai äänet on vaimeat',
    ...val),

  RVSV: (en: string, ...val: string[]) => translate(en,
    'Tuntematon virhe.',
    ...val),

  RVSW: (en: string, ...val: string[]) => translate(en,
    'Lähettäjä',
    ...val),

  RW1L: (en: string, ...val: string[]) => translate(en,
    'Aloita uusi aihe',
    ...val),

  RW6O: (en: string, ...val: string[]) => translate(en,
    'Odota hetki, tietoja tarkastetaan...',
    ...val),

  RW72: (en: string, ...val: string[]) => translate(en,
    'Vanha laite tulee palauttaa Elisalle 2 vkon kuluessa.',
    ...val),

  RW9V: (en: string, ...val: string[]) => translate(en,
    'Päivitä liittymää',
    ...val),

  RWE3: (en: string, ...val: string[]) => translate(en,
    'Lisää laite tai liittymä',
    ...val),

  RWLD: (en: string, ...val: string[]) => translate(en,
    'Päivitä liittymätyyppiä',
    ...val),

  RWP3: (en: string, ...val: string[]) => translate(en,
    'Liittymä on jo liitetty Ring-ratkaisuun.',
    ...val),

  RX8Z: (en: string, ...val: string[]) => translate(en,
    'Tunnuksen luonti epäonnistui',
    ...val),

  RXBO: (en: string, ...val: string[]) => translate(en,
    'Hyväksyjän pitää valita laskutussopimus tilauksen hyväksymisen yhteydessä',
    ...val),

  RXQO: (en: string, ...val: string[]) => translate(en,
    'Vahvista sähköpostiosoitteesi.',
    ...val),

  RXRO: (en: string, ...val: string[]) => translate(en,
    'Tämä on Elisa Tunnuksen testiympäristö.',
    ...val),

  RY1J: (en: string, ...val: string[]) => translate(en,
    'Suosittelemme käyttämään yksilöllistä sähköpostia ja puhelinnumeroa tai valitsemaan sopivan yhteyshenkilön yrityksesi käyttäjälistalta.',
    ...val),

  RYGM: (en: string, ...val: string[]) => translate(en,
    'Minuuttihintaiset liittymät',
    ...val),

  RYLC: (en: string, ...val: string[]) => translate(en,
    'Lähetämme tiedon laitesopimuksen hylkäämisestä osoitteeseen',
    ...val),

  RYY6: (en: string, ...val: string[]) => translate(en,
    'Estä puhelut ulkomaille',
    ...val),

  RZIU: (en: string, ...val: string[]) => translate(en,
    'Kutsun lähetys työntekijälle epäonnistui',
    ...val),

  RZU4: (en: string, ...val: string[]) => translate(en,
    'Vahvista tilaus',
    ...val),

  RZXC: (en: string, ...val: string[]) => translate(en,
    'Tilaajan sähköposti:',
    ...val),

  S07L: (en: string, ...val: string[]) => translate(en,
    'Oletko varma, että haluat irtisanoa Elisa Mobiilivarmenne Työntekijälle -palvelun',
    ...val),

  S0FN: (en: string, ...val: string[]) => translate(en,
    'Työntekijän OmaElisa',
    ...val),

  S0H2: (en: string, ...val: string[]) => translate(en,
    'Liittymällä on käytössä lisäpalveluita, jotka estävät sen muuttamisen hyllyliittymäksi.',
    ...val),

  S0IN: (en: string, ...val: string[]) => translate(en,
    'On tärkeää, että työsähköpostisi ja -puhelinnumerosi ovat ajan tasalla Työntekijän OmaElisassa. Hyödynnämme näitä yhteystietojasi, kun laitamme sinulle viestiä yrityksesi käyttämistä Elisan palveluista. Ilmoitamme sinulle esimerkiksi tilaamiesi tuotteiden toimituksesta.\nYrityksesi pääkäyttäjä näkee antamasi työyhteystiedot Yritysten OmaElisassa, jossa hän pääsee niitä tarvittaessa myös muokkaamaan. Jos puhelinnumerosi on julkinen, se näkyy lisäksi luettelotiedoissa.',
    ...val),

  S0V2: (en: string, ...val: string[]) => translate(en,
    'Elisa veloittaa tuhoutuneesta tai kadonneesta laitteesta jäljelle jäävien kuukausimaksujen summan kertaveloituksena.',
    ...val),

  S14B: (en: string, ...val: string[]) => translate(en,
    'Laiterekisteröintiohjelma',
    ...val),

  S1F0: (en: string, ...val: string[]) => translate(en,
    'Puhelut numero- ja yhdistämispalvelunumeroihin sekä hyötypalveluihin, esim. soittaminen ja tekstiviestit numeropalveluun, taksin tilaus ja pankkipalvelut',
    ...val),

  S1IM: (en: string, ...val: string[]) => translate(en,
    '100 Mbit/s • 5000 min • 100 kpl',
    ...val),

  S3DX: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen vaihto epäonnistui',
    ...val),

  S4DE: (en: string, ...val: string[]) => translate(en,
    'Valitse tiedosto',
    ...val),

  S4DF: (en: string, ...val: string[]) => translate(en,
    'Poista',
    ...val),

  S4FM: (en: string, ...val: string[]) => translate(en,
    'Piilota salasana',
    ...val),

  S5PK: (en: string, ...val: string[]) => translate(en,
    'Yhteyssähköpostiosoite',
    ...val),

  S5QX: (en: string, ...val: string[]) => translate(en,
    'Kotiosoite',
    ...val),

  S5RO: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä on siirretty yritykseen {}. Huomaa, että käyttäjän tuotteet näkyvät tällä sivulla vasta, kun niiden siirto on käsitelty.',
    ...val),

  S6EV: (en: string, ...val: string[]) => translate(en,
    'Arvo voi sisältää vain numeroita 0-9, kirjaimia a-f ja kaksoispisteitä.',
    ...val),

  S6PJ: (en: string, ...val: string[]) => translate(en,
    'Kiitos yhteydenotosta!',
    ...val),

  S6U5: (en: string, ...val: string[]) => translate(en,
    ':n käyttäjät',
    ...val),

  S74X: (en: string, ...val: string[]) => translate(en,
    'Jos haluat voit seurata datan käyttöäsi älypuhelimesi asetuksista. Joissain puhelimissa on myös netin käyttöä seuraavat laskurit.',
    ...val),

  S81T: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelinnumeroa ei löydy tai se ei ole aktiivinen',
    ...val),

  S8K2: (en: string, ...val: string[]) => translate(en,
    'Tietojen päivityksessä saattaa kestää muutama minuutti, jonka jälkeen voit käyttää liittymää kellollasi.',
    ...val),

  S8N1: (en: string, ...val: string[]) => translate(en,
    ' Laskulle tulostuva laskun maksajan y-tunnus.',
    ...val),

  S8TX: (en: string, ...val: string[]) => translate(en,
    'alv. 0 %',
    ...val),

  SBCJ: (en: string, ...val: string[]) => translate(en,
    'Estä datasiirto EU:n ja ETA:n ulkopuolella',
    ...val),

  SBDY: (en: string, ...val: string[]) => translate(en,
    'Anna sähköpostiosoitteesi',
    ...val),

  SBUB: (en: string, ...val: string[]) => translate(en,
    'Saatavuustietojen haussa oli ongelma, tee vikailmoitus tai pyydä apua YA Päivittäistuesta',
    ...val),

  SC8P: (en: string, ...val: string[]) => translate(en,
    'Aika-asetukset',
    ...val),

  SCDP: (en: string, ...val: string[]) => translate(en,
    'Nauhoitamme kaikki puhelut palvelun laadun varmistamiseksi. Lisätietoja tietosuojasta saat osoitteesta',
    ...val),

  SCL4: (en: string, ...val: string[]) => translate(en,
    'Julkinen',
    ...val),

  SCQO: (en: string, ...val: string[]) => translate(en,
    'Käyttöraja',
    ...val),

  SCZB: (en: string, ...val: string[]) => translate(en,
    'Unohtunut tai vanhentunut käyttäjätunnus',
    ...val),

  SD0B: (en: string, ...val: string[]) => translate(en,
    'Eikö sinulla ole tunnuksia? Voit lähettää viestin meille myös kirjautumatta.',
    ...val),

  SDTI: (en: string, ...val: string[]) => translate(en,
    'Rajaton data kotimaassa, Pohjolassa ja Baltiassa',
    ...val),

  SDTJ: (en: string, ...val: string[]) => translate(en,
    'Sujuva arki ja työnteko',
    ...val),

  SDTK: (en: string, ...val: string[]) => translate(en,
    'Palaverit ja leffojen katselu',
    ...val),

  SDTL: (en: string, ...val: string[]) => translate(en,
    'Suositus {0} henkilöä ({1} laitetta)',
    ...val),

  SDTM: (en: string, ...val: string[]) => translate(en,
    'Sujuvaan arkikäyttöön',
    ...val),

  SDTN: (en: string, ...val: string[]) => translate(en,
    'Aktiivinen työnteko',
    ...val),

  SDTO: (en: string, ...val: string[]) => translate(en,
    'Videopalaverit ja sarjojen katselu',
    ...val),

  SDTP: (en: string, ...val: string[]) => translate(en,
    'Nopea yrityskäyttö',
    ...val),

  SDTQ: (en: string, ...val: string[]) => translate(en,
    'Videoneuvottelut ja 4K-leffat',
    ...val),

  SDTR: (en: string, ...val: string[]) => translate(en,
    'Aktiiviseen surffailuun',
    ...val),

  SDTS: (en: string, ...val: string[]) => translate(en,
    'Nopeaan yrityskäyttöön',
    ...val),

  SDTT: (en: string, ...val: string[]) => translate(en,
    'Supernopea yrityskäyttö',
    ...val),

  SDTU: (en: string, ...val: string[]) => translate(en,
    'Videoneuvottelut ja 8K-leffat',
    ...val),

  SDTV: (en: string, ...val: string[]) => translate(en,
    'Kaikki netinkäyttäjät ja laitteet',
    ...val),

  SDTW: (en: string, ...val: string[]) => translate(en,
    'Supernopeaan yrityskäyttöön',
    ...val),

  SDU1: (en: string, ...val: string[]) => translate(en,
    'Reitittimellä jaat verkon usealle eri laitteelle',
    ...val),

  SDU2: (en: string, ...val: string[]) => translate(en,
    'Saat parhaimman tehon irti mobiililaajakaistasta, kun hankit reitittimen. Reitittimellä voit jakaa verkon langattomalla WiFi-verkolla eri laitteille, kuten tietokoneille, tableteille ja televisioon. Valitse 4G-liittymään 4G-reititin ja 5G-liittymään 5G-reititin:',
    ...val),

  SDU3: (en: string, ...val: string[]) => translate(en,
    'Katso kaikki reitittimet',
    ...val),

  SDU4: (en: string, ...val: string[]) => translate(en,
    'Nettiliittymä pieneen toimistoon tai etätöihin',
    ...val),

  SDU5: (en: string, ...val: string[]) => translate(en,
    ' on täyden palvelun nettiliittymä pienelle yrityksellesi tai kotitoimistoon. Katsomme sinulle parhaimman vaihtoehdon sijaintisi ja tarpeesi mukaan.',
    ...val),

  SDU6: (en: string, ...val: string[]) => translate(en,
    'Tutustu Netti Lite -palveluun',
    ...val),

  SDU7: (en: string, ...val: string[]) => translate(en,
    'Ota liikkuva laajakaista yrityksessäsi sujuvasti käyttöön',
    ...val),

  SDU8: (en: string, ...val: string[]) => translate(en,
    'Lue ohjeet ja vinkit',
    ...val),

  SDU9: (en: string, ...val: string[]) => translate(en,
    'Kiinnostuitko?',
    ...val),

  SDUA: (en: string, ...val: string[]) => translate(en,
    'Jätä yhteystietosi',
    ...val),

  SDUB: (en: string, ...val: string[]) => translate(en,
    'Ma-Pe 8-16 | Maksuton numero',
    ...val),

  SDUD: (en: string, ...val: string[]) => translate(en,
    'Ma-Pe 8-17 | 3,04 €/puhelu + 3,62 €/min alv. 25,5 % + pvm/mpm',
    ...val),

  SDUF: (en: string, ...val: string[]) => translate(en,
    'Netti suuremmalle toimipaikalle',
    ...val),

  SDUG: (en: string, ...val: string[]) => translate(en,
    ' -liittymät tuovat vakaan nettiyhteyden toimipaikallesi. Voit myös hyödyntää niitä liiketilan monipuolisena nettiyhteytenä.',
    ...val),

  SDUH: (en: string, ...val: string[]) => translate(en,
    'Tutustu Netti Pro ja -Plus -liittymiin',
    ...val),

  SDUI: (en: string, ...val: string[]) => translate(en,
    'Elisa Laitenetti – ketterä mobiililiittymä laitteisiin',
    ...val),

  SDUJ: (en: string, ...val: string[]) => translate(en,
    ' sopii esimerkiksi valvontakameroihin, hälytysjärjestelmiin tai GPS-paikantimiin. Tutustu ja tilaa Laitenetti-liittymä etäohjattaviin laitteisiin!',
    ...val),

  SDUK: (en: string, ...val: string[]) => translate(en,
    'Tutustu LaiteNetti -liittymiin',
    ...val),

  SDUV: (en: string, ...val: string[]) => translate(en,
    'Salasana vaihdettu onnistuneesti.',
    ...val),

  SDV0: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista - liikkuva laajakaista yrityksille',
    ...val),

  SDV1: (en: string, ...val: string[]) => translate(en,
    'Liikkuva netti kulkee mukana etätöissä ja reissussa. Liikkuva laajakaista on SIM-kortilla toimiva mobiililaajakaistaliittymä, jolla saat nettiyhteyden esimerkiksi tablettiin tai reitittimeen.',
    ...val),

  SDV2: (en: string, ...val: string[]) => translate(en,
    'Toimiva netti missä vain',
    ...val),

  SDV3: (en: string, ...val: string[]) => translate(en,
    'Nopea 4G-, 5G ja 5G+ -verkko',
    ...val),

  SDV4: (en: string, ...val: string[]) => translate(en,
    'Kattavat datapaketit',
    ...val),

  SDV5: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista sopii hyvin liikkuvaan työhön. Hoida työt joustavasti eri paikoissa - etätöissä, työmatkoilla ja myyntitapaamisissa.',
    ...val),

  SDV6: (en: string, ...val: string[]) => translate(en,
    'Elisan 4G-verkko kattaa jo läpi Suomen ja olemme 5G-verkon edelläkävijä. Hyvä kuuluvuus ja toimiva verkko takaavat vakaan yhteyden.',
    ...val),

  SDV7: (en: string, ...val: string[]) => translate(en,
    'Kaikissa liittymissä on rajaton netti kotimaassa. Lisäksi saat hyvin dataa EU- ja ETA-maihin, niin pääset surffaamaan turvallisesti reissussa.',
    ...val),

  SDV8: (en: string, ...val: string[]) => translate(en,
    'Kaipaatko apua mobiililaajakaistan valinnassa?',
    ...val),

  SDV9: (en: string, ...val: string[]) => translate(en,
    'Autamme sinua valitsemaan sopivan liikkuvan laajakaistan yrityksellesi. Jätä yhteystietosi, niin myyjämme vastaa kysymyksiisi:',
    ...val),

  SDVA: (en: string, ...val: string[]) => translate(en,
    'Mikä on liikkuva laajakaista?',
    ...val),

  SDVB: (en: string, ...val: string[]) => translate(en,
    'Miten saan mobiililaajakaistan käyttöön?',
    ...val),

  SDVC: (en: string, ...val: string[]) => translate(en,
    'Miksi kannattaa valita Elisan mobiililaajakaista?',
    ...val),

  SDVD: (en: string, ...val: string[]) => translate(en,
    'Miten nopea Elisan mobiililaajakaista on?',
    ...val),

  SDVE: (en: string, ...val: string[]) => translate(en,
    'Toimivatko 5G-liittymät alueellani?',
    ...val),

  SDVF: (en: string, ...val: string[]) => translate(en,
    'Voiko liikkuvaa laajakaistaa käyttää ulkomailla?',
    ...val),

  SDVG: (en: string, ...val: string[]) => translate(en,
    'Onko mobiililaajakaistan sopimus määräaikainen?',
    ...val),

  SDVH: (en: string, ...val: string[]) => translate(en,
    'Tarvitsenko erillisen reitittimen?',
    ...val),

  SDVI: (en: string, ...val: string[]) => translate(en,
    'Liikkuva laajakaista yrityksille - usein kysyttyä',
    ...val),

  SDVJ: (en: string, ...val: string[]) => translate(en,
    'Liikkuva laajakaista on SIM-kortilla toimiva ',
    ...val),

  SDVK: (en: string, ...val: string[]) => translate(en,
    'mobiililaajakaistaliittymä',
    ...val),

  SDVL: (en: string, ...val: string[]) => translate(en,
    ', jolla saat nettiyhteyden liikkuvaan työhön. Elisa Yritysdata -mobiililaajakaistan mukana tulee SIM-kortti, jonka voit asentaa esimerkiksi tablettiin tai reitittimeen. Voit jatkaa töitäsi joustavasti myös reissussa.',
    ...val),

  SDVM: (en: string, ...val: string[]) => translate(en,
    'Katso myös ',
    ...val),

  SDVN: (en: string, ...val: string[]) => translate(en,
    'puhelinliittymät yrityksille.',
    ...val),

  SDVO: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaistan mukana tulee oma SIM-kortti, jonka voit asentaa esimerkiksi tablettiin tai reitittimeen.',
    ...val),

  SDVP: (en: string, ...val: string[]) => translate(en,
    'Voit hankkia SIM-kortin nykyisin myös eSIM-korttina. Tällöin voit kytkeä SIM-kortin päälle digitaalisesti. Huomaa, että päätelaitteesi tulee tukea eSIM-toiminnallisuutta.',
    ...val),

  SDVQ: (en: string, ...val: string[]) => translate(en,
    'Lue, miten otat eSIM-kortin käyttöön.',
    ...val),

  SDVR: (en: string, ...val: string[]) => translate(en,
    'Elisan mobiililaajakaistaliittymä on luotettava, kotimainen valinta yrityksellesi. Kattavassa ja laadukkaassa 5G- ja 5G+ -verkossa työskentelet joustavasti myös tien päällä.',
    ...val),

  SDVS: (en: string, ...val: string[]) => translate(en,
    'Elisa panostaa aktiivisesti tuotekehitykseen ja laajennamme nopeita 5G-verkkojamme jatkuvasti.',
    ...val),

  SDVT: (en: string, ...val: string[]) => translate(en,
    'Elisan mobiililaajakaistaan on saatavilla erilaisia nopeusluokkia, jotka ilmoitetaan aina maksiminopeuksina.',
    ...val),

  SDVU: (en: string, ...val: string[]) => translate(en,
    'Nopeus riippuu mm. sijainnista, käytettävissä olevasta verkkotekniikasta, kuuluvuuden voimakkuudesta, häiriötasosta, päätelaitteen ominaisuuksista ja verkon kuormituksesta.',
    ...val),

  SDVV: (en: string, ...val: string[]) => translate(en,
    'Katso kuuluvuuskartasta alueesi kuuluvuus.',
    ...val),

  SDVW: (en: string, ...val: string[]) => translate(en,
    'Elisa laajentaa ja parantaa 5G- ja 5G+ -verkkojaan jatkuvasti ympäri Suomen. Elisan 5G-verkon alueella asuu jo yli 4 miljoonaa suomalaista ja se on yli 200 paikkakunnalla.',
    ...val),

  SDVX: (en: string, ...val: string[]) => translate(en,
    'Katso tarkemmat kuuluvuusalueet.',
    ...val),

  SDVY: (en: string, ...val: string[]) => translate(en,
    'Jos käytät 5G-mobiililaajakaistaa 5G-alueen ulkopuolella, käytössäsi on nopea 4G-yhteys.',
    ...val),

  SDVZ: (en: string, ...val: string[]) => translate(en,
    'Kaikkiin liittymiin kuuluu tietty määrä dataa EU- ja ETA-maihin. ',
    ...val),

  SDW0: (en: string, ...val: string[]) => translate(en,
    'Lue lisää liittymän käytöstä ulkomailla.',
    ...val),

  SDW1: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaistaan on mahdollista tilata ',
    ...val),

  SDW2: (en: string, ...val: string[]) => translate(en,
    'Elisa Reissunetti -palvelu',
    ...val),

  SDW3: (en: string, ...val: string[]) => translate(en,
    ', mikäli päätelaite tukee tekstiviestien lähetystä ja vastaanottoa. Yleensä reitittimiä varten on olemassa puhelimeen asennettava sovellus, jonka kautta tekstiviestejä voi hallinnoida (esim. Huawei HiLink).',
    ...val),

  SDW4: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaistaliittymämme ovat toistaiseksi voimassa olevia lukuunottamatta Suomen Yrittäjät -jäsenetua ja tarjouksia.',
    ...val),

  SDW5: (en: string, ...val: string[]) => translate(en,
    'Toistaiseksi voimassa olevan liittymän voi irtisanoa milloin vain ',
    ...val),

  SDW6: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisassa',
    ...val),

  SDW7: (en: string, ...val: string[]) => translate(en,
    ' tai ',
    ...val),

  SDW8: (en: string, ...val: string[]) => translate(en,
    'asiakaspalvelun',
    ...val),

  SDW9: (en: string, ...val: string[]) => translate(en,
    ' kautta.',
    ...val),

  SDWA: (en: string, ...val: string[]) => translate(en,
    'Reitittimellä saat jaettua nettiyhteyden useaan eri laitteeseen eli saat muodostettua WiFi-verkon. Näin voit yhdistää samaan verkkoon esimerkiksi tablettisi, tietokoneesi ja televisiosi. ',
    ...val),

  SDWB: (en: string, ...val: string[]) => translate(en,
    'Reititin ei tule automaattisesti mobiililaajakaistaliittymän mukana, mutta voit ostaa sen erikseen ',
    ...val),

  SDWC: (en: string, ...val: string[]) => translate(en,
    'yritysverkkokaupastamme.',
    ...val),

  SDWD: (en: string, ...val: string[]) => translate(en,
    'Kaikissa liittymissä on rajaton data kotimaassa, Pohjolassa ja Baltiassa.',
    ...val),

  SDWE: (en: string, ...val: string[]) => translate(en,
    'Usein kysytyt kysymykset & vastaukset',
    ...val),

  SDWF: (en: string, ...val: string[]) => translate(en,
    'Tutustu ja tilaa',
    ...val),

  SDWG: (en: string, ...val: string[]) => translate(en,
    'Usein kysytyt kysymykset',
    ...val),

  SDWH: (en: string, ...val: string[]) => translate(en,
    'Heräsikö kysymyksiä yritysten puhelinliittymistä?',
    ...val),

  SDWL: (en: string, ...val: string[]) => translate(en,
    ' käytät nettiä huoletta myös EU/ETA-maiden ulkopuolella. ',
    ...val),

  SDWM: (en: string, ...val: string[]) => translate(en,
    'Avainlippu on merkki suomalaisesta työstä ja yrityksen kotimaisuudesta.',
    ...val),

  SDWN: (en: string, ...val: string[]) => translate(en,
    'Tunnuksen ovat saaneet kaikki Elisan yritys- ja kuluttajapuolen liittymät (mobiili ja kiinteä).',
    ...val),

  SDWO: (en: string, ...val: string[]) => translate(en,
    'Valmiit datapakettimme mahdollistavat edullisemman roamingin ulkomailla ja vältät yllättävät kustannukset.',
    ...val),

  SDWP: (en: string, ...val: string[]) => translate(en,
    'Liittymiin kuuluvat datapaketit aktivoituvat automaattisesti saavuttuasi kohdemaahan ja ne veloitetaan erikseen: Premium 26,90 €/14 vuorokautta, Perus 21,90 €/14 vuorokautta ja Mini 10,90 €/päivä.',
    ...val),

  SDWQ: (en: string, ...val: string[]) => translate(en,
    'Yhdistä liittymääsi lisää ominaisuuksia',
    ...val),

  SDWR: (en: string, ...val: string[]) => translate(en,
    '',
    ...val),

  SDWS: (en: string, ...val: string[]) => translate(en,
    'Elisa OmaLaskulla',
    ...val),

  SDWT: (en: string, ...val: string[]) => translate(en,
    'Elisalta saat myös nämä palvelut',
    ...val),

  SDWU: (en: string, ...val: string[]) => translate(en,
    'Tilaa liittymääsi 5G-verkon maksiminopeus päivän, viikon tai kuukauden ajaksi. ',
    ...val),

  SDWV: (en: string, ...val: string[]) => translate(en,
    'Roaming ulkomailla',
    ...val),

  SDWX: (en: string, ...val: string[]) => translate(en,
    ' voit tarjota työntekijöillesi mielekkäitä lisäpalveluita puhelinliittymään. Voit esimerkiksi sallia puhelut ja tekstarit maksullisiin palvelunumeroihin.',
    ...val),

  SDWY: (en: string, ...val: string[]) => translate(en,
    'Yhdistä puheliittymäsi älykelloon tai puhelimeen digitaalisen eSIM-kortin avulla. ',
    ...val),

  SDWZ: (en: string, ...val: string[]) => translate(en,
    'Elisa WiFi-puhelinpalvelu',
    ...val),

  SDX0: (en: string, ...val: string[]) => translate(en,
    'Elisa Yritysliittymä 5G sisältää WiFi-puhelut (VoWiFi) ja 4G-puhelut (VoLTE). Näin voit soittaa myös vaikeissa kuuluvuuspaikoissa. ',
    ...val),

  SDX3: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelinvastaaja',
    ...val),

  SDX4: (en: string, ...val: string[]) => translate(en,
    'Elisa matkapuhelinvastaajalla',
    ...val),

  SDX5: (en: string, ...val: string[]) => translate(en,
    ' saat liittymääsi vastaajan, lomavastaajan sekä ilmoituksen vastaamattomista puheluista. Tekstiviesti tuo tiedon vastaamattomista puheluista.',
    ...val),

  SDX6: (en: string, ...val: string[]) => translate(en,
    'Lomavastaajalla voit erotella myös työ- ja yksityispuhelut toisistaan ja varmistaa, että työpuhelut ohjautuvat haluamallesi henkilölle lomasi aikana.',
    ...val),

  SDXB: (en: string, ...val: string[]) => translate(en,
    'Virheellinen SIM-kortin numero.',
    ...val),

  SDXD: (en: string, ...val: string[]) => translate(en,
    ' tuo vauhtia etätöihin, työreissulle ja vapaa-ajan harrastuksiin.',
    ...val),

  SDXE: (en: string, ...val: string[]) => translate(en,
    ' on helppokäyttöinen puhevaihde yrittäjälle ja pienelle yritykselle. Hallitse yrityksesi tulevia puheluita paremmin ja paranna yrityksesi tavoitettavuutta.',
    ...val),

  SDXF: (en: string, ...val: string[]) => translate(en,
    'Elisa Ring -viestintäratkaisu',
    ...val),

  SDXG: (en: string, ...val: string[]) => translate(en,
    ' on pilvipohjainen joustava puhe- ja asiakaspalveluratkaisu, joka yhdistää asiakaspalvelun ja puhelinvaihteen yhteen pakettiin.',
    ...val),

  SDXH: (en: string, ...val: string[]) => translate(en,
    'Elisa Lankapuhelin -palvelu',
    ...val),

  SDXI: (en: string, ...val: string[]) => translate(en,
    '',
    ...val),

  SDXO: (en: string, ...val: string[]) => translate(en,
    'Tiedonsiirtonopeus vaihtelee päätelaitteen ominaisuuksien, sijainnin ja verkon kuormituksen mukaan. Tarkista kuuluvuus alueellasi ',
    ...val),

  SDXP: (en: string, ...val: string[]) => translate(en,
    'kuuluvuuskartasta',
    ...val),

  SDXQ: (en: string, ...val: string[]) => translate(en,
    '. Pohjola ja Baltian maat: Ruotsi, Norja, Tanska, Viro, Latvia ja Liettua. Yritysliittymä 5G -liittymissä käyttörajan ylittävä data hinnoitellaan ',
    ...val),

  SDXR: (en: string, ...val: string[]) => translate(en,
    'roaming-hinnaston mukaisesti',
    ...val),

  SDXS: (en: string, ...val: string[]) => translate(en,
    '. Liittymät sisältävät puhetta 5000 min/kk normaalihintaisiin kotimaan matka- ja lankapuhelinnumeroihin ja SMS-viestejä 100 kpl/kk. Puheaika ei sisällä puheluita ulkomaille tai puheluita lisämaksullisiin palvelunumeroihin, kuten 0700- ja 0600-alkuisiin numeroihin. Soitettaessa ulkomaille tai lisämaksullisiin numeroihin veloitetaan lisäksi matkapuhelumaksu (mpm), jonka hinta on 0,199 €/ min. Hinnat eivät koske erityishinnoiteltuja verkkoja (laivat/satelliitit/lentokoneet).',
    ...val),

  SDXT: (en: string, ...val: string[]) => translate(en,
    'Takaisin sivun alkuun',
    ...val),

  SDXU: (en: string, ...val: string[]) => translate(en,
    'Nettiliittymät yrityksille',
    ...val),

  SDXV: (en: string, ...val: string[]) => translate(en,
    'Etsitkö nettiä liikkuvaan työhön, kotitoimistolle tai yrityksesi toimipisteelle? Elisalta löydät sopivan nettiliittymän tarpeidesi mukaan.',
    ...val),

  SDXW: (en: string, ...val: string[]) => translate(en,
    'Tutustu nettiliittymiin',
    ...val),

  SDXX: (en: string, ...val: string[]) => translate(en,
    'Elisalta saat lisäksi muita hyödyllisiä palveluita yrittäjän tai suuremman yrityksen tarpeisiin',
    ...val),

  SDXY: (en: string, ...val: string[]) => translate(en,
    'Lue lisää ja ',
    ...val),

  SDXZ: (en: string, ...val: string[]) => translate(en,
    'tutustu kattaviin asiakaspalveluratkaisuihimme.',
    ...val),

  SE00: (en: string, ...val: string[]) => translate(en,
    ' parantaa mobiililiittymän selainturvallisuutta. Se tunnistaa haitalliset sivut ja ilmoittaa niistä käyttäjille.',
    ...val),

  SE01: (en: string, ...val: string[]) => translate(en,
    'Saat turvaa yleisintä tietoturvauhkaa eli tietojen kalastelua (phishing) vastaan, kun käytät nettiä 4G- tai 5G-verkossa.',
    ...val),

  SE02: (en: string, ...val: string[]) => translate(en,
    'Yritysliittymien lisäpalveluilla sujuvampi ja turvallisempi arki',
    ...val),

  SE04: (en: string, ...val: string[]) => translate(en,
    'Elisa eSIM:n',
    ...val),

  SE05: (en: string, ...val: string[]) => translate(en,
    'käyttöönotto on helppoa ja nopeaa.',
    ...val),

  SE06: (en: string, ...val: string[]) => translate(en,
    'Lue lisää Elisa WiFi-puhelinpalvelusta.',
    ...val),

  SE07: (en: string, ...val: string[]) => translate(en,
    'Elisa Lankapuhelinpalvelulla',
    ...val),

  SE08: (en: string, ...val: string[]) => translate(en,
    ' voit yhdistää yrityksesi lankapuhelimeen tulevat puhelut kännykkääsi. Näin vastaat yrityksesi lankanumeroon helposti myös tien päällä.',
    ...val),

  SE11: (en: string, ...val: string[]) => translate(en,
    'Premium-liittymään saat kaikki lisäpalvelut. Voit tilata Mini- ja Perus-liittymät valmiina paketteina tältä sivulta. Jos haluat täydentää Mini- tai Perus-liittymääsi muilla lisäpalveluilla, siirry tilaamaan ne ',
    ...val),

  SE12: (en: string, ...val: string[]) => translate(en,
    '. OmaElisassa voit lisätä lisäpalveluita myös olemassa olevaan liittymään.',
    ...val),

  SE13: (en: string, ...val: string[]) => translate(en,
    'Reissunetti- ja Reissaaja-datapaketeilla',
    ...val),

  SE27: (en: string, ...val: string[]) => translate(en,
    'Näytettävä numero puheluille',
    ...val),

  SE2C: (en: string, ...val: string[]) => translate(en,
    'Aktiivinen netin selaus, videopalaverit ja selainpohjaiset työkalut',
    ...val),

  SE2D: (en: string, ...val: string[]) => translate(en,
    'Toimistoympäristöt, raskaampi tiedonsiirto ja 4K-videot',
    ...val),

  SEB2: (en: string, ...val: string[]) => translate(en,
    'Valitse itsellesi sopivat laitteet työntekoon. Osallistumalla itse kustannuksiin saat aina parhaan mahdollisen puhelimen ja lisätarvikkeet.',
    ...val),

  SEBD: (en: string, ...val: string[]) => translate(en,
    'Omat tiedot',
    ...val),

  SEER: (en: string, ...val: string[]) => translate(en,
    'Vaihda',
    ...val),

  SEYV: (en: string, ...val: string[]) => translate(en,
    'Nettiliittymät',
    ...val),

  SEYW: (en: string, ...val: string[]) => translate(en,
    'Nettiliittymät toimistolle sekä etäohjattaviin laitteisiin',
    ...val),

  SEZ9: (en: string, ...val: string[]) => translate(en,
    'Laskulle tulostuva laskun maksajan nimi',
    ...val),

  SF00: (en: string, ...val: string[]) => translate(en,
    '5G+ eli itsenäinen 5G-verkko nyt saatavilla yritysliittymiin',
    ...val),

  SF01: (en: string, ...val: string[]) => translate(en,
    'Tuomme itsenäisen 5G-verkon asiakkaidemme saataville ensimmäisenä Suomessa.',
    ...val),

  SF02: (en: string, ...val: string[]) => translate(en,
    'Itsenäinen 5G-verkko (5G Standalone) on 5G:n seuraava teknologinen kehitysaskel, joka mahdollistaa entistä vakaammat ja tasalaatuisemmat yhteydet.',
    ...val),

  SF03: (en: string, ...val: string[]) => translate(en,
    'Sen avulla nykyisen 5G-verkon potentiaali kasvaa entistä täysimääräisemmin hyötykäyttöön.',
    ...val),

  SF04: (en: string, ...val: string[]) => translate(en,
    '5G+ on Elisan paras 5G-yhteys ja se on myös ekologisempi ja energiatehokkaampi, kuin aiemmat teknologiat.',
    ...val),

  SF05: (en: string, ...val: string[]) => translate(en,
    '5G+ on vakiona mukana Perus 300M, Perus 600M ja Premium -puhelinliittymissämme. Huomioithan kuitenkin, että itsenäiseen verkkoon päästäksesi ',
    ...val),

  SF06: (en: string, ...val: string[]) => translate(en,
    'tarvitset myös sitä tukevan puhelimen',
    ...val),

  SF07: (en: string, ...val: string[]) => translate(en,
    'Mitkä ovat itsenäisen 5G:n hyödyt?',
    ...val),

  SF08: (en: string, ...val: string[]) => translate(en,
    'Itsenäinen 5G-verkko tarjoaa viimeisintä teknologiaa edustavan parhaan mahdollisen ja tasalaatuisemman yhteyden. Koska verkko on myös energiatehokkaampi, se kuluttaa vähemmän puhelimen akkua. Se mahdollistaa myös 5G-puhelut (Voice over New Radio, VoNR-puhelut), joiden laatu on entistä parempi.',
    ...val),

  SF09: (en: string, ...val: string[]) => translate(en,
    'Mitkä kaikki puhelimet toimivat itsenäisessä verkossa?',
    ...val),

  SF10: (en: string, ...val: string[]) => translate(en,
    'Itsenäinen 5G-verkko toimii toistaiseksi rajatussa joukossa älypuhelimia, mutta valikoima kasvaa säännöllisesti. Tutustu listaukseemme ',
    ...val),

  SF11: (en: string, ...val: string[]) => translate(en,
    '5G+ tuen omaavista laitteista',
    ...val),

  SF12: (en: string, ...val: string[]) => translate(en,
    'Puhelimen vaihtaminen ei ole aina tarpeen, sillä monien uudempien laitteiden osalta riittää, että ohjelmistopäivitykset ovat ajan tasalla.',
    ...val),

  SF13: (en: string, ...val: string[]) => translate(en,
    'Yritysliittymäni on ilman + -ominaisuutta. Päivittyykö nykyinen liittymäni automaattisesti?',
    ...val),

  SF14: (en: string, ...val: string[]) => translate(en,
    'Liittymä ei päivity automaattisesti. Saat päivitettyä puhelinliittymäsi kirjautumalla ',
    ...val),

  SF15: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisaan',
    ...val),

  SF16: (en: string, ...val: string[]) => translate(en,
    'Jos kaipaat lisätietoja, ',
    ...val),

  SF17: (en: string, ...val: string[]) => translate(en,
    'asiakaspalvelumme auttaa mielellään',
    ...val),

  SF18: (en: string, ...val: string[]) => translate(en,
    'Voiko 5G+ -liittymää käyttää puhelimella, joka ei tue itsenäistä verkkoa?',
    ...val),

  SF19: (en: string, ...val: string[]) => translate(en,
    'Kyllä. Jos puhelimesi ei tue itsenäistä verkkoa, se käyttää silloin tavallista 5G- tai 4G -yhteyttä aivan vastaavasti, kuin ennenkin.',
    ...val),

  SF4C: (en: string, ...val: string[]) => translate(en,
    'Näytä kaikki',
    ...val),

  SF6S: (en: string, ...val: string[]) => translate(en,
    'Haluatko sulkea liittymän väliaikaisesti vai muuttaa sen hyllyliittymäksi?',
    ...val),

  SF9V: (en: string, ...val: string[]) => translate(en,
    'Aihe',
    ...val),

  SF9W: (en: string, ...val: string[]) => translate(en,
    'Voit hakea mm. nimellä, puhelinnumerolla, tunnistenumerolla tai laskutussopimusnumerolla. Voit hakea useammalla hakusanalla erottelemalla sanat välilyönnillä. Kirjoita vähintään kolme merkkiä.',
    ...val),

  SFRK: (en: string, ...val: string[]) => translate(en,
    'Aktiivinen',
    ...val),

  SG00: (en: string, ...val: string[]) => translate(en,
    'Nopea 4G-, 5G- ja itsenäinen 5G-verkko',
    ...val),

  SG01: (en: string, ...val: string[]) => translate(en,
    'Toimme ensimmäisenä Suomessa myös itsenäisen 5G-verkon asiakkaidemme saataville.',
    ...val),

  SG02: (en: string, ...val: string[]) => translate(en,
    'Se on 5G-teknologian viimeisin kehitysaskel, ja uusien 5G+-liittymiemme ansiosta surffailet entistä vakaammin sekä viiveettömämmin.',
    ...val),

  SG03: (en: string, ...val: string[]) => translate(en,
    '5G+ on vakiona mukana 1000M, 600M, 300M ja 100M -mobiililaajakaistaliittymissämme. Huomioithan kuitenkin, että voidaksesi hyödyntää itsenäistä verkkoa, ',
    ...val),

  SG04: (en: string, ...val: string[]) => translate(en,
    'tarvitset myös sitä tukevan reitittimen',
    ...val),

  SG05: (en: string, ...val: string[]) => translate(en,
    'Miksi itsenäinen 5G-verkko eli 5G+ on aiempaa parempi?',
    ...val),

  SG06: (en: string, ...val: string[]) => translate(en,
    'Itsenäinen 5G-verkko tarjoaa viimeisintä teknologiaa edustavan parhaan mahdollisen ja tasalaatuisemman yhteyden. Tämä tarkoittaa käytännössä esimerkiksi viiveettömämpää nettiä. 5G+ on myös energiatehokkaampi sekä ekologisempi kuin edeltäjänsä.',
    ...val),

  SG07: (en: string, ...val: string[]) => translate(en,
    'Mitkä kaikki reitittimet toimivat itsenäisessä verkossa?',
    ...val),

  SG08: (en: string, ...val: string[]) => translate(en,
    'Itsenäinen 5G-verkko toimii toistaiseksi rajatussa joukossa reittimiä, mutta valikoima kasvaa säännöllisesti. Tutustu listaukseemme ',
    ...val),

  SG09: (en: string, ...val: string[]) => translate(en,
    ' Voit tilata uuden reitittimen niin netistä, kuin ',
    ...val),

  SG10: (en: string, ...val: string[]) => translate(en,
    'asioimalla myymälässämme',
    ...val),

  SG11: (en: string, ...val: string[]) => translate(en,
    'Nykyinen 5G-nettiliittymäni on ilman + -ominaisuutta. Päivittyykö se automaattisesti?',
    ...val),

  SG12: (en: string, ...val: string[]) => translate(en,
    'Liittymä ei päivity automaattisesti. Saat päivitettyä sen kirjautumalla Yritysten OmaElisaan. Jos kaipaat lisätietoja, ',
    ...val),

  SG13: (en: string, ...val: string[]) => translate(en,
    'asiakaspalvelumme auttaa mielellään',
    ...val),

  SG14: (en: string, ...val: string[]) => translate(en,
    'Voiko 5G+ -liittymää käyttää reittittimellä, joka ei tue itsenäistä verkkoa?',
    ...val),

  SG15: (en: string, ...val: string[]) => translate(en,
    'Kyllä. Jos reitin ei tue itsenäistä verkkoa, se käyttää silloin tavallista 5G- tai 4G -yhteyttä aivan vastaavasti, kuin ennenkin.',
    ...val),

  SG16: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista sopii liikkuvaan työhön',
    ...val),

  SG17: (en: string, ...val: string[]) => translate(en,
    'Liikkuva laajakaista sisältää kattavat datapaketit',
    ...val),

  SGLK: (en: string, ...val: string[]) => translate(en,
    'Liittymäsi tietoja ei löydy.',
    ...val),

  SH61: (en: string, ...val: string[]) => translate(en,
    'alk.',
    ...val),

  SI9A: (en: string, ...val: string[]) => translate(en,
    'Asennuspäivä',
    ...val),

  SIEL: (en: string, ...val: string[]) => translate(en,
    'Ei valittavilta asiakkailta puuttuu olemassa oleva EPP sopimus. Ole yhteydessä Elisa kontaktihenkilöösi uuden EPP sopimuksen luonnin osalta.',
    ...val),

  SIPZ: (en: string, ...val: string[]) => translate(en,
    'Täytä maksajan tiedot',
    ...val),

  SJ3Y: (en: string, ...val: string[]) => translate(en,
    'Vanha SIM-korttisi lakkaa nyt toimimasta',
    ...val),

  SJ7E: (en: string, ...val: string[]) => translate(en,
    'Osittain toimitettu',
    ...val),

  SJBG: (en: string, ...val: string[]) => translate(en,
    'eSIMin toimituksessa kestää hetki, jonka jälkeen voit aktivoida sen.',
    ...val),

  SKA0: (en: string, ...val: string[]) => translate(en,
    'Tarkasta, että käyttäjällä on ajan tasalla olevat työsähköposti ja -puhelinnumero.',
    ...val),

  SKDZ: (en: string, ...val: string[]) => translate(en,
    'Vakio-ohjauksien päivityksessä tapahtui virhe',
    ...val),

  SKP7: (en: string, ...val: string[]) => translate(en,
    'Muu liittymä',
    ...val),

  SL51: (en: string, ...val: string[]) => translate(en,
    'Laitteeseen sisäänrakennettu eSIM toimii perinteisen SIM-kortin tavoin. Voit ottaa eSIMin käyttöön sähköisesti heti tilauksen jälkeen. eSIMin käyttöönotto vaatii, että laitteesi on yhteydessä langattomaan verkkoon (wifi). Huomioithan, että eSIM toimii vain laitteella, johon se on ensimmäisellä kerralla ladattu.',
    ...val),

  SLBP: (en: string, ...val: string[]) => translate(en,
    'OmaElisa Classic',
    ...val),

  SLOC: (en: string, ...val: string[]) => translate(en,
    'Palvelutaso',
    ...val),

  SLOI: (en: string, ...val: string[]) => translate(en,
    'Rajaton netti kotimaassa, Pohjolassa ja Baltiassa',
    ...val),

  SMAR: (en: string, ...val: string[]) => translate(en,
    'Ohjeet tietojen palauttamiseen',
    ...val),

  SMI6: (en: string, ...val: string[]) => translate(en,
    'Käyttäjätunnus on pakollinen',
    ...val),

  SMM7: (en: string, ...val: string[]) => translate(en,
    'Nykyinen SIM-kortti lakkaa toimimasta heti tilauksen jälkeen.',
    ...val),

  SMPX: (en: string, ...val: string[]) => translate(en,
    'Tarkista ennen tilausta, että käytössäsi on ',
    ...val),

  SMSV: (en: string, ...val: string[]) => translate(en,
    'Sinulla on jo käytössä Yritysten OmaElisa-tunnus.',
    ...val),

  SNOO: (en: string, ...val: string[]) => translate(en,
    'Voit muokata vain yhtä tietuetta kerrallaan. Tallenna tai peruuta nykyiset muutokset.',
    ...val),

  SOJT: (en: string, ...val: string[]) => translate(en,
    'Valittua laskutussopimusta käytetään oletuksena kaikille tilauksille, joita tältä laitelistalta tehdään. Tarvittaessa tilauksen hyväksyjä voi vaihtaa laskutussopimuksen toiseen. Voit myös valita vaihtoehdon, jossa hyväksyjä valitsee laskutussopimuksen itse tilausten hyväksymisen yhteydessä.',
    ...val),

  SONR: (en: string, ...val: string[]) => translate(en,
    'Laite tulee palauttaa Elisalle viikon kuluessa. Palauttamattomasta laitteesta veloitetaan palautusrikemaksu.',
    ...val),

  SOQ1: (en: string, ...val: string[]) => translate(en,
    'Tilaus on välitetty onnistuneesti tilauskäsittelyyn',
    ...val),

  SOV8: (en: string, ...val: string[]) => translate(en,
    'Ei puhelinliittymiä',
    ...val),

  SOXH: (en: string, ...val: string[]) => translate(en,
    'Ei sisällä roaming ominaisuuksia, toimii vain kotimaassa. Normaalihintaiset kotimaan puhelut 0,0565 €/min, tekstiviestit 0,0565 €/kpl, multimediaviestit 0,156 €/kpl.',
    ...val),

  SQW6: (en: string, ...val: string[]) => translate(en,
    'Lisää laite',
    ...val),

  SQYY: (en: string, ...val: string[]) => translate(en,
    'Valitse ilmoituksen aihe',
    ...val),

  SRWY: (en: string, ...val: string[]) => translate(en,
    'Laiterekisteriohjelmat myös sujuvoittavat uusien laitteiden käyttöönottoa, koska osa käyttöönoton vaiheista jää automaattisesti pois. Käyttöönottokokemusta voi myös räätälöidä muokkaamalla ja piilottamalla käyttäjälle näkyviä käyttöönoton vaiheita.',
    ...val),

  SSBV: (en: string, ...val: string[]) => translate(en,
    'Elisan myymälät',
    ...val),

  SST5: (en: string, ...val: string[]) => translate(en,
    'Varattu',
    ...val),

  ST4J: (en: string, ...val: string[]) => translate(en,
    'Voit lisätä puhelin- tai nettiliittymäsi antamalla työpuhelinnumerosi ylläolevista napeista. Molemmat liittymät tulee lisätä erikseen. Mikäli et silti näe liittymääsi, tarkista että omat työyhteystietosi ovat oikein ',
    ...val),

  STA2: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän {} Ring-palvelut, alanumero {} ja yritysnumero {} säilytetään. Jotta voit käyttää Ring-liittymää ilman matkapuhelinta, sinun on otettava siihen ohjelmisto- tai IP-puhelin.',
    ...val),

  STLY: (en: string, ...val: string[]) => translate(en,
    'Sopimuksen alkupvm',
    ...val),

  STU7: (en: string, ...val: string[]) => translate(en,
    'Toimitustapa',
    ...val),

  STWG: (en: string, ...val: string[]) => translate(en,
    'Tilauksen käsittelyssä tapahtui ongelma. Soita asiakaspalveluumme {0} ({1}). Asiakaspalvelumme on avoinna {2}.',
    ...val),

  SU49: (en: string, ...val: string[]) => translate(en,
    'Siirry vanhaan OmaElisaan',
    ...val),

  SUZL: (en: string, ...val: string[]) => translate(en,
    'Luo tunnus tai palauta unohtunut salasana',
    ...val),

  SW0J: (en: string, ...val: string[]) => translate(en,
    'Sopimukset',
    ...val),

  SWA5: (en: string, ...val: string[]) => translate(en,
    'Maksettavaa yhteensä (sis. alv)',
    ...val),

  SX7R: (en: string, ...val: string[]) => translate(en,
    'Sopijayritystä ei voida vaihtaa, koska liittymällä oleva lisäpalvelu estää siirron. Jätä tarvittaessa [tukipyyntö].',
    ...val),

  SXJ5: (en: string, ...val: string[]) => translate(en,
    'Ei lunastuspyyntöjä',
    ...val),

  SXTT: (en: string, ...val: string[]) => translate(en,
    'Ring-liittymä',
    ...val),

  SY1D: (en: string, ...val: string[]) => translate(en,
    'Vastaanottaja',
    ...val),

  SYWR: (en: string, ...val: string[]) => translate(en,
    'Kampanjahinta',
    ...val),

  SYWS: (en: string, ...val: string[]) => translate(en,
    'Normaalihinta',
    ...val),

  SZ6N: (en: string, ...val: string[]) => translate(en,
    'Siirretty',
    ...val),

  SZBW: (en: string, ...val: string[]) => translate(en,
    'Virheellinen aika',
    ...val),

  T07E: (en: string, ...val: string[]) => translate(en,
    'Voimassa: ',
    ...val),

  T0FF: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin numeroiden sijainnit',
    ...val),

  T0KV: (en: string, ...val: string[]) => translate(en,
    'Aktivoinnin loppuvaiheessa puhelin voi mennä nukkumistilaan, jonka jälkeen puhelin kysyy uutta PIN-koodia 1234 (ei ole sama asia kuin puhelimen suojakoodi).',
    ...val),

  T1H4: (en: string, ...val: string[]) => translate(en,
    'Jos laitteesi on vahingoittunut, tee siitä vahinko- ja huoltoilmoitus. Toimitamme nopeasti korvaavan laitteen rikkoutuneen tilalle. Valitse ensin huollettava laite.',
    ...val),

  T1WX: (en: string, ...val: string[]) => translate(en,
    'Käyttäjätiedot',
    ...val),

  T29V: (en: string, ...val: string[]) => translate(en,
    'Muutostilauksen tekeminen onnistui',
    ...val),

  T2IH: (en: string, ...val: string[]) => translate(en,
    'Data',
    ...val),

  T3CO: (en: string, ...val: string[]) => translate(en,
    'Tuote on poistunut valikoimasta',
    ...val),

  T3N9: (en: string, ...val: string[]) => translate(en,
    'Päiväys',
    ...val),

  T425: (en: string, ...val: string[]) => translate(en,
    'Palvelun aktivoituminen voi kestää muutaman minuutin.',
    ...val),

  T4CE: (en: string, ...val: string[]) => translate(en,
    'Päivämäärä ei voi olla menneisyydessä',
    ...val),

  T4FT: (en: string, ...val: string[]) => translate(en,
    'Puheluerittely',
    ...val),

  T4MT: (en: string, ...val: string[]) => translate(en,
    'Laskun SMS-muistutuksen esto',
    ...val),

  T4OU: (en: string, ...val: string[]) => translate(en,
    'Tekninen virhe, yritä myöhemmin uudelleen.',
    ...val),

  T51P: (en: string, ...val: string[]) => translate(en,
    'Valitse tämä käyttäjä',
    ...val),

  T56E: (en: string, ...val: string[]) => translate(en,
    'Veloitetaan erikseen',
    ...val),

  T56J: (en: string, ...val: string[]) => translate(en,
    'Uusia muutoksia on mahdollista tehdä vasta, kun edellinen muutospyyntö on käsitelty. Huomaathan myös, että käsittelyssä oleva muutos tulee näkyviin vasta, kun muutospyyntö on käsitelty.',
    ...val),

  T57H: (en: string, ...val: string[]) => translate(en,
    'Pyydä raportti',
    ...val),

  T57I: (en: string, ...val: string[]) => translate(en,
    'Raportti',
    ...val),

  T57J: (en: string, ...val: string[]) => translate(en,
    'Luo uusi raportti',
    ...val),

  T57K: (en: string, ...val: string[]) => translate(en,
    'Luo raportti',
    ...val),

  T57L: (en: string, ...val: string[]) => translate(en,
    'Yritykset',
    ...val),

  T57M: (en: string, ...val: string[]) => translate(en,
    'Valmiit raportit',
    ...val),

  T57N: (en: string, ...val: string[]) => translate(en,
    'Ladattavissa 24 h',
    ...val),

  T57O: (en: string, ...val: string[]) => translate(en,
    'Raportin luonti onnistui',
    ...val),

  T57P: (en: string, ...val: string[]) => translate(en,
    'Raportin luonti epäonnistui',
    ...val),

  T57S: (en: string, ...val: string[]) => translate(en,
    'Raporttia luodaan...',
    ...val),

  T58Y: (en: string, ...val: string[]) => translate(en,
    'Sinua ei ole vielä yhdistetty mihinkään yritykseen. ',
    ...val),

  T6EF: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että muuttaessasi valintaa OmaElisasta, päivitetyt tiedot välitetään tämän jälkeen erikseen numeropalveluihin. Tietojen päivittyminen numeropalveluihin kestää joitakin arkipäiviä ja ne muuttuvat viiveellä.',
    ...val),

  T78Y: (en: string, ...val: string[]) => translate(en,
    'Toimitustiedot',
    ...val),

  T95T: (en: string, ...val: string[]) => translate(en,
    'Aktivoi',
    ...val),

  T9V1: (en: string, ...val: string[]) => translate(en,
    'Olen tarkistanut toimitusosoitteen',
    ...val),

  TA3J: (en: string, ...val: string[]) => translate(en,
    'Tunnistaudutaan',
    ...val),

  TA45: (en: string, ...val: string[]) => translate(en,
    ' muuta tuotetta',
    ...val),

  TAXY: (en: string, ...val: string[]) => translate(en,
    'Kaikki liittymät',
    ...val),

  TBCM: (en: string, ...val: string[]) => translate(en,
    'Tietosuoja',
    ...val),

  TBES: (en: string, ...val: string[]) => translate(en,
    'Tilauksen tuotteet',
    ...val),

  TBVG: (en: string, ...val: string[]) => translate(en,
    'Etsi tilausta...',
    ...val),

  TC0J: (en: string, ...val: string[]) => translate(en,
    'selvittää puheluiden ja nettiyhteyden ongelmia vianselvitystyökalulla',
    ...val),

  TC62: (en: string, ...val: string[]) => translate(en,
    'Vakion aika-asetukset',
    ...val),

  TCGR: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että myös käyttäjän linkitys yritykseen poistetaan ja tämän jälkeen hän ei voi nähdä kyseisen yrityksen yritysliittymiään tai laitteitaan Työntekijän OmaElisassa.',
    ...val),

  TCZK: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että sopimus näkyy aktiviisena sopimuslistalla kunnes olemme käsitelleet sen.',
    ...val),

  TE4L: (en: string, ...val: string[]) => translate(en,
    'Kertamaksut yhteensä',
    ...val),

  TEA3: (en: string, ...val: string[]) => translate(en,
    'Kaikki hinnat alv. 0 %',
    ...val),

  TER4: (en: string, ...val: string[]) => translate(en,
    'Paperilasku',
    ...val),

  TETZ: (en: string, ...val: string[]) => translate(en,
    'Elisa Puheratkaisu Vakio tuo matkapuhelimet luontevasti osaksi yrityksen puhelinjärjestelmää.',
    ...val),

  TEVT: (en: string, ...val: string[]) => translate(en,
    'Palvelu sisältää modeemin ja yhteyden asennuksen sekä toimivuuden testauksen.',
    ...val),

  TEXW: (en: string, ...val: string[]) => translate(en,
    'Liittymän käyttäjältä puuttuu henkilötietoja.',
    ...val),

  TEYY: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka ja viitenumero',
    ...val),

  TF3M: (en: string, ...val: string[]) => translate(en,
    'Puhelinnumero luovutetaan liittymän käyttäjälle',
    ...val),

  TF4R: (en: string, ...val: string[]) => translate(en,
    'Muutos astuu voimaan noin vuorokauden kuluessa. Tukipyynnön tila on nähtävillä viestikeskuksessa. Ilmoitus muutetuista sopimuksista tulee sekä viestikeskukseen että yhteyshenkilön sähköpostiin.',
    ...val),

  TF8Q: (en: string, ...val: string[]) => translate(en,
    'Näytä lisää vaihtoehtoja mobiiliverkon Netti Lite -liittymille',
    ...val),

  TFDQ: (en: string, ...val: string[]) => translate(en,
    'Luo uusi',
    ...val),

  TFV4: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoite tai tunnus',
    ...val),

  TFX1: (en: string, ...val: string[]) => translate(en,
    'Ei laitelistoja',
    ...val),

  TG07: (en: string, ...val: string[]) => translate(en,
    'Muutos',
    ...val),

  TGFE: (en: string, ...val: string[]) => translate(en,
    'OmaLasku Palvelunumerot -ominaisuudella voit poistaa työnantajan asettamat palveluestot, jolloin maksat itse palvelunumeroihin tehdyistä puheluista ja viesteistä.',
    ...val),

  TGPX: (en: string, ...val: string[]) => translate(en,
    'Jos kuitenkin jo näet liittymäsi työtekijäportaalissa, ota yhteyttä Elisan asiakaspalveluun.',
    ...val),

  TH83: (en: string, ...val: string[]) => translate(en,
    'Yrityksen maksuosuus',
    ...val),

  THKA: (en: string, ...val: string[]) => translate(en,
    'Laitteen lisääminen ei ole mahdollista',
    ...val),

  THL9: (en: string, ...val: string[]) => translate(en,
    'Tilauksella voi olla tuotteita vain yhdeltä laitelistalta.',
    ...val),

  THX7: (en: string, ...val: string[]) => translate(en,
    'Jos asiasi on kiireellinen, voit ottaa yhteyttä chatin kautta tai soittaa asiakaspalveluun. Kaikki yhteystiedot löydät ',
    ...val),

  TIWU: (en: string, ...val: string[]) => translate(en,
    'Hallinnoi palvelua',
    ...val),

  TIZ1: (en: string, ...val: string[]) => translate(en,
    '500 Mt aktivoituu automaattisesti kohdemaissa ja mahdollistaa mobiilipäätelaitteella tai kannettavalla tietokoneella käytettävän nopean tiedonsiirtoyhteyden edulliseen hintaan EU/ETA-alueen ulkopuolisissa maissa. Palvelussa ei ole kuukausimaksua, vaan hinnoittelu aktivoituu käytön mukaan 10,90 € / 1 vrk.',
    ...val),

  TJ3Q: (en: string, ...val: string[]) => translate(en,
    'Yhteensä verollinen',
    ...val),

  TJ54: (en: string, ...val: string[]) => translate(en,
    'Avoin',
    ...val),

  TJ9N: (en: string, ...val: string[]) => translate(en,
    'Laitesopimus päättyy ',
    ...val),

  TJKD: (en: string, ...val: string[]) => translate(en,
    'Tallenna luonnos',
    ...val),

  TJPP: (en: string, ...val: string[]) => translate(en,
    'Suositus',
    ...val),

  TJPQ: (en: string, ...val: string[]) => translate(en,
    'Tarjous',
    ...val),

  TJPR: (en: string, ...val: string[]) => translate(en,
    'Tarjoustuotteet',
    ...val),

  TLA6: (en: string, ...val: string[]) => translate(en,
    'Häiriötilanteessa voit tehdä nopeasti vianmäärityksen käytössäsi olevalle liittymälle.',
    ...val),

  TLCF: (en: string, ...val: string[]) => translate(en,
    'Hups… hakemaasi sivua ei löytynyt.',
    ...val),

  TM9L: (en: string, ...val: string[]) => translate(en,
    'Kuulokkeen ritilään tai mikrofonin aukkoon kertyy helposti likaa, joka aiheuttaa sen, että ääni ei kuulu kunnolla tai on vaimea. Nämä voi puhdistaa esimerkiksi teipillä, sinitarralla, pienellä harjalla, mikrokuituliinalla, paineilmapullolla tai hammastikulla. Puhdistamiseen voi käyttää lisäksi kännykän puhdistamiseen soveltuvaa nestettä',
    ...val),

  TMN1: (en: string, ...val: string[]) => translate(en,
    'Käyttöehdot',
    ...val),

  TO3U: (en: string, ...val: string[]) => translate(en,
    'tuo liittymäpakettiisi lomavastaajan, ilmoituksen vastaamattomasta puhelusta ja matkapuhelinvastaajan.',
    ...val),

  TO3V: (en: string, ...val: string[]) => translate(en,
    'Elisa Matkapuhelinvastaajan avulla sinulla on lomavastaaja, ilmoitus vastaamattomista puheluista ja matkapuhelinvastaaja kaikki samassa paketissa.',
    ...val),

  TOL1: (en: string, ...val: string[]) => translate(en,
    'Säilytetään numero',
    ...val),

  TPI4: (en: string, ...val: string[]) => translate(en,
    'Viestisi',
    ...val),

  TPQ5: (en: string, ...val: string[]) => translate(en,
    'Viimeinen mahdollinen irtisanomispäivä on 3 kk kuluttua nykyhetkestä',
    ...val),

  TPTZ: (en: string, ...val: string[]) => translate(en,
    'Tarkista tilaamasi tuotteet. Tämän jälkeen sinut siirretään takaisin työnantajasi järjestelmään, jossa voit viimeistellä ja vahvistaa tilauksen.',
    ...val),

  TPVQ: (en: string, ...val: string[]) => translate(en,
    'Laite',
    ...val),

  TQ6Z: (en: string, ...val: string[]) => translate(en,
    'Viankuvaus',
    ...val),

  TQFU: (en: string, ...val: string[]) => translate(en,
    'tai pyydä pääkäyttäjältäsi linkki päästäksesi kiinni palveluihinne.',
    ...val),

  TQLE: (en: string, ...val: string[]) => translate(en,
    'Kirjoita viesti',
    ...val),

  TQNV: (en: string, ...val: string[]) => translate(en,
    'Piilota erittely',
    ...val),

  TRE5: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaite',
    ...val),

  TRS1: (en: string, ...val: string[]) => translate(en,
    'on helppo ja toimiva nettiliittymä kotikonttoriin tai pieneen toimistoon. Netti Liten laadukas nettiyhteys tekee työarjestasi tehokasta ja sujuvaa. Palvelun mukana saat reitittimen, jolla yhdistät langattoman verkon tietokoneeseen, puhelimeen ja muihin kodin älylaitteisiin. Voit jakaa verkon usealle käyttäjälle ja mobiiliyhteydellä teet etätöitä missä haluat.',
    ...val),

  TS0L: (en: string, ...val: string[]) => translate(en,
    'Kenelle laite lunastetaan?',
    ...val),

  TSLW: (en: string, ...val: string[]) => translate(en,
    'Palveluun sovelletaan pääliittymää koskevia sopimusehtoja.',
    ...val),

  TTBU: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä on päättymässä oleva Elisa palvelupäätelaite sopimus. Valitse laite jolle haluat tehdä laitevaihdon tai valitse uusi sopimus.',
    ...val),

  TTFH: (en: string, ...val: string[]) => translate(en,
    'Liittymän nimi ja numero',
    ...val),

  TTKS: (en: string, ...val: string[]) => translate(en,
    'Haluan irtisanoa myös Ring-liittymän',
    ...val),

  TTM6: (en: string, ...val: string[]) => translate(en,
    'Jos sinulla on muuta kysyttävää, voit [lähettää viestin]. Jos asiasi on kiireinen, niin chatista saat nopeimman avun.',
    ...val),

  TTZ4: (en: string, ...val: string[]) => translate(en,
    'Esim. Varaston yhteiskäytössä oleva puhelin',
    ...val),

  TUZU: (en: string, ...val: string[]) => translate(en,
    'Asiakkaan laiterekisteröinnin alias',
    ...val),

  TVKW: (en: string, ...val: string[]) => translate(en,
    'Voit lisätä korkeintaan {} liitetiedostoa. (max. {} MB/liite)',
    ...val),

  TVWC: (en: string, ...val: string[]) => translate(en,
    'Sähköpostin voi lisätä ja sitä voi muokata myös myöhemmin OmaRing-palvelussa.',
    ...val),

  TWHW: (en: string, ...val: string[]) => translate(en,
    'Nouda noutopisteestä',
    ...val),

  TXTH: (en: string, ...val: string[]) => translate(en,
    'Tämä käyttäjä on Elisa Yritystietoturvan pääkäyttäjä. Jos muutat käyttäjän nimeä, matkapuhelinnumeroa tai sähköpostiosoitetta, myös Elisa Yritystietoturvan kirjautumistunnukset muuttuvat.',
    ...val),

  TXXM: (en: string, ...val: string[]) => translate(en,
    'eSIMiä tukeva laite',
    ...val),

  TYKY: (en: string, ...val: string[]) => translate(en,
    'Tietosuojan vuoksi on tärkeää, että henkilötiedot ovat oikein.',
    ...val),

  TYQZ: (en: string, ...val: string[]) => translate(en,
    'Olet aktivoimassa kaksivaiheisen tunnistautumisen, mikä vaatii jatkossa kirjautumisen yhteydessä tunnuksen ja salasanan lisäksi matkapuhelimeen tulevan kertakäyttöisen tunnistautumiskoodin.',
    ...val),

  TYVC: (en: string, ...val: string[]) => translate(en,
    'Sopijayritystä ei voida vaihtaa, koska:',
    ...val),

  TZBP: (en: string, ...val: string[]) => translate(en,
    'Käsitellään',
    ...val),

  TZNK: (en: string, ...val: string[]) => translate(en,
    'Jos työnantajasi pääkäyttäjä on tilannut laitteen puolestasi, ei laite näy laitelistassa. Laite tulee lisätä listaan erikseen, jotta voimme vahvistaa, että laite on käytössäsi.',
    ...val),

  TZYQ: (en: string, ...val: string[]) => translate(en,
    'Verkkolaskutustietojen päivitys',
    ...val),

  U0N3: (en: string, ...val: string[]) => translate(en,
    'Lasku laitteesta lähetetään työntekijälle',
    ...val),

  U17V: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelimet',
    ...val),

  U1IQ: (en: string, ...val: string[]) => translate(en,
    'Uudenveroinen',
    ...val),

  U1QE: (en: string, ...val: string[]) => translate(en,
    'Tarkista saatavuus',
    ...val),

  U1WD: (en: string, ...val: string[]) => translate(en,
    'Kuvaile lyhyesti laitteen vikaa ja miten se ilmeni',
    ...val),

  U21Y: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä ja numeron julkisuus',
    ...val),

  U2A0: (en: string, ...val: string[]) => translate(en,
    'Hyötyesto',
    ...val),

  U2AK: (en: string, ...val: string[]) => translate(en,
    'Yhteysvirhe',
    ...val),

  U2PF: (en: string, ...val: string[]) => translate(en,
    'Vaiheet',
    ...val),

  U373: (en: string, ...val: string[]) => translate(en,
    'Ring-asetukset',
    ...val),

  U3B8: (en: string, ...val: string[]) => translate(en,
    'Oletko varma, että haluat poistaa OmaLasku Palvelunumerot -ominaisuuden käytöstä? Huomaa, että jos maksuja on laskuttamatta, saat niistä vielä laskun.',
    ...val),

  U3TB: (en: string, ...val: string[]) => translate(en,
    'Olet poistamassa omat pääkäyttäjäoikeutesi ja sinut kirjataan ulos Yritysten OmaElisasta välittömästi. Toimintoa ei voida peruuttaa.',
    ...val),

  U3UL: (en: string, ...val: string[]) => translate(en,
    'Tietojen lähteet: Yhteyshenkilötiedot muodostuvat palveluiden käyttäjätiedoista, asiakaskohtaamisista (myynti, markkinointi, asiakaspalvelu), sekä Alma Media Finland Oy:n päättäjärekisterin tiedoista.',
    ...val),

  U4MA: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä',
    ...val),

  U54O: (en: string, ...val: string[]) => translate(en,
    'Gt/kk',
    ...val),

  U5N7: (en: string, ...val: string[]) => translate(en,
    'Koontilasku erittelyllä',
    ...val),

  U63I: (en: string, ...val: string[]) => translate(en,
    '.',
    ...val),

  U65A: (en: string, ...val: string[]) => translate(en,
    'Datavartija ei vaikuta paikallisten WiFi-yhteyksien kuten hotellien wlan-verkkojen käyttöön.',
    ...val),

  U6YJ: (en: string, ...val: string[]) => translate(en,
    'Toimitamme postitse liikuteltavan mobiilireitittimen määritettyyn osoitteeseen. Voit muuttaa toimitusosoitteen yllä.',
    ...val),

  U781: (en: string, ...val: string[]) => translate(en,
    '5G+ verkon hyödyt',
    ...val),

  U7F2: (en: string, ...val: string[]) => translate(en,
    'Työntekijän OmaElisa on palvelu, jossa voit hallinnoida omia työsuhdeliittymiäsi ja -laitteitasi. Voit muun muassa tilata työvälineet helposti työntekijän laitekaupasta, tarkastaa työliittymäsi PUK-koodin ja seurata liittymän käyttöä reaaliajassa.',
    ...val),

  U7HJ: (en: string, ...val: string[]) => translate(en,
    'Maksun vahvistaminen epäonnistui',
    ...val),

  U7MJ: (en: string, ...val: string[]) => translate(en,
    'Optimoituvalla puheella et maksa turhasta, vaan ainoastaan käytön mukaan. Raja-arvot puhelaskutuksessa ovat ',
    ...val),

  U7MK: (en: string, ...val: string[]) => translate(en,
    ' minuuttia.',
    ...val),

  U84N: (en: string, ...val: string[]) => translate(en,
    'Voit hoitaa asennuksen itse tai tilata asennuspalvelun Netti Lite tilauksen yhteydessä.',
    ...val),

  U8AN: (en: string, ...val: string[]) => translate(en,
    'sis.',
    ...val),

  U8IP: (en: string, ...val: string[]) => translate(en,
    'En lunasta tai palauta laitetta. Haluan uuden sopimuksen aikaisempien sopimusten lisäksi. Aikaisemmat sopimukset jatkuvat ennallaan sopimuskauden mukaisesti.',
    ...val),

  U92B: (en: string, ...val: string[]) => translate(en,
    'Turbonopeudella teet etätöitä sujuvammin, soitat videopuheluita kavereille ja katsot Netflix-maratoneja ilman katkoksia.',
    ...val),

  U9EI: (en: string, ...val: string[]) => translate(en,
    'Tilauksen vastaanottaja',
    ...val),

  UA9T: (en: string, ...val: string[]) => translate(en,
    'Muutospyynnön lähetys onnistui.',
    ...val),

  UAAP: (en: string, ...val: string[]) => translate(en,
    'Kassalle',
    ...val),

  UAD1: (en: string, ...val: string[]) => translate(en,
    'Estä puhelimen paikannus',
    ...val),

  UB77: (en: string, ...val: string[]) => translate(en,
    'Lisenssien hallintaportaaliin pääsy on estetty',
    ...val),

  UBBK: (en: string, ...val: string[]) => translate(en,
    'OmaElisa Työntekijöille',
    ...val),

  UC8X: (en: string, ...val: string[]) => translate(en,
    'Yritä uudelleen ja jos virhe toistuu, ota yhteyttä Elisan asiakaspalveluun.',
    ...val),

  UCAJ: (en: string, ...val: string[]) => translate(en,
    'Lähetä kertakäyttösalasana uudelleen',
    ...val),

  UCPM: (en: string, ...val: string[]) => translate(en,
    'Jos liittymälläsi on Mobiilivarmenne käytössä, se poistetaan eSIM-tilauksen yhteydessä.',
    ...val),

  UCRF: (en: string, ...val: string[]) => translate(en,
    'Tilauksen hyväksyjällä on oikeus hyväksyä ja hylätä tilauksia sekä lunastuspyyntöjä Työntekijän OmaElisassa. Tilauksen hyväksyjä ei saa pääsyä Yritysten OmaElisaan.',
    ...val),

  UDN6: (en: string, ...val: string[]) => translate(en,
    '{} kpl',
    ...val),

  UDSQ: (en: string, ...val: string[]) => translate(en,
    'Lunastettava laite',
    ...val),

  UE2G: (en: string, ...val: string[]) => translate(en,
    'Ostoskorissa on nyt {} tuotetta',
    ...val),

  UE8R: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka ei päivity automaattisesti käyttäjän palveluille. Tarkastathan kustannuspaikan tuotteen tai palvelun tiedoista.',
    ...val),

  UEHC: (en: string, ...val: string[]) => translate(en,
    'Tunnistautumisessa tapahtui virhe',
    ...val),

  UEMQ: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikkalasku erittelyillä',
    ...val),

  UEOZ: (en: string, ...val: string[]) => translate(en,
    'Kopioi',
    ...val),

  UF2D: (en: string, ...val: string[]) => translate(en,
    'Ei nettiliittymiä',
    ...val),

  UF3P: (en: string, ...val: string[]) => translate(en,
    'Peruutettu',
    ...val),

  UFGV: (en: string, ...val: string[]) => translate(en,
    'Suljettu',
    ...val),

  UFR3: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti maksun vahvistaminen ei onnistunut. Yritä uudelleen tai käytä jotain muuta maksutapaa.',
    ...val),

  UG6S: (en: string, ...val: string[]) => translate(en,
    'Saatavuuden tarkistamisessa tapahtui virhe. Yritä hetken hetken kuluttua uudelleen. Jos asiasi on kiireellinen, voit ottaa yhteyttä chatin kautta tai soittaa asiakaspalveluun {0} ({1}).',
    ...val),

  UG9B: (en: string, ...val: string[]) => translate(en,
    'Ring pääkäyttöoikeuden poistaminen epäonnistui',
    ...val),

  UGH3: (en: string, ...val: string[]) => translate(en,
    'Siirry vahvistamaan',
    ...val),

  UGPA: (en: string, ...val: string[]) => translate(en,
    'kk. Ilmoitetut hinnat alv. 0 %',
    ...val),

  UIV1: (en: string, ...val: string[]) => translate(en,
    'Onko laskusi loppusumma erilainen kuin yleensä?',
    ...val),

  UJ7S: (en: string, ...val: string[]) => translate(en,
    'Voit lisätä vain yhden alennuskoodin tilaukselle.',
    ...val),

  UJ8H: (en: string, ...val: string[]) => translate(en,
    'Jätä tarvittaessa [tukipyyntö].',
    ...val),

  UJRH: (en: string, ...val: string[]) => translate(en,
    'Toimi nopeasti, tuotetta jäljellä rajoitetusti verkkokaupan varastossa',
    ...val),

  UJRP: (en: string, ...val: string[]) => translate(en,
    'tuotetta',
    ...val),

  UKMC: (en: string, ...val: string[]) => translate(en,
    'Pankki- tai luottokortti',
    ...val),

  ULI0: (en: string, ...val: string[]) => translate(en,
    'Sopimusaika',
    ...val),

  ULM3: (en: string, ...val: string[]) => translate(en,
    'Täytä laskutusyhteyshenkilön tiedot',
    ...val),

  ULM4: (en: string, ...val: string[]) => translate(en,
    'Muista liittymän käyttäjän vaihtuessa irtisanoa myös liittymässä oleva käyttäjän henkilökohtainen vastaajapalvelu. Uudelle käyttäjälle tulee aktivoida oma henkilökohtainen vastaajapalvelu.',
    ...val),

  ULMQ: (en: string, ...val: string[]) => translate(en,
    'Päivämäärä ei ole kelvollinen',
    ...val),

  UM14: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä on siirretty toiseen yritykseen. Huomaa, että käyttäjätiedot poistuvat yrityksestä {}, kun siirto on käsitelty.',
    ...val),

  UM9T: (en: string, ...val: string[]) => translate(en,
    'Alanumerot',
    ...val),

  UMEE: (en: string, ...val: string[]) => translate(en,
    'Ei käyttäjiä',
    ...val),

  UNK4: (en: string, ...val: string[]) => translate(en,
    'Tila',
    ...val),

  UNYV: (en: string, ...val: string[]) => translate(en,
    'Huomiothan, että laitteen lunastaminen on sitova eikä muutos ole peruttavissa.',
    ...val),

  UORO: (en: string, ...val: string[]) => translate(en,
    'Valitse haluatko laitteet osatoimituksena vai kerralla.',
    ...val),

  UOYP: (en: string, ...val: string[]) => translate(en,
    'Estää puhelut esim. erilaisille keskustelulinjoille ja markkinointikampanjoihin sekä osallistumisen puhelinäänestyksiin ja TV-visailuihin. Sisältää eston viihdenumeroihin.',
    ...val),

  UP0J: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista (SIM-kortti)',
    ...val),

  UPE3: (en: string, ...val: string[]) => translate(en,
    'Ei rajoituksia',
    ...val),

  UQ4L: (en: string, ...val: string[]) => translate(en,
    'Yrityksesi on estänyt tämän maksullisen toiminnon. Tarvittaessa ota yhteyttä yrityksesi pääkäyttäjään.',
    ...val),

  UQDI: (en: string, ...val: string[]) => translate(en,
    'Kysy Oskulta',
    ...val),

  UQHZ: (en: string, ...val: string[]) => translate(en,
    'Maksetut',
    ...val),

  UQMA: (en: string, ...val: string[]) => translate(en,
    'Yritysten omat laitelistat löydät omalta laitelista-sivulta. Kopiointi ei ylikirjoita olemassa olevia listoja, joten poista tarvittaessa vanhat listat.',
    ...val),

  UQVM: (en: string, ...val: string[]) => translate(en,
    'Vastaaja',
    ...val),

  UR0I: (en: string, ...val: string[]) => translate(en,
    'Ladataan saatavuustietoja',
    ...val),

  UR1U: (en: string, ...val: string[]) => translate(en,
    'Yritysnumero tai alanumero ei ole saatavilla',
    ...val),

  URAB: (en: string, ...val: string[]) => translate(en,
    'Varmistamme henkilöllisyytesi tunnistautumisella.',
    ...val),

  URAQ: (en: string, ...val: string[]) => translate(en,
    'Valinta vaikuttaa millä kielellä saat mm. tilausvahvistukset sekä laskut',
    ...val),

  URFP: (en: string, ...val: string[]) => translate(en,
    'Valokuitu',
    ...val),

  US8M: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksella on ajastettu muutospyyntö',
    ...val),

  USHS: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä on jo olemassa',
    ...val),

  UT0X: (en: string, ...val: string[]) => translate(en,
    'Lähetä kutsu työntekijälle',
    ...val),

  UT8Z: (en: string, ...val: string[]) => translate(en,
    'Anna työsuhdepuhelinnumerosi, niin lähetämme sinulle vahvistuskoodin tekstiviestillä. Tällä varmistamme, mitä työsuhdeliittymiä tai -laitteita sinulla on käytössäsi.',
    ...val),

  UTC5: (en: string, ...val: string[]) => translate(en,
    'Valitse datapaketti',
    ...val),

  UTIR: (en: string, ...val: string[]) => translate(en,
    'Sopimus päättyy',
    ...val),

  UU5G: (en: string, ...val: string[]) => translate(en,
    'Valitse lisäpalvelut',
    ...val),

  UU5X: (en: string, ...val: string[]) => translate(en,
    'Olemme ratkaisseet tukipyynnön ja ratkaisu on nähtävillä tällä sivulla. Olemme myös lähettäneet kuittauksen tukipyynnön avanneen käyttäjän sähköpostiin.',
    ...val),

  UUO7: (en: string, ...val: string[]) => translate(en,
    'Paino',
    ...val),

  UV57: (en: string, ...val: string[]) => translate(en,
    'Sopimus ja lisäpalvelut',
    ...val),

  UV5C: (en: string, ...val: string[]) => translate(en,
    'Yhteystietoja puuttuu',
    ...val),

  UVCL: (en: string, ...val: string[]) => translate(en,
    'Ei tuotteita luettelossa.',
    ...val),

  UVFA: (en: string, ...val: string[]) => translate(en,
    'Käyttäjältä {} poistetaan Ring-palvelut. Alanumero {} ja yritysnumero {} vapautetaan.',
    ...val),

  UW4H: (en: string, ...val: string[]) => translate(en,
    'Laskun tyyppi',
    ...val),

  UXSJ: (en: string, ...val: string[]) => translate(en,
    'Nettiliittymän asennus',
    ...val),

  UYCL: (en: string, ...val: string[]) => translate(en,
    'siirron uudelle omistajalle.',
    ...val),

  UYL0: (en: string, ...val: string[]) => translate(en,
    'Nouto Matkahuollon noutopisteestä',
    ...val),

  UZ2G: (en: string, ...val: string[]) => translate(en,
    'Laitelistaa ei voitu kopioida kaikille yrityksille. Ole hyvä ja yritä uudelleen.',
    ...val),

  UZ3K: (en: string, ...val: string[]) => translate(en,
    'Muu vastaanottaja',
    ...val),

  UZ6X: (en: string, ...val: string[]) => translate(en,
    'alv.',
    ...val),

  UZFU: (en: string, ...val: string[]) => translate(en,
    ', mm. Saunalahden yritysasiakkaiden palvelut löytyvät sieltä.',
    ...val),

  UZOX: (en: string, ...val: string[]) => translate(en,
    'Yhteystiedot',
    ...val),

  V02H: (en: string, ...val: string[]) => translate(en,
    'Päivitys muuttaa käyttäjän kirjautumistunnuksen',
    ...val),

  V05V: (en: string, ...val: string[]) => translate(en,
    'Ring-käyttäjän vaihtaminen',
    ...val),

  V07Q: (en: string, ...val: string[]) => translate(en,
    'Oma Ring',
    ...val),

  V09R: (en: string, ...val: string[]) => translate(en,
    'Muutospyyntö kesken',
    ...val),

  V0VJ: (en: string, ...val: string[]) => translate(en,
    'Hupsis, jotain meni pieleen',
    ...val),

  V0XR: (en: string, ...val: string[]) => translate(en,
    'Elisa Yrityksille',
    ...val),

  V16Q: (en: string, ...val: string[]) => translate(en,
    'Valitse liittymä',
    ...val),

  V19M: (en: string, ...val: string[]) => translate(en,
    'Siirry laskutussopimukseen',
    ...val),

  V2BO: (en: string, ...val: string[]) => translate(en,
    '600 Mbit/s • 5000 min • 100 kpl',
    ...val),

  V2IT: (en: string, ...val: string[]) => translate(en,
    'Poista Ring liittymältä',
    ...val),

  V2S1: (en: string, ...val: string[]) => translate(en,
    'tulosta.',
    ...val),

  V2YZ: (en: string, ...val: string[]) => translate(en,
    'Tuotetta varastossa',
    ...val),

  V34H: (en: string, ...val: string[]) => translate(en,
    'Käytössä',
    ...val),

  V3KW: (en: string, ...val: string[]) => translate(en,
    'Tilaamiseen tarvitaan vain puhelinnumero, sähköpostiosoite sekä luottokortti tai MobilePay. Tilaussivulle ei tarvitse kirjautua erikseen. ',
    ...val),

  V3UP: (en: string, ...val: string[]) => translate(en,
    'Laitteiden sopimuskausi',
    ...val),

  V4GK: (en: string, ...val: string[]) => translate(en,
    'Haku',
    ...val),

  V549: (en: string, ...val: string[]) => translate(en,
    'Listaa ei ole julkaistu. Lista on käytettävissä vasta julkaisun jälkeen.',
    ...val),

  V59H: (en: string, ...val: string[]) => translate(en,
    'Lähetämme tähän numeroon SMS-ilmoituksen erääntyneestä laskusta.',
    ...val),

  V5EW: (en: string, ...val: string[]) => translate(en,
    'Koskeeko muutos kaikkia tämän Y-tunnuksen laskutussopimuksia',
    ...val),

  V5HK: (en: string, ...val: string[]) => translate(en,
    'Olethan yhteydessä asiakaspalveluun {0} ({1})',
    ...val),

  V62H: (en: string, ...val: string[]) => translate(en,
    'Listassa näet laskutussopimukset, joissa käyttäjä on laskutusyhteyshenkilönä.',
    ...val),

  V686: (en: string, ...val: string[]) => translate(en,
    'Laskun toimitusosoite',
    ...val),

  V6WM: (en: string, ...val: string[]) => translate(en,
    'Laskun tilan päivittyminen',
    ...val),

  V72N: (en: string, ...val: string[]) => translate(en,
    'Hinta',
    ...val),

  V7EA: (en: string, ...val: string[]) => translate(en,
    'Tekninen neuvonta ja käytönopastus',
    ...val),

  V7HT: (en: string, ...val: string[]) => translate(en,
    'Langattoman MESH-verkon asennus',
    ...val),

  V7I7: (en: string, ...val: string[]) => translate(en,
    'Kertamaksu, alv. {} %',
    ...val),

  V7KF: (en: string, ...val: string[]) => translate(en,
    'Lisää käyttäjä',
    ...val),

  V972: (en: string, ...val: string[]) => translate(en,
    'Alanumero',
    ...val),

  V97L: (en: string, ...val: string[]) => translate(en,
    'numerosi on julkinen ja sen tiedot löytyvät numeropalveluista.',
    ...val),

  V996: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että jos yhteys tilataan muualle kuin kotiin, niin asennuspalvelu on suositeltava.',
    ...val),

  V9HK: (en: string, ...val: string[]) => translate(en,
    'Valitse tuote',
    ...val),

  V9K9: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelinnumero (työ)',
    ...val),

  V9OQ: (en: string, ...val: string[]) => translate(en,
    'Tietolähde Patentti- ja rekisterihallitus / YTJ, tiedonvälittäjä Alma Media Finland Oy',
    ...val),

  VA1O: (en: string, ...val: string[]) => translate(en,
    'Ei tietueita',
    ...val),

  VA44: (en: string, ...val: string[]) => translate(en,
    'Löytynyt käyttäjä',
    ...val),

  VAJN: (en: string, ...val: string[]) => translate(en,
    'Väliaikainen sulku kannattaa tehdä esimerkiksi silloin, jos laite on kadonnut ja haluat estää sen väärinkäyttöä.',
    ...val),

  VAS5: (en: string, ...val: string[]) => translate(en,
    'Päivitä liittymätyyppiä',
    ...val),

  VB8M: (en: string, ...val: string[]) => translate(en,
    'Säännölliseen työntekoon ja aktiiviseen vapaa-ajan surffailuun',
    ...val),

  VBQK: (en: string, ...val: string[]) => translate(en,
    'Elisa Oyj ei hallinnoi yrityksesi laitelistojen valikoimaa. Jos sinulla on kysyttävää laitevalikoimaan liittyen, ota yhteyttä työnantajasi OmaElisa-pääkäyttäjään.',
    ...val),

  VBS1: (en: string, ...val: string[]) => translate(en,
    'Ladataan tuotetietoja',
    ...val),

  VC3L: (en: string, ...val: string[]) => translate(en,
    'Saatavuus:',
    ...val),

  VCUZ: (en: string, ...val: string[]) => translate(en,
    'OmaElisa yrityksille',
    ...val),

  VCX1: (en: string, ...val: string[]) => translate(en,
    'Julkinen numero',
    ...val),

  VDB4: (en: string, ...val: string[]) => translate(en,
    'Yhteenveto',
    ...val),

  VDE4: (en: string, ...val: string[]) => translate(en,
    'Tunnuksena toimii sähköpostiosoitteesi.',
    ...val),

  VDQI: (en: string, ...val: string[]) => translate(en,
    'Etkö näe käytössäsi olevaa laitetta? ',
    ...val),

  VEGK: (en: string, ...val: string[]) => translate(en,
    'Määräaikaisuus päättyy',
    ...val),

  VF8X: (en: string, ...val: string[]) => translate(en,
    'Kyllä: uudet tiedot tulevat voimaan ensimmäisestä mahdollisesta laskusta alkaen. Huomioithan, että muutos ei vaikuta jo muodostuneisiin laskuihin.\n\nEi: voit ilmoittaa muuttuvat laskutustiedot etukäteen ja ne tulevat voimaan valitsemanasi päivänä.',
    ...val),

  VFZ2: (en: string, ...val: string[]) => translate(en,
    'Muutokset tallennettu',
    ...val),

  VG7P: (en: string, ...val: string[]) => translate(en,
    'Puh. ',
    ...val),

  VGFF: (en: string, ...val: string[]) => translate(en,
    'Tyyppi',
    ...val),

  VGFI: (en: string, ...val: string[]) => translate(en,
    'Nimi',
    ...val),

  VH3U: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen yhteyshenkilö, jolle viestitään laskuihin liittyvistä asioista, myös maksamattomista laskuista. Ei tulostu laskulle.',
    ...val),

  VH59: (en: string, ...val: string[]) => translate(en,
    'Palaa',
    ...val),

  VH8G: (en: string, ...val: string[]) => translate(en,
    'Liittymä ei ole liitetty yrityksesi Vakio-ratkaisuun',
    ...val),

  VHFX: (en: string, ...val: string[]) => translate(en,
    'Avaaja',
    ...val),

  VI0P: (en: string, ...val: string[]) => translate(en,
    'Laajakaista',
    ...val),

  VIZE: (en: string, ...val: string[]) => translate(en,
    'Siirry aktivoimaan',
    ...val),

  VJBG: (en: string, ...val: string[]) => translate(en,
    'Liittymälläsi on Elisa Mobiiliturva yrityksille -palvelu, joka estää palvelun käyttöönoton.',
    ...val),

  VJCA: (en: string, ...val: string[]) => translate(en,
    'Työajan ulkopuolella (manuaalinen)',
    ...val),

  VJI9: (en: string, ...val: string[]) => translate(en,
    'Huomioithan että kaikki työntekijät, joilla on yrityksesi hankkima Elisan mobiililiittymä käytössä, voivat ottaa Elisa Mobiilivarmenne -palvelun käyttöönsä ilman erillistä hyväksymistä.',
    ...val),

  VJK6: (en: string, ...val: string[]) => translate(en,
    'Lisätty ostoskoriin',
    ...val),

  VJMT: (en: string, ...val: string[]) => translate(en,
    'Laitesopimuksesi päättyy {}',
    ...val),

  VJNB: (en: string, ...val: string[]) => translate(en,
    'Lunastetaanko laite yritykselle vai työntekijälle?',
    ...val),

  VJYS: (en: string, ...val: string[]) => translate(en,
    'Tiedostossa voi olla enintään 500 sähköpostiosoitetta.',
    ...val),

  VKFM: (en: string, ...val: string[]) => translate(en,
    'Lisää',
    ...val),

  VKJ4: (en: string, ...val: string[]) => translate(en,
    'Palauttamattomasta laitteesta veloitetaan palautusrikemaksu.',
    ...val),

  VKRZ: (en: string, ...val: string[]) => translate(en,
    'Vähintään 8 merkkiä pitkä',
    ...val),

  VLDA: (en: string, ...val: string[]) => translate(en,
    'Valmistajan mukaan',
    ...val),

  VLOS: (en: string, ...val: string[]) => translate(en,
    'Kirjautuminen on uudistunut.',
    ...val),

  VLQ3: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin numero',
    ...val),

  VLSP: (en: string, ...val: string[]) => translate(en,
    'Tietojen päivitys onnistui.',
    ...val),

  VLZR: (en: string, ...val: string[]) => translate(en,
    'Jatka ostoksia',
    ...val),

  VME0: (en: string, ...val: string[]) => translate(en,
    'laskuja',
    ...val),

  VMNS: (en: string, ...val: string[]) => translate(en,
    'Estää puhelut esim. lento- ja laivayhtiöiden asiakaspalvelunumeroihin sekä osallistumisen mielipidemittauksiin ja puhelinlahjoituksiin. Estää myös tilauspalveluiden käytön (esim. juoma- tai elintarvikeautomaatit) sekä pizza- ja matkalipputilaukset. Sisältää eston viihde- ja ajanvietenumeroihin.',
    ...val),

  VMT9: (en: string, ...val: string[]) => translate(en,
    'Liittymää ei voida avata maksamattomien laskujen vuoksi. Tarkistathan laskujen tilanteen.',
    ...val),

  VNWG: (en: string, ...val: string[]) => translate(en,
    'Estää mobiilidatan ja multimediaviestien käytön ulkomailla.',
    ...val),

  VO2Q: (en: string, ...val: string[]) => translate(en,
    'Palvelu asettaa matkapuhelinliittymälle 50 euron (alv. 0 %) saldorajan ulkomailla tapahtuvaan roaming-datasiirtoon. Palvelu ilmoittaa tekstiviestillä saldon täyttymisestä. Voit muuttaa rajaa (50–5000 €) tekstiviestillä milloin vain, ja tilattu raja on voimassa siitä eteenpäin.',
    ...val),

  VOF3: (en: string, ...val: string[]) => translate(en,
    'Toimitus yrityksen toimipisteeseen',
    ...val),

  VOR4: (en: string, ...val: string[]) => translate(en,
    'Liittymäsi on nyt suljettu väliaikaisesti.',
    ...val),

  VOV7: (en: string, ...val: string[]) => translate(en,
    'Evästeasetukset',
    ...val),

  VOW9: (en: string, ...val: string[]) => translate(en,
    'Nouto seuraavana työpäivänä postista klo 16 jälkeen. Paketista lähetetään saapumisilmoitus',
    ...val),

  VP3V: (en: string, ...val: string[]) => translate(en,
    'Lisää matkapuhelinnumero kontaktitietoihisi',
    ...val),

  VP3X: (en: string, ...val: string[]) => translate(en,
    'Yritykselläsi on kaksivaiheinen tunnistautuminen käytössä, mutta emme löytäneet yhteystiedoistasi puhelinnumeroa. Ole hyvä ja ota yhteyttä asiakaspalveluun puhelinnumeron lisäämiseksi yhteystietoihisi.',
    ...val),

  VP3Y: (en: string, ...val: string[]) => translate(en,
    'Yhteystiedoista puuttuu matkapuhelinnumero, ota yhteyttä asiakaspalveluun',
    ...val),

  VPJ3: (en: string, ...val: string[]) => translate(en,
    'Siirrä nykyinen puhelinnumero',
    ...val),

  VPVR: (en: string, ...val: string[]) => translate(en,
    'Kenttä tarvitsee arvon',
    ...val),

  VQ6O: (en: string, ...val: string[]) => translate(en,
    'Aloita tilaus alusta',
    ...val),

  VQE6: (en: string, ...val: string[]) => translate(en,
    'Hae',
    ...val),

  VQKT: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyyntö on hyväksyttävä erikseen',
    ...val),

  VQOB: (en: string, ...val: string[]) => translate(en,
    'ei sopimushintaa',
    ...val),

  VQU7: (en: string, ...val: string[]) => translate(en,
    'Kirjoita muutostoiveesi tähän...',
    ...val),

  VQXC: (en: string, ...val: string[]) => translate(en,
    'Maksusopimuksen tilaus maksimissaan 30 päiväksi.\n\nOlemme ottaneet maksusopimuspyyntösi käsittelyyn. Tarkistamme tilanteesi ja saat päätöksen eräpäivän siirrosta ja uuden maksupäivän sähköpostiisi 5 arkipäivän kuluessa. Uusi maksupäivä ei näy Yritysten OmaElisassa. Jos et saa vahvistusviestiä 5 arkipäivän jälkeen, olethan yhteydessä Yritysasiakaspalveluumme.\n\nMaksusopimuksen tekemisestä veloitetaan hinnaston mukainen maksu seuraavassa laskussa.',
    ...val),

  VR6E: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää ei voida poistaa, koska käyttäjällä on aktiivisia sopimuksia tai käyttäjä on laskutussopimuksella yhteyshenkilönä tai vastaanottajana.',
    ...val),

  VRG9: (en: string, ...val: string[]) => translate(en,
    'Etsi yrityksen nimellä tai Y-tunnuksella',
    ...val),

  VRMK: (en: string, ...val: string[]) => translate(en,
    'Estää soittamisen esim. aikataulu-, tiedotus- ja pankkipalveluihin. Estää myös soittamisen taksikeskukseen ja numerotiedusteluihin sekä numerotiedustelujen yhdistämispalveluiden käytön. Sisältää eston viihde-, ajanviete- ja asiointinumeroihin.',
    ...val),

  VRVV: (en: string, ...val: string[]) => translate(en,
    'Tukipyyntösi on lähetetty',
    ...val),

  VS0Z: (en: string, ...val: string[]) => translate(en,
    'Yritykselle',
    ...val),

  VSE5: (en: string, ...val: string[]) => translate(en,
    'Datapaketti',
    ...val),

  VSRK: (en: string, ...val: string[]) => translate(en,
    'liittymän käyttö EU- ja ETA-maissa',
    ...val),

  VT06: (en: string, ...val: string[]) => translate(en,
    'Poista muutospyyntö',
    ...val),

  VT5Z: (en: string, ...val: string[]) => translate(en,
    'Oletko käyttänyt puhelintasi ulkomailla?',
    ...val),

  VU38: (en: string, ...val: string[]) => translate(en,
    'Muut palvelut',
    ...val),

  VUU0: (en: string, ...val: string[]) => translate(en,
    'Tuki',
    ...val),

  VV3T: (en: string, ...val: string[]) => translate(en,
    'Vastaajan kieli',
    ...val),

  VVDT: (en: string, ...val: string[]) => translate(en,
    'Raportti sisältää yrityksen ostamat kertamaksulliset ja kuukausimaksulliset laitteet sekä lisätarvikkeet.',
    ...val),

  VVQP: (en: string, ...val: string[]) => translate(en,
    'Ostoskoriin',
    ...val),

  VWKR: (en: string, ...val: string[]) => translate(en,
    'Ilmoittaja',
    ...val),

  VWNX: (en: string, ...val: string[]) => translate(en,
    'Tuotteeni ja palveluni',
    ...val),

  VWSV: (en: string, ...val: string[]) => translate(en,
    'Omavastuu',
    ...val),

  VX6E: (en: string, ...val: string[]) => translate(en,
    'Siirtyvät sopimukset',
    ...val),

  VXHX: (en: string, ...val: string[]) => translate(en,
    'Postin lähetystunnus',
    ...val),

  VXVC: (en: string, ...val: string[]) => translate(en,
    'OVT tunnus',
    ...val),

  VXXY: (en: string, ...val: string[]) => translate(en,
    'Ei oikeuksia',
    ...val),

  VY6Q: (en: string, ...val: string[]) => translate(en,
    'vko',
    ...val),

  VYFI: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisan pääkäyttäjien lisääminen ja poistaminen tapahtuu vielä toistaiseksi OmaElisa Classicissa. Yritysten OmaElisa -tunnuksilla voit kirjautua OmaElisa Classiciin, Yritysten OmaElisaan sekä joihinkin Elisa Ringin palveluihin, joihin kuuluvat mm. web-admin ja tallennearkisto.',
    ...val),

  VYGS: (en: string, ...val: string[]) => translate(en,
    'Laite on jatkokaudella. Laitekaupasta voit tilata uuden laitteen ja halutessasi myös lunastaa tämän vanhan laitteen. Mikäli laitevaihtoa ei tehdä tai sopimusta päätetä jatkokauden aikana, laite siirtyy automaattisesti yrityksen omaisuudeksi.',
    ...val),

  VYIS: (en: string, ...val: string[]) => translate(en,
    'Tilaajan tulee varmistaa tietojen oikeellisuus tilausvaiheessa. Mikäli tilaus on käyttäjän virheestä johtuva, veloitusta ei palauteta.',
    ...val),

  VYZS: (en: string, ...val: string[]) => translate(en,
    'Yhteyshenkilö',
    ...val),

  VZZW: (en: string, ...val: string[]) => translate(en,
    'Käsittelyssä',
    ...val),

  W18C: (en: string, ...val: string[]) => translate(en,
    'Lisää laitteesi',
    ...val),

  W1D4: (en: string, ...val: string[]) => translate(en,
    'Kortin tilaaminen on ilmainen. Kun SIM-kortti aktivoidaan käyttöön, veloitamme',
    ...val),

  W1PP: (en: string, ...val: string[]) => translate(en,
    'Vastaanottajan sähköposti',
    ...val),

  W1Q4: (en: string, ...val: string[]) => translate(en,
    'Matkapuhelinnumero',
    ...val),

  W1RX: (en: string, ...val: string[]) => translate(en,
    'Kokonaishinta {}',
    ...val),

  W1ZW: (en: string, ...val: string[]) => translate(en,
    'Tilauksen tuotteiden laskutustiedot',
    ...val),

  W2NJ: (en: string, ...val: string[]) => translate(en,
    'Tilaa uusi laite',
    ...val),

  W2ZR: (en: string, ...val: string[]) => translate(en,
    'nettisivuiltamme',
    ...val),

  W3LI: (en: string, ...val: string[]) => translate(en,
    'Kuljetus toimitusosoitteeseen. Perillä seuraavana työpäivänä klo 9 mennessä',
    ...val),

  W3P0: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä ei ole nettiliittymiä.',
    ...val),

  W447: (en: string, ...val: string[]) => translate(en,
    'Valittu yritys:',
    ...val),

  W4CZ: (en: string, ...val: string[]) => translate(en,
    'Luo uusi laskutussopimus',
    ...val),

  W4JJ: (en: string, ...val: string[]) => translate(en,
    'Säännölliseen työntekoon ja vapaa-ajan viihteeseen.',
    ...val),

  W4NW: (en: string, ...val: string[]) => translate(en,
    'Aseta ajan mukaan',
    ...val),

  W4RA: (en: string, ...val: string[]) => translate(en,
    'ongelma liittymän lisäämisessä',
    ...val),

  W52I: (en: string, ...val: string[]) => translate(en,
    'Haluatko helpompaa asiointia?',
    ...val),

  W547: (en: string, ...val: string[]) => translate(en,
    'tiedot',
    ...val),

  W59S: (en: string, ...val: string[]) => translate(en,
    'Toimituksessa',
    ...val),

  W5JG: (en: string, ...val: string[]) => translate(en,
    'Lisää tai poista laitteita',
    ...val),

  W691: (en: string, ...val: string[]) => translate(en,
    'Tuotteet sopimuksella',
    ...val),

  W692: (en: string, ...val: string[]) => translate(en,
    'Vahvista laitteen lunastuspyynnön hylkääminen',
    ...val),

  W6DS: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että tämä laskutussopimus poistetaan {}, jos sitä ennen sille ei tilata palveluita tai tuotteita.',
    ...val),

  W6MP: (en: string, ...val: string[]) => translate(en,
    'Lisäinfoa OmaElisasta',
    ...val),

  W77L: (en: string, ...val: string[]) => translate(en,
    'Ohjeet ja vinkit yritysten arkeen',
    ...val),

  W77N: (en: string, ...val: string[]) => translate(en,
    'Tilaa uutiskirjeemme ajankohtaisista asioista.',
    ...val),

  W7H8: (en: string, ...val: string[]) => translate(en,
    'Ota tarvittaessa yhteyttä [asiakaspalveluumme].',
    ...val),

  W8I2: (en: string, ...val: string[]) => translate(en,
    'Lisätarvikkeidan haku epäonnistui',
    ...val),

  W8I6: (en: string, ...val: string[]) => translate(en,
    'Muualla EU:ssa',
    ...val),

  W9UE: (en: string, ...val: string[]) => translate(en,
    'Lisää nettiliittymä',
    ...val),

  WA19: (en: string, ...val: string[]) => translate(en,
    'Vakio-ratkaisu:',
    ...val),

  WA1M: (en: string, ...val: string[]) => translate(en,
    'Yhteyshenkilöt',
    ...val),

  WAEN: (en: string, ...val: string[]) => translate(en,
    'Laitteiden sopimuskaudet on määritelty ennalta. Ilmoitetuissa hinnoissa on mukana alv. {} %.',
    ...val),

  WAIL: (en: string, ...val: string[]) => translate(en,
    'Valitse pankki',
    ...val),

  WAMK: (en: string, ...val: string[]) => translate(en,
    'Käsittelemme irtisanomispyyntösi mahdollisimman pian. Saat vahvistuksen sähköpostilla, kun palvelu on irtisanottu. Huomaathan, että jos kuukausimaksuja on vielä laskuttamatta, ne lisätään kyseisen liittymän seuraavalle laskulle.',
    ...val),

  WBKW: (en: string, ...val: string[]) => translate(en,
    'Tarkasta, että syöttämäsi tiedot ovat oikein ja valitse tarvittaessa oikeat yhteystiedot alapuolelta.',
    ...val),

  WBNM: (en: string, ...val: string[]) => translate(en,
    'Siirrä suuria tiedostoja ja jaa netti usealle hengelle',
    ...val),

  WBQT: (en: string, ...val: string[]) => translate(en,
    'Kortin aktivoinnissa tapahtui virhe',
    ...val),

  WBV9: (en: string, ...val: string[]) => translate(en,
    'Vaatii erillisen laitteen (tabletti, tietokone, reititin)',
    ...val),

  WCAQ: (en: string, ...val: string[]) => translate(en,
    'Liittymätarjoukset voimassa 2.1. - 22.1.2025.',
    ...val),

  WD0I: (en: string, ...val: string[]) => translate(en,
    'Varmista että sinulla on oikea SIM -kortin numero ja mikäli ongelma toistuu, ole yhteydessä asiakaspalveluumme.',
    ...val),

  WDIX: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu sisään Elisa Tunnuksellasi, jotta pääset siirtymään kassalle. Kirjautumalla saat myös käyttöösi sinulle räätälöityjä palveluja ja etuja. Jos et ole vielä luonut tunnuksia Yritysten OmaElisaan, tee se nyt.',
    ...val),

  WDX0: (en: string, ...val: string[]) => translate(en,
    '1 tuote lisättiin ostoskoriin',
    ...val),

  WE32: (en: string, ...val: string[]) => translate(en,
    'Seuraa laitteen antamia ohjeita.',
    ...val),

  WENR: (en: string, ...val: string[]) => translate(en,
    'Viimeisimmät tilauksesi',
    ...val),

  WFEF: (en: string, ...val: string[]) => translate(en,
    'Tarkistetaan alennuskoodia {}',
    ...val),

  WFXG: (en: string, ...val: string[]) => translate(en,
    'Tilaa Elisa Mobiilivarmenne Työntekijälle -palvelu',
    ...val),

  WFZ0: (en: string, ...val: string[]) => translate(en,
    'Lisää liite',
    ...val),

  WG63: (en: string, ...val: string[]) => translate(en,
    'Odottaa huomiotasi',
    ...val),

  WG7P: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisa-tunnus luotu onnistuneesti.',
    ...val),

  WG8A: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimus tai asiakasnumero on jo annettu',
    ...val),

  WGE3: (en: string, ...val: string[]) => translate(en,
    'Verkkotunnukset',
    ...val),

  WGGQ: (en: string, ...val: string[]) => translate(en,
    'Henkilönumero',
    ...val),

  WGM5: (en: string, ...val: string[]) => translate(en,
    'Vahvista laitelistan poistaminen',
    ...val),

  WGXF: (en: string, ...val: string[]) => translate(en,
    'Huomaathan, että laskun tila päivittyy n. 3 arkipäivän aikana, joten maksettu lasku voi näkyä hetken avoimena. Myöhässä maksetusta laskusta ei tarvitse ilmoittaa asiakaspalveluumme.',
    ...val),

  WH9D: (en: string, ...val: string[]) => translate(en,
    'Kustannuspaikka',
    ...val),

  WHAA: (en: string, ...val: string[]) => translate(en,
    'Huomioitavaa työnantajasi pääkäyttäjältä.',
    ...val),

  WHE6: (en: string, ...val: string[]) => translate(en,
    'Uusi kortti aktivoituu välittömästi muutoksen jälkeen.',
    ...val),

  WHGU: (en: string, ...val: string[]) => translate(en,
    'Pohjola ja Baltian maat: Ruotsi, Norja, Tanska, Viro, Latvia ja Liettua. Yritysdata EU-liittymissä käyttörajan ylittävä data hinnoitellaan',
    ...val),

  WHH4: (en: string, ...val: string[]) => translate(en,
    'PUK2-koodi',
    ...val),

  WHHI: (en: string, ...val: string[]) => translate(en,
    'Valitse alta toimitusosoite johon toimitamme palautuspussin.',
    ...val),

  WHKI: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimuksen numero puuttuu',
    ...val),

  WI85: (en: string, ...val: string[]) => translate(en,
    'Saat luotua',
    ...val),

  WIMV: (en: string, ...val: string[]) => translate(en,
    'Valittu yhteyshenkilö ei kelpaa, tarkista valinta.',
    ...val),

  WIPE: (en: string, ...val: string[]) => translate(en,
    'Ma-Pe 8-16',
    ...val),

  WISG: (en: string, ...val: string[]) => translate(en,
    'Muutoksen aktivointi kestää muutaman minuutin.',
    ...val),

  WJN7: (en: string, ...val: string[]) => translate(en,
    'Näytettävä numero vaaditaan kun näytettävän numeron muunnos on käytössä.',
    ...val),

  WKIN: (en: string, ...val: string[]) => translate(en,
    'Valitulla yrityksellä ei ole vastaavaa Elisa Palvelupäätelaite -sopimusta',
    ...val),

  WL1E: (en: string, ...val: string[]) => translate(en,
    'Kyllä: muutos tehdään kaikille Elisan laskutussopimuksille, joissa Y-tunnus on maksajana. Myös Elisan kuluttajapuolella Y-tunnuksella olevat sopimukset huomioidaan.\n\nEi: muutos koskee vain ilmoittamiasi laskutussopimuksia.',
    ...val),

  WL8J: (en: string, ...val: string[]) => translate(en,
    'Yritysnumero',
    ...val),

  WLCJ: (en: string, ...val: string[]) => translate(en,
    'Näytä kaikki liittymät',
    ...val),

  WLNI: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjän nimi',
    ...val),

  WLQ1: (en: string, ...val: string[]) => translate(en,
    'Haluatko korvata asennusosoitteessa olevan kiinteän laajakaistan?',
    ...val),

  WLWK: (en: string, ...val: string[]) => translate(en,
    'Huomioithan, että liittymän luovutus käyttäjälle koskee vain siirtoa Elisan yritysliittymästä Elisan kuluttajaliittymään.',
    ...val),

  WMAS: (en: string, ...val: string[]) => translate(en,
    'Numeron',
    ...val),

  WMWY: (en: string, ...val: string[]) => translate(en,
    'Olet vaihtamassa käyttäjää laitteelle, jolla on käytössä työntekijän Oma Laitelasku. Työntekijän jäljellä olevat kuukausimaksut laskutetaan yritykseltä.',
    ...val),

  WN51: (en: string, ...val: string[]) => translate(en,
    'Jos sen sijaan olet yrityksesi pääkäyttäjä, kirjaudu ulos ja uudelleenkirjaudu yritysten OmaElisaan.',
    ...val),

  WNEF: (en: string, ...val: string[]) => translate(en,
    'Sis. 5 Gt datan käyttöä kotimaassa. Paketin ylimenevä datankäyttö 4,50 € / alkava 5 Gt.',
    ...val),

  WNUM: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää ei voi poistaa',
    ...val),

  WO28: (en: string, ...val: string[]) => translate(en,
    'Olen tarkistanut että tilaus- ja laskutustiedot ovat oikein.',
    ...val),

  WOWC: (en: string, ...val: string[]) => translate(en,
    'Alkaa',
    ...val),

  WOYD: (en: string, ...val: string[]) => translate(en,
    'Sulje',
    ...val),

  WP79: (en: string, ...val: string[]) => translate(en,
    'Liittymältäsi puuttuu Elisa WiFi puhelu -palvelu, mikä on edellytys palvelun käyttöönotolle.',
    ...val),

  WP9V: (en: string, ...val: string[]) => translate(en,
    'Esimerkkejä rajoista:',
    ...val),

  WPEL: (en: string, ...val: string[]) => translate(en,
    'Hae verkkotunnusta',
    ...val),

  WPOQ: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys jatkaaksesi',
    ...val),

  WPXQ: (en: string, ...val: string[]) => translate(en,
    'Valitse käyttäjä tai käyttötarkoitus',
    ...val),

  WPZI: (en: string, ...val: string[]) => translate(en,
    'Ostoskorissa on 1 tuote',
    ...val),

  WQ2D: (en: string, ...val: string[]) => translate(en,
    'Suosittelemme kiinteän verkon Netti Lite -liittymää ensisijaisena vaihtoehtona. Kiinteällä liittymällä saat mahdollisimman vakaan ja toimintavarman nettiyhteyden sujuvaan etätyöhön, videoneuvotteluihin ja suoratoistopalveluihin.',
    ...val),

  WQ2E: (en: string, ...val: string[]) => translate(en,
    'Suosittelemme Mobiililaajakaista Netti Lite 5G+ -liittymää, jos haluat nopean netin, mutta osoitteeseesi ei ole kiinteää yhteyttä saatavilla. Mobiililaajakaista kulkee mukanasi, jos teet töitä useammassa eri osoitteessa. Saat sen mukaasi vaikka mökille.',
    ...val),

  WQ7L: (en: string, ...val: string[]) => translate(en,
    'Vahvista laitteen lunastaminen',
    ...val),

  WQCL: (en: string, ...val: string[]) => translate(en,
    'Tämä kutsulinkki on vanhentunut tai väärä. Ole hyvä ja käytä oikeaa linkkiä.',
    ...val),

  WQRC: (en: string, ...val: string[]) => translate(en,
    'Palveluun kuuluu 2Gt datakäyttöä kohdemaihin ja se on voimassa 14 vrk ajan tai kunnes 2Gt käyttö on täynnä.',
    ...val),

  WR9Q: (en: string, ...val: string[]) => translate(en,
    'Hei {}!',
    ...val),

  WSA8: (en: string, ...val: string[]) => translate(en,
    'Elisa Yritystietoturvan tietojen palautus ja puhelintuki',
    ...val),

  WSO1: (en: string, ...val: string[]) => translate(en,
    'Mobiililaajakaista yrityksille',
    ...val),

  WT3N: (en: string, ...val: string[]) => translate(en,
    'Elisa YritysWIFI Lite -palvelulla toimiva ja entistäkin nopeampi netti toimipisteesi jokaiseen huoneeseen vaivattomalla kuukausimaksusopimuksella.',
    ...val),

  WT42: (en: string, ...val: string[]) => translate(en,
    'Muuta sopimusten käyttäjätiedot ja/tai laskutussopimusten yhteyshenkilötiedot.',
    ...val),

  WTML: (en: string, ...val: string[]) => translate(en,
    'Emme pysty varmentamaan, että sinulla on yrityksen nimenkirjoitusoikeus. Tarkista, että Y-tunnus on rekisteröity kaupparekisteriin. Voit myös ottaa yhteyttä asiakaspalveluumme numeroon {0}.',
    ...val),

  WTWB: (en: string, ...val: string[]) => translate(en,
    'Ole hyvä ja vahvista tarkistaneesi osoitteen.',
    ...val),

  WURW: (en: string, ...val: string[]) => translate(en,
    'Avaa lasku',
    ...val),

  WVHG: (en: string, ...val: string[]) => translate(en,
    'Jätä viesti asiakaspalvelulle',
    ...val),

  WVLB: (en: string, ...val: string[]) => translate(en,
    'Verkkolaskuoperaattori',
    ...val),

  WVNV: (en: string, ...val: string[]) => translate(en,
    'Jätä viesti',
    ...val),

  WW24: (en: string, ...val: string[]) => translate(en,
    'Sisältö',
    ...val),

  WW2M: (en: string, ...val: string[]) => translate(en,
    'Turvaa laitteesi rikkoutumisen varalta - yllättävän vahingon sattuessa saat uutta vastaavan laitteen rikkoutuneen tilalle yhdellä soitolla.',
    ...val),

  WW6V: (en: string, ...val: string[]) => translate(en,
    'Kaikki yritykset',
    ...val),

  WWEG: (en: string, ...val: string[]) => translate(en,
    'Laitelistan tuotevalikoiman kopiominen ei ole mahdollista kk-maksullisille laitelistoille. Päivitä laitelistat yksitellen tai käytä Kopioi laitelista -toiminnetta.',
    ...val),

  WWSI: (en: string, ...val: string[]) => translate(en,
    'Laskutus ja asiakastiedot',
    ...val),

  WWTP: (en: string, ...val: string[]) => translate(en,
    'Nykyinen salasana',
    ...val),

  WWYF: (en: string, ...val: string[]) => translate(en,
    'Haluatko varmasti poistaa tietueen?',
    ...val),

  WX52: (en: string, ...val: string[]) => translate(en,
    'Käyttäjällä on päättymässä oleva Elisa palvelupäätelaite sopimus. Vain yksi laitevaihto on mahdollisesta tehdä yhdellä tilauksella.',
    ...val),

  WXB4: (en: string, ...val: string[]) => translate(en,
    'Käytä älykelloasi kuin puhelinta omalla tutulla numerollasi.',
    ...val),

  WXDM: (en: string, ...val: string[]) => translate(en,
    'Työaikaketju',
    ...val),

  WXZC: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti tilausta ei voida käsitellä luottopäätöksen vuoksi.',
    ...val),

  WYE7: (en: string, ...val: string[]) => translate(en,
    'Nykyisestä laitteesta on avoin lunastuspyyntö',
    ...val),

  WYPE: (en: string, ...val: string[]) => translate(en,
    'Max. 65 merkkiä.',
    ...val),

  WZ0H: (en: string, ...val: string[]) => translate(en,
    'voimassa olevia laitesopimuksia.',
    ...val),

  WZ46: (en: string, ...val: string[]) => translate(en,
    'Toimitusmaksu',
    ...val),

  WZNT: (en: string, ...val: string[]) => translate(en,
    'Samalla Elisa Päätelaitteen sopimuskausi keskeytetään.',
    ...val),

  X0II: (en: string, ...val: string[]) => translate(en,
    'Gt aktivoituu automaattisesti kohdemaissa ja mahdollistaa mobiilipäätelaitteella tai kannettavalla tietokoneella käytettävän nopean tiedonsiirtoyhteyden edulliseen hintaan EU/ETA-alueen ulkopuolisissa maissa. Palvelussa ei ole kuukausimaksua, vaan hinnoittelu aktivoituu käytön mukaan 21,90 € / 14 vrk.',
    ...val),

  X0LO: (en: string, ...val: string[]) => translate(en,
    'Liitä Ringiin',
    ...val),

  X0RT: (en: string, ...val: string[]) => translate(en,
    'Ei asetettua käyttötarkoitusta',
    ...val),

  X0V6: (en: string, ...val: string[]) => translate(en,
    'Huom. Käytäthän jatkossa kirjautumiseen valitsemaasi tunnusta.',
    ...val),

  X1DF: (en: string, ...val: string[]) => translate(en,
    ' -sivulta.',
    ...val),

  X1RF: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä on yhteyshenkilönä palvelulla',
    ...val),

  X1TL: (en: string, ...val: string[]) => translate(en,
    'Siirry käyttäjän tietoihin',
    ...val),

  X2HQ: (en: string, ...val: string[]) => translate(en,
    'Valitse sopimus',
    ...val),

  X2UI: (en: string, ...val: string[]) => translate(en,
    'Lue lisää laiterekisteriohjelmista.',
    ...val),

  X2X6: (en: string, ...val: string[]) => translate(en,
    'pankkitunnuksilla',
    ...val),

  X32V: (en: string, ...val: string[]) => translate(en,
    'Listalle valittu laite sisältää kaikki saatavilla olevat muisti- sekä värivariaatiot.',
    ...val),

  X49N: (en: string, ...val: string[]) => translate(en,
    'Näytä',
    ...val),

  X5AB: (en: string, ...val: string[]) => translate(en,
    'Aihe',
    ...val),

  X7KY: (en: string, ...val: string[]) => translate(en,
    '{} min kuuluu pakettiin',
    ...val),

  X7RU: (en: string, ...val: string[]) => translate(en,
    'Valitse laitelistat, joihin haluat kopioida tuotevalikoiman.',
    ...val),

  X7T4: (en: string, ...val: string[]) => translate(en,
    ' sekä käytössäsi olevan päätelaitteen puitteissa.',
    ...val),

  X7W5: (en: string, ...val: string[]) => translate(en,
    'Lankapuhelinnumero',
    ...val),

  X924: (en: string, ...val: string[]) => translate(en,
    'Tietosuojakäytäntö',
    ...val),

  X9W7: (en: string, ...val: string[]) => translate(en,
    'Maksulliset puhelut',
    ...val),

  XCDT: (en: string, ...val: string[]) => translate(en,
    'Käyttäjän siirto toiseen yritykseen',
    ...val),

  XCEN: (en: string, ...val: string[]) => translate(en,
    'Valitse liittymän tyyppi',
    ...val),

  XE0P: (en: string, ...val: string[]) => translate(en,
    'Luo tunnukset',
    ...val),

  XE4X: (en: string, ...val: string[]) => translate(en,
    'Laitelistan tiedot',
    ...val),

  XED2: (en: string, ...val: string[]) => translate(en,
    'Kertamaksulliset laitteet',
    ...val),

  XF0E: (en: string, ...val: string[]) => translate(en,
    'Tuotevalikoima, hinnoittelu ja sopimuskaudet',
    ...val),

  XF2A: (en: string, ...val: string[]) => translate(en,
    'Päivitä',
    ...val),

  XF86: (en: string, ...val: string[]) => translate(en,
    'Palveluun kuuluu 1Gt tai 1,5Gt datakäyttöä kohdemaasta riippuen ja se on voimassa 14 vrk ajan tai kunnes 1Gt / 1,5 Gt käyttö on täynnä.',
    ...val),

  XFA9: (en: string, ...val: string[]) => translate(en,
    'Ota käyttöön',
    ...val),

  XGRM: (en: string, ...val: string[]) => translate(en,
    'Yritysnumerolla on käytössä kaksi ketjua (työajalla ja työajan ulkopuolella), joihin voit molempiin lisätä neljä vastaanottajaa.',
    ...val),

  XGWK: (en: string, ...val: string[]) => translate(en,
    'Poissaoloketju',
    ...val),

  XHYX: (en: string, ...val: string[]) => translate(en,
    'Lisätietoa puhelimen käytöstä ulkomailla',
    ...val),

  XI4V: (en: string, ...val: string[]) => translate(en,
    'Laitteen tyyppi',
    ...val),

  XI84: (en: string, ...val: string[]) => translate(en,
    'Työaikaketjuun vaaditaan ensimmäinen kohdenumero kun aikaohjaus on valittuna',
    ...val),

  XIFY: (en: string, ...val: string[]) => translate(en,
    'Valitse työnantajayrityksesi listalta',
    ...val),

  XIOZ: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteiden elinkaari ja tapahtumat',
    ...val),

  XJMB: (en: string, ...val: string[]) => translate(en,
    'Ei määräaikaisuutta',
    ...val),

  XJMC: (en: string, ...val: string[]) => translate(en,
    'Ei määräaikaisuutta.',
    ...val),

  XKDW: (en: string, ...val: string[]) => translate(en,
    'Kelpaavat maksukortit',
    ...val),

  XKFL: (en: string, ...val: string[]) => translate(en,
    'Tällä lomakkeella tehdyt laskutustietojen muutospyynnöt käsitellään maksutta. Asiakaspalvelun kautta tehdyistä muutospyynnöistä veloitamme ',
    ...val),

  XKKL: (en: string, ...val: string[]) => translate(en,
    'Tilauksen vahvistaminen onnistui.',
    ...val),

  XL11: (en: string, ...val: string[]) => translate(en,
    'määräaikaisuus',
    ...val),

  XLTQ: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti järjestelmässämme tapahtui virhe. Yritä hetken kuluttua uudelleen. Jos asia on kiireellinen, ota yhteyttä asiakaspalveluun {0} ({1}). Pahoittelemme häiriötä.',
    ...val),

  XMCF: (en: string, ...val: string[]) => translate(en,
    'Laitesopimuksesi on jatkokaudella',
    ...val),

  XNEL: (en: string, ...val: string[]) => translate(en,
    'OmaLasku -palvelu ei ole käytettävissä',
    ...val),

  XOGB: (en: string, ...val: string[]) => translate(en,
    'Jatka palveluun',
    ...val),

  XOVM: (en: string, ...val: string[]) => translate(en,
    'Jatkokaudella olevat',
    ...val),

  XOYE: (en: string, ...val: string[]) => translate(en,
    'Arvo on liian pitkä, enintään {} merkkiä on sallittu',
    ...val),

  XP04: (en: string, ...val: string[]) => translate(en,
    'Puhelinnumero ei ole enää vapaana',
    ...val),

  XPAQ: (en: string, ...val: string[]) => translate(en,
    'Valittu lisäpalvelu',
    ...val),

  XPIK: (en: string, ...val: string[]) => translate(en,
    'Voit tilata uuden laitteen yrityksesi',
    ...val),

  XQ5R: (en: string, ...val: string[]) => translate(en,
    'Liittymät',
    ...val),

  XRLY: (en: string, ...val: string[]) => translate(en,
    'Poista laitelista',
    ...val),

  XS42: (en: string, ...val: string[]) => translate(en,
    'Tabletit',
    ...val),

  XSQ0: (en: string, ...val: string[]) => translate(en,
    'Tuotteen nimi',
    ...val),

  XT3B: (en: string, ...val: string[]) => translate(en,
    'Alkaen',
    ...val),

  XTU4: (en: string, ...val: string[]) => translate(en,
    'Sinulla on käytössäsi Elisa Yritystietoturva -tilaus, joka tarjoaa käyttöösi erillisen puhelintuen tietoturvaongelmissa. Puhelintuesta vastaa yhteistyökumppanimme F-Secure.',
    ...val),

  XUDO: (en: string, ...val: string[]) => translate(en,
    'DNS-tietueen poistaminen epäonnistui!',
    ...val),

  XUE0: (en: string, ...val: string[]) => translate(en,
    'https://yrityksille.elisa.fi/yhteystiedot',
    ...val),

  XUFV: (en: string, ...val: string[]) => translate(en,
    'Suodata',
    ...val),

  XUME: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin aktivointi',
    ...val),

  XUMF: (en: string, ...val: string[]) => translate(en,
    'SIM-kortin aktivointi onnistui.',
    ...val),

  XV2A: (en: string, ...val: string[]) => translate(en,
    'Rajaton netti kotimaassa',
    ...val),

  XV60: (en: string, ...val: string[]) => translate(en,
    'Lue lisää Reissaaja palvelusta.',
    ...val),

  XV9S: (en: string, ...val: string[]) => translate(en,
    'Luo Elisa-tunnus tai tunnus unohtunut',
    ...val),

  XVCP: (en: string, ...val: string[]) => translate(en,
    'Eivätkö käytössäsi olevat palvelut näy listauksessa? Käy lisäämässä ne Laitteeni ja liittymäni -sivulla.',
    ...val),

  XVHQ: (en: string, ...val: string[]) => translate(en,
    'Sähköposti',
    ...val),

  XVZ7: (en: string, ...val: string[]) => translate(en,
    'Laitteiden hinnat',
    ...val),

  XW4L: (en: string, ...val: string[]) => translate(en,
    'tukipyyntö-lomakkeen',
    ...val),

  XWA9: (en: string, ...val: string[]) => translate(en,
    'Olet valinnut toimitukseen mukaan tuotteen, jolla rajoitettu toimitustapa. Rajoituksen perusteet mainittu tuotteen tiedoissa.',
    ...val),

  XWHX: (en: string, ...val: string[]) => translate(en,
    'Ylläpidetty palvelu',
    ...val),

  XWU1: (en: string, ...val: string[]) => translate(en,
    'Mikäli liittymä irtisanotaan tai siirretään toiselle operaattorille määräaikaisen sopimuskauden ollessa voimassa, veloitetaan sopimuksen mukaiset kuukausi- ja muut maksut määräaikaisen sopimuskauden loppuun.',
    ...val),

  XX5A: (en: string, ...val: string[]) => translate(en,
    'Elisa Mobiilivarmenne Työntekijälle',
    ...val),

  XXVX: (en: string, ...val: string[]) => translate(en,
    'kk',
    ...val),

  XXXQ: (en: string, ...val: string[]) => translate(en,
    'Oletko päivittänyt liittymääsi?',
    ...val),

  XY1R: (en: string, ...val: string[]) => translate(en,
    'https://yrityksille.elisa.fi/ohjeet/laskut',
    ...val),

  XZA8: (en: string, ...val: string[]) => translate(en,
    'Ei palveluita',
    ...val),

  XZH6: (en: string, ...val: string[]) => translate(en,
    'Laskutustapa',
    ...val),

  Y028: (en: string, ...val: string[]) => translate(en,
    'käytetty',
    ...val),

  Y02E: (en: string, ...val: string[]) => translate(en,
    'Ei palveluestoja',
    ...val),

  Y0HD: (en: string, ...val: string[]) => translate(en,
    'Muista lisätä tilaukselle näytönsuoja sekä suojakotelo. Voit myös hankkia ne haluamastasi paikasta omakustanteisesti. Vahinkopalvelu edellyttää, että laite on suojattu. Jatkamalla sitoudun suojaamaan laitteeni.',
    ...val),

  Y0IR: (en: string, ...val: string[]) => translate(en,
    'Valitse asennuspalvelu',
    ...val),

  Y0SU: (en: string, ...val: string[]) => translate(en,
    'Elisa WiFi-puhelupalvelu',
    ...val),

  Y1FD: (en: string, ...val: string[]) => translate(en,
    '5G-yhteensopivuus',
    ...val),

  Y1KA: (en: string, ...val: string[]) => translate(en,
    'En muista käyttäjätunnustani tai se on vanhentunut.',
    ...val),

  Y29L: (en: string, ...val: string[]) => translate(en,
    'Hyväksyntäpyynnöt',
    ...val),

  Y2QC: (en: string, ...val: string[]) => translate(en,
    'Satunnaiseen työskentelyyn ja asiointiin verkossa',
    ...val),

  Y30S: (en: string, ...val: string[]) => translate(en,
    'Kun haluat vaihtaa Ring-liittymän käyttäjää, laita meille viestiä ja hoidamme asian puolestasi. Kiireellisissä tapauksissa suosittelemme soittamaan asiakaspalveluumme {0} ({1}).',
    ...val),

  Y30X: (en: string, ...val: string[]) => translate(en,
    'Uuden laskun PDF-lasku on saatavilla noin vuorokauden kuluttua.',
    ...val),

  Y36X: (en: string, ...val: string[]) => translate(en,
    'Raportit',
    ...val),

  Y37N: (en: string, ...val: string[]) => translate(en,
    'Muut yritykset',
    ...val),

  Y3YM: (en: string, ...val: string[]) => translate(en,
    'Tavoitettavuusketjut',
    ...val),

  Y41S: (en: string, ...val: string[]) => translate(en,
    'Sukunimi',
    ...val),

  Y6JY: (en: string, ...val: string[]) => translate(en,
    'Salasanan luominen epäonnistui. Yritä uudestaan',
    ...val),

  Y72F: (en: string, ...val: string[]) => translate(en,
    'Käsitellään',
    ...val),

  Y7C0: (en: string, ...val: string[]) => translate(en,
    'Laskut',
    ...val),

  Y7UG: (en: string, ...val: string[]) => translate(en,
    'Pääkäyttäjä ja tilausten hyväksyjä',
    ...val),

  Y7WJ: (en: string, ...val: string[]) => translate(en,
    'Avausmaksut',
    ...val),

  Y821: (en: string, ...val: string[]) => translate(en,
    'Sujuvaan työntekoon, pilvipalveluihin ja vapaa-ajan viihteeseen',
    ...val),

  Y8GO: (en: string, ...val: string[]) => translate(en,
    'Lisää tehoa liittymääsi',
    ...val),

  Y8KX: (en: string, ...val: string[]) => translate(en,
    'Käyttötarkoitus',
    ...val),

  Y8LP: (en: string, ...val: string[]) => translate(en,
    'Käyttöoikeudet',
    ...val),

  Y8OY: (en: string, ...val: string[]) => translate(en,
    'Sukunimi',
    ...val),

  Y91N: (en: string, ...val: string[]) => translate(en,
    'Ajastettu muutos',
    ...val),

  Y93H: (en: string, ...val: string[]) => translate(en,
    'Valitse oletuslaskutussopimus laitelistalle',
    ...val),

  Y9B3: (en: string, ...val: string[]) => translate(en,
    'Ohjeet ja tukisivut',
    ...val),

  Y9CE: (en: string, ...val: string[]) => translate(en,
    'Vaativaan työntekoon ja suoratoistopalveluiden aktiiviseen katsomiseen',
    ...val),

  Y9JE: (en: string, ...val: string[]) => translate(en,
    'Työnantajan kustantama',
    ...val),

  Y9S5: (en: string, ...val: string[]) => translate(en,
    'Tietueen tyyppi',
    ...val),

  Y9TT: (en: string, ...val: string[]) => translate(en,
    'Liittymään kuuluu 100 minuuttia puhetta kuukaudessa. Sen ylittävät osuudet veloitetaan seuraavien puhepakettirajojen mukaisesti:',
    ...val),

  YA79: (en: string, ...val: string[]) => translate(en,
    'Tarkista käyttäjän henkilötiedot',
    ...val),

  YA97: (en: string, ...val: string[]) => translate(en,
    'Ostoskorissa on {} tuotetta',
    ...val),

  YAA5: (en: string, ...val: string[]) => translate(en,
    'Tekemäsi tukipyyntö tulee näkyviin OmaElisaan Tukipyynnöt-sivulle ja se näkyy kaikille yrityksenne OmaElisa-käyttäjille.',
    ...val),

  YAB3: (en: string, ...val: string[]) => translate(en,
    'Gt/kk',
    ...val),

  YAJE: (en: string, ...val: string[]) => translate(en,
    'Irtisanomispäivä ei saa osua viikonlopulle',
    ...val),

  YAMO: (en: string, ...val: string[]) => translate(en,
    'Ohjeita laskutukseen?',
    ...val),

  YAVU: (en: string, ...val: string[]) => translate(en,
    'Lankanumerot',
    ...val),

  YB45: (en: string, ...val: string[]) => translate(en,
    'Tunnistaudu pankkitunnuksilla',
    ...val),

  YBCH: (en: string, ...val: string[]) => translate(en,
    'roaming-hinnaston mukaisesti',
    ...val),

  YBFC: (en: string, ...val: string[]) => translate(en,
    'kt',
    ...val),

  YBX5: (en: string, ...val: string[]) => translate(en,
    'Nykyinen puhelinnumero',
    ...val),

  YD2N: (en: string, ...val: string[]) => translate(en,
    'Ei tukipyyntöjä',
    ...val),

  YE3V: (en: string, ...val: string[]) => translate(en,
    'Laskun tila',
    ...val),

  YE85: (en: string, ...val: string[]) => translate(en,
    'Käyttäjää ei voida siirtää',
    ...val),

  YEX6: (en: string, ...val: string[]) => translate(en,
    'Käyttäjä poistetaan järjestelmästä lopullisesti. Poistoa ei voi peruuttaa.',
    ...val),

  YFPB: (en: string, ...val: string[]) => translate(en,
    'Voit tarvittaessa muuttaa laitteen laskutussopimusta.',
    ...val),

  YFT1: (en: string, ...val: string[]) => translate(en,
    'Siirry maksettuihin laskuihin',
    ...val),

  YFWU: (en: string, ...val: string[]) => translate(en,
    'Puhelut ja viestit aikuisviihdenumeroihin',
    ...val),

  YG89: (en: string, ...val: string[]) => translate(en,
    'tai liittymätyypin muutosmaksu 3,99 €',
    ...val),

  YI9C: (en: string, ...val: string[]) => translate(en,
    'Avaa puhelimesi kamera ja kohdista se vieressä olevaan QR-koodiin.',
    ...val),

  YIDW: (en: string, ...val: string[]) => translate(en,
    'Liittymä näytetään Työntekijän OmaElisassa käyttäjän henkilötietojen perusteella.',
    ...val),

  YJMM: (en: string, ...val: string[]) => translate(en,
    'Elisa Palvelupäätelaitteiden vuokravastuut',
    ...val),

  YK9W: (en: string, ...val: string[]) => translate(en,
    'Sisältää sekä isoja että pieniä kirjaimia',
    ...val),

  YKJA: (en: string, ...val: string[]) => translate(en,
    'Laitesopimuksen hylkääminen onnistui.',
    ...val),

  YLAI: (en: string, ...val: string[]) => translate(en,
    'Maksajan lisätieto',
    ...val),

  YLCX: (en: string, ...val: string[]) => translate(en,
    'Virheellinen puhelinnumero',
    ...val),

  YLFT: (en: string, ...val: string[]) => translate(en,
    'Tekijänoikeudet © 2025 Elisa Oyj. Kaikki oikeudet pidätetään.',
    ...val),

  YLXB: (en: string, ...val: string[]) => translate(en,
    'Valitse yritys, johon käyttäjä siirretään.',
    ...val),

  YM3W: (en: string, ...val: string[]) => translate(en,
    'Näytetään laskutussopimuksen tämänhetkiset tiedot.',
    ...val),

  YMED: (en: string, ...val: string[]) => translate(en,
    'Käsitelty',
    ...val),

  YMF3: (en: string, ...val: string[]) => translate(en,
    'Voit seurata tukipyynnön etenemistä Yritysten OmaElisassa ',
    ...val),

  YMJF: (en: string, ...val: string[]) => translate(en,
    'Seuraavien listojen kopiointi ei onnistunut. Tuotteita ei kopioitu. Tarkista ja muokkaa listat yksitellen:',
    ...val),

  YMLL: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu sivustolle yrityksille.elisa.fi/työntekijä ja lisää se Liittymäni-sivulla.',
    ...val),

  YMMM: (en: string, ...val: string[]) => translate(en,
    'Hyväksy vielä sopimusehdot',
    ...val),

  YN14: (en: string, ...val: string[]) => translate(en,
    'Yritysnumeron tavoitettavuus',
    ...val),

  YN9F: (en: string, ...val: string[]) => translate(en,
    'Elisa Oma Laskun avulla voit valita työsuhdelaitteeksesi työnantajasi normaalikäytäntöä paremman laitteen. Maksat hinnan erotuksen kuukausittain erillisellä laskulla.',
    ...val),

  YNUS: (en: string, ...val: string[]) => translate(en,
    'Voit seurata tukipyynnön etenemistä',
    ...val),

  YO7F: (en: string, ...val: string[]) => translate(en,
    '{}/kk',
    ...val),

  YOU8: (en: string, ...val: string[]) => translate(en,
    'Soittajan numero',
    ...val),

  YOW5: (en: string, ...val: string[]) => translate(en,
    'Ratkaisu',
    ...val),

  YP6T: (en: string, ...val: string[]) => translate(en,
    'Asiakaspalvelijan viesti',
    ...val),

  YPAM: (en: string, ...val: string[]) => translate(en,
    'Uusi tietue luotu',
    ...val),

  YPMQ: (en: string, ...val: string[]) => translate(en,
    'Laitteen voi vaihtaa uuteen kaksi viikkoa ennen sopimuskauden päättymistä. Mikäli laitevaihtoa ei tehdä tai sopimusta päätetä, laite siirtyy automaattisesti 6 kk jatkokaudelle.',
    ...val),

  YRYX: (en: string, ...val: string[]) => translate(en,
    'Erääntynyt',
    ...val),

  YRZJ: (en: string, ...val: string[]) => translate(en,
    'Laitteeni ja liittymäni',
    ...val),

  YTH3: (en: string, ...val: string[]) => translate(en,
    'Tunnistenumero',
    ...val),

  YTNE: (en: string, ...val: string[]) => translate(en,
    'Lunastan',
    ...val),

  YU2R: (en: string, ...val: string[]) => translate(en,
    'Palaa OmaElisaan',
    ...val),

  YU6F: (en: string, ...val: string[]) => translate(en,
    'Kopioi laitelistan tuotevalikoima muihin Elisa Palvelupäätelaite -listoihin',
    ...val),

  YV0D: (en: string, ...val: string[]) => translate(en,
    'Sinulla ei ole oikeuksia hallinnoida tuotteita uudessa OmaElisassa.',
    ...val),

  YV0E: (en: string, ...val: string[]) => translate(en,
    'Yritysten OmaElisassa voit hallinnoida nykyisiä liittymiäsi, tarkastella laskujasi ja tilata uusia liittymiä.',
    ...val),

  YVR4: (en: string, ...val: string[]) => translate(en,
    'Laitelistan tuotteet',
    ...val),

  YW61: (en: string, ...val: string[]) => translate(en,
    'Kiinteät laajakaistat',
    ...val),

  YW9I: (en: string, ...val: string[]) => translate(en,
    'Lisää maksuaikaa',
    ...val),

  YWE4: (en: string, ...val: string[]) => translate(en,
    'Julkaise muutokset heti valittuihin listoihin',
    ...val),

  YX3Y: (en: string, ...val: string[]) => translate(en,
    'Asiointiesto P2',
    ...val),

  YX78: (en: string, ...val: string[]) => translate(en,
    'alv. {} %',
    ...val),

  YXF1: (en: string, ...val: string[]) => translate(en,
    'Ostoskori',
    ...val),

  YXMI: (en: string, ...val: string[]) => translate(en,
    'Hinta veroineen',
    ...val),

  YY7Z: (en: string, ...val: string[]) => translate(en,
    'Katalogin julkaisu epäonnistui',
    ...val),

  YYCY: (en: string, ...val: string[]) => translate(en,
    'Työaika',
    ...val),

  YZ8Q: (en: string, ...val: string[]) => translate(en,
    'Liittymän päivitys onnistui',
    ...val),

  YZJ3: (en: string, ...val: string[]) => translate(en,
    'Liittymän kuukausimaksut tilauksen jälkeen ',
    ...val),

  YZMF: (en: string, ...val: string[]) => translate(en,
    'Kirjoita viesti',
    ...val),

  YZQH: (en: string, ...val: string[]) => translate(en,
    'Palaa kauppaan',
    ...val),

  Z0HF: (en: string, ...val: string[]) => translate(en,
    'Laitelista',
    ...val),

  Z0OY: (en: string, ...val: string[]) => translate(en,
    'Nouto postin pakettiautomaatista',
    ...val),

  Z0U9: (en: string, ...val: string[]) => translate(en,
    'Palaa Työntekijän OmaElisaan',
    ...val),

  Z12D: (en: string, ...val: string[]) => translate(en,
    'Tutustu ja tilaa',
    ...val),

  Z13H: (en: string, ...val: string[]) => translate(en,
    'Siirry OmaElisaan',
    ...val),

  Z16I: (en: string, ...val: string[]) => translate(en,
    'Kirjaudu',
    ...val),

  Z1M1: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyynnöt',
    ...val),

  Z20L: (en: string, ...val: string[]) => translate(en,
    'Laiterekisteriohjelmilla voit varmistaa, että jokaiselle uudelle laitteelle otetaan automaattisesti käyttöön yrityksen päätelaitteiden hallinta. Näin laitteet pysyvät yrityksen hallinnassa käyttöönotosta elinkaarensa loppuun asti.',
    ...val),

  Z223: (en: string, ...val: string[]) => translate(en,
    'Laskutuskoodi',
    ...val),

  Z2Y8: (en: string, ...val: string[]) => translate(en,
    'Tutustu myös ratkaisuihimme isommalle toimistolle',
    ...val),

  Z3L0: (en: string, ...val: string[]) => translate(en,
    'Lisätietoa aiheesta',
    ...val),

  Z3W8: (en: string, ...val: string[]) => translate(en,
    'Palauta salasanasi',
    ...val),

  Z40V: (en: string, ...val: string[]) => translate(en,
    'Yritysnumeron ajastettu työaika',
    ...val),

  Z44Y: (en: string, ...val: string[]) => translate(en,
    'Asiointiesto',
    ...val),

  Z64W: (en: string, ...val: string[]) => translate(en,
    'Vastausryhmät',
    ...val),

  Z6SX: (en: string, ...val: string[]) => translate(en,
    'Uusin ensin',
    ...val),

  Z6YC: (en: string, ...val: string[]) => translate(en,
    'Maksukehotus',
    ...val),

  Z71F: (en: string, ...val: string[]) => translate(en,
    'Sinulla on jo avoin tilaus',
    ...val),

  Z7CU: (en: string, ...val: string[]) => translate(en,
    ' nimenkorjaukseen liittyen.',
    ...val),

  Z7FX: (en: string, ...val: string[]) => translate(en,
    'Ikävä kyllä, palvelu ei vielä toistaiseksi ole saatavilla käytössäsi olevalle liittymätyypille.',
    ...val),

  Z7IG: (en: string, ...val: string[]) => translate(en,
    'Kadonnut tai tuhoutunut laite',
    ...val),

  Z7K0: (en: string, ...val: string[]) => translate(en,
    'Löydät SIM-kortin numeron kuvan osoittamista kohdista. Käynnistä laitteesi uudelleen kortin aktivoimisen jälkeen. Uuden SIM-kortin PIN-koodi on aluksi 1234.',
    ...val),

  Z7NS: (en: string, ...val: string[]) => translate(en,
    'Tässä liittymässä on seuraavat estot:',
    ...val),

  Z7S5: (en: string, ...val: string[]) => translate(en,
    'Postitoimipaikka',
    ...val),

  Z7TJ: (en: string, ...val: string[]) => translate(en,
    'Näytä lisää vaihtoehtoja kiinteän verkon Netti Lite -liittymille',
    ...val),

  Z85X: (en: string, ...val: string[]) => translate(en,
    'SquareTrade ei ole saatavissa tässä yhteydessä ja se pitää poistaa ostoskorista.',
    ...val),

  Z875: (en: string, ...val: string[]) => translate(en,
    'Valittu yhteensä',
    ...val),

  Z8CO: (en: string, ...val: string[]) => translate(en,
    'Mikäli liittymä korvaa olemassa olevan liittymän, niin laita tähän korvattavan liittymän irtisanomispäivämäärä.',
    ...val),

  Z8MP: (en: string, ...val: string[]) => translate(en,
    'Laitteen voi vaihtaa uuteen kaksi viikkoa ennen peruskauden päättymistä. Mikäli laitevaihtoa ei tehdä tai sopimusta päätetä, laite siirtyy automaattisesti 6 kk jatkokaudelle.',
    ...val),

  Z96J: (en: string, ...val: string[]) => translate(en,
    'Arvo ei voi sisältää ääkkösiä tai erikoismerkkejä.',
    ...val),

  Z9BX: (en: string, ...val: string[]) => translate(en,
    'Laskuun liittyvä reklamaatio',
    ...val),

  Z9IW: (en: string, ...val: string[]) => translate(en,
    'Lataa App Storesta',
    ...val),

  Z9IX: (en: string, ...val: string[]) => translate(en,
    'Lataa se Google Playsta',
    ...val),

  ZB19: (en: string, ...val: string[]) => translate(en,
    'csv- tai tekstitiedoston.',
    ...val),

  ZB3L: (en: string, ...val: string[]) => translate(en,
    'Lunastuspyyntöni',
    ...val),

  ZB7A: (en: string, ...val: string[]) => translate(en,
    'Kuukausi',
    ...val),

  ZBPW: (en: string, ...val: string[]) => translate(en,
    'Etätyöt, videopuhelut ja pilvipalvelut',
    ...val),

  ZC46: (en: string, ...val: string[]) => translate(en,
    ' ja ota tarvittaessa yhteyttä yrityksesi OmaElisa-pääkäyttäjään ja pyydä häntä tarkistamaan, että liittymiesi käyttäjätiedot ovat oikein.',
    ...val),

  ZC7D: (en: string, ...val: string[]) => translate(en,
    'Tilaaja',
    ...val),

  ZCA8: (en: string, ...val: string[]) => translate(en,
    'Etu jopa 67% normaalihinnasta, ei määräaikaa. Katso kaikki etuhintaiset nopeusvaihtoehdot',
    ...val),

  ZCS1: (en: string, ...val: string[]) => translate(en,
    'Jos laitteelle on tilattu asennuspalvelu, niin asentaja on yhteydessä asennusajasta.',
    ...val),

  ZCX7: (en: string, ...val: string[]) => translate(en,
    'Sama kuin yhteyshenkilö',
    ...val),

  ZD17: (en: string, ...val: string[]) => translate(en,
    'Palvelun irtisanomisen jälkeen Elisa Mobiilivarmenne toimii vielä kaksi viikkoa työntekijöillä, jotka ovat ottaneet sen käyttöönsä. Ilmoitamme tekstiviestillä kaikille palvelun käyttöönottaneille mahdollisuudesta tilata palvelu jatkossa omakustanteisesti.',
    ...val),

  ZDQ2: (en: string, ...val: string[]) => translate(en,
    'Olemme lähettäneet tilausvahvistuksen sähkopostiisi {}.',
    ...val),

  ZDX7: (en: string, ...val: string[]) => translate(en,
    'Uusi numero',
    ...val),

  ZE11: (en: string, ...val: string[]) => translate(en,
    'Valitettavasti kielteisen luottopäätöksen vuoksi valitsemasi laitteen tilaus ei onnistu. Valitsethan laitteen, jonka kuukausimaksun osuus on 0 euroa.',
    ...val),

  ZE18: (en: string, ...val: string[]) => translate(en,
    'Mihin osoitteeseen tarvitset netin?',
    ...val),

  ZE2K: (en: string, ...val: string[]) => translate(en,
    'Löysimme kohdeyrityksestä käyttäjän, jolla on samoja yhteystietoja',
    ...val),

  ZE9V: (en: string, ...val: string[]) => translate(en,
    'Ostoskorissa yhteensä {} tuotetta',
    ...val),

  ZEU1: (en: string, ...val: string[]) => translate(en,
    'Liittäminen ei ole tällä hetkellä mahdollista Lankanumero-lisäpalvelun vuoksi',
    ...val),

  ZEX1: (en: string, ...val: string[]) => translate(en,
    'Arvo ei voi sisältää kirjaimia. Esimerkki arvosta: [1-255].[0-255].[0-255].[0-255]',
    ...val),

  ZF4E: (en: string, ...val: string[]) => translate(en,
    'Ole hyvä ja viimeistele muutokset osoitetietoihin ennen jatkamista.',
    ...val),

  ZF77: (en: string, ...val: string[]) => translate(en,
    'Sopimusta ei voida päättää kesken sopimuskauden työntekijän toimesta. Ota tarvittaessa yhteyttä tilaajayhteyshenkilöösi.',
    ...val),

  ZFAS: (en: string, ...val: string[]) => translate(en,
    'Tabletti',
    ...val),

  ZGKE: (en: string, ...val: string[]) => translate(en,
    'Salainen',
    ...val),

  ZGSV: (en: string, ...val: string[]) => translate(en,
    'Avausmaksu lisätään seuraavalle laskulle.',
    ...val),

  ZGX7: (en: string, ...val: string[]) => translate(en,
    'Palvelu sisältää modeemin ja yhteyden asennuksen, sekä WIFI Mesh-laitteiden ({}) asennuksen optimaalisesti sijoiteltuna, jotta WIFI-verkko kattaa koko kodin/toimiston. Palveluun kuuluu myös asennetun kokonaisuuden toimivuuden testaus.',
    ...val),

  ZHBT: (en: string, ...val: string[]) => translate(en,
    'Laitevaihto',
    ...val),

  ZHSZ: (en: string, ...val: string[]) => translate(en,
    'Tämä ei vaikuta asiakkuuden sopijatietoon, vaan päivittää laskun saajan ja postiosoitteen. Nämä tiedot näkyvät laskulla.',
    ...val),

  ZIH0: (en: string, ...val: string[]) => translate(en,
    'Elisa Reissunetti 1-1,5 Gt',
    ...val),

  ZIH3: (en: string, ...val: string[]) => translate(en,
    'Toimitettavat laitteet voi liittää yrityksen käyttämään, Elisalle aktivoituun laiterekisteriohjelmaan. Niitä ovat Apple DEP, Samsung KME, Android Zero-Touch ja Windows Autopilot. Laiterekisteriohjelmien avulla laitteet ohjataan automaattisesti yrityksen käytössä olevaan laitehallintajärjestelmään.',
    ...val),

  ZJLK: (en: string, ...val: string[]) => translate(en,
    'Tekstiviesti ilmoittaa puhelusta, jota et vastaanottanut, kun puhelin ei ollut yhteydessä verkkoon tai oli sammutettuna esimerkiksi lennon aikana.',
    ...val),

  ZJQG: (en: string, ...val: string[]) => translate(en,
    'Luonnos',
    ...val),

  ZK2S: (en: string, ...val: string[]) => translate(en,
    'Tarkista salasana, kiitos',
    ...val),

  ZK30: (en: string, ...val: string[]) => translate(en,
    'Tilauksen vahvistaminen epäonnistui.',
    ...val),

  ZK7G: (en: string, ...val: string[]) => translate(en,
    'Poista käyttäjä',
    ...val),

  ZLAU: (en: string, ...val: string[]) => translate(en,
    'Viite',
    ...val),

  ZLQO: (en: string, ...val: string[]) => translate(en,
    'Hinta (alv. 0 %)',
    ...val),

  ZMK2: (en: string, ...val: string[]) => translate(en,
    'Täytäthän puuttuvat tiedot.',
    ...val),

  ZML8: (en: string, ...val: string[]) => translate(en,
    'Poissaoloketjuun vaaditaan ensimmäinen kohdenumero kun poissaoloketju on valittuna',
    ...val),

  ZMRO: (en: string, ...val: string[]) => translate(en,
    'Tukipyyntö on peruutettu. Olemme lähettäneet lisätietoa tukipyynnön avanneen käyttäjän sähköpostiin.',
    ...val),

  ZN5B: (en: string, ...val: string[]) => translate(en,
    'Ladataan lomakelistaa',
    ...val),

  ZNGS: (en: string, ...val: string[]) => translate(en,
    'Vanhan laitteen lunastushinta',
    ...val),

  ZNNI: (en: string, ...val: string[]) => translate(en,
    'Liittymän päivittäminen hyllyliittymäksi ei onnistu',
    ...val),

  ZNS1: (en: string, ...val: string[]) => translate(en,
    'SIM-kortti on jo käytössä toisella liittymällä.',
    ...val),

  ZO3A: (en: string, ...val: string[]) => translate(en,
    'Android Zore-touch laiterekisteröintiohjelma',
    ...val),

  ZOES: (en: string, ...val: string[]) => translate(en,
    'Lue lisää palvelusta',
    ...val),

  ZPN3: (en: string, ...val: string[]) => translate(en,
    'Ennen kuin tilaat mobiililaajakaistaliittymän, tarkistathan ',
    ...val),

  ZPXZ: (en: string, ...val: string[]) => translate(en,
    'Lunastaja',
    ...val),

  ZPZR: (en: string, ...val: string[]) => translate(en,
    'Työntekijän OmaElisassa',
    ...val),

  ZQ4R: (en: string, ...val: string[]) => translate(en,
    'Kuljetus toimitusosoitteeseen',
    ...val),

  ZRKX: (en: string, ...val: string[]) => translate(en,
    'Muutosmaksu',
    ...val),

  ZRR4: (en: string, ...val: string[]) => translate(en,
    'Tarkista, ettei yrityksesi palveluita ole',
    ...val),

  ZRXP: (en: string, ...val: string[]) => translate(en,
    'Samsung KME laiterekisteröintiohjelma',
    ...val),

  ZS4S: (en: string, ...val: string[]) => translate(en,
    'Prioriteetti',
    ...val),

  ZSRZ: (en: string, ...val: string[]) => translate(en,
    'Kun valitset laitteelle palautuksen, läheteämme palautuspussin tilauksen yhteydessä, jolla käyttäjän tulee palauttaa laite viipymättä',
    ...val),

  ZT0K: (en: string, ...val: string[]) => translate(en,
    'Tämä kutsulinkki on jo käytetty tai se on vanhentunut. Ole hyvä ja pyydä uusi kutsulinkki pääkäyttäjältäsi.',
    ...val),

  ZTNQ: (en: string, ...val: string[]) => translate(en,
    'Työntekijän OmaElisaan',
    ...val),

  ZTPC: (en: string, ...val: string[]) => translate(en,
    'Tilauspäivä',
    ...val),

  ZU62: (en: string, ...val: string[]) => translate(en,
    'Näytä esimerkkiviesti',
    ...val),

  ZU8S: (en: string, ...val: string[]) => translate(en,
    'Aktivoi kaksivaiheinen tunnistautuminen',
    ...val),

  ZUCA: (en: string, ...val: string[]) => translate(en,
    'Estopalvelut',
    ...val),

  ZUWC: (en: string, ...val: string[]) => translate(en,
    'yritysasiakkaiden palveluhinnaston',
    ...val),

  ZUZG: (en: string, ...val: string[]) => translate(en,
    'Salasanan on oltava vähintään 8 merkkiä pitkä, vähintään yksi numero, yksi iso kirjain ja yksi pieni kirjain.',
    ...val),

  ZV9E: (en: string, ...val: string[]) => translate(en,
    'Pysy ajan tasalla!',
    ...val),

  ZVDQ: (en: string, ...val: string[]) => translate(en,
    'Salasanan uusiminen onnistui',
    ...val),

  ZVIL: (en: string, ...val: string[]) => translate(en,
    'Vakion numeroasetukset',
    ...val),

  ZVMK: (en: string, ...val: string[]) => translate(en,
    'Laskutussopimukset',
    ...val),

  ZVP0: (en: string, ...val: string[]) => translate(en,
    'Pakettiin kuuluvat viestit',
    ...val),

  ZVQ5: (en: string, ...val: string[]) => translate(en,
    'Sähköpostiosoite',
    ...val),

  ZVQ6: (en: string, ...val: string[]) => translate(en,
    'Työsähköpostiosoite',
    ...val),

  ZVU5: (en: string, ...val: string[]) => translate(en,
    'Suositus {0} käyttäjää, ({1} laitetta)',
    ...val),

  ZW5Q: (en: string, ...val: string[]) => translate(en,
    'eSIM-kortti kytketään päälle QR-koodilla, joka luetaan puhelimen kameralla tai siihen erikseen tarkoitetulla sovelluksella. Voit syöttää tiedot myös käsin lisäämällä eSIMin laitteen asetuksista.',
    ...val),

  ZW5W: (en: string, ...val: string[]) => translate(en,
    'Ei valintaa',
    ...val),

  ZWK3: (en: string, ...val: string[]) => translate(en,
    'Liitä numero osaksi yrityksesi Ring-palvelua',
    ...val),

  ZWKY: (en: string, ...val: string[]) => translate(en,
    'Tekemäsi tukipyyntö tulee näkyviin OmaElisaan Tukipyynnöt-sivulle ja se näkyy kaikille yrityksenne OmaElisa-käyttäjille.',
    ...val),

  ZX0L: (en: string, ...val: string[]) => translate(en,
    'Valitse käyttäjätunnus',
    ...val),

  ZX32: (en: string, ...val: string[]) => translate(en,
    'Yritysnumero',
    ...val),

  ZXA6: (en: string, ...val: string[]) => translate(en,
    'Odottaa hyväksyntää',
    ...val),

  ZYAW: (en: string, ...val: string[]) => translate(en,
    'Kirjautumiseen tarvitset',
    ...val),

  ZYIU: (en: string, ...val: string[]) => translate(en,
    'Elisa Ring -ratkaisu liittymälle',
    ...val),

  ZZK7: (en: string, ...val: string[]) => translate(en,
    'Viikonloppuisin',
    ...val),

  ZZWN: (en: string, ...val: string[]) => translate(en,
    'Valitse tekstiviestipaketti',
    ...val),

});
