import * as CL from '@design-system/component-library';
import { CheckboxTreeComponent } from '../../common/react-hook-form/components/CheckboxTreeComponent';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '../Grid/Grid.js';
import { ReportDetails } from './ReportDetails';
import { ReportStatus } from '../../generated/api/reportStatus';
import { ReportType } from '../../generated/api/reportType';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio';
import { companiesMsg, t } from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generateReport } from '../../common/fetch';
import { startNotification } from '../../selfservice/actions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRevalidator } from 'react-router-dom';
import type { AuthenticatedUserState } from '../../common/types/states';
import type { ReportItemResponse } from '../../generated/api/reportItemResponse.js';
import type { ReportStatusChange } from '../../generated/api/reportStatusChange.js';

import './Reports.scss';

export interface AccountSubset {
  companyName: string;
  mdmId: string;
  eppSolutionActive: boolean;
}

export interface ReportsProps {
  authenticatedUser?: AuthenticatedUserState;
  accounts: AccountSubset[];
  latestReportStatusChange?: ReportStatusChange;
  reports: ReportItemResponse[];
}

export interface ReportFormProps {
  accounts: AccountSubset[];
  handleCreatingNew: (selectedMdmIds?: string[], reportType?: ReportType) => void;
  isMultibiz: boolean;
  isSubmitting: boolean;
}

export interface ReportFormValues {
  key: number;
  reportType: string;
  selectedMdmIds: string[];
}

const Instructions = () => (
  <div className="report-disclaimer">
    <h3>{t.RD4R('Instructions')}</h3>
    <strong>{t.JC42('Purchased devices')}</strong>
    {t.VVDT(
      'The report includes all devices and accessories that the company has purchased as either one-time or monthly payments.'
    )}
    <strong>{t.KZ5M('Elisa Palvelupäätelaitteet')}</strong>
    {t.PR0R(
      'The report includes the phones, tablets, and computers purchased through Elisa Palvelupäätelaitteet (Elisa Device as a Service).'
    )}
    <strong>{t.C02I('Lifecycle of Elisa Palvelupäätelaitteet devices')}</strong>
    {t.PRK5(
      'The report includes the following information for the Elisa Palvelupäätelaitteet (Elisa Device as a Service) service: returned devices and devices to be returned, maintenance events, device replacements, redeemed devices, and penalty fees for missing or late returns.'
    )}
    <strong>{t.YJMM('Lease liability for Elisa Palvelupäätelaitteet')}</strong>
    {t.KUZQ(
      'The report shows the lease liability calculation for the Elisa Palvelupäätelaitteet (Elisa Devices as a Service) service. The calculation includes the company’s share of costs for devices on their basic contract period, and any monthly costs for the damage service. The costs are calculated on the report creation date.'
    )}
  </div>
);

const ReportForm = ({ accounts, handleCreatingNew, isMultibiz, isSubmitting }: ReportFormProps) => {
  const defaultValues = {
    reportType: 'devices_and_accessories_report',
    selectedMdmIds: isMultibiz ? [] : [accounts[0].mdmId],
    key: 0,
  };
  const methods = useForm<ReportFormValues>({ defaultValues });
  const { handleSubmit, setValue, watch } = methods;
  const shouldDisableEppOptions =
    (!isMultibiz && !accounts[0].eppSolutionActive) || !accounts.find(acc => acc.eppSolutionActive);
  const selectedReportTypeFieldName = 'reportType';
  const selectedMdmIdsFieldName = 'selectedMdmIds';
  const selectedReportType = watch(selectedReportTypeFieldName);
  const key = watch('key');

  useEffect(() => {
    const { unsubscribe } = watch((values: ReportFormValues) => {
      const { reportType, selectedMdmIds } = values;
      // If some accounts without active EPP solution are selected and report type selection of
      // epp_device_mobile_and_pc_report or epp_device_lifecycle_report is made, we need to uncheck these account
      // selections as the checkboxes for them will get disabled.
      if (
        reportType === ReportType.epp_device_mobile_and_pc_report ||
        reportType === ReportType.epp_device_lifecycle_report ||
        reportType === ReportType.epp_device_mobile_and_pc_report ||
        reportType === ReportType.epp_device_leasing_liabilities_report
      ) {
        const nonEppMdmIds = accounts.filter(acc => !acc.eppSolutionActive).map(acc => acc.mdmId);
        if (selectedMdmIds.some(mdmId => nonEppMdmIds.includes(mdmId))) {
          const updatedSelectedMdmIds = selectedMdmIds.filter(mdmId => !nonEppMdmIds.includes(mdmId));
          setValue(selectedMdmIdsFieldName, updatedSelectedMdmIds);
          setValue('key', key + 1);
        }
      }
    });
    return () => unsubscribe();
  }, [accounts, key, setValue, watch]);

  const onSubmit = async ({ reportType, selectedMdmIds }: ReportFormValues) => {
    if (!selectedMdmIds || selectedMdmIds.length === 0) {
      return;
    }
    handleCreatingNew(selectedMdmIds, reportType as ReportType);
  };

  const radioItems = [
    { label: t.ML02('Purchased devices'), value: 'devices_and_accessories_report' },
    {
      label: t.KZ5M('Elisa Palvelupäätelaitteet'),
      value: 'epp_device_mobile_and_pc_report',
      disabled: shouldDisableEppOptions,
    },
    {
      label: t.KQ9B('Lifecycle of Elisa Palvelupäätelaitteet devices'),
      value: 'epp_device_lifecycle_report',
      disabled: shouldDisableEppOptions,
    },
    {
      label: t.G0XR('Lease liability for Elisa Palvelupäätelaitteet'),
      value: 'epp_device_leasing_liabilities_report',
      disabled: shouldDisableEppOptions,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={dsClass.MARGIN_BOTTOM_4}>
        <h3>{t.T57J('Create new report')}</h3>
        <SelectRadio label={t.T57I('Report')} items={radioItems} name={selectedReportTypeFieldName} />
        {isMultibiz && (
          <>
            <CL.Label labelFor="selectedCompanies">{t.T57L(companiesMsg)}</CL.Label>
            <CheckboxTreeComponent
              data={accounts
                .slice()
                .sort((a, b) => a.companyName.localeCompare(b.companyName))
                .map(company => ({
                  label: company.companyName,
                  value: company.mdmId,
                  disabled:
                    selectedReportType !== ReportType.devices_and_accessories_report && !company.eppSolutionActive,
                }))}
              error={t.O6ZK('Select at least one company')}
              name={selectedMdmIdsFieldName}
              required={isMultibiz}
            />
          </>
        )}
        <CL.Button
          type="submit"
          color="light"
          loading={isSubmitting}
          className={isMultibiz ? dsClass.MARGIN_TOP_4 : dsClass.MARGIN_TOP_2}
        >
          {t.T57K('Create report')}
        </CL.Button>
      </form>
    </FormProvider>
  );
};

const isSameMdmIds = (report1: string[], report2?: string[]) => {
  return !!report2 && report1.length === report2.length && report1.every(mdmId => report2.includes(mdmId));
};

const isReportCreationInProgress = (status?: ReportStatus) =>
  !!status && (status === ReportStatus.New || status === ReportStatus.Running);

export const Reports = ({ accounts, authenticatedUser, latestReportStatusChange, reports }: ReportsProps) => {
  const { revalidate } = useRevalidator();
  const existingReportsRef = useRef<HTMLDivElement>(null);
  const isMultibiz = accounts.length > 1;
  const [latestReportStatusChangeState, setLatestReportStatusChangeState] = useState<ReportStatusChange | undefined>(
    latestReportStatusChange
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const showSuccessMessage = useCallback(
    () => dispatch(startNotification(t.T57O('Report created successfully'))),
    [dispatch]
  );

  const showErrorMessage = useCallback(
    (errorMessage?: string) => dispatch(startNotification(errorMessage || t.T57P('Report creation failed'), 'error')),
    [dispatch]
  );

  useEffect(() => {
    if (
      !latestReportStatusChange ||
      (latestReportStatusChange.onlineReportId === latestReportStatusChangeState?.onlineReportId &&
        latestReportStatusChange.status === latestReportStatusChangeState?.status)
    ) {
      return;
    }
    setLatestReportStatusChangeState(latestReportStatusChange);
    revalidate();

    if (latestReportStatusChange?.status === ReportStatus.Success) {
      showSuccessMessage();
    } else {
      showErrorMessage();
    }
  }, [latestReportStatusChange, latestReportStatusChangeState, revalidate, showErrorMessage, showSuccessMessage]);

  const handleUpdate = async (reportToUpdate: ReportItemResponse) => {
    try {
      const res = await generateReport(reportToUpdate.onlineReportType!, reportToUpdate.accountMasterIds);
      if (!res.ok) {
        showErrorMessage();
        return;
      }
      revalidate();
      existingReportsRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      showErrorMessage();
    }
  };

  const handleCreatingNew = async (selectedMdmIds: string[], reportType: ReportType) => {
    setIsSubmitting(true);
    const existingReport = reports.find(
      report =>
        report.accountMasterIds &&
        isSameMdmIds(report.accountMasterIds, selectedMdmIds) &&
        report.onlineReportType === reportType
    );
    try {
      const res = await generateReport(reportType as ReportType, selectedMdmIds);
      if (!res.ok) {
        isReportCreationInProgress(existingReport?.status)
          ? showErrorMessage(t.EJTJ('Report creation is already in progress'))
          : showErrorMessage();
        return;
      }
      revalidate();
      existingReportsRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      showErrorMessage();
    } finally {
      setIsSubmitting(false);
    }
  };

  const sortReports = (reportsToSort: ReportItemResponse[]) => {
    const statusOrder = (status?: ReportStatus) => {
      if (status === ReportStatus.New || status === ReportStatus.Running) {
        return -1;
      }
      return 0;
    };
    return reportsToSort.sort((a, b) => {
      const statusComparison = statusOrder(a.status) - statusOrder(b.status);
      if (statusComparison !== 0) {
        return statusComparison;
      }
      return a.updated! > b.updated! ? -1 : 1;
    });
  };

  return (
    <Grid className="of-reports">
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthL={3}>
          <ReportForm
            accounts={accounts}
            handleCreatingNew={handleCreatingNew}
            isMultibiz={isMultibiz}
            isSubmitting={isSubmitting}
          />
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthL={6}>
          <div ref={existingReportsRef}>
            <h3>{t.T57M('Previously created reports')}</h3>
            {reports.length > 0 ? (
              sortReports(reports).map((report, i) => (
                <ReportDetails
                  key={i}
                  report={report}
                  authenticatedUser={authenticatedUser!}
                  isCreating={isReportCreationInProgress(report.status)}
                  isMultibiz={isMultibiz}
                  handleUpdate={handleUpdate}
                />
              ))
            ) : (
              <p>{t.ITD0('No reports')}</p>
            )}
          </div>
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthL={3}>
          <Instructions />
        </CL.GridCol>
      </CL.GridRow>
    </Grid>
  );
};
