import type { OnlineModel } from '../../../generated/api/onlineModel';
import type { OnlineModelHeader } from '../../../generated/api/onlineModelHeader';
import type { OnlineModelsResponse } from '../../../generated/api/onlineModelsResponse';

interface HeadersAndModelsByCode {
  onlineModelsByCode?: Record<string, OnlineModel>;
  onlineModelHeadersByCode?: Record<string, OnlineModelHeader>;
}
export interface AccountSpecificOnlineModels extends HeadersAndModelsByCode {
  onlineModelsByVoucher?: Record<string, HeadersAndModelsByCode>;
  failedCodes?: string[];
}
export enum PrivateQueryActionType {
  GET_PRIVATE_ONLINE_MODELS_SUCCESS,
}
export interface PrivateQueryAction {
  type: PrivateQueryActionType;
  response?: OnlineModelsResponse;
  onlineModelCodes?: string[];
  voucherCode?: string;
}

const getModelsAndHeaderByKey = (
  action: PrivateQueryAction,
  initialModels: Record<string, OnlineModel>,
  initialHeaders: Record<string, OnlineModelHeader>
): HeadersAndModelsByCode => {
  const modelsByModelCodes =
    action.response?.models?.reduce<Record<string, OnlineModel>>((acc, obj) => {
      acc[obj.onlineModelCode] = obj;
      return acc;
    }, initialModels) ?? {};
  const headersByModelCodes =
    action.response?.headers?.reduce<Record<string, OnlineModelHeader>>((acc, obj) => {
      acc[obj.onlineModelCode] = obj;
      return acc;
    }, initialHeaders) ?? {};
  return {
    onlineModelsByCode: modelsByModelCodes,
    onlineModelHeadersByCode: headersByModelCodes,
  };
};

const updateReducerByVoucher = (
  state: AccountSpecificOnlineModels,
  action: PrivateQueryAction,
  voucherCode: string
): AccountSpecificOnlineModels => {
  const onlineModelsByVoucher = state?.onlineModelsByVoucher ?? {};
  const { onlineModelsByCode, onlineModelHeadersByCode } = getModelsAndHeaderByKey(
    action,
    (state?.onlineModelsByVoucher ?? {})[voucherCode]?.onlineModelsByCode ?? {},
    (state?.onlineModelsByVoucher ?? {})[voucherCode]?.onlineModelHeadersByCode ?? {}
  );
  onlineModelsByVoucher[voucherCode] = {
    onlineModelHeadersByCode,
    onlineModelsByCode,
  };
  switch (action.type) {
    case PrivateQueryActionType.GET_PRIVATE_ONLINE_MODELS_SUCCESS:
      return {
        ...state,
        onlineModelsByVoucher,
      };
    default:
      return { ...state };
  }
};

export const privateOnlineModelReducer = (
  state: AccountSpecificOnlineModels,
  action: PrivateQueryAction
): AccountSpecificOnlineModels => {
  const voucherCode = action.voucherCode;
  if (voucherCode) {
    return updateReducerByVoucher(state, action, voucherCode);
  }
  const { onlineModelsByCode, onlineModelHeadersByCode } = getModelsAndHeaderByKey(
    action,
    state?.onlineModelsByCode ?? {},
    state?.onlineModelHeadersByCode ?? {}
  );
  switch (action.type) {
    case PrivateQueryActionType.GET_PRIVATE_ONLINE_MODELS_SUCCESS:
      return {
        ...state,
        onlineModelHeadersByCode,
        onlineModelsByCode,
      };
    default:
      return { ...state };
  }
};
