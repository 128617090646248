import * as CL from '@design-system/component-library';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import type { CatalogProduct } from '../../common/utils/catalogUtils';

interface ProductSelectionCheckboxProps {
  product: CatalogProduct;
}

export const ProductSelectionCheckbox = ({ product }: ProductSelectionCheckboxProps) => {
  const { watch, setValue } = useFormContext();
  const selected = watch('selectedProductCodes');
  const current = selected?.includes(product.code);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProductCodes = event.target.checked
      ? [...selected, product.code]
      : selected.filter((code: string) => code !== product.code);
    setValue('selectedProductCodes', selectedProductCodes);
  };

  return (
    <CL.Checkbox
      // TODO: Workaround to force CL.Checkbox to rerender after checked prop update. See https://atlas.elisa.fi/jira/browse/DS-1592.
      key={`${product.code}.selected.${current}}`}
      name={product.code}
      checked={current}
      onChange={onChange}
      label=""
    />
  );
};
