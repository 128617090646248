import { PrivateQueryActionType } from './privateOnlineModelReducer';
import { fetchPrivateOnlineModels } from '../../../common/fetch';
import { filterPricesByAccountSettings } from '../../../common/utils/priceUtils';
import { isEmployeeUser } from '../../../components/ProductDetails/utils/productDetailsEmployeeUtils';
import type { AuthenticatedUserState, CompanyInfoState } from '../../../common/types/states';
import type { Dispatch } from 'react';
import type { OnlineModel } from '../../../generated/api/onlineModel';
import type { OnlineModelHeader } from '../../../generated/api/onlineModelHeader';
import type { PrivateQueryAction } from './privateOnlineModelReducer';

interface QueueModelCodes {
  queue: Set<string>;
  loading: Set<string>;
  loaded: Set<string>;
}
export interface AsyncOnlineModelQueueData {
  def: QueueModelCodes;
  loadedByVoucher: {
    [voucherCode: string]: QueueModelCodes;
  };
  activeVoucherCode?: string;
  isUpdating: boolean;
}

const BATCH_SIZE = 50;
export const batchQueryPrivateOnlineModels = async (
  current: QueueModelCodes,
  dispatch: Dispatch<PrivateQueryAction>,
  activeVoucher?: string
) => {
  if (current.loading.size > 0) {
    const array = Array.from(current.loading);
    const batchedCodes = [];
    for (let i = 0; i < array.length; i += BATCH_SIZE) {
      batchedCodes.push(array.slice(i, i + BATCH_SIZE));
    }
    await Promise.all(
      batchedCodes.map(async onlineModelCodes => {
        const response = await fetchPrivateOnlineModels({ onlineModelCodes, voucherCode: activeVoucher });
        dispatch({
          type: PrivateQueryActionType.GET_PRIVATE_ONLINE_MODELS_SUCCESS,
          response: response,
          voucherCode: activeVoucher,
        });
      })
    );
    array.forEach(code => {
      current.loaded.add(code);
      current.loading.delete(code);
    });
  }
};

const getOnlineModelHeader = (
  onlineModelHeader: OnlineModelHeader,
  onlineModelHeadersByCode?: Record<string, OnlineModelHeader>
) =>
  onlineModelHeadersByCode && onlineModelHeader.onlineModelCode in onlineModelHeadersByCode
    ? onlineModelHeadersByCode[onlineModelHeader.onlineModelCode]
    : onlineModelHeader;

export const getFilteredHeaders = (
  headers: OnlineModelHeader[],
  companyInfo?: CompanyInfoState | null,
  authenticatedUser?: AuthenticatedUserState | null,
  onlineModelHeadersByCode?: Record<string, OnlineModelHeader>
) => {
  return filterPricesByAccountSettings(
    headers
      .filter(onlineModelHeader => !onlineModelHeader.tags?.includes('Hide from list'))
      .map(onlineModelHeader => getOnlineModelHeader(onlineModelHeader, onlineModelHeadersByCode)),
    companyInfo,
    authenticatedUser
  );
};

export const discountsLoaded = (
  user: AuthenticatedUserState | undefined | null,
  models: OnlineModelHeader[] | OnlineModel[],
  accountSpecificModels?: Record<string, OnlineModelHeader> | Record<string, OnlineModel>
) => {
  const privateModels = accountSpecificModels ?? {};
  if (user && !isEmployeeUser(user)) {
    return !(
      Object.keys(privateModels).length === 0 || models.some(model => !(model.onlineModelCode in privateModels))
    );
  }
  return true;
};
