import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../../components/DialogWrapper';
import { EmptyOrError, getGenericSystemError } from '../../components/EmptyOrError/index.js';
import { Header } from '../../components/Header/Header.js';
import { Locale, switchingCompanyToMsg, t } from '../../common/i18n/index.js';
import { Maintenance } from './Maintenance.js';
import { NOINDEX_NOFOLLOW, ROBOTS_META_SELECTOR, setElementBySelectorAndContent } from '../../common/hooks/useMeta.js';
import { PublicPageContent } from './path/PublicPath.js';
import { SelfServiceLogin } from '../../components/SelfServiceLoginScene/SelfServiceLogin.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fetchPublicPage } from '../../common/fetch.js';
import { isRouteErrorResponse, useNavigation, useRevalidator, useRouteError } from 'react-router-dom';
import { useAuth } from './AuthProvider.js';
import { useEffect, useState } from 'react';
import type { PageResponse } from '../../generated/api/models.js';

export const SUPPORTED_SITE_LANGUAGES = {
  en: Locale.EN,
  fi: Locale.FI,
  sv: Locale.SV,
};

export const Spinner = () => (
  <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.PADDING_8} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
    <CL.LoadingSpinner size="xl" />
  </div>
);

export interface SpinnerWithSwitchAccountInfoTextProps {
  switchToAccountName: string;
}

export const SpinnerWithSwitchAccountInfoText = ({ switchToAccountName }: SpinnerWithSwitchAccountInfoTextProps) => (
  <>
    <div
      className={`${dsClass.DISPLAY_FLEX} ${dsClass.PADDING_TOP_10} ${dsClass.PADDING_BOTTOM_4} ${dsClass.JUSTIFY_CONTENT_CENTER}`}
    >
      <CL.LoadingSpinner size="xl" />
    </div>
    <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
      <span className={dsClass.TEXT_LARGE}>
        {t.IGKK(switchingCompanyToMsg)} <b>{switchToAccountName}</b> {t.HQ7C('')}
      </span>
    </div>
  </>
);

/**
 * Responsible for loading spinner when moving to other pages through the SPA links
 * Not responsible for the initial loading spinner after full page load
 */
export const DataLoader = ({ children }: { children?: JSX.Element }) => {
  const isLoading = useNavigation().state === 'loading';
  if (isLoading) {
    return <Spinner />;
  }
  return children || <></>;
};

export const DataLoaderDialog = () =>
  useNavigation().state === 'loading' ? (
    <DialogWrapper size="s" onCloseDialog={() => undefined} closeable={false}>
      <Spinner />
    </DialogWrapper>
  ) : (
    <></>
  );

const NotFoundPage = () => {
  const [page, setPage] = useState<PageResponse | null>(null);
  useEffect(() => {
    setElementBySelectorAndContent(ROBOTS_META_SELECTOR, NOINDEX_NOFOLLOW);
    fetchPublicPage('/404').then(res => setPage(res));
  }, []);

  return (
    <>
      <Header />
      {page ? <PublicPageContent {...page} /> : <Spinner />}
    </>
  );
};

const GenericErrorPage = () => {
  return (
    <>
      <Header />
      <EmptyOrError {...getGenericSystemError()} />
    </>
  );
};

const isAuthError = (error: unknown) => isRouteErrorResponse(error) && error.status === 401;

export const AuthErrorBoundary = () => {
  const error = useRouteError();
  const revalidator = useRevalidator();
  const authState = useAuth().isAuthenticated;

  useEffect(() => {
    if (isAuthError(error) && authState) {
      // Auth state has changed, presumably user has logged in, let's revalidate.
      // TODO: leo 30.5.2024 - Ideally the revalidation should happen directly due to user interaction, pressing the login button, but that's not feasible until Redux is removed.
      revalidator.revalidate();
    }
  }, [authState, error, revalidator]);

  if (isAuthError(error)) {
    return <SelfServiceLogin />;
  }

  throw error; // Let the error bubble.
};

/**
 * Errors will bubble up through parent routes so this can be given to multiple routes
 * If below nested route has the errorElement in it, it will be rendered instead of the above errorElement
 * Above routes must have the Outlet in order to render other content with the error like header or footer
 */
export const RootErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        return <NotFoundPage />;

      case 503:
        return (
          <>
            <Header cmsPage isFullVersion={false} />
            <Maintenance />
          </>
        );
    }
  }
  return <GenericErrorPage />;
};
