import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';

export interface FormWrapperProps {
  children: JSX.Element;
  preSelected?: string[];
  onSubmit: (data: DeviceSelection, e?: React.BaseSyntheticEvent) => void;
}

export interface DeviceSelection {
  selectedProductCodes: string[];
}

export const CatalogProductSelectionFormWrapper = ({ children, preSelected, onSubmit }: FormWrapperProps) => {
  const methods = useForm({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: {
      selectedProductCodes: preSelected,
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
