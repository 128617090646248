import { isInBrowser } from '../../common/utils/ssrUtils.js';
import type { DeviceChangeRequest } from '../../common/types/device.js';

/**
 * Shopping-Cart
 */
export const CART_ITEMS_LOCAL_STORAGE_KEY = 'ngStorage-cartItems-default';
const CART_ID_LOCAL_STORAGE_KEY = 'ngStorage-cartItems-settings';
export const NEW_CATALOG_CODE_LOCAL_STORAGE_KEY = 'newVirtualCatalogCode';
export const DELETED_CATALOG_CODE_LOCAL_STORAGE_KEY = 'deletedVirtualCatalogCode';

export const getShoppingCart = (): string => {
  return (isInBrowser() && localStorage.getItem(CART_ITEMS_LOCAL_STORAGE_KEY)) || '[]';
};

export const setShoppingCart = (item: string) => {
  const prevCart = getShoppingCart();
  // Only set the cart when there's actual changes, otherwise syncing cart via LocalStorage between multiple tabs
  // will cause infinite loop.
  if (prevCart !== item) {
    isInBrowser() && localStorage.setItem(CART_ITEMS_LOCAL_STORAGE_KEY, item);
  }
};

export const clearShoppingCart = () => {
  return isInBrowser() && localStorage.setItem(CART_ITEMS_LOCAL_STORAGE_KEY, '[]');
};

/**
 * Shopping-basket for public shop and OmaElisa
 */
export const SHOPPING_BASKET_LOCAL_STORAGE_KEY = 'shopping-basket';

export const clearShoppingBasketFromLocalstorage = () => {
  return isInBrowser() && localStorage.removeItem(SHOPPING_BASKET_LOCAL_STORAGE_KEY);
};

/**
 * Shopping-Cart ID
 */
export const getShoppingCartId = () => {
  if (isInBrowser()) {
    const jsonAsString = localStorage.getItem(CART_ID_LOCAL_STORAGE_KEY);

    if (jsonAsString) {
      const jsonAsObject = JSON.parse(jsonAsString);
      return jsonAsObject.shoppingCartId ? jsonAsObject.shoppingCartId : null;
    }
  }
};

export const setShoppingCartId = (id: string) => {
  return isInBrowser() && localStorage.setItem(CART_ID_LOCAL_STORAGE_KEY, JSON.stringify({ shoppingCartId: id }));
};

export const removeShoppingCartId = () => {
  return isInBrowser() && localStorage.removeItem(CART_ID_LOCAL_STORAGE_KEY);
};

/**
 * Selected Catalog
 */
export const SELECTED_CATALOG_LOCAL_STORAGE_KEY = 'selected-catalog-code';

export const getCatalogCode = () => {
  return isInBrowser() && localStorage.getItem(SELECTED_CATALOG_LOCAL_STORAGE_KEY);
};

export const setCatalogCode = (code: string) => {
  return isInBrowser() && localStorage.setItem(SELECTED_CATALOG_LOCAL_STORAGE_KEY, code);
};

export const removeCatalogCode = () => {
  return isInBrowser() && localStorage.removeItem(SELECTED_CATALOG_LOCAL_STORAGE_KEY);
};

/**
 * Account Master ID
 */
export const ACTIVE_ACCOUNT_MASTER_ID_LOCAL_STORAGE_KEY = 'mdmId';

export const getActiveAccountMasterId = () => {
  return (isInBrowser() && localStorage.getItem(ACTIVE_ACCOUNT_MASTER_ID_LOCAL_STORAGE_KEY)) || undefined;
};

export const setActiveAccountMasterId = (activeAccountMasterId: string) => {
  return isInBrowser() && localStorage.setItem(ACTIVE_ACCOUNT_MASTER_ID_LOCAL_STORAGE_KEY, activeAccountMasterId);
};

export const removeActiveAccountMasterId = () => {
  return isInBrowser() && localStorage.removeItem(ACTIVE_ACCOUNT_MASTER_ID_LOCAL_STORAGE_KEY);
};

/**
 * Switch to account name
 */
export const SWITCH_TO_ACCOUNT_NAME_LOCAL_STORAGE_KEY = 'switchToAccountName';

export const getSwitchToAccountName = () => {
  return isInBrowser() && localStorage.getItem(SWITCH_TO_ACCOUNT_NAME_LOCAL_STORAGE_KEY);
};

export const setSwitchToAccountName = (switchToAccountName: string) => {
  return isInBrowser() && localStorage.setItem(SWITCH_TO_ACCOUNT_NAME_LOCAL_STORAGE_KEY, switchToAccountName);
};

export const removeSwitchToAccountName = () => {
  return isInBrowser() && localStorage.removeItem(SWITCH_TO_ACCOUNT_NAME_LOCAL_STORAGE_KEY);
};

export const isMigratedContentHidden = () => {
  return isInBrowser() && !localStorage.getItem('OF-show-migrated-content');
};

/**
 * User has seen notification for shopping basket company selection
 */
export const USER_HAS_SEEN_COMPANY_SELECTION_NOTIFICATION_SHOPPING_BASKET_LOCAL_STORAGE_KEY =
  'userHasSeenCompanySelectionNotificationShoppingBasket';

export const getUserHasSeenCompanySelectionNotificationShoppingBasket = () =>
  isInBrowser() && localStorage.getItem(USER_HAS_SEEN_COMPANY_SELECTION_NOTIFICATION_SHOPPING_BASKET_LOCAL_STORAGE_KEY);

export const setUserHasSeenCompanySelectionNotificationShoppingBasket = () =>
  isInBrowser() &&
  localStorage.setItem(USER_HAS_SEEN_COMPANY_SELECTION_NOTIFICATION_SHOPPING_BASKET_LOCAL_STORAGE_KEY, 'true');

/**
 * Punchout
 */
const PUNCHOUT_CHANGE_OBJECT_KEY = 'punchout-changeObject';

export const getPunchoutDeviceChangeRequest = (): DeviceChangeRequest | undefined =>
  (isInBrowser() && JSON.parse(sessionStorage.getItem(PUNCHOUT_CHANGE_OBJECT_KEY) || 'null')) || undefined;

export const setPunchoutDeviceChangeRequest = (deviceChangeRequest: DeviceChangeRequest) =>
  isInBrowser() && sessionStorage.setItem(PUNCHOUT_CHANGE_OBJECT_KEY, JSON.stringify(deviceChangeRequest));

export const removePunchoutDeviceChangeRequest = () =>
  isInBrowser() && sessionStorage.removeItem(PUNCHOUT_CHANGE_OBJECT_KEY);

/**
 * sessionId for the history/conversation in ai-chat powered by AWS Lambda/DynamoDB
 */
const AI_CHAT_LOCAL_STORAGE_KEY = 'ai-chat-session-id';

export const getAiChatSessionId = () => {
  return isInBrowser() && localStorage.getItem(AI_CHAT_LOCAL_STORAGE_KEY);
};

export const setAiChatSessionId = (sessionId: string) => {
  isInBrowser() && localStorage.setItem(AI_CHAT_LOCAL_STORAGE_KEY, sessionId);
};

export const removeAiChatSessionId = () => {
  isInBrowser() && localStorage.removeItem(AI_CHAT_LOCAL_STORAGE_KEY);
};

/**
 * elisa id v2 login
 */
export const AUTH_STATE_SESSION_KEY = 'auth_state';
export const AUTH_REDIRECTURI_SESSION_KEY = 'auth_redirectUri';

const clearSessionStorage = (key: string) => {
  if (isInBrowser()) {
    sessionStorage.removeItem(key);
  }
};

const getSessionStorageValue = (key: string) => (isInBrowser() ? sessionStorage.getItem(key) : undefined);

const clearAuthState = () => clearSessionStorage(AUTH_STATE_SESSION_KEY);
const clearAuthRedirectUri = () => clearSessionStorage(AUTH_REDIRECTURI_SESSION_KEY);

export const clearElisaIdSessionData = () => {
  clearAuthState();
  clearAuthRedirectUri();
};

export const getAuthState = () => getSessionStorageValue(AUTH_STATE_SESSION_KEY);

export const getAuthRedirectUrl = (): string | null | undefined => {
  return getSessionStorageValue(AUTH_REDIRECTURI_SESSION_KEY);
};
// multisim with elisa id v2 login
const MULTISIM_SUBSCRIPTION_TOKEN_KEY = 'subscriptionToken';
export const setMultisimSubscriptionToken = (multisimSubscriptionToken?: string) =>
  multisimSubscriptionToken && sessionStorage.setItem(MULTISIM_SUBSCRIPTION_TOKEN_KEY, multisimSubscriptionToken);
export const getMultisimSubscriptionToken = () => getSessionStorageValue(MULTISIM_SUBSCRIPTION_TOKEN_KEY);
export const clearMultisimSubscriptionToken = () => clearSessionStorage(MULTISIM_SUBSCRIPTION_TOKEN_KEY);

export const PRIMARY_MDM_ID_LOCAL_STORAGE_KEY = 'primaryMdmId';

export const getPrimaryMdmId = () => {
  return (isInBrowser() && localStorage.getItem(PRIMARY_MDM_ID_LOCAL_STORAGE_KEY)) || undefined;
};

export const setPrimaryMdmId = (activeAccountMasterId: string) => {
  return isInBrowser() && localStorage.setItem(PRIMARY_MDM_ID_LOCAL_STORAGE_KEY, activeAccountMasterId);
};

export const removePrimaryMdmId = () => {
  return isInBrowser() && localStorage.removeItem(PRIMARY_MDM_ID_LOCAL_STORAGE_KEY);
};

export const INVOICE_PAYMENT_MDM_ID_LOCAL_STORAGE_KEY = 'invoicePaymentMdmId';

export const getInvoicePaymentMdmId = () => {
  return (isInBrowser() && localStorage.getItem(INVOICE_PAYMENT_MDM_ID_LOCAL_STORAGE_KEY)) || undefined;
};

export const setInvoicePaymentMdmId = (mdmId: string) => {
  return isInBrowser() && localStorage.setItem(INVOICE_PAYMENT_MDM_ID_LOCAL_STORAGE_KEY, mdmId);
};

export const removeInvoicePaymentMdmId = () => {
  return isInBrowser() && localStorage.removeItem(INVOICE_PAYMENT_MDM_ID_LOCAL_STORAGE_KEY);
};
