import { GridNarrow } from '../../../components/Grid/Grid.js';
import { LoginAndRegistration } from '../../../components/LoginAndRegistration/LoginAndRegistration.js';
import { ScrollToTop } from '../../../common/utils/browserUtils';
import { ShoppingBasket } from '../../../components/ShoppingBasket/ShoppingBasket.js';
import { Spinner } from '../siteUtils.js';
import {
  changePaymentOption,
  changeQuantityInBasket,
  findBasketItemForId,
  getAnalyticsEventForAddOrRemoveItem,
} from '../../../components/ShoppingBasket/shoppingBasketUtils.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getUserAccounts } from '../../../components/Header/dynamic/headerFunctions.js';
import { parseBasketJson, useShoppingBasket } from '../../../common/hooks/useShoppingBasket.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { pushToDataLayer } from '../../../common/analytics';
import { useAuth } from '../AuthProvider.js';
import { useEffect } from 'react';
import { useFirstRender } from '../../../common/hooks/useFirstRender.js';
import { useLocation, useNavigate, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { ChangePaymentOptions } from '../../../components/ShoppingBasket/types';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { ShoppingBasketType } from '../../../common/types/shoppingBasket.js';
import type { State } from '../../../selfservice/common/store.js';

export const ShoppingBasketPath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const firstRender = useFirstRender();

  const loginInProgress = location.state?.loginInProgress === true;
  const { isAuthenticated, authenticatedUser } = useAuth();
  const loaderData = useRouteLoaderData('shoppingBasketRoot') as OnlineModel[];
  const { companyInfo, eppSolution } = useSelector((state: State) => {
    return {
      companyInfo: state.selfservice?.companyInfo,
      eppSolution: state.selfservice?.companyInfo?.eppSolution,
    };
  }, deepEqual);

  const { shoppingBasket, setShoppingBasket } = useShoppingBasket();
  const shoppingCartJson: ShoppingBasketType = parseBasketJson(shoppingBasket);

  // Revalidate the loader (load onlinemodels) only if cart item amount changes and not on first render
  useEffect(() => {
    if (revalidator.state === 'idle' && !firstRender) {
      revalidator.revalidate();
    }
  }, [shoppingCartJson.items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeQuantity = (cartItemId: string, quantity: number) => {
    const basketItem = findBasketItemForId(shoppingCartJson.items, cartItemId);
    const analyticsEvent = getAnalyticsEventForAddOrRemoveItem(basketItem, quantity, loaderData);
    if (analyticsEvent) {
      pushToDataLayer(analyticsEvent);
    }
    setShoppingBasket(JSON.stringify(changeQuantityInBasket(shoppingCartJson, cartItemId, quantity)));
  };

  const onPaymentOptionChange = (changePaymentOptions: ChangePaymentOptions) => {
    setShoppingBasket(JSON.stringify(changePaymentOption(changePaymentOptions)));
  };

  const userAccounts = getUserAccounts(authenticatedUser) || [];

  const isFetching = authenticatedUser?.isFetching;
  const isCompanyNameEmpty = companyInfo?.companyName && companyInfo?.companyName?.length === 0;
  /* Checkout page initiates a bug situation:
    When user arrives back here from Checkout the companyInfo?.companyInfoLoading is left to true.
    This is because it dispatches LOAD_COMPANY_INFO but never gets _FULFILLED or _FAILED state.
    Therefore, use company name to determine when page is loading.
   */
  const isLoading = isFetching || (isFetching === false && isCompanyNameEmpty === undefined);

  useEffect(() => {
    if (!isLoading && loginInProgress && userAccounts.length === 1) {
      // If there is no need for user to select company first: reset state
      navigate(paths.SHOPPING_BASKET, { replace: true });
    }
  }, [loginInProgress, isLoading, navigate, userAccounts.length]);

  if (isLoading) {
    return <Spinner />;
  }

  return !isAuthenticated && loginInProgress ? (
    <GridNarrow>
      <ScrollToTop />
      <LoginAndRegistration />
    </GridNarrow>
  ) : (
    <div>
      <ShoppingBasket
        userAccounts={userAccounts}
        basketItems={shoppingCartJson}
        discountedPrices={companyInfo?.discountedPrices || []}
        models={loaderData}
        loggedIn={isAuthenticated}
        loginInProgress={loginInProgress}
        changeQuantity={changeQuantity}
        onPaymentOptionChange={onPaymentOptionChange}
        eppSolution={eppSolution}
      />
    </div>
  );
};
