import * as CL from '@design-system/component-library';
import { ConsolidatedListView } from '../../components/ConsolidatedListView/ConsolidatedListView.js';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table, TableUrlParams } from '../Table/index.js';
import { Grid } from '../Grid/Grid.js';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import {
  OPEN_INVOICES_HASH,
  PAID_INVOICES_HASH,
  billingAccountMsg,
  companyMsg,
  dueDateMsg,
  paidMsg,
  payerMsg,
  t,
} from '../../common/i18n/index.js';
import { Status } from '../Status/Status.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatBalance, formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getStatusColumnInvoiceState } from '../InvoiceDetails/InvoiceDetails.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { ClickableText } from '../CompositeList/index.js';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { InvoicesSearchResponse } from '../../generated/api/models.js';

import './InvoiceList.scss';

export interface InvoiceListProps {
  category: string;
  extraEmptySearchResultLink?: ClickableText;
  invoices?: InvoicesSearchResponse[];
  total?: number;
}

export const getEmptyListText = (category: string) => {
  if (category === 'open') {
    return `${t.HS4R('No')} ${t.FOKG('open')} ${t.VME0('invoices')}`;
  }
  if (category === 'paid') {
    return `${t.HS4R('No')} ${t.QVCE('paid')} ${t.VME0('invoices')}`;
  }
  return `${t.HS4R('No')} ${t.VME0('invoices')}`;
};

export const InvoiceList = ({ category, extraEmptySearchResultLink, invoices, total }: InvoiceListProps) => {
  const { authenticatedUser } = useAuth();
  const navigate = useNavigate();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;

  const onStateChange = (stateOption = ''): void => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(TableUrlParams.PAGE, '1');
    queryParams.set(TableUrlParams.OFFSET, '0');
    switch (stateOption) {
      case 'open':
        navigate(`${paths.INVOICES}?${queryParams}#${OPEN_INVOICES_HASH}`);
        break;
      case 'paid':
        navigate(`${paths.INVOICES}?${queryParams}#${PAID_INVOICES_HASH}`);
        break;
      default:
        navigate(`${paths.INVOICES}?${queryParams}`);
    }
  };

  const dropDown = (
    <CL.Dropdown
      key={category}
      items={[
        { label: t.EY6A('All'), value: 'all' },
        { label: t.GMAP('Open'), value: 'open' },
        { label: t.UQHZ('Paid'), value: 'paid' },
      ]}
      selectedValue={category}
      className="of-table-header-dropdown"
      integrated={true}
      onValueChange={value => {
        onStateChange(value?.dataset?.value);
      }}
    />
  );

  const columns: CL.Column[] = [
    {
      key: 'invoiceDisplayId',
      label: t.BRFX('Invoice number'),
      sortable: true,
    },
    { key: 'billingAccountDisplayId', label: `${t.IFT9(billingAccountMsg)} *`, sortable: true },
    { key: 'payerName', label: `${t.PB6S(payerMsg)} *`, sortable: true },
    { key: 'due', label: t.LA93(dueDateMsg), sortable: true },
    {
      key: 'sum',
      label: t.P4RQ('Amount'),
      sortable: true,
    },
    { key: 'state', label: dropDown, sortable: false },
    { key: 'companyName', label: t.KJTS(companyMsg), sortable: false },
  ];

  const rows = invoices?.map(invoiceSearchResponse => {
    const invoice = invoiceSearchResponse.result;
    const matchedFields = invoiceSearchResponse.matchedFields;
    return {
      billingAccountDisplayId: (
        <span>
          {invoice?.billingAccountDisplayId}
          <br />
          <div className={dsClass.FONT_SIZE_SMALL}>{invoice.billingAccountName}</div>
          <div className={dsClass.FONT_SIZE_SMALL}>{invoice?.billingAccountNameExtension}</div>
        </span>
      ),
      payerName: (
        <span>
          {invoice?.payerName}
          <div className={dsClass.FONT_SIZE_SMALL}>{invoice?.payerNameExtension}</div>
        </span>
      ),
      invoiceDisplayId: (
        <>
          <Link
            to={`${generatePath(paths.INVOICE, { invoiceId: invoice.invoiceDisplayId })}?mdmId=${
              invoice.accountMasterId
            }`}
          >
            {matchedFields?.includes('invoiceDisplayId')
              ? highlightKeyword(invoice.invoiceDisplayId, search)
              : invoice.invoiceDisplayId}
          </Link>
          {search && matchedFields?.includes('invoiceDisplayId') && (
            <div className={dsClass.FONT_SIZE_SMALL}>
              {t.IFT9(billingAccountMsg)}: {highlightKeyword(invoice.billingAccountDisplayId || '', search)}
            </div>
          )}
        </>
      ),
      state: (
        <Status
          textAfterBadge={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).text}
          color={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).color}
          inTable={true}
        />
      ),
      due: formatTimestampToUTCDDMMYYYY(invoice.due),
      sum: (
        <span>
          {formatSum(invoice.sum)}
          <div className={dsClass.FONT_SIZE_SMALL}>
            {t.RJ27(paidMsg)} {formatBalance(invoice.sum, invoice.balance || 0)}
          </div>
        </span>
      ),
      companyName: getCompanyName(authenticatedUser, invoice.accountMasterId),
    };
  });

  return (
    <Grid colWidthL={12}>
      <div className="of-invoice-list">
        <ConsolidatedListView
          authenticatedUser={authenticatedUser}
          listSearch={
            <ListSearch
              className={dsClass.MARGIN_TOP_4}
              onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
            />
          }
          listElement={<Table columns={columns} noItemsText={getEmptyListText('')} rows={rows || []} />}
          listPagination={<ListPagination totalItems={search && rows ? rows.length : total || 0} />}
          wideLayout={true}
        />
        {category !== 'all' && rows?.length === 0 && (
          <p>
            <CL.Button onClick={extraEmptySearchResultLink?.onClick}>{extraEmptySearchResultLink?.text}</CL.Button>
          </p>
        )}
      </div>
      <div className={`${dsClass.TEXT_SMALL} ${dsClass.MARGIN_TOP_4}`}>
        * {t.YM3W('Showing the current information of the billing account.')}
      </div>
    </Grid>
  );
};
