import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType, StatusColor } from '../../common/enums.js';
import { IconButton } from '../IconButton/IconButton.js';
import { Loading } from '../Loading/index.js';
import { MobileIdDisabled } from './MobileIdDisabled.js';
import { Status } from '../Status/Status.js';
import {
  activeMsg,
  cancelMsg,
  closedMsg,
  contractsMsg,
  mobiilivarmenneForEmployeeMsg,
  mobiilivarmennePricePerUserMsg,
  omaElisaForCompaniesMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { terminateMobileIdContract } from '../../selfservice/actions/mobileIdContractActions.js';
import { useDispatch, useStore } from 'react-redux';
import { useState } from 'react';
import type { Contract } from '../../generated/api/models.js';
import type { GenericConfirmationDialogParams, GenericInfoDialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';

import './MobileId.scss';

export interface MobileIdDetailsProps {
  mobileIdContract: Contract;
}

export const STATUS_COLORS = {
  ACTIVATED: StatusColor.GREEN,
  TERMINATED: StatusColor.DARK_BLUE,
};

export const getContractStatus = () => ({
  ACTIVATED: t.SFRK(activeMsg),
  TERMINATED: t.UFGV(closedMsg),
});

export const MobileIdDetails = ({ mobileIdContract }: MobileIdDetailsProps) => {
  const dispatch = useDispatch();
  const [terminateInitiated, setTerminateInitiated] = useState(false);

  const items: CL.DescriptionItem[] = mobileIdContract
    ? [
        {
          title: t.ASQT(statusMsg),
          description: (
            <Status
              color={STATUS_COLORS[mobileIdContract.contractStatus]}
              textAfterBadge={getContractStatus()[mobileIdContract.contractStatus]}
            />
          ),
        },
        {
          title: t.REA8('Contract number'),
          description: `${mobileIdContract.contractNumber}`,
        },
        {
          title: t.SLOC('Service level'),
          description: `${mobileIdContract.contractName}`,
        },
        {
          title: t.STLY('Initial contract date'),
          description: `${formatTimestampToDDMMYYYY(mobileIdContract.contractStartDate)}`,
        },
        {
          title: t.P0PS('Monthly fee'),
          description: t.BJ25(mobiilivarmennePricePerUserMsg),
        },
      ]
    : [];

  const confirmTerminateDialogParams: GenericConfirmationDialogParams = {
    type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    header: t.EFXH('Contract termination'),
    hideClosingCross: true,
    confirmButtonText: t.I3DO('Confirm Termination'),
    onConfirm: () => {
      if (mobileIdContract) {
        dispatch(terminateMobileIdContract(mobileIdContract.contractNumber));
      }
    },
    body: (
      <>
        <div className={`${dsClass.MARGIN_VERTICAL_2} ${dsClass.FONT_WEIGHT_BOLD} ${dsClass.PADDING_TOP_1}`}>
          {t.S07L('Do you want to terminate Elisa Mobiilivarmenne for Employee service')}?
        </div>
        <div className={dsClass.MARGIN_VERTICAL_4}>
          {t.WAMK(
            'We will process your termination request as soon as possible. You will receive a confirmation email when the service is terminated. Please note that uncharged fees will be added to the next invoice.'
          )}
        </div>
      </>
    ),
  };

  const pendingTerminateDialogParams: GenericInfoDialogParams = {
    type: DialogType.GENERIC_INFO_DIALOG,
    header: t.G91V('A request to terminate the contract has already been made'),
    body: (
      <>
        <div className={`${dsClass.MARGIN_VERTICAL_2} ${dsClass.FONT_WEIGHT_BOLD} ${dsClass.PADDING_TOP_1}`}>
          {t.MTNX(
            'We will process your cancellation request as soon as possible. You will receive a confirmation by email when the service has been terminated.'
          )}
        </div>
        <div className={dsClass.MARGIN_VERTICAL_4}>
          {t.TCZK(
            'Please note that the contract is shown as active in the contract list until we have processed the termination request.'
          )}
        </div>
      </>
    ),
    buttonText: t.B2V1(cancelMsg),
  };

  return (
    <>
      {mobileIdContract ? (
        <>
          {terminateInitiated ? (
            <>
              <div className={`of-mobileid__title ${dsClass.FONT_WEIGHT_600} ${dsClass.PADDING_HORIZONTAL_1}`}>
                {t.LFZM('Terminate Elisa Mobiilivarmenne for Employee service')}
              </div>
              <div className={dsClass.MARGIN_VERTICAL_4}>
                {t.ZD17(
                  'After terminating the service, Elisa Mobiilivarmenne will continue to operate for two weeks with employees who have activated it. We will inform these employees with a text message about the possibility of ordering the service at their own expense.'
                )}
              </div>
              <div className={`of-mobileid__buttons ${dsClass.DISPLAY_FLEX}`}>
                <CL.Button
                  className={dsClass.MARGIN_RIGHT_1}
                  id="mobileid-confirmation-confirm"
                  onClick={() => dispatch(showDialog(confirmTerminateDialogParams))}
                >
                  {t.C0QM('Terminate')}
                </CL.Button>
                <CL.Button
                  className={dsClass.MARGIN_LEFT_2}
                  id="mobileid-confirmation-cancel"
                  onClick={() => setTerminateInitiated(false)}
                  color="link"
                >
                  {t.B2V1(cancelMsg)}
                </CL.Button>
              </div>
            </>
          ) : (
            <div className="of-subscription-details__mobileid-content">
              <CL.Description className="of-mobileid__description" id="subscription-details-device" items={items} />
              <div className={`of-subscription-details__actions ${dsClass.MARGIN_TOP_8} ${dsClass.MARGIN_BOTTOM_2}`}>
                <IconButton
                  classNames="of-mobileid__button"
                  key="terminate_mobileid"
                  label={t.D9LE('Terminate service')}
                  buttonKey="terminate_mobileid"
                  onClick={() =>
                    mobileIdContract?.pendingTermination
                      ? dispatch(showDialog(pendingTerminateDialogParams))
                      : setTerminateInitiated(true)
                  }
                  beginIcon="power"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export const MobileIdDetailsContent = ({ mobileIdContract }: MobileIdDetailsProps) => {
  const { featureFlags } = useStore<State>().getState().config;

  if (!isFeatureEnabled(featureFlags.mobileId)) {
    return <MobileIdDisabled />;
  }

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.SW0J(contractsMsg), path: paths.PS_CONTRACTS },
        { name: 'Mobiilivarmenne' },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={breadCrumbs}
      id="mobileid-confirmation"
      heading={t.XX5A(mobiilivarmenneForEmployeeMsg)}
      headingBottom={t.DA7N('Provide employees with a strong electronic identification tool')}
      heroIcon="mobiilivarmenne-hero"
    >
      <MobileIdDetails mobileIdContract={mobileIdContract} />
    </DetailsWrapper>
  );
};
