import * as CL from '@design-system/component-library';
import { Dropdown } from '../../common/react-hook-form/components/Dropdown';
import { PhoneNumber } from '../../common/react-hook-form/fields';
import { RespondentList } from './RespondentList';
import { ScheduledWorkingHours } from './ScheduledWorkingHours';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio';
import { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails';
import {
  activeChainItems,
  corporateNumberDropdownItems,
  extensionNumberOptions,
  getAllNumbersFromAllRanges,
  getErrorForOffWorkingTimeRespondents,
  getErrorForWorkingHourRespondents,
  numberConversionOptions,
} from './PbxAccordionUtils';
import {
  cancelMsg,
  confirmMsg,
  extensionMsg,
  numberConversionToBeDisplayedMsg,
  numberToDisplayMsg,
  t,
} from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import { findBestMatchForExtensionNumber } from '../../common/utils/pbxUtils';
import { useFormContext } from 'react-hook-form';
import { useNumberRanges } from '../../common/hooks/useNumberRanges';
import type { PbxSettingFormData } from './PbxAccordionUtils';
import type { Subscription } from '../../generated/api/subscription';
import CurrentActiveChainEnum = SubscriptionPbxDetails.CurrentActiveChainEnum;
import { NumberRange } from '../../generated/api/numberRange';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';

export interface PbxDetailsEditProps {
  pbxSolution: Subscription;
  setEditing: (editing: boolean) => void;
  isSaving: boolean;
}

const calloutTimeOptions = () => [
  { label: '15 s', value: '15' },
  { label: '20 s', value: '20' },
  { label: '25 s', value: '25' },
  { label: '30 s', value: '30' },
];

const busyActionOptions = () => [
  {
    label: t.EGRR('Forward to next number in chain'),
    value: SubscriptionPbxConfiguration.BusyActionEnum.NEXT_NUMBER_IN_CHAIN,
  },
  {
    label: t.J5SL('Busy signal'),
    value: SubscriptionPbxConfiguration.BusyActionEnum.BUSY_TONE,
  },
  {
    label: t.HS6F('Forward to'),
    value: SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER,
  },
];

export const PbxDetailsEdit = ({ pbxSolution, setEditing, isSaving }: PbxDetailsEditProps) => {
  const { watch, setValue, reset, clearErrors } = useFormContext();
  const formValues = watch() as PbxSettingFormData;
  const { corporateNumberRange, extensionNumberRange } = useNumberRanges(
    formValues.corporateNumberRangeId,
    formValues.extensionRangeId
  );

  const showCallerLineIdNumber =
    formValues.callerLineIdMasking !== SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE;
  const showConnectToNumber = formValues.busyAction === SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER;
  const showWorkingHours = formValues.currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR;

  const callerIdNumbers = getAllNumbersFromAllRanges(
    pbxSolution.details?.mobilePbx?.numberRanges?.filter(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
    ) ?? []
  ).map(rangeNumber => ({ label: formatPhoneNumber(rangeNumber, true), value: rangeNumber }));

  const isFirstNumberRequiredForOfficeHours =
    formValues.currentActiveChain === CurrentActiveChainEnum.CALENDAR ||
    formValues.currentActiveChain === CurrentActiveChainEnum.WORKING_HOURS;
  const isFirstNumberRequiredForOutOfOfficeHours = formValues.currentActiveChain === CurrentActiveChainEnum.OFF_WORK;

  const onSelectCorporateNumber = (selectedCorporateNumber: string) => {
    if (selectedCorporateNumber) {
      const bestMatch = findBestMatchForExtensionNumber(selectedCorporateNumber, extensionNumberRange?.allNumbers);
      setValue('extensionNumber', bestMatch);
    }
  };

  return (
    <div className="of-pbx-accordion-content">
      <CL.Grid className={dsClass.PADDING_TOP_4}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <Dropdown
              name="callerLineIdMasking"
              items={numberConversionOptions(formValues.pbxType)}
              label={t.O1MZ(numberConversionToBeDisplayedMsg)}
            />
          </CL.GridCol>
          {showCallerLineIdNumber && (
            <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
              <Dropdown name="callerLineIdNumber" items={callerIdNumbers} label={t.FL34(numberToDisplayMsg)} />
            </CL.GridCol>
          )}
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_6}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12}>
            <h4 className={`${dsClass.MARGIN_BOTTOM_0} ${dsClass.MARGIN_TOP_0}`}>
              {t.YN14('Reachability of company number')}
            </h4>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol
            className={`${dsClass.PADDING_TOP_4} of-pbx-accordion-content__chain_dropdown`}
            colsXS={4}
            colsS={6}
            colsM={3}
            colsL={6}
            colsXL={6}
          >
            <Dropdown
              name="currentActiveChain"
              items={activeChainItems()}
              label={t.MM8Z('Active state')}
              onValueChange={() => clearErrors()}
            />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      {showWorkingHours && (
        <CL.Grid className={dsClass.PADDING_TOP_4}>
          <CL.GridRow>
            <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12}>
              <div className={`${dsClass.PADDING_BOTTOM_4} ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} `}>
                <h4 className={`${dsClass.MARGIN_BOTTOM_0} ${dsClass.MARGIN_TOP_0} ${dsClass.PADDING_RIGHT_2}`}>
                  {t.Z40V('Scheduled office hours for company number')}
                </h4>
                <CL.Tooltip
                  triggerElement={
                    <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                  }
                  placement="top"
                  i18n_tooltip_contentText={t.NKFF(
                    'Select if the active state for the company number should be during office hours, outside of office hours, or according to scheduled office hours. ' +
                      'If you select the last option, you can set the time range when office-hour respondents are used. Outside of these hours, out-of-office respondents are used. ' +
                      'If you want to use the out-of-office respondents for the entire weekend, set the time to 00.00 - 00.00.'
                  )}
                />
              </div>
            </CL.GridCol>
          </CL.GridRow>
          <CL.GridRow
            className={`${dsClass.PADDING_TOP_4} ${dsClass.PADDING_LEFT_4}  ${dsClass.PADDING_RIGHT_2} of-pbx-accordion-content__working-hours`}
          >
            <ScheduledWorkingHours formValues={formValues} edit={true} />
          </CL.GridRow>
        </CL.Grid>
      )}

      <CL.Grid className={dsClass.PADDING_TOP_6}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <div className={`${dsClass.PADDING_BOTTOM_3} ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} `}>
              <h5 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_RIGHT_2}`}>{t.L6G9('Office-hour respondents')}</h5>
              <CL.Tooltip
                triggerElement={
                  <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                }
                placement="top"
                i18n_tooltip_contentText={t.XGRM(
                  'The company number has two available chains (for office hours and out-of-office hours). You can add four respondents to both chains.'
                )}
              />
            </div>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <RespondentList
            name="workingHoursReachabilityChain"
            edit={true}
            getError={() => getErrorForWorkingHourRespondents(formValues)}
            isFirstRequired={isFirstNumberRequiredForOfficeHours}
          />
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_6}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <div className={`${dsClass.PADDING_BOTTOM_3} ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} `}>
              <h5 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_RIGHT_2}`}>
                {t.D14J('Out-of-office respondents')}
              </h5>
              <CL.Tooltip
                triggerElement={
                  <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                }
                placement="top"
                i18n_tooltip_contentText={t.XGRM(
                  'The company number has two available chains (for office hours and out-of-office hours). You can add four respondents to both chains.'
                )}
              />
            </div>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <RespondentList
            name="offWorkReachabilityChain"
            edit={true}
            getError={() => getErrorForOffWorkingTimeRespondents(formValues)}
            isFirstRequired={isFirstNumberRequiredForOutOfOfficeHours}
          />
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={dsClass.PADDING_TOP_6}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <SelectRadio name="busyAction" items={busyActionOptions()} label={t.MW58('When respondent is busy')} />
            {showConnectToNumber && <PhoneNumber name="connectToNumber" label="" required={showConnectToNumber} />}
          </CL.GridCol>
          <CL.GridCol className={`${dsClass.PADDING_BOTTOM_2}`} colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <div className={`${dsClass.MARGIN_BOTTOM_2} ${dsClass.DISPLAY_FLEX}`}>
              <label className={dsClass.INPUT_LABELAREA_LABEL} htmlFor="delayForChainCalls">
                {t.NG9B('Callout time')}
              </label>
              <CL.Tooltip
                triggerElement={
                  <CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="s" />
                }
                placement="top"
                i18n_tooltip_contentText={t.P04G('Define how long a call rings on a single respondent.')}
              />
            </div>
            <Dropdown name="delayForChainCalls" id="delayForChainCalls" optional={false} items={calloutTimeOptions()} />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      <CL.Grid className={`${dsClass.PADDING_TOP_6} ${dsClass.PADDING_BOTTOM_4}`}>
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12}>
            <h4 className={`${dsClass.MARGIN_BOTTOM_1}  ${dsClass.MARGIN_TOP_0}`}>{t.AN17('Number settings')}</h4>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow className={`${dsClass.PADDING_TOP_4}`}>
          <CL.GridCol colsS={6} colsM={3} colsL={6} colsXL={6}>
            <Dropdown
              name="corporateNumber"
              id="corporateNumber"
              items={corporateNumberDropdownItems(formValues, corporateNumberRange)}
              onValueChange={onSelectCorporateNumber}
              label={t.D9TG('Company number')}
            />
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
            <Dropdown
              name="extensionNumber"
              items={extensionNumberOptions(formValues, extensionNumberRange)}
              label={t.NO66(extensionMsg)}
            />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      <div className={dsClass.PADDING_BOTTOM_3}>
        <CL.Button size="l" type="submit" loading={isSaving}>
          {t.QVYK(confirmMsg)}
        </CL.Button>
        <CL.Button
          onClick={() => {
            setEditing(false);
            reset();
          }}
          size="l"
          color="link"
        >
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </div>
    </div>
  );
};
