import { batchQueryPrivateOnlineModels } from './privateOnlineModelService';
import { createContext, useContext, useReducer, useRef } from 'react';
import { privateOnlineModelReducer } from './privateOnlineModelReducer';
import type { AccountSpecificOnlineModels, PrivateQueryAction } from './privateOnlineModelReducer';
import type { AsyncOnlineModelQueueData } from './privateOnlineModelService';
import type { Dispatch, MutableRefObject, ReactNode } from 'react';

export interface AccountSpecificContextData {
  priceAlteredModels: AccountSpecificOnlineModels;
  fetchPrivateModelsByCodes: (onlineModelCodes: string[]) => void;
  setActiveVoucher: (voucherCode: string) => void;
}
const PrivateOnlineModelContext = createContext<AccountSpecificContextData>({
  priceAlteredModels: {},
  fetchPrivateModelsByCodes: () => {},
  setActiveVoucher: () => {},
});

const getQueue = (asyncData: MutableRefObject<AsyncOnlineModelQueueData>) => {
  const voucherCode = asyncData.current.activeVoucherCode ?? '';
  if (!voucherCode) {
    return asyncData.current.def;
  }
  if (voucherCode in asyncData.current.loadedByVoucher) {
    return asyncData.current.loadedByVoucher[voucherCode];
  }
  asyncData.current.loadedByVoucher[voucherCode] = {
    loaded: new Set<string>(),
    loading: new Set<string>(),
    queue: new Set<string>(),
  };
  return asyncData.current.loadedByVoucher[voucherCode];
};

const updateAsyncProviderState = async (
  asyncData: MutableRefObject<AsyncOnlineModelQueueData>,
  dispatch: Dispatch<PrivateQueryAction>
) => {
  const current = getQueue(asyncData);
  if (current.queue.size > 0) {
    const currentQueue = new Set([...current.queue]);
    current.queue.clear();
    let codesUpdated = false;
    currentQueue.forEach(headerCode => {
      if (!current.loaded.has(headerCode) && !current.loading.has(headerCode)) {
        current.loading.add(headerCode);
        codesUpdated = true;
      }
    });
    if (codesUpdated) {
      await batchQueryPrivateOnlineModels(current, dispatch, asyncData.current.activeVoucherCode);
    }
  }
};

const updateState = async (
  currentAsyncQueue: MutableRefObject<AsyncOnlineModelQueueData>,
  dispatch: Dispatch<PrivateQueryAction>
) => {
  await updateAsyncProviderState(currentAsyncQueue, dispatch);
};

const scheduleUpdate = (
  onlineModelCodes: string[],
  currentAsyncQueue: MutableRefObject<AsyncOnlineModelQueueData>,
  stateUpdateTimeout: MutableRefObject<ReturnType<typeof setTimeout> | null>,
  dispatch: Dispatch<PrivateQueryAction>
) => {
  const scheduleStateUpdate = () => {
    if (!currentAsyncQueue.current.isUpdating) {
      currentAsyncQueue.current.isUpdating = true;
      stateUpdateTimeout.current = setTimeout(async () => {
        await updateState(currentAsyncQueue, dispatch);
      }, 100);
      currentAsyncQueue.current.isUpdating = false;
    }
  };
  const currentQueue = getQueue(currentAsyncQueue);
  onlineModelCodes
    .filter(
      code => code && !currentQueue.loaded.has(code) && !currentQueue.loading.has(code) && !currentQueue.queue.has(code)
    )
    .forEach(code => currentQueue.queue.add(code));
  scheduleStateUpdate();
};

export const PrivateOnlineModelContextProvider = ({
  children,
  initialData,
}: {
  children: ReactNode;
  initialData: AccountSpecificOnlineModels;
}) => {
  const [privateOnlineModelData, dispatch] = useReducer(privateOnlineModelReducer, initialData);
  const currentAsyncQueue = useRef<AsyncOnlineModelQueueData>({
    def: {
      loaded: new Set<string>(),
      loading: new Set<string>(),
      queue: new Set<string>(),
    },
    loadedByVoucher: {},
    isUpdating: false,
  });
  const setVoucher = (voucher: string) => (currentAsyncQueue.current.activeVoucherCode = voucher);
  const stateUpdateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const scheduleQuery = (onlineModelCodes: string[]) => {
    scheduleUpdate(onlineModelCodes, currentAsyncQueue, stateUpdateTimeout, dispatch);
  };
  const contextData = {
    priceAlteredModels: privateOnlineModelData,
    fetchPrivateModelsByCodes: scheduleQuery,
    setActiveVoucher: setVoucher,
  };
  return <PrivateOnlineModelContext.Provider value={contextData}>{children}</PrivateOnlineModelContext.Provider>;
};

export const usePrivateOnlineModelsContext = (): AccountSpecificContextData => useContext(PrivateOnlineModelContext);
