import * as CL from '@design-system/component-library';
import { continueShoppingMsg, quantityMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils';
import {
  findBasketItemForId,
  getAddonsDisplayData,
  getAnalyticsEventForAddOrRemoveItem,
  getDisclaimerFields,
  getOffer,
  getOnetimePaymentText,
  getOnlineModelForBasketItem,
  getPriceFromBasketOfferItem,
  getPriceToDisplay,
  getTotalAmount,
} from '../ShoppingBasket/shoppingBasketUtils';
import { getProductImage } from '../ShoppingBasket/shoppingBasketUiUtils';
import { parseBasketJson, useShoppingBasket } from '../../common/hooks/useShoppingBasket';
import { pushToDataLayer } from '../../common/analytics';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import type { BasketItem, ShoppingBasketType } from '../../common/types/shoppingBasket';
import type { OnlineModel } from '../../generated/api/onlineModel';
import type { PageResponse } from '../../generated/api/pageResponse';
import type { PriceData } from '../ShoppingBasket/types';
import type { State } from '../../selfservice/common/store';

import './HeaderShoppingBasket.scss';

export interface HeaderShoppingBasketProps {
  toShoppingCart: string;
}

interface BasketItemUpdate {
  id: string;
  quantity: number;
  guid: string;
  model?: OnlineModel;
}

export const HeaderShoppingBasket = (props: HeaderShoppingBasketProps) => {
  const { toShoppingCart } = props;
  const navigate = useNavigate();
  const { shoppingBasket } = useShoppingBasket();
  const pageLoaderData = useRouteLoaderData('public-path-root') as PageResponse | undefined;
  const stateOnlineModels = useSelector((state: State) => state.selfservice?.onlineModels?.items || [], deepEqual);
  const onlineModels = [...stateOnlineModels, ...(pageLoaderData ? pageLoaderData.onlineModels : [])];
  const [lastItemChanged, setLastItemChanged] = useState({} as BasketItemUpdate);
  const lastModified = useRef<number>(new Date().getTime());

  const getBasketItemUpdates = (items: BasketItem[]) =>
    items.map(
      basketItem =>
        ({
          id: basketItem.id,
          quantity: basketItem.quantity,
          guid: basketItem.guid,
          model: getOnlineModelForBasketItem(onlineModels, basketItem),
        }) as BasketItemUpdate
    );

  const shoppingBasketType: ShoppingBasketType = parseBasketJson(shoppingBasket);
  const basketItems = shoppingBasketType.items;
  const basketFromStorage = getBasketItemUpdates(basketItems || []);
  const previousShoppingBasket = useRef<string>(shoppingBasket);
  const previousBasketFromStorage = useRef<BasketItemUpdate[] | undefined>(undefined);

  const previousItems = previousBasketFromStorage.current || [];

  const changedIndices = basketFromStorage
    .map((item, index) =>
      previousItems[index] === undefined ||
      item.id !== previousItems[index].id ||
      item.quantity > previousItems[index].quantity
        ? index
        : -1
    )
    .filter(index => index !== -1);

  const getQuantity = (items: BasketItemUpdate[], findId: string) => {
    return items.find(item => item.id === findId)?.quantity || 0;
  };

  if (changedIndices.length > 0 && previousShoppingBasket.current.trim().length > 0) {
    const lastChangedItem = basketFromStorage[changedIndices[0]];
    const addedQuantity =
      getQuantity(basketFromStorage, lastChangedItem.id) - getQuantity(previousItems, lastChangedItem.id);

    lastModified.current = new Date().getTime();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const existingBasketItemThatChanged = findBasketItemForId(basketItems, lastChangedItem.id);
    const newQuantity = (existingBasketItemThatChanged?.quantity || 0) + addedQuantity;
    const analyticsEvent = lastChangedItem.model
      ? getAnalyticsEventForAddOrRemoveItem(existingBasketItemThatChanged, newQuantity, [lastChangedItem.model])
      : undefined;

    if (analyticsEvent) {
      pushToDataLayer(analyticsEvent);
    }
    setLastItemChanged({
      id: lastChangedItem.id,
      quantity: addedQuantity,
      guid: lastChangedItem.guid,
      model: lastChangedItem.model,
    } as BasketItemUpdate);
  }

  previousBasketFromStorage.current = basketFromStorage;
  previousShoppingBasket.current = shoppingBasket;

  const resolveAriaSummary = (addedAmount: number, totalAmount: number) => {
    if (addedAmount) {
      const ariaSummaryPartOne =
        addedAmount === 1
          ? t.WDX0('One item as added to shopping cart')
          : t.IMA5('{} items added to shopping cart', String(addedAmount));

      const ariaSummaryPartTwo =
        totalAmount === 1
          ? t.HTQL('There is now one item in shopping cart')
          : t.UE2G('There are now {} items in shopping cart', String(totalAmount));

      return `${ariaSummaryPartOne}. ${ariaSummaryPartTwo}.`;
    } else {
      return '';
    }
  };

  const resolveI18nSummary = (totalAmount: number) => {
    if (totalAmount) {
      if (totalAmount > 1) {
        return t.ZE9V('There are {} items in your cart', String(totalAmount));
      } else {
        return t.CYZV('There is 1 item in your cart');
      }
    } else {
      return '';
    }
  };

  const getProducts = (lastItemChangedInBasket: BasketItemUpdate, models: OnlineModel[]) => {
    const basketItem = findBasketItemForId(basketItems, lastItemChangedInBasket.id);
    if (!basketItem) {
      return [];
    }
    const onlineModelForItem = getOnlineModelForBasketItem(models, basketItem);
    const addedQuantity = lastItemChangedInBasket.quantity;
    const price: PriceData = { price: getPriceFromBasketOfferItem(basketItem.offer) };
    const shoppingCartPrice = getPriceToDisplay(price, addedQuantity);
    const offer = getOffer(basketItem.offer.guid, onlineModelForItem);
    const isActivationFee = Boolean(basketItem.offer.oneTimePriceInCents && basketItem.offer.periodicPrice);
    return [
      {
        addons: onlineModelForItem
          ? getAddonsDisplayData(basketItem.offer.addOns || [], onlineModelForItem, addedQuantity)
          : [],
        disclaimer: [
          ...getDisclaimerFields(
            getOnetimePaymentText(basketItem.offer.oneTimePriceInCents, addedQuantity, isActivationFee),
            price
          ),
        ],
        id: basketItem.id,
        name: basketItem.name,
        quantity: addedQuantity,
        price: shoppingCartPrice,
        image: getProductImage(basketItem.imageUrl, basketItem.name, offer, onlineModelForItem),
      },
    ];
  };

  const totalProductQuantity = getTotalAmount(basketItems);
  return (
    <div className="of-header-shopping-basket">
      <CL.HeaderShoppingCart
        ariaSummary={resolveAriaSummary(lastItemChanged.quantity, totalProductQuantity)}
        i18nClose={t.VLZR(continueShoppingMsg)}
        i18nQuantity={t.M0W7(quantityMsg)}
        i18nSubmit={t.VVQP('To shopping cart')}
        i18nSummary={resolveI18nSummary(totalProductQuantity)}
        i18nTitle={t.VJK6('Added to shopping cart')}
        i18nButtonLabel={t.YXF1('Shopping cart')}
        lastChange={lastModified.current}
        products={lastItemChanged ? getProducts(lastItemChanged, onlineModels) : []}
        onSubmit={() => navigate(toShoppingCart)}
        totalProductQuantity={totalProductQuantity}
      />
    </div>
  );
};
