import * as CL from '@design-system/component-library';
import * as React from 'react';
import { EnrollmentProgramType } from '../../generated/api/models.js';
import { LabelWithTooltip } from '../LabelWithTooltip/LabelWithTooltip.js';
import {
  additionalServicesMsg,
  deviceEnrollmentProgramAliasInfoMsg,
  enrollmentAliasPlaceHolderMsg,
  optionalMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';

import './EnrollmentProgramConsent.scss';

export interface EnrollmentProgramConsentProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  enrollKey: string;
  onValueChange: (e: React.FormEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  showAlias?: boolean;
  enrollAliasKey?: string;
  enrollmentProgramAlias?: string;
  applicableEnrollmentProgram?: string;
}

const EnrollmentProgramInfo = () => (
  <span>
    <p>
      {t.Z20L(
        `Device enrollment programs ensure that your company's device management is automatically taken into use for all new devices. This way, the devices remain under the company's control from their deployment until the end of their life cycle.`
      )}
      <br />
    </p>
    <p>
      {t.SRWY(
        'In addition, device enrollment programs streamline the deployment process, as some steps are automatically skipped. If needed, you can further customize the user experience by skipping and modifying some screens.'
      )}
    </p>
    <p>
      <a href="https://yrityksille.elisa.fi/mobiililaiterekisteri">
        {t.GBHA('Read more about the Device enrollment programs')}
      </a>
    </p>
  </span>
);

interface EnrollmentProgramLabelProps {
  applicableEnrollmentProgram?: string;
}

const EnrollmentProgramLabel = ({ applicableEnrollmentProgram }: EnrollmentProgramLabelProps) => {
  switch (applicableEnrollmentProgram) {
    case EnrollmentProgramType.DEP:
      return <label>{t.CZS5('Apple device enrollment program')}</label>;

    case EnrollmentProgramType.KME:
      return <label>{t.ZRXP('Samsung KME enrollment program')}</label>;

    case EnrollmentProgramType.ZEROTOUCH:
      return <label>{t.ZO3A('Android Zero-touch (ZT) enrollment program')}</label>;

    case EnrollmentProgramType.AUTOPILOT:
      return <label>{t.G9OG('Windows Autopilot enrollment program')}</label>;

    default:
      return <label>{t.S14B('Device enrollment program')}</label>;
  }
};

interface EnrollmentProgramAliasProps {
  onValueChange: (e: React.FormEvent<HTMLInputElement> | null) => void;
  value?: string;
}

export const EnrollmentProgramAlias = ({ onValueChange, value }: EnrollmentProgramAliasProps) => (
  <div className={dsClass.MARGIN_TOP_6}>
    <div className={dsClass.INPUT_LABELAREA}>
      <label className={dsClass.INPUT_LABELAREA_LABEL}>{t.TUZU(`Customer's Device Enrollment Alias`)}</label>
      <CL.Tooltip
        triggerElement={
          <CL.Icon className={`${dsClass.MARGIN_TOP_1} ${dsClass.MARGIN_LEFT_1}`} icon="information" size="s" />
        }
        i18n_tooltip_contentText={t.BJRZ(deviceEnrollmentProgramAliasInfoMsg)}
        placement="top"
      />
    </div>
    <CL.Input
      name="enrollmentAlias"
      type="text"
      value={value}
      placeholder={t.IBVP(enrollmentAliasPlaceHolderMsg)}
      onValueChange={onValueChange}
      maxlength={64}
      optional={true}
      controlled={true}
      i18n_input_optionalText={t.G7RQ(optionalMsg)}
    />
  </div>
);

export const EnrollmentProgramConsent = ({
  enrollKey,
  onChange,
  isChecked,
  showAlias,
  onValueChange,
  enrollmentProgramAlias,
  applicableEnrollmentProgram,
}: EnrollmentProgramConsentProps) => {
  return (
    <div className="of-enrollment-program-consent">
      <h5 className="ea-h5">{t.LXSR(additionalServicesMsg)}</h5>

      <div className="ea-display--flex">
        <CL.Checkbox
          id={enrollKey}
          value={enrollKey}
          key={String(isChecked ?? true)}
          checked={isChecked ?? true}
          onChange={onChange}
        >
          <EnrollmentProgramLabel applicableEnrollmentProgram={applicableEnrollmentProgram} />
        </CL.Checkbox>
        <LabelWithTooltip labelText="">
          <EnrollmentProgramInfo />
        </LabelWithTooltip>
      </div>
      {showAlias && isChecked && (
        <EnrollmentProgramAlias onValueChange={onValueChange} value={enrollmentProgramAlias} />
      )}
    </div>
  );
};
