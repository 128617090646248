import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { COST_CENTER_REGEX } from '../../common/utils/validationUtils.js';
import { CompanyInfoResponse, CustomerOrderStatus } from '../../generated/api/models.js';
import { CustomerOrderContent } from './CustomerOrderContent';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { invalidCostCenterNumberMsg, orderMsg, t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect, useState } from 'react';
import type {
  BillingAccountSearchResponse,
  ContactHeader,
  ContactSearchResponse,
  CustomerOrder,
  DeliveryOrder,
  Subscription,
} from '../../generated/api/models.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';

import './CustomerOrderDetails.scss';

export interface CustomerOrderDetailsProps {
  buildDeliveryOrderHref: (cid: string, did: string) => string;
  contacts?: ContactSearchResponse[];
  deviceSubscriptions?: Subscription[];
  customerOrder?: CustomerOrder;
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  firstBreadCrumbName?: string;
  isEmployee?: boolean;
  billingAccounts?: BillingAccountSearchResponse[];
  mdmId: string;
  breadCrumbPaths?: BreadCrumbList;
  onShowContacts?: (displayId?: string) => void;
}

export interface CustomerOrderDetailsFormValues {
  billingAccountId?: string;
  costCenter?: string;
  employeeNumber?: string;
}

const validateCostCenterForInternal = (value: string) => {
  return !value || COST_CENTER_REGEX.test(value);
};

export const billingInfoFieldValidationsForInternal = {
  costCenter: {
    required: true,
    validationFn: validateCostCenterForInternal,
    messages: {
      validationFn: () => t.JE8S(invalidCostCenterNumberMsg),
    },
  },
  billingAccountId: { required: true },
};

export const CustomerOrderDetails = ({
  buildDeliveryOrderHref,
  contacts,
  deviceSubscriptions,
  customerOrder,
  breadCrumbPaths,
  customerType,
  onShowContacts,
  isEmployee = false,
  billingAccounts,
  mdmId,
}: CustomerOrderDetailsProps) => {
  const [ordererContact, setOrdererContact] = useState<ContactHeader | undefined>(undefined);
  const { authenticatedUser } = useAuth();

  const companyName = getCompanyName(authenticatedUser, mdmId);

  useEffect(() => {
    // We want to load contacts only in case of orders pending for approval.
    if (customerOrder?.status === CustomerOrderStatus.PENDING_APPROVAL && onShowContacts) {
      onShowContacts(customerOrder?.deliveryOrders.find((d: DeliveryOrder) => !!d.userContactId)?.userContactId);
    }
  }, [customerOrder, onShowContacts]);

  useEffect(() => {
    const subContact = contacts
      ?.map(c => c.result)
      .find(cont => customerOrder?.deliveryOrders.find((d: DeliveryOrder) => d.userContactId === cont.contactId));
    setOrdererContact(subContact);
  }, [customerType, contacts, customerOrder]);

  const isInternalCustomer = customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;

  if (!customerOrder) {
    return <Loading />;
  }

  const customerOrderDisplayId = customerOrder ? customerOrder.customerOrderDisplayId : '…';

  return (
    <DetailsWrapper
      classes={['of-customer-order-details']}
      detailsTop={breadCrumbPaths && <BreadCrumbsWithTitle breadCrumbPaths={breadCrumbPaths} />}
      id={`customer-order-details-${customerOrderDisplayId}`}
      heading={customerOrderDisplayId}
      headingBottom={`${formatTimestampToDDMMYYYY(customerOrder.created)}${companyName ? ` | ${companyName}` : ''}`}
      headingTop={t.C001(orderMsg)}
      heroPicto="order"
    >
      <CustomerOrderContent
        buildDeliveryOrderHref={buildDeliveryOrderHref}
        customerOrder={customerOrder}
        ordererContact={ordererContact}
        deviceSubscriptions={deviceSubscriptions}
        isInternalCustomer={isInternalCustomer}
        isEmployee={isEmployee}
        billingAccounts={billingAccounts}
        mdmId={mdmId}
      />
    </DetailsWrapper>
  );
};
