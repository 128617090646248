import * as File from '../File/index.js';
import { BreadCrumbsWithTitle } from '../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { CaseHistory } from '../CaseHistory/CaseHistory.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { ExpandableText } from '../ExpandableText/ExpandableText.js';
import { Link, generatePath } from 'react-router-dom';
import { Loading } from '../Loading/index.js';
import { Status } from '../Status/Status.js';
import { SupportCaseFields } from '../../common/enums.js';
import {
  categoryMsg,
  descriptionMsg,
  omaElisaForCompaniesMsg,
  resolutionMsg,
  stepsMsg,
  supportCaseMsg,
  supportCasesMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { featureTranslation, getColumnTranslation, statusData } from '../../common/utils/supportCaseUtils.js';
import { formatTimeStampToYYYYMMDDHHmm, formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { SupportCaseDataBundle, SupportCaseHeader, SupportCaseHistory } from '../../generated/api/models.js';

import './CaseDetails.scss';

export interface SupportCaseDescriptionBlockProps {
  heading: string;
  paragraphContent: string | JSX.Element;
  paragraphClassName?: string;
}

export interface UpdatedInfoProps {
  timestamp?: number;
  updatedBy?: string;
}

export interface CaseDetailsProps {
  supportCase: SupportCaseDataBundle;
  history?: SupportCaseHistory[];
  authenticatedUser?: AuthenticatedUserState;
}

const SupportCaseDescriptionBlock = ({
  heading,
  paragraphContent,
  paragraphClassName,
}: SupportCaseDescriptionBlockProps) => (
  <div className="description-block">
    <h4>{heading}</h4>
    <div className={`${dsClass.MARGIN_TOP_1} ${paragraphClassName || ''}`}>{paragraphContent}</div>
  </div>
);

export const CaseDetails = ({ supportCase, history, authenticatedUser }: CaseDetailsProps) => {
  const SHOW_DESCRIPTION_LINES = 3;
  const SHOW_RESOLUTION_LINES = 25;
  const CHAR_LIMIT_FOR_EXPANDABLE_TEXT_DESCRIPTION = 200;
  const CHAR_LIMIT_FOR_EXPANDABLE_TEXT_RESOLUTION = 1500;

  const {
    accountMasterId,
    attachments,
    createdAt,
    description,
    featureType,
    invoiceNumber,
    lastModified,
    resolution,
    supportCaseDisplayId,
  } = supportCase;

  const UpdatedInfo = ({ timestamp, updatedBy }: UpdatedInfoProps) => {
    return (
      <p className={`${dsClass.TEXT_S} ${dsClass.MARGIN_TOP_2}`}>
        {timestamp && <span>{formatTimestampToDDMMYYYY(timestamp)}</span>}
        {updatedBy && <span>| {updatedBy}</span>}
      </p>
    );
  };

  const getFullName = (sc: SupportCaseHeader) => {
    return `${sc?.firstName} ${sc?.lastName}`;
  };

  const supportCaseCreatedAt = createdAt ? formatTimeStampToYYYYMMDDHHmm(createdAt) : '';

  const SupportCaseHistorySteps = () => {
    return (
      <div className="stepper-block">
        <h2>{t.U2PF(stepsMsg)}</h2>
        <CaseHistory supportCaseHistory={history || []} supportCaseCreatedAt={supportCaseCreatedAt} />
      </div>
    );
  };

  const isMovePaymentDateCase: boolean =
    (featureType === 'Lisää maksuaikaa' || featureType === 'Move the payment date') ?? false;

  const statusValues = statusData(supportCase?.status);

  return (
    <DetailsWrapper
      classes={['of-case-details']}
      id="case-details"
      detailsTop={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.OEZW(supportCasesMsg), path: paths.SUPPORT_CASES },
            { name: supportCaseDisplayId },
          ]}
        />
      }
      heading={supportCaseDisplayId}
      headingTop={t.EKG9(supportCaseMsg)}
      headingBottom={getCompanyName(authenticatedUser, supportCase?.accountMasterId)}
    >
      <div>
        <div className="top-grid ">
          <div className="top-grid-block">
            <h4>{getColumnTranslation(SupportCaseFields.STATUS)}</h4>
            <div id="supportCaseStatus" className={dsClass.MARGIN_TOP_1}>
              <Status color={statusValues.color} textInBadge={statusValues.text} />
            </div>
          </div>
          <div className="top-grid-block">
            <h4>{getColumnTranslation(SupportCaseFields.SUPPORT_CASE_DISPLAY_ID)}</h4>
            <p id="supportCaseDisplayId" className={dsClass.MARGIN_TOP_1}>
              {supportCase?.supportCaseDisplayId}
            </p>
          </div>
          <div className="top-grid-block">
            <h4>{getColumnTranslation(SupportCaseFields.FEATURE)}</h4>
            <p id="supportCaseFeature" className={dsClass.MARGIN_TOP_1}>
              {featureTranslation(supportCase?.feature)}
            </p>
          </div>
          <div className="top-grid-block">
            <h4>{getColumnTranslation(SupportCaseFields.CREATED_AT)}</h4>
            <p id="supportCaseCreatedAt" className={dsClass.MARGIN_TOP_1}>
              {supportCaseCreatedAt}
            </p>
          </div>
          <div className="top-grid-block">
            <h4>{getColumnTranslation(SupportCaseFields.CONTACT_DETAILS)}</h4>
            <p className={dsClass.MARGIN_TOP_1}>{getFullName(supportCase)}</p>
          </div>
          <div className="top-grid-block">
            <h4>{getColumnTranslation(SupportCaseFields.LAST_MODIFIED)}</h4>
            <p id="supportCaseLastModified" className={dsClass.MARGIN_TOP_1}>
              {lastModified ? formatTimeStampToYYYYMMDDHHmm(lastModified) : ''}
            </p>
          </div>
        </div>
        <div className={`${dsClass.RESET} bottom-grid`}>
          <div className="description">
            <h2>{t.HBW3(descriptionMsg)}</h2>
            {featureType && (
              <>
                <SupportCaseDescriptionBlock heading={t.CV5H(categoryMsg)} paragraphContent={featureType} />
                <SupportCaseDescriptionBlock
                  heading={getColumnTranslation(SupportCaseFields.INVOICE_NUMBER)}
                  paragraphContent={
                    <>
                      {invoiceNumber && (
                        <Link
                          to={`${generatePath(paths.INVOICE, { invoiceId: invoiceNumber })}?mdmId=${accountMasterId}`}
                        >
                          {invoiceNumber}
                        </Link>
                      )}
                    </>
                  }
                />
              </>
            )}
            <SupportCaseDescriptionBlock
              heading={
                isMovePaymentDateCase ? t.HBW3(descriptionMsg) : getColumnTranslation(SupportCaseFields.DESCRIPTION)
              }
              paragraphContent={
                resolution !== undefined &&
                description !== undefined &&
                description.length > CHAR_LIMIT_FOR_EXPANDABLE_TEXT_DESCRIPTION ? (
                  <ExpandableText text={description || ''} lineClamp={SHOW_DESCRIPTION_LINES} />
                ) : (
                  <>{description}</>
                )
              }
              paragraphClassName={`content-with-line-breaks ${dsClass.MARGIN_BOTTOM_0}`}
            />
            {attachments && <File.List fileNames={attachments} />}
            <UpdatedInfo
              timestamp={supportCase?.createdAt}
              updatedBy={!isMovePaymentDateCase ? getFullName(supportCase) : undefined}
            />
            {resolution && (
              <>
                <hr className={`${dsClass.MARGIN_BOTTOM_5} ${dsClass.MARGIN_TOP_5}`} />
                <h2>{t.YOW5(resolutionMsg)}</h2>
                <SupportCaseDescriptionBlock
                  heading={t.YP6T('Message from customer service')}
                  paragraphContent={
                    resolution.length > CHAR_LIMIT_FOR_EXPANDABLE_TEXT_RESOLUTION ? (
                      <ExpandableText text={resolution} lineClamp={SHOW_RESOLUTION_LINES} />
                    ) : (
                      <div>{resolution}</div>
                    )
                  }
                  paragraphClassName={`content-with-line-breaks ${dsClass.MARGIN_BOTTOM_0}`}
                />
                <UpdatedInfo timestamp={history?.slice(-1).pop()?.modifiedDate} />
              </>
            )}
          </div>
          {history ? <SupportCaseHistorySteps /> : <Loading />}
        </div>
      </div>
    </DetailsWrapper>
  );
};
