import * as CL from '@design-system/component-library';
import {
  BILLING_ACCOUNT_MAX_LENGTH,
  BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH,
} from '../../../../common/utils/validationUtils.js';
import { BillingAccountDeliveryMethod, ContactType } from '../../../../generated/api/models.js';
import { BillingAccountDeliveryMethodEditForm } from '../deliveryMethod/BillingAccountDeliveryMethodEditForm.js';
import { BillingAccountReferenceNumbersForm } from '../../../BillingAccountReferenceNumbers/BillingAccountReferenceNumbersForm.js';
import { ContactDropdown } from '../../../../common/react-hook-form/fields/ContactDropdown.js';
import { DatePicker } from '../../../../common/react-hook-form/components/DatePicker.js';
import { Dropdown } from '../../../../common/react-hook-form/components/Dropdown.js';
import { EditingSection } from '../../billingAccountDetailsEnums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { GridColumn } from '../../components/GridColumn.js';
import { PostalCode } from '../../../../common/react-hook-form/fields/index.js';
import {
  ReceiverTypes,
  billingAccountInvoiceTypes,
  findContact,
  getContactDisplayValue,
} from '../../../../common/utils/billingAccountUtils.js';
import { SaveOrCancelBillingAccountButtons } from '../../components/SaveOrCancelBillingAccountButtons.js';
import { ScrollToTop } from '../../../../common/utils/browserUtils.js';
import { SelectRadio } from '../../../../common/react-hook-form/components/SelectRadio.js';
import { StreetAddress } from '../../../../common/react-hook-form/fields/StreetAddress.js';
import { TextInputWithTooltip } from '../../../../common/react-hook-form/fields/TextInputWithTooltip.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  businessIdMsg,
  cityMsg,
  fieldCantBeEmptyMsg,
  fieldValueTooLong,
  invoiceDeliveryMethodMsg,
  invoiceTypeMsg,
  payerAdditionalDetailsInfoMsg,
  payerDetailsMsg,
  payerMsg,
  payerNameInfoMsg,
  postalCodeMsg,
  referenceInformationMsg,
  streetAddressMsg,
  t,
} from '../../../../common/i18n/index.js';
import { formatDefinedTimestampToDDMMYYYY } from '../../../../common/utils/dateUtils.js';
import { useAuth } from '../../../../public/site/AuthProvider.js';
import { useRevalidator } from 'react-router-dom';
import { useState } from 'react';
import type {
  BillChannel,
  BillingAccount,
  BillingAccountScheduledChange,
  Contact,
} from '../../../../generated/api/models.js';
import type { BillingAccountVersion } from '../../billingAccountDetailsEnums.js';
import type { ReceiverType } from '../../../../common/utils/billingAccountUtils.js';

export interface BillingAccountInfoFormEditParams {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  onSubmit: (values: BillingAccount, schedule: ScheduledChangeValues, receiverType: ReceiverType) => void;
  setNotEditing: () => void;
  contacts: Contact[];
  billingAccountVersion: BillingAccountVersion;
  billingAccountScheduledChange?: BillingAccountScheduledChange;
  editingSection: EditingSection;
  allowChangeScheduling?: boolean;
  isSaving?: boolean;
}

export enum ScheduleChangeType {
  NOW = 'NOW',
  FUTURE = 'FUTURE',
}

export interface ScheduledChangeValues {
  change: ScheduleChangeType;
  date?: string;
}

export interface BillingAccountFormValues {
  billingAccount: BillingAccount;
  scheduledChange: ScheduledChangeValues;
  billReceiverSelection: ReceiverType;
}

const ScheduleOptions = ({
  editingSection,
  scheduleChangeOption,
  allowChangeScheduling,
}: {
  editingSection: EditingSection;
  scheduleChangeOption: ScheduleChangeType;
  allowChangeScheduling: boolean;
}) => {
  const today = new Date();
  const endDate = new Date();
  endDate.setDate(today.getDate() + 30);

  return allowChangeScheduling ? (
    <>
      <CL.GridRow>
        <h4>{t.JHAG('When will the change be published?')}</h4>
      </CL.GridRow>
      <CL.GridRow>
        <SelectRadio
          name="scheduledChange.change"
          defaultValue="now"
          disabled={editingSection === EditingSection.SCHEDULE}
          items={[
            {
              label: t.DFUR('As soon as possible'),
              value: ScheduleChangeType.NOW,
            },
            {
              label: t.MYX9('Select date'),
              value: ScheduleChangeType.FUTURE,
            },
          ]}
        />
      </CL.GridRow>
      {scheduleChangeOption === ScheduleChangeType.FUTURE && (
        <CL.GridRow>
          <DatePicker
            disableAllPastDays={true}
            blockedDates={[new Date()]}
            disableWeekends={false}
            label=""
            name="scheduledChange.date"
            required={true}
            visibleRange={[
              [today.getFullYear(), today.getMonth() + 1, today.getDate()],
              [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()],
            ]}
          />
        </CL.GridRow>
      )}
    </>
  ) : null;
};

export const BillingAccountDetailsEditForm = ({
  billingAccount,
  billChannels,
  onSubmit,
  setNotEditing,
  contacts,
  editingSection,
  billingAccountScheduledChange,
  allowChangeScheduling = true,
  isSaving = false,
}: BillingAccountInfoFormEditParams) => {
  const [contactsState, setContactsState] = useState<Contact[]>(contacts);
  const billingAccountContact = findContact(billingAccount, contactsState);
  const selectedContact = billingAccountContact?.contactId;
  const [newContactAdded, setNewContactAdded] = useState(false);
  const { authenticatedUser } = useAuth();
  const revalidator = useRevalidator();

  // For reasoning behind these, check billingAccountUtils.prepareBillingAccountSave
  const getBillReceiverName = () => {
    if (billingAccount.deliveryMethod === BillingAccountDeliveryMethod.EMAIL) {
      return billingAccount.billReceiverType === ContactType.PERSON
        ? undefined
        : (billingAccount.billReceiverName ?? '');
    }
    return billingAccount.billReceiverName;
  };

  const getBillReceiverType = () => {
    if (billingAccount.deliveryMethod === BillingAccountDeliveryMethod.EMAIL) {
      return billingAccount.billReceiverType ?? ContactType.PERSON;
    }
    return billingAccount.billReceiverType;
  };

  const validateBillingAccountName = (value: string) => {
    if (!value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    if (value.length > BILLING_ACCOUNT_MAX_LENGTH) {
      return t.XOYE(fieldValueTooLong, `${BILLING_ACCOUNT_MAX_LENGTH}`);
    }
    return undefined;
  };

  const initialFormValues: BillingAccountFormValues = {
    billingAccount: {
      billingAccountId: billingAccount.billingAccountId || '',
      billingAccountName: billingAccount.billingAccountName || '',
      billingAccountStatus: billingAccount.billingAccountStatus,
      accountBalance: billingAccount.accountBalance,
      billingAccountExtensionName: billingAccount.billingAccountExtensionName || '',
      payerName: billingAccount.payerName || '',
      payerNameExtension: billingAccount.payerNameExtension || '',
      payerAddress: billingAccount.payerAddress,
      payerBusinessId: billingAccount.payerBusinessId || '',
      billingContactId: selectedContact,
      billFormatType: billingAccount.billFormatType,
      billingContactName: billingAccount.billingContactName,
      deliveryMethod: billingAccount.deliveryMethod,
      billLanguage: billingAccount.billLanguage ?? authenticatedUser?.preferredLanguage,
      billElectronicOperator: billingAccount.billElectronicOperator,
      billElectronicAddress: billingAccount.billElectronicAddress,
      billReceiverId: billingAccount.billReceiverId,
      additionalBillReceiverEmails: billingAccount.additionalBillReceiverEmails,
      billReceiverType: getBillReceiverType(),
      billReceiverName: getBillReceiverName(),
      customerReference1: billingAccount.customerReference1,
      customerReference2: billingAccount.customerReference2,
      customerReference3: billingAccount.customerReference3,
      customerReference4: billingAccount.customerReference4,
    },
    scheduledChange: {
      change: billingAccountScheduledChange ? ScheduleChangeType.FUTURE : ScheduleChangeType.NOW,
      date: billingAccountScheduledChange?.scheduledChangeTimestamp
        ? formatDefinedTimestampToDDMMYYYY(billingAccountScheduledChange.scheduledChangeTimestamp)
        : undefined,
    },
    billReceiverSelection:
      billingAccount.billReceiverId === undefined || billingAccount.billReceiverId === billingAccount.billingContactId
        ? ReceiverTypes.SAME_AS_CONTACT
        : ReceiverTypes.OTHER,
  };

  const getReceiverValues = (values: BillingAccountFormValues) => {
    const newReceiver = contactsState.find(contact => contact.contactId === values.billingAccount.billReceiverId);

    return values.billingAccount.billReceiverType === ContactType.COMMON_FUNCTION || !newReceiver
      ? {}
      : {
          billReceiverType: ContactType.PERSON,
          billReceiverName: getContactDisplayValue(newReceiver),
          billReceiverEmail: newReceiver.person?.email,
        };
  };

  const submitBillingAccountDetails = (values: BillingAccountFormValues) => {
    const newContact = contactsState.find(contact => contact.contactId === values.billingAccount.billingContactId);
    const newContactDisplayName = getContactDisplayValue(newContact);
    const newValues = {
      ...values.billingAccount,
      billingContactType: ContactType.PERSON,
      billingContactName: newContactDisplayName,
      ...getReceiverValues(values),
    } as BillingAccount;
    if (newContactAdded) {
      revalidator.revalidate();
    }
    onSubmit(newValues, values.scheduledChange, values.billReceiverSelection);
  };

  const billingAccountInvoiceTypeOptions: CL.DropDownItem[] = billingAccountInvoiceTypes.map(type => ({
    label: type.displayValue(),
    value: type.value,
  }));

  const methods = useForm<BillingAccountFormValues>({
    defaultValues: initialFormValues,
  });

  const scheduleChangeOption = methods.watch('scheduledChange.change');
  const selectedDeliveryMethod = methods.watch('billingAccount.deliveryMethod');

  const onContactCreated = (contact: Contact) => {
    if (contactsState.some(c => c.contactId === contact.contactId)) {
      return;
    }
    setContactsState([contact, ...contactsState]);
    setNewContactAdded(true);
  };

  return (
    <div className="of-billing-account-details">
      <ScrollToTop />
      <FormProvider {...methods}>
        <CL.Grid>
          <CL.GridRow>
            <CL.GridCol colsS={6} colsM={6} colsL={9}>
              <CL.Grid>
                <CL.GridRow>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <TextInputWithTooltip
                      labelForTooltip={t.PB6S(payerMsg)}
                      tooltipText={t.SEZ9(payerNameInfoMsg)}
                      name="billingAccount.payerName"
                      maxLength={100}
                      disabled={true}
                      required={true}
                    />
                  </GridColumn>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <TextInputWithTooltip
                      labelForTooltip={t.YLAI(payerDetailsMsg)}
                      tooltipText={t.O83E(payerAdditionalDetailsInfoMsg)}
                      name="billingAccount.payerNameExtension"
                      maxLength={100}
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                </CL.GridRow>
                <CL.GridRow>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <TextInputWithTooltip
                      labelForTooltip={t.MPA5(businessIdMsg)}
                      tooltipText={t.S8N1('The business ID of the payer, printed on the invoice.')}
                      name="billingAccount.payerBusinessId"
                      maxLength={100}
                      disabled={true}
                      required={true}
                    />
                  </GridColumn>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <StreetAddress
                      name="billingAccount.payerAddress.line1"
                      required={true}
                      label={t.DD38(streetAddressMsg)}
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                </CL.GridRow>
                <CL.GridRow>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <PostalCode
                      label={t.RUAW(postalCodeMsg)}
                      required={true}
                      name="billingAccount.payerAddress.postalCode"
                      allowPoBox={true}
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <StreetAddress
                      label={t.J0YE(cityMsg)}
                      required={true}
                      name="billingAccount.payerAddress.postOffice"
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                </CL.GridRow>
                <CL.GridRow>
                  <GridColumn className="of-billing-account-details form-column edit-field no-max-height">
                    <ContactDropdown
                      labelText={t.VYZS('Contact person')}
                      canAddNewContacts={true}
                      contacts={contactsState}
                      name="billingAccount.billingContactId"
                      disabled={editingSection === EditingSection.SCHEDULE}
                      onContactCreated={onContactCreated}
                    />
                  </GridColumn>
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <Dropdown
                      name="billingAccount.billFormatType"
                      id="billFormatType"
                      items={billingAccountInvoiceTypeOptions}
                      label={t.UW4H(invoiceTypeMsg)}
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                </CL.GridRow>
                <CL.GridRow>
                  <GridColumn className="of-billing-account-details form-column edit-field" />
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <TextInputWithTooltip
                      labelForTooltip={t.RH6T(billingAccountNameMsg)}
                      tooltipText={t.JKJJ(
                        'Mandatory name information given to the Billing Agreement. Does not print on the invoice.'
                      )}
                      name="billingAccount.billingAccountName"
                      maxLength={BILLING_ACCOUNT_MAX_LENGTH}
                      validate={validateBillingAccountName}
                      required={true}
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                </CL.GridRow>
                <CL.GridRow>
                  <GridColumn className="of-billing-account-details form-column edit-field" />
                  <GridColumn className="of-billing-account-details form-column edit-field">
                    <TextInputWithTooltip
                      labelForTooltip={t.KUTS(billingAccountExtensionNameMsg)}
                      tooltipText={t.OBHZ(
                        'Billing Agreement Name Add-on, helps identify the billing agreement. Does not print on invoice.'
                      )}
                      name="billingAccount.billingAccountExtensionName"
                      maxLength={BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH}
                      disabled={editingSection === EditingSection.SCHEDULE}
                    />
                  </GridColumn>
                </CL.GridRow>
                <CL.GridRow>
                  <h4 className="of-billing-account-details__delivery-methods" id="deliveryMethodDetails">
                    {t.H272(invoiceDeliveryMethodMsg)}
                  </h4>
                </CL.GridRow>
                <BillingAccountDeliveryMethodEditForm
                  billChannels={billChannels}
                  disabled={editingSection === EditingSection.SCHEDULE}
                  billingAccount={billingAccount}
                  selectedDeliveryMethod={selectedDeliveryMethod}
                  contacts={contactsState}
                  onBillReceiverContactCreated={onContactCreated}
                />
                <CL.GridRow>
                  <h4 id="referenceInformation">{t.J9AR(referenceInformationMsg)}</h4>
                </CL.GridRow>
                <BillingAccountReferenceNumbersForm disabled={editingSection === EditingSection.SCHEDULE} />
                <ScheduleOptions
                  editingSection={editingSection}
                  scheduleChangeOption={scheduleChangeOption}
                  allowChangeScheduling={allowChangeScheduling}
                />
                <CL.GridRow>
                  <SaveOrCancelBillingAccountButtons
                    setNotEditing={setNotEditing}
                    onSubmit={submitBillingAccountDetails}
                    name="billingAccountDetails"
                    isSaving={isSaving}
                  />
                </CL.GridRow>
              </CL.Grid>
            </CL.GridCol>
            <CL.GridCol colsS={6} colsM={6} colsL={3} />
          </CL.GridRow>
        </CL.Grid>
      </FormProvider>
    </div>
  );
};
