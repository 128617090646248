import { DialogType } from '../../common/enums.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getPriceWithTax, getVatAsString } from '../../common/utils/taxUtils.js';
import { postEppRedeemTerminate, showDialog } from '../../selfservice/actions/index.js';
import { t, vatPercentageMsg } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import type { BillingAccount, EppModificationPrices, Subscription } from '../../generated/api/models.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';
import type { PersonalBillingDetailsData } from '../PersonalBillingDetails/PersonalBillingDetails.js';

export const getRedeemPrice = (
  redeemType: EppRedeemTerminateRequestType,
  eppModificationPrices: EppModificationPrices
) => {
  return redeemType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM ||
    redeemType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM_BY_ADMIN
    ? `${formatSum(getPriceWithTax(eppModificationPrices.redeemOC!.totalEmployeePrice!))} (${t.U8AN('incl.')} ${t.YX78(
        vatPercentageMsg,
        getVatAsString()
      )})`
    : `${formatSum(eppModificationPrices.redeemOC!.totalCorporatePrice)} (${t.YX78(vatPercentageMsg, '0')})`;
};

interface ForwardClickArguments {
  isRedeemedByAdmin: boolean;
  billingAccountId?: string;
  redeemType: EppRedeemTerminateRequestType;
  subscription: EmployeeSubscription | Subscription;
  companyName?: string;
  approverContact?: string;
  personBillingAccount?: BillingAccount;
  personalBillingDetails?: PersonalBillingDetailsData;
  firstName?: string;
  lastName?: string;
}

export const useEppRedeemForwardClick = ({
  approverContact,
  billingAccountId,
  personBillingAccount,
  isRedeemedByAdmin,
  redeemType,
  subscription,
  companyName,
  personalBillingDetails,
  firstName,
  lastName,
}: ForwardClickArguments): (() => void) => {
  const dispatch = useDispatch();
  const newPersonBaId = 'NEW_PERSON_BA';

  if (isRedeemedByAdmin) {
    return () => {
      dispatch(
        showDialog({
          onConfirm: () => {
            dispatch(
              postEppRedeemTerminate(
                {
                  requestType: redeemType,
                  companyRedeem:
                    redeemType === EppRedeemTerminateRequestType.COMPANY_REDEEM
                      ? { billingAccountId: billingAccountId! }
                      : undefined,
                },
                subscription.subscriptionId
              )
            );
          },
          subscription: subscription,
          payerName:
            redeemType === EppRedeemTerminateRequestType.COMPANY_REDEEM
              ? companyName!
              : subscription.subscriptionContactName!,
          redeemType: redeemType,
          type: DialogType.EPP_REDEEM,
        })
      );
    };
  }

  return () => {
    const eppEmployeeRedeemDetails = {
      approverContact: approverContact || '',
      personBillingAccountId: subscription.details!.device!.eppModificationPrices!.redeemOC?.totalEmployeePrice
        ? personBillingAccount
          ? personBillingAccount.billingAccountId
          : newPersonBaId
        : undefined,
    };

    dispatch(
      showDialog({
        onConfirm: () => {
          dispatch(
            postEppRedeemTerminate(
              {
                employeeRedeem: eppEmployeeRedeemDetails,
                requestType: redeemType,
              },
              subscription.subscriptionId,
              personalBillingDetails?.personalBillingAddress,
              personalBillingDetails?.email
            )
          );
        },
        subscription: subscription,
        payerName: `${firstName} ${lastName}`,
        redeemType: EppRedeemTerminateRequestType.EMPLOYEE_REDEEM,
        type: DialogType.EPP_REDEEM,
      })
    );
  };
};
