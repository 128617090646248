import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';
import type { PropsWithChildren } from 'react';

import './ImageCards.scss';

type ImageCardProps = PropsWithChildren<{
  image: string;
  title: string;
  linkText?: string;
  buttonText?: string;
  imageSize: 's' | 'm' | 'l';
  type: 'dark' | 'transparent';
  url?: string;
}>;

export const ImageCard = ({
  buttonText,
  children,
  image,
  imageSize,
  title,
  type,
  url,
}: ImageCardProps): JSX.Element => (
  <CL.Card
    content={
      <>
        <img className={`of-card-image--${imageSize}`} alt="" src={`${pictureStorageBaseUrl}${image}`} />
        <div>
          <h3>{title}</h3>
          <p>{children}</p>
          {buttonText != null && url != null && (
            <Link
              className={`${dsClass.LINK} ${dsClass.LINK_BUTTON_COLOR_PRIMARY} ${dsClass.LINK_BUTTON_SIZE_L} ${dsClass.LINK_STYLE_LINK_BUTTON}`}
              to={url}
            >
              {buttonText}
            </Link>
          )}
        </div>
      </>
    }
    type={type}
  />
);

type ImageCardsProps = {
  imageSize?: 's' | 'm' | 'l';
  items: {
    buttonText?: string;
    linkText?: string;
    content: string;
    image: string;
    title: string;
    url?: string;
  }[];
  threeWide?: boolean;
  type: 'dark' | 'transparent';
};

// TODO replace this with new CMS element(s) if possible
export const ImageCards = ({ imageSize = 's', items, threeWide, type }: ImageCardsProps): JSX.Element => (
  <CL.CardContainer className={`of-image-cards ${threeWide ? 'three-wide' : ''}`}>
    {items.map(({ buttonText, linkText, content, image, title, url }, idx) => (
      <ImageCard
        key={idx}
        buttonText={buttonText}
        imageSize={imageSize}
        image={image}
        title={title}
        type={type}
        url={url}
      >
        {url && linkText && <Link to={url}>{linkText}</Link>}
        {content}
      </ImageCard>
    ))}
  </CL.CardContainer>
);
