import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormQuestionType } from '../OpenFormQuestion/OpenFormQuestionType.js';
import { OpenFormSectionLabel } from '../../OpenFormComponents/OpenFormSectionLabel.js';
import type { OpenFormSection } from '../../../../generated/api/openFormSection.js';

export const OpenFormPageQuestion = ({
  section,
  answers,
  disabled,
}: {
  section: OpenFormSection;
  answers: OpenFormAnswers;
  disabled: boolean;
}) => (
  <OpenFormGridRow>
    <OpenFormSectionLabel section={section} />
    {section.questions.filter(answers.visible).map(question => (
      <OpenFormGridRow id={question.guid} key={question.guid}>
        <OpenFormGridCol className="of-openform__view__choices">
          <OpenFormQuestionType question={question} answers={answers} disabled={disabled} />
        </OpenFormGridCol>
      </OpenFormGridRow>
    ))}
  </OpenFormGridRow>
);
