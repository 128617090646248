import { AddOnVisibilityOperationType } from '../../common/enums.js';
import { AdditionalServicesCheckoutContent } from '../OrderSubscriptionConfiguration/AdditionalServicesCheckoutContent.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { SUBSCRIPTION_CHANGE_SERVICE_FEE } from './content/LaitenettiSubscriptionCardContent';
import { SalesType } from '../../generated/api/salesType.js';
import { changeFeeMsg, phoneNumberMsg, t, userTextMsg } from '../../common/i18n';
import { changeOffer } from '../../selfservice/actions';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  filterAddOns,
  formatPrice,
  getAddOnFormData,
  getAddOnPayload,
  getAddedAddOnForOpeningFee,
  getIconType,
  getMonthlyPrice,
  getPreselectedAddOns,
  handleChangeOffer,
  removeDuplicateAddedAddOns,
  shouldUpdateState,
} from './subscriptionChangeConfirmationUtils.js';
import {
  filterAddonsFromRingSubs,
  filterChangeOfferAddonsFromRingSubs,
  filterVisibilitiesFromRingSubs,
  isRingSubscription,
} from '../../common/utils/ringUtils.js';
import { getNonEditableAddOns } from '../SubscriptionAdditionalService/subscriptionAddOnUtils';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { AddOnRulesResponse } from '../../generated/api/addOnRulesResponse';
import type { AddOnSelectionType, OrderConfiguration } from '../OrderSubscriptionConfiguration/FormWrapper.js';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { Subscription } from '../../generated/api/subscription.js';

import './SubscriptionChangeConfirmation.scss';

const FORM_CONFIG_NAME = 'changeOfferForm';

interface SubscriptionChangeConfirmationProps {
  selectedCommercialProduct: CommercialProduct;
  subscription: Subscription;
  addOnRules: AddOnRulesResponse;
  addOnRulesMobilePbx: AddOnRulesResponse;
  addOnVisibilities: AddOnVisibility[];
  selectedAddOns?: AddedAddon[];
  campaignAssociation?: CampaignAssociation;
  campaignDescription?: string;
  pbxSolutions: Subscription[];
}

const ContentHeader = ({ commercialProduct }: { commercialProduct: CommercialProduct }) => {
  const iconType = getIconType(commercialProduct.productSubType);

  return (
    <div className="of-subscription-change-confirmation__header">
      <div className={`of-subscription-change-confirmation__header__icon__${iconType}`}></div>
      <div className="of-subscription-change-confirmation__header__content">
        <div className="of-subscription-change-confirmation__header__content__wrapper">
          <div className="of-subscription-change-confirmation__header__content__wrapper__product-name">
            {commercialProduct.commercialProductName}
          </div>
          <div className="of-subscription-change-confirmation__header__content__wrapper__product-price">
            {formatPrice(getMonthlyPrice(commercialProduct))} {t.H37G('€/month')}
          </div>
        </div>
        <div className={dsClass.MARGIN_TOP_4}>
          {t.ZRKX(changeFeeMsg)} {formatPrice(SUBSCRIPTION_CHANGE_SERVICE_FEE)} €
        </div>
      </div>
    </div>
  );
};

const PhoneNumberAndUserDetails = ({ subscription }: { subscription: Subscription }) => (
  <>
    <div className="of-subscription-change-confirmation__details__content__header">{t.AGIJ(phoneNumberMsg)}</div>
    <div className="of-subscription-change-confirmation__details__content__value">
      {subscription.subscriptionUserFriendlyId}
    </div>
    <div className="of-subscription-change-confirmation__details__content__header">{t.LUZM(userTextMsg)}</div>
    <div>{subscription.subscriptionPurposeOfUse}</div>
  </>
);

export const SubscriptionChangeConfirmation = ({
  selectedCommercialProduct,
  subscription,
  addOnRules,
  addOnRulesMobilePbx,
  selectedAddOns = [],
  campaignAssociation,
  addOnVisibilities,
  campaignDescription,
  pbxSolutions,
}: SubscriptionChangeConfirmationProps) => {
  const isRingSub = isRingSubscription(subscription, pbxSolutions);
  const visibilitiesConsideringRing = isRingSub ? filterVisibilitiesFromRingSubs(addOnVisibilities) : addOnVisibilities;
  const handleChangeOfferResult = handleChangeOffer(
    subscription,
    selectedCommercialProduct,
    addOnRules,
    addOnRulesMobilePbx,
    isRingSub,
    addOnVisibilities
  );
  const { addOnsToAdd, addOnsToUpdate, addOnsToRemove } = filterChangeOfferAddonsFromRingSubs({
    ...handleChangeOfferResult,
    isRingSub,
  });
  // This is needed in order to show the order summary correctly in the first render
  const preSelectedAddOns = getPreselectedAddOns(
    addOnsToAdd,
    addOnsToUpdate,
    isRingSub ? filterAddonsFromRingSubs(selectedAddOns) : selectedAddOns,
    visibilitiesConsideringRing
  );

  // All addons that are included in the new subscription
  const subscriptionAddOns = Array.from(new Set([...addOnsToAdd, ...addOnsToUpdate]));

  // Addons that cannot be edited but are included in the subscription (e.g. Reissunetti in voice Perus)
  const nonEditableAddons: AddedAddon[] = getNonEditableAddOns(visibilitiesConsideringRing, subscriptionAddOns);

  const [formAddOns, setFormAddOns] = useState<AddOnSelectionType | undefined>({ addedAddOns: selectedAddOns });
  const [configuredCommercialProduct, setConfiguredCommercialProduct] = useState<ConfiguredCommercialProduct>({
    commercialProduct: selectedCommercialProduct,
    addedAddOns: preSelectedAddOns,
    selectedCampaignAssociation: campaignAssociation,
    purposeOfUseOrContact: {
      contactName: subscription.subscriptionContactName,
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm({ mode: 'all' });
  const formValues = methods.watch();

  const onSubmit = () => {
    const { codesToAdd, codesToUpdate, codesToRemove } = getAddOnPayload(
      addOnsToAdd,
      addOnsToUpdate,
      addOnsToRemove,
      isRingSub,
      formAddOns
    );
    dispatch(
      changeOffer(
        subscription.subscriptionId,
        subscription.subscriptionDisplayId,
        subscription.subscriptionType,
        selectedCommercialProduct.commercialProductCode,
        codesToAdd,
        codesToRemove,
        codesToUpdate,
        campaignAssociation?.campaignAssociationCode
      )
    );
  };

  // Keep order summary in sync with form values. Get values from the form and compare the values to the current
  // values in component state. If there has been changes, set new values to the state, so that order summary can be
  // updated. Payload check is needed in order to avoid endless loop and unwanted initial render with empty payload.
  useEffect(() => {
    const updatedFormAddOns: AddOnSelectionType | undefined = getAddOnFormData(formValues as OrderConfiguration);

    if (shouldUpdateState(updatedFormAddOns, formAddOns)) {
      setFormAddOns({ ...updatedFormAddOns });
      setConfiguredCommercialProduct({
        ...configuredCommercialProduct,
        selectedCampaignAssociation: campaignAssociation,
        addedAddOns: [...nonEditableAddons, ...removeDuplicateAddedAddOns(updatedFormAddOns?.addedAddOns || [])],
      });
    }
  }, [
    formValues,
    formAddOns,
    setConfiguredCommercialProduct,
    configuredCommercialProduct,
    selectedCommercialProduct,
    subscription,
    nonEditableAddons,
    campaignAssociation,
  ]);

  return (
    <div className="of-subscription-change-confirmation">
      <div>
        <h3>{t.OKGT('Subscription details')}</h3>
      </div>
      <hr />
      <ContentHeader commercialProduct={selectedCommercialProduct} />
      <div className="of-subscription-change-confirmation__details__wrapper">
        <div className="of-subscription-change-confirmation__details">
          <div className="of-subscription-change-confirmation__details__content">
            <PhoneNumberAndUserDetails subscription={subscription} />
            <FormProvider {...methods}>
              <form noValidate>
                <div>
                  <AdditionalServicesCheckoutContent
                    addOnAssociations={(addOnRules.associations?.[0] as AssociationRecord[]) || []}
                    addOnDependencies={(addOnRules.dependencies?.[0] as DependencyRecord[]) || []}
                    commercialProduct={selectedCommercialProduct}
                    isRingSelected={isRingSub}
                    cartItemInstanceId={FORM_CONFIG_NAME}
                    productAddOns={Array.from(new Set([...selectedAddOns, ...subscriptionAddOns]))}
                    preFetchedAddOnVisibilities={addOnVisibilities}
                    addOnVisibilityOperationType={AddOnVisibilityOperationType.CHANGE}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </div>

        <div className="of-subscription-change-confirmation__details__order-summary">
          <OrderSummary
            commercialProducts={[
              {
                ...configuredCommercialProduct,
                addedAddOns: filterAddOns(configuredCommercialProduct.commercialProduct.commercialProductName, [
                  ...(configuredCommercialProduct.addedAddOns || []),
                  getAddedAddOnForOpeningFee(),
                ]),
              },
            ]}
            summaryType={OrderSummaryType.DETAILS_CLOSED}
            campaignAssociation={campaignAssociation}
            campaignDescription={campaignDescription}
            salesType={SalesType.CHANGE_OFFER}
          />
        </div>
        <div className="of-subscription-change-confirmation__details__buttons">
          <ButtonGroupForSubmitAndBack
            onSubmit={() => methods.handleSubmit(onSubmit)()}
            onCancel={() => navigate(-1)}
          />
        </div>
      </div>
    </div>
  );
};
