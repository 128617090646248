import { InputComponent } from '../components/InputComponent.js';
import { fieldCantBeEmptyMsg, referenceIdExampleMsg, referenceIdMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const Reference = (props: OptionalInputComponentProps) => {
  const {
    name = 'employeeNumber',
    label = t.CWR5(referenceIdMsg),
    placeholder = ' ',
    required = false,
    disabled = false,
    hint = t.FKO8(referenceIdExampleMsg),
    readonly,
    tooltip,
    validate,
  } = props;

  const validateReference = (value: string) => {
    if (required && !value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }

    return validate?.(value);
  };

  return (
    <InputComponent
      validate={validateReference}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      hint={hint}
      tooltip={tooltip}
      readonly={readonly}
      {...props}
    />
  );
};
