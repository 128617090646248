import { OmalaskuStatusResponse } from '../../generated/api/omalaskuStatusResponse';
import { StatusColor } from '../../common/enums';
import {
  barringAdultEntertainmentServicenumberMsg,
  barringConnectionServiceNumbersMsg,
  barringEntertainmentServiceNumberMsg,
  barringGeneralServiceNumbersMsg,
  barringInternationalCallsMsg,
  barringTelephoneAbroadMsg,
  barringTransactionServiceNumberMsg,
  changeRequestInProgressMsg,
  entertainmentBarringMsg,
  generalServiceBarringMsg,
  inUseMsg,
  noBarringsMsg,
  notInUseMsg,
  premiumRateBarringMsg,
  serviceBarringMsg,
  t,
} from '../../common/i18n';

export interface OmalaskuStatusWithMsisdnResponse extends OmalaskuStatusResponse {
  msisdn: string;
}

export const getOmalaskuBarringsTitleText = (barrings: OmalaskuStatusResponse.SpeechBarringsEnum[]): string => {
  const barringsTitleMap = {
    [OmalaskuStatusResponse.SpeechBarringsEnum.P1]:
      `${t.U2A0(generalServiceBarringMsg)} (${OmalaskuStatusResponse.SpeechBarringsEnum.P1})`,
    [OmalaskuStatusResponse.SpeechBarringsEnum.P2]:
      `${t.Z44Y(serviceBarringMsg)} (${OmalaskuStatusResponse.SpeechBarringsEnum.P2})`,
    [OmalaskuStatusResponse.SpeechBarringsEnum.P3]:
      `${t.K7TH(entertainmentBarringMsg)} (${OmalaskuStatusResponse.SpeechBarringsEnum.P3})`,
    [OmalaskuStatusResponse.SpeechBarringsEnum.P4]:
      `${t.OMJ2(premiumRateBarringMsg)} (${OmalaskuStatusResponse.SpeechBarringsEnum.P4})`,
    [OmalaskuStatusResponse.SpeechBarringsEnum.U]: t.F12I(barringInternationalCallsMsg),
    [OmalaskuStatusResponse.SpeechBarringsEnum.R]: t.NDIM(barringTelephoneAbroadMsg),
    [OmalaskuStatusResponse.SpeechBarringsEnum.NONE]: t.Y02E(noBarringsMsg),
  };
  return barrings.map(barring => barringsTitleMap[barring]).join(' + ');
};

export interface BarringsDetails {
  employeePaying: string[];
  companyPaying: string[];
}

export const getOmalaskuBarringsDetailsText = (
  barrings: OmalaskuStatusResponse.SpeechBarringsEnum[]
): BarringsDetails => {
  const p4BarringText: string[] = [t.YFWU(barringAdultEntertainmentServicenumberMsg)];
  const p3BarringText: string[] = [t.JE92(barringEntertainmentServiceNumberMsg), ...p4BarringText];
  const p2BarringText: string[] = [t.MWSW(barringTransactionServiceNumberMsg), ...p3BarringText];
  const p1BarringText: string[] = [
    t.PKES(barringGeneralServiceNumbersMsg),
    t.S1F0(barringConnectionServiceNumbersMsg),
    ...p2BarringText,
  ];
  const uBarringText = [t.F12I(barringInternationalCallsMsg)];
  const rBarringText = [t.NDIM(barringTelephoneAbroadMsg)];
  const allBarringText = [...p1BarringText, ...uBarringText, ...rBarringText];

  const employeePayingTextMap = {
    [OmalaskuStatusResponse.SpeechBarringsEnum.P1]: p1BarringText,
    [OmalaskuStatusResponse.SpeechBarringsEnum.P2]: p2BarringText,
    [OmalaskuStatusResponse.SpeechBarringsEnum.P3]: p3BarringText,
    [OmalaskuStatusResponse.SpeechBarringsEnum.P4]: p4BarringText,
    [OmalaskuStatusResponse.SpeechBarringsEnum.U]: uBarringText,
    [OmalaskuStatusResponse.SpeechBarringsEnum.R]: rBarringText,
    [OmalaskuStatusResponse.SpeechBarringsEnum.NONE]: [],
  };

  const employeePaying = barrings.flatMap(barring => employeePayingTextMap[barring]);
  const companyPaying = allBarringText.filter(barringText => !employeePaying.includes(barringText));

  return {
    employeePaying,
    companyPaying,
  };
};

export const getOmalaskuStatus = (omalaskuStatus: OmalaskuStatusResponse.OmaLaskuStatusEnum) => {
  const statusTextMap = {
    [OmalaskuStatusResponse.OmaLaskuStatusEnum.Active]: { color: StatusColor.GREEN, status: t.V34H(inUseMsg) },
    [OmalaskuStatusResponse.OmaLaskuStatusEnum.Inactive]: { color: StatusColor.GREY, status: t.MUF5(notInUseMsg) },
    [OmalaskuStatusResponse.OmaLaskuStatusEnum.Activation_Inprogress]: {
      color: StatusColor.YELLOW,
      status: t.V09R(changeRequestInProgressMsg),
    },
    [OmalaskuStatusResponse.OmaLaskuStatusEnum.Cancellation_Inprogress]: {
      color: StatusColor.YELLOW,
      status: t.V09R(changeRequestInProgressMsg),
    },
  };
  return statusTextMap[omalaskuStatus];
};
