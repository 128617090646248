import { CheckboxComponent } from '../FieldComponents/index.js';
import { Field } from 'formik';
import type { CheckboxComponentProps } from '../FieldComponents/index.js';

export const Checkbox = ({ ...props }: CheckboxComponentProps) => {
  return (
    <Field
      {...{
        name: props.name,
        label: props.label,
        disabled: props.disabled,
        type: 'checkbox',
        ...props,
      }}
      component={CheckboxComponent}
    />
  );
};
