import * as CL from '@design-system/component-library';

import './Icon.scss';

export interface IconAttrs extends Omit<CL.IconProps, 'color'> {
  color: IconColor;
}

export type IconColor = CL.DsColor;

export const Icon = ({ color, ...rest }: IconAttrs): JSX.Element => {
  return <CL.Icon {...rest} color={color} />;
};
