import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  SearchFilters,
  Table,
  TableTopActions,
} from '../Table/index.js';
import { SelectCompanyDialog } from '../../components/Dialogs/SelectCompanyDialog.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import {
  billingAccountMsg,
  companyNameMsg,
  deviceMsg,
  identifierMsg,
  monthsMsg,
  orderMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import {
  getCommitmentPeriodFilterGroup,
  getDeviceUserFilterGroup,
  getEppFilterGroup,
  getExpiringFilterGroup,
  getPaymentTypeFilterGroup,
  getTypeFilterGroup,
} from './SubscriptionsDeviceFilters.js';
import { getCompanyName, isMultiBiz } from '../../common/utils/accountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { isDefined } from '../../common/utils/objectUtils.js';
import {
  isEppDeviceSubscriptionExpired,
  isEppDeviceSubscriptionExpiring,
} from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { isInExtensionPeriod } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setActiveAccount } from '../../selfservice/exports.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useMultiFilterUpdate } from '../SubscriptionLists/SubscriptionTable.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';
import type {
  SubscriptionAggregationsResponseDeviceAggregations,
  SubscriptionHeader,
  SubscriptionSearchResponse,
} from '../../generated/api/models.js';
import type { SubscriptionDeviceTableSearchParams } from '../SubscriptionLists/SubscriptionTable.js';
import type { SubscriptionsLoaderData } from '../../common/loaders.js';

const getDeviceSubscriptionHeaderColumnDisclaimerText = (subscriptionHeader: SubscriptionHeader) => {
  const eppDeviceSubscriptionExpired = isEppDeviceSubscriptionExpired(subscriptionHeader);
  const eppDeviceSubscriptionInExtensionPeriod = isInExtensionPeriod(subscriptionHeader);

  if (eppDeviceSubscriptionInExtensionPeriod) {
    return `${t.PVHD('Extension period')} 6 ${t.XXVX(monthsMsg)}`;
  } else if (eppDeviceSubscriptionExpired) {
    return t.CL9N('Contract expired');
  } else if (subscriptionHeader.commitmentPeriod) {
    return `${subscriptionHeader.commitmentPeriod} ${t.XXVX(monthsMsg)}`;
  }
  return '';
};

const getDeviceRows = (
  search?: string,
  searchResults: SubscriptionSearchResponse[] = [],
  authenticatedUser?: AuthenticatedUserState
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <>
        <SubscriptionLink subscription={subscriptionHeader} path={paths.PS_DEVICE} search={search}>
          <SubscriptionHiddenFields
            subscriptionHeader={subscriptionHeader}
            search={search}
            matchedFields={matchedFields}
          />
          {subscriptionHeader.serialNumber && (
            <div
              className={`${dsClass.FONT_WEIGHT_400} ${dsClass.FONT_SIZE_SMALL} ${dsClass.FONT_STYLE_NORMAL} ${dsClass.COLOR_NEUTRAL_500}`}
            >
              IMEI {highlightKeyword(subscriptionHeader.serialNumber, search)}
            </div>
          )}
        </SubscriptionLink>
      </>
    ),
    purposeOfUseOrContactName:
      matchedFields?.includes('subscriptionPurposeOfUse') || matchedFields?.includes('subscriptionContactName')
        ? highlightKeyword(
            subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
            search
          )
        : subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    contractEndDate:
      subscriptionHeader.eppSubscription && subscriptionHeader.contractEndDate ? (
        <>
          <div
            className={`${dsClass.DISPLAY_BLOCK} ${
              isEppDeviceSubscriptionExpiring(subscriptionHeader) ? dsClass.COLOR_ORANGE : ''
            }`}
          >
            {formatTimestampToUTCDDMMYYYY(subscriptionHeader?.contractEndDate)}
          </div>
          <div
            className={`${dsClass.FONT_WEIGHT_400} ${dsClass.FONT_SIZE_SMALL} ${dsClass.FONT_STYLE_NORMAL} ${dsClass.COLOR_NEUTRAL_500}`}
          >
            {getDeviceSubscriptionHeaderColumnDisclaimerText(subscriptionHeader)}
          </div>
        </>
      ) : (
        ''
      ),
    companyName: getCompanyName(authenticatedUser, subscriptionHeader.accountMasterId),
  }));
};

// We do not show the epp filter options unless the user has at least one epp device available
const showEppDeviceFilter = (aggregations?: SubscriptionAggregationsResponseDeviceAggregations) => {
  return aggregations?.eppDevices?.all !== 0;
};

export const SubscriptionsDevice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [showSelectCompanyDialog, setShowSelectCompanyDialog] = useState(false);
  const { subscriptions, aggregations } = useLoaderData() as SubscriptionsLoaderData;
  const searchParams = useSearchParams<SubscriptionDeviceTableSearchParams>();
  const { search, subscriptionSubType, eppFilter, expiringFilter, paymentType, commitmentPeriod, deviceUser } =
    searchParams;

  const deviceFilterGroups: SearchFilterGroup[] = [
    getTypeFilterGroup(subscriptionSubType, aggregations.deviceAggregations?.subscriptionSubType),
    showEppDeviceFilter(aggregations.deviceAggregations)
      ? getEppFilterGroup(eppFilter, aggregations.deviceAggregations?.eppDevices)
      : undefined,
    showEppDeviceFilter(aggregations.deviceAggregations)
      ? getCommitmentPeriodFilterGroup(commitmentPeriod, aggregations.deviceAggregations?.commitmentPeriod)
      : undefined,
    showEppDeviceFilter(aggregations.deviceAggregations)
      ? getExpiringFilterGroup(expiringFilter, aggregations.deviceAggregations?.expiring)
      : undefined,
    getPaymentTypeFilterGroup(paymentType, aggregations.deviceAggregations?.paymentType),
    getDeviceUserFilterGroup(deviceUser, aggregations.deviceAggregations?.deviceUser),
  ].filter(isDefined);

  const [_, onFilterUpdated] = useMultiFilterUpdate({
    subscriptionSubType,
    eppFilter,
    paymentType,
    commitmentPeriod,
    deviceUser,
  });

  const onMultiFilterChange = (queryParameters: URLSearchParams) => {
    onFilterUpdated(Object.fromEntries(queryParameters));
  };

  const getColumns = () => [
    { key: 'subscriptionName', label: t.TPVQ(deviceMsg) },
    { key: 'purposeOfUseOrContactName', label: t.L9QG(identifierMsg), sortable: true },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'contractEndDate', label: t.UTIR('Contract ends'), sortable: true },
    { key: 'companyName', label: t.KJTS(companyNameMsg) },
  ];

  return (
    <div className="of-subscription-table">
      {showSelectCompanyDialog && (
        <SelectCompanyDialog
          onCloseDialog={() => setShowSelectCompanyDialog(false)}
          onContinue={selectedMdmId => {
            dispatch(setActiveAccount(selectedMdmId, false, paths.WEB_SHOP));
          }}
        />
      )}
      <TableTopActions
        buttons={[
          {
            label: t.AQOL(orderMsg),
            onClick: () => {
              if (isMultiBiz(authenticatedUser)) {
                setShowSelectCompanyDialog(true);
              } else {
                navigate(paths.WEB_SHOP);
              }
            },
          },
        ]}
      />
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={
          <Table
            columns={getColumns()}
            noItemsText={t.CD0S('No devices')}
            rows={getDeviceRows(search, subscriptions?.searchResults, authenticatedUser)}
          />
        }
        listPagination={<ListPagination totalItems={subscriptions?.total || 0} />}
        listSearch={
          <ListSearch
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        searchFilters={
          <SearchFilters
            displayActiveFiltersAsTiles={!filtersOpen}
            filterGroups={deviceFilterGroups}
            onFilterChange={onMultiFilterChange}
            onModalClose={() => setFiltersOpen(false)}
          />
        }
      />
    </div>
  );
};
