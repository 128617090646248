import { EmployeeLoginPage } from '../../../../components/EmployeeLoginPage/EmployeeLoginPage.js';
import { SiteContext } from '../../SiteContext.js';
import { initiateElisaIdV2Login } from '../../../common/util/elisaIdLogin.js';
import { useContext } from 'react';

export const EmployeeLoginPath = () => {
  const { siteLanguage } = useContext(SiteContext);
  return (
    <div id="elisaid-login-form-parent" className="of-employee__employee-portal-home">
      <EmployeeLoginPage initiateLogin={() => initiateElisaIdV2Login(siteLanguage.substring(0, 2))} />
    </div>
  );
};
