import * as CL from '@design-system/component-library';
import { CheckboxTree } from '../../../components/CheckboxTree/ChecboxTree';
import { dsClass } from '../../constants/dsClasses';
import { fieldCantBeEmptyMsg, t } from '../../i18n';
import { useController, useFormContext } from 'react-hook-form';
import type { CheckboxTreeItem } from '../../../components/CheckboxTree/ChecboxTree';

export interface CheckboxTreeProps {
  data: CheckboxTreeItem[];
  error?: string;
  name: string;
  onChange?: (selectedValues: string[]) => void;
  required?: boolean;
}

export const CheckboxTreeComponent = ({ data, error, name, required = true }: CheckboxTreeProps) => {
  const { control, watch } = useFormContext();
  const values = watch();
  const reset = values.key || 0;
  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      required: required ? { value: required, message: error || t.VPVR(fieldCantBeEmptyMsg) } : false,
    },
  });

  return (
    <>
      <CheckboxTree
        data={data}
        key={`${name}-checkboxtree${reset}`}
        initialValues={reset === 0 ? [] : field.value}
        onChange={(selectedValues: string[]) => field.onChange(selectedValues)}
      />
      <div className={dsClass.MARGIN_TOP_3}>
        {fieldState.error && <CL.InputError>{fieldState.error.message}</CL.InputError>}
      </div>
    </>
  );
};
