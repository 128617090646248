/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Lower level categorization of commercial product. MOBILE_PBX_LIGHT, MOBILE_PBX_PREMIUM and MOBILE_PBX_REGULAR mapped to Elisa Ring Kevyt, Elisa Ring Premium and Elisa Ring (liittymä) respectively. E.g. MOBILE_BROADBAND. SFDC: cspmb__Price_Item__c.Sub_Type__c
 */
export type CommercialProductSubType = 'MOBILE_BROADBAND' | 'MOBILE_VOICE' | 'EPP_DEVICE' | 'DEVICE' | 'MOBILE_PBX_LIGHT' | 'MOBILE_PBX_PREMIUM' | 'MOBILE_PBX_REGULAR' | 'M2M';

export const CommercialProductSubType = {
    MOBILE_BROADBAND: 'MOBILE_BROADBAND' as CommercialProductSubType,
    MOBILE_VOICE: 'MOBILE_VOICE' as CommercialProductSubType,
    EPP_DEVICE: 'EPP_DEVICE' as CommercialProductSubType,
    DEVICE: 'DEVICE' as CommercialProductSubType,
    MOBILE_PBX_LIGHT: 'MOBILE_PBX_LIGHT' as CommercialProductSubType,
    MOBILE_PBX_PREMIUM: 'MOBILE_PBX_PREMIUM' as CommercialProductSubType,
    MOBILE_PBX_REGULAR: 'MOBILE_PBX_REGULAR' as CommercialProductSubType,
    M2M: 'M2M' as CommercialProductSubType
};

