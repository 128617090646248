import { CompanySelector } from '../../../../../../components/CompanySelector/CompanySelector';
import { Grid } from '../../../../../../components/Grid/Grid';
import { dsClass } from '../../../../../../common/constants/dsClasses';
import { getUserAccounts } from '../../../../../../components/Header/dynamic/headerFunctions';
import { isMultiBiz } from '../../../../../../common/utils/accountUtils';
import { selectCompanyHeaderMsg, selectCompanyMsg, t } from '../../../../../../common/i18n';
import { setActiveAccount } from '../../../../../../selfservice/actions';
import { useAuth } from '../../../../../../public/site/AuthProvider';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../../../../../common/hooks/useSearchParams';

import './OrderSelectionPath.scss';

export interface OrderSubscriptionSelectionHeaderProps {
  breadCrumbs: JSX.Element;
}

export const OrderSubscriptionSelectionHeader = ({ breadCrumbs }: OrderSubscriptionSelectionHeaderProps) => {
  const { authenticatedUser } = useAuth();
  const navigate = useNavigate();
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const dispatch = useDispatch();

  return (
    <>
      {isMultiBiz(authenticatedUser) ? (
        <div className="of-subscription-selection-nav">
          {breadCrumbs}
          <Grid>
            <h3 className={dsClass.H3_BOOK}>{t.BR9A(selectCompanyHeaderMsg)}</h3>
            <p>{t.JUY2(selectCompanyMsg)}.</p>
            <CompanySelector
              userAccounts={getUserAccounts(authenticatedUser)}
              onInputChange={(_, option) => {
                dispatch(setActiveAccount(option.accountMasterId!));
                navigate(`?mdmId=${option.accountMasterId}`, { replace: true });
              }}
              initialMdmId={mdmId}
            />
          </Grid>
        </div>
      ) : (
        breadCrumbs
      )}
    </>
  );
};
