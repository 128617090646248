import { CustomerOrderListFields } from '../../common/enums.js';
import { HeadingWithButtonStyleLink } from './HeadingWithButtonStyleLink.js';
import { Link, generatePath } from 'react-router-dom';
import { Status } from '../Status/Status.js';
import { Table } from '../Table/index.js';
import {
  companyMsg,
  contentMsg,
  notPlacedOrdersMsg,
  orderDateMsg,
  orderMsg,
  statusMsg,
  t,
  userTextMsg,
  yourLatestOrdersMsg,
} from '../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getCustomerOrderStatusInfo } from '../CustomerOrderDetails/CustomerOrderDetailsUtil.js';
import { paths } from '../../common/constants/pathVariables.js';
import { truncateText } from '../../common/utils/stringUtils.js';
import type * as CL from '@design-system/component-library';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { CustomerOrderHeader } from '../../generated/api/customerOrderHeader.js';

const truncateTextMaxChars = 100;
export interface SelfServiceHomeCustomerOrdersProps {
  customerOrders?: CustomerOrderHeader[];
  authenticatedUser?: AuthenticatedUserState;
  className?: string;
}
export const SelfServiceHomeCustomerOrders = ({
  customerOrders,
  authenticatedUser,
  className,
}: SelfServiceHomeCustomerOrdersProps) => {
  const columns: CL.Column[] = [
    {
      key: CustomerOrderListFields.CUSTOMER_ORDER_DISPLAY_ID,
      label: t.C001(orderMsg),
      sortable: false,
    },
    {
      key: CustomerOrderListFields.CREATED,
      label: t.ZTPC(orderDateMsg),
      sortable: false,
    },
    {
      key: CustomerOrderListFields.DELIVERY_ORDER_CONTACT_NAMES,
      label: t.U4MA(userTextMsg),
      sortable: false,
    },
    {
      key: CustomerOrderListFields.DELIVERY_ORDERS,
      label: t.NBNH(contentMsg),
      sortable: false,
    },
    {
      key: CustomerOrderListFields.STATUS,
      label: t.ASQT(statusMsg),
      sortable: false,
    },
    {
      key: CustomerOrderListFields.COMPANY,
      label: t.KJTS(companyMsg),
      sortable: false,
    },
  ];

  const rows = customerOrders?.map(value => ({
    [CustomerOrderListFields.CUSTOMER_ORDER_DISPLAY_ID]: (
      <Link
        to={`${generatePath(paths.CUSTOMER_ORDER, { orderId: value?.customerOrderDisplayId })}?mdmId=${
          value.accountMasterId
        }`}
      >
        {value.customerOrderDisplayId}
      </Link>
    ),
    [CustomerOrderListFields.CREATED]: formatTimestampToDDMMYYYY(value.created),
    [CustomerOrderListFields.DELIVERY_ORDER_CONTACT_NAMES]: truncateText(
      (value.deliveryOrderContactNames?.length ?? 0) > 0
        ? value.deliveryOrderContactNames
        : value.deliveryOrderPurposeOfUses,
      truncateTextMaxChars
    ),
    [CustomerOrderListFields.DELIVERY_ORDERS]: truncateText(value.deliveryOrders, truncateTextMaxChars),
    [CustomerOrderListFields.STATUS]: (
      <Status
        textAfterBadge={getCustomerOrderStatusInfo(value.status).text}
        color={getCustomerOrderStatusInfo(value.status).color}
        inTable={true}
      />
    ),
    [CustomerOrderListFields.COMPANY]: getCompanyName(authenticatedUser, value.accountMasterId),
  }));

  return (
    <div className={className}>
      <HeadingWithButtonStyleLink
        headingText={t.WENR(yourLatestOrdersMsg)}
        linkText={t.MEHD('All orders')}
        linkHref={paths.CUSTOMER_ORDERS}
      />
      <Table tableType="bordered" columns={columns} noItemsText={t.EGKZ(notPlacedOrdersMsg)} rows={rows || []} />
    </div>
  );
};
