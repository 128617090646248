import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import { ContractValiditySummary } from '../SubscriptionLists/ContractValiditySummary.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  SearchFilters,
  Table,
  TableTopActions,
} from '../Table/index.js';
import { SelectNewSubscriptionDialog } from '../../components/Dialogs/SelectNewSubscriptionDialog.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import {
  addressOrNumberMsg,
  billingAccountMsg,
  companyMsg,
  contractMsg,
  identifierMsg,
  orderMsg,
  subscriptionMsg,
  t,
} from '../../common/i18n/index.js';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import { getBroadbandSubscriptionTypeFilterGroup } from './SubscriptionsBroadbandFilters';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type * as CL from '@design-system/component-library';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';
import type { SearchFilterGroup } from '../Table/SearchFilters';
import type { SubscriptionSearchResponse } from '../../generated/api/models.js';
import type { SubscriptionsLoaderData } from '../../common/loaders';

const getBroadbandRows = (
  searchResults: SubscriptionSearchResponse[] = [],
  search?: string,
  authenticatedUser?: RealAuthenticatedUserState
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <SubscriptionLink subscription={subscriptionHeader} path={paths.PS_BROADBAND_SUBSCRIPTION} search={search}>
        <SubscriptionHiddenFields
          subscriptionHeader={subscriptionHeader}
          search={search}
          matchedFields={matchedFields}
        />
      </SubscriptionLink>
    ),
    subscriptionUserFriendlyId: matchedFields?.includes('subscriptionUserFriendlyId')
      ? highlightKeyword(subscriptionHeader.subscriptionUserFriendlyId || '', search)
      : subscriptionHeader.subscriptionUserFriendlyId || '',
    purposeOfUseOrContactName: highlightKeyword(
      subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
      search
    ),
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    contractValidity: <ContractValiditySummary subscriptionHeader={subscriptionHeader} />,
    company: getCompanyName(authenticatedUser, subscriptionHeader.accountMasterId),
  }));
};

export interface SubscriptionsBroadbandFilter {
  subscriptionType?: string;
  mdmId?: string;
}

export type SubscriptionBroadbandSearchParams = DefaultListSearchParams & SubscriptionsBroadbandFilter;

export const SubscriptionsBroadband = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const searchParams = useSearchParams<SubscriptionBroadbandSearchParams>();
  const { search, subscriptionType, mdmId } = searchParams;
  const { subscriptions, aggregations } = useLoaderData() as SubscriptionsLoaderData;
  const { authenticatedUser } = useAuth();
  const [showSelectNewSubscriptionDialog, setShowSelectNewSubscriptionDialog] = useState(false);

  const broadbandColumns: CL.Column[] = [
    {
      key: 'subscriptionName',
      label: t.P674(subscriptionMsg),
      width: '25%',
    },
    { key: 'subscriptionUserFriendlyId', label: t.LK7B(addressOrNumberMsg), sortable: true, width: '20%' },
    { key: 'purposeOfUseOrContactName', label: t.L9QG(identifierMsg), sortable: true, width: '25%' },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true, width: '5%' },
    { key: 'contractValidity', label: t.C8I6(contractMsg), sortable: false, width: '25%' },
    { key: 'company', label: t.KJTS(companyMsg), sortable: false },
  ];

  const searchFilterGroups: SearchFilterGroup[] = [
    getBroadbandSubscriptionTypeFilterGroup(subscriptionType, aggregations.broadbandAggregations?.subscriptionType),
  ].filter((filterGroup?: SearchFilterGroup): filterGroup is SearchFilterGroup => filterGroup !== undefined);

  return (
    <div className="of-subscription-table">
      {showSelectNewSubscriptionDialog && (
        <SelectNewSubscriptionDialog
          onCloseDialog={() => setShowSelectNewSubscriptionDialog(false)}
          hideBlankSim={true}
          hideVoice={true}
          mdmId={mdmId || getActiveAccountMasterId()}
        />
      )}
      <TableTopActions
        buttons={[
          {
            label: t.AQOL(orderMsg),
            onClick: () => setShowSelectNewSubscriptionDialog(true),
          },
        ]}
      />
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={
          <Table
            columns={broadbandColumns}
            noItemsText={t.UF2D('No internet subscriptions')}
            rows={getBroadbandRows(subscriptions.searchResults, search, authenticatedUser)}
          />
        }
        listSearch={
          <ListSearch
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        searchFilters={
          <SearchFilters
            displayActiveFiltersAsTiles={!filtersOpen}
            filterGroups={searchFilterGroups}
            onModalClose={() => setFiltersOpen(false)}
          />
        }
        listPagination={<ListPagination totalItems={subscriptions.total || 0} />}
      />
    </div>
  );
};
