import { dsClass } from '../../common/constants/dsClasses.js';
import { forwardRef, useId } from 'react';
import type { ReactNode, Ref, TextareaHTMLAttributes } from 'react';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode;
  error?: string;
  hint?: ReactNode;
  label?: ReactNode;
  wrapperClassName?: string;
}

export const TextArea = forwardRef((props: InputProps, ref: Ref<HTMLTextAreaElement>) => {
  const id = useId();
  const { children, error, hint, label, wrapperClassName, ...rest } = props;

  return (
    <div
      className={[
        dsClass.INPUT,
        error && dsClass.INPUT_ERROR,
        rest.disabled && dsClass.INPUT_DISABLED,
        rest.readOnly && dsClass.INPUT_READONLY,
        wrapperClassName,
      ]
        .filter(i => i)
        .join(' ')}
    >
      <div className={dsClass.INPUT_LABELAREA}>
        {label && (
          <label id={`${id}-label`} className={dsClass.INPUT_LABELAREA_LABEL} htmlFor={`${id}-textarea`}>
            {label}
          </label>
        )}
      </div>
      <div className={dsClass.INPUT_INPUTAREA}>
        <textarea
          {...rest}
          ref={ref}
          id={`${id}-textarea`}
          style={{ verticalAlign: 'top' }}
          className={[dsClass.INPUT_INPUTAREA_TEXTAREA, props.className].filter(i => i).join(' ')}
          aria-labelledby={props.label ? `${id}-label` : undefined}
          aria-invalid={Boolean(error)}
          aria-errormessage={error ? `${id}-error` : undefined}
          aria-describedby={error ? error && `${id}-error` : undefined}
        />
        {children}
      </div>
      {error && (
        <p id={`${id}-error`} className={dsClass.INPUTERROR} role="alert" aria-live="polite">
          {error}
        </p>
      )}
      {hint && (
        <p id={`${id}-hint`} className={dsClass.INPUTHELP}>
          {hint}
        </p>
      )}
    </div>
  );
});
