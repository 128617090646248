import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import {
  companyNumberMsg,
  connectToNumberMsg,
  notInUseMsg,
  notSetMsg,
  officeHourChainMsg,
  outOfOfficeChainMsg,
  t,
} from '../../common/i18n/index.js';
import type { PbxWorkingHoursConfig } from '../PbxWorkingHours/pbxWorkingHoursUtil.js';
import CloseHoursActionEnum = SubscriptionPbxDetails.CloseHoursActionEnum;
import { createPbxWorkingHoursConfig } from '../PbxAccordionContent/PbxAccordionUtils';
import type { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration';

export interface PbxTimeSettingFormData extends PbxWorkingHoursConfig {
  pbxConfigurationDetails: SubscriptionPbxDetails;
  corporateNumber: string;
  connectToNumber?: string;

  currentActiveChain?: SubscriptionPbxDetails.CurrentActiveChainEnum;
  callerLineIdMasking?: SubscriptionPbxDetails.CallerLineIdMaskingEnum;
  callerLineIdTargetNumber?: SubscriptionPbxDetails.CallerLineIdTargetNumberEnum;
  callerLineIdNumber?: string;
  workingHoursReachabilityChain?: string[];
  offWorkReachabilityChain?: string[];
}

export const getCurrentActiveChainItems = () => [
  {
    label: t.W4NW('Install based on the time given'),
    value: SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR.toString(),
  },
  {
    label: t.WXDM(officeHourChainMsg),
    value: SubscriptionPbxDetails.CurrentActiveChainEnum.WORKING_HOURS.toString(),
  },
  {
    label: t.XGWK(outOfOfficeChainMsg),
    value: SubscriptionPbxDetails.CurrentActiveChainEnum.OFF_WORK.toString(),
  },
];

export const getClidMaskingOptions = (pbxType: SubscriptionPbxDetails.PbxTypeEnum | undefined) => {
  const options = [
    {
      label: t.JR2G('Always in use'),
      value: SubscriptionPbxDetails.CallerLineIdMaskingEnum.ALWAYS_ON.toString(),
    },
    {
      label: t.MUF5(notInUseMsg),
      value: SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE.toString(),
    },
  ];
  if (pbxType !== SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU) {
    options.push({
      label: t.E23N('Enabled during office hours'),
      value: SubscriptionPbxDetails.CallerLineIdMaskingEnum.BY_CALENDAR.toString(),
    });
  }
  return options;
};

export const getClidOptions = (pbxType?: SubscriptionPbxDetails.PbxTypeEnum) => {
  const options = [
    {
      label: t.YOU8('Caller’s number'),
      value: SubscriptionPbxDetails.CallerLineIdTargetNumberEnum.ORIGINAL_CLID,
    },
  ];
  if (pbxType !== SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU) {
    options.push({
      label: t.ZX32(companyNumberMsg),
      value: SubscriptionPbxDetails.CallerLineIdTargetNumberEnum.PSTN_NUMBER_OF_SERVICE,
    });
  }
  return options;
};

export const getReachabilityChainLabel = (pbxType?: SubscriptionPbxDetails.PbxTypeEnum) => {
  if (pbxType !== SubscriptionPbxDetails.PbxTypeEnum.VAKIO) {
    return pbxType === SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU
      ? t.Z64W('Answer groups')
      : t.MIQC('Answer group');
  }
  return t.Y3YM('Reachability chain');
};

export const createTimeSettingData = (
  subscriptionPbxConfiguration: SubscriptionPbxConfiguration
): PbxTimeSettingFormData => ({
  corporateNumber: subscriptionPbxConfiguration.corporateNumber,
  ...createPbxWorkingHoursConfig(subscriptionPbxConfiguration),
  pbxConfigurationDetails: subscriptionPbxConfiguration.pbxConfigurationDetails,
});

export const closeHoursAction = (closeHoursEnum?: CloseHoursActionEnum) => {
  switch (closeHoursEnum) {
    case CloseHoursActionEnum.EXIT_TO_NUMBER:
      return t.HS6F(connectToNumberMsg);
    case CloseHoursActionEnum.PLACEHOLDER:
      return 'Placeholder';
    default:
      return t.BTMU(notSetMsg);
  }
};
