import * as CL from '@design-system/component-library';
import { MbbSubscriptionSelectionSectionId } from './MobileBroadbandSubscriptionCards';
import { MobileBroadbandService } from '../../common/constants/subscriptionConstants';
import { Table } from '../Table';
import { noMsg, simCardMsg, t, yesMsg } from '../../common/i18n';

import './MobileBroadbandAlternativesSummary.scss';

enum TableKeys {
  FEATURES = 'features',
  WIFI = 'wifi',
  DATA = 'data',
}

const getTableColumns = (): CL.Column[] => [
  {
    key: TableKeys.FEATURES,
    label: t.JWLU('Features'),
  },
  {
    key: TableKeys.WIFI,
    label: 'Liikkuva Wifi',
  },
  {
    key: TableKeys.DATA,
    label: 'Yritysdata',
  },
];

const getTableRows = (): CL.KeyRow[] => {
  const yes = t.M03S(yesMsg);
  const no = t.HI56(noMsg);

  return [
    {
      [TableKeys.FEATURES]: t.UP0J('Mobile broadband (SIM card)'),
      [TableKeys.WIFI]: yes,
      [TableKeys.DATA]: yes,
    },
    {
      [TableKeys.FEATURES]: t.CYVE('Unlimited domestic internet use'),
      [TableKeys.WIFI]: yes,
      [TableKeys.DATA]: yes,
    },
    {
      [TableKeys.FEATURES]: t.E35R('Works abroad as well'),
      [TableKeys.WIFI]: yes,
      [TableKeys.DATA]: yes,
    },
    {
      [TableKeys.FEATURES]: t.WBV9('Requires a separate device (tablet, computer, router)'),
      [TableKeys.WIFI]: no,
      [TableKeys.DATA]: yes,
    },
    {
      [TableKeys.FEATURES]: t.LRZV('Includes the device'),
      [TableKeys.WIFI]: yes,
      [TableKeys.DATA]: no,
    },
    {
      [TableKeys.FEATURES]: t.NSLH('Includes an exchange service'),
      [TableKeys.WIFI]: yes,
      [TableKeys.DATA]: no,
    },
    {
      [TableKeys.FEATURES]: t.D2O8('Power Book feature'),
      [TableKeys.WIFI]: yes,
      [TableKeys.DATA]: no,
    },
  ];
};

const handleClickScroll = (id: string) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({ behavior: 'smooth' });
};

const LinkButton = ({ targetSectionId }: { targetSectionId: string }) => (
  <div className="link-button-container">
    <CL.Button onClick={() => handleClickScroll(targetSectionId)}>{t.OLVS('Show subscriptions')}</CL.Button>
  </div>
);

const LiikkuvaWifi = () => (
  <div className="infobox-container-item">
    <div className="icon-container-liikkuva-wifi">
      <div className="icon-container-item">
        <strong>{t.PIZC(simCardMsg)}</strong>
      </div>
      <div className="icon-container-item"></div>
      <div className="icon-container-item">
        <strong>{t.CV2A('Mobile router')}</strong>
      </div>
      <div className="icon-container-item">
        <CL.Icon icon="sim" size="l" type="regular" />
      </div>
      <div className="icon-container-item">
        <CL.Icon icon="add" size="l" type="regular" />
      </div>
      <div className="icon-container-item">
        <CL.Icon icon="mobile-broadband" size="l" type="regular" />
      </div>
    </div>
    <h3>{MobileBroadbandService.LIIKKUVA_WIFI}</h3>
    <p>
      {t.OZCV(
        'Suitable for you if your work is mobile and electricity is not always readily available. The compact mobile router with a durable battery is easy to carry around.'
      )}
    </p>
    <LinkButton targetSectionId={MbbSubscriptionSelectionSectionId.WIFI_SUBS_ID} />
  </div>
);

const Yritysdata = () => (
  <div className="infobox-container-item">
    <div className="icon-container-yritysdata">
      <div className="icon-container-item">
        <strong>{t.PIZC(simCardMsg)}</strong>
      </div>
      <div className="icon-container-item">
        <CL.Icon icon="sim" size="l" type="regular" />
      </div>
    </div>
    <h3>{MobileBroadbandService.YRITYSDATA}</h3>
    <p>
      {t.B7E8(
        'Suitable for occasional remote work from places other than home, such as summer cottage. The included SIM card fits routers, mobile routers and tablets, so you can easily transfer from one device to another.'
      )}
    </p>
    <LinkButton targetSectionId={MbbSubscriptionSelectionSectionId.YRITYSDATA_SUBS_ID} />
  </div>
);

const InfoBoxes = () => (
  <div className="mbb-alternatives-info">
    <h2>{t.OT0Z('Mobile broadband options')}</h2>
    <div className="infobox-container">
      <LiikkuvaWifi />
      <Yritysdata />
    </div>
  </div>
);

const PropertyComparison = () => (
  <div className="mbb-feature-comparison">
    <CL.Accordion>
      <CL.AccordionItem heading={t.CNNS('Compare properties')}>
        <Table columns={getTableColumns()} rows={getTableRows()} />
      </CL.AccordionItem>
    </CL.Accordion>
  </div>
);

export const MobileBroadbandAlternativesSummary = () => (
  <div className="mbb-alternatives">
    <InfoBoxes />
    <PropertyComparison />
  </div>
);
