import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useCallback, useEffect, useState } from 'react';
import type { StepperItem } from '@design-system/component-library';

import './FixedBroadbandCheckoutStepManager.scss';

interface CheckoutStepManagerProps {
  defaultActiveStepIndex?: number;
  steps: StepperItem[];
  getNextStepFn: (fn: () => void) => void;
  enableOpenPreviousStep?: boolean;
  onActiveStepChange?: (activeStepIndex: number) => void;
}

export const FixedBroadbandCheckoutStepManager = (props: CheckoutStepManagerProps) => {
  const { onActiveStepChange, steps, defaultActiveStepIndex = 0, getNextStepFn, enableOpenPreviousStep } = props;

  const [activeStepIndex, setActiveStepIndex] = useState(defaultActiveStepIndex);
  const activeStep = steps[activeStepIndex].id;

  const stepsWithCompleteAndOnBackHandler = steps.map((step, idx) => {
    if (idx < activeStepIndex) {
      step.completed = true;
    } else {
      step.completed = false;
      step.onClick = undefined;
    }
    if (enableOpenPreviousStep && step.completed) {
      step.onClick = () => setActiveStepIndex(idx);
    }
    return step;
  });

  const getNextStepFnCallback = useCallback(() => {
    setActiveStepIndex(idx => {
      const newIndex = idx + 1;
      if (onActiveStepChange) {
        onActiveStepChange(newIndex);
      }
      return newIndex;
    });
  }, [onActiveStepChange]);

  useEffect(() => {
    getNextStepFn(getNextStepFnCallback);
  }, [getNextStepFn, getNextStepFnCallback]);

  return (
    <div className={dsClass.RESET}>
      <CL.Stepper steps={stepsWithCompleteAndOnBackHandler} activeStep={activeStep} />
    </div>
  );
};
