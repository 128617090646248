import * as CL from '@design-system/component-library';
import { CompanyAndContactDetails, CustomFormErrorAndButton } from '../CustomFormFields.js';
import { CustomFormRadio } from '../partials/CustomFormRadio.js';
import { PriceSummary } from '../partials/PriceSummary.js';
import { TextInput } from '../../../common/react-hook-form/fields/index.js';
import {
  additionalInformationMsg,
  mainUserEmailMsg,
  mainUserNameMsg,
  mainUserPhoneMsg,
  possibleExtraDetailsMsg,
  selectProductMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { CustomFormProps } from '../CustomForm.js';
import type { CustomFormRadioItem } from '../partials/CustomFormRadio.js';

const serviceOptions: CustomFormRadioItem[] = [
  {
    value: 'Yksisuuntainen palvelu',
    label: 'Yksisuuntainen palvelu',
    monthlyRecurringCharge: 5000,
    oneTimeCharge: undefined,
    isDefault: false,
  },
  {
    value: 'Kaksisuuntainen palvelu',
    label: 'Kaksisuuntainen palvelu',
    monthlyRecurringCharge: 10000,
    oneTimeCharge: undefined,
    isDefault: false,
  },
];

export const DialogiMessagingServiceForm = (props: CustomFormProps) => {
  const { enableCompanyFields = true, leadFailed, leadInProgress, title } = props;
  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow>
        <CL.GridCol className="title" colWidthS={6} colWidthM={6} colWidthL={12} colWidthXL={12}>
          <h2>{title}</h2>
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CustomFormRadio title={t.V9HK(selectProductMsg)} options={serviceOptions} />
          <PriceSummary {...props} />
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CompanyAndContactDetails enableCompanyFields={enableCompanyFields} />
          <h3 className={dsClass.H4}>{t.GZUK(additionalInformationMsg)}</h3>
          <TextInput label={t.WLNI(mainUserNameMsg)} name="mainUserName" placeholder="" required={true} />
          <TextInput label={t.CI5R(mainUserEmailMsg)} name="mainUserEmail" placeholder="" required={true} />
          <TextInput label={t.BMK4(mainUserPhoneMsg)} name="mainUserPhone" placeholder="" required={false} />
          <TextInput label={t.RLGI(possibleExtraDetailsMsg)} name="messageSenderName" placeholder="" required={false} />
          <CustomFormErrorAndButton leadInProgress={leadInProgress} leadFailed={leadFailed} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
