import * as CL from '@design-system/component-library';
import { ApproveOrDeny } from '../ApproveOrDeny/ApproveOrDeny.js';
import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper';
import { DialogType } from '../../common/enums.js';
import { Grid } from '../Grid/Grid.js';
import { Loading } from '../Loading';
import { PostReviewRequest } from '../../generated/api/postReviewRequest.js';
import { StickyFooter } from '../StickyFooter';
import { SubscriptionAction } from '../../generated/api/subscriptionAction.js';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getDeviceTypeText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { getSubscriptionDetailsDevice } from '../SubscriptionDetails/subscriptionDetailsDevice.js';
import { getSubscriptionStatus, getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resumeSubscription, review, showDialog, showTerminateSubscriptionForm } from '../../selfservice/actions';
import { t } from '../../common/i18n';
import { useDispatch } from 'react-redux';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';
import type { SubscriptionRedeemReview } from '../../generated/api/subscriptionRedeemReview.js';
import type { SupportCase } from '../../generated/api/supportCase.js';

const needEppRedeemAdminReview = (subscriptionActions: SubscriptionAction[], subscription: Subscription) => {
  const subscriptionActionItem =
    subscriptionActions?.find(item => item.subscriptionId === subscription.subscriptionId) ?? undefined;
  if (subscriptionActionItem) {
    return (
      subscriptionActionItem.subscriptionActionType ===
        SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE &&
      subscriptionActionItem.status === SubscriptionAction.StatusEnum.PENDING_APPROVAL
    );
  }
  return false;
};

const getButtonTypes = (isAccessory: boolean, subscription: Subscription) => {
  if (isAccessory) {
    return [];
  }

  if (subscription.details?.device?.eppSubscription) {
    if (subscription.subscriptionContactId) {
      return [
        SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST,
        SubscriptionDetailsButtonType.EPP_REDEEM,
        SubscriptionDetailsButtonType.EPP_DEVICE_CHANGE,
        SubscriptionDetailsButtonType.EPP_TERMINATE,
      ];
    }
    // Device change is not supported for subscriptions that have purpose of use
    return [
      SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST,
      SubscriptionDetailsButtonType.EPP_REDEEM,
      SubscriptionDetailsButtonType.EPP_TERMINATE,
    ];
  }

  return [SubscriptionDetailsButtonType.SUPPORT_REQUEST];
};

export const SubscriptionDevice = ({
  billingAccounts,
  breadCrumbs,
  category,
  companyInfo,
  contacts,
  subscription,
  pendingSubscriptionActions,
}: SubscriptionDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const companyName = companyInfo?.companyName;
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const onShowSubscriptionTerminationForm = () => dispatch(showTerminateSubscriptionForm());
  const onResumeSubscription = (subId: string) => dispatch(resumeSubscription(subId));
  const isAccessory = subscription?.subscriptionSubType?.toLowerCase() === 'accessories' || false;
  const subscriptionId = subscription ? subscription.subscriptionId : '…';

  const getHeroPicto = (subscriptionSubType = '') => {
    switch (subscriptionSubType.toLowerCase()) {
      case 'phone':
        return 'phone-filled';
      case 'tablet':
        return 'tablet-filled';
      case 'computers':
        return 'laptop-filled';
      case 'network equipment':
        return 'wireless-device-filled';
      case 'accessories':
        return 'headphones-filled';
      default:
        // Do not show any placeholder icon while loading the data
        return undefined;
    }
  };

  const headingBottom = subscription?.subscriptionContactName
    ? `${subscription?.subscriptionContactName} | ${companyName}`
    : companyName;

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        detailsTop={breadCrumbs}
        heading={subscription?.subscriptionName || ''}
        headingBottom={headingBottom}
        headingTop={getDeviceTypeText(subscription?.subscriptionSubType || '')}
        heroPicto={getHeroPicto(subscription?.subscriptionSubType)}
        id={`subscription-details-${subscriptionId}`}
      >
        <Loading />
      </DetailsWrapper>
    );
  }

  if (!subscription.details.device) {
    throw new Error('Subscription data insufficient!');
  }

  const subscriptionDetails = (
    <>
      <div className="of-subscription-details__content of-subscription-details__content--device">
        {subscriptionStatus.pendingActions && (
          <CL.Disclaimer
            className={dsClass.MARGIN_BOTTOM_7}
            disclaimerType="warning"
            visible={true}
            icon={<CL.Icon icon="notification" />}
            title={t.H0XT('Change request in process')}
            text={t.D7Y7(
              `Change request for this device is currently in process. You can't make new changes until the previous change request has been processed. Note that the change will become visible once the change request has been processed.`
            )}
          />
        )}
        {getSubscriptionDetailsDevice(
          subscription,
          subscription.details.device,
          <SubscriptionDetailsBillingAccount
            billingAccounts={billingAccounts}
            subscription={subscription}
            pendingSubscriptionActions={pendingSubscriptionActions}
          />
        )}
        <ButtonGroup
          buttonTypes={getButtonTypes(isAccessory, subscription)}
          companyInfo={companyInfo}
          subscription={subscription}
          subscriptionStatus={{
            ...subscriptionStatus,
            pendingActions: subscriptionStatus.pendingActions,
          }}
          onShowDialog={onShowDialog}
          onShowSubscriptionTerminationForm={onShowSubscriptionTerminationForm}
          onResumeSubscription={onResumeSubscription}
          onClickEppMaintenanceRequest={(subId: string) => {
            navigate(generatePath(paths.PS_DEVICE_SUPPORT_REQUEST + search, { subscriptionId: subId }));
          }}
          onClickEppRedeem={() => {
            navigate(
              generatePath(paths.PS_DEVICE_REDEMPTION + search, { subscriptionId: getSubscriptionUrlId(subscription) }),
              { replace: true }
            );
          }}
          mdmId={mdmId}
        />
        <SubscriptionDetailsAccordions
          subscription={subscription}
          pendingSubscriptionActions={pendingSubscriptionActions}
          companyInfo={companyInfo}
          category={category}
          contacts={contacts}
          billingAccounts={billingAccounts}
        />
      </div>
      {needEppRedeemAdminReview(pendingSubscriptionActions || [], subscription) && (
        <StickyFooter active={true}>
          <Grid>
            {/* TODO this logic is almost same as in SubscriptionActionDetailsView.tsx and it could be combined to live in ApproveOrDeny */}
            <ApproveOrDeny
              approveButtonLabel={t.RDZA('Approve redeem request')}
              denyButtonLabel={t.NC6Y('Reject redeem request')}
              onApprove={() => {
                onShowDialog({
                  body: t.AWTH('EPP redeem request'),
                  header: t.KCZ6('Confirm Approve Redeem Request'),
                  onConfirm: () => {
                    dispatch(
                      review(
                        { subscriptionId: subscription.subscriptionId } as SubscriptionRedeemReview,
                        PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        pendingSubscriptionActions
                      )
                    );
                  },
                  type: DialogType.GENERIC_CONFIRMATION_DIALOG,
                });
              }}
              onDeny={() => {
                onShowDialog({
                  type: DialogType.EPP_REDEEM_REJECT,
                  onRedeemReject: (rejectReason: SupportCase) => {
                    dispatch(
                      review(
                        {
                          subscriptionId: subscription.subscriptionId,
                        } as SubscriptionRedeemReview,
                        PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM,
                        false,
                        rejectReason.description,
                        undefined,
                        undefined,
                        pendingSubscriptionActions
                      )
                    );
                  },
                });
              }}
            />
          </Grid>
        </StickyFooter>
      )}
    </>
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionName}
      headingBottom={headingBottom}
      headingTop={getDeviceTypeText(subscription?.subscriptionSubType || '')}
      heroPicto={getHeroPicto(subscription.subscriptionSubType)}
      id={`subscription-details-${subscriptionId}`}
    >
      {subscriptionDetails}
    </DetailsWrapper>
  );
};
