import { ProductSelectionCheckbox } from './ProductSelectionCheckbox';
import { createImageUrl } from '../../common/utils/domUtils';
import { dsClass } from '../../common/constants/dsClasses';
import { getStartingPrice } from './CatalogProductSelectionUtils';
import type { AccessoryCategory, ProductRecommendation } from '../../common/utils/catalogUtils';

interface AccessoryRecommendationsProps {
  recommendations: ProductRecommendation[];
  productCode: string;
  imagesBaseUrl: string;
  category: AccessoryCategory;
  title: string;
}

export const AccessoryRecommendations = ({
  recommendations,
  productCode,
  imagesBaseUrl,
  category,
  title,
}: AccessoryRecommendationsProps) => {
  const recommendedAccessories = recommendations
    .filter(acc => acc.code === productCode)
    .flatMap(acc => acc.recommendedAccessories?.[category] || []);
  return recommendedAccessories.length > 0 ? (
    <>
      <div className={dsClass.FONT_WEIGHT_BOLD}>{title}</div>
      {recommendedAccessories.map(accessory => {
        return (
          <div key={accessory.code} className="of-catalog-products-selection__table__related-accessory">
            <div className="of-catalog-products-selection__table__related-accessory_prod-checkbox">
              <ProductSelectionCheckbox product={accessory} />
            </div>
            <div className="of-catalog-products-selection__table__images_related">
              <img
                alt={accessory.manufacturer}
                {...createImageUrl({
                  format: 'png',
                  height: 150,
                  imagesBaseUrl,
                  originalUrl: accessory.imageUrl,
                  scalingFactors: [0.5, 1, 1.5],
                  width: 150,
                })}
              />
            </div>
            <div className="of-catalog-products-selection__table__related-accessory_prod-text">
              {accessory.manufacturer} {accessory.name}
            </div>
            <div className="of-catalog-products-selection__table__related-accessory_prod-price">
              <div className={dsClass.WHITE_SPACE_NOWRAP}> {getStartingPrice(accessory)} </div>
            </div>
          </div>
        );
      })}
    </>
  ) : null;
};
