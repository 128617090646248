// Cookies are stored in the document, so don't try to use this in SSR, it makes no sense
export const getCookie = (name: string): string | null => {
  const nameEq = `${name}=`;
  const cookies = document.cookie.split(/\s*;\s*/);

  const cookie = cookies.find(c => c.startsWith(nameEq));
  if (cookie) {
    return cookie.substring(nameEq.length, cookie.length);
  }
  return null;
};
