import { CommonErrorType } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { PbxTimeSettingsEditor } from './PbxTimeSettingsEditor.js';
import { PbxTimeSettingsView } from './PbxTimeSettingsView.js';
import { SubscriptionPbxDetails, WorkingHours } from '../../generated/api/models.js';
import { changeSubscriptionPbxConfiguration } from '../../selfservice/actions/index.js';
import { createTimeSettingData } from './pbxTimeSettingsUtil.js';
import {
  invalidTimeMsg,
  officeHourChainValidityRequiredMsg,
  startTimeCantBeGreaterMsg,
  t,
} from '../../common/i18n/index.js';
import type { CommonError } from '../../common/types/errors.js';
import type { Dispatch } from 'redux';
import type { PbxTimeSettingFormData } from './pbxTimeSettingsUtil.js';
import type { RangeNumbersState } from '../../common/types/states.js';
import type { Subscription, SubscriptionPbxConfiguration } from '../../generated/api/models.js';
import CurrentActiveChainEnum = SubscriptionPbxDetails.CurrentActiveChainEnum;
import {
  filterWorkingHours,
  updateConfigurationToSaveWithWorkingHours,
} from '../PbxAccordionContent/PbxAccordionUtils';

import './PbxTimeSettingsAccordionContent.scss';

export type PbxTimeSettingsAccordionContentProps = {
  dispatch: Dispatch;
  subscriptionPbxConfiguration: SubscriptionPbxConfiguration;
  numberRange?: RangeNumbersState;
  subscriptionId: string;
  editing?: boolean;
  errors?: CommonError[];
  pbxSolution: Subscription;
  saving?: boolean;
  companySubscription?: Subscription;

  onCancel: () => void;
  onEdit: () => void;
};

const getErrorsFromPbxWorkingHours = (pbxConfig: SubscriptionPbxConfiguration) => {
  const errors: CommonError[] = [];
  const workingHoursWeekdayStart = filterWorkingHours(
    pbxConfig,
    WorkingHours.WorkingHoursTypeEnum.WEEKDAY,
    true,
    false
  );
  const workingHoursWeekdayEnd = filterWorkingHours(pbxConfig, WorkingHours.WorkingHoursTypeEnum.WEEKDAY, false, false);
  const workingHoursWeekendStart = filterWorkingHours(
    pbxConfig,
    WorkingHours.WorkingHoursTypeEnum.WEEKEND,
    true,
    false
  );
  const workingHoursWeekendEnd = filterWorkingHours(pbxConfig, WorkingHours.WorkingHoursTypeEnum.WEEKEND, false, false);

  if (
    workingHoursWeekdayStart === undefined ||
    workingHoursWeekdayEnd === undefined ||
    workingHoursWeekendStart === undefined ||
    workingHoursWeekendEnd === undefined
  ) {
    if (pbxConfig.pbxConfigurationDetails.currentActiveChain === CurrentActiveChainEnum.CALENDAR) {
      if (workingHoursWeekdayStart === undefined) {
        errors.push({
          field: 'workingHoursWeekday[0]',
          message: t.DZA1(officeHourChainValidityRequiredMsg),
          parent: 'pbxWorkingHours',
          type: CommonErrorType.VALIDATION,
        });
      }
      if (workingHoursWeekdayEnd === undefined) {
        errors.push({
          field: 'workingHoursWeekday[1]',
          message: t.DZA1(officeHourChainValidityRequiredMsg),
          parent: 'pbxWorkingHours',
          type: CommonErrorType.VALIDATION,
        });
      }
      if (workingHoursWeekendStart === undefined) {
        errors.push({
          field: 'workingHoursWeekend[0]',
          message: t.DZA1(officeHourChainValidityRequiredMsg),
          parent: 'pbxWorkingHours',
          type: CommonErrorType.VALIDATION,
        });
      }
      if (workingHoursWeekendEnd === undefined) {
        errors.push({
          field: 'workingHoursWeekend[1]',
          message: t.DZA1(officeHourChainValidityRequiredMsg),
          parent: 'pbxWorkingHours',
          type: CommonErrorType.VALIDATION,
        });
      }
    }
  } else {
    const workingHoursWeekdayStartNumber =
      typeof workingHoursWeekdayStart === 'string' ? parseInt(workingHoursWeekdayStart, 10) : workingHoursWeekdayStart;
    const workingHoursWeekdayEndNumber =
      typeof workingHoursWeekdayEnd === 'string' ? parseInt(workingHoursWeekdayEnd, 10) : workingHoursWeekdayEnd;
    const workingHoursWeekendStartNumber =
      typeof workingHoursWeekendStart === 'string' ? parseInt(workingHoursWeekendStart, 10) : workingHoursWeekendStart;
    const workingHoursWeekendEndNumber =
      typeof workingHoursWeekendEnd === 'string' ? parseInt(workingHoursWeekendEnd, 10) : workingHoursWeekendEnd;

    if (workingHoursWeekdayStartNumber < 0 || workingHoursWeekdayStartNumber >= 24 * 60 * 60 * 1000) {
      errors.push({
        field: 'workingHoursWeekday[0]',
        message: t.SZBW(invalidTimeMsg),
        parent: 'pbxWorkingHours',
        type: CommonErrorType.VALIDATION,
      });
    }
    if (workingHoursWeekdayEndNumber < 0 || workingHoursWeekdayEndNumber >= 24 * 60 * 60 * 1000) {
      errors.push({
        field: 'workingHoursWeekday[1]',
        message: t.SZBW(invalidTimeMsg),
        parent: 'pbxWorkingHours',
        type: CommonErrorType.VALIDATION,
      });
    }
    if (workingHoursWeekdayStart > workingHoursWeekdayEnd) {
      errors.push({
        field: 'workingHoursWeekday[0]',
        message: t.E9GS(startTimeCantBeGreaterMsg),
        parent: 'pbxWorkingHours',
        type: CommonErrorType.VALIDATION,
      });
    }

    if (workingHoursWeekendStartNumber < 0 || workingHoursWeekendStartNumber >= 24 * 60 * 60 * 1000) {
      errors.push({
        field: 'workingHoursWeekend[0]',
        message: t.SZBW(invalidTimeMsg),
        parent: 'pbxWorkingHours',
        type: CommonErrorType.VALIDATION,
      });
    }
    if (workingHoursWeekendEndNumber < 0 || workingHoursWeekendEndNumber >= 24 * 60 * 60 * 1000) {
      errors.push({
        field: 'workingHoursWeekend[1]',
        message: t.SZBW(invalidTimeMsg),
        parent: 'pbxWorkingHours',
        type: CommonErrorType.VALIDATION,
      });
    }
    if (workingHoursWeekendStart > workingHoursWeekendEnd) {
      errors.push({
        field: 'workingHoursWeekend[0]',
        message: t.E9GS(startTimeCantBeGreaterMsg),
        parent: 'pbxWorkingHours',
        type: CommonErrorType.VALIDATION,
      });
    }
  }

  return errors;
};

const getErrorsFromNumberChain = (pbxConfig: SubscriptionPbxConfiguration) => {
  const errors: CommonError[] = [];
  const currentActiveChain = pbxConfig.pbxConfigurationDetails.currentActiveChain;
  const offWorkReachabilityChain = pbxConfig.pbxConfigurationDetails.offWorkReachabilityChain;

  if (currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR) {
    if (!Array.isArray(pbxConfig.workingHoursReachabilityChain) || pbxConfig.workingHoursReachabilityChain.length < 1) {
      errors.push({
        field: 'workingHoursReachabilityChain[0]',
        message: t.XI84(
          'The first extension number is required for the office-hours chain when time control is selected'
        ),
        parent: 'pbxNumberChains',
        type: CommonErrorType.VALIDATION,
      });
    }
    if (!Array.isArray(offWorkReachabilityChain) || offWorkReachabilityChain.length < 1) {
      errors.push({
        field: 'offWorkReachabilityChain[0]',
        message: t.DCMM('The first number for the out-of-office chain is required when time control is selected'),
        parent: 'pbxNumberChains',
        type: CommonErrorType.VALIDATION,
      });
    }
  } else if (currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.WORKING_HOURS) {
    if (!Array.isArray(pbxConfig.workingHoursReachabilityChain) || pbxConfig.workingHoursReachabilityChain.length < 1) {
      errors.push({
        field: 'workingHoursReachabilityChain[0]',
        message: t.PRLT(
          'The first extension number is required for the office-hours chain when ‘office-hours chain’ is selected'
        ),
        parent: 'pbxNumberChains',
        type: CommonErrorType.VALIDATION,
      });
    }
  } else if (currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.OFF_WORK) {
    if (!Array.isArray(offWorkReachabilityChain) || offWorkReachabilityChain.length < 1) {
      errors.push({
        field: 'offWorkReachabilityChain[0]',
        message: t.ZML8(
          'The first number for the out-of-office chain is required when out-of-office chain is selected'
        ),
        parent: 'pbxNumberChains',
        type: CommonErrorType.VALIDATION,
      });
    }
  }

  return errors;
};

export const getErrorsFromPbxTimeSettings = (pbxConfig: SubscriptionPbxConfiguration) => {
  const errors: CommonError[] = getErrorsFromPbxWorkingHours(pbxConfig);
  const numberChainErrors = getErrorsFromNumberChain(pbxConfig);
  if (numberChainErrors.length) {
    errors.push(...numberChainErrors);
  }
  if (
    pbxConfig.pbxConfigurationDetails.pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO &&
    pbxConfig.pbxConfigurationDetails.callerLineIdMasking !==
      SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE &&
    !pbxConfig.pbxConfigurationDetails.callerLineIdNumber
  ) {
    errors.push({
      field: 'pbxConfigurationDetails.callerLineIdNumber',
      message: t.WJN7('The number to be displayed is required when number conversion is enabled.'),
      parent: 'pbxSettings',
      type: CommonErrorType.VALIDATION,
    });
  }

  return errors.length > 0 ? errors : undefined;
};

const updateConfigurationToSaveWithPbxSettings = (
  formData: PbxTimeSettingFormData,
  pbxConfigurationToSave: SubscriptionPbxConfiguration
) => {
  pbxConfigurationToSave.pbxConfigurationDetails.currentActiveChain = formData.currentActiveChain;
  pbxConfigurationToSave.connectToNumber = formData.connectToNumber;
  pbxConfigurationToSave.pbxConfigurationDetails.callerLineIdTargetNumber = formData.callerLineIdTargetNumber;
  pbxConfigurationToSave.corporateNumberRangeId = undefined;
  pbxConfigurationToSave.extensionRangeId = undefined;
  pbxConfigurationToSave.pbxConfigurationDetails.callerLineIdMasking =
    formData.callerLineIdMasking ?? SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE;

  if (pbxConfigurationToSave.pbxConfigurationDetails.pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO) {
    pbxConfigurationToSave.pbxConfigurationDetails.callerLineIdNumber = formData.callerLineIdNumber;
  }

  pbxConfigurationToSave.pbxConfigurationDetails.offWorkReachabilityChain =
    formData.offWorkReachabilityChain?.filter(n => !!n) ?? [];
  pbxConfigurationToSave.workingHoursReachabilityChain = formData.workingHoursReachabilityChain?.filter(n => !!n) ?? [];
};

const preparePbxConfigurationToSave = (
  formData: PbxTimeSettingFormData,
  pbxConfiguration: SubscriptionPbxConfiguration
) => {
  const pbxConfigurationToSave = { ...pbxConfiguration };

  updateConfigurationToSaveWithPbxSettings(formData, pbxConfigurationToSave);
  updateConfigurationToSaveWithWorkingHours(formData, pbxConfigurationToSave);

  return pbxConfigurationToSave;
};

export const PbxTimeSettingsAccordionContent = (props: PbxTimeSettingsAccordionContentProps) => {
  const timeSettings = createTimeSettingData(props.subscriptionPbxConfiguration);
  const methods = useForm<PbxTimeSettingFormData>({ defaultValues: timeSettings });
  const data = methods.watch();
  const errors = getErrorsFromPbxTimeSettings(preparePbxConfigurationToSave(data, props.subscriptionPbxConfiguration));

  const onSaveWanted = (formData: PbxTimeSettingFormData) => {
    const pbxConfigurationToSave = preparePbxConfigurationToSave(formData, props.subscriptionPbxConfiguration);

    props.dispatch(
      changeSubscriptionPbxConfiguration(props.subscriptionId, pbxConfigurationToSave, getErrorsFromPbxTimeSettings)
    );
  };

  const onEditCancelled = () => {
    methods.reset();
    props.onCancel();
  };

  if (props.editing) {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSaveWanted)}>
          <PbxTimeSettingsEditor
            pbxSolution={props.pbxSolution}
            timeSettings={timeSettings}
            errors={errors}
            saving={props.saving}
            onEditCompleted={onEditCancelled}
          />
        </form>
      </FormProvider>
    );
  }

  return (
    <PbxTimeSettingsView pbxSolution={props.pbxSolution} timeSettings={timeSettings} onEditWanted={props.onEdit} />
  );
};
