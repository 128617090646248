import {
  OpenFormBillingAccount,
  OpenFormBillingContact,
  OpenFormBillingInvoiceDeliveryMethod,
  OpenFormContact,
} from '../../OpenFormComponents/OpenFormFormatted.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import {
  billingContactMsg,
  billingDetailsMsg,
  deliveryMethodMsg,
  newBillingAgreementMsg,
  selectedBillingAccountMsg,
  t,
} from '../../../../common/i18n/index.js';

export const OpenFormSummaryBillingAccount = ({ summary }: { summary?: OpenFormSummary }) => {
  const ba = summary?.billingAccountData ?? summary?.billingAccount;
  const co = summary?.contactEntries.find(([_, c]) => c.Id === ba?.billingContactId)?.[1];
  return !ba ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.RPMR(billingDetailsMsg)}</h4>
        <OpenFormGridRow key={ba.billingAccountName}>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem
              title={
                'billingAccountDisplayId' in ba ? t.HVS2(selectedBillingAccountMsg) : t.NCIJ(newBillingAgreementMsg)
              }
            >
              <OpenFormBillingAccount ba={ba} />
            </OpenFormItem>
          </OpenFormGridCol>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem title={t.XZH6(deliveryMethodMsg)}>
              <OpenFormBillingInvoiceDeliveryMethod ba={ba} />
            </OpenFormItem>
          </OpenFormGridCol>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem title={t.EY5Q(billingContactMsg)}>
              {co ? <OpenFormContact co={co} /> : ba ? <OpenFormBillingContact ba={ba} /> : null}
            </OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
