import * as CL from '@design-system/component-library';
import * as React from 'react';
import { HeaderShoppingCart } from '../HeaderShoppingCart/HeaderShoppingCart.js';
import { Header as PublicHeader } from '../Header/Header.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { SmallHeader } from '../SmallHeader/SmallHeader.js';
import {
  chosenLanguageMsg,
  closeMsg,
  englishMsg,
  forTheEmployeeMsg,
  logInMsg,
  logOutMsg,
  myInformationMsg,
  suomiMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { employeeHeaderAccordions } from './dynamic/employeeHeaderAccordions.js';
import { employeeNavigationLinksWithCurrentProperty } from './dynamic/employeeNavigationLinks.js';
import { employeeUserMenuLinks } from './dynamic/employeeUserMenuLinks.js';
import { getPublishedCatalogs } from '../../common/utils/catalogUtils.js';
import { getUser } from '../../selfservice/actions/index.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { siteSelect } from '../Header/dynamic/headerFunctions.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userHasApproverRole } from '../../common/utils/employeeUtils.js';
import type { NestedSubMenu } from '@design-system/component-library';
import type { State } from '../../selfservice/common/store.js';

import './EmployeeHeader.scss';

export const NewEmployeeHeader = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { siteBaseUrl } = useContext(SiteContext);
  const dispatch = useDispatch();
  const { siteLanguage, saveUserPreferredLanguage } = React.useContext(SiteContext);
  const menuId = 'employee-oma-elisa-navigation';
  const hamburgerRef = useRef<HTMLElement>(null);

  const { ssoSessionValid, elisaIdSessionValid, authenticatedUser } = useAuth();

  const { isSmallHeaderShown, virtualCatalogItems, elisaIdV2 } = useSelector(
    (state: State) => ({
      isSmallHeaderShown: !state.layout?.hideHeaderFooter,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
      elisaIdV2: state.config.featureFlags.elisaIdV2,
    }),
    deepEqual
  );

  const isLoggedIn = !!authenticatedUser?.userName;
  const { firstName = '', lastName = '', email = '', companyName = '' } = authenticatedUser || {};
  const hasApproverRole = userHasApproverRole(authenticatedUser);
  const hasPublishedCatalogs = virtualCatalogItems
    ? (getPublishedCatalogs(virtualCatalogItems) || []).length > 0
    : false;
  const navigationItemList: NestedSubMenu[] = employeeNavigationLinksWithCurrentProperty(
    hasApproverRole,
    hasPublishedCatalogs,
    pathname,
    siteBaseUrl
  );
  const personalLinks = employeeUserMenuLinks(navigate, hasApproverRole, false);
  const accordions = employeeHeaderAccordions();

  const languageSelect = {
    languages: [
      { name: suomiMsg, abbr: 'fi' },
      { name: englishMsg, abbr: 'en' },
    ],
    selectedLanguage: siteLanguage.substring(0, 2),
    onLanguageChange: ({ lang }: { lang: string }) => {
      saveUserPreferredLanguage(lang);
    },
  };

  const isAuthenticated = (isFeatureEnabled(elisaIdV2) && ssoSessionValid) || (ssoSessionValid && elisaIdSessionValid);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUser());
    }
  }, [dispatch, isAuthenticated]);

  const employeeLogout = () => {
    logout(paths.EMPLOYEE_LOGOUT);
  };

  return (
    <>
      {isSmallHeaderShown && decodeURI(pathname).startsWith(`${paths.EMPLOYEE_EMAIL_VERIFICATION}/`) ? (
        <SmallHeader />
      ) : isLoggedIn ? (
        <CL.Header
          desktopBreakpointOffset={35}
          className="of-employee-header"
          isLoggedIn={isLoggedIn}
          languageSelect={languageSelect}
          siteSelect={siteSelect(siteBaseUrl)}
        >
          <CL.HeaderNavigationSection>
            <CL.HeaderLogoAndService
              logo={{
                label: 'Elisa',
                onClick: e => {
                  e.preventDefault();
                  navigate(paths.EMPLOYEE_HOME);
                },
                url: '/',
              }}
              service={
                companyName
                  ? {
                      identifiers: ['OmaElisa', t.ACGC(forTheEmployeeMsg)],
                      label: '',
                      onClick: e => {
                        e.preventDefault();
                        navigate(paths.EMPLOYEE_HOME);
                      },
                      url: paths.EMPLOYEE_HOME,
                    }
                  : undefined
              }
            />
          </CL.HeaderNavigationSection>
          <CL.HeaderNavigationSection>
            <CL.HeaderNavigation
              id={menuId}
              languageSelect={languageSelect}
              navigationItemList={navigationItemList}
              onButtonClick={() => {}}
              onLinkClick={(e, { url }) => {
                const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');
                if (url && !isAbsolute.test(url)) {
                  e.preventDefault();
                  navigate(url);
                }
              }}
              requireLogin={false}
              returnFocusToRef={hamburgerRef}
              siteSelect={siteSelect(siteBaseUrl)}
            />
          </CL.HeaderNavigationSection>
          <CL.HeaderNavigationSection>
            <HeaderShoppingCart
              toShoppingCart={paths.EMPLOYEE_SHOPPING_CART}
              toCheckout={paths.EMPLOYEE_DEVICE_CHECKOUT}
            />
            <CL.HeaderUserMenu
              i18nButtonLabel={t.MIFJ(myInformationMsg)}
              i18nLanguageSwitchSelectedLanguageLabel={t.FT4V(chosenLanguageMsg)}
              i18nLogin={t.Z16I(logInMsg)}
              logout={{
                onClick: employeeLogout,
                title: t.LQ3X(logOutMsg),
              }}
              notifications={personalLinks}
              personalLinks={[]}
              user={
                authenticatedUser
                  ? {
                      email,
                      fullName: `${firstName} ${lastName}`,
                      name: `${firstName} ${lastName}`,
                    }
                  : undefined
              }
              userPronounText={firstName}
              userMenuLinkAccordions={accordions}
            />
            <CL.HeaderHamburger
              i18nCloseAlt={t.WOYD(closeMsg)}
              i18nOpenAlt="menu"
              ref={hamburgerRef}
              toggledModalChild={menuId}
            />
          </CL.HeaderNavigationSection>
        </CL.Header>
      ) : (
        <PublicHeader />
      )}
    </>
  );
};
