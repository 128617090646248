import * as CL from '@design-system/component-library';
import { PhoneNumber } from '../../common/react-hook-form/fields';
import { dsClass } from '../../common/constants/dsClasses';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import { t } from '../../common/i18n';

export interface RespondentListProps {
  numbers?: string[];
  name?: string;
  edit?: boolean;
  getError?: () => string | undefined;
  isFirstRequired?: boolean;
}

interface NumberViewOrEditProps {
  label: string;
  name: string;
  number: string;
  edit: boolean;
  error?: string;
  required?: boolean;
}

const NumberViewOrEdit = ({ label, name, number, edit, required = false, error }: NumberViewOrEditProps) => (
  <div className="of-pbx-accordion-content__number">
    {edit ? (
      <>
        <PhoneNumber name={name} required={required} label={label} />
        {error && <p className={dsClass.INPUTERROR}>{error}</p>}
      </>
    ) : (
      <>
        <div className={`${dsClass.PADDING_BOTTOM_1}`}>
          <label className={dsClass.INPUT_LABELAREA_LABEL}>{label}</label>
        </div>
        {formatPhoneNumber(number || '—')}
      </>
    )}
  </div>
);

export const RespondentList = ({
  numbers = [],
  name,
  edit = false,
  getError,
  isFirstRequired = false,
}: RespondentListProps) => {
  return (
    <>
      {Array.from(Array(4).keys()).map(number => (
        <CL.GridCol
          colsXS={4}
          colsS={6}
          colsM={3}
          colsL={3}
          colsXL={3}
          className={dsClass.PADDING_BOTTOM_2}
          key={number}
        >
          <NumberViewOrEdit
            name={`${name}[${number}]`}
            number={numbers[number]}
            label={`${t.UQVM('Respondent')} ${number + 1}`}
            edit={edit}
            required={number === 0 && isFirstRequired}
            error={number === 0 && getError ? getError() : undefined}
          />
        </CL.GridCol>
      ))}
    </>
  );
};
