import { BillingAccount, BillingAccountDeliveryMethod, ContactType } from '../../generated/api/models.js';
import { BillingAccountDeliveryMethodForm } from './components/BillingAccountDeliveryMethodForm.js';
import { BillingAccountForm } from './components/BillingAccountForm.js';
import { Form, Formik } from 'formik';
import {
  ReceiverTypes,
  getContactDisplayValue,
  getDefaultBillingContactId,
  getDefaultDeliveryMethod,
  getElectronicInvoiceOperatorDropDownOptions,
  getEmptyBillingAccount,
  getReceiverType,
  onBillingAccountSaveValues,
} from '../../common/utils/billingAccountUtils.js';
import { SaveOrCancelCreateBillingAccount } from './components/SaveOrCancelCreateBillingAccount.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { loadBillChannels } from '../../selfservice/actions/billChannelActions.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { Address, BillChannel, BillingAccountStatus, Contact, ContactHeader } from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type {
  ChangeCatalogBillingAccountWizardParams,
  ChangeSubscriptionBillingAccountWizardParams,
} from '../../common/types/wizard.js';
import type { FormikProps } from 'formik';
import type { State } from '../../selfservice/common/store.js';

import '../BillingAccountDetails/BillingAccountDetails.scss';

export const getBillingAccountToSave = (
  values: BillingAccountCreateFormValues | BillingAccount | undefined,
  contacts?: Contact[] | ContactHeader[]
) => {
  const newContact = contacts?.find(contact => contact.contactId === values?.billingContactId);
  const newReceiver = contacts?.find(contact => contact.contactId === values?.billReceiverId);
  const receiverContact = newReceiver as Contact;
  const receiverContactHeader = newReceiver as ContactHeader;
  const newContactDisplayName = getContactDisplayValue(newContact);
  const additionalBillReceiversEmptyRemoved = values?.additionalBillReceiverEmails?.filter(Boolean);
  const receiverType = getReceiverType(values);

  const newReceiverValue =
    receiverType === ReceiverTypes.OTHER
      ? {
          billReceiverType: ContactType.PERSON,
          billReceiverName: getContactDisplayValue(newReceiver),
          billReceiverEmail: receiverContact?.person?.email || receiverContactHeader?.email,
        }
      : {};

  return {
    newValues: {
      ...values,
      billingContactType: ContactType.PERSON,
      billingContactName: newContactDisplayName,
      additionalBillReceiverEmails: additionalBillReceiversEmptyRemoved,
      ...newReceiverValue,
    },
    receiverType,
  };
};

export interface CreateBillingAccountProps {
  billChannels?: BillChannel[];
  contacts: Contact[] | ContactHeader[];
  commonErrors?: CommonError[];
  getPrepareSaveValuesFn?: (prepareSaveValues: BillingAccountOrErrorSupplier) => void;
  payerName: string;
  isSaving: boolean;
  scrollTo?: (elementOrY: HTMLElement | number) => void;
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
  objRevisionRefField?: string;

  onUpsertBillingAccount?: (
    updatedBillingAccount: BillingAccount,
    validationErrors?: CommonError[],
    wizardParams?: ChangeSubscriptionBillingAccountWizardParams | ChangeCatalogBillingAccountWizardParams
  ) => void;
}

export interface BillingAccountCreateFormValues {
  billingAccountId?: string;
  billingAccountName?: string;
  billingAccountStatus?: BillingAccountStatus;
  billingAccountExtensionName?: string;
  accountBalance?: number;
  payerName: string;
  payerNameExtension?: string;
  payerBusinessId?: string;
  payerAddress: Address;
  billingContactId?: string;
  billFormatType: BillingAccount.BillFormatTypeEnum;
  billingContactName?: string;
  deliveryMethod: BillingAccountDeliveryMethod;
  billLanguage: string;
  billReceiverType?: ContactType;
  additionalBillReceiverEmails?: string[];
  billElectronicOperator?: string;
  billElectronicAddress?: string;
  billReceiverName?: string;
  billReceiverId?: string;
  customerReference1?: string;
  customerReference2?: string;
  customerReference3?: string;
  customerReference4?: string;
}

export const CreateBillingAccount = ({
  billChannels,
  contacts,
  onUpsertBillingAccount,
  payerName,
  setRefCallback,
  isSaving,
  getPrepareSaveValuesFn,
  commonErrors,
}: CreateBillingAccountProps) => {
  const billChannelsFromRedux = useSelector((state: State) => state?.selfservice?.billChannels, deepEqual);
  const billingAccountSearchResults = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const defaultPayerAddress = useSelector((state: State) => state.selfservice?.companyInfo?.address, deepEqual);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!billChannels) {
      dispatch(loadBillChannels());
    }
  }, [dispatch, billChannels]);

  const emptyBillingAccount: BillingAccount = getEmptyBillingAccount(payerName);
  const usedBillChannels = billChannels ?? billChannelsFromRedux?.items;
  const defaultOperator =
    (usedBillChannels && getElectronicInvoiceOperatorDropDownOptions(usedBillChannels)[0].value) || undefined;
  const billingAccountHeaders = billingAccountSearchResults?.searchResults?.map(r => r.result);
  const defaultBillingContactId = getDefaultBillingContactId(billingAccountHeaders);
  const defaultDeliveryMethod = getDefaultDeliveryMethod(billingAccountHeaders);

  const initialFormValues: BillingAccountCreateFormValues = {
    payerName,
    billingContactId: defaultBillingContactId,
    billFormatType: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITH_ITEMIZATION,
    billLanguage: 'FI',
    billReceiverType: ContactType.PERSON,
    deliveryMethod: defaultDeliveryMethod ?? BillingAccountDeliveryMethod.ELECTRONIC,
    billElectronicOperator: defaultOperator,
    billingAccountName: '',
    billElectronicAddress: '',
    billReceiverId: '',
    payerAddress: {
      countryCode: 'FIN',
      line1: defaultPayerAddress?.line1 ?? '',
      postOffice: defaultPayerAddress?.postOffice ?? '',
      postalCode: defaultPayerAddress?.postalCode ?? '',
    },
  };

  const formikRef = useRef<FormikProps<BillingAccountCreateFormValues>>(null);

  /**
   * We should get rid of this getPrepareSaveValuesFn and other ref's.
   * --> That would mean refactoring the components using this (mainly AddOrSelectBillingAccount)
   * And components using AddOrSelectBillingAccount (at least KeyUserBillingStep and OrderDeliveryOptions).
   */
  useEffect(() => {
    if (!formikRef.current?.isValid) {
      // submit touches all fields (and thus shows errors on not yet touched fields)
      formikRef.current?.submitForm();
    }
    if (getPrepareSaveValuesFn) {
      getPrepareSaveValuesFn(() => {
        const { newValues } = getBillingAccountToSave(formikRef.current?.values, contacts);
        return {
          obj: newValues as BillingAccount,
        };
      });
    }
  }, [formikRef, commonErrors, contacts]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const submitBillingAccountDetails = (values: BillingAccount) => {
    const { newValues, receiverType } = getBillingAccountToSave(values, contacts);
    if (onUpsertBillingAccount) {
      onBillingAccountSaveValues(
        emptyBillingAccount,
        newValues as BillingAccount,
        onUpsertBillingAccount,
        receiverType,
        undefined,
        location.state
      );
    }
  };

  return (
    <div className="of-create-billing-account">
      <div className="of-billing-account-details">
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validateOnMount={true}
          onSubmit={() => {}}
          innerRef={formikRef}
        >
          <Form>
            <BillingAccountForm setRefCallback={setRefCallback} contacts={contacts} />
            <BillingAccountDeliveryMethodForm
              billingAccount={emptyBillingAccount}
              billChannels={usedBillChannels || []}
              contacts={contacts || []}
            />
            {onUpsertBillingAccount && (
              <SaveOrCancelCreateBillingAccount
                submitBillingAccountDetails={submitBillingAccountDetails}
                emptyBillingAccount={emptyBillingAccount}
                isSaving={isSaving}
              />
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};
