import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid';
import { Loading } from '../Loading';
import { ReportStatus } from '../../generated/api/reportStatus';
import { companiesMsg, companyMsg, contentMsg, createdMsg, t } from '../../common/i18n';
import { downloadReport } from '../../common/fetch';
import { dsClass } from '../../common/constants/dsClasses';
import { formatTimeStampToYYYYMMDDHHmm } from '../../common/utils/dateUtils';
import { getCompanyName } from '../../common/utils/accountUtils';
import type { AuthenticatedUserState } from '../../common/types/states';
import type { ReportItemResponse } from '../../generated/api/reportItemResponse';
import type { ReportType } from '../../generated/api/reportType';
import type React from 'react';

import './ReportDetails.scss';

const Creating = () => (
  <Grid>
    <CL.GridRow className="buttons-content">
      <CL.GridCol colWidthXS={1} colWidthS={1}>
        <Loading big={false} />
      </CL.GridCol>
      <CL.GridCol colWidthXS={3} colWidthS={5}>
        <div className={`${dsClass.FONT_SIZE_SMALL} status-text`}>{t.T57S('Creating report...')}</div>
      </CL.GridCol>
    </CL.GridRow>
  </Grid>
);

const reportTypeTitle = (reportType: ReportType) => {
  switch (reportType) {
    case 'devices_and_accessories_report':
      return t.ML02('Purchased devices');
    case 'epp_device_mobile_and_pc_report':
      return t.KZ5M('Elisa Palvelupäätelaitteet');
    case 'epp_device_lifecycle_report':
      return t.KQ9B('Lifecycle of Elisa Palvelupäätelaitteet devices');
    case 'epp_device_leasing_liabilities_report':
      return t.JM44('Lease liability for Elisa Palvelupäätelaitteet');
  }
};

const reportTypeContent = (reportType: ReportType) => {
  switch (reportType) {
    case 'devices_and_accessories_report':
      return t.PN10(`Devices and accessories purchased with one-time or monthly payments`);
    case 'epp_device_mobile_and_pc_report':
      return t.KZ5M('Elisa Palvelupäätelaitteet');
    case 'epp_device_lifecycle_report':
      return t.XIOZ(`Lifecycle and events of Elisa Palvelupäätelaitteet devices`);
    case 'epp_device_leasing_liabilities_report':
      return t.JM44('Lease liability calculation for Elisa Palvelupäätelaitteet ');
  }
};

const exportDataFromURI = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const downloadReportAction = async (event: React.SyntheticEvent, onlineReportType: string, onlineReportId: string) => {
  event.preventDefault();
  const response = await downloadReport(onlineReportType, onlineReportId);
  if (response.ok) {
    const blob = await response.blob();
    const header = response.headers.get('Content-Disposition');
    // RegExp for parsing filename from Content-Disposition header
    const fileName = header ? header.split('=')[1].replace(/['"]+/g, '') : 'Report.xlsx';
    exportDataFromURI(blob, fileName);
  }
};

export interface ReportDetailsProps {
  authenticatedUser?: AuthenticatedUserState;
  handleUpdate: (report: ReportItemResponse) => void;
  isCreating: boolean;
  isMultibiz: boolean;
  report: ReportItemResponse;
}

export const ReportDetails = ({
  authenticatedUser,
  isCreating,
  isMultibiz,
  handleUpdate,
  report,
}: ReportDetailsProps) => (
  <CL.NotificationBanner
    className={`${dsClass.MARGIN_TOP_4} of-report-details`}
    icon={<CL.Icon icon="file-xls" color="primary" />}
    title={reportTypeTitle(report.onlineReportType!)}
    buttons={
      isCreating ? (
        <Creating />
      ) : (
        <>
          <CL.Button
            className={dsClass.MARGIN_RIGHT_1}
            size="s"
            icon={<CL.Icon icon="update" color="primary" className={dsClass.MARGIN_RIGHT_2} />}
            color="link"
            onClick={() => handleUpdate(report)}
          >
            {t.XF2A('Update')}
          </CL.Button>
          {report.status !== ReportStatus.Error && (
            <CL.Button
              size="s"
              icon={<CL.Icon icon="download" color="invert" className={dsClass.MARGIN_RIGHT_2} />}
              color="primary"
              onClick={e => downloadReportAction(e, report.onlineReportType!, report.onlineReportId!)}
            >
              {t.IRZJ('Download')}
            </CL.Button>
          )}
        </>
      )
    }
  >
    <div className="of-report-details">
      <p>
        <strong className={dsClass.MARGIN_RIGHT_1}>{t.NBNH(contentMsg)}:</strong>
        {reportTypeContent(report.onlineReportType!)}
      </p>
      <p>
        <strong className={dsClass.MARGIN_RIGHT_1}>{isMultibiz ? t.T57L(companiesMsg) : t.KJTS(companyMsg)}:</strong>
        {report.accountMasterIds
          ?.map(mdmId => getCompanyName(authenticatedUser, mdmId))
          .slice()
          .sort((a, b) => a.localeCompare(b))
          .join(', ')}
      </p>
      {report.updated && (
        <p>
          <strong className={dsClass.MARGIN_RIGHT_1}>{t.GSYE(createdMsg)}:</strong>
          {report.status === ReportStatus.Success
            ? `${formatTimeStampToYYYYMMDDHHmm(report.updated!)} | ${t.T57N('Available for download 24h')}`
            : '–'}
        </p>
      )}
      {report.status === ReportStatus.Error && (
        <div>
          <CL.InputError className={dsClass.DISPLAY_FLEX}>{t.T57P('Report creation failed')}</CL.InputError>
        </div>
      )}
    </div>
  </CL.NotificationBanner>
);
