import { BillingAccountDetailsEditForm } from './details/BillingAccountDetailsEditForm.js';
import { BillingAccountDetailsView } from './details/BillingAccountDetailsView.js';
import { EditingSection } from '../billingAccountDetailsEnums.js';
import type {
  BillChannel,
  BillingAccount,
  BillingAccountScheduledChange,
  Contact,
} from '../../../generated/api/models.js';
import type { BillingAccountVersion } from '../billingAccountDetailsEnums.js';
import type { ReceiverType } from '../../../common/utils/billingAccountUtils.js';
import type { ScheduledChangeValues } from './details/BillingAccountDetailsEditForm.js';

export interface BillingAccountFormProps {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  onSaveBillingAccount: (values: BillingAccount, schedule: ScheduledChangeValues, receiverType: ReceiverType) => void;
  onDeleteClick: () => void;
  contacts: Contact[];
  setEditingSection: (editingSection: EditingSection) => void;
  editableInClassic: boolean;
  editingSection: EditingSection;
  test?: string;
  showScheduledChange?: boolean;
  billingAccountVersion: BillingAccountVersion;
  billingAccountScheduledChange?: BillingAccountScheduledChange;
  isSaving?: boolean;
}

export const BillingAccountDetailsForm = ({
  editableInClassic,
  contacts,
  onSaveBillingAccount,
  billingAccount,
  billChannels,
  setEditingSection,
  editingSection,
  showScheduledChange,
  billingAccountScheduledChange,
  billingAccountVersion,
  onDeleteClick,
  isSaving,
}: BillingAccountFormProps) =>
  editingSection === EditingSection.DETAILS || editingSection === EditingSection.SCHEDULE ? (
    <BillingAccountDetailsEditForm
      billingAccount={
        billingAccountScheduledChange && showScheduledChange
          ? billingAccountScheduledChange.billingAccount
          : billingAccount
      }
      billChannels={billChannels}
      contacts={contacts}
      isSaving={isSaving}
      onSubmit={onSaveBillingAccount}
      setNotEditing={() => setEditingSection(EditingSection.NONE)}
      billingAccountScheduledChange={billingAccountScheduledChange}
      billingAccountVersion={billingAccountVersion}
      editingSection={editingSection}
    />
  ) : (
    <BillingAccountDetailsView
      activeBillingAccount={billingAccount}
      billingAccountScheduledChange={billingAccountScheduledChange}
      billingAccountVersion={billingAccountVersion}
      billChannels={billChannels}
      contacts={contacts}
      setNewEditingSection={setEditingSection}
      editingSection={editingSection}
      editableInClassic={editableInClassic}
      showScheduledChange={showScheduledChange}
      onDeleteClick={onDeleteClick}
    />
  );
