import { ActionPhase } from '../common/storeUtils.js';
import { Category, isRetainNumberProduct } from '../../common/utils/cartProductUtils.js';
import { CommercialProductSubType, OnlineModelCategory } from '../../generated/api/models.js';
import { PriceSubType, getErrorsFromCheckout } from '../../common/utils/checkoutUtils.js';
import { TypeKeys } from '../actions/index.js';
import { VAT_MULTIPLIER } from '../../common/utils/taxUtils.js';
import { addToActionHistory, updateActionStatePhase } from '../common/index.js';
import {
  determineExistingPhoneNumberErrors,
  mapEnrollmentProgramConsentToConfiguredCommercialProducts,
  mapPurposeOfUseOrContactsToConfiguredCommercialProducts,
  reduceCrudAction,
} from './reducerUtils.js';
import { getErrorsFromAddress } from '../../components/DeliveryAddress/DeliveryAddress.js';
import { getErrorsFromUpdateUserDetails } from '../../components/UserDetails/UserDetails.js';
import { getErrorsFromUpsertBillingAccount } from '../../common/utils/billingAccountUtils.js';
import { isEnrollmentProgramCompatible } from '../../components/DeviceCheckout/deviceCheckoutUtils.js';
import type { ActionsHistory, DeviceCheckoutState } from '../common/store.js';
import type {
  AddOnBundle,
  CartItemType,
  PeriodicEntity,
  Price,
} from '../common/shopping-cart/shoppingCartInterfaces.js';
import type { CartValidationRules, PostProcessCartItemsAction, SelfServiceActionTypes } from '../actions/index.js';
import type { CommonError } from '../../common/types/errors.js';
import type { PhoneNumberOwner, SelectedPhoneNumber } from '../../common/types/subscription.js';
import type { PrimitiveAdditionalField } from '../common/shopping-cart/shoppingCartFunctions.js';
import type { ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

const getPeriodicPriceForOnlyEmployeeUsers = (periodicPrice: PeriodicEntity, validationRules?: CartValidationRules) => {
  if (
    periodicPrice.price &&
    periodicPrice.priceSubType === PriceSubType.EPP_DEVICE &&
    validationRules?.corporateShare !== undefined &&
    validationRules.corporateShare < Math.round(periodicPrice.price * 100)
  ) {
    return Math.round(VAT_MULTIPLIER * (Math.round(periodicPrice.price * 100) - validationRules.corporateShare));
  }
  return 0;
};

const calculateProductPrice = (price: Price, isEmployee: boolean, validationRules?: CartValidationRules) => {
  if (isEmployee) {
    return {
      guid: price.guids[0],
      onetime: { price: 0 },
      periodic: price.periodic?.length
        ? {
            payments: price.periodic[0].payments,
            price: getPeriodicPriceForOnlyEmployeeUsers(price.periodic[0], validationRules),
            priceSubType: price.periodic[0].priceSubType,
            billingPeriod: price.periodic[0].billingPeriod,
          }
        : undefined,
    };
  }
  return {
    guid: price.guids[0],
    onetime:
      price.onetime.price >= 0
        ? {
            price: Math.round(price.onetime.price * 100),
          }
        : undefined,
    periodic: price.periodic?.length
      ? {
          payments: price.periodic[0].payments,
          price: Math.round(price.periodic[0].price * 100),
          priceSubType: price.periodic[0].priceSubType,
          billingPeriod: price.periodic[0].billingPeriod,
        }
      : undefined,
  };
};

const toCleanedUpperCase = (str: string) => {
  return str.replace(/\s|_/g, '').toUpperCase();
};

const isEppDevice = (priceSubType?: CommercialProductSubType) => {
  return toCleanedUpperCase(priceSubType || '') === toCleanedUpperCase(CommercialProductSubType.EPP_DEVICE);
};

const hasOneTimePrice = (cartItem: CartItemType) => cartItem.bundleItems[0].price.onetime.price > 0;
const hasRecurringPrice = (cartItem: CartItemType) => {
  return (
    cartItem.bundleItems[0].price.periodic?.length &&
    !isEppDevice(cartItem.bundleItems[0].price.periodic[0].priceSubType) &&
    cartItem.bundleItems[0].price.periodic[0].price > 0
  );
};
const hasEppPrice = (cartItem: CartItemType) =>
  cartItem.bundleItems[0].price.periodic?.length &&
  isEppDevice(cartItem.bundleItems[0].price.periodic[0].priceSubType) &&
  cartItem.bundleItems[0].price.periodic[0].price > 0;
const hasContractPeriod = (cartItem: CartItemType, contractPeriod: number) =>
  cartItem.bundleItems[0].price.periodic?.length &&
  cartItem.bundleItems[0].price.periodic[0].payments === contractPeriod;
const isAccessory = (cartItem: CartItemType) =>
  cartItem.bundle.category === 'Accessories' || cartItem.bundle.category === OnlineModelCategory.ACCESSORIES;
const isNotAccessory = (cartItem: CartItemType) => !isAccessory(cartItem);

const filterCartItem = (cartItems: CartItemType[], isEmployee: boolean, validationRules?: CartValidationRules) => {
  if (isEmployee && !validationRules?.products) {
    return [];
  }
  if (!isEmployee && !validationRules) {
    return cartItems;
  }
  return cartItems.filter(item => {
    if (isEmployee && !validationRules!.products!.includes(item.bundle.guid)) {
      return false;
    }
    if (item.bundle.category === Category.SALES_PRODUCT || item.bundle.category === Category.SELF_SERVICE) {
      return true;
    }
    if (isNotAccessory(item)) {
      if (!validationRules?.oneTimeAllowed && hasOneTimePrice(item)) {
        return false;
      }
      if (!validationRules?.recurringAllowed && hasRecurringPrice(item)) {
        return false;
      }
      if (!validationRules?.eppAllowed && hasEppPrice(item)) {
        return false;
      }
      if (validationRules?.contractPeriod && !hasContractPeriod(item, validationRules.contractPeriod)) {
        return false;
      }
    } else if (validationRules?.eppAllowed && hasRecurringPrice(item)) {
      // don't show recurring prices for accessories when epp is allowed.
      return false;
    }
    return true;
  });
};

const getSelectedAddOns = (addOnBundle: AddOnBundle, isEmployee: boolean) =>
  addOnBundle.selectedAddons.reduce((addOns: Array<ShoppingCartAddOn>, selectedAddOnCode) => {
    const addOn = addOnBundle.availableAddOns.find(availableAddOn => availableAddOn.addOnCode === selectedAddOnCode);
    if (addOn) {
      addOns.push({
        addOnAssociationCode: addOn.addOnAssociationCode,
        addOnAssociationId: addOn.addOnAssociationId,
        addOnCode: addOn.addOnCode,
        addOnGroup: addOn.addOnGroup,
        addOnMonthlyRecurringCharge:
          addOn.addOnMonthlyRecurringCharge !== null && !isEmployee ? addOn.addOnMonthlyRecurringCharge : undefined,
        addOnOneTimeCharge: addOn.addOnOneTimeCharge !== null && !isEmployee ? addOn.addOnOneTimeCharge : undefined,
        addOnProductName: addOn.addOnProductName,
        addOnPurpose: addOn.addOnPurpose,
        addOnType: addOn.addOnType,
        display: addOn.display,
      });
    }
    return addOns;
  }, []);

const cartItemsHaveNoError = (cartItems: ShoppingCartItemForCheckout[]): boolean => {
  return (
    cartItems.map(cartItem => cartItem.errors).filter(itemErrors => (itemErrors && itemErrors.length > 0) || false)
      .length === 0
  );
};

export const mapCartItem = (
  cartItem: CartItemType,
  action: PostProcessCartItemsAction
): ShoppingCartItemForCheckout => {
  return {
    id: cartItem.id,
    addOns: cartItem.bundleItems[0].addOnBundle.availableAddOns,
    selectedAddOns: cartItem.bundleItems[0].addOnBundle
      ? getSelectedAddOns(cartItem.bundleItems[0].addOnBundle, action.isEmployee)
      : [],
    category: cartItem.bundle.category,
    commercialProductCodes: cartItem.bundleItems[0].price.guids,
    imageListingUrl: cartItem.bundleItems[0].product.images[0],
    manufacturer: cartItem.bundle.manufacturer,
    offerCode: cartItem.bundleItems[0].product.guid,
    price: calculateProductPrice(cartItem.bundleItems[0].price, action.isEmployee, action.validationRules),
    productPrice: calculateProductPrice(cartItem.bundleItems[0].price, false),
    productName: cartItem.bundleItems[0].product.name,
    purposeOfUseOrContacts: [],
    numberPublicities: [],
    simCardConfigurations: [],
    selectedPhoneNumbers: [],
    phoneNumberOwners: [],
    quantity: cartItem.quantity,
    productPath: cartItem.bundleItems[0].productPath,
    onlineModelCode: cartItem.bundle.guid,
    onlineModelName: cartItem.bundle.name,
    enrollmentProgramConsents: [],
    additionalFields: cartItem.bundleItems[0].additionalFields,
    additionalFieldValues: [],
    productType: cartItem.bundleItems[0].productType,
  };
};

export function deviceCheckoutReducer(
  state: (DeviceCheckoutState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (DeviceCheckoutState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.POST_PROCESS_CART_ITEMS: {
      if (!action.cartUpdated && !action.cartItemJson) {
        return {
          ...state,
          cartUpdated: false,
        };
      }

      const cartItems: CartItemType[] = JSON.parse(action.cartItemJson!);
      const latestItemAddedToCart: CartItemType | undefined = action.latestItemAddedToCart
        ? filterCartItem([JSON.parse(action.latestItemAddedToCart)], action.isEmployee, action.validationRules)[0]
        : undefined;
      return {
        ...state,
        cartItems: filterCartItem(cartItems, action.isEmployee, action.validationRules).map(item => {
          return mapCartItem(item, action);
        }),
        cartUpdated: action.cartConfig?.skipToggle === true ? false : action.cartUpdated,
        latestItemAddedToCart: latestItemAddedToCart ? mapCartItem(latestItemAddedToCart, action) : undefined,
        latestItemAddedToCartTimestamp: action.latestItemAddedToCartTimestamp,
      };
    }

    case TypeKeys.CLEAR_CART_ITEMS: {
      return {
        ...state,
        cartItems: [],
      };
    }

    case TypeKeys.CLEAR_DELIVERY_DETAILS: {
      return {
        ...state,
        deliveryDetails: undefined,
      };
    }

    case TypeKeys.SET_CART_UPDATED: {
      return {
        ...state,
        cartUpdated: action.cartUpdated,
      };
    }

    case TypeKeys.PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS: {
      const arrayOfErrors: Record<string, CommonError[]> = state.cartItems
        .map(cartItem => {
          return { id: cartItem.id, value: [] };
        })
        .reduce((a, v) => ({ ...a, [v.id]: v.value }), {});

      state.cartItems.map(cartItem => {
        if (isRetainNumberProduct(cartItem.productName)) {
          const selectedPhoneNumbers: SelectedPhoneNumber[] = action.cartItemAdditionalParameters[cartItem.id]
            .filter(item => item.selectedPhoneNumber)
            .map(item => item.selectedPhoneNumber!);
          const phoneNumberOwners: PhoneNumberOwner[] = action.cartItemAdditionalParameters[cartItem.id]
            .filter(item => item.phoneNumberOwner)
            .map(item => item.phoneNumberOwner!);
          if (selectedPhoneNumbers.length > 0) {
            determineExistingPhoneNumberErrors(
              selectedPhoneNumbers,
              phoneNumberOwners,
              arrayOfErrors[cartItem.id],
              cartItem.id
            );
          }
        }
      });

      const cartItems = state.cartItems.map(item => {
        const isItemEnrollmentProgramCompatible = isEnrollmentProgramCompatible(
          action.enrollmentPrograms,
          item.offerCode,
          action.onlineModels
        );
        const additionalFieldValues = action.cartItemAdditionalParameters[item.id].map(array => {
          return item.additionalFields
            .filter(field => 'name' in field)
            .map((field: PrimitiveAdditionalField) => {
              return array.additionalFields[field.name]?.trim().length !== 0
                ? {
                    [field.name]: array.additionalFields[field.name],
                  }
                : {};
            })
            .reduce((acc, c) => ({ ...acc, ...c }), {});
        });

        return {
          ...item,
          errors: arrayOfErrors[item.id].length > 0 ? arrayOfErrors[item.id] : undefined,
          enrollmentProgramConsents: isItemEnrollmentProgramCompatible
            ? mapEnrollmentProgramConsentToConfiguredCommercialProducts(
                item,
                action.cartItemAdditionalParameters[item.id].map(
                  cartItemAdditionalParameter => cartItemAdditionalParameter.enrollmentProgramConsent
                )
              )
            : undefined,
          enrollmentProgramAliases: isItemEnrollmentProgramCompatible
            ? action.cartItemAdditionalParameters[item.id].map(
                cartItemAdditionalParameter => cartItemAdditionalParameter.enrollmentProgramAlias
              )
            : undefined,
          purposeOfUseOrContacts:
            arrayOfErrors[item.id].length > 0
              ? item.purposeOfUseOrContacts
              : mapPurposeOfUseOrContactsToConfiguredCommercialProducts(
                  item,
                  action.cartItemAdditionalParameters[item.id].map(
                    cartItemAdditionalParameter => cartItemAdditionalParameter.purposeOfUseOrContact
                  )
                ),
          numberPublicities: action.cartItemAdditionalParameters[item.id].map(
            cartItemAdditionalParameter => cartItemAdditionalParameter.numberPublicity
          ),
          simCardConfigurations:
            arrayOfErrors[item.id].length > 0
              ? item.simCardConfigurations
              : action.cartItemAdditionalParameters[item.id]
                  .filter(cartItemAdditionalParameter => cartItemAdditionalParameter.simCardConfiguration)
                  .map(cartItemAdditionalParameter => cartItemAdditionalParameter.simCardConfiguration),
          selectedPhoneNumbers:
            arrayOfErrors[item.id].length > 0
              ? item.selectedPhoneNumbers
              : action.cartItemAdditionalParameters[item.id]
                  .filter(cartItemAdditionalParameter => cartItemAdditionalParameter.selectedPhoneNumber)
                  .map(cartItemAdditionalParameter => cartItemAdditionalParameter.selectedPhoneNumber),
          phoneNumberOwners: action.cartItemAdditionalParameters[item.id]
            .filter(cartItemAdditionalParameter => cartItemAdditionalParameter.phoneNumberOwner)
            .map(cartItemAdditionalParameter => cartItemAdditionalParameter.phoneNumberOwner),
          additionalFieldValues,
        };
      });

      if (action.validateOnly) {
        return {
          ...state,
          actions: addToActionHistory(state, {
            phase: cartItemsHaveNoError(cartItems) && !action.hasFormErrors ? ActionPhase.SUCCESS : ActionPhase.FAILED,
            value: action,
          }),
          cartItems,
        };
      } else {
        return {
          ...state,
          actions: addToActionHistory(state, {
            phase: cartItemsHaveNoError(cartItems) && !action.hasFormErrors ? ActionPhase.SUCCESS : ActionPhase.FAILED,
            value: action,
          }),
          cartItems,
          deliveryDetails: action.deliveryDetails,
        };
      }
    }

    case TypeKeys.DUPLICATE_CONTACTS_CHECK: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        duplicateContactCheck: {
          inProgress: true,
        },
      };
    }

    case TypeKeys.DUPLICATE_CONTACTS_CHECK_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.DUPLICATE_CONTACTS_CHECK,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        duplicateContactCheck: {
          inProgress: false,
          duplicateContacts: action.response.duplicateContacts,
        },
        moveToNextStep: !(action.response.duplicateContacts && action.response.duplicateContacts.length),
      };
    }

    case TypeKeys.GET_DELIVERY_METHODS: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        deliveryMethods: {
          isLoading: true,
        },
      };
    }

    case TypeKeys.GET_DELIVERY_METHODS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_DELIVERY_METHODS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        deliveryMethods: {
          isLoading: false,
          lisOfDeliveryMethods: action.response?.filter(item => item.isActive),
        },
      };
    }

    case TypeKeys.GET_DELIVERY_METHODS_FAILED: {
      return {
        ...state,
        deliveryMethods: {
          isLoading: false,
        },
        actions: updateActionStatePhase(
          TypeKeys.GET_DELIVERY_METHODS,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }

    case TypeKeys.DUPLICATE_CONTACTS_CHECK_FAILED: {
      // If the duplicate check api fails to get the response, we won't block the order and let the user proceed to next step.
      // Chances are that the order will fail in sfdc due to duplicate contact, but at-least that can be handled manually in sfdc rather blocking the order.
      return {
        ...state,
        moveToNextStep: true,
        actions: updateActionStatePhase(
          TypeKeys.DUPLICATE_CONTACTS_CHECK,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        duplicateContactCheck: {
          inProgress: false,
        },
      };
    }

    case TypeKeys.DUPLICATE_CONTACTS_CHECK_SUBMIT: {
      return {
        ...state,
        cartItems: action.cartItems,
        moveToNextStep: true,
      };
    }

    case TypeKeys.SUBMIT_ONLINE_ORDER: {
      let contactPersonErrors;
      if (action.contactPerson) {
        contactPersonErrors = getErrorsFromUpdateUserDetails(
          action.contactPerson,
          action.customerType,
          action.contactPersonValidationErrors
        );
      }
      const errors = getErrorsFromCheckout(
        action.approverContact,
        action.personBillingAddress,
        action.personBillingEmail,
        contactPersonErrors
      );
      if (errors) {
        return {
          ...state,
          errors,
        };
      }
      return {
        ...state,
        errors: undefined,
      };
    }

    case TypeKeys.UPDATE_USER_DETAILS: {
      const errors = getErrorsFromUpdateUserDetails(action.payload, action.customerType, action.validationErrors);
      if (errors) {
        return {
          ...state,
          errors,
        };
      }
      return {
        ...state,
        moveToNextStep: true,
      };
    }

    case TypeKeys.UPDATE_USER_DETAILS_FULFILLED: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case TypeKeys.PROCESS_DELIVERY_DETAILS: {
      let errors: CommonError[] | undefined;
      if (action.deliveryDetails.address) {
        errors = getErrorsFromAddress(
          action.deliveryDetails.address,
          action.deliveryDetails.recipientName,
          action.deliveryDetails.recipientPhoneNumber,
          action.deliveryDetails.companyName,
          action.deliveryDetails.addressType,
          action.errors
        );
      }

      if (action.contactPerson) {
        errors = getErrorsFromUpdateUserDetails(action.contactPerson, action.customerType, errors);
      }
      errors = getErrorsFromCheckout(action.approverContact, undefined, undefined, errors);
      if (errors) {
        return {
          ...state,
          errors,
        };
      }

      return {
        ...state,
        deliveryDetails: action.deliveryDetails,
        errors: undefined,
        moveToNextStep: action.moveToNextStep && (!action.isEmployee || action.isOmaLaiteLasku),
      };
    }

    case TypeKeys.PROCESS_BILLING_ACCOUNT: {
      const errors = getErrorsFromUpsertBillingAccount(
        action.newBillingAccount,
        action.newBillingAccountValidationErrors
      );
      if (errors) {
        return {
          ...state,
          errors,
        };
      }
      return {
        ...state,
        billingAccount: action.newBillingAccount,
      };
    }

    case TypeKeys.UPDATE_SELECTED_BA_ID: {
      return {
        ...state,
        selectedBaId: action.selectedBaId,
      };
    }

    case TypeKeys.RESET_MOVE_TO_NEXT_STEP: {
      return {
        ...state,
        moveToNextStep: undefined,
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK_V2_FULFILLED: {
      return {
        ...state,
        onlineOrderId: action.onlineOrderId,
      };
    }

    case TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED: {
      return {
        ...state,
        onlineOrderId: action.response.onlineRequestId,
      };
    }
    case TypeKeys.FORCE_BASKET_ITEMS_TO_STATE: {
      return {
        ...state,
        cartItems: action.items,
      };
    }

    default:
      return state;
  }
}
