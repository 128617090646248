import * as CL from '@design-system/component-library';
import { AccordionsMain, TechnicalInformationBroadBand } from '../../common/utils/accordionUtils.js';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EmployeeAdditionalServicesAccordion } from './EmployeeAdditionalServicesAccordion.js';
import { Loading } from '../Loading/index.js';
import { SimCardDetails } from './EmployeeSubscriptionDetailsVoice.js';
import { Status } from '../Status/Status.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { SubscriptionType } from '../../generated/api/models.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  durationOfContractMsg,
  idNumberMsg,
  internetSubscriptionsMsg,
  mobileBroadbandMsg,
  omaElisaForEmployeeMsg,
  pukCodeMsg,
  statusMsg,
  subscriptionIdMsg,
  subscriptionStatusMsg,
  subscriptionTypeMsg,
  t,
  technicalDataMsg,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { getCommitmentText } from './eppSubscriptionUtils.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { getSubscriptionStatusFieldValue } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { parseMobileSubscriptionAttrs } from './employeeSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { EmployeeSubscription, SubscriptionStatus } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription, SubscriptionAction } from '../../generated/api/models.js';
import type { SubscriptionId } from '../../common/constants/pathInterfaces.js';

import './EmployeeSubscriptionDetails.scss';

interface BroadbandSubscriptionDetailsContentProps {
  subscription: Subscription;
  subscriptionStatus: SubscriptionStatus;
}

interface DetailsWrapperContentProps {
  subscription: EmployeeSubscription;
  subscriptionActions?: SubscriptionAction[];
  isMobileBroadband: boolean;
}

const isMobileBroadband = (subscription?: Subscription) =>
  subscription?.subscriptionType === SubscriptionType.MOBILE_BROADBAND;

const BroadbandSubscriptionDetailsContent = ({
  subscription,
  subscriptionStatus,
}: BroadbandSubscriptionDetailsContentProps) => (
  <CL.Description
    className="of-employee-subscription-details__description"
    items={[
      {
        title: t.LROP(subscriptionStatusMsg),
        description: (
          <Status
            color={subscriptionStatus.color}
            textAfterBadge={getSubscriptionStatusFieldValue(subscriptionStatus)}
          />
        ),
      },
      {
        title: t.YTH3(idNumberMsg),
        description: subscription.subscriptionDisplayId,
      },
      {
        title: t.CAA9(durationOfContractMsg),
        description: getCommitmentText(subscription?.details || {}),
      },
      {
        title: t.QH8P(subscriptionTypeMsg),
        description: subscription.subscriptionName,
      },
    ]}
  />
);

interface MobileBroadbandSubscriptionDetailsContentProps {
  subscription: EmployeeSubscription;
  subscriptionStatus: SubscriptionStatus;
}

const MobileBroadbandSubscriptionDetailsContent = ({
  subscription,
  subscriptionStatus,
}: MobileBroadbandSubscriptionDetailsContentProps) => (
  <CL.Description
    className="of-employee-subscription-details__description"
    items={[
      {
        title: t.ASQT(statusMsg),
        description: <Status color={subscriptionStatus.color} textAfterBadge={subscriptionStatus.text} />,
      },
      {
        title: t.K5TL(pukCodeMsg),
        description: parseMobileSubscriptionAttrs(subscription).puk1,
      },
      {
        title: t.YTH3(subscriptionIdMsg),
        description: subscription.subscriptionDisplayId,
      },
      {
        title: t.CAA9(durationOfContractMsg),
        description: getCommitmentText(subscription?.details || {}),
      },
      {
        title: t.QH8P(subscriptionTypeMsg),
        description: subscription.subscriptionName,
      },
    ]}
  />
);

const DetailsWrapperContent = ({ subscription, subscriptionActions }: DetailsWrapperContentProps) => {
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions);
  return (
    <div>
      {isMobileBroadband(subscription) ? (
        <MobileBroadbandSubscriptionDetailsContent
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
        />
      ) : (
        <BroadbandSubscriptionDetailsContent subscription={subscription} subscriptionStatus={subscriptionStatus} />
      )}
      <ButtonGroup
        buttonTypes={[
          isMobileBroadband(subscription)
            ? SubscriptionDetailsButtonType.MOBILE_FIXER
            : SubscriptionDetailsButtonType.BROADBAND_FIXER,
        ]}
        portal="EOE"
        subscription={subscription}
      />
      {isMobileBroadband(subscription) && (
        <>
          <SimCardDetails
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            onClickSimActivation={() => {}}
            onClickOrderSim={() => {}}
            actions={false}
          />
          <EmployeeAdditionalServicesAccordion subscription={subscription} category={SubscriptionCategory.BROADBAND} />
        </>
      )}
      {!isMobileBroadband(subscription) && (
        <AccordionsMain
          accordions={[
            {
              headerName: t.L2MX(technicalDataMsg),
              id: 'technical-information',
              includedData: subscription?.details?.broadband ? (
                TechnicalInformationBroadBand(subscription?.details?.broadband)
              ) : (
                <></>
              ),
              displayed: true,
            },
          ]}
        />
      )}
    </div>
  );
};

export const EmployeeSubscriptionDetailsBroadband = ({ subscriptionId }: SubscriptionId) => {
  const bbSubs = useSelector((state: State) => state.user?.authenticated?.subscriptions?.broadband, deepEqual);
  const subscriptionActions = useSelector(
    (state: State) => state.selfservice?.pendingSubscriptionActions?.items || [],
    deepEqual
  );
  const imagesBaseUrl = useSelector((state: State) => state.config?.imagesBaseUrl);
  const subscription = bbSubs?.find(s => s.subscriptionId === subscriptionId);
  const subscriptionDetailPath =
    subscription?.subscriptionType === SubscriptionType.MOBILE_BROADBAND
      ? `${t.EQKJ(mobileBroadbandMsg)} ${subscription?.details?.mobile?.mobileNumber}`
      : subscription?.subscriptionDisplayId;
  const getBreadCrumbPaths = () => [
    t.H6JV(omaElisaForEmployeeMsg),
    t.SEYV(internetSubscriptionsMsg),
    subscription && subscriptionDetailPath ? subscriptionDetailPath : '...',
  ];

  const subscriptionContactName = bbSubs?.find(s => s.subscriptionId === subscriptionId)?.subscriptionContactName;

  // We cannot use breadcrumb path as page title for the MOBILEBROADBAND, as it contains PII (mobileNumber)
  useTitle(
    subscription?.subscriptionType === SubscriptionType.MOBILE_BROADBAND
      ? [`${t.EQKJ(mobileBroadbandMsg)} ${subscription?.subscriptionDisplayId}`]
      : getBreadCrumbPaths()
  );

  return (
    <DetailsWrapper
      classes={['of-employee-subscription-details']}
      detailsTop={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
            { name: t.SEYV(internetSubscriptionsMsg), path: paths.EMPLOYEE_SUBSCRIPTIONS },
            { name: subscription && subscriptionDetailPath ? subscriptionDetailPath : '...' },
          ]}
        />
      }
      id="employee-subscription"
      headingTop={isMobileBroadband(subscription) ? t.EQKJ(mobileBroadbandMsg) : t.VI0P('Broadband')}
      heading={
        (isMobileBroadband(subscription) && subscriptionContactName) ||
        subscription?.subscriptionUserFriendlyId ||
        subscription?.subscriptionPurposeOfUse ||
        ''
      }
      headingBottom={
        isMobileBroadband(subscription) ? formatPhoneNumber(subscription?.details?.mobile?.mobileNumber || '') : ''
      }
      heroPicto={isMobileBroadband(subscription) ? 'mobilebb' : 'fixedbb'}
      imagesBaseUrl={imagesBaseUrl}
    >
      {subscription ? (
        <DetailsWrapperContent
          subscription={subscription}
          subscriptionActions={subscriptionActions}
          isMobileBroadband={isMobileBroadband(subscription)}
        />
      ) : (
        <Loading />
      )}
    </DetailsWrapper>
  );
};
