import * as CL from '@design-system/component-library';
import { CompanyAndContactDetails, CustomFormErrorAndButton } from '../CustomFormFields.js';
import { CustomFormDomainSearch } from '../partials/CustomFormDomainSearch.js';
import { PriceSummary } from '../partials/PriceSummary.js';
import type { CustomFormProps } from '../CustomForm.js';
import type { Price } from '../../../generated/api/models.js';

const price: Price = {
  monthlyRecurringCharge: 500,
};

export const DomainNameForm = (props: CustomFormProps) => {
  const { leadFailed, leadInProgress, title, enableCompanyFields = true } = props;
  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow>
        <CL.GridCol className="title" colWidthS={6} colWidthM={6} colWidthL={12} colWidthXL={12}>
          <h2>{title}</h2>
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CustomFormDomainSearch price={price} />
          <PriceSummary {...props} />
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CompanyAndContactDetails enableCompanyFields={enableCompanyFields} />
          <CustomFormErrorAndButton leadFailed={leadFailed} leadInProgress={leadInProgress} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
