import { BillingAccountDetails } from '../../../../../components/BillingAccountDetails/BillingAccountDetails.js';
import { InvoiceSystemError } from '../../../../../components/Invoices/Invoices';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import type { BillingAccount } from '../../../../../generated/api/billingAccount';
import type { BillingAccountLoaderResponse } from '../../../../../common/loaders.js';

export const BillingAccountPath = () => {
  const billingAccount = useRouteLoaderData('baRoot') as BillingAccount;
  const { billChannels, contacts } = useLoaderData() as BillingAccountLoaderResponse;
  return (
    <InvoiceSystemError>
      <BillingAccountDetails billingAccount={billingAccount} billChannels={billChannels} contacts={contacts} />
    </InvoiceSystemError>
  );
};
