import { OpenFormAnswers } from '../OpenFormAnswers.js';
import { getValue } from '../OpenFormUtils.js';
import { loadingFormMsg, t } from '../../../common/i18n/index.js';
import { setValueOptions } from './useOpenFormMethods.js';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { FormId } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormResponse } from '../../../generated/api/openFormResponse.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export class OpenForm {
  constructor(private readonly response: OpenFormResponse) {}

  get formId(): string {
    return this.response.formId;
  }

  get name(): string {
    return this.response.name;
  }

  get questions(): OpenFormQuestion[] {
    return this.response.sections.flatMap(s => s.questions);
  }

  get sections(): OpenFormSection[] {
    return this.response.sections;
  }

  linkedValues(q: OpenFormQuestion, value: string | string[] | undefined): string[] {
    return value?.length ? q.choices.flatMap(c => (value.includes(c.guid) && c.linkedValue) || []) : [];
  }
}

export const useOpenForm = ({ formId }: FormId) => {
  const [data, setData] = useState<OpenForm | undefined>(undefined);
  const { setValue, trigger } = useFormContext();
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = async
      .fetchOpenForm({ formId })
      .settled(response => {
        const form = new OpenForm(response);
        const answers = new OpenFormAnswers(Object.entries(async.storage.getItem(formId)?.answers ?? {}));
        answers.forEachOf('choices', (guid, choices) => setValue(guid, getValue(choices), setValueOptions));
        trigger()
          .then(() => setData(form))
          .then(() => async.actions.form({ answers, form }));
      })
      .failed(text => async.actions.notification({ text, type: 'error' }))
      .cache(formId)
      .execute(t.DR5T(loadingFormMsg));

    return () => {
      ctrl.abort();
      async.cache.clear();
      async.actions.form({ answers: undefined, form: undefined });
    };
  }, [formId, setValue, trigger, async]);

  return data;
};
