import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EmployeeAdditionalServicesAccordion } from './EmployeeAdditionalServicesAccordion.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { ProgressBar } from '../ProgressBar/ProgressBar.js';
import { SimCard } from '../SimCard/SimCard.js';
import { Status } from '../Status/Status.js';
import { SubscriptionAction, SubscriptionType } from '../../generated/api/models.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { SubscriptionUsageTabs } from '../SubscriptionUsageDetails/SubscriptionUsageTabs.js';
import {
  callDetailsPeriodMsg,
  minutesMsg,
  omaElisaForEmployeeMsg,
  pcsMsg,
  pukCodeMsg,
  simCardMsg,
  statusMsg,
  subscriptionIdMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getSubscriptionLocalUsage, getSubscriptionRoamingUsage } from '../../selfservice/actions/index.js';
import {
  getSubscriptionStatus,
  hasEsimQrCode,
  isExternalSubscription,
  simCardChangeAllowed,
} from '../../common/utils/subscriptionUtils.js';
import { parseMobileSubscriptionAttrs } from './employeeSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { ActionsHistory, State } from '../../selfservice/common/store.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { EmployeeSubscription, SubscriptionStatus } from '../../common/types/subscription.js';
import type { LocalUsageResponse, UsageInfo } from '../../generated/api/models.js';
import type { SubscriptionId } from '../../common/constants/pathInterfaces.js';

import './EmployeeSubscriptionDetails.scss';

interface VoiceSubscriptionDetailsContentProps {
  subscription: EmployeeSubscription;
  subscriptionStatus: SubscriptionStatus;
}

interface DetailsWrapperContentProps {
  subscriptionId: string;
  subscription: EmployeeSubscription;
  subscriptionActions?: SubscriptionAction[];
  user?: (AuthenticatedUserState & ActionsHistory) | null;
}

interface SubscriptionUsageDetailsProps {
  subscriptionId: string;
  subscription: EmployeeSubscription;
}

interface SimCardDetailsProps {
  subscription: EmployeeSubscription;
  subscriptionStatus: SubscriptionStatus;
  onClickSimActivation: (subscriptionId: string) => void;
  onClickOrderSim: (subscriptionId: string) => void;
  actions?: boolean;
}

const DetailsContent = ({ subscription, subscriptionStatus }: VoiceSubscriptionDetailsContentProps) => (
  <CL.Description
    className="of-employee-subscription-details__description"
    items={[
      {
        title: t.ASQT(statusMsg),
        description: <Status color={subscriptionStatus.color} textAfterBadge={subscriptionStatus.text} />,
      },
      {
        title: t.K5TL(pukCodeMsg),
        description: parseMobileSubscriptionAttrs(subscription).puk1,
      },
      {
        title: t.WHH4('PUK2'),
        description: parseMobileSubscriptionAttrs(subscription).puk2,
      },
      {
        title: t.REA8(subscriptionIdMsg),
        description: subscription.subscriptionDisplayId,
      },
    ]}
  />
);

export const SimCardDetails = ({
  subscription,
  subscriptionStatus,
  onClickSimActivation,
  onClickOrderSim,
  actions = true,
}: SimCardDetailsProps) => {
  // This is called for all "voice" subs, including non-mobile, which don't have a SIM.
  const mobileDetails = subscription.details?.mobile;
  const pendingSimChanges =
    subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM;
  return (
    <LinkableAccordion heading={t.PIZC(simCardMsg)} headingLevel="h3" id="sim-card-change">
      <SimCard
        pendingSimChanges={pendingSimChanges}
        pendingSubscriptionChanges={subscriptionStatus.pendingActions}
        simCardConfig={{ simType: mobileDetails!.simType, simCardNumber: mobileDetails?.simCardNumber }}
        sourceIsExternal={isExternalSubscription(subscription)}
        onClickSimActivation={() => onClickSimActivation(subscription.subscriptionId)}
        onClickOrderSim={() => onClickOrderSim(subscription.subscriptionId)}
        actions={actions}
        simCardChangeAllowed={simCardChangeAllowed(subscription)}
        hasEsimQrCode={hasEsimQrCode(subscription)}
      />
    </LinkableAccordion>
  );
};

export const SubscriptionRoamingUsageDetails = ({ subscriptionId, subscription }: SubscriptionUsageDetailsProps) => {
  const { roamingUsage } = subscription;
  const [showMonthlySpecification, setShowMonthlySpecification] = useState(false);
  const dispatch = useDispatch();

  return (
    <LinkableAccordion
      defaultOpen={false}
      onOpen={() => dispatch(getSubscriptionRoamingUsage(subscriptionId))}
      heading={t.H0K6('Roaming usage')}
      headingLevel="h3"
      id="subscription-roaming-usage"
    >
      <div>
        {roamingUsage ? (
          <CL.Grid className="usage-info-grid">
            <CL.GridRow gutter={false}>
              {(roamingUsage.datavartija.quota !== 0 ||
                roamingUsage.euFup.quota !== 0 ||
                roamingUsage.reissunetti.quota !== 0) && (
                <CL.GridCol
                  colsXS={2}
                  colsS={3}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className="subscription-roaming-usage-bill-period"
                >
                  <h4 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_2} `}>
                    <div className={dsClass.DISPLAY_FLEX}>
                      <span className={`${dsClass.MARGIN_RIGHT_2}`}>{t.OWG1(callDetailsPeriodMsg)}</span>
                      <CL.Tooltip
                        triggerElement={<CL.Icon icon="information" />}
                        placement="right"
                        i18n_tooltip_contentText={t.PB3G('Subscription usage for an open billing period')}
                      />
                    </div>
                  </h4>
                  <div>{`${roamingUsage.billPeriod}`}</div>
                </CL.GridCol>
              )}
              <CL.GridCol colsXS={2} colsS={3} colsM={3} colsL={6} colsXL={6} />
              {roamingUsage.euFup.quota !== 0 && (
                <CL.GridCol
                  colsXS={2}
                  colsS={3}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className="subscription-roaming-usage-euFup"
                >
                  <h4 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_2}`}>{t.IJNQ('EU and EEA countries')}</h4>
                  <div>
                    {t.SCQO('Data usage limit')}
                    {` ${roamingUsage.euFup.quota} `}
                    {t.C4YQ('GB')}
                  </div>
                  <div>
                    {`${roamingUsage.euFup.usage} `}
                    {t.C4YQ('GB') + ` `}
                    {t.Y028('used')}
                  </div>
                </CL.GridCol>
              )}

              {roamingUsage.datavartija.quota !== 0 && (
                <CL.GridCol
                  colsXS={4}
                  colsS={3}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className="subscription-roaming-usage-datavartija"
                >
                  <h4 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_2}`}>Roaming Datavartija</h4>
                  <div>
                    {t.SCQO('Data usage limit')}
                    {` ${roamingUsage.datavartija.quota} €`}
                  </div>
                  <div>
                    {`${roamingUsage.datavartija.usage} € `}
                    {t.Y028('used')}
                  </div>
                </CL.GridCol>
              )}

              {roamingUsage.reissunetti.quota !== 0 && (
                <CL.GridCol
                  colsXS={2}
                  colsS={3}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className="subscription-roaming-usage-reissunetti"
                >
                  <h4 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_2}`}>Reissunetti</h4>
                  <div>
                    {t.SCQO('Data usage limit')}
                    {` ${roamingUsage.reissunetti.quota} `}
                    {t.C4YQ('GB')}
                  </div>
                  <div>
                    {`${roamingUsage.reissunetti.usage} `}
                    {t.C4YQ('GB') + ` `}
                    {t.Y028('used')}
                  </div>
                </CL.GridCol>
              )}
              {roamingUsage.datavartija.quota === 0 &&
                roamingUsage.euFup.quota === 0 &&
                roamingUsage.reissunetti.quota === 0 && (
                  <CL.GridCol
                    colsXS={2}
                    colsS={3}
                    colsM={6}
                    colsL={12}
                    colsXL={12}
                    className="subscription-roaming-usage-no-usage"
                  >
                    <div>
                      <div>{t.NH45('No roaming usage found!')}</div>
                    </div>
                  </CL.GridCol>
                )}
            </CL.GridRow>
            <CL.GridRow className={dsClass.MARGIN_TOP_4}>
              {showMonthlySpecification && <SubscriptionUsageTabs subscriptionId={subscriptionId} isRoaming />}
              <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12} className="roaming-usage-more-details">
                <CL.Button color="light" onClick={() => setShowMonthlySpecification(!showMonthlySpecification)}>
                  {showMonthlySpecification ? t.DXK6('Hide monthly call details') : t.G5EQ('Show monthly call details')}
                </CL.Button>
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
        ) : (
          <Loading />
        )}
      </div>
    </LinkableAccordion>
  );
};

const getLocalData = (localUsage: LocalUsageResponse) => {
  const { quota, usage } = localUsage.data;
  const dataUnit = (quota !== 0 ? quota / 1024 : usage / 1024) / 1024 > 1 ? 'Gt' : 'Mt';
  const roundedQuota = dataUnit === 'Gt' ? Math.round((quota / 1024 / 1024) * 100) / 100 : Math.round(quota / 1024);
  const roundedUsage = dataUnit === 'Gt' ? Math.round((usage / 1024 / 1024) * 100) / 100 : Math.round(usage / 1024);
  return { quota: roundedQuota, usage: roundedUsage, dataUnit };
};

const isUsageAndQuotaZero = (item: UsageInfo) => item.quota === 0 && item.usage === 0;

const SubscriptionLocalUsageDetails = ({ subscriptionId, subscription }: SubscriptionUsageDetailsProps) => {
  const dispatch = useDispatch();
  const [showMonthlySpecification, setShowMonthlySpecification] = useState(false);
  const { localUsage } = subscription;

  return (
    <LinkableAccordion
      defaultOpen={false}
      heading={t.O948('Domestic usage')}
      headingLevel="h3"
      onOpen={() => dispatch(getSubscriptionLocalUsage(subscriptionId))}
      id="subscription-local-usage"
    >
      <div>
        {localUsage ? (
          <CL.Grid className="usage-info-grid">
            <CL.GridRow>
              {(!isUsageAndQuotaZero(localUsage.data) ||
                !isUsageAndQuotaZero(localUsage.text) ||
                !isUsageAndQuotaZero(localUsage.voice)) && (
                <CL.GridCol
                  colsXS={4}
                  colsS={6}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className={`subscription-local-usage-bill-period ${dsClass.MARGIN_BOTTOM_4}`}
                >
                  <h4 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_2}`}>
                    <div className={dsClass.DISPLAY_FLEX}>
                      <span className={`${dsClass.MARGIN_RIGHT_2}`}>{t.OWG1(callDetailsPeriodMsg)}</span>
                      <CL.Tooltip
                        triggerElement={<CL.Icon icon="information" />}
                        placement="right"
                        i18n_tooltip_contentText={t.PB3G('Subscription usage for an open billing period')}
                      />
                    </div>
                  </h4>
                  <div>{`${localUsage.billPeriod}`}</div>
                </CL.GridCol>
              )}

              {isUsageAndQuotaZero(localUsage.data) &&
                isUsageAndQuotaZero(localUsage.text) &&
                isUsageAndQuotaZero(localUsage.voice) && (
                  <CL.GridCol
                    colsXS={4}
                    colsS={6}
                    colsM={3}
                    colsL={6}
                    colsXL={6}
                    className="subscription-local-usage-no-usage"
                  >
                    <div>
                      <div>{t.DVM1('No local use found!')}</div>
                    </div>
                  </CL.GridCol>
                )}

              <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6} />

              {localUsage.voice.quota === 0 ? (
                localUsage.voice.usage !== 0 && (
                  <CL.GridCol
                    colsXS={4}
                    colsS={6}
                    colsM={3}
                    colsL={6}
                    colsXL={6}
                    className="subscription-local-usage-calls"
                  >
                    <span>
                      <strong>{t.ORN1('Phone calls')}</strong>
                    </span>
                    <div>
                      {`${localUsage.voice.usage} `}
                      {t.NIHS(minutesMsg)}
                    </div>
                  </CL.GridCol>
                )
              ) : (
                <CL.GridCol
                  colsXS={4}
                  colsS={6}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className={`subscription-local-usage-calls-with-quota ${dsClass.MARGIN_BOTTOM_4}`}
                >
                  <span>
                    <strong>{t.ORN1('Phone calls')}</strong>
                  </span>
                  <div>
                    <ProgressBar progressPercentage={(localUsage.voice.usage / localUsage.voice.quota) * 100} />
                    <div>
                      <span>{t.G87R('Used')}</span>
                      <span className="of-employee-subscription-details__usage">
                        <strong
                          className={
                            localUsage.voice.usage / localUsage.voice.quota > 1
                              ? 'of-employee-subscription-details__usage__overused'
                              : ''
                          }
                        >
                          {localUsage.voice.usage}
                        </strong>
                        {`/${localUsage.voice.quota} `}
                        {t.NIHS(minutesMsg)}
                      </span>
                    </div>
                  </div>
                </CL.GridCol>
              )}

              {localUsage.text.quota === 0 ? (
                localUsage.text.usage !== 0 && (
                  <CL.GridCol
                    colsXS={4}
                    colsS={6}
                    colsM={3}
                    colsL={6}
                    colsXL={6}
                    className={`subscription-local-usage-text-messages ${dsClass.MARGIN_BOTTOM_4}`}
                  >
                    <span>
                      <strong>{t.MXR5('Text messages')}</strong>
                    </span>
                    <div>
                      {`${localUsage.text.usage} `}
                      {t.B3MG(pcsMsg)}
                    </div>
                  </CL.GridCol>
                )
              ) : (
                <CL.GridCol
                  colsXS={4}
                  colsS={6}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className={`subscription-local-usage-text-messages-with-quota ${dsClass.MARGIN_BOTTOM_4}`}
                >
                  <span>
                    <strong>{t.MXR5('Text messages')}</strong>
                  </span>
                  <div>
                    <ProgressBar progressPercentage={(localUsage.text.usage / localUsage.text.quota) * 100} />
                    <div>
                      <span>{t.G87R('Used')}</span>
                      <span className="of-employee-subscription-details__usage">
                        <strong
                          className={
                            localUsage.text.usage / localUsage.text.quota > 1
                              ? 'of-employee-subscription-details__usage__overused'
                              : ''
                          }
                        >
                          {localUsage.text.usage}
                        </strong>
                        {`/${localUsage.text.quota} `}
                        {t.B3MG(pcsMsg)}
                      </span>
                    </div>
                  </div>
                </CL.GridCol>
              )}

              {localUsage.data.quota === 0 ? (
                localUsage.data.usage !== 0 && (
                  <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6} className={dsClass.MARGIN_BOTTOM_4}>
                    <span>
                      <strong>{t.GX72('Data usage')}</strong>
                    </span>
                    <div>
                      <div>
                        {`${getLocalData(localUsage).usage} `}
                        {getLocalData(localUsage).dataUnit === 'Gt' ? t.C4YQ('GB') : t.PZOF('MB')}
                      </div>
                      <span>{t.HF21('Unlimited in Finland')}</span>{' '}
                    </div>
                  </CL.GridCol>
                )
              ) : (
                <CL.GridCol
                  colsXS={4}
                  colsS={6}
                  colsM={3}
                  colsL={6}
                  colsXL={6}
                  className={`subscription-local-usage-data-with-quota ${dsClass.MARGIN_BOTTOM_4}`}
                >
                  <span>
                    <strong>{t.GX72('Data usage')}</strong>
                  </span>
                  <div>
                    <ProgressBar progressPercentage={(localUsage.data.usage / localUsage.data.quota) * 100} />
                    <div>
                      <span>{t.G87R('Used')}</span>
                      <span className="of-employee-subscription-details__usage">
                        <strong
                          className={
                            localUsage.data.usage / localUsage.data.quota > 1
                              ? 'of-employee-subscription-details__usage__overused'
                              : ''
                          }
                        >
                          {getLocalData(localUsage).usage}
                        </strong>
                        {`/${getLocalData(localUsage).quota} `}
                        {getLocalData(localUsage).dataUnit === 'Gt' ? t.C4YQ('GB') : t.PZOF('MB')}
                      </span>
                    </div>
                  </div>
                </CL.GridCol>
              )}
            </CL.GridRow>
            <CL.GridRow>
              {showMonthlySpecification && <SubscriptionUsageTabs subscriptionId={subscriptionId} />}
              <CL.GridCol colsXS={4} colsS={6} colsM={6} colsL={12} colsXL={12} className="local-usage-more-details">
                <CL.Button color="light" onClick={() => setShowMonthlySpecification(!showMonthlySpecification)}>
                  {showMonthlySpecification ? t.DXK6('Hide monthly call details') : t.G5EQ('Show monthly call details')}
                </CL.Button>
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
        ) : (
          <Loading />
        )}
      </div>
    </LinkableAccordion>
  );
};

const DetailsWrapperContent = ({ subscriptionId, subscription, subscriptionActions }: DetailsWrapperContentProps) => {
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions);
  const navigate = useNavigate();
  return (
    <div>
      <DetailsContent subscription={subscription} subscriptionStatus={subscriptionStatus} />
      <ButtonGroup
        buttonTypes={[SubscriptionDetailsButtonType.MOBILE_FIXER]}
        portal="EOE"
        subscription={subscription}
      />
      {subscription.subscriptionType === SubscriptionType.MOBILE && (
        <SimCardDetails
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onClickSimActivation={() => {
            navigate(generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION_ACTIVATE_SIM, { subscriptionId }));
          }}
          onClickOrderSim={() => {
            navigate(generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION_ORDER_SIM, { subscriptionId }));
          }}
        />
      )}
      <SubscriptionRoamingUsageDetails subscriptionId={subscriptionId} subscription={subscription} />
      <SubscriptionLocalUsageDetails subscriptionId={subscriptionId} subscription={subscription} />
      <EmployeeAdditionalServicesAccordion subscription={subscription} category={SubscriptionCategory.VOICE} />
    </div>
  );
};

export const EmployeeSubscriptionDetailsVoice = ({ subscriptionId }: SubscriptionId) => {
  const { authenticatedUser } = useAuth();
  const voiceSubs = useSelector((state: State) => state.user?.authenticated?.subscriptions?.voice, deepEqual);
  const subscriptionActions = useSelector(
    (state: State) => state.selfservice?.pendingSubscriptionActions?.items || [],
    deepEqual
  );
  const imagesBaseUrl = useSelector((state: State) => state.config?.imagesBaseUrl);
  const subscription = voiceSubs?.find(s => s.subscriptionId === subscriptionId);

  const currentPathName = subscription ? parseMobileSubscriptionAttrs(subscription).mobileNumber : '...';

  // We cannot use breadcrumb path as page title as it contains PII (mobileNumber)
  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.AR0B(voiceSubscriptionsMsg),
    subscription?.subscriptionDisplayId || subscription?.subscriptionId || '',
  ]);

  return (
    <DetailsWrapper
      classes={['of-employee-subscription-details']}
      detailsTop={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
            { name: t.AR0B(voiceSubscriptionsMsg), path: paths.EMPLOYEE_SUBSCRIPTIONS },
            { name: currentPathName },
          ]}
        />
      }
      id="employee-subscription"
      headingTop={subscription?.subscriptionName ?? ''}
      heading={authenticatedUser ? `${authenticatedUser.firstName} ${authenticatedUser.lastName}` : ''}
      headingBottom={t.AR0B(voiceSubscriptionsMsg)}
      heroPicto="sim"
      imagesBaseUrl={imagesBaseUrl}
    >
      {subscription ? (
        <DetailsWrapperContent
          subscriptionId={subscriptionId}
          subscription={subscription}
          subscriptionActions={subscriptionActions}
        />
      ) : (
        <Loading />
      )}
    </DetailsWrapper>
  );
};
