import { DialogType } from '../../common/enums';
import { FormProvider, useForm } from 'react-hook-form';
import { PbxDetailsEdit } from './PbxDetailsEdit';
import { PbxDetailsView } from './PbxDetailsView';
import { SubscriptionStatusType } from '../../generated/api/subscriptionStatusType';
import { changeSubscriptionMobilePbx } from '../../common/fetch';
import { createPbxWorkingHoursConfig, formatPayload, workingHourErrors } from './PbxAccordionUtils';
import { generatePath, useNavigate } from 'react-router-dom';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils';
import { paths } from '../../common/constants/pathVariables';
import { showDialog, startNotification } from '../../selfservice/actions';
import { somethingWentWrongMsg, startTimeCantBeGreaterMsg, t } from '../../common/i18n';
import { useDispatch } from 'react-redux';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import { useState } from 'react';
import type { DialogParams } from '../../common/types/dialog';
import type { PbxSettingFormData } from './PbxAccordionUtils';
import type { Subscription } from '../../generated/api/subscription';
import type { SubscriptionAction } from '../../generated/api/subscriptionAction';
import type { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration';

import './PbxAccordionContent.scss';

interface PbxAccordionContentAttrs {
  pendingSubscriptionActions: SubscriptionAction[];
  pbxSolution: Subscription;
  subscription: Subscription;
}

const createFormData = (subscriptionPbxConfiguration: SubscriptionPbxConfiguration): PbxSettingFormData => ({
  corporateNumber: subscriptionPbxConfiguration.corporateNumber,
  ...createPbxWorkingHoursConfig(subscriptionPbxConfiguration),
  connectToNumber: subscriptionPbxConfiguration.connectToNumber,
  corporateNumberRangeId: subscriptionPbxConfiguration.corporateNumberRangeId,
  extensionNumber: subscriptionPbxConfiguration.extensionNumber,
  delayForChainCalls: subscriptionPbxConfiguration.delayForChainCalls
    ? subscriptionPbxConfiguration.delayForChainCalls.toString()
    : undefined,
  busyAction: subscriptionPbxConfiguration.busyAction,
  pbxSolutionId: subscriptionPbxConfiguration.pbxSolutionId,
  extensionRangeId: subscriptionPbxConfiguration.extensionRangeId,
  corporateRangeId: subscriptionPbxConfiguration.corporateNumberRangeId,
  pbxType: subscriptionPbxConfiguration.pbxConfigurationDetails.pbxType,
});

export const PbxAccordionContent = ({
  pbxSolution,
  pendingSubscriptionActions,
  subscription,
}: PbxAccordionContentAttrs) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();

  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  // When we have pbxConfiguration, checked in MobileSubscriptionAccordions, this always exists
  const subscriptionPbxConfiguration = subscription.details!.mobile!.pbxConfiguration!;

  const formValues = createFormData(subscriptionPbxConfiguration);
  const methods = useForm({
    defaultValues: formValues,
    mode: 'onSubmit',
  });

  const onEdit = () => {
    if (subscription?.subscriptionStatus === SubscriptionStatusType.IN_ACTIVATION) {
      onShowDialog({ type: DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION });
      return;
    }
    if (subscriptionStatus.pendingActions) {
      onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
      return;
    }
    setIsEditing(true);
  };

  const onConfirm = async (values: PbxSettingFormData) => {
    const weekdayErrors = workingHourErrors(values?.workingHoursWeekdayStart, values.workingHoursWeekdayEnd);
    const weekEndErrors = workingHourErrors(values?.workingHoursWeekendStart, values.workingHoursWeekendEnd);
    if (weekdayErrors || weekEndErrors) {
      if (weekdayErrors) {
        methods.setError(`workingHoursWeekdayEnd`, { message: t.E9GS(startTimeCantBeGreaterMsg) });
      }
      if (weekEndErrors) {
        methods.setError(`workingHoursWeekendEnd`, { message: t.E9GS(startTimeCantBeGreaterMsg) });
      }
      return;
    }

    setIsSaving(true);

    try {
      const payload = formatPayload(values, subscriptionPbxConfiguration);
      await changeSubscriptionMobilePbx(payload, subscription.subscriptionId, mdmId);
      setIsSaving(false);
      setIsEditing(false);
      dispatch(startNotification(t.T29V('Change order successful'), 'success'));
      navigate(
        `${generatePath(paths.PS_MOBILE_SUBSCRIPTION, {
          subscriptionId: subscription?.subscriptionDisplayId,
        })}${location.search}`
      );
    } catch (error) {
      dispatch(startNotification(t.JDTO(somethingWentWrongMsg), 'error'));
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  return isEditing ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onConfirm)} noValidate>
        <PbxDetailsEdit pbxSolution={pbxSolution} setEditing={setIsEditing} isSaving={isSaving} />
      </form>
    </FormProvider>
  ) : (
    <PbxDetailsView formValues={formValues} onEdit={onEdit} />
  );
};
