const SELF_SERVICE_HOME = '/omaelisa';
const EMPLOYEE_HOME = '/työntekijä';
const PUNCHOUT_HOME = '/punchout';
const DEVICE_CHECKOUT = '/kassa';
export const EMPLOYEE_LOGOUT_PARAM = '?logout';

// Please follow the Alphabetical ordering
const pathVars = {
  // A
  ANONYMOUS_FIXED_BROADBAND_ORDER: '/netin-saatavuus',
  ANONYMOUS_FIXED_BROADBAND_ORDER_OFFERS: '/netin-saatavuus/valitse-nettiyhteys',
  ANONYMOUS_FIXED_BROADBAND_ORDER_CHECKOUT: '/netin-saatavuus/tilaa-nettiyhteys',
  ANONYMOUS_FIXED_BROADBAND_ORDER_CONFIRMATION: '/netin-saatavuus/kiitos-tilauksesta',
  ANONYMOUS_MOBILE_BROADBAND: '/mobiililaajakaista',
  ANONYMOUS_MOBILE_VOICE: '/yritysliittymat',
  AUTHENTICATION_EXT: '/tunnistaudu',
  AUTHENTICATION_EXT_RETURN: '/tunnistaudu/paluu',
  AUTHORIZED_NUMBER_PORTING: '/tunnistaudu/vahvistettu-numeronsiirto/:token',
  AUTHORIZE_NUMBER_PORTING: '/tunnistaudu/vahvista-numeronsiirto/:token',
  AUXILIARY_ESIM_ORDER_WEBSHEET: `${SELF_SERVICE_HOME}/multisim`,
  // B
  BULLETINS: 'https://corporate.elisa.fi/elisa-oyj/tiedotteet/tiedote/?tag=all',
  BUSINESS_SECURITY: '/yritystietoturva',
  BILLING_ACCOUNTS: `${SELF_SERVICE_HOME}/laskutussopimukset`,
  BILLING_ACCOUNTS_CREATE_NEW_BA: `${SELF_SERVICE_HOME}/laskutussopimukset/luo-uusi-laskutussopimus`,
  BILLING_ACCOUNT: `${SELF_SERVICE_HOME}/laskutussopimukset/:billingAccountId`,
  BILLING_ACCOUNT_SUBSCRIPTIONS: `${SELF_SERVICE_HOME}/laskutussopimukset/:billingAccountId/tuotteet`,
  // C
  CORPORATE_SERVICES: '/yritys-palvelut',
  COMPANY_INFO_CATALOGS: `${SELF_SERVICE_HOME}/yritystiedot/laitelistat`,
  COMPANY_INFO_CATALOG: `${SELF_SERVICE_HOME}/yritystiedot/laitelistat/:catalogCode`,
  COMPANY_INFO_CATALOG_LIST_NEW: `${SELF_SERVICE_HOME}/yritystiedot/laitelistat/uusi-laitelista`,
  COMPANY_INFO_CATALOG_LIST_EDIT: `${SELF_SERVICE_HOME}/yritystiedot/laitelistat/laitelistan-muokkaus`,
  COMPANY_INFO_CATALOG_LIST_PRODUCTS: `${SELF_SERVICE_HOME}/yritystiedot/laitelistat/laitelistan-tuotteet`,
  COMPANY_INFO_CATALOG_LIST_INFO: `${SELF_SERVICE_HOME}/yritystiedot/laitelistat/laitelistan-tiedot`,
  COMPANY_INFO_CONTACTS: `${SELF_SERVICE_HOME}/yritystiedot/käyttäjät`,
  COMPANY_INFO_CONTACTS_NEW: `${SELF_SERVICE_HOME}/yritystiedot/käyttäjät/lisää-uusi-käyttäjä`,
  COMPANY_INFO_CONTACT: `${SELF_SERVICE_HOME}/yritystiedot/käyttäjät/:contactMasterId`,
  COMPANY_INFO_CONTACT_MOVE: `${SELF_SERVICE_HOME}/yritystiedot/käyttäjät/:contactMasterId/siirrä-käyttäjä`,
  COMPANY_INFO_HOME: `${SELF_SERVICE_HOME}/yritystiedot`,
  COMPANY_INFO_INVITES: `${SELF_SERVICE_HOME}/yritystiedot/kutsut`,
  COMPANY_INFO_MY_ACCOUNT: `${SELF_SERVICE_HOME}/yritystiedot/omat-tiedot`,
  COMPANY_INFO_REPORTS: `${SELF_SERVICE_HOME}/yritystiedot/raportit`,
  COMPANY_INFO_RING_ADMINS: `${SELF_SERVICE_HOME}/yritystiedot/ring-pääkäyttäjät`,
  COMPANY_INFO_RING_ADMIN: `${SELF_SERVICE_HOME}/yritystiedot/ring-pääkäyttäjät/:adminId`,
  // TODO Sebastian 4.5.2022 -> add-new-ring-admin path should not be used. Needs to be discussed with business.
  COMPANY_INFO_RING_ADMIN_NEW_USER: `${SELF_SERVICE_HOME}/yritystiedot/ring-pääkäyttäjät/add-new-ring-admin/:userName`,
  COMPANY_INFO_SETTINGS: `${SELF_SERVICE_HOME}/yritystiedot/asetukset`,
  COMPANY_INFO_MFA_SELF_SERVICE: `${SELF_SERVICE_HOME}/yritystiedot/asetukset/mfa-self-service/`,
  CONTACT: '/ota-yhteytta',
  CONTACT_FORM: '/yhteydenotto',
  CONTACT_GENERAL: '/yhteydenotto_yleinen',
  CONTACT_INFO: '/yhteystiedot',
  CONTACT_NEW_MESSAGE: '/ota-yhteytta/luo-uusi-viesti',
  CONTACT_THANK_YOU: '/ota-yhteytta/kiitos-viestista',
  COVERAGE: 'https://elisa.fi/kuuluvuus/',
  CUSTOMER_ORDER_HOME: `${SELF_SERVICE_HOME}/tilaushistoria`,
  CUSTOMER_ORDERS: `${SELF_SERVICE_HOME}/tilaushistoria/tilaukset`,
  CUSTOMER_ORDER: `${SELF_SERVICE_HOME}/tilaushistoria/tilaukset/:orderId`,
  CUSTOMER_ORDER_PRODUCT: `${SELF_SERVICE_HOME}/tilaushistoria/tilaukset/:orderId/:subscriptionId`,
  CUSTOMER_ORDER_REDEEM_REQUESTS: `${SELF_SERVICE_HOME}/tilaushistoria/lunastuspyynnot`,
  CUSTOMER_ORDER_REDEEM_REQUEST: `${SELF_SERVICE_HOME}/tilaushistoria/lunastuspyynnot/:requestId`,
  CUSTOMER_SERVICE_SOLUTIONS: '/asiakaspalveluratkaisut',

  // D
  DEVICE_CHECKOUT,
  DEVICE_CHECKOUT_CARD_PAYMENT: `${DEVICE_CHECKOUT}/maksu`,
  DEVICE_CHECKOUT_CARD_PAYMENT_IN_PROGRESS: `${DEVICE_CHECKOUT}/maksu/kaynnissa`,
  DEVICE_CHECKOUT_CARD_PAYMENT_COMPLETE: `${DEVICE_CHECKOUT}/maksu/valmis`,
  DEVICE_CHECKOUT_THANK_YOU: `${DEVICE_CHECKOUT}/kiitos`,
  DEVICE_ENROLLMENT: '/mobiililaiterekisteri',

  // E
  ELISA_PRIVACY_POLICY: 'https://elisa.fi/tietosuoja/tietosuojaperiaatteet/',
  ELISA_SIM_CARD_PRODUCT: '/yhdistelma-sim-kortti',
  EMPLOYEE_HOME,
  EMPLOYEE_LOGOUT: `${EMPLOYEE_HOME}/${EMPLOYEE_LOGOUT_PARAM}`,
  // E = Employee email confirmation
  EMPLOYEE_EMAIL_VERIFICATION: `${EMPLOYEE_HOME}/emailvahvistus`,
  EMPLOYEE_EMAIL_VERIFICATION_TOKEN: `${EMPLOYEE_HOME}/emailvahvistus/:token`,
  // E = Employee device and subscription plural pages
  EMPLOYEE_DEVICES: `${EMPLOYEE_HOME}/laitteet`,
  EMPLOYEE_SUBSCRIPTIONS: `${EMPLOYEE_HOME}/liittymat`,
  EMPLOYEE_VOICE_SUBSCRIPTIONS: `${EMPLOYEE_HOME}/puhelinliittymät`,
  EMPLOYEE_BROADBAND_SUBSCRIPTIONS: `${EMPLOYEE_HOME}/nettiliittymät`,
  // E = Employee device and subscription add new pages
  EMPLOYEE_VOICE_SUBSCRIPTION_ADD: `${EMPLOYEE_HOME}/puhelinliittymät/lisää`,
  EMPLOYEE_SUBSCRIPTIONS_VERIFY_ADD: `${EMPLOYEE_HOME}/:categoryUrl/lisää/vahvistuskoodi`,
  EMPLOYEE_BROADBAND_SUBSCRIPTION_ADD: `${EMPLOYEE_HOME}/nettiliittymät/lisää`,
  EMPLOYEE_DEVICE_ADD: `${EMPLOYEE_HOME}/laitteet/lisää`,
  // E = Employee device and subscription special pages
  EMPLOYEE_VOICE_SUBSCRIPTION_ACTIVATE_SIM: `${EMPLOYEE_HOME}/puhelinliittymät/:subscriptionId/aktivoi-sim-kortti`,
  EMPLOYEE_VOICE_SUBSCRIPTION_ORDER_SIM: `${EMPLOYEE_HOME}/puhelinliittymät/:subscriptionId/tilaa-sim`,
  EMPLOYEE_VOICE_SUBSCRIPTION_CALL_DETAILS: `${EMPLOYEE_HOME}/puhelinliittymät/:subscriptionId/puheluntiedot`,
  EMPLOYEE_DEVICE_SUPPORT_REQUEST: `${EMPLOYEE_HOME}/laitteet/:subscriptionId/tee-tukipyynto`,
  // E = Employee device and subscription singular pages
  EMPLOYEE_DEVICE: `${EMPLOYEE_HOME}/laitteet/:subscriptionId`,
  EMPLOYEE_VOICE_SUBSCRIPTION: `${EMPLOYEE_HOME}/puhelinliittymät/:subscriptionId`,
  EMPLOYEE_BROADBAND_SUBSCRIPTION: `${EMPLOYEE_HOME}/nettiliittymät/:subscriptionId`,
  // E = Employee cash register pages
  EMPLOYEE_DEVICE_CHECKOUT: `${EMPLOYEE_HOME}/kassa`,
  EMPLOYEE_DEVICE_CHECKOUT_THANK_YOU: `${EMPLOYEE_HOME}/kassa/kiitos`,
  EMPLOYEE_DEVICE_STORE: `${EMPLOYEE_HOME}/kauppa`,
  EMPLOYEE_DEVICE_STORE_PHONES: `${EMPLOYEE_HOME}/kauppa/puhelimet`,
  EMPLOYEE_DEVICE_STORE_TABLETS: `${EMPLOYEE_HOME}/kauppa/tabletit`,
  EMPLOYEE_DEVICE_STORE_COMPUTERS: `${EMPLOYEE_HOME}/kauppa/tietokoneet`,
  EMPLOYEE_DEVICE_STORE_NETWORK_DEVICES: `${EMPLOYEE_HOME}/kauppa/verkkolaitteet`,
  EMPLOYEE_DEVICE_STORE_ACCESSORIES: `${EMPLOYEE_HOME}/kauppa/lisatarvikkeet`,
  EMPLOYEE_DEVICE_STORE_PHONE: `${EMPLOYEE_HOME}/kauppa/puhelimet/:deviceName`,
  EMPLOYEE_DEVICE_STORE_TABLET: `${EMPLOYEE_HOME}/kauppa/tabletit/:deviceName`,
  EMPLOYEE_DEVICE_STORE_COMPUTER: `${EMPLOYEE_HOME}/kauppa/tietokoneet/:deviceName`,
  EMPLOYEE_DEVICE_STORE_NETWORK_DEVICE: `${EMPLOYEE_HOME}/kauppa/verkkolaitteet/:deviceName`,
  EMPLOYEE_DEVICE_STORE_ACCESSORY: `${EMPLOYEE_HOME}/kauppa/lisatarvikkeet/:deviceName`,
  EMPLOYEE_DEVICE_STORE_PRODUCTS: `${EMPLOYEE_HOME}/kauppa/:category`,
  EMPLOYEE_DEVICE_STORE_PRODUCT: `${EMPLOYEE_HOME}/kauppa/:category/:deviceName`,
  // E = Employee request approvals
  EMPLOYEE_ORDERS: `${EMPLOYEE_HOME}/tilaukset`,
  EMPLOYEE_PENDING_REVIEW_ORDERS: `${EMPLOYEE_HOME}/tilaukset/odottaa`,
  EMPLOYEE_PENDING_REVIEW_ORDER: `${EMPLOYEE_HOME}/tilaukset/odottaa/:subscriptionId`,
  EMPLOYEE_PENDING_REVIEW_ORDER_ITEM: `${EMPLOYEE_HOME}/tilaukset/odottaa/:subscriptionId/:deliveryId`,
  EMPLOYEE_FINISHED_REVIEW_ORDERS: `${EMPLOYEE_HOME}/tilaukset/käsitellyt`,
  EMPLOYEE_FINISHED_REVIEW_ORDER: `${EMPLOYEE_HOME}/tilaukset/käsitellyt/:subscriptionId`,
  EMPLOYEE_FINISHED_REVIEW_ORDER_ITEM: `${EMPLOYEE_HOME}/tilaukset/käsitellyt/:subscriptionId/:deliveryId`,
  EMPLOYEE_CHANGE_REQUEST_ORDERS: `${EMPLOYEE_HOME}/tilaukset/muutospyynnöt`,
  EMPLOYEE_CHANGE_REQUEST_ORDER: `${EMPLOYEE_HOME}/tilaukset/muutospyynnöt/:subscriptionId`,
  // E = Employee own orders
  EMPLOYEE_OWN: `${EMPLOYEE_HOME}/omat`,
  EMPLOYEE_OWN_ORDERS: `${EMPLOYEE_HOME}/omat/tilaukset`,
  EMPLOYEE_OWN_ORDER: `${EMPLOYEE_HOME}/omat/tilaukset/:subscriptionId`,
  EMPLOYEE_OWN_ORDER_ITEM: `${EMPLOYEE_HOME}/omat/tilaukset/:subscriptionId/:deliveryId`,
  EMPLOYEE_OWN_CHANGE_REQUESTS: `${EMPLOYEE_HOME}/omat/muutospyynnöt`,
  EMPLOYEE_OWN_CHANGE_REQUEST: `${EMPLOYEE_HOME}/omat/muutospyynnöt/:subscriptionId`,
  // E = Employee own information
  EMPLOYEE_OWN_INFO: `${EMPLOYEE_HOME}/omat-tiedot`,
  EMPLOYEE_SHOPPING_CART: `${EMPLOYEE_HOME}/ostoskori`,
  EMPLOYEE_OMALASKU: `${EMPLOYEE_HOME}/omalasku`,
  // E = Rest
  ESIM_FOR_COMPANIES: '/esim-yrityksille',

  // F
  FORGOT_PASSWORD: `${SELF_SERVICE_HOME}/palauta-salasana`,
  // I
  INSTRUCTION_CHANGE_SUBSCRIPTION_OWNERSHIP: '/ohje/matkapuhelinliittyman-omistussuhteen-muutos',
  INSTRUCTION_ELISA_MATKAPUHELINVASTAAJA: '/ohje/elisa-matkapuhelinvastaaja',
  INSTRUCTION_ESIM_ACTIVATION: '/ohje/eSIM-kayttoonotto',
  INSTRUCTION_INVOICE_SUM_DIFFERS: '/ohje/miksi-laskuni-loppusumma-on-aiempaa-suurempi',
  INSTRUCTION_INVOICE_SUM_DIFFERS_EN: '/en/instructions/why-is-my-invoice-higher-than-usual',
  INSTRUCTION_PIN_AND_PUK_CODES: '/ohje/pin-ja-puk-koodit',
  INSTRUCTION_SIM_CARD_ORDERING_AND_ACTIVATION: '/ohje/sim-kortin-tilaus-ja-aktivointi',
  INSTRUCTIONS: '/ohjeet',
  INSTRUCTIONS_INVOICES: '/ohjeet/laskut',
  INSTRUCTIONS_INVOICES_EN: '/en/invoices',
  INSTRUCTIONS_MOBILE_BROADBAND: '/ohjeet/mobiililaajakaista',
  INSTRUCTIONS_VOICE_SUBSCRIPTIONS: '/ohjeet/puhelinliittymat',
  INTERNET_FOR_BIG_COMPANY: '/netti-isolle-yritykselle',
  INTERNET_SUBSCRIPTIONS_PUBLIC: '/nettiliittymat',
  INVOICE_DOCUMENTS: `${SELF_SERVICE_HOME}/laskutukseen-liittyvät-kirjeet`,
  INVOICE: `${SELF_SERVICE_HOME}/laskut/:invoiceId`,
  INVOICES: `${SELF_SERVICE_HOME}/laskut`,
  IOT_DEVICES: '/iot-laitteet',
  // L
  LAITENETTI: '/laitenetti',
  LANKANUMERO: '/lankanumero',
  // M
  MICROSOFT_365: '/microsoft-365',
  MOBILE_DEVICES_AS_LIFECYCLE_SERVICE: '/mobiililaitteet-elinkaaripalveluna',
  MY_OFFERS: '/omat-edut',
  // N
  NETTI_LITE: '/netti-pieneen-toimistoon',
  NOT_FOUND_404: '/404',

  // O
  OMA_LASKU: '/oma-lasku-tyosuhdeliittymaan',
  OPENFORM_LIST: '/openform/list/:listId',
  OPENFORM_PREVIEW: '/openform/preview/:formId/:page',
  OPENFORM_VIEW: '/openform/view/:formId/:page',
  OPENFORM_VIEW_COMPLETED: '/openform/view/:formId/completed',
  ORDER_NEW_SIM_CARD: '/yhdistelma-sim-kortti',

  // P
  PUNCHOUT_CHECKOUT: `${PUNCHOUT_HOME}/kassa`,
  PUNCHOUT_SHOPPING_CART: `${PUNCHOUT_HOME}/ostoskori`,
  PUNCHOUT_HOME: PUNCHOUT_HOME,
  PUNCHOUT_STORE: `${PUNCHOUT_HOME}/kauppa`,
  PUNCHOUT_STORE_PRODUCTS: `${PUNCHOUT_HOME}/kauppa/:category`,
  PUNCHOUT_STORE_PRODUCT: `${PUNCHOUT_HOME}/kauppa/:category/:product`,
  PUNCHOUT_DEVICE_STORE_ACCESSORY: `${PUNCHOUT_HOME}/kauppa/lisatarvikkeet/:deviceName`,

  // P = Products & Services
  PS_HOME: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni`,
  // P = Products & Services - Broadband Subscriptions
  PS_BROADBAND_SUBSCRIPTIONS: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät`,
  PS_BROADBAND_SUBSCRIPTION: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/:subscriptionId`,
  PS_BROADBAND_SUBSCRIPTION_NEW: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/uusi-mobiililaajakaista`,
  PS_BROADBAND_SUBSCRIPTION_PRODUCT_INFO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/uusi-mobiililaajakaista/tuotteiden-tiedot`,
  PS_BROADBAND_SUBSCRIPTION_DELIVERY_INFO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/uusi-mobiililaajakaista/toimitustiedot`,
  PS_BROADBAND_SUBSCRIPTION_NEW_M2M: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/uusi-laitenetti`,
  PS_BROADBAND_SUBSCRIPTION_NEW_M2M_PRODUCT_INFO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/uusi-laitenetti/tuotteiden-tiedot`,
  PS_BROADBAND_SUBSCRIPTION_NEW_M2M_DELIVERY_INFO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/uusi-laitenetti/toimitustiedot`,
  PS_BROADBAND_SUBSCRIPTION_ITEM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/:subscriptionId/:addonId`,
  PS_BROADBAND_SUBSCRIPTION_UPDATE: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/:subscriptionId/päivitä-mobiililaajakaista`,
  PS_BROADBAND_SUBSCRIPTION_UPDATE_M2M: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/:subscriptionId/päivitä-laitenetti`,
  PS_BROADBAND_SUBSCRIPTION_ACTIVATE_SIM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/:subscriptionId/aktivoi-sim-kortti`,
  PS_BROADBAND_SUBSCRIPTION_ORDER_SIM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/nettiliittymät/:subscriptionId/tilaa-sim`,
  // P = Products & Services - Devices
  PS_DEVICES: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/laitteet`,
  PS_DEVICE: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/laitteet/:subscriptionId`,
  PS_DEVICE_ITEM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/laitteet/:subscriptionId/:addonId`,
  PS_DEVICE_SUPPORT_REQUEST: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/laitteet/:subscriptionId/tee-tukipyynto`,
  PS_DEVICE_REDEMPTION: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/laitteet/:subscriptionId/laitteen-lunastaminen`,
  // P = Products & Services - Elisa DNS
  PS_ELISA_DNS: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/elisa-dns`,
  PS_ELISA_DNS_DOMAIN: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/elisa-dns/:subscriptionId`,
  PS_ELISA_DNS_DOMAIN_HISTORY: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/elisa-dns/:subscriptionId/muutoshistoria`,
  PS_ELISA_DNS_DOMAIN_HISTORY_ITEM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/elisa-dns/:subscriptionId/muutoshistoria/:historyId`,
  // P = Products & Services - Mobile Subscriptions
  PS_MOBILE_SUBSCRIPTIONS: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät`,
  PS_MOBILE_SUBSCRIPTION: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId`,
  PS_MOBILE_SUBSCRIPTION_NEW: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/uusi-liittymä`,
  PS_MOBILE_SUBSCRIPTION_NEW_PRODUCT_INFO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/uusi-liittymä/tuotteiden-tiedot`,
  PS_MOBILE_SUBSCRIPTION_NEW_DELIVERY_INFO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/uusi-liittymä/toimitustiedot`,
  PS_MOBILE_SUBSCRIPTION_ITEM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId/:addonId`,
  PS_MOBILE_SUBSCRIPTION_UPDATE: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId/päivitä-liittymä`,
  PS_MOBILE_SUBSCRIPTION_UPDATE_CONFIRMATION: 'vahvista',
  PS_MOBILE_SUBSCRIPTION_ACTIVATE_SIM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId/aktivoi-sim-kortti`,
  PS_MOBILE_SUBSCRIPTION_ATTACH_RING: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId/liitä-ring`,
  PS_MOBILE_SUBSCRIPTION_ATTACH_VAKIO: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId/liitä-vakio`,
  PS_MOBILE_SUBSCRIPTION_ORDER_SIM: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/puhelinliittymät/:subscriptionId/tilaa-sim`,
  // P = Products & Services - Services
  PS_SERVICES: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/palvelut`,
  PS_SERVICE: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/palvelut/:subscriptionId`,
  // P = Products & Services - License management
  PS_LICENSES: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/lisenssit`,
  // P = Products & Services - Contracts
  PS_CONTRACTS: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/sopimukset`,
  PS_CONTRACT_MOBILE_ID_NEW: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/sopimukset/uusi-mobiilivarmenne`,
  PS_CONTRACT_MOBILE_ID: `${SELF_SERVICE_HOME}/tuotteeni-ja-palveluni/sopimukset/:contractDisplayId`,
  // P = Rest
  PUHERATKAISU_VAKIO: '/puheratkaisu-vakio',
  // R
  REGISTER_PAGE: '/rekisteroidy',
  REISSUNETTI: '/reissunetti',
  RING_MOBILE_SWITCHBOARD: '/ring-mobiilivaihde',
  ROUTERS: '/reitittimet',
  // S
  SEARCH_PUBLIC_PAGES: '/haku',
  SELF_SERVICE_COMPANY_INFO: '/asioi-yritysten-oma-elisassa',
  SELF_SERVICE_CONTACT_INFO: `${SELF_SERVICE_HOME}/yhteystiedot`,
  SELF_SERVICE_HOME: SELF_SERVICE_HOME,
  SELF_SERVICE_LOGOUT: '/?uoe',
  SHOPPING_BASKET: '/ostoskori',
  STORES: '/myymalat',
  SUBSCRIPTION_ACTIVATION: '/ota-liittyma-kayttoon',
  SUBSCRIPTIONS_PUBLIC: '/liittymat',
  SUPPORT_CASES: `${SELF_SERVICE_HOME}/tuki/tukipyynnöt`,
  SUPPORT_CASE_NEW: `${SELF_SERVICE_HOME}/tuki/tukipyynnöt/luo-uusi`,
  SUPPORT_CASE: `${SELF_SERVICE_HOME}/tuki/tukipyynnöt/:supportCaseDisplayId`,
  SUPPORT_HOME: `${SELF_SERVICE_HOME}/tuki`,
  // T
  TRAINING_ENROLLMENT: '/ilmo/:trainingName',
  // U
  USING_YOUR_PHONE_ABROAD: '/verkkovierailu-ulkomailla',
  // W
  WEB_SHOP: '/kauppa',
  WIFI_CALL_SERVICE: '/wifi-puhelu',
  WILDCARD: '*',
  // Y
  YRITTAJAJASENEN_EDUT: '/yrittajajasenen-edut',
};

export const paths = Object.freeze(pathVars); // This freezing is done as a separate step, otherwise some people's IDE has trouble inspecting.
