import * as CL from '@design-system/component-library';
import { AddOrSelectBillingAccounts } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { CommercialProductType, DuplicateContact, SimType } from '../../generated/api/models.js';
import { DEFAULT_COUNTRY_CODE, isPOBoxAddress } from '../../common/utils/validationUtils.js';
import { DuplicateContactDialog } from '../Dialogs/DuplicateContactDialog';
import { DuplicateContactError } from '../OrderSubscription/DuplicateContactError';
import { FormWrapper } from './FormWrapper.js';
import { Loading } from '../Loading/index.js';
import { Name, PhoneNumber, PostalCode, TextInput } from '../../common/react-hook-form/fields/index.js';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { POBoxWarningModal } from '../Modal/POBoxWarningModal.js';
import { PhaseIndicator } from '../PhaseIndicator/index.js';
import { PhoneNumberType, SelectedPurposeOfUseOrContact, SimCardSelection } from '../../common/enums.js';
import { ScrollToTopWhenSearchParamsChange, scrollTo } from '../../common/utils/browserUtils.js';
import { SupportCaseSendingFailureDialog } from '../Dialogs/SupportCaseSendingFailureDialog';
import { WizardActions } from '../WizardActions/index.js';
import {
  addEmptyFieldValidationError,
  addErrorsFromUpsertAddress,
  convertStringMapToCommonErrors,
  getElementsWithErrors,
} from '../../common/utils/errorUtils.js';
import {
  companyMsg,
  confirmOrderMsg,
  deliveryDetailsMsg,
  deliveryMethodMsg,
  editMsg,
  nameMsg,
  phoneNumberMsg,
  postalCodeMsg,
  productInformationsMsg,
  shippingAddressMsg,
  streetAddressMsg,
  t,
  yourOrderHasBeenTransferredForProcessingMsg,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { generateNumbers, validateNumbers } from '../../common/fetch.js';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils';
import {
  getEmptyBillingAccount,
  getErrorsFromUpsertBillingAccount,
  getReceiverType,
  prepareBillingAccountSave,
} from '../../common/utils/billingAccountUtils.js';
import { getGenericErrorDuplicateContact } from '../OrderSubscription/contactUtils';
import { getTopMostElement } from '../../common/utils/domUtils.js';
import { handleLiikkuvaWifiSupportCase } from '../OrderSubscription/liikkuvaWifiSupportCaseUtils';
import { isDefined } from '../../common/utils/objectUtils.js';
import { isLiikkuvaWifiOffer } from '../../common/utils/subscriptionUtils';
import { paths } from '../../common/constants/pathVariables.js';
import { startNotification } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  AddOn,
  Address,
  BillChannel,
  BillingAccount,
  BillingAccountsResponse,
  CampaignAssociation,
  Contact,
  ContactHeader,
  DeliveryAddress,
  GenericErrorDuplicateContact,
} from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { DeliveryAddressFormValues } from './FormWrapper.js';
import type { OrderSubscriptionStateParams } from '../OrderSubscription/OrderSubscriptionLayout.js';
import type { SelectedBAData } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';

import './OrderDeliveryOptions.scss';

export interface OrderDeliveryOptionsProps {
  contacts?: Contact[] | ContactHeader[];
  companyInfo: CompanyInfoState;
  onlineOrdersErrors?: CommonError[];
  saving: boolean;
  user?: Contact;
  useDuplicateContact?: GenericErrorDuplicateContact;
  orderStateParams: OrderSubscriptionStateParams;
  billingAccounts: BillingAccountsResponse;
  billChannels?: BillChannel[];
  onSubmitOrder: (
    orderItems: ConfiguredOffer[],
    deliveryAddress: DeliveryAddress,
    isCompanyNameRequired?: boolean,
    deliveryAddressValidationErrors?: CommonError[],
    personAccountValidationErrors?: CommonError[],
    billingAccountId?: string,
    newBillingAccount?: BillingAccount,
    newBillingAccountValidationErrors?: CommonError[],
    addOns?: AddOn[],
    setShowPOBoxWarningModal?: (isShown: boolean) => void
  ) => void;
}

interface UnavailableNumbersModalProps {
  onSubmit: () => void;
  onModalClose: () => void;
  notAvailableNumbers: Array<string>;
}

export const getErrorsFromUpsertDeliveryAddress = (
  upsertedDeliveryAddress: Readonly<DeliveryAddress>,
  isCompanyNameRequired?: boolean,
  validationErrors?: Readonly<CommonError>[]
): CommonError[] | undefined => {
  const errors: CommonError[] = [];

  addErrorsFromUpsertAddress(errors, upsertedDeliveryAddress.address, 'address');
  const { companyName, phoneNumber, recipient } = upsertedDeliveryAddress;

  if (isCompanyNameRequired !== false && !companyName?.length) {
    addEmptyFieldValidationError(errors, 'companyName');
  }
  if (!phoneNumber?.length) {
    addEmptyFieldValidationError(errors, 'phoneNumber');
  }
  if (!recipient?.length) {
    addEmptyFieldValidationError(errors, 'recipient');
  }

  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
};

const UnavailableNumbersModal = ({ onSubmit, onModalClose, notAvailableNumbers }: UnavailableNumbersModalProps) => (
  <CL.Modal autoOpen={true} onModalClose={onModalClose}>
    <h3 className={dsClass.MARGIN_BOTTOM_0}>{t.XP04('Phone number is no longer available')}</h3>
    <p>
      {t.IEC8('Unfortunately, the following phone number or numbers are no longer available')}:<br />
      {notAvailableNumbers.map(number => formatPhoneNumber(number, true)).join(', ')}
    </p>
    <p>
      {t.N894(
        'You can still confirm the order, in which case the next available phone number will be assigned to your subscription. If you wish to choose the phone number yourself, please start the order process again.'
      )}
    </p>
    <ButtonGroupForSubmitAndBack
      className={`${dsClass.PADDING_TOP_4} ${dsClass.TEXT_ALIGN_RIGHT}`}
      submitButtonText={t.RZU4(confirmOrderMsg)}
      onSubmit={onSubmit}
      cancelButtonText={t.VQ6O('Start order process again')}
      onCancel={() => {
        window.location.href = `${paths.PS_MOBILE_SUBSCRIPTION_NEW}?mdmId=${getActiveAccountMasterId()}`;
      }}
    />
  </CL.Modal>
);

const getDeliveryMethodText = (hasDeliverables: boolean, isLiikkuvaWifiOrder: boolean): string => {
  if (isLiikkuvaWifiOrder) {
    return t.U6YJ(
      'New battery-powered portable mobile router will be delivered to defined address. You can change the delivery address above.'
    );
  }
  return hasDeliverables
    ? t.FTA1('SIM cards are delivered by post to the address of your choice.')
    : t.R2DK('Nothing to deliver.');
};

export const OrderDeliveryOptions = ({
  contacts,
  companyInfo,
  billingAccounts,
  billChannels,
  onlineOrdersErrors,
  saving,
  user,
  useDuplicateContact,
  orderStateParams,
  onSubmitOrder,
}: OrderDeliveryOptionsProps) => {
  const [editDeliveryAddress, setEditDeliveryAddress] = useState(false);
  const [shouldScrollToError, setShouldScrollToError] = useState(false);
  const [billingAccountSaveValues, setBillingAccountSaveValues] = useState<SelectedBAData>();
  const [billingAccountErrors, setBillingAccountErrors] = useState<CommonError[]>([]);
  const [displayUnavailableNumbersModal, setDisplayUnavailableNumbersModal] = useState(false);
  const [notAvailableNumbers, setNotAvailableNumbers] = useState<Array<string>>([]);
  const [deliveryAddressFormValuesState, setDeliveryAddressFormValuesState] = useState<DeliveryAddressFormValues>();
  const [submitting, setSubmitting] = useState(false);
  const [showPOBoxWarningModal, setShowPOBoxWarningModal] = useState(false);
  const [showSupportCaseSendingError, setShowSupportCaseSendingError] = useState(false);
  const [showDuplicateContactModal, setShowDuplicateContactModal] = useState(false);
  const [duplicateContactId, setDuplicateContactId] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const domRefs = useRef<{ [elementKey: string]: HTMLElement }>({});
  const deliveryAddress: DeliveryAddress = {
    address: companyInfo?.address
      ? {
          ...companyInfo.address,
          line2: undefined,
          countryCode: companyInfo.address.countryCode || DEFAULT_COUNTRY_CODE,
        }
      : ({} as Address),
    companyName: companyInfo?.companyName || '',
    phoneNumber: user?.person?.phoneNumber || '',
    recipient: user?.person ? `${user.person.firstName} ${user.person.lastName}` : '',
  };
  const selectedOffer = orderStateParams.selectedOffer!;
  const commercialProducts: ConfiguredCommercialProduct[] = selectedOffer.selectedCommercialProducts;
  const selectedCampaignAssociation: CampaignAssociation | undefined = selectedOffer.selectedCampaignAssociation;
  const ringProducts: ReadonlyArray<ConfiguredCommercialProduct> = commercialProducts
    .filter(product => product.configuredRingOffer)
    .map(product => product.configuredRingOffer!.selectedCommercialProducts[0]);
  const selectedCommercialProducts: Readonly<ConfiguredCommercialProduct>[] = [...commercialProducts, ...ringProducts];
  const isLiikkuvaWifiOrder = isLiikkuvaWifiOffer(selectedOffer.offer.offerCode);
  const hasDeliverables = selectedOffer.selectedCommercialProducts.some(
    selectedCp =>
      (selectedCp.simCardConfiguration?.simSelection === SimCardSelection.ORDER_NEW &&
        selectedCp.simCardConfiguration?.simType !== SimType.ESIM) ||
      isLiikkuvaWifiOrder
  );

  useEffect(() => {
    const allErrors: CommonError[] = [
      ...(billingAccountErrors ? billingAccountErrors : []),
      ...(onlineOrdersErrors ? onlineOrdersErrors : []),
    ];
    if (shouldScrollToError && window && allErrors.length > 0) {
      scrollTo(getTopMostElement(getElementsWithErrors(domRefs.current, allErrors)));
      setShouldScrollToError(false);
    }
  }, [billingAccountErrors, onlineOrdersErrors, shouldScrollToError]);

  const prepareNewBillingAccount = (prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier) => {
    if (!prepareNewBillingAccountSaveValues) {
      return {};
    }

    const { obj, validationErrors } = prepareNewBillingAccountSaveValues();
    const receiverType = getReceiverType(obj);
    const updatedBillingAccount = prepareBillingAccountSave(
      getEmptyBillingAccount(companyInfo?.companyName ?? ''),
      obj,
      receiverType
    );

    return {
      newBillingAccount: updatedBillingAccount,
      newBillingAccountValidationErrors: convertStringMapToCommonErrors(validationErrors),
    };
  };

  const onCloseDuplicateContactModal = () => setShowDuplicateContactModal(false);

  const updateOfferWithDuplicateContactIfAny = (offer: ConfiguredOffer): ConfiguredOffer => {
    if (!useDuplicateContact) {
      return offer;
    }

    const duplContactId = useDuplicateContact.contactId;
    const duplicate = contacts?.find(c => c.contactId === duplContactId);
    const duplicateContact = duplicate as Contact;
    const duplicateContactHeader = duplicate as ContactHeader;
    const purposeOfUseOrContact = {
      selected: SelectedPurposeOfUseOrContact.CONTACT,
      contactId: duplicate?.contactId ?? duplContactId,
      costCenter: duplicateContact?.person?.costCenter || duplicateContactHeader?.costCenter || '',
      employeeNumber:
        duplicateContact?.person?.employeeNumber || duplicateContactHeader?.referenceOrAdditionalInformation || '',
    };

    return {
      ...offer,
      selectedCommercialProducts: offer.selectedCommercialProducts.map((product, index) =>
        index === 0 ? { ...product, purposeOfUseOrContact } : product
      ),
    };
  };

  const updateOfferWithAvailableNumbers = async () => {
    const newNumbersCount = notAvailableNumbers.length;
    const generatedNumbers = await generateNumbers({ count: newNumbersCount });
    const updatedCommercialProducts = selectedOffer.selectedCommercialProducts.map(product => {
      const currentNumber = product.selectedPhoneNumber?.newPhoneNumber;
      const newPhoneNumber =
        currentNumber && notAvailableNumbers.includes(currentNumber) ? generatedNumbers.numbers.shift() : currentNumber;
      return {
        ...product,
        selectedPhoneNumber: product.selectedPhoneNumber
          ? {
              ...product.selectedPhoneNumber,
              newPhoneNumber: newPhoneNumber,
            }
          : product.selectedPhoneNumber,
      };
    });
    return {
      ...selectedOffer,
      selectedCommercialProducts: updatedCommercialProducts,
    };
  };

  const validateNewMobileNumbersIfAny = async () => {
    const newMobileNumbers = selectedOffer.selectedCommercialProducts
      .filter(
        product =>
          product.commercialProduct.productType === CommercialProductType.MOBILE &&
          product.selectedPhoneNumber?.type === PhoneNumberType.NEW
      )
      .map(product => product.selectedPhoneNumber?.newPhoneNumber)
      .filter(isDefined);

    if (newMobileNumbers.length > 0) {
      const validateNumbersResponse = await validateNumbers({ numbers: newMobileNumbers });
      setNotAvailableNumbers(validateNumbersResponse.notAvailableNumbers || []);
      setDisplayUnavailableNumbersModal(!validateNumbersResponse.allNumbersAvailable);
      return validateNumbersResponse.allNumbersAvailable;
    }
    return true;
  };

  const onSubmit = async (
    deliveryAddressFormValues: DeliveryAddressFormValues,
    shouldValidateNumbers = true,
    shouldReplaceUnavailableNumbers = false,
    shouldUseDuplicateContact = false
  ) => {
    if (isPOBoxAddress(deliveryAddressFormValues?.deliveryAddress?.address?.postalCode) && hasDeliverables) {
      setShowPOBoxWarningModal(true);
      return;
    }

    setSubmitting(true);

    const { selectedBaId, prepareNewBillingAccountSaveValues } = billingAccountSaveValues!;
    const { newBillingAccount, newBillingAccountValidationErrors } = prepareNewBillingAccount(
      prepareNewBillingAccountSaveValues
    );

    if (newBillingAccount) {
      const errors = getErrorsFromUpsertBillingAccount(newBillingAccount, newBillingAccountValidationErrors);
      if (errors) {
        setSubmitting(false);
        setShouldScrollToError(true);
        setBillingAccountErrors(errors);
        return;
      }
    }

    const allNumbersAvailable = shouldValidateNumbers ? await validateNewMobileNumbersIfAny() : true;
    if (!allNumbersAvailable) {
      setSubmitting(false);
      return;
    }

    const offer = shouldReplaceUnavailableNumbers ? await updateOfferWithAvailableNumbers() : selectedOffer;
    const offerWithDuplicateContactsHandled = updateOfferWithDuplicateContactIfAny(offer);

    if (isLiikkuvaWifiOrder) {
      try {
        await handleLiikkuvaWifiSupportCase(
          offerWithDuplicateContactsHandled,
          companyInfo,
          deliveryAddressFormValues.deliveryAddress,
          billingAccounts?.searchResults ?? [],
          contacts,
          selectedCampaignAssociation,
          selectedBaId,
          newBillingAccount,
          shouldUseDuplicateContact
        );
        navigate(paths.SELF_SERVICE_HOME);
        dispatch(startNotification(t.FDSO(yourOrderHasBeenTransferredForProcessingMsg)));
      } catch (error) {
        if (error instanceof DuplicateContactError) {
          setDuplicateContactId(error.contactId);
          setShowDuplicateContactModal(true);
        } else {
          setShowSupportCaseSendingError(true);
        }
      }
    } else {
      onSubmitOrder(
        [offerWithDuplicateContactsHandled],
        deliveryAddressFormValues.deliveryAddress,
        true,
        undefined,
        undefined,
        selectedBaId,
        newBillingAccount,
        newBillingAccountValidationErrors,
        orderStateParams.addOns
      );
    }

    setSubmitting(false);
  };

  const onSelectDuplicateContact = async () => {
    await onSubmit(deliveryAddressFormValuesState!, false, false, true);
  };

  const setRefCallback = (key: string, ref: HTMLElement | null) => {
    if (ref) {
      domRefs.current[key] = ref;
    } else {
      delete domRefs.current[key];
    }
  };

  if (!deliveryAddress.companyName || !deliveryAddress.recipient || !billingAccounts.searchResults) {
    return <Loading />;
  }

  return (
    <>
      {showPOBoxWarningModal && <POBoxWarningModal setShowModal={setShowPOBoxWarningModal} />}
      <FormWrapper
        defaultAddress={deliveryAddress}
        onSubmit={async deliveryAddressFormValues => {
          setDeliveryAddressFormValuesState(deliveryAddressFormValues);
          await onSubmit(deliveryAddressFormValues);
        }}
      >
        <div className="of-order-delivery-options">
          <ScrollToTopWhenSearchParamsChange />
          <div className="of-order-delivery-options__phaseindicator-container">
            <PhaseIndicator
              phases={[
                {
                  displayName: t.R4EV(productInformationsMsg),
                },
                {
                  displayName: t.T78Y(deliveryDetailsMsg),
                },
              ]}
              currentPhaseIndex={1}
            />
          </div>
          <div className="of-order-delivery-options__container">
            <CL.Grid className="of-order-delivery-options__grid">
              <h3 className={`${dsClass.MARGIN_BOTTOM_1} ${dsClass.MARGIN_TOP_0}`}>{t.IHO6(shippingAddressMsg)}</h3>
              <div className="of-order-delivery-options__section">
                {editDeliveryAddress ? (
                  <>
                    <CL.GridRow>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <TextInput name="deliveryAddress.companyName" maxLength={256} label={t.KJTS(companyMsg)} />
                      </CL.GridCol>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <Name name="deliveryAddress.recipient" maxLength={100} label={t.VGFI(nameMsg)} placeholder="" />
                      </CL.GridCol>
                    </CL.GridRow>
                    <CL.GridRow>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <PhoneNumber name="deliveryAddress.phoneNumber" label={t.W1Q4(phoneNumberMsg)} placeholder="" />
                      </CL.GridCol>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <TextInput
                          name="deliveryAddress.address.line1"
                          maxLength={100}
                          label={t.DD38(streetAddressMsg)}
                        />
                      </CL.GridCol>
                    </CL.GridRow>
                    <CL.GridRow>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <PostalCode
                          name="deliveryAddress.address.postalCode"
                          label={t.RUAW(postalCodeMsg)}
                          placeholder=""
                        />
                      </CL.GridCol>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <TextInput name="deliveryAddress.address.postOffice" maxLength={50} label={t.J0YE('City')} />
                      </CL.GridCol>
                    </CL.GridRow>
                  </>
                ) : (
                  <div>
                    <div>
                      <span>
                        {deliveryAddress.companyName}
                        <br />
                      </span>
                      <span>
                        {deliveryAddress.recipient}
                        <br />
                      </span>
                      <span>
                        {deliveryAddress.phoneNumber}
                        <br />
                      </span>
                      <span>
                        {deliveryAddress.address.line1}
                        <br />
                      </span>
                      <span>{deliveryAddress.address.postalCode + ' ' + deliveryAddress.address.postOffice}</span>
                    </div>
                    <div className="of-order-delivery-options__section-edit-button">
                      <CL.Button
                        className={dsClass.MARGIN_TOP_2}
                        color="light"
                        onClick={() => {
                          setEditDeliveryAddress(true);
                        }}
                      >
                        {t.NVPK(editMsg)}
                      </CL.Button>
                    </div>
                  </div>
                )}
              </div>
              <CL.GridRow>
                <CL.GridCol colWidthXL={12}>
                  <h3 className={`${dsClass.MARGIN_BOTTOM_1} ${dsClass.MARGIN_TOP_0}`}>{t.STU7(deliveryMethodMsg)}</h3>
                  <div className="of-order-delivery-options__section">
                    {getDeliveryMethodText(hasDeliverables, isLiikkuvaWifiOrder)}
                  </div>
                </CL.GridCol>
              </CL.GridRow>
              <CL.GridRow>
                <CL.GridCol colWidthXL={12}>
                  <AddOrSelectBillingAccounts
                    billingAccounts={billingAccounts.searchResults}
                    billChannels={billChannels}
                    contacts={contacts || []}
                    companyInfo={companyInfo}
                    addNewBA={true}
                    getBillingAccountSaveValuesFn={selectedBaData => {
                      setBillingAccountSaveValues(selectedBaData);
                    }}
                    setRefCallBack={setRefCallback}
                    isSaving={saving}
                    createBillingAccountErrors={billingAccountErrors}
                  />
                </CL.GridCol>
              </CL.GridRow>
              <CL.GridRow>
                <CL.GridCol colWidthXL={12}>
                  <OrderSummary
                    commercialProducts={selectedCommercialProducts}
                    campaignAssociation={selectedCampaignAssociation}
                    summaryType={OrderSummaryType.DETAILS_OPEN}
                  />
                  <div className="of-wizard-actions-container">
                    <WizardActions
                      submitting={submitting}
                      forwardButtonText={t.RZU4(confirmOrderMsg)}
                      forwardButtonWideOnPhone={true}
                      onBackClick={() => history.back()}
                      onForwardClick={() => {}}
                    />
                  </div>
                </CL.GridCol>
              </CL.GridRow>
            </CL.Grid>
            {displayUnavailableNumbersModal && (
              <UnavailableNumbersModal
                onSubmit={async () => {
                  setDisplayUnavailableNumbersModal(false);
                  await onSubmit(deliveryAddressFormValuesState!, false, true);
                }}
                onModalClose={() => setDisplayUnavailableNumbersModal(false)}
                notAvailableNumbers={notAvailableNumbers}
              />
            )}
            {showSupportCaseSendingError && (
              <SupportCaseSendingFailureDialog setShowDialog={setShowSupportCaseSendingError} />
            )}
            {showDuplicateContactModal && (
              <DuplicateContactDialog
                duplicateContactType={DuplicateContact.DuplicateTypeEnum.DUPLICATE_CONTACT}
                onSelectDuplicateContact={onSelectDuplicateContact}
                onCloseDialog={onCloseDuplicateContactModal}
                duplicateContact={getGenericErrorDuplicateContact(contacts, duplicateContactId)}
              />
            )}
          </div>
        </div>
      </FormWrapper>
    </>
  );
};
