import { CREATED_CONTACT } from '../../common/react-hook-form/fields/Contact';
import { ContactRole } from '../../generated/api/contactRole';
import { ContactType } from '../../generated/api/contactType';
import { ReceiverTypes } from '../../common/utils/billingAccountUtils';
import { createBillingAccount, postContact } from '../../common/fetch';
import type { Contact } from '../../generated/api/contact';
import type { PutBillingAccountResponse } from '../../generated/api/putBillingAccountResponse';
import type { ReadyFormValues } from './CatalogConfigurationForm';

const enrichContact = (contact: Contact) => {
  const contactPerson = contact.person!;
  return {
    ...contact,
    person: {
      ...contactPerson,
      roles: [ContactRole.BILLING_CONTACT],
    },
  };
};

export const createBillingAccountAndContacts = async (
  data: ReadyFormValues,
  mdmId?: string
): Promise<PutBillingAccountResponse> => {
  const isNewContact = data.billingAccount?.billingContactId === CREATED_CONTACT;
  const billingContactId = isNewContact
    ? (await postContact(enrichContact(data.contact!), mdmId)).contactId
    : data.billingAccount?.billingContactId;

  const isNewReceiver = data.billingAccount?.billReceiverId === CREATED_CONTACT;
  const billReceiverId = isNewReceiver
    ? (await postContact(enrichContact(data.receiver!), mdmId)).contactId
    : data.billReceiverSelection
      ? data.billReceiverSelection === ReceiverTypes.SAME_AS_CONTACT
        ? billingContactId
        : data.billingAccount?.billReceiverId
      : undefined;
  const billReceiverType = billReceiverId ? ContactType.PERSON : undefined;

  const catalogBa = data.billingAccount!;
  return await createBillingAccount(
    {
      ...catalogBa,
      billingContactId,
      billReceiverId,
      billReceiverType,
    },
    mdmId
  );
};
