import { DeviceCheckoutBillSummaryHeader } from '../DeviceCheckoutBillSummaryHeader/DeviceCheckoutBillSummaryHeader.js';
import { SmallHeader } from '../SmallHeader/SmallHeader.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getConfiguredCommercialProducts } from '../../common/utils/cartProductUtils.js';
import { getDeliveryCharges, isFinalPage } from '../../common/utils/checkoutUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { State } from '../../selfservice/common/store.js';

export const CheckoutHeader = ({
  isEmployee,
  currentStep,
  hideDeliveryMethodSelection,
  redirectPath,
  returnToBasket,
}: {
  isEmployee: boolean;
  currentStep: number;
  hideDeliveryMethodSelection: boolean;
  redirectPath: string;
  returnToBasket?: boolean;
}) => {
  const { deliveryDetails, cartItems, listOfDeliveryMethods } = useSelector(
    (state: State) => ({
      deliveryDetails: state.deviceCheckout?.deliveryDetails,
      cartItems: state.deviceCheckout?.cartItems || [],
      listOfDeliveryMethods: state.deviceCheckout?.deliveryMethods?.lisOfDeliveryMethods,
    }),
    deepEqual
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToHome = () => {
    dispatch(resetErrors());
    navigate(isEmployee ? paths.EMPLOYEE_HOME : paths.SELF_SERVICE_HOME);
  };

  if (isFinalPage(pathname)) {
    return (
      <SmallHeader
        buttonText={isEmployee ? t.Z0U9(`Return to the Employee's OmaElisa`) : t.YU2R('Return to the OmaElisa')}
        onClick={navigateToHome}
      />
    );
  } else if (currentStep >= 0 && currentStep < (isEmployee ? 1 : 2)) {
    return (
      <SmallHeader
        buttonText={returnToBasket ? t.DKXU('Back to cart') : t.PLAW('Return to the shop')}
        onClick={() => {
          navigate(returnToBasket ? paths.SHOPPING_BASKET : redirectPath, { state: { from: 'kassa' } });
        }}
      />
    );
  }

  return (
    <DeviceCheckoutBillSummaryHeader
      commercialProducts={getConfiguredCommercialProducts(cartItems)}
      deliveryCharges={getDeliveryCharges(deliveryDetails, hideDeliveryMethodSelection, listOfDeliveryMethods)}
      // Prices is always included with VAT for employee or consumer users.
      priceIncludesVAT={isEmployee}
    />
  );
};
