import * as CL from '@design-system/component-library';
import { ActionGroup } from '../../content-blocks/ActionGroup/ActionGroup.js';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { FaqAccordionItemContent } from '../FaqAccordion/FaqAccordionItemContent.js';
import { ImageCards } from '../ImageCards/ImageCards.js';
import { Link } from 'react-router-dom';
import { MbbSubscriptionSelectionSectionId } from '../OrderSubscriptionSelection/MobileBroadbandSubscriptionCards';
import { MobileBroadbandAlternativesSummary } from '../OrderSubscriptionSelection/MobileBroadbandAlternativesSummary';
import { MobileBroadbandService } from '../../common/constants/subscriptionConstants';
import { PAGE_MARGINS_AS_PADDING } from '../PageMargins/PageMargins.js';
import { Picture } from '../../components/Picture/Picture.js';
import { PublicSelfServiceBanner } from '../PublicSelfServiceBanner/PublicSelfServiceBanner.js';
import { PublicSubscriptionImageCards } from '../../components/PublicMobileVoiceSubscriptions/PublicSubscriptionImageCards.js';
import { ReissunettiAndReissaajaInfo } from '../OrderSubscriptionSelection/ReissunettiAndReissaajaInfo';
import { RoutersPromotionBlock } from './RoutersPromotionBlock.js';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { YjaOfferBlock } from '../YjaOfferBlock/YjaOfferBlock.js';
import {
  addToCartMsg,
  contactUsMsg,
  corporateCustomersMsg,
  frequentlyAskedQuestionsAndAnswersMsg,
  internetSubscriptionsMsg,
  mobileBroadbandInfoMsg,
  mobileBroadbandMsg,
  subscriptionsMsg,
  t,
} from '../../common/i18n/index.js';
import { addToShoppingBasket } from '../ShoppingBasket/shoppingBasketFunctions';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getMobileBroadbandSubscriptionCardContents } from '../OrderSubscriptionSelection/content/MobileBroadbandSubscriptionCardContent';
import { isFiveGPlusOffer, isLiikkuvaWifiOffer } from '../../common/utils/subscriptionUtils';
import { isSubCardWithImpression } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import { loadOnlineModelWithId } from '../../selfservice/actions/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../../components/Picture/contentfulImageProxy.js';
import { useAuth } from '../../public/site/AuthProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { CommercialProduct, Offer, OnlineModel } from '../../generated/api/models.js';
import type { MobileBroadbandSubCardProps, SubCardProps } from '../SubscriptionCard/SubscriptionCard.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubCardWithImpressionProps } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';

import './PublicMobileBroadbandSubscriptions.scss';

const FAQ_ID = 'mobiililaajakaista_faq_accordion';

const whatIsMobileBroadbandMsg = 'What is mobile broadband?';
const howDoIGetAccessToMobileBroadbandMsg = 'How do I get access to mobile broadband?';
const whyChooseAnElisaMobileBroadbandSubscriptionMsg = 'Why choose an Elisa mobile broadband subscription?';
const howFastIsElisasMobileBroadbandMsg = 'How fast is Elisa’s mobile broadband?';
const willA5GSubscriptionWorkInMyAreaMsg = 'Will a 5G subscription work in my area?';
const canIUseMobileBroadbandAbroadMsg = 'Can I use mobile broadband abroad?';
const isTheMobileBroadbandContractFixedTermMsg = 'Is the mobile broadband contract fixed-term?';
const doINeedASeparateRouterMsg = 'Do I need a separate router?';
const highSpeed4gAnd5gAnd5gPlusNetworks = 'High-speed 4G, 5G and 5G+ networks';
const whyIs5GplusBetterThan5GMsg = 'Why is the standalone 5G network, i.e. 5G+, better than 5G?';
const whatDevicesWorkIn5Gplus = 'Which routers work in the standalone network?';
const willCurrent5GautomaticallyUpdateMsg =
  'My current 5G subscription does not have the + feature. Will it be updated automatically?';
const can5GsubscriptionBeUsedWithoutSupportMsg =
  'Can the 5G+ subscription be used with a router that does not support a standalone network?';
const convenientMobileBroadbandDeploymentForBusinessesMsg = 'Convenient mobile broadband deployment for businesses';
const internetSubscriptionsForOfficesAndRemotelyControlledDevicesMsg =
  'Internet subscriptions for offices and remotely controlled devices';
const needHelpChoosingMobileBroadbandPackageMsg = 'Need help choosing a mobile broadband package?';
const mobileBroadbandForBusinessesFrequentlyAskedQuestionsMsg =
  'Mobile broadband for businesses - Frequently asked questions';

const Breadcrumbs = (): JSX.Element => (
  <BreadCrumbs
    breadCrumbPaths={[
      { name: t.KBLX(corporateCustomersMsg), path: '/' },
      { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
      { name: t.SEYV(internetSubscriptionsMsg), path: paths.INTERNET_SUBSCRIPTIONS_PUBLIC },
      { name: t.HV3Q(mobileBroadbandMsg) },
    ]}
  />
);

const Header = (): JSX.Element => (
  <header>
    <h1 className={dsClass.PADDING_BOTTOM_3}>{t.SDV0('Mobile broadband for businesses')}</h1>
    <div className={dsClass.TEXT_LEAD}>{t.SDV1(mobileBroadbandInfoMsg)}</div>
  </header>
);

const FeatureImageCards = (): JSX.Element => (
  <section>
    <PublicSubscriptionImageCards
      items={[
        {
          content: (
            <>
              {t.SDV5(
                'A mobile broadband is well suited for mobile work. Take care of business flexibly in different locations - when working remotely, on business trips and at sales meetings.'
              )}
            </>
          ),
          image: 'hfqW15rbSXKNSB08YDmay/i.svg',
          imageAlt: t.SG16('A mobile broadband is well suited for mobile work'),
          title: t.SDV2('Functioning Internet anywhere'),
        },
        {
          content: (
            <>
              {t.SDV6(
                'Elisa’s 4G network already covers Finland, and we are a pioneer of the 5G network. Good coverage and a functioning network ensure a stable connection.'
              )}
            </>
          ),
          image: '7gNJQSWwgmYfw1BMaQVCBu/i.svg',
          imageAlt: t.SDV3(highSpeed4gAnd5gAnd5gPlusNetworks),
          title: t.SDV3(highSpeed4gAnd5gAnd5gPlusNetworks),
        },
        {
          content: (
            <>
              {t.SDV7(
                'All subscriptions have unlimited internet in Finland. In addition, you will have plenty of data in the EU and EEA countries, so you can use the Internet safely while travelling.'
              )}
            </>
          ),
          image: '1PHbSocipUGt89El2BmOVO/i.svg',
          imageAlt: t.SG17('A mobile broadband with excellent data packages'),
          title: t.SDV4('Comprehensive data packages'),
        },
      ]}
    />
  </section>
);

const ContactUs = (): JSX.Element => (
  <section
    aria-label={t.SDV8(needHelpChoosingMobileBroadbandPackageMsg)}
    className={`${dsClass.BACKGROUND_COLOR_NEUTRAL_200} ${dsClass.MARGIN_TOP_6} ${PAGE_MARGINS_AS_PADDING}`}
  >
    <h2 className={dsClass.H3}>{t.SDV8(needHelpChoosingMobileBroadbandPackageMsg)}</h2>
    <p>
      {t.SDV9(
        'We will help you choose the right mobile broadband package for your business. Leave us your contact information, and our sales staff will answer your questions:'
      )}
    </p>
    <Link
      className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_LIGHT} ${dsClass.LINK_BUTTON_SIZE_L} ${dsClass.MARGIN_TOP_2}`}
      to={`${paths.CONTACT_FORM}?sourceUrl=${paths.ANONYMOUS_MOBILE_BROADBAND}`}
    >
      {t.NYU3(contactUsMsg)}
    </Link>
  </section>
);

const OtherSubscriptionTypes = (): JSX.Element => (
  <section aria-label={t.SEYW(internetSubscriptionsForOfficesAndRemotelyControlledDevicesMsg)}>
    <h2 className={dsClass.H3}>{t.SEYW(internetSubscriptionsForOfficesAndRemotelyControlledDevicesMsg)}</h2>
    <ImageCards
      imageSize="l"
      items={[
        {
          linkText: 'Netti Lite',
          content: t.SDU5(
            ' is a full-service internet connection for your small business or home office. We will offer the best option for you based on your location and needs.'
          ),
          image: '4vlCownAZmg06oRZoWWtOW/i.svg',
          title: t.SDU4('Internet access for a small office or remote work'),
          url: paths.NETTI_LITE,
        },
        {
          linkText: t.M6CL('The Netti Plus and Netti Pro'),
          content: t.SDUG(
            ' subscriptions bring a stable internet connection to your place of business. You can also utilise them as your business’s versatile Internet connection.'
          ),
          image: '2RyOpcVOPbTdbqCd84gVtn/i.svg',
          title: t.SDUF('Internet for a larger location'),
          url: paths.INTERNET_FOR_BIG_COMPANY,
        },
        {
          linkText: 'Elisa Laitenetti',
          content: t.SDUJ(
            ' is suitable for surveillance cameras, alarm systems or GPS trackers, for example. Read more about the Laitenetti subscription for remotely controlled devices and order yours!'
          ),
          image: '6ipMQkHYLT9n4xGEtaerqV/i.svg',
          title: t.SDUI('Elisa Laitenetti – an agile mobile subscription for devices'),
          url: paths.LAITENETTI,
        },
      ]}
      type="dark"
    />
  </section>
);

const FAQAccordion = (): JSX.Element => (
  <section aria-label={t.SDVI(mobileBroadbandForBusinessesFrequentlyAskedQuestionsMsg)} id={FAQ_ID}>
    <div className={dsClass.TEXT_ALIGN_LEFT} itemScope itemType="https://schema.org/FAQPage">
      <h2 className={dsClass.H3}>{t.SDVI(mobileBroadbandForBusinessesFrequentlyAskedQuestionsMsg)}</h2>
      {/* TODO use new CMS component when available */}
      <CL.Accordion>
        <CL.AccordionItem heading={t.SDVA(whatIsMobileBroadbandMsg)}>
          <FaqAccordionItemContent heading={t.SDVA(whatIsMobileBroadbandMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDVJ('Mobile broadband works with a SIM card and gives you access to ')}
                <b>{t.SDVK('Internet')}</b>
                {t.SDVL(
                  ' while working remotely. Elisa Yritysdata mobile broadband comes with a SIM card, which you can install on a tablet or router, for example. You can also work flexibly while traveling.'
                )}
              </li>
              <li>
                {t.SDVM('Also check out our ')}
                <Link to={paths.ANONYMOUS_MOBILE_VOICE}>{t.SDVN('telephone subscriptions for businesses.')}</Link>
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVB(howDoIGetAccessToMobileBroadbandMsg)}>
          <FaqAccordionItemContent heading={t.SDVB(howDoIGetAccessToMobileBroadbandMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDVO(
                  'Mobile broadband comes with its own SIM card, which you can install on a tablet or router, for example.'
                )}
              </li>
              <li>
                {t.SDVP(
                  'You can now also get a SIM card as an eSIM card. In this case, you can activate the SIM card digitally. Please note that your device must support eSIM functionality.'
                )}
                <br />
                <a href={paths.INSTRUCTION_ESIM_ACTIVATION}>
                  {t.SDVQ('Read more about how to activate an eSIM card.')}
                </a>
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVC(whyChooseAnElisaMobileBroadbandSubscriptionMsg)}>
          <FaqAccordionItemContent heading={t.SDVC(whyChooseAnElisaMobileBroadbandSubscriptionMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDVR(
                  'Elisa’s mobile broadband subscription is a reliable and Finnish choice for your company. With a comprehensive and high-quality 5G network, you can also work flexibly on the go. '
                )}
              </li>
              <li>
                {t.SDVS(
                  'Elisa invests actively in product development, and we are constantly expanding our fast 5G networks.'
                )}
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVD(howFastIsElisasMobileBroadbandMsg)}>
          <FaqAccordionItemContent heading={t.SDVD(howFastIsElisasMobileBroadbandMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDVT(
                  'Elisa’s mobile subscription is available in various speed categories, which are reported as maximum rates of speed.'
                )}
              </li>
              <li>
                {t.SDVU(
                  'The speed depends on the location, available network technology, signal strength, disturbance level, terminal device characteristics and network load, among other things. '
                )}
                <br />
                <a href={paths.COVERAGE} rel="noreferrer" target="_blank">
                  {t.SDVV('See your region’s coverage in the coverage map.')}
                </a>
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVE(willA5GSubscriptionWorkInMyAreaMsg)}>
          <FaqAccordionItemContent heading={t.SDVE(willA5GSubscriptionWorkInMyAreaMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDVW(
                  'Elisa is constantly expanding and improving the 5G and 5G+ networks all over Finland. More than 4 million Finns live within Elisa’s 5G coverage area, and the 5G network is active in more than 200 municipalities. '
                )}
                <br />
                <a href={paths.COVERAGE} rel="noreferrer" target="_blank">
                  {t.SDVX('See the coverage areas in more detail. ')}
                </a>
              </li>
              <li>
                {t.SDVY(
                  'If you use a 5G mobile broadband outside the 5G area, you will have a high-speed 4G connection.'
                )}
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVF(canIUseMobileBroadbandAbroadMsg)}>
          <FaqAccordionItemContent heading={t.SDVF(canIUseMobileBroadbandAbroadMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDVZ('All subscriptions include a certain amount of data for EU and EEA countries. ')}
                <br />
                <Link to={paths.USING_YOUR_PHONE_ABROAD}>{t.SDW0('Read more about using a subscription abroad.')}</Link>
              </li>
              <li>
                {t.SDW1('It is possible to order the ')}
                <Link to={paths.REISSUNETTI}>{t.SDW2('Elisa Reissunetti service')}</Link>
                {t.SDW3(
                  ' for mobile broadband subscriptions if the terminal supports sending and receiving SMS messages. Usually, there is a phone application for routers through which text messages can be managed (e.g., Huawei HiLink).'
                )}
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVG(isTheMobileBroadbandContractFixedTermMsg)}>
          <FaqAccordionItemContent heading={t.SDVG(isTheMobileBroadbandContractFixedTermMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDW4(
                  'Our mobile broadband subscriptions are valid until further notice, with the exception of the Suomen Yrittäjät member benefit and offers.'
                )}
              </li>
              <li>
                {t.SDW5('A subscription valid until further notice can be terminated at any time in ')}
                <Link to={paths.SELF_SERVICE_HOME}>{t.SDW6('OmaElisa')}</Link>
                {t.SDW7(' or through ')}
                <Link to={paths.CONTACT_INFO}>{t.SDW8('customer service')}</Link>
                {t.SDW9('.')}
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SDVH(doINeedASeparateRouterMsg)}>
          <FaqAccordionItemContent heading={t.SDVH(doINeedASeparateRouterMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SDWA(
                  'With a router, you can share your internet connection to several devices, which means you can set up a WiFi network. This allows you to connect, for example, your tablet, computer and TV to the same network. '
                )}
              </li>
              <li>
                {t.SDWB(
                  'The router does not come automatically with your mobile broadband subscription, but you can buy it separately from our '
                )}
                <Link to={paths.ROUTERS}>{t.SDWC('corporate online store.')}</Link>
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SG05(whyIs5GplusBetterThan5GMsg)}>
          <FaqAccordionItemContent heading={t.SG05(whyIs5GplusBetterThan5GMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SG06(
                  'The standalone 5G network offers a more stable connection with the latest technology. In practice, this also means less delays. 5G+ is also more energy efficient and ecological than its predecessors.'
                )}
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SG07(whatDevicesWorkIn5Gplus)}>
          <FaqAccordionItemContent heading={t.SG07(whatDevicesWorkIn5Gplus)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SG08(
                  'For now, the standalone 5G network works with a limited number of routers, but the selection is growing steadily. Check our list of '
                )}
                <Link to={paths.ROUTERS}>{t.SF11('devices that support 5G+')}</Link>.
                {t.SG09(' You can order a new router online or ')}
                <Link to={paths.STORES}>{t.SG10('visit one of our shops')}</Link>.
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SG11(willCurrent5GautomaticallyUpdateMsg)}>
          <FaqAccordionItemContent heading={t.SG11(willCurrent5GautomaticallyUpdateMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SG12(
                  'The subscription will not be updated automatically. You can update it by logging in to Corporate OmaElisa. If you need more information, '
                )}
                <Link to={paths.CONTACT_INFO}>{t.SG13('our customer service is happy to help')}</Link>.
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.SG14(can5GsubscriptionBeUsedWithoutSupportMsg)}>
          <FaqAccordionItemContent heading={t.SG14(can5GsubscriptionBeUsedWithoutSupportMsg)}>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.SG15(
                  'Yes. If the router does not support an standalone network, it will use the regular 5G or 4G connection as before.'
                )}
              </li>
            </ul>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
      </CL.Accordion>
    </div>
  </section>
);

const TipsAndTricks = (): JSX.Element => (
  <aside
    aria-label={t.SDU7(convenientMobileBroadbandDeploymentForBusinessesMsg)}
    className={`${dsClass.BACKGROUND_COLOR_BLUE_800} ${PAGE_MARGINS_AS_PADDING}`}
  >
    <h2 className={dsClass.H3} style={{ color: 'white' }}>
      {t.SDU7(convenientMobileBroadbandDeploymentForBusinessesMsg)}
    </h2>
    <Link
      className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_LIGHT_INVERSE} ${dsClass.LINK_BUTTON_SIZE_L} ${dsClass.MARGIN_TOP_4}`}
      to={paths.INSTRUCTIONS_MOBILE_BROADBAND}
    >
      {t.SDU8('Read instructions and tips')}
    </Link>
  </aside>
);

// TODO use new CMS component when available
const Contacts = (): JSX.Element => (
  <ActionGroup
    actionBlocks={[
      {
        additionalInformation: paths.CONTACT_FORM,
        id: 'contact-request',
        label: t.SDUA('Leave your contact details'),
        title: t.SDU9('Interested?'),
        type: 'contact',
      },
      {
        additionalInformation: t.SDUB('Mon-Fri 8 am-4 pm | Free of charge'),
        id: 'sales-service',
        label: '0800 04411',
        title: t.JXQZ('Sales service'),
        type: 'call',
      },
      {
        additionalInformation: t.SDUD(
          'Mon-Fri, 8 am-5 pm | 3,04 €/call + 3,62 €/min VAT 25.5% + local/mobile network charge'
        ),
        id: 'yritysguru',
        label: '0600 900 200',
        title: t.MFED('Yritysguru helps you with technical problems'),
        type: 'call',
      },
    ]}
    type=""
  />
);

const FiveGPlusInfo = (): JSX.Element => (
  <section className="of-public-mobile-broadband-subscriptions__marketing-block">
    <div>
      <div>
        <h2>{t.SG00('High-speed 4G, 5G and standalone 5G network')}</h2>
        <p>{t.SG01('We were to first operator to offer a standalone 5G network to our customers.')}</p>
        <p>
          {t.SG02(
            'It is the latest development in 5G technology. Thanks to our new 5G+ networks, you can now use the internet in a more stable manner with less delays.'
          )}
        </p>
        <p>
          {t.SG03(
            '5G+ is included as a default in our 1000M, 600M, 300M and 100M mobile broadband subscriptions. However, please note that in order to utilise the standalone network, '
          )}
          <Link to="/kauppa/itsenaisen-verkon-laitteet">{t.SG04('you will also need a router that supports it')}</Link>.
        </p>
      </div>
      <Picture
        alt="Nopea 4G-, 5G- ja itsenäinen 5G-verkko"
        offerWidthAlternatives={[288, 340, 433, 448, 509, 515, 592, 1018]}
        renderedImageSize={{
          onPhone: 'calc(100vw - 32px)',
          onPhoneLarge: 'calc(100vw - 48px)',
          onLaptop: 'calc((100vw - 64px) * 0.4)',
          onDesktop: '509px',
        }}
        src={`${pictureStorageBaseUrl}4PX4qgCXijwFNSReTwn4dH/i.png`}
      />
    </div>
  </section>
);

const MobileBroadbandSubscriptionCards = ({
  subscriptionCardData,
  isAuthenticated,
  onlineModels,
  listName,
}: {
  isAuthenticated: boolean;
  subscriptionCardData: MobileBroadbandSubCardProps[];
  onlineModels: OnlineModel[];
  listName: string;
}) => {
  const onCardButtonClick = useCallback(
    (subData: SubCardProps | SubCardWithImpressionProps) => {
      if (!isSubCardWithImpression(subData)) {
        return;
      }
      const { offer, onlineModel } = subData;
      const cartItemContainer = {
        additionalFields: [],
        commercialProduct: offer.commercialProducts[0],
        isPublicSite: true,
        offer: offer,
        onlineModel: onlineModel,
        quantity: 1,
        selectedAddOnBundles: [],
        productPath: onlineModel.pagePath || '',
      };
      addToShoppingBasket(cartItemContainer, isAuthenticated);
    },
    [isAuthenticated]
  );

  return (
    <SubscriptionCards
      buttonText={t.JFOS(addToCartMsg)}
      listName={listName}
      onButtonClick={onCardButtonClick}
      subscriptions={subscriptionCardData.map(subData => {
        const onlineModel = onlineModels.find(
          (om: OnlineModel) => om.onlineModelCode === subData.salesProduct.onlineModelCode
        );
        const offer = onlineModel?.offers.find((of: Offer) => of.offerCode === subData.salesProduct.offerCode);
        const oneTimePrice = offer?.commercialProducts.reduce(
          (a: number, c: CommercialProduct) => a + (c.oneTimeCharge || 0),
          0
        );
        const monthlyPrice = offer?.commercialProducts.reduce(
          (a: number, c: CommercialProduct) => a + (c.monthlyRecurringCharge || 0),
          0
        );
        const isLoading = offer == null;

        return {
          bullets: subData.bullets,
          buttonText: t.JFOS(addToCartMsg),
          contentHeader: subData.contentHeader,
          description: subData.description,
          fixedTermMonths: subData.fixedTermMonths,
          isLoading,
          mainHeader: subData.mainHeader,
          monthlyPrice: monthlyPrice,
          offer,
          oneTimePrice: oneTimePrice,
          onlineModel: isLoading ? undefined : onlineModel,
          originalMonthlyPrice: subData.originalMonthlyPrice,
          originalOneTimePrice: subData.originalOneTimePrice,
          pricePreamble: subData.pricePreamble,
          recommended: subData.recommended,
          // This is the NOE offerCode. We need it here so that specifications' values can be filtered/fetched.
          selectedOffer: subData.selectedOffer,
          serviceFee: subData.serviceFee,
          specifications: subData.specifications,
          services: subData.services,
        };
      })}
    />
  );
};

const LiikkuvaWifiCards = ({
  subscriptionProductData,
  isAuthenticated,
  onlineModels,
}: {
  isAuthenticated: boolean;
  subscriptionProductData: MobileBroadbandSubCardProps[];
  onlineModels: OnlineModel[];
}) => {
  const liikkuvaWifiCards = subscriptionProductData.filter(sub => isLiikkuvaWifiOffer(sub.selectedOffer));

  return (
    <div id={MbbSubscriptionSelectionSectionId.WIFI_SUBS_ID}>
      <h2>{MobileBroadbandService.LIIKKUVA_WIFI}</h2>
      <p>
        {t.JA5V(
          `Using a mobile router can save your phone's battery life, as you won't need to share the internet from your phone. Thanks to 5G+ technology, the battery consumption of the mobile router has been minimized up to 20 % compared to the older 4G/5G network alternative.`
        )}
      </p>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <MobileBroadbandSubscriptionCards
          isAuthenticated={isAuthenticated}
          subscriptionCardData={liikkuvaWifiCards}
          onlineModels={onlineModels}
          listName="Liikkuva WiFi"
        />
      </div>
    </div>
  );
};

const YritysDataCardsByType = ({
  isAuthenticated,
  cards,
  onlineModels,
  headerText,
  listName,
}: {
  isAuthenticated: boolean;
  cards: MobileBroadbandSubCardProps[];
  onlineModels: OnlineModel[];
  headerText: string;
  listName: string;
}) => (
  <>
    <h3>{headerText}</h3>
    <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
      <MobileBroadbandSubscriptionCards
        isAuthenticated={isAuthenticated}
        subscriptionCardData={cards}
        onlineModels={onlineModels}
        listName={listName}
      />
    </div>
  </>
);

const YritysdataCards = ({
  subscriptionProductData,
  isAuthenticated,
  onlineModels,
}: {
  isAuthenticated: boolean;
  subscriptionProductData: MobileBroadbandSubCardProps[];
  onlineModels: OnlineModel[];
}) => {
  const yritysDataCards = subscriptionProductData.filter(sub => !isLiikkuvaWifiOffer(sub.selectedOffer));
  const fiveGPlusCards = yritysDataCards.filter(card => isFiveGPlusOffer(card.selectedOffer));
  const fiveGCards = yritysDataCards.filter(card => !isFiveGPlusOffer(card.selectedOffer));

  return (
    <div id={MbbSubscriptionSelectionSectionId.YRITYSDATA_SUBS_ID}>
      <h2 className={`${dsClass.PADDING_TOP_3}`}>{MobileBroadbandService.YRITYSDATA}</h2>
      <p className={`${dsClass.PADDING_BOTTOM_3}`}>
        {t.ADV9('Includes just the subscription, and the device is sold separately.')}
      </p>
      <YritysDataCardsByType
        isAuthenticated={isAuthenticated}
        cards={fiveGPlusCards}
        onlineModels={onlineModels}
        headerText={t.MCL8('Select a 5G+ mobile broadband subscription')}
        listName="Yritysdataliittymät 5G+"
      />
      <div className={`${dsClass.PADDING_BOTTOM_3}`}>
        <ReissunettiAndReissaajaInfo />
      </div>
      <YritysDataCardsByType
        isAuthenticated={isAuthenticated}
        cards={fiveGCards}
        onlineModels={onlineModels}
        headerText={t.E86C('Select a 5G mobile broadband subscription')}
        listName="Yritysdataliittymät 5G"
      />
    </div>
  );
};

const UnlimitedDataDisclaimer = () => (
  <div className={`${dsClass.TEXT_ALIGN_CENTER} ${dsClass.MARGIN_HORIZONTAL_3}`}>
    <p className={dsClass.TEXT_S}>
      {t.SDWD('All subscriptions have unlimited data in Finland, the Nordic countries and the Baltics.')}
    </p>
    <p className={`${dsClass.MARGIN_BOTTOM_7} ${dsClass.MARGIN_TOP_6}`}>
      <CL.Link linkStyle="link-button" buttonColor="light" buttonSize="l" linkHref={`#${FAQ_ID}`}>
        {t.SDWE(frequentlyAskedQuestionsAndAnswersMsg)}
      </CL.Link>
    </p>
  </div>
);

export const PublicMobileBroadbandSubscriptions = () => {
  const dispatch = useDispatch();
  const onlineModels = useSelector((state: State) => state.selfservice?.onlineModels?.items || [], deepEqual);
  // flatMap removes undefined values
  const onlineModelsCodes = useMemo(
    () => onlineModels.flatMap((onlineModel: OnlineModel) => onlineModel.onlineModelCode),
    [onlineModels]
  );
  const subscriptionProductData = getMobileBroadbandSubscriptionCardContents(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    // Only load models not already present
    subscriptionProductData
      .reduce((acc, curr) => {
        const { onlineModelCode } = curr.salesProduct;
        if (!acc.includes(onlineModelCode)) {
          acc.push(onlineModelCode);
        }
        return acc;
      }, [] as string[])
      .forEach(onlineModelCode => {
        if (!onlineModelsCodes.includes(onlineModelCode)) {
          dispatch(loadOnlineModelWithId(onlineModelCode));
        }
      });
  }, [dispatch, subscriptionProductData, onlineModelsCodes]);

  return (
    <section className="of-public-mobile-broadband-subscriptions of-public-page">
      <Breadcrumbs />
      <Header />
      <MobileBroadbandAlternativesSummary />
      <LiikkuvaWifiCards
        isAuthenticated={isAuthenticated}
        subscriptionProductData={subscriptionProductData}
        onlineModels={onlineModels}
      />
      <YritysdataCards
        isAuthenticated={isAuthenticated}
        subscriptionProductData={subscriptionProductData}
        onlineModels={onlineModels}
      />
      <UnlimitedDataDisclaimer />
      <FeatureImageCards />
      <FiveGPlusInfo />
      <YjaOfferBlock />
      <PublicSelfServiceBanner />
      <ContactUs />
      <RoutersPromotionBlock />
      <OtherSubscriptionTypes />
      <FAQAccordion />
      <TipsAndTricks />
      <Contacts />
    </section>
  );
};
