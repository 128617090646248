import { BankButtonsDialog } from '../BankButtonsDialog/BankButtonsDialog.js';
import { BbEmployeeInvitationDialog } from '../SubscriptionDetails/BroadBandInvitation/BbEmployeeInvitationDialog.js';
import { BbEmployeeNotFoundDialog } from '../SubscriptionDetails/BroadBandInvitation/BbEmployeeNotFoundDialog.js';
import { ChangeNettiLiteSubscriptionDialog } from '../ChangeNettiLiteSubscriptionDialog/ChangeNettiLiteSubscriptionDialog.js';
import { ChangeOfferConfirmationDialogContent } from '../ChangeOfferConfirmationDialogContent/ChangeOfferConfirmationDialogContent.js';
import { ChangeSimCardConfirmDialog } from '../ChangeSimCardConfirmDialog/ChangeSimCardConfirmDialog.js';
import { ChangeSubscriptionContactDialog } from '../ChangeSubscriptionContactDialog/ChangeSubscriptionContactDialog.js';
import { CommonErrorType, DialogType } from '../../common/enums.js';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { DayPicker } from '../DayPicker/index.js';
import { DetachRingConfirmationDialog } from '../DetachRingConfirmationDialog/DetachRingConfirmationDialog.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { EppDeviceChangeDialog } from '../EppDialogs/EppDeviceChangeDialog.js';
import { EppRedeemDialog } from '../EppRedeem/EppRedeemDialog.js';
import { EppTerminateDialog } from '../EppDialogs/EppTerminateDialog.js';
import {
  I18nTextLinkEmbedded,
  activateSubMsg,
  addContactMsg,
  changeRequestInProcessHeaderMsg,
  changeRingUserBodyMsg,
  changeRingUserHeaderMsg,
  chooseThisContactMsg,
  closeMsg,
  confirmMsg,
  contactAlreadyExistsMsg,
  contactCanBeFoundInCompanyListingMsg,
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenLongMsg,
  customerServiceNumberPriceMsg,
  describeYourProblemMsg,
  duplicateContactHeaderMsg,
  howCanWeHelpMsg,
  inquiryHasBeenSentMsg,
  problemWithProcessingOrderMsg,
  processingOrderMsg,
  returnToOmaElisaMsg,
  subChangeRequestInProcessMsg,
  t,
  terminateSubscriptionMsg,
  updateSubscriptionMsg,
} from '../../common/i18n/index.js';
import { InvoiceSupportCaseDialog } from '../InvoiceSupportCaseDialog/InvoiceSupportCaseDialog.js';
import { LoadingDialog } from '../LoadingDialog/LoadingDialog.js';
import { MovePaymentDateDialog } from '../MovePaymentDateDialog/MovePaymentDateDialog.js';
import { NameChangeConfirmationDialog } from '../NameChangeConfirmationDialog/NameChangeConfirmationDialog.js';
import { NewInterworksAccountDialog } from '../LicenseManagement/NewInterworksAccountDialog/NewInterworksAccountDialog.js';
import { OnboardEmployeeDialog } from '../OnboardEmployeeDialog/OnboardEmployeeDialog.js';
import { ReviewOnlineOrderDialog } from '../ReviewOnlineOrderDialog/ReviewOnlineOrderDialog.js';
import { RingEmailDialog } from '../RingEmailDialog/RingEmailDialog.js';
import { SelectUsernameDialog } from '../SelectUsernameDialog/SelectUsernameDialog.js';
import { SupportCase } from '../../generated/api/models.js';
import { SupportCaseDialog } from '../SupportCaseDialog/SupportCaseDialog.js';
import { UpsertContactDialog, UpsertContactFields } from '../UpsertContactDialog/UpsertContactDialog.js';
import {
  addSupportCase,
  addSupportCaseFulfilled,
  closeDialog,
  selectUsername,
  upsertContact,
} from '../../selfservice/actions/index.js';
import {
  changeOffer,
  resumeSubscription,
  setDuplicateContact,
} from '../../selfservice/actions/subscriptionActionsActions.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { AccountHeader, Contact, PutSupportCaseResponse } from '../../generated/api/models.js';
import type {
  AddContactDialogParams,
  ChangeOfferDialogParams,
  DayPickerDialogParams,
  DuplicateContactInformationDialogParams,
  GenericConfirmationDialogParams,
  PossibleDuplicateContactInformationDialogParams,
  SubscriptionResumeFromTemporarilyCloseDialogParams,
} from '../../common/types/dialog.js';
import type { BillingAccountsState, CompanyInfoState, DialogState } from '../../common/types/states.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogWrapperButton } from '../DialogWrapper/index.js';
import type { LoadingDialogError } from '../LoadingDialog/LoadingDialog.js';
import type { SetDuplicateContactAction } from '../../selfservice/actions/subscriptionActionsActions.js';
import type { State } from '../../selfservice/common/store.js';

const DuplicateContactDialog = ({
  onCloseDialog,
  onDispatch,
  dialogParams,
  header,
  note,
}: {
  onCloseDialog: () => void;
  onDispatch: () => SetDuplicateContactAction;
  dialogParams: DuplicateContactInformationDialogParams | PossibleDuplicateContactInformationDialogParams;
  header: string;
  note: string;
}) => {
  const buttons: DialogWrapperButton[] = [
    {
      color: 'link',
      onClick: () => {
        onCloseDialog();
      },
      text: t.B2V1('Cancel'),
    },
    {
      color: 'primary',
      onClick: () => {
        onDispatch();
        onCloseDialog();
      },
      text: t.T51P(chooseThisContactMsg),
    },
  ];

  return (
    <DialogWrapper buttons={buttons} onCloseDialog={onCloseDialog} header={header}>
      <p>{note}</p>
      <div>
        <div>
          <strong>{t.VA44('Found contact')}:</strong>
        </div>
        <div>
          {dialogParams.duplicateContact.firstName} {dialogParams.duplicateContact.lastName}
        </div>
        <div>{dialogParams.duplicateContact.email}</div>
        <div>{formatPhoneNumber(dialogParams.duplicateContact.phoneNumber)}</div>
      </div>
    </DialogWrapper>
  );
};

export const createUserRightsInSalesforceDialog = (classicSiteUrl: string) => {
  return (
    <DialogWrapper
      buttons={[
        {
          onClick: () => (window.location.href = classicSiteUrl),
          text: t.SU49('Go to old OmaElisa'),
        },
      ]}
      onCloseDialog={() => {}}
      header={t.B4NE('Operation failed')}
    >
      <p>{t.YV0D('Unfortunately you do not have access to New OmaElisa.')}</p>
      <p>
        {t.GVIF('In case you need access to manage your company´s Elisa Palvelupäätelaite service, please contact')}{' '}
        <a href="https://yrityksille.elisa.fi/yhteystiedot">{t.AP5F('Elisa customer support')}</a>.
      </p>
      <p>
        {t.P9G3('If you are interested in managing your own mobile subscriptions or devices, please log into')}{' '}
        <a href={paths.EMPLOYEE_HOME}>{t.ZTNQ('Employee OmaElisa')}</a>.
      </p>
    </DialogWrapper>
  );
};

interface DialogElementProps {
  dialog: DialogState;
  companyName: string;
  firstName: string;
  activeAccountMasterId: string;
  billingAccounts?: BillingAccountsState;
  companyInfo?: CompanyInfoState | null;
  lastName?: string;
  secondaryAccounts?: AccountHeader[];
  userFeatureFlags?: string[];
}

export const DialogElement = ({ dialog, firstName, lastName }: DialogElementProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const store = useStore<State>();
  const config = store.getState().config;
  const onCloseDialog = () => dispatch(closeDialog());
  const onAddSupportCase = (
    supportCase: SupportCase,
    noSuccessNotification?: boolean,
    showInfoDialog?: boolean,
    successNotificationMsg?: string
  ) => dispatch(addSupportCase(supportCase, noSuccessNotification, showInfoDialog, successNotificationMsg));

  const onAddedSupportCase = (
    response: PutSupportCaseResponse,
    noSuccessNotification?: boolean,
    showInfoDialog?: boolean,
    successNotificationMsg?: string
  ) => dispatch(addSupportCaseFulfilled(response, noSuccessNotification, showInfoDialog, successNotificationMsg));

  useEffect(() => {
    // Close the dialog when location changes.
    return () => {
      dispatch(closeDialog());
    };
  }, [dispatch, pathname]);

  if (dialog?.params?.type && dialog.visible) {
    switch (dialog.params.type) {
      case DialogType.ADD_CONTACT: {
        return (
          <UpsertContactDialog
            errors={dialog.errors}
            header={t.CSTF(addContactMsg)}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={(contact: Contact, validationErrors?: CommonError[]) => {
              dispatch(
                upsertContact(contact, false, false, false, validationErrors, dialog.params as AddContactDialogParams)
              );
            }}
          />
        );
      }
      case DialogType.UPSERT_CONTACT: {
        const params = dialog.params;
        return (
          <UpsertContactDialog
            body={t.ZMK2('Please fill in the missing information.')}
            contact={params.contact}
            disableEditing={[UpsertContactFields.FIRST_NAME, UpsertContactFields.LAST_NAME]}
            errors={dialog.errors}
            header={t.EQK0('Some contact information is missing')}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={() => {
              if (params.onCancel) {
                params.onCancel();
              }
              onCloseDialog();
            }}
            onSubmitDialog={(contact: Contact, validationErrors?: CommonError[]) =>
              dispatch(
                upsertContact(
                  contact,
                  false,
                  false,
                  false,
                  validationErrors,
                  params as unknown as AddContactDialogParams
                )
              )
            }
            saveText={t.QVYK(confirmMsg)}
          />
        );
      }
      case DialogType.UPDATE_CONTACT_DUPLICATE: {
        return <NameChangeConfirmationDialog onCloseDialog={onCloseDialog} errors={dialog.errors} />;
      }
      case DialogType.ASK_ABOUT_INVOICE: {
        return (
          <InvoiceSupportCaseDialog
            invoiceDisplayId={dialog.params.invoiceDisplayId}
            errors={dialog.errors}
            creator={firstName + ' ' + lastName}
            featureName={SupportCase.FeatureEnum.INVOICES}
            featureItemId={dialog.params.invoiceId}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddedSupportCase}
            companyName={dialog.params.companyName}
            accountMasterId={dialog.params.accountMasterId}
          />
        );
      }
      case DialogType.MOVE_PAYMENT_DATE: {
        return (
          <MovePaymentDateDialog
            featureItemId={dialog.params.invoiceId}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddSupportCase}
          />
        );
      }
      case DialogType.DAY_PICKER_DIALOG: {
        return (
          <DialogWrapper closeable onCloseDialog={onCloseDialog} size="s">
            <DayPicker
              disabledDays={dialog.params.isDayDisabled}
              initialMonth={
                new Date(
                  dialog.params.currentlySelectedDate
                    ? dialog.params.currentlySelectedDate.getFullYear()
                    : new Date().getFullYear(),
                  dialog.params.currentlySelectedDate
                    ? dialog.params.currentlySelectedDate.getMonth()
                    : new Date().getMonth()
                )
              }
              fromMonth={new Date()}
              toMonth={new Date(new Date().getFullYear(), new Date().getMonth() + 3)}
              selectedDay={dialog.params.currentlySelectedDate}
              onSelectDay={(date: Date) => {
                (dialog.params as DayPickerDialogParams).onSelectDay(date);
                onCloseDialog();
              }}
            />
          </DialogWrapper>
        );
      }
      case DialogType.SUPPORT_CASE_SENT_INFO_DIALOG: {
        return (
          <DialogWrapper
            buttons={[
              {
                onClick: onCloseDialog,
                text: t.WOYD(closeMsg),
              },
            ]}
            closeable
            header={t.VRVV(inquiryHasBeenSentMsg)}
            onCloseDialog={onCloseDialog}
          >
            <div className={dsClass.MARGIN_TOP_2}>
              {t.YMF3('You can see the progress of your inquiry in ')}
              <a href={paths.SUPPORT_CASES}>{t.H4F0('OmaElisa')}</a>
              {t.JHFF('. We do our best to reply to you within 24 hours on working days.')}
            </div>
          </DialogWrapper>
        );
      }
      case DialogType.GENERIC_INFO_DIALOG: {
        return (
          <DialogWrapper
            buttons={
              dialog.params.hideButtons
                ? []
                : [{ onClick: onCloseDialog, text: dialog.params.buttonText || t.WOYD(closeMsg) }]
            }
            closeable
            header={dialog.params.header}
            onCloseDialog={onCloseDialog}
            size={dialog.params.wide ? 'l' : 'm'}
          >
            <div className={dsClass.PADDING_TOP_4}>{dialog.params.body}</div>
          </DialogWrapper>
        );
      }

      case DialogType.DUPLICATE_CONTACT: {
        return (
          <DialogWrapper
            buttons={[
              {
                onClick: () => {
                  onCloseDialog();
                },
                text: 'OK',
              },
            ]}
            onCloseDialog={onCloseDialog}
            header={dialog.params.header}
          >
            <p>{dialog.params.body}</p>
          </DialogWrapper>
        );
      }

      case DialogType.DUPLICATE_CONTACT_INFORMATION: {
        return (
          <DuplicateContactDialog
            onCloseDialog={onCloseDialog}
            onDispatch={() =>
              dispatch(setDuplicateContact((dialog.params as DuplicateContactInformationDialogParams).duplicateContact))
            }
            dialogParams={dialog.params}
            header={t.USHS(contactAlreadyExistsMsg)}
            note={t.HFUO(contactCanBeFoundInCompanyListingMsg)}
          />
        );
      }

      case DialogType.POSSIBLE_DUPLICATE_CONTACT_INFORMATION: {
        return (
          <DuplicateContactDialog
            onCloseDialog={onCloseDialog}
            onDispatch={() =>
              dispatch(
                setDuplicateContact((dialog.params as PossibleDuplicateContactInformationDialogParams).duplicateContact)
              )
            }
            dialogParams={dialog.params}
            header={t.CVJF(duplicateContactHeaderMsg)}
            note={t.AR17('Is this the user you are looking for? Select the found user or create a new user.')}
          />
        );
      }

      case DialogType.USER_RIGHTS_IN_SALESFORCE: {
        return createUserRightsInSalesforceDialog(config.classicSiteUrl);
      }

      case DialogType.GENERIC_CONFIRMATION_DIALOG: {
        return (
          <ConfirmationDialog
            body={dialog.params.body}
            confirmButtonText={dialog.params.confirmButtonText}
            errors={dialog.errors}
            header={dialog.params.header}
            hideClosingCross={dialog.params.hideClosingCross}
            disableConfirm={dialog.params.disableConfirm}
            isLoading={dialog.submitInProgress}
            onCloseDialog={() => {
              const params = dialog.params as GenericConfirmationDialogParams;
              if (params.onCancel) {
                params.onCancel();
              }
              onCloseDialog();
            }}
            onConfirm={dialog.params.onConfirm}
          />
        );
      }
      case DialogType.ORDER_SIM_CARD_NUMBER_HELP: {
        return (
          <DialogWrapper
            buttons={[{ onClick: onCloseDialog, text: t.WOYD(closeMsg) }]}
            closeable
            header={t.IPFH('Where can I find the SIM-card number?')}
            onCloseDialog={onCloseDialog}
          >
            <>
              <img
                className={dsClass.PADDING_TOP_4}
                src={`${config.imagesBaseUrl || '.'}/7exVmyAO7Cak8ig8i86yWm/sim-card-guide.png?w=420`}
                alt={t.T0FF('Locations of SIM card numbers')}
              />
              <p>
                {t.Z7K0(
                  'You can find the SIM card number in the locations indicated by the image. Restart your device after activating the card. The initial PIN code for the new SIM card is 1234.'
                )}
              </p>
            </>
          </DialogWrapper>
        );
      }
      case DialogType.CHANGE_SUBSCRIPTION_CONTACT: {
        const {
          showOmaLaiteLaskuWarning,
          showVoiceMailWarning,
          subscriptionType,
          type,
          onCancel: dialogParamsOnCancel,
          onConfirm: dialogParamsOnConfirm,
        } = dialog.params;

        return (
          <ChangeSubscriptionContactDialog
            showOmaLaiteLaskuWarning={showOmaLaiteLaskuWarning}
            showVoiceMailWarning={showVoiceMailWarning}
            subscriptionType={subscriptionType}
            type={type}
            onCancel={() => {
              dialogParamsOnCancel();
              onCloseDialog();
            }}
            onConfirm={() => {
              dialogParamsOnConfirm();
              onCloseDialog();
            }}
          />
        );
      }
      case DialogType.CHANGE_NETTI_LITE_SUBSCRIPTION: {
        return <ChangeNettiLiteSubscriptionDialog onCloseDialog={onCloseDialog} />;
      }
      case DialogType.UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION: {
        return <ChangeNettiLiteSubscriptionDialog onCloseDialog={onCloseDialog} isLiikkuvaWifiSubscription={true} />;
      }
      case DialogType.SUBSCRIPTION_CHANGE_SUBSCRIPTION: {
        return (
          <SupportCaseDialog
            action={t.RW9V(updateSubscriptionMsg)}
            body={t.NYY5('State what kind of subscription you need')}
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            featureItemId={dialog.params.subscriptionId}
            header={t.EJPD('Send a message to our sales team')}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddSupportCase}
            placeholder={t.NWSE('Type your need here...')}
          />
        );
      }
      case DialogType.SUBSCRIPTION_TERMINATE_REQUEST: {
        return (
          <SupportCaseDialog
            action={t.M1TW(terminateSubscriptionMsg)}
            body={t.ODY8(
              'You can terminate the subscription by sending a message to our customer service. In your message, tell us to which subscription the termination applies, and when you want the subscription to be deactivated (ongoing contract is terminated within 1 month of termination request). Please note that you will receive a final invoice for a terminated subscription if usage or monthly charges have not yet been invoiced.'
            )}
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            featureItemId={dialog.params.subscriptionId}
            header={t.M1TW(terminateSubscriptionMsg)}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddSupportCase}
            placeholder={t.BK58('Type your message, subscription number, and service termination date here...')}
          />
        );
      }
      case DialogType.SUBSCRIPTION_SUPPORT_REQUEST: {
        return (
          <DialogWrapper
            buttons={[{ onClick: onCloseDialog, text: t.WOYD(closeMsg) }]}
            closeable
            header={t.KFRW('Need help?')}
            onCloseDialog={onCloseDialog}
          >
            <p>
              <I18nTextLinkEmbedded
                translation={t.O7WR('Check the current functionality of the network with the [network assistant].')}
                linkHref="https://elisa.fi/verkkoapuri/"
                linkProps={{
                  rel: 'noreferrer',
                  target: '_blank',
                }}
              />
            </p>
            <p>
              <I18nTextLinkEmbedded
                translation={t.TTM6(
                  `If you have any other questions, you can [send a message]. If you're in a hurry, chat is the fastest way to get help.`
                )}
                linkHref={
                  dialog.params.mdmId
                    ? `${paths.SUPPORT_CASE_NEW}?mdmId=${dialog.params.mdmId}`
                    : paths.SUPPORT_CASE_NEW
                }
                linkProps={{
                  rel: 'noreferrer',
                  target: '_blank',
                }}
              />
            </p>
          </DialogWrapper>
        );
      }
      case DialogType.SUBSCRIPTION_SUBMIT_SUPPORT_CASE: {
        return (
          <SupportCaseDialog
            action={t.RW9V(updateSubscriptionMsg)}
            body={t.GCMD(howCanWeHelpMsg)}
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            featureItemId={dialog.params.subscriptionId}
            header={t.F6H1('Send support request')}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddSupportCase}
            placeholder={t.NKY1(describeYourProblemMsg)}
          />
        );
      }
      case DialogType.NEW_RING_USERNAME: {
        return <RingEmailDialog onCloseDialog={onCloseDialog} params={dialog.params} errors={dialog.errors} />;
      }
      case DialogType.RING_SUBSCRIPTION_UPDATE_USER: {
        return (
          <SupportCaseDialog
            action={t.RW9V(updateSubscriptionMsg)}
            body={t.Y30S(
              changeRingUserBodyMsg,
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg)
            )}
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            featureItemId={dialog.params.subscriptionId}
            header={t.V05V(changeRingUserHeaderMsg)}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddSupportCase}
            placeholder={t.NKY1(describeYourProblemMsg)}
          />
        );
      }
      case DialogType.EPP_DEVICE_CHANGE: {
        return <EppDeviceChangeDialog onCloseDialog={onCloseDialog} params={dialog.params} />;
      }
      case DialogType.EPP_REDEEM: {
        return (
          <EppRedeemDialog
            onCloseDialog={onCloseDialog}
            params={dialog.params}
            errors={dialog.errors}
            submitInProgress={dialog.submitInProgress}
          />
        );
      }
      case DialogType.EPP_REDEEM_REJECT: {
        return (
          <SupportCaseDialog
            action="EPP_REDEEM_REJECT"
            body=""
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            header={t.W692('Confirm redeem rejection request')}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={dialog.params.onRedeemReject}
            placeholder={t.EBNB('Additional info to employee')}
            saveButtonLabel={t.QVYK(confirmMsg)}
          />
        );
      }
      case DialogType.EPP_TERMINATE: {
        return (
          <EppTerminateDialog
            params={dialog.params}
            onCloseDialog={onCloseDialog}
            isLoading={dialog.submitInProgress}
            errors={dialog.errors}
            isEmployee={false}
          />
        );
      }
      case DialogType.SUBSCRIPTION_RESUME_FROM_TEMPORARILY_CLOSE: {
        return (
          <ConfirmationDialog
            header={t.D6Q4('Subscription temporarily deactivated')}
            body={<p>{dialog.params.body}</p>}
            confirmButtonText={t.QV5K(activateSubMsg)}
            onCloseDialog={onCloseDialog}
            isLoading={dialog.submitInProgress}
            onConfirm={() => {
              dispatch(
                resumeSubscription((dialog.params as SubscriptionResumeFromTemporarilyCloseDialogParams).subscriptionId)
              );
            }}
            errors={dialog.errors}
          />
        );
      }
      case DialogType.SUBSCRIPTION_ACTION_PENDING: {
        return (
          <DialogWrapper closeable header={t.I9KT(changeRequestInProcessHeaderMsg)} onCloseDialog={onCloseDialog}>
            {t.T56J(subChangeRequestInProcessMsg)}
          </DialogWrapper>
        );
      }
      case DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION: {
        return (
          <DialogWrapper closeable header={t.LUFX('Activation in progress')} onCloseDialog={onCloseDialog}>
            {t.LZT3('Subscription activation is in progress. Other changes are not possible at the moment.')}
          </DialogWrapper>
        );
      }
      case DialogType.SUBSCRIPTION_TERMINATION_FORBIDDEN: {
        return (
          <DialogWrapper
            closeable
            header={t.IX1T('Fixed term subscription cannot be terminated.')}
            onCloseDialog={onCloseDialog}
          >
            {t.IWHO(
              'Subscription is fixed term until {} and it cannot be terminated before that.',
              dialog.params.commitmentEndDate
            )}
          </DialogWrapper>
        );
      }
      case DialogType.SUBSCRIPTION_CHANGE_SPECIAL_NUMBER: {
        return (
          <SupportCaseDialog
            action={t.RW9V(updateSubscriptionMsg)}
            body={t.HHOH('State what changes do you need?')}
            errors={dialog.errors}
            featureName={SupportCase.FeatureEnum.SUBSCRIPTIONS}
            featureItemId={dialog.params.subscriptionId}
            header={t.OU0R('Make a change request')}
            isSubmitInProgress={dialog.submitInProgress}
            onCloseDialog={onCloseDialog}
            onSubmitDialog={onAddSupportCase}
            placeholder={t.VQU7('Type your change request here...')}
          />
        );
      }
      case DialogType.SELECT_USERNAME: {
        const usernames = dialog.params.usernames;
        return (
          <SelectUsernameDialog
            onCloseDialog={onCloseDialog}
            usernames={usernames}
            onContinue={selected =>
              dispatch(
                selectUsername({
                  userName: selected,
                })
              )
            }
            errors={dialog.errors}
            isLoading={dialog.submitInProgress}
          />
        );
      }
      case DialogType.SUBMIT_ORDER: {
        let loadingDialogError: LoadingDialogError | undefined;
        if (dialog.errors && dialog.errors.length > 0) {
          const dialogError = dialog.errors[0];
          loadingDialogError = {
            buttonAction: () => {
              navigate(paths.SELF_SERVICE_HOME);
            },
            buttonText: t.YU2R(returnToOmaElisaMsg),
            header:
              dialogError.type === CommonErrorType.SYSTEM
                ? t.V0VJ('Oops, something went wrong')
                : t.N3V5('What a shame'),
            message: t.STWG(
              problemWithProcessingOrderMsg,
              customerServiceNumberLabelMsg,
              t.HQWW(customerServiceNumberPriceMsg),
              t.HQWV(customerServiceNumberOpenLongMsg)
            ),
          };
        }
        return <LoadingDialog error={loadingDialogError} loadingText={t.RW6O(processingOrderMsg)} />;
      }
      case DialogType.CHANGE_SIM_CARD_CONFIRMATION: {
        return (
          <ChangeSimCardConfirmDialog
            category={dialog.params.category}
            simCardNumber={dialog.params.simCardNumber}
            subscriptionDisplayId={dialog.params.subscriptionDisplayId}
            subscriptionId={dialog.params.subscriptionId}
            onCloseDialog={onCloseDialog}
            isLoading={dialog.submitInProgress}
            errors={dialog.errors}
          />
        );
      }
      case DialogType.REVIEW_ONLINE_ORDER: {
        return (
          <ReviewOnlineOrderDialog
            onlineOrderReview={dialog.params.onlineOrderReview}
            customerOrder={dialog.params.customerOrder}
            approve={dialog.params.approved}
            onCloseDialog={onCloseDialog}
            errors={dialog.errors}
            ordererEmail={dialog.params.ordererEmail}
            isLoading={dialog.submitInProgress}
          />
        );
      }
      case DialogType.ONBOARD_EMPLOYEE: {
        return <OnboardEmployeeDialog errors={dialog.errors} isLoading={dialog.submitInProgress} />;
      }
      case DialogType.BANK_BUTTONS: {
        return (
          <BankButtonsDialog
            errors={dialog.errors}
            onCloseDialog={onCloseDialog}
            invoiceId={dialog.params.invoiceId}
            balance={dialog.params.balance}
          />
        );
      }
      case DialogType.CHANGE_OFFER: {
        const params = dialog.params as ChangeOfferDialogParams;

        const modalTitle =
          params.addOnsToRemoveDisplayed.length > 0
            ? t.MY67('These add-ons will be removed when updating subscription')
            : t.WISG('Changes will take effect in few minutes.');

        return (
          <ConfirmationDialog
            header={modalTitle}
            body={
              <ChangeOfferConfirmationDialogContent
                addOnsToRemoveDisplayed={params.addOnsToRemoveDisplayed}
                newProductMonthlyRecurringCharge={params.newProductMonthlyRecurringCharge}
              />
            }
            onCloseDialog={onCloseDialog}
            isLoading={dialog.submitInProgress}
            onConfirm={() => {
              dispatch(
                changeOffer(
                  params.subscriptionId,
                  params.subscriptionDisplayId,
                  params.subscriptionType,
                  params.commercialProductCode,
                  params.addOnsToAdd.map(addOn => addOn.addOnCode),
                  params.addOnsToRemove.map(addOn => addOn.addOnCode),
                  params.addOnsToUpdate.map(addOn => addOn.addOnCode),
                  params.campaignAssociationCode
                )
              );
              onCloseDialog();
            }}
            errors={dialog.errors}
          />
        );
      }
      case DialogType.CHANGE_OFFER_NOT_AVAILABLE: {
        const body: JSX.Element = (
          <div>
            <p>
              {t.OTG1('Updating subscription is not currently available in OmaElisa because it has commitment period.')}
            </p>
            <p>
              {t.FA9S(
                'Our sales department can update subscription for you. Please call 0800 04411 (weekdays at 8:00-16:00)'
              )}
            </p>
          </div>
        );
        return (
          <DialogWrapper
            buttons={[{ onClick: onCloseDialog, text: t.WOYD(closeMsg) }]}
            closeable
            header={t.C49W('Subscription update')}
            onCloseDialog={onCloseDialog}
          >
            {body}
          </DialogWrapper>
        );
      }

      case DialogType.DETACH_RING: {
        return <DetachRingConfirmationDialog subscription={dialog.params.subscription} onCloseDialog={onCloseDialog} />;
      }
      case DialogType.SENDING_ONBOARDING_REQUEST: {
        return <LoadingDialog loadingText={t.N1WI('Sending')} />;
      }
      case DialogType.BB_SUB_EMPLOYEE_INVITATION: {
        return (
          <BbEmployeeInvitationDialog
            contactPerson={dialog.params.contactPerson}
            onCloseDialog={onCloseDialog}
            subscriptionId={dialog.params.subscriptionId}
          />
        );
      }
      case DialogType.BB_SUB_EMPLOYEE_NOT_FOUND: {
        return <BbEmployeeNotFoundDialog onCloseDialog={onCloseDialog} />;
      }
      case DialogType.NEW_INTERWORKS_ACCOUNT_DIALOG: {
        return (
          <NewInterworksAccountDialog
            onCloseDialog={onCloseDialog}
            billChannels={dialog.params.billChannels}
            accountMasterId={dialog.params.accountMasterId}
          />
        );
      }
    }
  }
  return null;
};

export const DialogContainer = () => {
  const { authenticatedUser } = useAuth();
  const { companyInfo, dialog } = useSelector((state: State) => {
    return {
      companyInfo: state.selfservice?.companyInfo,
      dialog: state.dialog,
    };
  }, deepEqual);

  if (!dialog) {
    return null;
  }

  return (
    <DialogElement
      dialog={dialog!}
      companyName={authenticatedUser?.companyName || ''}
      firstName={authenticatedUser?.firstName || ''}
      lastName={authenticatedUser?.lastName || ''}
      companyInfo={companyInfo}
      activeAccountMasterId={authenticatedUser?.activeAccountMasterId || authenticatedUser?.mdmId || ''}
      secondaryAccounts={authenticatedUser?.secondaryAccounts}
      userFeatureFlags={authenticatedUser?.enabledFeatureFlags}
    />
  );
};
