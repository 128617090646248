import * as CL from '@design-system/component-library';
import { type Context } from '../../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { Table } from '../../OpenFormHooks/useTable.js';
import { setValueOptions } from '../../OpenFormHooks/useOpenFormMethods.js';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../../generated/api/openFormChoice.js';

export const OpenFormQuestionSelectGroup = ({
  disabled,
  required,
  label,
  name,
  multiselect,
  choices,
  context,
  available,
}: {
  disabled: boolean;
  required: boolean;
  label: string;
  name: string;
  multiselect: boolean;
  choices: OpenFormChoice[];
  context: Context | undefined;
  available: (guid: string) => boolean;
}) => {
  const { setValue } = useFormContext();
  const inactive = useCallback((guid: string) => disabled || !available(guid), [available, disabled]);
  const set = useMemo(() => new Set(context?.choices ?? []), [context]);
  const sorted = useMemo(
    () =>
      choices
        .map(({ guid, values }) => ({ availability: available(guid), guid, values }))
        .sort(Table.compare('availability', 'desc')),
    [choices, available]
  );

  return !sorted.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        <CL.SelectGroup
          key={name}
          label={label}
          type="column"
          multiselect={true}
          value={Array.from(set)}
          products={sorted.map(({ availability, guid, values }) => ({
            active: set.has(guid),
            availability: availability,
            disabled: inactive(guid),
            id: guid,
            name: values.join('\n'),
          }))}
          onClick={({ target: { value } }) => {
            if (!value || inactive(value)) {
              return;
            } else if (set.has(value)) {
              set.delete(value);
            } else {
              !multiselect && set.clear();
              set.add(value);
            }
            setValue(name, Array.from(set), setValueOptions);
          }}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
