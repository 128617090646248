// Clamps a number in the range [min, max]
export const clampNumber = (x: number, min: number, max: number) => {
  if (min > max) {
    throw Error(`min must not be greater than max; x: ${x}; min: ${min}; max: ${max}`);
  }
  return Math.min(Math.max(x, min), max);
};

export const calculateTotalPages = (itemsPerPage: number, totalItems: number) =>
  totalItems > 0 ? Math.trunc((totalItems - 1) / itemsPerPage) + 1 : 1;

export const randomIntFromInterval = (min: number, max: number) => {
  // min and max are included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
