// Service names, the same name is used in both languages
export enum MobileBroadbandService {
  LIIKKUVA_WIFI = 'Liikkuva Wifi',
  YRITYSDATA = 'Yritysdata',
}

export enum LiikkuvaWifiOfferCode {
  MBB_1000_M = '19e40786-04a7-e704-f5c5-41c933b7a1de',
  MBB_600_M = 'e212db9f-7c82-a86a-87b5-4c5babc1125e',
  MBB_300_M = '71603324-6b7c-5125-5434-3062a310753f',
}

export enum LaitenettiOfferCode {
  MOBILE_M2M_OFFER_CODE = '9be7de49-3191-b932-127e-811c67ae245f',
  MOBILE_M2M_OFFER_CODE_5G = 'b1ff488a-9672-22c0-f9db-bcd7ec64a4ca',
}

export enum SalesProductOnlineModel {
  LIIKKUVA_WIFI_1000M = '34be8251-cfb2-bccd-f0aa-55d7c1e5931b',
  LIIKKUVA_WIFI_600M = '33b1af50-25d2-9ced-5157-38897cfd08d0',
  LIIKKUVA_WIFI_300M = 'a35c4c26-275b-749e-826b-32990a3c0bd9',
}

export enum SalesProductLiikkuvaWifiOfferCodes {
  LIIKKUVA_WIFI_1000M = '7281900c-74b6-e486-2c5a-d8b89b7c377f',
  LIIKKUVA_WIFI_600M = 'c25244a0-a2cc-9dd0-5db2-0b05c2cd5f7c',
  LIIKKUVA_WIFI_300M = '5e26182a-6f59-bd12-20a4-7716da4a1b7d',
}

export enum SalesProductOpeningFeeCpCodes {
  YRITYS_DATA = '89eb5aaf-622f-623a-3925-b36cf9328b7e',
  LIIKKUVA_WIFI = '0fbba701-9854-58eb-40ff-5ed94e917376',
}
