import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { EppSolutionResponse } from '../../../../../generated/api/eppSolutionResponse';
import { Reports } from '../../../../../components/Reports/Reports.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getUserAccounts } from '../../../../../components/Header/dynamic/headerFunctions';
import { useAuth } from '../../../AuthProvider';
import { useLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { AccountSubset } from '../../../../../components/Reports/Reports.js';
import type { ReportsLoaderData } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store.js';
import EppSolutionStatusEnum = EppSolutionResponse.EppSolutionStatusEnum;
import { getPrimaryMdmId } from '../../../../../common/utils/accountUtils';

export const CompanyInfoReportsPath = () => {
  const latestReportStatusChange = useSelector(
    (state: State) => state.selfservice?.reports?.latestReportStatusChange,
    deepEqual
  );
  const { authenticatedUser } = useAuth();
  const { companyInfo, reports, secondaryAccounts } = useLoaderData() as ReportsLoaderData;
  const userAccounts = getUserAccounts(authenticatedUser);
  const accountSubsets = [
    {
      companyName: companyInfo.companyName,
      eppSolutionActive: companyInfo.eppSolution?.eppSolutionStatus === EppSolutionStatusEnum.ACTIVE || false,
      mdmId: getPrimaryMdmId(authenticatedUser),
    },
    ...secondaryAccounts
      .map(secondaryAccount => ({
        companyName: userAccounts.find(acc => acc.accountMasterId === secondaryAccount.accountMasterId)?.name || '',
        eppSolutionActive: secondaryAccount.eppSolution?.eppSolutionStatus === EppSolutionStatusEnum.ACTIVE || false,
        mdmId: secondaryAccount.accountMasterId,
      }))
      .filter(acc => acc.companyName),
  ] as AccountSubset[];

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.REPORTS}>
      <Reports
        accounts={accountSubsets}
        authenticatedUser={authenticatedUser}
        latestReportStatusChange={latestReportStatusChange}
        reports={reports}
      />
    </CompanyInfoContainer>
  );
};
