import { Status } from '../Status/Status.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCatalogModificationStatus } from '../../common/utils/catalogUtils.js';
import type { VirtualCatalogStatus } from '../../generated/api/virtualCatalogStatus.js';

interface ModificationStatusProps {
  status: VirtualCatalogStatus;
  noSubtext?: boolean;
  inTable?: boolean;
}

export const ModificationStatus = (props: ModificationStatusProps) => {
  const { status, noSubtext, inTable } = props;
  const { color, text, subtext } = getCatalogModificationStatus(status);

  return (
    <Status
      color={color}
      textAfterBadge={
        <>
          <div>{text}</div>
          {!noSubtext && subtext && <div className={dsClass.FONT_SIZE_SMALLER}>{subtext}</div>}
        </>
      }
      inTable={inTable}
    />
  );
};
