import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import { CatalogConfiguration } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper';
import {
  deviceListsMsg,
  listOfDevicesMsg,
  newDeviceListMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n';
import { isMultiBiz } from '../../../../../common/utils/accountUtils';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import type { CatalogConfigurationLoaderData } from '../../../../../components/CatalogConfiguration/CatalogConfigurationForm';

export const CompanyInfoCatalogListNewPath = () => {
  const { companyInfo } = useLoaderData() as CatalogConfigurationLoaderData;

  const { authenticatedUser } = useAuth();
  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: t.MPFC(newDeviceListMsg) },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-catalog-configuration']}
      detailsTop={breadCrumbs}
      id="catalog-configuration"
      heading={t.Z0HF(listOfDevicesMsg)}
      headingBottom={isMultiBiz(authenticatedUser) ? undefined : companyInfo?.companyName}
      heroPicto="catalog"
    >
      <CatalogConfiguration companyInfo={companyInfo} user={authenticatedUser} />
    </DetailsWrapper>
  );
};
