import { CommercialProductType } from '../../generated/api/commercialProductType';
import { deepEqual } from '../../common/utils/objectUtils';
import {
  getShoppingBasketFromLocalStorage,
  handleShoppingBasketUpdate,
  parseBasketJson,
} from '../../common/hooks/useShoppingBasket';
import type { BasketItem, ShoppingBasketType } from '../../common/types/shoppingBasket';
import type { CartItemContainer } from '../../components/ProductDetails/ProductDetails';
import type { CommercialProduct } from '../../generated/api/commercialProduct';

const generateUniqueId = (existingItems: ShoppingBasketType): string => {
  const newId = crypto.randomUUID();
  // Probability is near zero for collision, but handle anyways..
  if (existingItems.items?.find(existingItem => existingItem.id === newId)) {
    return generateUniqueId(existingItems);
  } else {
    return newId;
  }
};

export const areItemsEqualInBasket = (newBasketItem: BasketItem, existingBasketItem: BasketItem) => {
  return (
    existingBasketItem.guid === newBasketItem.guid &&
    existingBasketItem.offer.guid === newBasketItem.offer.guid &&
    existingBasketItem.offer.commercialProductGuid === newBasketItem.offer.commercialProductGuid &&
    deepEqual(existingBasketItem.offer.addOns, newBasketItem.offer.addOns)
  );
};

export const addToShoppingBasket = (basketItem: CartItemContainer, sendShoppingBasketToApi: boolean) => {
  const shoppingBasketJson: ShoppingBasketType = parseBasketJson(getShoppingBasketFromLocalStorage());

  const isSalesProduct = basketItem.commercialProduct.productType === CommercialProductType.SALES_PRODUCT;
  const spCpWithOnetimeCharge = basketItem.offer.commercialProducts.find(field => 'oneTimeCharge' in field);
  const spCpWithMonthlyRecurringCharge = basketItem.offer.commercialProducts.find(
    field => 'monthlyRecurringCharge' in field
  );

  const getOneTimeCharges = (commercialProduct?: CommercialProduct) => {
    return commercialProduct?.oneTimeCharge;
  };

  const getMonthlyRecurringCharges = (commercialProduct?: CommercialProduct) => {
    return {
      periodicPriceInCents: commercialProduct?.monthlyRecurringCharge,
      payments: commercialProduct?.payments,
      period: commercialProduct?.billingPeriod || 1, // Billing period in months, usually not available so default to 1
    };
  };

  const oneTimePriceInCents = getOneTimeCharges(isSalesProduct ? spCpWithOnetimeCharge : basketItem.commercialProduct);
  const monthlyRecurringCharge = getMonthlyRecurringCharges(
    isSalesProduct ? spCpWithMonthlyRecurringCharge : basketItem.commercialProduct
  );

  const offerItem = {
    guid: basketItem.offer.offerCode,
    addOns: basketItem.selectedAddOnBundles.map(addOn => addOn.addOnCode),
    commercialProductGuid: basketItem.commercialProduct.commercialProductCode || '',
    oneTimePriceInCents: oneTimePriceInCents,
    periodicPrice: monthlyRecurringCharge,
  };

  const newItem: BasketItem = {
    id: generateUniqueId(shoppingBasketJson),
    guid: basketItem.onlineModel.onlineModelCode,
    name: basketItem.offer.offerName,
    quantity: basketItem.quantity,
    offer: offerItem,
    imageUrl:
      (basketItem.offer.images && basketItem.offer.images.length > 0 && basketItem.offer.images[0]) ||
      basketItem.onlineModel.listingImage ||
      '',
    pageUrl: basketItem.onlineModel.pagePath,
  };

  const existingBasketItem = shoppingBasketJson.items?.find(existingItem =>
    areItemsEqualInBasket(newItem, existingItem)
  );
  if (existingBasketItem) {
    existingBasketItem.quantity += newItem.quantity;
  } else {
    shoppingBasketJson.items = [...(shoppingBasketJson.items || []), newItem];
  }

  handleShoppingBasketUpdate(JSON.stringify(shoppingBasketJson), sendShoppingBasketToApi);
};
