import * as CL from '@design-system/component-library';
import { DetailsWrapper } from '../../DetailsWrapper';
import { DialogType } from '../../../common/enums.js';
import { SiteContext } from '../../../public/site/SiteContext.js';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
  type SubscriptionDetailsProps,
} from '../../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionDetailsButtonType } from '../../SubscriptionDetails/subscriptionDetailsButtons.js';
import { SubscriptionStatusType } from '../../../generated/api/subscriptionStatusType.js';
import { SupportCase } from '../../../generated/api/supportCase.js';
import { TerminateNettiLiteDialog } from './TerminateNettiLiteDialog.js';
import { createSupportCaseAuthenticated } from '../../../common/fetch.js';
import {
  customFormErrorFailedMsg,
  getLocale,
  supportRequestMsg,
  t,
  terminateSubscriptionMsg,
  yourMessageWasSentMsg,
} from '../../../common/i18n';
import { formatTimestampToUTCDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { getSubscriptionDetailsBroadband } from '../../SubscriptionDetails/subscriptionDetailsBroadband.js';
import {
  getSubscriptionStatus,
  isCommitmentPeriodActive,
  isExternalSubscription,
  openSubscriptionDialog,
} from '../../../common/utils/subscriptionUtils.js';
import { startBroadbandFixer, startNotification } from '../../../selfservice/actions';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { DialogParams } from '../../../common/types/dialog.js';
import type { Subscription } from '../../../generated/api/subscription.js';
import type { TerminateNettiLiteForm } from './TerminateNettiLiteDialog.js';

interface SubscriptionBroadbandNettiLiteProps extends SubscriptionDetailsProps {
  mdmId?: string;
  subscription: Subscription;
  onShowDialog: (params: DialogParams) => void;
  onResumeSubscription: (subscriptionId: string) => void;
}

const NettiLiteDetails = ({
  addOnRules,
  addOnRulesMobilePbx,
  billingAccounts,
  category,
  companyInfo,
  contacts,
  subscription,
  pendingSubscriptionActions,
  onShowDialog,
  onResumeSubscription,
  mdmId,
}: SubscriptionBroadbandNettiLiteProps) => {
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const subscriptionId = subscription.subscriptionId;
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const [isTerminateDialogVisible, setIsTerminateDialogVisible] = useState(false);
  const [isSubmittingSupportCase, setIsSubmittingSupportCase] = useState(false);

  const onShowTerminateSubscriptionDialog = () => {
    if (!onShowDialog) {
      return;
    }
    if (subscriptionStatus.pendingActions) {
      onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
    } else if (subscriptionStatus) {
      setIsTerminateDialogVisible(true);
    }
  };

  const onTerminateSubscription = async (values: TerminateNettiLiteForm) => {
    setIsSubmittingSupportCase(true);
    const fixedPeriodMessage = isCommitmentPeriodActive(subscription.details?.commitmentEndDate)
      ? `Määräaikaisuus ${formatTimestampToUTCDDMMYYYY(subscription.details?.commitmentEndDate)} asti`
      : '';
    const description = [
      'Yhteydenottopyyntö Netti Lite -liittymän irtisanomiseksi',
      `Tunnistenumero: ${subscription.subscriptionDisplayId}`,
      fixedPeriodMessage,
      `Osoite: ${subscription.details?.broadband?.installationAddress ?? '-'}\n`,
      `Soittopyynnön puhelinnumero: ${values.msisdn}`,
      `Viesti asiakkaalta:`,
      `${values.message}`,
    ].join('\n');

    try {
      await createSupportCaseAuthenticated(
        {
          feature: SupportCase.FeatureEnum.SUBSCRIPTIONS,
          action: 'Netti Lite -liittymän irtisanominen, yhteydenottopyyntö',
          featureItemId: subscription?.subscriptionId,
          url: `${siteBaseUrl}${pathname}${search}`,
          description,
        },
        mdmId ?? ''
      );
      dispatch(startNotification(t.Q61D(yourMessageWasSentMsg), 'success'));
    } catch (err) {
      dispatch(startNotification(t.DLRT(customFormErrorFailedMsg), 'error'));
    } finally {
      setIsSubmittingSupportCase(false);
      setIsTerminateDialogVisible(false);
    }
  };

  return (
    <div className="of-subscription-details__content of-subscription-details__content--broadband">
      {isTerminateDialogVisible && (
        <TerminateNettiLiteDialog
          isSubmitting={isSubmittingSupportCase}
          onCloseDialog={() => setIsTerminateDialogVisible(false)}
          fixedTermEndDate={subscription.details?.commitmentEndDate}
          onTerminateNettiLiteSubscription={onTerminateSubscription}
        />
      )}
      {getSubscriptionDetailsBroadband(
        onShowDialog,
        subscription,
        subscriptionStatus,
        onResumeSubscription,
        subscription.details,
        <SubscriptionDetailsBillingAccount
          billingAccounts={billingAccounts}
          subscription={subscription}
          pendingSubscriptionActions={pendingSubscriptionActions}
        />
      )}
      <div className="of-subscription-details__actions">
        <CL.Button
          onClick={() => {
            if (onShowDialog && subscriptionStatus) {
              openSubscriptionDialog(subscriptionStatus, onShowDialog, {
                type: DialogType.SUBSCRIPTION_SUPPORT_REQUEST,
                mdmId,
              });
            }
          }}
          size="l"
          color="link"
          key={SubscriptionDetailsButtonType.SUPPORT_REQUEST}
        >
          <CL.Icon icon="maintenance" />
          <>{t.ROBH(supportRequestMsg)}</>
        </CL.Button>

        {!isExternalSubscription(subscription) && (
          <CL.Button
            onClick={onShowTerminateSubscriptionDialog}
            size="l"
            color="link"
            key={SubscriptionDetailsButtonType.TERMINATE_VIA_DIALOG}
          >
            <CL.Icon icon="power" />
            <>{t.M1TW(terminateSubscriptionMsg)}</>
          </CL.Button>
        )}

        {!isExternalSubscription(subscription) && (
          <CL.Button
            onClick={() => dispatch(startBroadbandFixer(subscriptionId, getLocale(), 'NOE'))}
            size="l"
            color="link"
            disabled={subscription.subscriptionStatus !== SubscriptionStatusType.ACTIVE}
            key={SubscriptionDetailsButtonType.BROADBAND_FIXER}
          >
            <CL.Icon icon="wifi" />
            <>{t.OTF5('Connection problems?')}</>
          </CL.Button>
        )}
      </div>
      <SubscriptionDetailsAccordions
        addOnRules={addOnRules}
        addOnRulesMobilePbx={addOnRulesMobilePbx}
        category={category}
        contacts={contacts}
        companyInfo={companyInfo}
        pendingSubscriptionActions={pendingSubscriptionActions}
        subscription={subscription}
      />
    </div>
  );
};

export const SubscriptionBroadbandNettiLite = ({
  addOnRules,
  addOnRulesMobilePbx,
  billingAccounts,
  breadCrumbs,
  category,
  companyInfo,
  contacts,
  subscription,
  pendingSubscriptionActions,
  onShowDialog,
  onResumeSubscription,
  mdmId,
}: SubscriptionBroadbandNettiLiteProps) => {
  const subscriptionId = subscription.subscriptionId;
  const companyName = companyInfo?.companyName;

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionUserFriendlyId || subscription.subscriptionPurposeOfUse || ''}
      headingBottom={companyName}
      headingTop={t.VI0P('Broadband')}
      heroPicto={subscription && 'fixedbb'}
      id={`subscription-details-${subscriptionId}`}
    >
      <NettiLiteDetails
        addOnRules={addOnRules}
        addOnRulesMobilePbx={addOnRulesMobilePbx}
        billingAccounts={billingAccounts}
        category={category}
        companyInfo={companyInfo}
        contacts={contacts}
        subscription={subscription}
        pendingSubscriptionActions={pendingSubscriptionActions}
        onShowDialog={onShowDialog}
        onResumeSubscription={onResumeSubscription}
        mdmId={mdmId}
      />
    </DetailsWrapper>
  );
};
