/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Report type
 */
export type ReportType = 'devices_and_accessories_report' | 'epp_device_mobile_and_pc_report' | 'epp_device_lifecycle_report' | 'epp_device_leasing_liabilities_report';

export const ReportType = {
    devices_and_accessories_report: 'devices_and_accessories_report' as ReportType,
    epp_device_mobile_and_pc_report: 'epp_device_mobile_and_pc_report' as ReportType,
    epp_device_lifecycle_report: 'epp_device_lifecycle_report' as ReportType,
    epp_device_leasing_liabilities_report: 'epp_device_leasing_liabilities_report' as ReportType
};

