import '@design-system/component-library/src/main.css';
import { AuthProvider } from './AuthProvider.js';
import { CookieConsentWrapper } from './components/CookieConsentWrapper.js';
import { Footer } from '../../components/Footer/Footer.js';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PrivateOnlineModelContextProvider } from './privateOnlineModel/privateOnlineModelContextProvider';
import { SUPPORTED_SITE_LANGUAGES } from './siteUtils.js';
import { ScrollToTop } from '../../common/utils/browserUtils.js';
import { SiteContext } from './SiteContext.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getLocale, resolveLocaleFromPath, setLocale, setSiteLocale, strToLocale } from '../../common/i18n';
import { initializeActiveAccountChangeListener, initializeShoppingCart } from '../../selfservice/init.js';
import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHeadJsonLd } from '../../common/hooks/useHeadJsonLd.js';
import { useLegacyStyles } from '../../common/hooks/useLegacyStyles.js';
import type { Locale } from '../../common/i18n';
import type { State } from '../../selfservice/exports.js';

export const SiteFn = () => {
  const dispatch = useDispatch();
  const store = useStore<State>();
  const layoutState = useSelector((state: State) => state.layout, deepEqual);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const featureFlags = useSelector((s: State) => s.config.featureFlags, deepEqual);

  const [siteLanguage, setSiteLanguage] = useState<Locale>(() => {
    const initialLanguage = searchParams.get('language');
    return initialLanguage ? strToLocale(initialLanguage) : getLocale();
  });

  const saveUserPreferredLocale = (locale: Locale) => {
    setLocale(locale);
    setSiteLanguage(locale);
  };

  useEffect(() => {
    const locale = resolveLocaleFromPath(pathname, siteLanguage);
    if (searchParams.get('language')) {
      setLocale(siteLanguage);
      searchParams.delete('language');
      setSearchParams(searchParams, { replace: true });
    }

    if (locale) {
      setSiteLanguage(locale.language);
      setSiteLocale(locale.content);
    }
  }, [pathname, siteLanguage, searchParams, setSearchParams]);

  type Language = 'fi' | 'en' | 'sv';
  const saveUserPreferredLanguage = (language: Language) => {
    saveUserPreferredLocale(SUPPORTED_SITE_LANGUAGES[language]);
  };

  const switchPreferredLanguage = (language: Language) => {
    if (language === 'fi') {
      navigate('/');
    } else {
      navigate(`/${language}`);
    }
    saveUserPreferredLanguage(language);
  };

  useEffect(() => {
    initializeActiveAccountChangeListener(dispatch);
  }, [dispatch]);

  useEffect(() => {
    initializeShoppingCart(store);
  }, [store]);

  const isOverrideOn = useLegacyStyles();
  useHeadJsonLd();

  return (
    <SiteContext.Provider
      value={{
        switchPreferredLanguage,
        saveUserPreferredLanguage,
        saveUserPreferredLocale,
        siteBaseUrl: store.getState().config.siteBaseUrl,
        siteLanguage,
      }}
    >
      <AuthProvider>
        <PrivateOnlineModelContextProvider initialData={{}}>
          <main className={isOverrideOn ? 'override-enabled' : ''}>
            <ScrollToTop />
            <CookieConsentWrapper />
            <div className="of-site">
              <Outlet key={siteLanguage} /* TODO: key needs to be removed when the i18n is cleaned up. */ />
            </div>
          </main>
          {!layoutState?.hideHeaderFooter && (
            <Footer isMaintenanceModeForCmsPages={isStrictFeatureEnabled(featureFlags.isMaintenanceModeForCmsPages)} />
          )}
        </PrivateOnlineModelContextProvider>
      </AuthProvider>
    </SiteContext.Provider>
  );
};
