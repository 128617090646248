import { EmployeeLoginPage } from '../../EmployeeLoginPage/EmployeeLoginPage';
import { LanguageSelector } from '../LanguageSelector.js';
import { SiteContext } from '../../../public/site/SiteContext.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useContext } from 'react';

export interface AuxiliaryEsimOrderLoginProps {
  onElisaIdLoginSuccess: (token: string) => void;
  startElisaIdV2Login: (locale: string) => void;
}

export const AuxiliaryEsimOrderLogin = ({ startElisaIdV2Login }: AuxiliaryEsimOrderLoginProps) => {
  const { siteLanguage, saveUserPreferredLocale } = useContext(SiteContext);

  return (
    <>
      <LanguageSelector onChangeLocale={saveUserPreferredLocale} selectedLocale={siteLanguage} />
      <div id="elisaid-login-form-parent" className={`${dsClass.BACKGROUND_COLOR_WHITE} of-login-form`}>
        <EmployeeLoginPage
          initiateLogin={() => startElisaIdV2Login(siteLanguage.substring(0, 2))}
          isAuxiliarySimLogin={true}
        />
      </div>
    </>
  );
};
