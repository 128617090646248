import type { LoaderFunctionArgs } from 'react-router-dom';

/**
 * If there are duplicate keys in URLSearchParams: primary URLSearchParams value will be preferred
 */
const combineURLSearchParams = (primaryParams: URLSearchParams, secondaryParams: URLSearchParams) => {
  const urlSearchParams = new URLSearchParams({
    ...Object.fromEntries(secondaryParams),
    ...Object.fromEntries(primaryParams),
  });
  const url = urlSearchParams.toString();
  return url ? `?${url}` : '';
};

export const createRedirectURL = (args: LoaderFunctionArgs, res: Response) => {
  try {
    const headerURL = new URL(res.headers.get('Location') || '');
    const clientURL = new URL(args.request.url);
    const search = combineURLSearchParams(headerURL.searchParams, clientURL.searchParams);
    return `${headerURL.origin}${headerURL.pathname}${search}`;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMdmIdFromUrlParams = () => new URLSearchParams(window.location.search).get('mdmId') || undefined;
