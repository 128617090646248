import { Outlet } from 'react-router-dom';
import { Subscription } from '../../generated/api/subscription';
import { SubscriptionCategory } from '../../common/enums';
import { SubscriptionType } from '../../generated/api/subscriptionType';
import { createContext, useContext, useEffect, useState } from 'react';
import { fetchEmployeeSubscriptions, fetchOmalaskuStatus } from '../../common/fetch';
import { getEmployeeSubscriptionType } from '../../public/common/util/category';
import { useAuth } from '../../public/site/AuthProvider';
import type { OmalaskuStatusWithMsisdnResponse } from './omalaskuUtils';

interface OmaLaskuProps {
  omalaskuSubsStatuses?: OmalaskuStatusWithMsisdnResponse[];
  msisdns?: string[];
  resetOmalaskuStatus: () => void;
  firstName: string;
  lastName: string;
}

const defaultValues: OmaLaskuProps = {
  omalaskuSubsStatuses: undefined,
  msisdns: undefined,
  resetOmalaskuStatus: () => {},
  firstName: '',
  lastName: '',
};

export const OmalaskuContext = createContext(defaultValues);

export const Omalasku = () => {
  const [voiceMsisdns, setVoiceMsisdns] = useState<string[]>();
  const [subscriptionFetchFailed, setSubscriptionFetchFailed] = useState(false);
  const [omalaskuStatuses, setOmalaskuStatuses] = useState<Array<OmalaskuStatusWithMsisdnResponse>>();
  const { authenticatedUser } = useAuth();
  const firstName = authenticatedUser?.firstName || '';
  const lastName = authenticatedUser?.lastName || '';

  useEffect(() => {
    // fetch subscriptions from sfdc
    if (!voiceMsisdns) {
      const query = {
        details: true,
        subscriptionType: getEmployeeSubscriptionType(SubscriptionCategory.VOICE),
      };
      void fetchEmployeeSubscriptions(query)
        .then(response => {
          const filteredVoiceMsisdns = response.subscriptions
            ?.filter(
              sub =>
                sub.subscriptionUserFriendlyId &&
                sub.subscriptionType === SubscriptionType.MOBILE &&
                sub.sourceSystem === Subscription.SourceSystemEnum.TELLUS
            )
            .map(sub => sub.subscriptionUserFriendlyId!);
          setVoiceMsisdns(filteredVoiceMsisdns || []);
        })
        .catch(() => setSubscriptionFetchFailed(true));
    }

    // fetch omalasku status for tellus subscriptions
    if (voiceMsisdns && !omalaskuStatuses) {
      Promise.all(voiceMsisdns.map(msisdn => fetchOmalaskuStatus(msisdn)))
        .then(statuses => setOmalaskuStatuses(statuses))
        .catch(() => setOmalaskuStatuses([]));
    }
  }, [omalaskuStatuses, voiceMsisdns]);

  const resetOmalaskuStatus = () => {
    setOmalaskuStatuses(undefined);
  };

  if (subscriptionFetchFailed) {
    throw Error('Failed to load subscription details');
  }

  return (
    <OmalaskuContext.Provider
      value={{
        omalaskuSubsStatuses: omalaskuStatuses,
        msisdns: voiceMsisdns,
        resetOmalaskuStatus: resetOmalaskuStatus,
        firstName: firstName,
        lastName: lastName,
      }}
    >
      <Outlet />
    </OmalaskuContext.Provider>
  );
};

export const useOmalasku = (): OmaLaskuProps => {
  return useContext(OmalaskuContext);
};
