import { AdditionalFieldType } from '../../selfservice/common/shopping-cart/shoppingCartEnums.js';
import { Category, isRetainNumberProduct } from '../../common/utils/cartProductUtils.js';
import { SimCardSelection } from '../../common/enums.js';
import { SimType } from '../../generated/api/models.js';
import { getDate } from '../OrderSubscriptionConfiguration/OrderSubscriptionConfiguration.js';
import { processAdditionalParametersInCartItems } from '../../selfservice/actions/index.js';
import { selectNonOpeningFeeCommercialProductCode } from '../../common/utils/commercialProductUtils';
import type { AliasSupplier } from '../Checkout/steps/CartProductsStep.js';
import type { CartItemAdditionalParameters } from '../../selfservice/actions/index.js';
import type { ContactOrPurposeOfUseCallables } from '../ContactOrPurposeOfUse/ContactOrPurposeOfUse.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { Dispatch } from 'redux';
import type { EnrollmentProgram } from '../../generated/api/models.js';
import type { OnlineModelsState } from '../../common/types/states.js';
import type { OrderConfiguration } from '../OrderSubscriptionConfiguration/FormWrapper.js';
import type {
  PhoneNumberOwner,
  PurposeOfUseOrContact,
  SelectedPhoneNumber,
  SimCardConfiguration,
} from '../../common/types/subscription.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { ValidatedSim } from '../../common/types/sim.js';

export const existingPhoneNumberKey = 'existingPhoneNumber';
export const enrollmentProgramConsentKey = 'enrollmentProgramConsent';
export const enrollmentProgramAliasKey = 'enrollmentProgramAliasKey';

export const getEnrollmentProgramSelectionByIndex = (
  key: string,
  index: number,
  enrollmentProgram?: Record<string, boolean>
) => {
  if (enrollmentProgram) {
    return enrollmentProgram[`${key}[${index}]`];
  }
  return false;
};

export const cartItemIndex = (cartProductIndexUUID: string | undefined, productItemIndex: number) =>
  cartProductIndexUUID ? `${cartProductIndexUUID}-${productItemIndex}` : `${productItemIndex}`;

const getSalesProductIdentifierKey = (cartItem: ShoppingCartItemForCheckout): string => {
  if (cartItem.category === Category.SALES_PRODUCT) {
    return isRetainNumberProduct(cartItem.productName) ? '--transfer' : '--new';
  }
  return '';
};

export const getCartItemUniqueKey = (cartItem: ShoppingCartItemForCheckout): string =>
  `${selectNonOpeningFeeCommercialProductCode(cartItem.commercialProductCodes)}${
    cartItem.selectedAddOns.length && '--with-addOn'
  }${getSalesProductIdentifierKey(cartItem)}`;

export const saveAdditionalParametersInCartItems = async (
  validateOnly: boolean,
  cartProductDetailsFns: Record<string, Record<string, AliasSupplier>>,
  getContactOrPurposeOfUseFns: Record<string, Record<number, ContactOrPurposeOfUseCallables['getValues']>>,
  cartItems: Array<ShoppingCartItemForCheckout>,
  dispatch: Dispatch,
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType,
  enrollmentPrograms?: EnrollmentProgram[],
  onlineModels?: OnlineModelsState,
  validatedSims?: ValidatedSim[],
  data?: OrderConfiguration
) => {
  const getObjectsForMatchingKey = (
    matchingCartProductDetailsFn: Record<
      string,
      () => { obj: object | boolean | string; validationErrors?: { [p: string]: string } }
    >,
    matcherKey: string
  ) => {
    // It's trusted here that length of cartProductDetailsFn is same as item quantity.
    return Object.keys(matchingCartProductDetailsFn)
      .filter(key => key.startsWith(matcherKey))
      .map(key => matchingCartProductDetailsFn[key]().obj);
  };

  const getIndexedObjectFromArray = <T>(objects: T[], index: number): T | undefined => {
    return objects.length > index ? objects[index] : undefined;
  };

  const getMatchingCartProductDetailsFn = (cartItem: ShoppingCartItemForCheckout) => {
    const cartItemUniqueKey = getCartItemUniqueKey(cartItem);
    return cartProductDetailsFns[`cartProductDetailsFn[${cartItemUniqueKey}]`];
  };

  let hasFormErrors = false;

  // Here we need to resolve promises sequentially, hence Promise.all can't be used
  const contactOrPurposeOfUseValues: Record<string, PurposeOfUseOrContact> | false = await Object.values(
    getContactOrPurposeOfUseFns
  )
    .map(Object.entries)
    .flat()
    .reduce(async (values, [key, fn], index) => {
      const value = await fn(index === Object.keys(await values).length);
      if (value) {
        return {
          ...(await values),
          [key]: value,
        };
      } else {
        hasFormErrors = true;
        return values;
      }
    }, Promise.resolve({}));

  const cartItemAdditionalParameters: Record<string, CartItemAdditionalParameters[]> = cartItems
    .map(cartItem => {
      const matchingCartProductDetailsFn = getMatchingCartProductDetailsFn(cartItem) || {};
      const enrollmentProgramConsents = getObjectsForMatchingKey(
        matchingCartProductDetailsFn,
        enrollmentProgramConsentKey
      ) as boolean[];
      const enrollmentProgramAliases = getObjectsForMatchingKey(
        matchingCartProductDetailsFn,
        enrollmentProgramAliasKey
      ) as string[];
      const existingPhoneNumbers = getObjectsForMatchingKey(
        matchingCartProductDetailsFn,
        existingPhoneNumberKey
      ) as SelectedPhoneNumber[];

      return {
        id: cartItem.id,
        value: Array.from({ length: cartItem.quantity }, (_e, productItemIndex) => {
          const numberPublicity = data?.configuration?.[cartItemIndex(cartItem.id, productItemIndex)]?.numberPublicity;

          const primitiveAdditionalFields =
            data?.configuration?.[cartItemIndex(cartItem.id, productItemIndex)]?.additionalFields ?? {};

          let selectedPhoneNumber: SelectedPhoneNumber | undefined;
          let phoneNumberOwner: PhoneNumberOwner | undefined;
          if (
            cartItem.additionalFields.some(
              field => field.type === AdditionalFieldType.NUMBER_AND_NUMBER_PRIVACY_AND_SIM_CARD_SELECTION
            )
          ) {
            const configuredNumber =
              data?.configuration?.[cartItemIndex(cartItem.id, productItemIndex)]?.selectPhoneNumber;

            // TODO we should probably unify new and existing number from sales products to use same SelectPhoneNumber component?
            //  or at least use same form data. Currently sales products use CFG things so we need to differ here
            if (configuredNumber !== undefined) {
              selectedPhoneNumber = {
                type: configuredNumber.type,
                existingPhoneNumber: configuredNumber.existingPhoneNumber,
                newPhoneNumber: configuredNumber.newPhoneNumber,
                transferDate:
                  configuredNumber.transferDate !== undefined ? getDate(configuredNumber.transferDate) : undefined,
              };
            }
          } else {
            selectedPhoneNumber = getIndexedObjectFromArray(
              existingPhoneNumbers,
              productItemIndex
            ) as SelectedPhoneNumber;
          }

          const configuredOwner =
            data?.configuration?.[cartItemIndex(cartItem.id, productItemIndex)]?.selectPhoneNumber?.numberOwner;

          if (configuredOwner !== undefined) {
            phoneNumberOwner = {
              ownerType: configuredOwner.type,
              personEmail: configuredOwner.personEmail,
              permitDonoringOnFixedContract: configuredOwner.permitDonoringOnFixedContract,
            };
          }

          const simCardConfiguration =
            data?.configuration?.[cartItemIndex(cartItem.id, productItemIndex)]?.simCardConfiguration;

          return {
            purposeOfUseOrContact: (contactOrPurposeOfUseValues[
              `${getCartItemUniqueKey(cartItem)}-${productItemIndex}`
            ] || {}) as PurposeOfUseOrContact,
            numberPublicity: numberPublicity !== undefined ? numberPublicity === 'true' : undefined,
            simCardConfiguration: simCardConfiguration
              ? ({
                  simType:
                    simCardConfiguration?.simType === 'ACTIVATE_EXISTING'
                      ? SimType.PHYSICAL
                      : simCardConfiguration?.simType,
                  simCardNumber: simCardConfiguration?.simCardNumber,
                  simSelection:
                    simCardConfiguration?.simType === 'ACTIVATE_EXISTING'
                      ? SimCardSelection.ACTIVATE_EXISTING
                      : SimCardSelection.ORDER_NEW,
                } satisfies SimCardConfiguration)
              : undefined,
            selectedPhoneNumber,
            phoneNumberOwner,
            enrollmentProgramConsent: (getIndexedObjectFromArray(enrollmentProgramConsents, productItemIndex) ||
              undefined) as boolean,
            additionalFields: primitiveAdditionalFields,
            enrollmentProgramAlias: getIndexedObjectFromArray(enrollmentProgramAliases, productItemIndex) || '',
          };
        }),
      };
    })
    .reduce((a, v) => ({ ...a, [v.id]: v.value }), {});
  dispatch(
    processAdditionalParametersInCartItems(
      validateOnly,
      cartItemAdditionalParameters,
      hasFormErrors,
      deliveryDetails,
      enrollmentPrograms,
      onlineModels,
      validatedSims
    )
  );
  return hasFormErrors;
};

export const evaluateDomRefFns = (
  domRefFns: Record<string, () => { [parentKey: string]: { [elementKey: string]: HTMLElement } }>,
  domRefFn: () => { [parentKey: string]: { [elementKey: string]: HTMLElement } },
  cartItemUniqueIdentifier: string,
  cartProductUnMounted?: boolean
): Record<string, () => { [parentKey: string]: { [elementKey: string]: HTMLElement } }> => {
  const toBeUpdatedDomRefFns = domRefFns;
  if (toBeUpdatedDomRefFns && cartProductUnMounted) {
    delete toBeUpdatedDomRefFns[`cartItemDomRef[${cartItemUniqueIdentifier}]`];
  } else {
    (toBeUpdatedDomRefFns || {})[`cartItemDomRef[${cartItemUniqueIdentifier}]`] = domRefFn;
  }
  return { ...toBeUpdatedDomRefFns };
};

export const evaluateAdditionalParametersFns = (
  cartProductDetailsFns: Record<string, Record<string, AliasSupplier>>,
  collectAdditionalParametersFn: Record<string, AliasSupplier>,
  cartItemUniqueIdentifier: string,
  cartProductUnMounted?: boolean
): Record<string, Record<string, AliasSupplier>> => {
  const toBeUpdatedCartProductDetailsFns = cartProductDetailsFns;
  if (toBeUpdatedCartProductDetailsFns && cartProductUnMounted) {
    delete toBeUpdatedCartProductDetailsFns[`cartProductDetailsFn[${cartItemUniqueIdentifier}]`];
  } else {
    (toBeUpdatedCartProductDetailsFns || {})[`cartProductDetailsFn[${cartItemUniqueIdentifier}]`] =
      collectAdditionalParametersFn;
  }
  return { ...toBeUpdatedCartProductDetailsFns };
};

export const evaluateGetContactOrPurposeOfUseFns = (
  getContactOrPurposeOfUseFns: Record<string, Record<number, ContactOrPurposeOfUseCallables['getValues']>>,
  cartItemUniqueKey: string,
  productItemIndex?: number,
  fn?: ContactOrPurposeOfUseCallables['getValues']
): Record<string, Record<number, ContactOrPurposeOfUseCallables['getValues']>> | undefined => {
  const productItemKey = `${cartItemUniqueKey}-${productItemIndex}`;
  if (productItemIndex === undefined || !fn) {
    if (productItemIndex && getContactOrPurposeOfUseFns[cartItemUniqueKey]) {
      // @ts-ignore
      delete getContactOrPurposeOfUseFns[cartItemUniqueKey][productItemKey];
    } else {
      delete getContactOrPurposeOfUseFns[cartItemUniqueKey];
    }
    return undefined;
  }
  return {
    ...getContactOrPurposeOfUseFns,
    [cartItemUniqueKey]: {
      ...(getContactOrPurposeOfUseFns[cartItemUniqueKey] || {}),
      [productItemKey]: fn,
    },
  };
};
