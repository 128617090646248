import * as CL from '@design-system/component-library';
import { AccessoryCategory, AccessoryTag, SupportedModelCategory } from '../../common/utils/catalogUtils.js';
import { AccessoryRecommendations } from './AccessoryRecommendations';
import { LinkableAccordion } from '../LinkableAccordion';
import { ProductSelectionCheckbox } from './ProductSelectionCheckbox';
import { Table } from '../Table/index.js';
import { createImageUrl } from '../../common/utils/domUtils.js';
import { deviceMsg, priceMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fetchPublicPage } from '../../common/fetch';
import { getStartingPrice } from './CatalogProductSelectionUtils.js';
import { startNotification } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import type { CatalogProduct, ProductRecommendation } from '../../common/utils/catalogUtils.js';
import type { DeviceSelection } from './CatalogProductSelectionFormWrapper.js';
import type { PromotionBlockData } from '../../content-blocks/cmsSchema';

interface CatalogSelectionDeviceTableProps {
  products: CatalogProduct[];
  imagesBaseUrl: string;
  accessories?: CatalogProduct[];
  productCategory?: string;
}

const containsAccessories = (recommendation?: ProductRecommendation): boolean => {
  return (
    recommendation?.recommendedAccessories !== undefined &&
    Object.values(recommendation?.recommendedAccessories).some(a => a.length > 0)
  );
};

export const getRecommendedProducts = async (
  prod: CatalogProduct,
  accessories?: CatalogProduct[]
): Promise<ProductRecommendation> => {
  const response = await fetchPublicPage(prod.pagePath || '');
  const promotedProductCodes = response.contentBlocks
    .filter(block => block.contentBlockType === 'CB-PromotionBlock')
    .flatMap(promo => (promo.fields as PromotionBlockData)?.promotions?.map(p => p.productGuid));

  const recommendedAccessoriesList =
    accessories?.filter(acc => Array.from(new Set(promotedProductCodes)).includes(acc.code)) || [];

  const categorizeAccessories = (tags: AccessoryTag[]) =>
    recommendedAccessoriesList
      .filter(acc => tags.some(tag => acc.tags?.includes(tag)))
      .sort((a, b) => `${a.manufacturer} ${a.name}`.localeCompare(`${b.manufacturer} ${b.name}`));

  const accessoriesByCategory: Record<AccessoryCategory, CatalogProduct[]> = {
    [AccessoryCategory.SCREEN_COVERS]: categorizeAccessories([AccessoryTag.SCREEN_COVERS]),
    [AccessoryCategory.CASES]: categorizeAccessories([AccessoryTag.PROTECTIVE_CASES, AccessoryTag.PROTECTIVE_COVERS]),
    [AccessoryCategory.CHARGERS]: categorizeAccessories([AccessoryTag.CHARGERS]),
    [AccessoryCategory.OTHERS]: recommendedAccessoriesList.filter(
      acc => !Object.values(AccessoryTag).some(tag => acc.tags?.includes(tag))
    ),
  };
  return {
    code: prod.code,
    recommendedAccessories: accessoriesByCategory,
  };
};

export const CatalogSelectionDeviceTable = ({
  products,
  imagesBaseUrl,
  accessories,
  productCategory,
}: CatalogSelectionDeviceTableProps) => {
  const { setValue, watch } = useFormContext<DeviceSelection>();
  const [allSelected, setAllSelected] = useState(false);
  const [recommendedAccessories, setRecommendedAccessories] = useState<ProductRecommendation[]>([]);
  const dispatch = useDispatch();

  const selected = watch('selectedProductCodes');

  const onChangeSelectAll = () => {
    const categoryProductCodes = products.map(p => p.code);

    const updatedProducts = allSelected
      ? selected.filter(code => !categoryProductCodes.includes(code))
      : [...new Set([...selected, ...categoryProductCodes])];

    setValue('selectedProductCodes', updatedProducts);
    setAllSelected(!allSelected);
  };

  const columns: CL.Column[] = [
    {
      key: 'selection',
      label: (
        <CL.Checkbox
          checked={allSelected}
          onChange={onChangeSelectAll}
          label={t.A8VA('Select all')}
          className={dsClass.FONT_WEIGHT_BOLD}
        />
      ),
      width: '5%',
    },
    { key: 'img', label: '', width: '5%' },
    {
      key: 'device',
      label: t.TPVQ(deviceMsg),
      width: '70%',
    },
    {
      key: 'startingPrice',
      label: t.V72N(priceMsg),
      width: '15%',
    },
  ];

  const rows = products?.map(prod => {
    const showRecommendedAccessories =
      selected.includes(prod.code) &&
      (productCategory === SupportedModelCategory.PHONE || productCategory === SupportedModelCategory.TABLET);
    return {
      selection: <ProductSelectionCheckbox product={prod} />,
      img: (
        <div className="of-catalog-products-selection__table__images">
          <img
            alt={prod.manufacturer}
            {...createImageUrl({
              format: 'png',
              height: 150,
              imagesBaseUrl,
              originalUrl: prod.imageUrl,
              scalingFactors: [0.5, 1, 1.5],
              width: 150,
            })}
          />
        </div>
      ),
      device: showRecommendedAccessories ? (
        <div>
          {prod.manufacturer} {prod.name}
          <LinkableAccordion
            heading={t.BPM8('Most popular accessories')}
            id={prod.code}
            defaultOpen={false}
            onOpen={async () => {
              if (!recommendedAccessories.find(acc => acc.code === prod.code)) {
                try {
                  const recommended = await getRecommendedProducts(prod, accessories);
                  setRecommendedAccessories([...recommendedAccessories, recommended]);
                } catch (error) {
                  dispatch(startNotification(t.W8I2('Accessory search failed'), 'error'));
                }
              }
            }}
          >
            <div className={dsClass.PADDING_TOP_2}>
              {containsAccessories(recommendedAccessories.find(acc => acc.code === prod.code)) ? (
                <>
                  <AccessoryRecommendations
                    recommendations={recommendedAccessories}
                    productCode={prod.code}
                    imagesBaseUrl={imagesBaseUrl}
                    category={AccessoryCategory.CASES}
                    title={t.EK6P('Cases')}
                  />
                  <AccessoryRecommendations
                    recommendations={recommendedAccessories}
                    productCode={prod.code}
                    imagesBaseUrl={imagesBaseUrl}
                    category={AccessoryCategory.SCREEN_COVERS}
                    title={t.IVJT('Screen protectors')}
                  />
                  <AccessoryRecommendations
                    recommendations={recommendedAccessories}
                    productCode={prod.code}
                    imagesBaseUrl={imagesBaseUrl}
                    category={AccessoryCategory.CHARGERS}
                    title={t.F3LL('Chargers')}
                  />
                  <AccessoryRecommendations
                    recommendations={recommendedAccessories}
                    productCode={prod.code}
                    imagesBaseUrl={imagesBaseUrl}
                    category={AccessoryCategory.OTHERS}
                    title={t.F0CY('Other accessories')}
                  />
                </>
              ) : (
                <>
                  {t.O1Y8(
                    'This device does not have any recommended accessories. See our entire selection of accessories in the '
                  )}
                  <span className={dsClass.FONT_WEIGHT_BOLD}>{t.EV5V('Accessories ')}</span>
                  {t.RAS9('tab')}
                </>
              )}
            </div>
          </LinkableAccordion>
        </div>
      ) : (
        `${prod.manufacturer} ${prod.name}`
      ),
      startingPrice: <div className={dsClass.WHITE_SPACE_NOWRAP}> {getStartingPrice(prod)} </div>,
    };
  });

  return (
    <div className="of-catalog-products-selection__table">
      <Table
        columns={columns}
        rows={rows || []}
        tableType="bordered"
        noItemsText={t.HUVS('No matching products found')}
      />
    </div>
  );
};
