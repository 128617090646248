import * as CL from '@design-system/component-library';
import { WorkingHours } from './WorkingHours';
import { t } from '../../common/i18n';
import type { PbxSettingFormData } from './PbxAccordionUtils';

import './ScheduledWorkingHours.scss';

export interface ScheduledWorkingHoursProps {
  formValues: PbxSettingFormData;
  edit?: boolean;
}

export const ScheduledWorkingHours = ({ formValues, edit = false }: ScheduledWorkingHoursProps) => {
  return (
    <>
      <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
        <WorkingHours
          title={t.EBAT('On weekdays')}
          namePrefix="workingHoursWeekday"
          start={formValues?.workingHoursWeekdayStart}
          end={formValues?.workingHoursWeekdayEnd}
          edit={edit}
        />
      </CL.GridCol>

      <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={6} colsXL={6}>
        <WorkingHours
          title={t.ZZK7('On weekends')}
          namePrefix="workingHoursWeekend"
          start={formValues?.workingHoursWeekendStart}
          end={formValues?.workingHoursWeekendEnd}
          edit={edit}
        />
      </CL.GridCol>
    </>
  );
};
