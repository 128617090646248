import { ContactType } from '../../generated/api/contactType';
import { DuplicateContactError } from './DuplicateContactError';
import { DuplicateContactResult } from '../../generated/api/duplicateContactResult';
import { notAbleToProcessContactRequestMsg, t } from '../../common/i18n';
import { upsertContactUser } from '../../common/fetch';
import type { Contact } from '../../generated/api/contact';
import type { ContactHeader } from '../../generated/api/contactHeader';
import type { ContactPerson } from '../../generated/api/contactPerson';
import type { DuplicateContact } from '../../generated/api/duplicateContact';
import type { GenericErrorDuplicateContact } from '../../generated/api/genericErrorDuplicateContact';
import type { PurposeOfUseOrContact } from '../../common/types/subscription';
import type { PutContactResponse } from '../../generated/api/putContactResponse';

export interface DuplicateContactResponse {
  type: DuplicateContact.DuplicateTypeEnum;
  duplicateContact?: GenericErrorDuplicateContact;
}

export const isDuplicateContactError = (response: PutContactResponse | DuplicateContactResponse) =>
  'type' in response &&
  (response.type === DuplicateContactResult.TypeEnum.DUPLICATE_CONTACT ||
    response.type === DuplicateContactResult.TypeEnum.POSSIBLE_CONTACT_DUPLICATE);

export const isConflictingContactError = (response: PutContactResponse | DuplicateContactResponse) =>
  'type' in response && response.type === DuplicateContactResult.TypeEnum.CONFLICTING_CONTACT;

const isContact = (object: object): object is Contact => 'contactType' in object;

const createContact = async (
  contactPerson: ContactPerson,
  mdmId?: string
): Promise<PutContactResponse | DuplicateContactResponse> => {
  const { email, firstName, lastName, phoneNumber, roles } = contactPerson;
  const contact = {
    contactType: ContactType.PERSON,
    person: {
      email,
      firstName,
      lastName,
      phoneNumber,
      roles,
    },
  };
  const response = await upsertContactUser(contact, false, false, true, mdmId);
  const responseData = await response.json();

  if (response.ok || isDuplicateContactError(responseData)) {
    return responseData;
  }

  if (response.status === 409 && responseData.type === DuplicateContactResult.TypeEnum.CONTACT_SUPPORT) {
    throw new Error(t.EGFA(notAbleToProcessContactRequestMsg));
  }

  throw new Error(t.RG74('Failed to create the new contact.'));
};

const isDuplicateContactResponse = (
  response: PutContactResponse | DuplicateContactResponse
): response is DuplicateContactResponse => (response as DuplicateContactResponse).type !== undefined;

const mergeCostCenterAndEmployeeNumber = (pucBase: PurposeOfUseOrContact, puc?: PurposeOfUseOrContact) => {
  if (puc) {
    return {
      ...pucBase,
      costCenter: puc.costCenter,
      employeeNumber: puc.employeeNumber,
    };
  }
  return pucBase;
};

export const mapContactPersonToPuc = (
  contact: ContactPerson,
  purposeOfUseOrContact?: PurposeOfUseOrContact
): PurposeOfUseOrContact => {
  const pucBase = {
    firstName: contact.firstName,
    lastName: contact.lastName,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
  };

  return mergeCostCenterAndEmployeeNumber(pucBase, purposeOfUseOrContact);
};

// Handle both Contact (from Salesforce) and ContactHeader (from ElasticSearch) objects
export const mapAllContactTypesToPuc = (
  contact?: Contact | ContactHeader,
  purposeOfUseOrContact?: PurposeOfUseOrContact
): PurposeOfUseOrContact => {
  if (!contact) {
    throw new Error('No contact provided');
  }
  if (isContact(contact)) {
    const person = contact.person as ContactPerson;
    return mapContactPersonToPuc(person, purposeOfUseOrContact);
  }
  const pucBase = {
    firstName: contact.firstName,
    lastName: contact.lastName,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
  };

  return mergeCostCenterAndEmployeeNumber(pucBase, purposeOfUseOrContact);
};

const mapPucToGenericErrorDuplicateContact = (puc: PurposeOfUseOrContact): GenericErrorDuplicateContact => ({
  contactId: puc.contactId || '',
  email: puc.email || '',
  firstName: puc.firstName || '',
  lastName: puc.lastName || '',
  phoneNumber: puc.phoneNumber || '',
});

export const handleContacts = async (
  purposeOfUseOrContact: PurposeOfUseOrContact,
  contacts?: ContactHeader[] | Contact[],
  mdmId?: string,
  shouldUseDuplicateContact?: boolean
): Promise<PurposeOfUseOrContact> => {
  // shouldUseDuplicateContact is true if the user has chosen from modal, that he/she wants to use duplicate contact.
  if (purposeOfUseOrContact.purposeOfUse || shouldUseDuplicateContact) {
    return purposeOfUseOrContact;
  }
  const { newContact, contactId } = purposeOfUseOrContact;
  if (newContact) {
    const res = await createContact(newContact, mdmId);
    if (isDuplicateContactResponse(res)) {
      throw new DuplicateContactError('A contact with this ID already exists.', res.duplicateContact?.contactId);
    }
    return mapContactPersonToPuc(newContact);
  }

  const contact = contacts?.find(c => c.contactId === contactId);

  if (!contact) {
    throw new Error('Cannot find contact');
  }
  return mapAllContactTypesToPuc(contact, purposeOfUseOrContact);
};

export const getGenericErrorDuplicateContact = (
  contacts?: Contact[] | ContactHeader[],
  contactId?: string
): GenericErrorDuplicateContact => {
  const contact = contacts?.find(c => c.contactId === contactId);
  if (!contact) {
    throw new Error('Could not find contact');
  }
  return mapPucToGenericErrorDuplicateContact(mapAllContactTypesToPuc(contact));
};
