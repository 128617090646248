import {
  CommercialProductType,
  Subscription,
  SubscriptionAction,
  SubscriptionStatusType,
  SubscriptionType,
  TaapiTechnology,
} from '../../generated/api/models.js';
import {
  DialogType,
  FiveGMobileBroadbandOfferCode,
  FiveGVoiceOfferCode,
  MobiilivarmenneAddonGroups,
  StatusColor,
  SubscriptionAdditionalServiceStatusEnum,
} from '../enums.js';
import {
  LaitenettiOfferCode,
  LiikkuvaWifiOfferCode,
  SalesProductLiikkuvaWifiOfferCodes,
} from '../constants/subscriptionConstants';
import {
  NetworkType,
  nettiLiteOfferSpecification,
} from '../../components/OrderSubscriptionSelection/content/NettiLiteOfferSpecification';
import {
  activeMsg,
  closedMsg,
  inDeliveryMsg,
  subChangePendingHeaderMsg,
  t,
  temporarilyClosedMsg,
} from '../i18n/index.js';
import { isTimestampDateInFuture } from './dateUtils.js';
import { suspendedCommercialProductCodes } from './commercialProductUtils.js';
import type { AddOn, CommercialProduct, Offer, SubscriptionHeader } from '../../generated/api/models.js';
import type { DialogParams } from '../types/dialog.js';
import type { SubscriptionActionsState } from '../types/states.js';
import type { SubscriptionStatus } from '../types/subscription.js';

export const ONE_TIME_DEVICE_CHANGE_NOT_ALLOWED = 'Changes not allowed for one time charged products';

export const ELISA_YTT = 'Elisa Yritystietoturva';

export const getSubscriptionStatus = (
  sub?: Subscription | SubscriptionHeader,
  subActions?: SubscriptionAction[]
): SubscriptionStatus => {
  const subAction = subActions?.find(action => action.subscriptionId === sub?.subscriptionId);
  const statusEnum = SubscriptionAction.StatusEnum;

  if (subAction && [statusEnum.PENDING, statusEnum.PENDING_APPROVAL, statusEnum.ERROR].includes(subAction.status)) {
    // Subscription actions might have other actions as well than pending or error, but other actions should change
    // the value of the subscription's own status field too. Status ERROR means that the subscription action moved
    // to manual handling by sales people, so it's still ongoing.
    return {
      color: StatusColor.YELLOW,
      pendingActionType: subAction.subscriptionActionType,
      pendingActions: true,
      isChangeOfferAllowed: false,
      text: t.I9KT(subChangePendingHeaderMsg),
    };
  } else if ((sub as Subscription)?.details?.asset?.hasPendingActions) {
    // Asset in EOE
    return {
      color: StatusColor.YELLOW,
      pendingActions: true,
      isChangeOfferAllowed: false,
      text: t.I9KT(subChangePendingHeaderMsg),
    };
  }

  // TODO probably no need for this many statuses?
  switch (sub?.subscriptionStatus) {
    case SubscriptionStatusType.CREATED:
    case SubscriptionStatusType.IN_ACTIVATION:
      return {
        color: StatusColor.YELLOW,
        pendingActions: false,
        isChangeOfferAllowed: false,
        text: t.W59S(inDeliveryMsg),
      };

    case SubscriptionStatusType.ACTIVE:
      return {
        color: StatusColor.GREEN,
        pendingActions: false,
        isChangeOfferAllowed: true,
        text: t.SFRK(activeMsg),
      };

    case SubscriptionStatusType.SUSPENDED:
      return {
        color: StatusColor.DARK_BLUE,
        pendingActions: false,
        isChangeOfferAllowed: false,
        text: t.PC3E(temporarilyClosedMsg),
      };

    default:
      return {
        color: StatusColor.DARK_BLUE,
        pendingActions: false,
        isChangeOfferAllowed: true,
        text: t.UFGV(closedMsg),
      };
  }
};

export const doActionIfNotPendingSubscription = (
  subscriptionStatus: SubscriptionStatus,
  onShowDialog: (params: DialogParams) => void,
  action: () => void
) => (subscriptionStatus.pendingActions ? onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING }) : action());

export const openSubscriptionDialog = (
  subscriptionStatus: SubscriptionStatus,
  onShowDialog: (params: DialogParams) => void,
  dialogParams: DialogParams
) => onShowDialog(subscriptionStatus.pendingActions ? { type: DialogType.SUBSCRIPTION_ACTION_PENDING } : dialogParams);

export const getTaapiTechnologyAsString = (taapiTechnology?: TaapiTechnology): string | undefined => {
  switch (taapiTechnology) {
    case TaapiTechnology.ADSL:
    case TaapiTechnology.ADSL_FTTB:
    case TaapiTechnology.ADSL_FTTC:
      return t.G07Y('ADSL');

    case TaapiTechnology.CRM_KTV:
    case TaapiTechnology.CABLE_MODEM:
      return t.R488('Cable');

    case TaapiTechnology.ETHERNET_FTTB:
    case TaapiTechnology.FTTB_ETH:
    case TaapiTechnology.FTTB_ETH_1G:
    case TaapiTechnology.FTTH:
    case TaapiTechnology.YKI:
    case TaapiTechnology.ETH_FTTB_FIB:
    case TaapiTechnology.YKI_FIB:
      return t.URFP('Optical fibre');

    case TaapiTechnology.VDSL_CO:
    case TaapiTechnology.VDSL2_FTTB:
    case TaapiTechnology.VDSL2_FTTC:
      return t.LG3L('VDSL');

    default:
      return undefined;
  }
};

export const isCommitmentPeriodActive = (endDateTimestamp?: number) =>
  endDateTimestamp ? isTimestampDateInFuture(endDateTimestamp) : false;

export const isInExtensionPeriod = (subscription: Subscription | SubscriptionHeader): boolean =>
  subscription.changeType === 'Extension Period';

export const commercialProductIdForHyllyliittyma = {
  MOBILE: 'a0d2e28a-8ad8-aea3-9929-02aa2498c2fe',
  MOBILE_BROADBAND: '3ca41f56-58b4-c6ca-5b5c-5e492f8b689e',
};

export const isBbOrMbbSubscription = (subscription: Subscription): boolean =>
  subscription.subscriptionType === SubscriptionType.BROADBAND ||
  subscription.subscriptionType === SubscriptionType.MOBILE_BROADBAND;

export const isHyllyLiittyma = (subscription: Subscription): boolean =>
  !!subscription.commercialProductCode && suspendedCommercialProductCodes.includes(subscription.commercialProductCode);

export const isMobileIdAddOn = (addOnGroup?: string): boolean =>
  Object.values(MobiilivarmenneAddonGroups).some(group => group === addOnGroup);

export const mobiilivarmenneNotInUseAddOn: AddOn = {
  addOnCode: SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE,
  addOnGroup: MobiilivarmenneAddonGroups.CERTIFICATE,
  addOnProductName: 'Mobiilivarmenne',
  addOnType: SubscriptionType.MOBILE_CERTIFICATE,
  display: true,
};

export const simCardChangeAllowed = (subscription: Subscription) =>
  subscription.subscriptionStatus !== SubscriptionStatusType.CREATED &&
  subscription.subscriptionStatus !== SubscriptionStatusType.IN_ACTIVATION;

export const hasEsimQrCode = (subscription: Subscription) =>
  Boolean(subscription.details?.mobile?.eSimQrActivationCode);

export const isSubscriptionInSfdc = (subscription: Subscription | SubscriptionHeader) =>
  Boolean(subscription.sourceSystem === Subscription.SourceSystemEnum.SFDC || subscription.migrated);

export const isFiveGMbbOffer = (offerGuid = ''): boolean =>
  [
    FiveGMobileBroadbandOfferCode.MBB_300_M,
    FiveGMobileBroadbandOfferCode.MBB_100_M,
    FiveGMobileBroadbandOfferCode.MBB_50_M,
    FiveGMobileBroadbandOfferCode.MBB_10_M,
  ].some(code => code === offerGuid);

export const isFiveGPlusMbbOffer = (offerGuid = ''): boolean =>
  [
    FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M,
    FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M,
    FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_300_M,
    FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_100_M,
    LiikkuvaWifiOfferCode.MBB_1000_M,
    LiikkuvaWifiOfferCode.MBB_600_M,
    LiikkuvaWifiOfferCode.MBB_300_M,
  ].some(code => code === offerGuid);

export const isFiveGPlusVoiceOffer = (offerGuid = ''): boolean =>
  [
    FiveGVoiceOfferCode.Voice5gPlus1000Mbps,
    FiveGVoiceOfferCode.Voice5gPlus600Mbps,
    FiveGVoiceOfferCode.Voice5gPlus300Mbps,
  ].some(code => code === offerGuid);

export const isFiveGPlusNettiliteOffer = (offerGuid = '') =>
  nettiLiteOfferSpecification()
    .filter(nettilite => nettilite.offerCodes.includes(offerGuid))
    .some(nettiLite => nettiLite.networkType === NetworkType.FIVE_G_PLUS);

export const isFiveGVoiceOffer = (offerGuid = ''): boolean =>
  Object.values(FiveGVoiceOfferCode).includes(offerGuid as FiveGVoiceOfferCode);

export const isLiikkuvaWifiOffer = (offerGuid = ''): boolean =>
  Object.values(LiikkuvaWifiOfferCode).includes(offerGuid as LiikkuvaWifiOfferCode) ||
  Object.values(SalesProductLiikkuvaWifiOfferCodes).includes(offerGuid as SalesProductLiikkuvaWifiOfferCodes);

export const isFiveGOffer = (offerGuid = ''): boolean => isFiveGMbbOffer(offerGuid) || isFiveGVoiceOffer(offerGuid);

export const isFiveGPlusOffer = (offerGuid = ''): boolean =>
  isFiveGPlusMbbOffer(offerGuid) || isFiveGPlusVoiceOffer(offerGuid);

export const isYttSubscription = (subscriptionSubType?: string) => subscriptionSubType?.includes(ELISA_YTT);

export const containsYttSubscription = (subscriptions?: SubscriptionHeader[] | Subscription[]) =>
  subscriptions ? subscriptions.some(res => isYttSubscription(res.subscriptionSubType)) : false;

// TODO: Remove after all requests that result in a new pending subscription action being created, are refactored to
//  use fetch instead of Redux. For now, this is the only way to ensure we have up-to-date collection of pending
//  sub actions.
export const combinePendingActions = (
  subscriptionId: string,
  pendingSubscriptionActions: SubscriptionAction[],
  pendingSubscriptionActionsFromRedux: SubscriptionActionsState | null | undefined
): SubscriptionAction[] => {
  const pendingActionForCurrentSubscription = pendingSubscriptionActionsFromRedux?.items?.find(
    pendingAction => pendingAction.subscriptionId === subscriptionId
  );
  const actionExists = pendingSubscriptionActions.some(
    p => p.subscriptionActionId === pendingActionForCurrentSubscription?.subscriptionActionId
  );
  return pendingActionForCurrentSubscription && !actionExists
    ? [...pendingSubscriptionActions, pendingActionForCurrentSubscription]
    : pendingSubscriptionActions;
};

export const isExternalSubscription = (subscription: Subscription | SubscriptionHeader) =>
  !isSubscriptionInSfdc(subscription);

export const getSubscriptionBreadcrumbName = (subscription: Subscription) =>
  subscription.subscriptionDisplayId ??
  subscription.details?.mobile?.mobileNumber ??
  subscription.subscriptionUserFriendlyId ??
  subscription.subscriptionId;

export const getSubscriptionUrlId = (subscription: Subscription | SubscriptionHeader) =>
  subscription.subscriptionDisplayId ?? subscription.subscriptionId;

export const isLaitenettiOffer = (offer: Offer) =>
  Object.values(LaitenettiOfferCode).includes(offer.offerCode as LaitenettiOfferCode);

export const isSalesProduct = (commercialProduct?: CommercialProduct) =>
  commercialProduct?.productType === CommercialProductType.SALES_PRODUCT;
