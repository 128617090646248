import { type BillingAccountData } from '../OpenFormAnswers.js';
import { concat } from '../OpenFormUtils.js';
import { eInvoiceMsg, emailInvoiceMsg, paperInvoiceMsg, t } from '../../../common/i18n/index.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { ParameterizedSearchContactResult } from '../../../generated/api/parameterizedSearchContactResult.js';

export const OpenFormBillingAccount = ({ ba }: { ba: BillingAccount | BillingAccountData }) => (
  <>
    <div>{ba.billingAccountName || ba.payerName}</div>
    <div>{ba.payerNameExtension}</div>
    <div>{ba.billingAccountExtensionName}</div>
  </>
);

export const OpenFormBillingContact = ({ ba }: { ba: BillingAccount | BillingAccountData }) => (
  <>
    <div>{(ba as BillingAccountData).billingContactAccountName}</div>
    <div>{ba.billingContactName}</div>
    <div>{(ba as BillingAccountData).billingContactEmail}</div>
    <div>{ba.billingContactPhone}</div>
  </>
);

export const OpenFormBillingInvoiceDeliveryMethod = ({ ba }: { ba: BillingAccount | BillingAccountData }) => {
  switch (ba.deliveryMethod) {
    case 'ELECTRONIC':
      return (
        <>
          <div>
            {ba.billElectronicOperator} {ba.billElectronicAddress}
          </div>
          <div>{t.IK1D(eInvoiceMsg)}</div>
        </>
      );
    case 'EMAIL':
      return (
        <>
          <div>{ba.billReceiverEmail || (ba as BillingAccountData).billingContactEmail}</div>
          <div>{t.NNV9(emailInvoiceMsg)}</div>
        </>
      );
    case 'PAPER':
      return (
        <>
          {!ba.payerAddress ? null : (
            <>
              <div>{concat(ba.payerAddress.line1, ba.payerAddress.line2).join(' ')}</div>
              <div>
                {ba.payerAddress.postalCode} {ba.payerAddress.postOffice}
              </div>
            </>
          )}
          <div>{t.TER4(paperInvoiceMsg)}</div>
        </>
      );
  }
  return null;
};

export const OpenFormContact = ({ co }: { co: ParameterizedSearchContactResult }) => (
  <>
    <div>{co.Account?.Name}</div>
    <div>{concat(co.FirstName, co.LastName).join(' ')}</div>
    <div>{co.Email}</div>
    <div>{co.MobilePhone}</div>
  </>
);
