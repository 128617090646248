import * as CL from '@design-system/component-library';
import * as React from 'react';
import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { GlobalSearch } from '../GlobalSearch/GlobalSearch.js';
import { HeaderNotification } from '../HeaderNotification/HeaderNotification.js';
import { LoggedInAsEmployeeNotification, siteSelect } from '../Header/dynamic/headerFunctions.js';
import { SelectCompanyDialog } from '../Dialogs/SelectCompanyDialog';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  chosenLanguageMsg,
  closeMsg,
  englishMsg,
  forCorporationsMsg,
  logInMsg,
  logOutMsg,
  myInformationMsg,
  searchMsg,
  suomiMsg,
  t,
} from '../../common/i18n';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { headerAccordions } from '../Header/dynamic/headerAccordions.js';
import { isFeatureEnabled, isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import { omaElisaNavigationLinksWithCurrentProperty } from './dynamic/omaElisaNavigationLinks.js';
import { paths } from '../../common/constants/pathVariables.js';
import { personalUserMenuLinks } from '../Header/dynamic/personalUserMenuLinks.js';
import { setActiveAccount } from '../../selfservice/actions';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { State } from '../../selfservice/common/store.js';

import './OmaElisaHeader.scss';

interface SearchButtonProps {
  searchFieldVisible: boolean;
  setSearchFieldVisible: (visible: boolean) => void;
}
const SearchButton = ({ searchFieldVisible, setSearchFieldVisible }: SearchButtonProps) => {
  const { toggleActiveItem } = useContext(CL.HeaderContext);
  return (
    <button
      className={`${dsClass.NAVIGATIONBUTTON} ${dsClass.HEADERSEARCHBUTTON}`}
      onClick={() => {
        if (!searchFieldVisible) {
          // This hides whatever item might have been active/open in the header
          toggleActiveItem(null, null);
        }
        setSearchFieldVisible(!searchFieldVisible);
      }}
      type="button"
    >
      <div className={dsClass.NAVIGATIONBUTTON_ICON}>
        <CL.Icon icon="search" />
      </div>
      <span className="search-button__text">{t.V4GK(searchMsg)}</span>
    </button>
  );
};

export const OmaElisaHeader = () => {
  const { logout, ssoSessionValid } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { siteBaseUrl } = useContext(SiteContext);
  const dispatch = useDispatch();
  const { siteLanguage, saveUserPreferredLanguage } = React.useContext(SiteContext);
  const menuId = 'oma-elisa-navigation';
  const hamburgerRef = useRef<HTMLDialogElement>(null);
  const userMenuRef = useRef<HTMLDialogElement>(null);
  const [selectCompanyDialogUrl, setSelectCompanyDialogUrl] = useState<string>();
  const [searchFieldVisible, setSearchFieldVisible] = useState(false);
  const { authenticatedUser } = useAuth();
  const { classicSiteUrl, ringBaseUrl, featureFlags } = useSelector(
    (state: State) => ({
      classicSiteUrl: state.config.classicSiteUrl,
      ringBaseUrl: state.config.ringBaseUrl,
      featureFlags: state.config.featureFlags,
    }),
    deepEqual
  );
  const dnsManagementEnabled = isFeatureEnabledForUser(
    'dnsManagement',
    featureFlags,
    authenticatedUser?.enabledFeatureFlags
  );
  const isEmployeeUser = authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;
  const { firstName = '', lastName = '', email = '', companyName = '' } = authenticatedUser || {};
  const mobileId = isFeatureEnabled(featureFlags.mobileId);
  const navigationItemList = omaElisaNavigationLinksWithCurrentProperty(mobileId, pathname, dnsManagementEnabled);
  const accordions = headerAccordions(navigate, ssoSessionValid, ringBaseUrl, (_, url) => {
    if (url === paths.WEB_SHOP) {
      setSelectCompanyDialogUrl(url);
    }
  });
  const notifications = personalUserMenuLinks(navigate, classicSiteUrl);

  const languageSelect = {
    languages: [
      { name: suomiMsg, abbr: 'fi' },
      { name: englishMsg, abbr: 'en' },
    ],
    selectedLanguage: siteLanguage.substring(0, 2),
    onLanguageChange: ({ lang }: { lang: string }) => {
      saveUserPreferredLanguage(lang);
    },
  };

  const homePath = `/${languageSelect.selectedLanguage === 'fi' ? '' : languageSelect.selectedLanguage}`;

  const logo = {
    label: 'Elisa',
    onClick: (e: React.SyntheticEvent) => {
      e.preventDefault();
      navigate(homePath);
    },
    url: homePath,
  };

  const service = companyName
    ? {
        identifiers: ['OmaElisa', t.KBLX(forCorporationsMsg)],
        label: '',
        onClick: (e: React.SyntheticEvent) => {
          e.preventDefault();
          navigate(paths.SELF_SERVICE_HOME);
        },
        url: paths.SELF_SERVICE_HOME,
      }
    : undefined;

  const headerUser = authenticatedUser
    ? {
        email,
        fullName: `${firstName} ${lastName}`,
        name: firstName,
      }
    : undefined;

  return (
    <>
      {!!selectCompanyDialogUrl && (
        <SelectCompanyDialog
          onCloseDialog={() => setSelectCompanyDialogUrl(undefined)}
          onContinue={selectedMdmId => {
            // This is needed for webshop as the webshop contains links that do full page reload
            // and there is no possibility to append the mdmId in some of the links coming from the CMS
            // due the links are HTML elements in text.
            dispatch(setActiveAccount(selectedMdmId, false, selectCompanyDialogUrl));
          }}
        />
      )}
      <CL.Header
        desktopBreakpointOffset={35}
        className={`of-self-service-header ${searchFieldVisible ? 'of-self-service-header__search-active' : ''}`}
        isLoggedIn={Boolean(companyName)}
        languageSelect={languageSelect}
        siteSelect={siteSelect(siteBaseUrl)}
      >
        <div className={searchFieldVisible ? 'of-self-service-header__hide-on-mobile' : ''}>
          <CL.HeaderNavigationSection>
            <CL.HeaderLogoAndService logo={logo} service={service} />
          </CL.HeaderNavigationSection>
        </div>
        {searchFieldVisible ? (
          <GlobalSearch setSearchFieldVisible={setSearchFieldVisible} isMultiBiz={isMultiBiz(authenticatedUser)} />
        ) : (
          <CL.HeaderNavigationSection>
            <CL.HeaderNavigation
              id={menuId}
              languageSelect={languageSelect}
              navigationItemList={navigationItemList}
              onButtonClick={() => {}}
              onLinkClick={(e, { url }) => {
                if (url) {
                  e.preventDefault();
                  if (
                    (url === paths.BUSINESS_SECURITY ||
                      url === paths.WEB_SHOP ||
                      url === paths.ANONYMOUS_FIXED_BROADBAND_ORDER) &&
                    isMultiBiz(authenticatedUser)
                  ) {
                    setSelectCompanyDialogUrl(url);
                  } else {
                    if (url.startsWith('http')) {
                      window.location.href = url;
                    } else {
                      navigate(url);
                    }
                  }
                }
              }}
              requireLogin={false}
              returnFocusToRef={hamburgerRef}
              siteSelect={siteSelect(siteBaseUrl)}
            />
          </CL.HeaderNavigationSection>
        )}
        <CL.HeaderNavigationSection className={searchFieldVisible ? 'of-self-service-header__hide-on-mobile' : ''}>
          <SearchButton searchFieldVisible={searchFieldVisible} setSearchFieldVisible={setSearchFieldVisible} />
          <CL.HeaderUserMenu
            i18nButtonLabel={t.MIFJ(myInformationMsg)}
            i18nLanguageSwitchSelectedLanguageLabel={t.FT4V(chosenLanguageMsg)}
            i18nLogin={t.Z16I(logInMsg)}
            logout={{ onClick: () => logout(paths.SELF_SERVICE_LOGOUT), title: t.LQ3X(logOutMsg) }}
            notifications={notifications}
            onToggle={(isVisible: boolean) => {
              if (isVisible) {
                setSearchFieldVisible(false);
              }
            }}
            personalLinks={[]}
            ref={userMenuRef}
            user={headerUser}
            userPronounText={firstName}
            userMenuLinkAccordions={accordions}
          />
          <CL.HeaderHamburger
            i18nCloseAlt={t.WOYD(closeMsg)}
            i18nOpenAlt="menu"
            ref={hamburgerRef}
            onToggle={(isVisible: boolean) => {
              if (isVisible) {
                setSearchFieldVisible(false);
              }
            }}
            toggledModalChild={menuId}
          />
        </CL.HeaderNavigationSection>
      </CL.Header>
      <HeaderNotification />
      {isEmployeeUser && LoggedInAsEmployeeNotification}
      {searchFieldVisible && (
        <div role="presentation" onClick={() => setSearchFieldVisible(false)} className="search-backdrop" />
      )}
    </>
  );
};
