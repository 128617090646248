import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid.js';
import { Link, generatePath } from 'react-router-dom';
import { changePasswordMsg, myAccountMsg, nameMsg, t, userDetailsMsg } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { State } from '../../selfservice/exports.js';

import './MyAccountDetails.scss';

export interface MyAccountDetailsProps {
  authenticatedUser?: AuthenticatedUserState;
}

export const MyAccountDetails = ({ authenticatedUser }: MyAccountDetailsProps): JSX.Element => {
  const classicBaseUrl = useSelector((state: State) => state.config.classicSiteUrl, deepEqual);

  const displayName = authenticatedUser ? `${authenticatedUser.firstName} ${authenticatedUser.lastName}` : '';
  const descriptionItems = [
    {
      title: t.VGFI(nameMsg),
      description: displayName,
    },
  ];

  return (
    <Grid className="my-account-details">
      <h3>{t.SEBD(myAccountMsg)}</h3>
      <CL.Description items={descriptionItems} className="wide-description" />
      <Link
        to={`${generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId: authenticatedUser?.contact?.contactMasterId ?? '' })}?mdmId=${authenticatedUser?.mdmId ?? ''}`}
        className={dsClass.LINK}
      >
        {t.T1WX(userDetailsMsg)}
      </Link>
      <div className={dsClass.MARGIN_TOP_6}>
        <CL.Button
          key="edit-in-classic"
          color="light"
          iconPosition="right"
          icon={<CL.Icon icon="external-link" color="blue-800" />}
          className="edit-in-classic"
          onClick={() => {
            window.open(`${classicBaseUrl}/omat-tiedot`, '_blank');
          }}
        >
          {t.R2MM(changePasswordMsg)}
        </CL.Button>
      </div>
    </Grid>
  );
};
