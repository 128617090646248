import * as CL from '@design-system/component-library';
import {
  type CatalogProduct,
  SupportedModelCategories,
  SupportedModelCategory,
} from '../../common/utils/catalogUtils.js';
import { CatalogSelectionDeviceTable } from './CatalogSelectionDeviceTable.js';
import { Grid } from '../Grid/Grid.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import { ProductGridFilter, createFilters } from '../ProductGrid/ProductGridFilter.js';
import { ProductGridSearchCL } from '../ProductGrid/ProductGridSearch.js';
import { ProductGridSort, SortType } from '../ProductGrid/ProductGridSort.js';
import { StickyFooter } from '../StickyFooter/index.js';
import { ToggleButton } from '../ToggleButton/ToggleButton.js';
import {
  continueMsg,
  deviceListsMsg,
  filterMsg,
  omaElisaForCompaniesMsg,
  pcsMsg,
  searchMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  getProductCategoryDetails,
  getSelectedCatalogProducts,
  sortAndFilterProducts,
} from './CatalogProductSelectionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import type { DeviceSelection } from './CatalogProductSelectionFormWrapper.js';
import type { Filters } from '../ProductGrid/ProductGridFilter.js';

export interface CatalogProductsSelectionFormProps {
  contractPeriod?: number;
  imagesBaseUrl: string;
  catalogProducts: Record<string, CatalogProduct[]>;
}

export interface CategorySummaryProps {
  selectedProducts: Record<SupportedModelCategory, CatalogProduct[]>;
}

interface NavigationProps {
  onChangeCategory: (SupportedCat: SupportedModelCategory) => void;
}

interface FooterProps {
  catalogProducts: Record<string, CatalogProduct[]>;
}

const CategorySummary = ({ selectedProducts }: CategorySummaryProps) => (
  <div className={dsClass.TEXT_S}>
    {SupportedModelCategories.map(cat => {
      return selectedProducts[cat]
        ? `${getProductCategoryDetails(cat).displayText}: ${selectedProducts[cat].length} ${t.B3MG(pcsMsg)}`
        : null;
    })
      .filter(line => line)
      .join(', ')}
  </div>
);

export const Navigation = ({ onChangeCategory }: NavigationProps) => (
  <NavigationMenu>
    {SupportedModelCategories.map(category => {
      const { displayText, iconClass } = getProductCategoryDetails(category);
      return (
        <NavigationMenuItem
          key={category}
          id={category}
          linkType="clickable"
          icon={<CL.Icon color="brand-blue" icon={iconClass} size="l" />}
          label={displayText}
          onClick={() => {
            onChangeCategory(category);
          }}
        />
      );
    })}
  </NavigationMenu>
);

const Footer = ({ catalogProducts }: FooterProps) => {
  const { getValues } = useFormContext<DeviceSelection>();
  const selectedProductCodes = getValues('selectedProductCodes') || [];

  const selectedCatalogProducts = getSelectedCatalogProducts(catalogProducts, selectedProductCodes);
  return (
    <StickyFooter active={selectedProductCodes.length > 0}>
      <CL.Grid>
        <CL.GridRow justifyCenter className={dsClass.MARGIN_VERTICAL_3}>
          <CL.GridCol colWidthXS={2} colWidthS={3} colWidthL={5}>
            <div>
              <div>
                {t.Z875('Selected in total')}:{' '}
                <b>
                  {selectedProductCodes.length} {t.B3MG(pcsMsg)}
                </b>
              </div>
              <CategorySummary selectedProducts={selectedCatalogProducts} />
            </div>
          </CL.GridCol>
          <CL.GridCol colWidthXS={2} colWidthS={3} colWidthL={5} className={dsClass.TEXT_ALIGN_RIGHT}>
            <CL.Button color="linkblue" size="l" type="submit">
              {t.I62A(continueMsg)}
            </CL.Button>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </StickyFooter>
  );
};

export const CatalogProductSelectionForm = ({
  catalogProducts,
  imagesBaseUrl,
  contractPeriod,
}: CatalogProductsSelectionFormProps) => {
  const { getValues } = useFormContext<DeviceSelection>();
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [sortType, setSortType] = useState(SortType.ORDER_NEWEST);
  const [searchString, setSearchString] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<Record<string, Filters>>({});
  const [productCategory, setProductCategory] = useState<SupportedModelCategory>(SupportedModelCategory.PHONE);
  const [orderedCatalogProducts, setOrderedCatalogProducts] =
    useState<Record<string, CatalogProduct[]>>(catalogProducts);

  if (!selectedFilters[productCategory]) {
    const filters: Record<string, Filters> = {};
    SupportedModelCategories.forEach(category => {
      filters[category] = createFilters(catalogProducts[category], category);
    });
    setSelectedFilters(filters);
    setOrderedCatalogProducts(sortAndFilterProducts(searchString, catalogProducts, sortType, filters));
  }

  const onChangeCategory = (value: SupportedModelCategory) => {
    setProductCategory(value);
    setOrderedCatalogProducts(sortAndFilterProducts(searchString, catalogProducts, sortType, selectedFilters));
  };

  const onChangeFilters = (updatedFilters: Filters) => {
    const newFilters = { ...selectedFilters, [productCategory]: updatedFilters };
    setSelectedFilters(newFilters);
    setOrderedCatalogProducts(sortAndFilterProducts(searchString, catalogProducts, sortType, newFilters));
  };

  const onSearch = (value: string) => {
    setSearchString(value);
    setOrderedCatalogProducts(sortAndFilterProducts(value, catalogProducts, sortType, selectedFilters));
  };

  const onChangeSortType = (value: SortType) => {
    setSortType(value);
    setOrderedCatalogProducts(sortAndFilterProducts(searchString, catalogProducts, value, selectedFilters));
  };

  const toggleFilters = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const onShowSelectedProductsFirst = () => {
    const selectedProductCodes = getValues('selectedProductCodes') || [];
    const sortedProducts = { ...orderedCatalogProducts };
    sortedProducts[productCategory] = [
      ...orderedCatalogProducts[productCategory].filter(p => selectedProductCodes.includes(p.code)),
      ...orderedCatalogProducts[productCategory].filter(p => !selectedProductCodes.includes(p.code)),
    ];
    setOrderedCatalogProducts(sortedProducts);
  };

  return (
    <>
      <HeroHeading
        center={false}
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
          { name: t.YVR4('Device list products') },
        ]}
        heroHeadingType={HeroHeadingType.BLANK}
        title={getProductCategoryDetails(productCategory).displayText}
        subtitle={
          contractPeriod
            ? `${t.V3UP('Device contract period')} ${contractPeriod} ${t.UGPA('month(s). Quoted prices VAT 0%')}`
            : undefined
        }
      />
      <div className="of-catalog-products-selection__border-bottom">
        <Grid colWidthL={10}>
          <Navigation onChangeCategory={onChangeCategory} />
        </Grid>
      </div>
      <CL.Grid className={dsClass.PADDING_TOP_2}>
        <CL.GridRow justifyCenter>
          <CL.GridCol colsXS={4} colsM={6} colsL={10} colsXL={10}>
            <div className="of-catalog-products-selection__sortandfilter">
              <div>
                <ProductGridSearchCL placeholder={t.VQE6(searchMsg)} onChange={onSearch} value={searchString} />
              </div>
              <div>
                <ToggleButton buttonLabel={t.XUFV(filterMsg)} isOpen={filtersExpanded} onClick={toggleFilters} />
              </div>
              <div>
                <ProductGridSort onChange={onChangeSortType} />
              </div>
              <div>
                <CL.Button type="button" color="link" onClick={onShowSelectedProductsFirst}>
                  <span>
                    <CL.Icon className={dsClass.MARGIN_RIGHT_2} icon="update" size="s" />
                    <span className="of-file-input--controls--button-text">{t.P9S4('Show selected first')}</span>
                  </span>
                </CL.Button>
              </div>
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
      {filtersExpanded && selectedFilters[productCategory] && (
        <Grid>
          <div className={`of-catalog-products-selection__filter-content ${dsClass.FONT_SIZE_SMALL}`}>
            <ProductGridFilter
              filters={selectedFilters[productCategory]}
              onChange={(updatedFilters: Filters) => {
                onChangeFilters(updatedFilters);
              }}
              onClose={() => setFiltersExpanded(false)}
            />
          </div>
        </Grid>
      )}
      <Grid key={productCategory}>
        <CatalogSelectionDeviceTable
          products={orderedCatalogProducts[productCategory]}
          imagesBaseUrl={imagesBaseUrl}
          accessories={orderedCatalogProducts.ACCESSORIES}
          productCategory={productCategory}
        />
      </Grid>
      <Footer catalogProducts={catalogProducts} />
    </>
  );
};
