import { Catalog, EppSolutionResponse } from '../../generated/api/models.js';
import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  BillingAccount,
  CommercialProduct,
  CompanyInfoResponse,
  ContactPerson,
  ContactsDuplicateCheckResponse,
  DeliveryMethod,
  DeliveryMethodsResponse,
  EnrollmentProgram,
  GenericError,
  Offer,
  OnlineModel,
} from '../../generated/api/models.js';
import type { CartItemContainer } from '../../components/ProductDetails/ProductDetails.js';
import type { CartUpdateAnalyticsData } from '../common/shopping-cart/shoppingCartInterfaces.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../components/DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { OnlineModelsState } from '../../common/types/states.js';
import type {
  PhoneNumberOwner,
  PurposeOfUseOrContact,
  SelectedPhoneNumber,
  SimCardConfiguration,
} from '../../common/types/subscription.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { ValidatedSim } from '../../common/types/sim.js';

export type CartValidationRules = {
  eppAllowed: boolean;
  oneTimeAllowed: boolean;
  recurringAllowed: boolean;
  contractPeriod?: number;
  corporateShare?: number;
  products?: string[];
};

const defaultValidationRules = {
  eppAllowed: false,
  oneTimeAllowed: true,
  recurringAllowed: true,
};

/**
 * Edit with caution by not adding any unnecessary stuff in this object
 * Moving of existing configs given to addToCart could possibly be moved in here
 * Idea of this config is to go through the chain of addToCart -> processCartItems -> postProcessCartItems
 * This way any information known when calling addToCart can be passed to later steps
 * At least in beginning this will be used by frosmo
 * In later steps of the refactorings these long chains will no longer exist, but that requires renewing of cart model
 */
export interface CartConfig {
  skipToggle: boolean;
}

export const eppSolutionToValidationRules = (eppSolution?: EppSolutionResponse) =>
  eppSolution?.eppSolutionStatus === EppSolutionResponse.EppSolutionStatusEnum.ACTIVE
    ? {
        eppAllowed: true,
        oneTimeAllowed: eppSolution.allowDevicesWithOneTimeCharges ?? false,
        recurringAllowed: eppSolution.allowDevicesWithRecurringCharges ?? false,
      }
    : defaultValidationRules;

export const catalogToValidationRules = (catalog?: Catalog) =>
  catalog
    ? {
        eppAllowed: catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING,
        oneTimeAllowed: catalog.productType === Catalog.ProductTypeEnum.ONETIME,
        recurringAllowed: catalog.productType === Catalog.ProductTypeEnum.RECURRING,
        contractPeriod: catalog.contractPeriod,
        corporateShare: catalog.corporateShare,
        products: catalog.products,
      }
    : defaultValidationRules;

export interface AbstractProcessCartItemAction extends Action {
  cartItemJson?: string;
  validationRules?: CartValidationRules;
  cartUpdated: boolean;
  isEmployee: boolean;
  latestItemAddedToCart?: string;
  latestItemAddedToCartTimestamp?: number;
}

export interface ProcessCartItemsAction extends AbstractProcessCartItemAction {
  cartConfig?: CartConfig;
  type: TypeKeys.PROCESS_CART_ITEMS;
}

export interface PostProcessCartItemsAction extends AbstractProcessCartItemAction {
  cartConfig?: CartConfig;
  updateStateOnly?: boolean;
  type: TypeKeys.POST_PROCESS_CART_ITEMS;
}

export interface SetCartUpdatedAction extends Action {
  cartUpdated: boolean;
  type: TypeKeys.SET_CART_UPDATED;
}

export const setCartUpdated = (cartUpdated: boolean): SetCartUpdatedAction => {
  return {
    cartUpdated,
    type: TypeKeys.SET_CART_UPDATED,
  };
};

export const processCartItems = (
  cartUpdated: boolean,
  isEmployee: boolean,
  cartItemJson?: string,
  validationRules?: CartValidationRules,
  cartConfig?: CartConfig,
  latestItemAddedToCart?: string,
  latestItemAddedToCartTimestamp?: number
): ProcessCartItemsAction => {
  return {
    cartConfig,
    cartItemJson,
    cartUpdated,
    validationRules,
    isEmployee,
    latestItemAddedToCart,
    latestItemAddedToCartTimestamp,
    type: TypeKeys.PROCESS_CART_ITEMS,
  };
};

export const postProcessCartItems = (
  cartUpdated: boolean,
  isEmployee: boolean,
  cartItemJson?: string,
  validationRules?: CartValidationRules,
  cartConfig?: CartConfig,
  latestItemAddedToCart?: string,
  latestItemAddedToCartTimestamp?: number,
  updateStateOnly?: boolean
): PostProcessCartItemsAction => {
  return {
    cartConfig,
    cartItemJson,
    cartUpdated,
    validationRules,
    isEmployee,
    latestItemAddedToCart,
    latestItemAddedToCartTimestamp,
    updateStateOnly,
    type: TypeKeys.POST_PROCESS_CART_ITEMS,
  };
};

export interface AddToCartAction extends CrudAction<TypeKeys.ADD_TO_CART> {
  cartItemContainer: CartItemContainer;
  type: TypeKeys.ADD_TO_CART;
  onAddToCart?: () => void;
  cartConfig?: CartConfig;
}

export const addToCart = (
  cartItemContainer: CartItemContainer,
  onAddToCart?: () => void,
  cartConfig?: CartConfig
): AddToCartAction => {
  return {
    cartConfig,
    cartItemContainer,
    id: generateActionId(),
    onAddToCart,
    type: TypeKeys.ADD_TO_CART,
  };
};

export interface UpdateCartItemAction extends CrudAction<TypeKeys.UPDATE_CART_ITEM> {
  addOnsToBeRemoved?: string[];
  cartItem: ShoppingCartItemForCheckout;
  newQuantity: number;
  onUpdateCart?: () => void;
}

export const updateCartItem = (
  cartItem: ShoppingCartItemForCheckout,
  newQuantity: number,
  addOnsToBeRemoved?: string[],
  onUpdateCart?: () => void
): UpdateCartItemAction => {
  return {
    addOnsToBeRemoved,
    cartItem,
    id: generateActionId(),
    newQuantity,
    onUpdateCart,
    type: TypeKeys.UPDATE_CART_ITEM,
  };
};

export interface UpdateCartItemPaymentAction extends CrudAction<TypeKeys.UPDATE_CART_ITEM_PAYMENT> {
  cartItem: ShoppingCartItemForCheckout;
  // We always should have at least one commercialProduct
  newCommercialProducts: CommercialProduct[];
  offer: Offer;
  onlineModel: OnlineModel;
}

export const updateCartItemPayment = (
  cartItem: ShoppingCartItemForCheckout,
  newCommercialProducts: CommercialProduct[],
  offer: Offer,
  onlineModel: OnlineModel
): UpdateCartItemPaymentAction => {
  return {
    cartItem,
    id: generateActionId(),
    newCommercialProducts,
    offer,
    onlineModel,
    type: TypeKeys.UPDATE_CART_ITEM_PAYMENT,
  };
};

export interface ProcessDeliveryDetailsAction extends Action {
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  contactPerson?: ContactPerson;
  approverContact?: string;
  isEmployee?: boolean;
  isOmaLaiteLasku?: boolean;
  moveToNextStep?: boolean;
  errors?: CommonError[];
  type: TypeKeys.PROCESS_DELIVERY_DETAILS;
}

export const processDeliveryDetails = (
  deliveryDetails: DeviceCheckoutDeliveryDetailsType,
  errors?: CommonError[],
  customerType?: CompanyInfoResponse.CustomerTypeEnum,
  contactPerson?: ContactPerson,
  approverContact?: string,
  isEmployee?: boolean,
  isOmaLaiteLasku?: boolean,
  moveToNextStep?: boolean
): ProcessDeliveryDetailsAction => {
  return {
    contactPerson,
    customerType,
    approverContact,
    deliveryDetails,
    errors,
    isEmployee,
    isOmaLaiteLasku,
    moveToNextStep,
    type: TypeKeys.PROCESS_DELIVERY_DETAILS,
  };
};

export interface ProcessBillingAccountAction extends Action {
  newBillingAccount: BillingAccount;
  newBillingAccountValidationErrors?: CommonError[];
  type: TypeKeys.PROCESS_BILLING_ACCOUNT;
}
export interface CartItemAdditionalParameters {
  purposeOfUseOrContact: PurposeOfUseOrContact;
  numberPublicity?: boolean;
  simCardConfiguration?: SimCardConfiguration;
  selectedPhoneNumber?: SelectedPhoneNumber;
  phoneNumberOwner?: PhoneNumberOwner;
  enrollmentProgramConsent: boolean;
  additionalFields: { [key: string]: string };
  enrollmentProgramAlias: string;
}

export interface ProcessAdditionalParametersInCartItemsAction
  extends CrudAction<TypeKeys.PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS> {
  validateOnly: boolean;
  /**
   * Every cart item has random unique identifier (for every instance of the item, such as uuid-0, uuid-1 etc).
   * This contains parameters for every product.
   */
  cartItemAdditionalParameters: Record<string, CartItemAdditionalParameters[]>;
  hasFormErrors: boolean;
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType;
  enrollmentPrograms?: EnrollmentProgram[];
  onlineModels?: OnlineModelsState;
  validatedSims?: ValidatedSim[];
  type: TypeKeys.PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS;
}

export const processAdditionalParametersInCartItems = (
  validateOnly: boolean,
  cartItemAdditionalParameters: Record<string, CartItemAdditionalParameters[]>,
  hasFormErrors: boolean,
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType,
  enrollmentPrograms?: EnrollmentProgram[],
  onlineModels?: OnlineModelsState,
  validatedSims?: ValidatedSim[]
): ProcessAdditionalParametersInCartItemsAction => {
  return {
    validateOnly,
    cartItemAdditionalParameters,
    hasFormErrors,
    deliveryDetails,
    enrollmentPrograms,
    onlineModels,
    validatedSims,
    id: generateActionId(),
    type: TypeKeys.PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS,
  };
};

export type DuplicateContactsCheckAction = CrudAction<TypeKeys.DUPLICATE_CONTACTS_CHECK>;

export const duplicateContactsCheck = (): DuplicateContactsCheckAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.DUPLICATE_CONTACTS_CHECK,
  };
};

export interface DuplicateContactCheckFulfilledAction extends Action {
  response: ContactsDuplicateCheckResponse;
  type: TypeKeys.DUPLICATE_CONTACTS_CHECK_FULFILLED;
}

export const duplicatContactsCheckFulfilled = (response?: AjaxResponse): DuplicateContactCheckFulfilledAction => {
  return {
    response: {
      duplicateContacts: response?.response.duplicateContacts,
    },
    type: TypeKeys.DUPLICATE_CONTACTS_CHECK_FULFILLED,
  };
};

export type DuplicateContactsCheckFailedAction = ErrorAction<TypeKeys.DUPLICATE_CONTACTS_CHECK_FAILED>;

export const duplicateContactsCheckFailed: ErrorActionCreator<TypeKeys.DUPLICATE_CONTACTS_CHECK_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): DuplicateContactsCheckFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.DUPLICATE_CONTACTS_CHECK_FAILED,
  };
};

export interface DuplicateContactCheckSubmitAction extends Action {
  cartItems: ShoppingCartItemForCheckout[];
  type: TypeKeys.DUPLICATE_CONTACTS_CHECK_SUBMIT;
}

export const submitDuplicateContactCheck = (
  cartItems: ShoppingCartItemForCheckout[]
): DuplicateContactCheckSubmitAction => {
  return {
    cartItems,
    type: TypeKeys.DUPLICATE_CONTACTS_CHECK_SUBMIT,
  };
};

export interface UpdateSelectedBaIdAction extends Action {
  selectedBaId: string;
  type: TypeKeys.UPDATE_SELECTED_BA_ID;
}

export const updateSelectedBaId = (selectedBaId: string): UpdateSelectedBaIdAction => {
  return {
    selectedBaId,
    type: TypeKeys.UPDATE_SELECTED_BA_ID,
  };
};

export interface ResetMoveToNextStepAction extends Action {
  type: TypeKeys.RESET_MOVE_TO_NEXT_STEP;
}

export const resetMoveToNextStep = (): ResetMoveToNextStepAction => {
  return {
    type: TypeKeys.RESET_MOVE_TO_NEXT_STEP,
  };
};

export interface ClearCartItemsAction extends CrudAction<TypeKeys.CLEAR_CART_ITEMS> {
  forceReload?: boolean;
}

export const clearCartItems = (forceReload?: boolean): ClearCartItemsAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.CLEAR_CART_ITEMS,
    forceReload,
  };
};

export interface ForceBasketItemsToStateAction extends CrudAction<TypeKeys.FORCE_BASKET_ITEMS_TO_STATE> {
  items: ShoppingCartItemForCheckout[];
}

export const forceBasketItemsToState = (items: ShoppingCartItemForCheckout[]): ForceBasketItemsToStateAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.FORCE_BASKET_ITEMS_TO_STATE,
    items,
  };
};

export interface ClearBasketItemsAction extends Action {
  type: TypeKeys.CLEAR_BASKET_ITEMS;
}

export const clearBasketItems = (): ClearBasketItemsAction => {
  return {
    type: TypeKeys.CLEAR_BASKET_ITEMS,
  };
};

export interface ClearDeliveyDetailsAction extends Action {
  type: TypeKeys.CLEAR_DELIVERY_DETAILS;
}

export const clearDeliveryDetails = (): ClearDeliveyDetailsAction => {
  return {
    type: TypeKeys.CLEAR_DELIVERY_DETAILS,
  };
};

export interface AddCartUpdateEventToAnalyticsAction extends CrudAction<TypeKeys.ADD_CART_EVENT_UPDATE_TO_ANALYTICS> {
  cartUpdateEventData: CartUpdateAnalyticsData[];
  type: TypeKeys.ADD_CART_EVENT_UPDATE_TO_ANALYTICS;
}

export const addCartUpdateEventToAnalytics = (
  cartUpdateEventData: CartUpdateAnalyticsData[]
): AddCartUpdateEventToAnalyticsAction => {
  return {
    cartUpdateEventData,
    id: generateActionId(),
    type: TypeKeys.ADD_CART_EVENT_UPDATE_TO_ANALYTICS,
  };
};

export interface LoadDeliveryMethodsAction extends CrudAction<TypeKeys.GET_DELIVERY_METHODS> {
  type: TypeKeys.GET_DELIVERY_METHODS;
  productType?: string[];
}

export const loadDeliveryMethods = (productType?: string[]): LoadDeliveryMethodsAction => {
  return {
    productType: productType,
    type: TypeKeys.GET_DELIVERY_METHODS,
    id: generateActionId(),
  };
};

export interface LoadDeliveryMethodsFulfilledAction extends Action {
  response: DeliveryMethod[];
  type: TypeKeys.GET_DELIVERY_METHODS_FULFILLED;
}

export const loadDeliveryMethodsFulfilled = (response: DeliveryMethodsResponse): LoadDeliveryMethodsFulfilledAction => {
  return {
    response: response?.deliveryMethods,
    type: TypeKeys.GET_DELIVERY_METHODS_FULFILLED,
  };
};

export type LoadDeliveryMethodsFailedAction = ErrorAction<TypeKeys.GET_DELIVERY_METHODS_FAILED>;

export const loadDeliveryMethodsFailed: ErrorActionCreator<TypeKeys.GET_DELIVERY_METHODS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadDeliveryMethodsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.GET_DELIVERY_METHODS_FAILED,
  };
};

export type DeviceCheckoutActionTypes =
  | AddCartUpdateEventToAnalyticsAction
  | AddToCartAction
  | UpdateCartItemAction
  | UpdateCartItemPaymentAction
  | PostProcessCartItemsAction
  | ProcessCartItemsAction
  | ProcessDeliveryDetailsAction
  | ProcessBillingAccountAction
  | ProcessAdditionalParametersInCartItemsAction
  | UpdateSelectedBaIdAction
  | ResetMoveToNextStepAction
  | ClearCartItemsAction
  | SetCartUpdatedAction
  | DuplicateContactsCheckAction
  | DuplicateContactCheckFulfilledAction
  | DuplicateContactsCheckFailedAction
  | DuplicateContactCheckSubmitAction
  | LoadDeliveryMethodsAction
  | LoadDeliveryMethodsFulfilledAction
  | LoadDeliveryMethodsFailedAction
  | ClearDeliveyDetailsAction
  | ForceBasketItemsToStateAction
  | ClearBasketItemsAction;
