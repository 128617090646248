import { EOECustomerOrderDetails } from '../../../../../components/CustomerOrderDetails/EOECustomerOrderDetails';
import { EmployeeOrdersWrapper } from '../../../../../components/EmployeeOrdersWrapper/EmployeeOrdersWrapper.js';
import { findItemById } from '../../../../../common/utils/stateUtils.js';
import { generatePath, useParams } from 'react-router-dom';
import {
  getFilteredCustomerOrders,
  getOrderContactPerson,
  getOrdererSubscription,
} from '../../../../../common/utils/employeeUtils.js';
import {
  loadCompanyInfo,
  loadCustomerOrderAdditionalInfo,
  loadCustomerOrders,
} from '../../../../../selfservice/actions/index.js';
import { omaElisaForEmployeeMsg, orderRequestsMsg, t } from '../../../../../common/i18n/index.js';
import { onLoadBillingAccounts } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const PendingReviewPath = () => {
  const { subscriptionId } = useParams<SubscriptionId>();
  const dispatch = useDispatch();
  const { customerOrders, customerType } = useSelector((state: State) => ({
    customerOrders: state.selfservice?.customerOrders,
    customerType: state.selfservice?.companyInfo?.customerType,
  }));
  const filteredCustomerOrders = getFilteredCustomerOrders(customerOrders, true);
  const customerOrder = findItemById('customerOrderDisplayId', subscriptionId, filteredCustomerOrders);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.Y29L(orderRequestsMsg), customerOrder?.customerOrderDisplayId ?? '']);

  useEffect(() => {
    dispatch(loadCompanyInfo());
    onLoadBillingAccounts(dispatch);
    dispatch(loadCustomerOrders(subscriptionId, undefined));
    if (customerOrder?.customerOrderDisplayId) {
      dispatch(loadCustomerOrderAdditionalInfo(customerOrder?.customerOrderDisplayId));
    }
  }, [dispatch, subscriptionId, customerOrder]);

  return (
    <EmployeeOrdersWrapper>
      <EOECustomerOrderDetails
        buildDeliveryOrderHref={(cid: string, did: string) =>
          generatePath(paths.EMPLOYEE_PENDING_REVIEW_ORDER_ITEM, { subscriptionId: cid, deliveryId: did })
        }
        ordererContact={getOrderContactPerson(
          filteredCustomerOrders?.additionalInfo,
          customerOrder?.customerOrderDisplayId
        )}
        customerOrder={customerOrder}
        deviceSubscriptions={getOrdererSubscription(
          filteredCustomerOrders?.additionalInfo,
          customerOrder?.customerOrderDisplayId
        )}
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.Y29L(orderRequestsMsg), path: paths.EMPLOYEE_PENDING_REVIEW_ORDERS },
          { name: customerOrder?.customerOrderDisplayId || '…' },
        ]}
        customerType={customerType}
      />
    </EmployeeOrdersWrapper>
  );
};
