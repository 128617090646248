import * as CL from '@design-system/component-library';
import { DetailsWrapper } from '../DetailsWrapper';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { Loading } from '../Loading';
import { Status } from '../Status/Status';
import {
  billingAccountMsg,
  deductibleMsg,
  deviceMsg,
  idNumberMsg,
  monthlyChargesMsg,
  squareTradeDeductibleMsg,
  statusMsg,
  t,
} from '../../common/i18n';
import { formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getSubscriptionStatus, getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type * as CLT from '@design-system/component-library';
import type { AddOn, Subscription, SubscriptionAction } from '../../generated/api/models.js';

import './AddonDetails.scss';

export interface AddonDetailsProps {
  breadCrumbs: JSX.Element;
  addOn?: AddOn;
  subscription?: Subscription;
  subscriptionActions?: SubscriptionAction[];
  subscriptionPath: string;
}

export const AddonDetails = ({
  breadCrumbs,
  addOn,
  subscription,
  subscriptionActions,
  subscriptionPath,
}: AddonDetailsProps) => {
  const { search } = useLocation();
  const addonDisplayId = addOn ? addOn.addOnId : '…';
  const addOnName = addOn ? addOn.addOnProductName : '...';
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '';

  if (!(addOn && subscription)) {
    return (
      <DetailsWrapper
        classes={['of-addon-details']}
        detailsTop={breadCrumbs}
        id={`addon-details-${addonDisplayId}`}
        heading={`${addOnName}`}
        headingBottom={isSquareTradeAddOn(addOn) ? t.E0RW('Additional warranty for device') : undefined}
        heroPicto="square-trade"
      >
        <Loading />
      </DetailsWrapper>
    );
  }

  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions);

  const fields: CLT.DescriptionItem[] = [
    {
      title: t.ASQT(statusMsg),
      description: <Status color={subscriptionStatus.color} textAfterBadge={subscriptionStatus.text} />,
    },
    {
      title: t.YTH3(idNumberMsg),
      description: subscriptionDisplayId ?? '—',
    },
  ];

  if (subscription.billingAccountDisplayId) {
    fields.push({
      title: t.IFT9(billingAccountMsg),
      description: (
        <Link
          to={generatePath(paths.BILLING_ACCOUNT, { billingAccountId: subscription.billingAccountDisplayId }) + search}
        >
          {subscription.billingAccountDisplayId}
        </Link>
      ),
    });
  }

  const formattedStartDate = formatTimestampToUTCDDMMYYYY(subscription.startDate);
  if (formattedStartDate !== undefined) {
    fields.push({
      title: t.STLY('Contract start date'),
      description: formattedStartDate,
    });
  }

  fields.push({
    title: t.P6BC(monthlyChargesMsg),
    description: formatSum(addOn.addOnMonthlyRecurringCharge),
  });

  fields.push({
    title: t.TPVQ(deviceMsg),
    description: (
      <Link to={generatePath(subscriptionPath, { subscriptionId: getSubscriptionUrlId(subscription) }) + search}>
        {subscription.subscriptionName}
      </Link>
    ),
  });

  if (isSquareTradeAddOn(addOn)) {
    fields.push({
      title: t.VWSV(deductibleMsg),
      description: squareTradeDeductibleMsg,
    });
  }

  return (
    <DetailsWrapper
      classes={['of-addon-details']}
      detailsTop={breadCrumbs}
      id={`addon-details-${addonDisplayId}`}
      heading={`${addOnName}`}
      headingBottom={isSquareTradeAddOn(addOn) ? t.E0RW('Additional warranty for device') : undefined}
      heroPicto="square-trade"
    >
      <div className="of-addon-details__content">
        <CL.Description items={fields} />
        {isSquareTradeAddOn(addOn) && (
          <div className="of-addon-details__disclaimer ea-disclaimertext">
            <p>
              {t.HZ90(
                'In the event of damage, please contact SquareTrade’s customer service. Tel. 0800 915 801, Mon—Fri 9am-7pm, Sat 10am—4.30pm. You can also submit a damage report via the website'
              )}{' '}
              <a href="https://www.squaretrade.fi">www.squaretrade.fi</a>
            </p>
          </div>
        )}
      </div>
    </DetailsWrapper>
  );
};
