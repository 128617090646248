import { CorporateWebHostingForm, defaultHostingOption } from './forms/CorporateWebHostingForm.js';
import { CustomFormThankYouForOrder, CustomFormType, createPrices } from './CustomFormFields.js';
import { DialogiMessagingServiceForm } from './forms/DialogiMessagingServiceForm.js';
import { DomainNameForm } from './forms/DomainNameForm.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Lead } from '../../generated/api/models.js';
import { LeadForm } from './forms/LeadForm.js';
import { Microsoft365Form } from './forms/Microsoft365Form.js';
import { addAnonymousLeadFulfilled } from '../../selfservice/actions/index.js';
import { getCookie } from '../../common/utils/cookieUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { postAnonymousLead } from '../../common/fetch.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { CustomFormData } from '../cmsSchema.js';
import type { PostAnonymousLeadRequest } from '../../generated/api/models.js';
import type { PriceMaps } from './CustomFormFields.js';

import './CustomForm.scss';

export enum CustomFormIds {
  CORPORATE_WEB_HOSTING = 'CORPORATE_WEBHOSTING',
  DIALOGI_MESSAGING_SERVICE = 'DIALOGI_MESSAGING_SERVICE',
  DOMAIN_NAME = 'DOMAIN_NAME',
  LEAD_FORM = 'LEAD_FORM',
  MICROSOFT_365 = 'MICROSOFT_365',
}

export interface CustomFormProps extends CustomFormData {
  leadInProgress?: boolean;
  leadFailed?: boolean;
  enableCompanyFields?: boolean;
  // TODO are these actually used for anything?
  initialValues: PriceMaps;
}

export interface CustomFormDataForLead {
  businessId: string;
  businessName: string;
  consentForMarketing?: boolean;
  email: string;
  firstName: string;
  interestArea?: Lead.InterestAreaEnum;
  lastName: string;
  message: string;
  phoneNumber: string;
}

// These are _only_ used by CorporateWebHostingForm
export const createInitialNonEmptyValues = (id: string) => {
  const periodicPriceMap = new Map();
  const onetimePriceMap = new Map();
  if (id === CustomFormIds.CORPORATE_WEB_HOSTING) {
    const defaultItem = defaultHostingOption();
    if (defaultItem) {
      if (defaultItem.oneTimeCharge) {
        onetimePriceMap?.set(CustomFormType.OPTION, defaultItem.oneTimeCharge);
      }
      if (defaultItem.monthlyRecurringCharge) {
        periodicPriceMap?.set(CustomFormType.OPTION, defaultItem.monthlyRecurringCharge);
      }
    }
  }

  return createPrices(periodicPriceMap, onetimePriceMap);
};

type StoredParametersCookie = {
  gaSource?: string;
  gaMedium?: Lead.GaMediumEnum;
  gaCampaign?: string;
  gaContent?: string;
  gaTerm?: string;
  gaClientId?: string;
  gaSessionId?: string;
};

const SP_KEY_MAP: [string, keyof StoredParametersCookie][] = [
  ['source', 'gaSource'],
  ['medium', 'gaMedium'],
  ['campaign', 'gaCampaign'],
  ['content', 'gaContent'],
  ['term', 'gaTerm'],
  ['ga_client_id', 'gaClientId'],
  ['ga_session_id', 'gaSessionId'],
];

const getStoredParametersCookie = () => {
  const cookieString = getCookie('stored_parameters');
  if (cookieString != null) {
    try {
      const cookie = JSON.parse(decodeURIComponent(cookieString));
      const storedParameters: StoredParametersCookie = SP_KEY_MAP.reduce((spc, field) => {
        const value = cookie[field[0]];
        if (typeof value === 'string') {
          if (field[1] === 'gaMedium') {
            if (value in Lead.GaMediumEnum) {
              spc[field[1]] = Lead.GaMediumEnum[value as keyof typeof Lead.GaMediumEnum];
            }
          } else if (value !== '' && value != null) {
            spc[field[1]] = value;
          }
        }
        return spc;
      }, {} as StoredParametersCookie);
      return storedParameters;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

const mapLeadFormDataToLeadRequest = (
  values: CustomFormDataForLead,
  campaignId?: string,
  sourceUrl?: string
): PostAnonymousLeadRequest => {
  if (!isInBrowser()) {
    throw new Error('Not in browser environment. Unable to send lead-form.');
  }
  const storedParametersCookie = getStoredParametersCookie();
  return {
    contactDetails: {
      businessId: values.businessId,
      businessName: values.businessName,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
    },
    lead: {
      ...(campaignId !== '' && campaignId != null ? { campaignId } : {}),
      ...(storedParametersCookie ? storedParametersCookie : {}),
      consentForMarketing: Boolean(values.consentForMarketing),
      consentToContact: true,
      description: values.message,
      interestArea: campaignId ? Lead.InterestAreaEnum.CAMPAIGN_DEFINED_AREA : values.interestArea,
      leadType: Lead.LeadTypeEnum.ONLINE_CONTACT,
      ...(sourceUrl !== '' && sourceUrl != null ? { sourceUrl } : {}),
    },
  };
};

const mapFormParameters = (data: CustomFormDataForLead) => {
  const formParameters = {};
  const filterList = [
    'businessId',
    'businessName',
    'companySearch',
    'domainSearch',
    'email',
    'phoneNumber',
    'firstName',
    'lastName',
    'onetimePriceMap',
    'periodicPriceMap',
  ];

  for (const [key, value] of Object.entries(data)) {
    if (filterList.indexOf(key) === -1) {
      if (typeof value === 'object') {
        if (value instanceof Map) {
          // @ts-ignore
          formParameters[key] = JSON.stringify(Object.entries(Object.fromEntries(value)));
        } else {
          if (key === CustomFormType.M365) {
            // This is required because the format in which m365 data is passed on is a bit specific
            const m365Value = Object.entries(value);
            // @ts-ignore
            formParameters[key] = JSON.stringify(m365Value);
          } else {
            // @ts-ignore
            formParameters[key] = JSON.stringify(value);
          }
        }
      } else {
        // @ts-ignore
        formParameters[key] = value;
      }
    }
  }
  return formParameters;
};

const mapCustomFormDataToLeadRequest = (data: CustomFormDataForLead): PostAnonymousLeadRequest => {
  if (!isInBrowser()) {
    throw new Error('Not in browser environment. Unable to send custom-form.');
  }
  return {
    contactDetails: {
      businessId: data.businessId,
      businessName: data.businessName,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
    },
    lead: {
      formParameters: mapFormParameters(data),
      leadType: Lead.LeadTypeEnum.ONLINE_ORDER,
      sourceUrl: window.location.href,
    },
  };
};

const CustomFormElement = (props: CustomFormProps) => {
  const { id } = props;
  switch (id) {
    case CustomFormIds.MICROSOFT_365:
      return <Microsoft365Form {...props} />;
    case CustomFormIds.DOMAIN_NAME:
      return <DomainNameForm {...props} />;
    case CustomFormIds.CORPORATE_WEB_HOSTING:
      return <CorporateWebHostingForm {...props} />;
    case CustomFormIds.DIALOGI_MESSAGING_SERVICE:
      return <DialogiMessagingServiceForm {...props} />;
    case CustomFormIds.LEAD_FORM:
      return <LeadForm {...props} />;
    default:
      return <></>;
  }
};

const CustomFormComponent = (props: CustomFormProps) => {
  const dispatch = useDispatch();
  const { campaignId, thankYouForOrderMessage } = props;
  const [leadInProgress, setLeadInProgress] = useState(false);
  const [leadSent, setLeadSent] = useState(false);
  const [leadFailed, setLeadFailed] = useState(false);
  const [searchParams] = useSearchParams();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...{
        businessId: '',
        businessName: '',
        email: '',
        existingDomain: '',
        firstName: '',
        lastName: '',
        mainUserEmail: '',
        mainUserName: '',
        mainUserPhone: '',
        message: '',
        phoneNumber: '',
        [CustomFormType.M365]: [],
      },
    },
  });

  const scrollTo = useRef<HTMLInputElement>(null);
  const executeScroll = () => scrollTo?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const onSubmit = async (values: CustomFormDataForLead) => {
    setLeadFailed(false);
    setLeadInProgress(true);
    const sourceUrl =
      searchParams.get('sourceUrl') ?? (document?.referrer && new URL(document.referrer).pathname) ?? undefined;
    const request =
      props.id === CustomFormIds.LEAD_FORM
        ? mapLeadFormDataToLeadRequest(values, campaignId, sourceUrl)
        : mapCustomFormDataToLeadRequest(values);
    const result = await postAnonymousLead(request);
    if (result.ok) {
      setLeadSent(true);
      // we use dispatch here to trigger manually the analytics event which was previously done 'inside' redux
      // remove/update this once analytics also uses fetch and/or have been re-architectured
      dispatch(addAnonymousLeadFulfilled(request));
      executeScroll();
    } else {
      setLeadFailed(true);
    }
    setLeadInProgress(false);
  };

  return (
    <div ref={scrollTo}>
      {leadSent ? (
        <CustomFormThankYouForOrder message={thankYouForOrderMessage ?? t.O4R6('Thank you for your order!')} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <CustomFormElement leadInProgress={leadInProgress} leadFailed={leadFailed} {...props} />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export const CustomForm = (data: CustomFormData) => {
  return <CustomFormComponent {...data} initialValues={createInitialNonEmptyValues(data.id)} />;
};
