import { AuthenticatedUserRole } from '../../generated/api/authenticatedUserRole.js';
import { SHOPPING_BASKET_LOCAL_STORAGE_KEY } from '../../selfservice/common/localStorageUtils.js';
import { ShoppingCart } from '../../generated/api/shoppingCart.js';
import { createShoppingBasket, updateShoppingBasket } from '../../common/fetch.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { submitGiosg } from '../utils/giosgUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSyncExternalStore } from 'react';
import type { ShoppingBasketType } from '../types/shoppingBasket';

const createEmptyBasket = (): ShoppingBasketType => {
  return {
    id: undefined, // ID will be generated when posting to ui-api
    items: [],
    vouchers: [],
  };
};

export const parseBasketJson = (rawBasketData?: string): ShoppingBasketType => {
  try {
    if (rawBasketData) {
      return JSON.parse(rawBasketData) as ShoppingBasketType;
    }
  } catch (error) {
    // eslint-disable-next-line
    console.error('Failed to parse basket JSON:', error);
  }
  return createEmptyBasket();
};

export const getShoppingBasketFromLocalStorage = (): string => {
  return (isInBrowser() && localStorage.getItem(SHOPPING_BASKET_LOCAL_STORAGE_KEY)) || '';
};

export const shouldSendBasketToApi = (userRole: AuthenticatedUserRole): boolean => {
  return ![AuthenticatedUserRole.EMPLOYEE, AuthenticatedUserRole.PUNCHOUT_USER].includes(userRole);
};

const createShoppingBasketApiRequest = (basket: ShoppingBasketType): ShoppingCart => {
  return {
    type: ShoppingCart.TypeEnum.ACCOUNT,
    items:
      basket.items?.map(item => ({
        offerCode: item.offer.guid,
        commercialProductCode: item.offer.commercialProductGuid,
        quantity: item.quantity,
      })) || [],
  };
};

/**
 * NOTE, after 1st phase, best to remove exporting this and just use useShoppingBasket() hook,
 * then the auth stuff won't be needed to pass forward. Keeping this exported since the calls comes
 * places (epics, utils), where hooks cannot be used.
 */
export const handleShoppingBasketUpdate = async (basket: string, sendShoppingBasketToApi: boolean) => {
  const prevBasket = getShoppingBasketFromLocalStorage();
  // Only set the basket when there's actual changes, otherwise syncing cart via LocalStorage between multiple tabs
  // will cause infinite loop.
  if (prevBasket !== basket) {
    const parsedBasket = parseBasketJson(basket);
    const timestamp = new Date().getTime();
    if (!parsedBasket.created) {
      parsedBasket.created = timestamp;
    }
    parsedBasket.modified = timestamp;
    const shoppingCartRequest = createShoppingBasketApiRequest(parsedBasket);

    if (sendShoppingBasketToApi) {
      const shoppingBasketResponse = parsedBasket.id
        ? await updateShoppingBasket(parsedBasket.id, shoppingCartRequest)
        : await createShoppingBasket(shoppingCartRequest);

      if (shoppingBasketResponse) {
        parsedBasket.id = shoppingBasketResponse.shoppingCartId;
      }
    }
    submitGiosg(parsedBasket);
    const parsedBasketAsString = JSON.stringify(parsedBasket);

    isInBrowser() && localStorage.setItem(SHOPPING_BASKET_LOCAL_STORAGE_KEY, parsedBasketAsString);
    window.dispatchEvent(
      new StorageEvent('storage', { key: SHOPPING_BASKET_LOCAL_STORAGE_KEY, newValue: parsedBasketAsString })
    );
  }
};

export function useShoppingBasket() {
  const { isAuthenticated, authenticatedUser } = useAuth();
  const sendShoppingBasketToApi =
    isAuthenticated && authenticatedUser?.userRole && shouldSendBasketToApi(authenticatedUser.userRole);
  const setShoppingBasket = (newValue: string) => {
    isInBrowser() && handleShoppingBasketUpdate(newValue, Boolean(sendShoppingBasketToApi));
  };

  if (!getShoppingBasketFromLocalStorage()) {
    isInBrowser() && localStorage.setItem(SHOPPING_BASKET_LOCAL_STORAGE_KEY, JSON.stringify(createEmptyBasket()));
  }
  const getSnapshot = () => getShoppingBasketFromLocalStorage();

  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener);
    return () => void window.removeEventListener('storage', listener);
  };

  const shoppingBasket = useSyncExternalStore(subscribe, getSnapshot, () => '');

  return { shoppingBasket, setShoppingBasket };
}
