// Use alphabetical order.
// Note, this file has line splitting by prettier effectively disabled (max length 9999) in prettierrc.json.
// Put here all English text strings that are used in multiple places.
// All shared test strings must have `Msg` suffix.

export const acceptTermsAndConditionMsg = 'Accept the terms and conditions';
export const accessRightsMsg = 'Access rights';
export const accessoriesMsg = 'Accessories';
export const accordingToTheBrandMsg = 'According to the brand';
export const activateMsg = 'Activate';
export const activateSimCardMsg = 'Activate SIM card';
export const activateSubMsg = 'Activate subscription';
export const activationFeeMsg = 'Activation fee';
export const activationFeeWithVarMsg = 'Activation fee {0}';
export const activationFeesWithVarMsg = 'Activation fee á {0}';
export const activeMsg = 'Active';
export const addAccessToOtherCompaniesAdditionalInformationMsg = 'You can also grant administrator rights to other companies to which you have access rights.';
export const addAccessToOtherCompaniesMsg = 'Add administrator rights to other companies';
export const addAttachmentMsg = 'Add attachment';
export const addBillingAccountMsg = 'Add billing account';
export const addBillingAgreementMsg = 'Add billing agreement';
export const addContactMsg = 'Add contact';
export const addDeviceOrSubscriptionMsg = 'Add device or subscription';
export const addFilterMsg = 'Add filter';
export const addMsg = 'Add';
export const addOrRemoveDevicesMsg = 'Add or remove devices';
export const addToCartMsg = 'Add to cart';
export const addUserMsg = 'Add user';
export const addYourDeviceMsg = 'Add your device';
export const addYourSubscriptionMsg = 'Add your subscription';
export const additionalInformationMsg = 'Additional information';
export const additionalInformationReceivedDescriptionMsg = 'We have received additional information for the case and will start processing it as soon as possible.';
export const additionalInformationReceivedMsg = 'Additional information received';
export const additionalServicesMsg = 'Additional services';
export const addressConfirmationMsg = 'I confirm that the address is correct';
export const addressLine2Msg = 'Address Line 2';
export const addressMsg = 'Address';
export const addressOrNumberMsg = 'Address or number';
export const administratorAndOrderAcceptorMsg = 'Administrator and order acceptor';
export const administratorMsg = 'Administrator';
export const allCallsAreRecordedMsg = 'We record all calls to ensure the quality of service. For more information about data protection, please visit';
export const allCompaniesMsg = 'All companies';
export const allMsg = 'All';
export const allPricesVatZeroMsg = 'All prices VAT 0%';
export const allServicesMsg = 'All services';
export const allSubscriptionsMsg = 'All subscriptions';
export const alvZeroMsg = 'VAT 0%';
export const amountOfLicensesToOrderMsg = 'Amount of licenses (users) to order';
export const appleWatchLoginScreenFooterMsg = `If you have issues with login, you have lost password, you don't have Elisa ID or you have lost it, please go to the website: https://yrityksille.elisa.fi/työntekijä.`;
export const approverContactMsg = 'Approver contact';
export const archiveMsg = 'Archive';
export const areYouMakingTheUpdateOnBehalfOfAnotherCompanyOrHousingCompanyMsg = 'Are you making the update on behalf of another company or housing company';
export const areYouTheInvoicingContactPersonMsg = 'Are you the invoicing contact person';
export const askAboutInvoiceMsg = 'Ask about the invoice';
export const authenticatingMsg = 'Authenticating';
export const authenticationFailedMsg = 'Authentication failed.';
export const authenticationMsg = 'Authentication';
export const auxiliaryEsimProductPriceMsg = '6,37 €/month (VAT 0%)';
export const availabilityResultsMsg = 'Available internet connections';
export const backMsg = 'Back';
export const backorderedMsg = 'Backordered';
export const barringServicesMsg = 'Barring services';
export const barringGeneralServiceNumbersMsg = 'Calls to general service numbers, e.g. ordering taxis or banking services';
export const barringConnectionServiceNumbersMsg = 'Calls to directory assistance and connection service numbers and general services, e.g. calls and text messages to directory assistance, taxi orders and banking services';
export const barringTransactionServiceNumberMsg = 'Calls and messages to transaction service numbers, such as donations to charity, paying for goods and services using calls or text messages';
export const barringEntertainmentServiceNumberMsg = 'Calls and messages to entertainment service numbers, e.g. chat lines, telephone voting, games and marketing campaigns';
export const barringAdultEntertainmentServicenumberMsg = 'Calls and messages to adult entertainment numbers';
export const barringInternationalCallsMsg = 'International calls';
export const barringTelephoneAbroadMsg = 'Use of telephone abroad (roaming charges)';
export const billingAddressMsg = 'You will receive first invoice via mail. We recommend ordering an e-invoice, so you can receive future invoices directly through your bank.';
export const billableChangesDisabledMsg = `Your employer has disabled adding this paid feature. Please contact your employer's admin user if necessary.`;
export const billingAccountChangeCompletedMsg = 'Billing account change completed';
export const billingAccountChangeFailedMsg = 'Billing account change failed';
export const billingAccountExtensionNameMsg = 'Billing account extension Name';
export const billingAccountMsg = 'Billing account';
export const billingAccountNameMsg = 'Billing account name';
export const billingAccountNumberMissing = 'Billing account number is missing';
export const billingAccountsMsg = 'Billing accounts';
export const billingAddressAlias = 'Billing address alias';
export const billingAddressTitleMsg = 'Billing address';
export const billingContactMsg = 'Billing contact';
export const billingDetailsMsg = 'Billing details';
export const billingEOperatorChangesMsg = 'Inform new or changed e-operator invoicing details';
export const billingMsg = 'Billing';
export const blockAllDataMsg = 'Block all data transfers';
export const blockAllUseAbroadMsg = 'Block all use abroad';
export const blockAllUseOutsideEuMsg = 'Block all use outside the EU and EEA';
export const blockCallsToForeignNumbersMsg = 'Block calls to foreign numbers';
export const blockDataAbroadMsg = 'Block data transfers abroad';
export const blockDataOutsideEuMsg = 'Block data transfers outside the EU and EEA';
export const blockInUseMsg = 'Block in use';
export const blockNotInUseMsg = 'Block not in use';
export const brandMsg = 'Brand';
export const bulletinsMsg = 'Bulletins';
export const businessIdMsg = 'Business ID';
export const businessIdOrCompanyMsg = 'Business ID or company name';
export const businessSecurityMsg = 'Business security';
export const callBarringsMsg = 'Call barrings';
export const callDestinationMsg = 'Call destination';
export const callDetailsPeriodMsg = 'Usage period';
export const cancelMsg = 'Cancel';
export const cancelledMsg = 'Cancelled';
export const cancelledStatusDescriptionMsg = 'The case has been canceled. We have emailed the resolution to the case reporter.';
export const cartIsEmptyMsg = 'Cart is empty';
export const caseCreatedMsg = 'Case created';
export const catalogCorporateMessageExampleLinkMsg = 'Show example message';
export const catalogCorporateMessageExampleMsg = 'Remember to add screen protector and protective case to your order. You can also purchase them separately at your own expense. Damage insurance requires whole device to be fully protected. By continuing I commit to protect my device.';
export const catalogCorporateMessageHelpMsg = 'Max 500 characters. No html tags.';
export const catalogCorporateMessageInstructionMsg = 'You can define a message which will be showed to employee during a check out. Headline of the message is "Message from your company admin" which cannot be changed. You can set in on and off for each catalog separately.';
export const catalogCorporateMessageLabelMsg = 'PopUp message to an employee';
export const catalogCorporateMessageSelectorMsg = 'Show PopUp message to employee';
export const catalogDetailsMsg = 'Catalog details';
export const categoryMsg = 'Category';
export const changeBillingAddressMsg = 'Change billing address';
export const changeDeliveryMethodMsg = 'Change delivery method';
export const changeFeeMsg = 'Change fee';
export const changeMsg = 'Change';
export const changeOfDeviceMsg = 'Change of device';
export const changePasswordMsg = 'Change password';
export const changeRequestInProcessHeaderMsg = `Change request in process`;
export const changeRequestInProgressMsg = 'Change request in progress';
export const changeRequestInProgressInDetailMsg = `You can't make new changes until the previous change request has been processed. Note that the change will become visible once the change request has been processed.`;
export const changeRingUserBodyMsg = 'If you want to change the user for your Ring-subscription, please contact us and we will take care of it. In urgent matters we kindly ask you to contact our customer support, phone {0} ({1}).';
export const changeRingUserHeaderMsg = 'Changing user for Ring';
export const changeSubscriptionTypeFeeMsg = 'or change subscription type fee 3,99 €';
export const changesFailedToSave = 'Changes failed to save';
export const changesWereSuccessfullySavedMsg = 'Changes were successfully saved';
export const cheapestFirstMsg = 'Cheapest first';
export const checkAvailabilityMsg = 'Check availability';
export const checkOutInternetSolutionsMsg = 'Elisa can offer you an internet connection also for bigger offices and for more demanding needs.';
export const checkoutMsg = 'Checkout';
export const checkoutRedeemOptionTitleMsg = 'To whom device is redeemed?';
export const checkRoamingUsageTitleMsg = 'Check roaming usage';
export const checkRoamingUsageContentMsg = 'Call *100# to check your remaining data roaming balance. You’ll receive a message with details of your roaming data usage. The service is free of charge.';
export const chooseFileButtonMsg = 'Choose file';
export const chooseModelMsg = 'Choose model';
export const chooseOneProductMsg = 'Select at least one item.';
export const chooseThisContactMsg = 'Choose this contact';
export const chosenLanguageMsg = 'Chosen language';
export const cityMsg = 'City';
export const clearFormMsg = 'Clear form';
export const clearFormsMsg = 'Clear forms';
export const closeMsg = 'Close';
export const closedMsg = 'Closed';
export const closedStatusDescriptionMsg = 'We have resolved and closed the case.';
export const companiesMsg = 'Companies';
export const companyAddressMsg = 'Company Address';
export const companyBillingInformationMsg = 'Company billing information';
export const companyCreditDecisionNegativeMsg = 'Unfortunately, the order cannot be processed due to the credit decision made. ';
export const companyCreditLimitExceededAdminMsg = 'Unfortunately, the order cannot be processed due to the credit decision made. Please contact customer service.';
export const companyCreditLimitExceededEmployeeMsg = `Unfortunately, the order cannot be processed. Please contact your company's admin person.`;
export const companyDetailsMsg = 'Company details';
export const companyMsg = 'Company';
export const companyMustBeChosenMsg = 'The company must be chosen';
export const companyNameMsg = 'Company name';
export const companyNumberMsg = 'Company number';
export const companyPayingForBarringServicesMsg = 'Your employer will pay for the following services:';
export const companySelectMsg = 'Select company';
export const companysShareOfFeeMsg = 'Company’s share of fee';
export const companysShareOfMonthlyFeeMsg = 'Company’s share of monthly fee for the device';
export const computersMsg = 'Computers';
export const confirmAgreementTerminationMsg = 'Confirm termination of agreement';
export const confirmMsg = 'Confirm';
export const confirmOrderMsg = 'Confirm order';
export const confirmationSMSMsg = 'A confirmation message has been sent to you regarding the receipt of the order';
export const confirmRemoveMsg = 'Confirm remove';
export const connectDeviceToRegistrationProgramMsg = 'Connect devices to a device registration program';
export const connectionProblemsMsg = 'Connection problems?';
export const connectToNumberMsg = 'Connect to number';
export const connectToRingMsg = 'Connect to Ring';
export const connectToVakioMsg = 'Connect to Vakio';
export const consumerCustomersMsg = 'Consumer customers';
export const consumerOmaElisaMsg = 'Consumer OmaElisa';
export const contactAlreadyExistsMsg = 'Contact already exists';
export const contactCanBeFoundInCompanyListingMsg = 'This contact can be found in company listing.';
export const contactDetailsMsg = 'Contact details';
export const contactDetailsYouAddedMsg = 'Contact details you added';
export const contactEmailAddressMsg = 'Contact email address';
export const contactForInvoiceMsg = 'The contact person for the billing agreement, who will be notified of matters related to the invoices, including unpaid invoices. This will not be printed on invoices.';
export const contactInfoMsg = 'Contact';
export const contactInformationMsg = 'Contact information';
export const contactManagementInstructionsMsg = 'Instructions for user management';
export const contactPhoneNumberMsg = 'Contact phone number';
export const contactsMsg = 'Contacts';
export const contactUsMsg = 'Contact us';
export const contactYourCompanyAdminMsg = `Contact your company's administrator.`;
export const contentMsg = 'Content';
export const continueMsg = 'Continue';
export const continueShoppingMsg = 'Continue shopping';
export const contractEndDateMsg = 'Contract end date';
export const contractMsg = 'Contract';
export const contractPeriodMsg = 'Contract period';
export const contractPriceMsg = 'contract price';
export const contractPriceCapitalizedMsg = 'Contract price';
export const contractTypeHeadingMsg = 'Is it change of device or new contract?';
export const contractTypeMsg = 'Contract type';
export const contractsMsg = 'Contracts';
export const cookieSettingsMsg = 'Cookie settings';
export const copyUserInfoMsg = 'Copy user information to all products';
export const copyrightMsg = 'Copyright © 2025 Elisa Corporation. All rights reserved.';
export const corporateCustomersMsg = 'Corporate customers';
export const corporateInfoURLMsg = 'https://elisa.com/corporate/';
export const corporateMessagePopUpHeaderMsg = 'Message from your company admin';
export const corporateOmaElisaMsg = 'Corporate OmaElisa';
export const corporateOrExtensionNumberIsNotAvailableMsg = 'Corporate or extension number is not available.';
export const corporatePhoneMsg = 'Corporate phone';
export const costCenterAndReferenceMsg = 'Cost center and reference';
export const costCenterAndReferenceVariableMsg = '{} cost center and reference';
export const costCenterMsg = 'Cost center';
export const costCenterTooLong = 'Cost center can be at most 40 characters long';
export const costCenterToolTipMsg = `Cost center is not automatically updated to user's services. Please check the cost center in the product or service information.`;
export const coverageDisclaimerMsgPartOne = 'Before ordering a mobile broadband subscription, please check the 5G coverage of your installation address on the ';
export const coverageDisclaimerMsgPartTwo = '. Enter your address in the search box on the map and select 5G availability.';
export const coverageInterruptionsIncidentsMsg = 'Coverage, interruptions and incidents';
export const coverageMapMsg = 'coverage map';
export const createIdMsg = 'Register an account';
export const createNewBillingAccountMsg = 'Create new billing account';
export const createNewMessageMsg = 'Create a new message';
export const createNewMsg = '+ Create new';
export const createNewSupportCase = 'Create new support case';
export const createSupportCaseFromInvoiceMsg = 'Create support case from invoice';
export const createdMsg = 'Created';
export const creditDecisionFailureMsg = 'Unfortunately, the order cannot be processed due to a credit decision.';
export const creditDecisionFailureRecommendCardPaymentMsg = 'Unfortunately, the order cannot be processed due to a credit decision. Did you know that you can pay for one-time devices and accessories with a card payment.';
export const customFormErrorFailedMsg = 'An error occurred while sending the form. Please try again or contact our customer service.';
export const customerMsg = 'Customer';
export const customerNumberHasNineDigitsMsg = 'The customer number can contain at least three (3) and at most nine (9) digits.';
export const customerNumberMsg = 'Customer number';
export const customerServiceNumberHrefMsg = `tel:+35810808088`;
export const customerServiceNumberIntlMsg = '+35810808088';
export const customerServiceNumberLabelMsg = '010 80 8088';
export const customerServiceNumberOpenLongMsg = 'weekdays 8am to 4:30pm';
export const customerServiceNumberOpenShortMsg = 'Mon-Fri 8am-4:30pm';
export const customerServiceNumberPriceMsg = 'mcf/inf';
export const customerServiceSolutionsMsg = 'Customer Service solutions';
export const damageInsuranceCoverMsg = `Damage insurance covers all damages outside the warranty at the cost of deductible. Damage insurance does not cover cases of theft or loss of the device. Deductibles are: Business Pro €30/damage incident and Business Premium €50/damage incident. Damaged device will be replaced with a device corresponding to the condition.`;
export const damageInsuranceMsg = 'Damage insurance';
export const damageOrSupportRequestMsg = 'Damage or support request';
export const dataBarringMsg = 'Data barring';
export const dataNotAvailableMsg = 'Data not available...';
export const dataPackageMsg = 'Data package';
export const dataProtectionMsg = 'Data protection';
export const dataSourcesForContactDetailsMsg = 'Data sources for contact details are: user details on Elisa services, customer interaction (sales, marketing, customer service) and Alma Media Finland oy´s registry.';
export const dateMustBeGreaterThanTodayAndOutsideWeekendsMsg = 'Date must be greater than today and outside weekends';
export const dayMsg = 'Day';
export const debitOrCreditCardMsg = 'Debit or Credit Card';
export const deductibleMsg = 'Deductible';
export const deleteChangesMsg = 'Delete changes';
export const deliveredMsg = 'Delivered';
export const deliveryDetailsMsg = 'Delivery details';
export const deliveryMethodMsg = 'Delivery method';
export const describeYourProblemMsg = 'Type your question or describe your problem...';
export const descriptionInquiryVisibleInOmaElisaMsg = 'Your inquiry will appear in OmaElisa and will be visible for all of your company admins.';
export const descriptionMsg = 'Description';
export const deviceListMsg = 'Device list';
export const deviceListsMsg = 'Device lists';
export const deviceMsg = 'Device';
export const deviceEnrollmentProgramAliasHelpMsg = 'If your company uses multiple device enrollment aliases in Samsung KME, you need to create a separate device list for each alias.';
export const deviceEnrollmentProgramAliasInfoMsg = 'Customer admin creates the profile alias in KME Admin Portal and shares it with a reseller. Alias is reseller related, it means that same alias name cannot be used for all resellers.';
export const deviceEnrollmentProgramMsg = 'Device enrollement program';
export const deviceListSavedAsDraftMsg = 'Device list is saved as a draft. Remember to publish the device list when ready.';
export const deviceShopCardSection1Msg = 'Your employer has selected products for device lists based on the company practices. To illustrate, a device list can contain devices of specific models or price classes.';
export const deviceShopCardSection2Msg = 'Products with the price of €0,00 are fully sponsored by your employer. To get a more expensive device, you can participate in its costs. The contract period of the device affects the total price, the monthly payment amount, and the exchange period.';
export const deviceShopCardSection3Msg = 'All contract periods are predefined. The prices include VAT of {}%.';
export const deviceShopCardSection1HeaderMsg = 'Device lists and product range';
export const deviceShopCardSection2HeaderMsg = 'Prices';
export const deviceShopCardSection3HeaderMsg = 'Contract periods';
export const deviceShopCardHeaderMsg = 'Product range, pricing, and contract periods';
export const deviceShopListFooterMsg = 'Elisa Oyj does not manage the product range on device lists. If you have any questions about the product range, contact your company’s OmaElisa administrator.';
export const deviceShopListHeaderMsg = 'Select device list';
export const deviceShopListInfoMsg = 'To start your order, select the desired device list. You can only order products from one device list at the time. If you don’t know which device list to select, ask your manager or your employer’s OmaElisa administrator.';
export const deviceShopMsg = 'Device shop';
export const deviceShopSubtitleMsg = 'Choose the most suitable work equipment for yourself. With OmaLasku, you can get the best device available.';
export const deviceShopHeaderMsg = 'Order a new device and accessories';
export const deviceToEnrollmentProgramMsg = `The devices to be delivered can be connected to a device enrollment program used by the company and activated for Elisa. These include Apple DEP, Samsung KME, Android Zero-Touch, and Windows Autopilot. Device enrollment programs automatically redirect the devices to the company’s mobile device management system.`;
export const deviceToEnrollmentReadMoreMsg = 'Read more about device enrollment programs';
export const devicesMsg = 'Devices';
export const dialogSystemErrorHeaderMsg = 'Oops, something went wrong';
export const directoryStatusOfUserAndNumberMsg = 'Directory status of user and number';
export const discountVoucherMsg = 'Discount voucher';
export const doesTheChangeApplyToAllBillingAccountsForThisBusinessIDMsg = 'Does the change apply to all billing accounts for this business ID';
export const domainCheckFailedMsg = 'An error occurred while checking the domain availability. Please try again later.';
export const domainErrorMsg = 'Type the domain in this format: homepage.fi';
export const domainLabelMsg = 'Desired domain (e.g. homepage.fi)';
export const domainMsg = 'Domain';
export const domainTitleMsg = 'Search for a domain';
export const domainsMsg = 'Domains';
export const downloadSpeed = 'Download speed';
export const draftMsg = 'Draft';
export const dueDateMsg = 'Due date';
export const duplicateContactDescriptionMsg = 'Please check that the information you entered is correct and select the correct contact information below.';
export const duplicateContactHeaderMsg = 'We found a user with similar contact details';
export const durationOfContractMsg = 'Duration of the contract';
export const eInvoiceMsg = 'E-invoice';
export const eInvoicingAddressMsg = 'E-invoicing address';
export const eInvoicingOperatorMsg = 'E-invoicing operator';
export const editDataMsg = 'Edit data';
export const editMsg = 'Edit';
export const elisaCorporationMsg = 'Elisa Corporation';
export const elisaDevicesServiceMsg = 'Elisa Devices Service';
export const elisaForCompaniesMsg = 'Elisa for companies';
export const elisaOmaLaitelaskuMsg = 'Elisa Oma Laitelasku';
export const elisaShopsMsg = 'Elisa shops';
export const elsewhereInEuMsg = 'Elsewhere in the EU';
export const emailAddressMsg = 'Email address';
export const emailAddressOrIdMsg = 'Email address or ID';
export const emailInvoiceMsg = 'Email invoice';
export const emailMsg = 'Email';
export const emailWorkMsg = 'Email (work)';
export const employeeCreditCheckFailedMsg = 'Unfortunately, the device you have chosen cannot be ordered due to the negative credit decision. Please choose a device, where the monthly billing is 0 euros. ';
export const employeeInvoiceNotesMsg = 'An invoice of the device is sent to the employee';
export const employeeNumberMsg = '(e.g. employee number)';
export const employeeOmaElisaMsg = 'Employee OmaElisa';
export const employeeOmaElisaWelcomeMsg = 'Welcome to Employee OmaElisa';
export const employeeOmaElisaWelcomeSubtitleMsg = 'Take care of your device and subscription matters anytime you like.';
export const employeeOmaLaiteLaskuMissingMsg = `It is not possible to redeem the device on behalf of the employee. This may be because company does not have the Employee's OmaElisa or the employee has not implemented My Invoice. Employee can also redeem device from Employee OmaElisa portal.`;
export const employeePayingForBarringServicesMsg = 'By ordering the OmaLasku Palvelunumerot -service, you will be responsible for all costs arising from the use of the following services:';
export const emptyFiltersMsg = 'Empty filters';
export const endTimeMsg = 'End time';
export const englishMsg = 'English';
export const enrollmentAliasPlaceHolderMsg = 'Enrollment Alias';
export const entertainmentBarringMsg = 'Entertainment barring';
export const entertainmentBarringP3Msg = 'Entertainment barring P3';
export const eoeInUseColumnMsg = 'Employee OmaElisa';
export const eoeInUseFalseMsg = 'Not in use';
export const eoeInUseTrueMsg = 'In use';
export const eppDeviceDisclaimerBodyMsg = 'The function is not possible, because the service already has one change request being processed.';
export const eppNotAvailableMsg = 'Product is not included in Elisa Devices Service. Please choose another product.';
export const eppRedeemOptionTitleMsg = 'Would you like to redeem the device for a company or an employee?';
export const euAndEtaDataMsg = 'EU and ETA: {} GB/month';
export const euDataIncludedMsg = 'Incl. {0} GB/mo of EU data';
export const euDataMsg = 'EU-data';
export const eurosPerMonthMsg = '€/month';
export const existingDomainInputMsg = 'If you already have a domain, write it here';
export const expiringEppSubsInfoMsg = 'The user has an expiring Elisa palvelupäätelaite subscription';
export const expiringEppSubsNotesMsg = 'There is an Elisa palvelupäätelaite subscription expiring for the selected user. Please select the device which you want to replace or select a new contract.';
export const expiringEppSubsWarnMsg = 'There is an Elisa palvelupäätelaite subscription expiring for the selected user. Only one device change is possible per order.';
export const extensionMsg = 'Extension';
export const extensionNumberMsg = 'Extension number';
export const externalAuthenticationErrorMsg = 'An error occurred during authentication';
export const externalAuthenticationRetryMsg = 'Try to authenticate your ID again.';
export const failedToCreateDuplicateContactMsg = 'Failed to create duplicate contact';
export const faultDescriptionPlaceHolderMsg = 'Briefly describe the device fault and how it occurred';
export const fieldCantBeEmptyMsg = 'The field cannot be empty';
export const fieldRequiresThreeCharactersMsg = 'The field requires at least three characters';
export const fieldValueTooLong = 'Value is too long, max {} characters allowed';
export const fileSizeTooLargeMsg = 'File size {} MB too large';
export const fileTypeNotAllowedMsg = 'File type "{}" not allowed';
export const filterMsg = 'Filter';
export const finlandNordicsAndBalticsMsg = 'Internet in Finland, Nordics and Baltics';
export const finnishMsg = 'Finnish';
export const firstNameMsg = 'First name';
export const fiveGCompatibilityMsg = '5G compatibility';
export const fixedBroadBandNettiLiteOffersMsg = 'Fixed broadband Netti Lite offers';
export const fixedBroadBandNettiLiteParagraphMsg = 'We recommend the fixed network Netti Lite subscription as the primary option. With a fixed connection, you get the most stable and reliable internet connection for smooth remote work, video conferences and streaming services';
export const fixedBroadbandMsg = 'Fixed broadband';
export const fixedBroadbandsMsg = 'Fixed broadbands';
export const fixedInternetMsg = 'Fixed internet - mobile broadband';
export const fixedLineSubscriptionMsg = 'Fixed line subscriptions';
export const fixedTermEndsMsg = 'Fixed term ends';
export const fixedTermMsg = 'fixed-term';
export const forCorporationsMsg = 'For companies';
export const forTheCompanyMsg = 'For the company';
export const forTheEmployeeMsg = 'For the employee';
export const forgottenPasswordMsg = 'Forgotten Password?';
export const foundUserMsg = 'Found user';
export const freeMsg = 'Free';
export const frequentlyAskedQuestionsMsg = 'Frequently asked questions';
export const frequentlyAskedQuestionsAndAnswersMsg = 'Frequently asked questions & answers';
export const fromOldElisaMsg = 'from the OmaElisa Classic';
export const frontPageMsg = 'Front page';
export const furtherDetailPhonesAbroadMsg = 'Further details about using phones abroad';
export const gBMoMsg = 'GB/month';
export const gbMsg = 'GB';
export const generalServiceBarringMsg = 'General service barring';
export const getYourDeviceFixedTitleMsg = 'Get your device fixed';
export const getYourDeviceFixedContentMsg = 'If your device is damaged, send us a damage report and service request. We will quickly replace the device with another one. First, select the device.';
export const goBackHomeMsg = 'Go back Home';
export const goToOmaElisaMsg = 'To OmaElisa';
export const helloMsg = 'Hello {}!';
export const hereMsg = 'here';
export const hideMsg = 'Hide';
export const homeAddressLine1Msg = 'Home address line 1';
export const homeAddressLine2Msg = 'Home address Line 2';
export const homeAddressMsg = 'Home Address';
export const hostnameValidationMsg = 'The value cannot contain special characters or umlauts.';
export const howCanWeHelpMsg = 'How can we help you?';
export const iAcceptTAndCOmalasku = 'I accept the {} of OmaLasku';
export const idNumberMsg = 'ID number';
export const identifierMsg = 'Identifier';
export const ifProblemPersistsMsg = 'If the problem persists, please contact our customer service.';
export const imeiSlashSerialNumberMsg = 'IMEI/serial number';
export const inDeliveryMsg = 'In delivery';
export const inProgressMsg = 'In progress';
export const inProgressStatusDescriptionMsg = 'We are processing the case.';
export const inUseMsg = 'In use';
export const includedMsg = 'Included';
export const incorrectPasswordMsg = 'The password is incorrect.';
export const informantBISMsg = 'Informant: Finnish Patent and Registration Office / The Finnish Business Information System (BIS). Communicator: Alma Media Finland Oy.';
export const inquiryHasBeenSentMsg = 'Your inquiry has been submitted';
export const installationAddressMsg = 'Installation address';
export const instructionsMsg = 'Instructions';
export const internetSolutionsMsg = 'Are you looking for an internet connection for larger office';
export const internetSubscriptionsMsg = 'Internet subscriptions';
export const invalidCostCenterNumberMsg = 'Invalid cost center number. Insert 4-digits cost center value.';
export const invalidDateMsg = 'Invalid date';
export const invalidEInvoiceAddressMsg = 'Check the format of the e-invoicing address. The correct format begins with 0037, TE0037, or FI. The e-invoicing address may contain the letters A-Z and the numbers 0-9.';
export const invalidIntegerMsg = 'Invalid integer';
export const invalidPhoneNumberMsg = 'Invalid phone number';
export const invalidPostalCodeMsg = 'Invalid postal code';
export const invalidSIMCardNumberMsg = 'Invalid SIM card number.';
export const invalidTimeMsg = 'Invalid time';
export const invalidValueMsg = 'Invalid value';
export const invitesMsg = 'Invites';
export const invoiceDeliveryMethodMsg = 'Invoice delivery method';
export const invoiceAdditionalInfoMsg = 'Help for invoice and payment issues';
export const invoiceInstructionsPart1Item1Msg = 'Have you updated your subscription?';
export const invoiceInstructionsPart1Item2Msg = 'Have you used your phone abroad?';
export const invoiceInstructionsPart1Item3Msg = 'Have you called any premium rate service numbers?';
export const invoiceInstructionsPart1LinkMsg = 'More information about unexpected invoice totals';
export const invoiceInstructionsPart2Msg = `Note that the invoice status updates within 3 business days after payment, so the invoice can appear open for a moment. You do not need to inform our customer service if you've paid an invoice late.`;
export const invoiceInstructionsPart2NewInvoiceMsg = 'PDF invoice from new invoice is available in 24 hours.';
export const invoiceInstructionsPart3Msg = 'If the invoice has a recurring reference number starting with 80, the payments will be directed to the oldest invoice with outstanding balance.';
export const invoiceInstructionsPart3LinkMsg = 'Answers to invoicing questions';
export const invoiceInstructionsSubtitle1Msg = 'Is your invoice higher or lower than usual?';
export const invoiceInstructionsSubtitle2Msg = 'Invoice status updates';
export const invoiceInstructionsSubtitle3Msg = 'Invoices whose reference numbers start with 80';
export const invoiceInstructionsTitleMsg = 'Instructions for invoicing';
export const invoiceInqueryMsg = 'Invoice inquery';
export const invoiceLanguageMsg = 'Invoice language';
export const invoiceLettersMsg = 'Invoice letters';
export const invoiceMsg = 'Invoice';
export const invoiceNumberMsg = 'Invoice number';
export const invoiceReferenceNumberDisclaimerMsg = 'If the reference number of the invoice is a standard reference invoice starting with 80, the payments are always directed to the oldest open invoice.';
export const invoiceRelatedClaimMsg = 'Invoice related claim';
export const invoiceStatusUpdateMsg = 'Please note that the invoice status is updated during approximately 3 business days, so a paid invoice may briefly appear to be open. You do not have to contact our customer service if you pay the invoice where the due date has passed.';
export const invoiceTypeMsg = 'Invoice type';
export const invoicesMsg = 'Invoices';
export const invoicingAndCustomerDetailsMsg = 'Invoicing and customer details';
export const invoicingDetailsMsg = 'Invoicing details';
export const invoicingMsg = 'Invoicing';
export const ipv4ValidationMsg = 'The value cannot contain letters. Example value: [0-255].[0-255].[0-255].[0-255]';
export const ipv6ValidationMsg = 'The value can only contain numbers 0-9, letters a-f, and colons.';
export const itServicesMsg = 'IT services';
export const languageMsg = 'Language';
export const lastNameMsg = 'Last name';
export const leaveMessageMsg = 'Leave a message';
export const licenseManagementPortalMsg = 'Licence management portal';
export const licenseManagementMsg = 'Licence management';
export const licenseManagementRedirectFailedHeaderMsg = 'Failed to navigate to the portal';
export const licenseManagementRedirectFailedMsg = 'Unfortunately, an error occurred in our system. Please try again later. If the problem persists, please contact our customer service. We apologize for the inconvenience.';
export const licenseManagementRedirectInfoMsg = 'In the licence management portal, you can manage M365 and Dynamics 365 licences.';
export const listOfDevicesMsg = 'List of devices';
export const listPriceMsg = 'List price';
export const billingFrequencyMsg = 'Billing frequency';
export const listedNumberMsg = 'Listed number';
export const loadingAccountMsg = 'Loading account';
export const loadingBillingAccountsMsg = 'Loading billing accounts';
export const loadingBillingChannelsMsg = 'Loading billing channels';
export const loadingFormListMsg = 'Loading form list';
export const loadingFormMsg = 'Loading form';
export const loadingMsg = 'Loading';
export const loadingProductAvailabilityMsg = 'Loading product availability';
export const loadingProductInformationMsg = 'Loading product information';
export const loadingPublicHolidaysMsg = 'Loading public holidays';
export const uploadingMsg = 'Uploading';
export const loadingWithThreePeriodsMsg = 'Loading...';
export const logInMsg = 'Log in';
export const logOutMsg = 'Log out';
export const loginMsg = 'Login';
export const loginToOmaElisaForCompaniesMsg = 'Login to OmaElisa for companies';
export const loginToOmaElisaMsg = 'Log in to OmaElisa';
export const lostDeviceMsg = 'Lost or destroyed device';
export const mBitsPerSecondMsg = '{} Mbit/s';
export const kBitsPerSecondMsg = '{} Kbit/s';
export const mBpsMsg = '{} Mbps';
export const maxMbpsMsg = 'max. {} Mbps';
export const mailingAddressMsg = 'Mailing address';
export const mainUserContactDetailsMsg = `Main user's contact details`;
export const mainUserEmailMsg = 'Email of main user';
export const mainUserNameMsg = 'Name of main user';
export const mainUserPhoneMsg = 'Phone number of main user';
export const manageEmployeeSubscriptionMsg = 'If you are an employee, you can manage your employee subscription in';
export const manageSubscriptionsMsg = 'Manage your subscriptions';
export const marketingConsentMsg = 'I give Elisa permission to contact me by email and phone, and to combine information obtained from the network (such as device and location information) with my customer information. I give Elisa permission to use this information for targeted communication and marketing.';
export const maxSubscriptionLimitMsg = 'Max one per subscription';
export const maximumDownloadAndUploadMsg = 'Maximum download speed {0} / Maximum upload speed {1}';
export const messageMsg = 'Message';
export const messageOrQuestionMsg = 'Message or question';
export const messageVisibilityAcknowledgementMsg = 'I understand that messages are visible to all OmaElisa users in my company.';
export const methodOfDeliveryMsg = 'Choose method of delivery';
export const mfaEnableAllCompaniesInSaMsg = 'Note that two-factor authentication is activated simultaneously for all companies belonging to the same service agreement.';
export const mfaEnableOECUsersMsg = 'Two-factor authentication improves security by requiring a one-time authentication code on your mobile phone, in addition to your ID and password, when you log in. Two-factor authentication is required for every OmaElisa for Companies administrator. The change also applies to OmaElisa Classic.';
export const microsoft365Msg = 'Microsoft 365';
export const minutesMsg = 'Minutes';
export const mobiilivarmenneMsg = 'Mobiilivarmenne';
export const mobiiliivarmenneTunnistauduMsg = 'Elisa Mobiilivarmenne';
export const mobiilivarmenneForEmployeeMsg = 'Elisa Mobiilivarmenne for Employee';
export const mobiilivarmennePricePerUserMsg = '1,60 €/month/user (VAT 0%)';
export const mobileBroadbandInfoMsg = 'A mobile broadband accompanies you when you work remotely and travel. The mobile broadband connection works with a SIM card and allows you to connect to the Internet with a tablet or router, for example.';
export const mobileBroadbandMsg = 'Mobile broadband';
export const mobileBroadbandsMsg = 'Mobile broadbands';
export const mobileBroadbandNettiLiteOffersMsg = 'Mobile broadband Netti Lite offers';
export const mobileBroadbandNettiLiteOffersParagraphMsg = 'We recommend the Mobile Broadband Netti Lite 5G+ subscription if you want fast internet, but there is no fixed connection available at your address. Mobile broadband goes with you if you work at several different addresses. You can take it with you to the cottage.';
export const mobileM2MMsg = 'Laitenetti';
export const mobilePhoneNumberMsg = 'Mobile phone number';
export const mobilePhoneNumberWorkMsg = 'Mobile phone number (work)';
export const mobileSubscriptionsMsg = 'Mobile subscriptions';
export const modifiedMsg = 'Modified';
export const monthMsg = 'Month';
export const monthlyChargeMsg = 'Monthly charge';
export const monthlyChargeSummaryMsg = '{} monthly agreement, total charge {}, VAT {}%';
export const monthlyChargesMsg = 'Monthly charges';
export const recurringChargesMsg = 'Recurring charges';
export const monthlyFeeOfSubAfterChangesMsg = 'Monthly fee of the subscription after changes {0}/month';
export const monthsMsg = 'months';
export const mostExpensiveFirstMsg = 'Most expensive first';
export const moveContactToAnotherCompanyMsg = 'Move contact to another company';
export const movePaymentDateMsg = 'Move the payment date';
export const moveThePaymentDateSuccessMsg = 'Request for payment date agreement sent successfully';
export const movedElsewhereDescriptionMsg = 'Your case has been transferred to be further processed. We have emailed this information to the initial requestor as well';
export const movedElsewhereMsg = 'Re-assigned';
export const mvOrderingSuccessfulMsg = 'Service ordered successfully';
export const mvTerminationSuccessfulMsg = 'Service terminated successfully';
export const myAccountMsg = 'My account';
export const myDevicesMsg = 'My devices';
export const myDevicesAndSubscriptionsMsg = 'My devices and subscriptions';
export const myInformationMsg = 'My information';
export const myOrdersMsg = 'My orders';
export const myProductsAndServicesMsg = 'My products and services';
export const myRedeemRequestsMsg = 'My redeem requests';
export const myServicesMsg = 'My services';
export const nameMsg = 'Name';
export const nameOfCatalogMsg = 'Name of catalog';
export const nettiLiteWeRecommendMsg = 'We recommend the following options:';
export const networkAndInformationSecurityServicesMsg = 'Network and Information Security services';
export const networkingHardwareMsg = 'Networking hardware';
export const newBillingAgreementMsg = '+ New billing agreement';
export const newContractMsg = 'New contract';
export const newDeviceListMsg = 'New device list';
export const newOrdersAndOfferRequestsMsg = 'New orders and offer requests';
export const newSubscriptionMsg = 'Order new subscription';
export const newestFirstMsg = 'Newest first';
export const nextMsg = 'Next';
export const noAdditionalServicesMsg = 'No additional services';
export const noBarringsMsg = 'No call barrings';
export const noContractPriceMsg = 'no contract price';
export const noDataPackageMsg = 'No data package';
export const noFixedTermMsg = `No fixed term`;
export const noMsg = 'No';
export const noOrdersMsg = 'No orders';
export const noProductsMsg = 'No products';
export const noQueuingMsg = 'No queuing';
export const noRestrictionsMsg = 'No restrictions';
export const noRightsMsg = 'No rights';
export const noSelectionMsg = 'No selection';
export const noSmsPackageMsg = 'No SMS package';
export const noSubscriptionsAvailableMsg = 'No subscription types available for address, you can generate a more extensive report with a separate check in OmaElisa Classic.';
export const noSubscriptionsErrorMsg = 'There was a problem retrieving subscription availability information, please submit a service request or contact YA support';
export const noUserOrPurposeOfUseSetMsg = 'User or purpose not set';
export const notAvailableMsg = 'Not available';
export const notInUseMsg = 'Not in use';
export const notPlacedOrdersMsg = 'You have not placed any orders yet.';
export const notSetMsg = 'Not set';
export const notToDirectoryMsg = 'Not for catalogue';
export const noUsersMsg = 'No users';
export const numberConversionToBeDisplayedMsg = 'Number conversion to be displayed';
export const numberDirectoryStatusMsg = 'Directory status of number';
export const numberMsg = 'Number';
export const numberOfFilesLimitMsg = 'You can add up to {} attachments. (max. {} MB/attachment)';
export const numberOfTheBillingAccountMsg = 'Number of the billing account';
export const numberToDisplayMsg = 'Number to display';
export const offerProductsMsg = 'Offer products';
export const offersMsg = 'Offers';
export const offersNotAvailableMsg = 'Your current mobile subscription has a fixed-term contract. Therefore, it can only be updated by our sales person. Please call to our sales number 0800 04411 (from Monday to Friday between 8AM and 4PM) and we help you to update your subscription';
export const officeHourChainMsg = 'Office-hour chain';
export const officeHourChainValidityRequiredMsg = 'Office-hour chain validity periods are required when time control is enabled.';
export const oldEppDeviceHeadingMsg = 'What to do with old device?';
export const omaElisaClassicMsg = 'OmaElisa Classic';
export const omaElisaClassicSwitchMsg = 'Switch to OmaElisa Classic';
export const omaElisaForCompaniesMsg = 'OmaElisa for companies';
export const omaElisaForEmployeeMsg = 'OmaElisa for employee';
export const omaLaskuServicesMsg = 'OmaLasku services';
export const oneTimePaymentMsg = 'One-time payment';
export const openChatMsg = 'Open chat';
export const openMsg = 'Open';
export const openOrdersMsg = 'Open orders';
export const openStatusDescriptionMsg = 'We have received your case and will start processing it as soon as possible.';
export const openSupportCasesMsg = 'Open support cases:';
export const operatorOfSubscriptionMsg = 'Operator of subscription';
export const optionalMsg = 'optional';
export const orMsg = 'or';
export const orderAcceptorAdditionalInformationMsg = 'The order acceptor has the right to accept and reject orders and redemption requests in Employee OmaElisa. The order acceptor does not get access to OmaElisa for Companies.';
export const orderAcceptorMsg = 'Order acceptor';
export const orderCouldNotBeProcessedMsg = 'Order could not be processed. Call our customer service on {0} and we’ll take care of it. Our customer service helps on {1}. The call price is {2}.';
export const orderDateMsg = 'Order date';
export const orderEsimMsg = 'Order eSIM';
export const orderHistoryMsg = 'Order history';
export const orderingContactMsg = 'Ordering contact';
export const orderingOmaLaskuServiceNotPossibleTitleMsg = 'Ordering the OmaLasku service is not possible';
export const orderingOmaLaskuServiceNotPossibleBodyMsg = 'Ordering the OmaLasku service is blocked. To resolve the situation, contact our customer service.';
export const orderMsg = 'Order';
export const orderNewDeviceTitleMsg = 'Order new device';
export const orderNewDeviceContentMsg = 'Get the right devices for work. If you want to always have the best phone and accessories, you can participate in the device costs yourself.';
export const orderNewSimCardMsg = 'Order a new SIM card';
export const orderRequestsMsg = 'Order requests';
export const orderSimMsg = 'Order SIM';
export const ordersMsg = 'Orders';
export const orStartingAtMonthlyMsg = 'Or starting at {} €/month';
export const orderSubmittedForProcessingMsg = 'The order has been successfully forwarded to order processing';
export const osMsg = 'Operating system';
export const otherMsg = 'Other';
export const otherCompaniesMsg = 'Other companies';
export const otherTopicMsg = 'Other topic';
export const ourCompanyMsg = 'Our Company';
export const outOfOfficeChainMsg = 'Out-of-office chain';
export const outOfStockMsg = 'Out of stock';
export const overMsg = 'Over';
export const paidMsg = 'Paid';
export const pairingInfoMsg = 'Enter your work phone number and we will send you a verification code via SMS. This will verify what employment-related subscriptions or devices you have in use.';
export const palvelunumerotMsg = 'Palvelunumerot';
export const paperInvoiceMsg = 'Paper invoice';
export const passwordMsg = 'Password';
export const passwordsDontMatchMsg = `Passwords don't match.`;
export const payMsg = 'Pay';
export const payerAdditionalDetailsInfoMsg = `Additional information on the payer's name printed on the invoice, eg purchase ledger.`;
export const payerBusinessIdMsg = 'Payer business ID';
export const payerDetailsMsg = 'Additional payer details';
export const payerMsg = 'Payer';
export const payerNameAndBusinessIdMsg = 'Payer name and business ID';
export const payerNameInfoMsg = 'The name of the payer is printed on the invoice';
export const payerNameMsg = 'Payer name';
export const payerNameOrAddressChangeInfoMsg = `This does not affect the customer's contract information, but updates the invoice recipient and postal address. This information appears on the invoice.`;
export const paymentContactDetailsMsg = 'Payment contact details';
export const paymentDetailsMsg = 'Payment details';
export const paymentPeriodMsg = 'Payment period';
export const pcsMsg = 'pcs';
export const pendingApprovalMsg = 'Pending approval';
export const personalAndCompanyDetailsMsg = 'Personal and company details';
export const phoneNumberMsg = 'Phone number';
export const phoneSubscriptionMsg = 'Phone subscription';
export const phoneTrackingMsg = 'Phone tracking';
export const phonesMsg = 'Phones';
export const pinCodeMsg = 'PIN code';
export const pleaseContactCustomerServiceMsg = 'Please contact customer service';
export const pleaseCheckEmailMsg = 'Please check email address. Email address cannot contain å,ä or ö.';
export const possibleExtraDetailsMsg = 'Possible extra details';
export const postOfficeMsg = 'Post Office';
export const postalAddressInfoMsg = `The payer’s postal address printed on the invoice. Letters related to invoicing, such as payment reminders, are sent to this address.`;
export const postalAddressMsg = 'Postal address';
export const postalCodeMsg = 'Postal code';
export const premiumRateBarringMsg = 'Premium rate barring';
export const premiumRateBarringP4Msg = 'Premium rate barring P4';
export const previousMsg = 'Previous';
export const priceMsg = 'Price';
export const privacyPolicyMsg = 'Privacy policy';
export const privateCustomersMsg = 'Private customers';
export const problemWithProcessingOrderMsg = 'There was a problem with processing the order. Please call our customer service on {0} ({1}). Our customer service is open {2}.';
export const processingMsg = 'Processing';
export const processingOrderMsg = 'Please wait while we process the order...';
export const productDeliveryMsg = 'Product delivery';
export const productHasBeenDiscontinuedMsg = 'The product has been discontinued.';
export const productInformationsMsg = 'Product information';
export const productMsg = 'Product';
export const productTypeMsg = 'Product type';
export const productsMsg = 'Products';
export const professionalServicesOrTrainingMsg = 'Professional services/Training';
export const publishMsg = 'Publish';
export const publishedMsg = 'Published';
export const pukCodeMsg = 'PUK-code';
export const punchoutDeviceLimitMsg = 'Unfortunately, the order cannot be processed. The number of devices permitted by your employer has been exceeded. Please contact your company admin for further details.';
export const purposeOfUseExampleMsg = 'E.g. shared phone in warehouse';
export const purposeOfUseMsg = 'Purpose of use';
export const qrCodeGenerationFailureMsg = 'Creating QR code failed. Please contact Elisa customer service.';
export const qrCodeMsg = 'QR code';
export const quantityMsg = 'Quantity';
export const readyMsg = 'Ready';
export const recipientEmailMsg = `Recipient's email address`;
export const recipientMsg = 'Recipient';
export const recipientNameMsg = 'Recipient Name';
export const recommendationUsersDevicesMsg = 'Recommendation: {0} users ({1} devices)';
export const redeemDeviceMsg = 'Redeem device';
export const redeemIsBinding = 'Please note that the redemption of the device is binding, and the change can’t be cancelled.';
export const redeemRequestDisplayIdMsg = 'Redeem request ID number';
export const redeemRequestMsg = 'Redemption Request Information';
export const redeemRequestsMsg = 'Redeem requests';
export const redeemableDeviceMsg = 'Redeemable device';
export const redeemingTheDeviceMsg = 'Redeeming the device';
export const redemptionInvoiceMsg = 'An invoice will be sent to the user for redemption';
export const redemptionMsg = 'Redemption';
export const redemptionPriceMsg = 'Redemption price';
export const referenceIdExampleMsg = '(e.g. employee number)';
export const referenceIdMsg = 'Reference ID';
export const referenceInformationMsg = 'Reference information';
export const referenceMsg = 'Reference';
export const referenceToolTipMsg = `The reference is not automatically updated to user's services. Please check the reference in the product or service information.`;
export const refundMsg = 'Refund';
export const removeDeviceListMsg = 'Remove Device List';
export const removeMsg = 'Remove';
export const removePalvelunumerotMsg = 'Remove Palvelunumerot -service';
export const removePalvelunumerotInDetailMsg = 'Are you sure that you want to remove your subscription to the OmaLasku Palvelunumero service? Note that you will still receive an invoice for any costs that have not been charged yet.';
export const reopenedDescriptionMsg = 'We will continue processing as soon as possible.';
export const reopenedMsg = 'Reopened';
export const replaceableDevicesMsg = 'Replaceable devices';
export const reporterMsg = 'Reporter';
export const reportsMsg = 'Reports';
export const requestorsEmailMsg = `Requestor's email`;
export const reservedMsg = 'Reserved';
export const resolutionMsg = 'Resolution';
export const resolvedMsg = 'Resolved';
export const resolvedStatusDescriptionMsg = 'We have resolved your case and the solution can be seen on this page. We have also emailed the resolution to the case reporter.';
export const retrievingContentMsg = 'Retrieving content...';
export const returnDeviceMsg = 'Return device to Elisa';
export const returnMsg = 'Return';
export const returnToCheckoutMsg = 'Return to checkout';
export const returnToCustomerViewMsg = 'Return to the customer view';
export const returnToHomePageMsg = 'Return to home page';
export const returnToOmaElisaMsg = 'Return to OmaElisa';
export const returnToSubscriptionPageMsg = 'Return to subscription page';
export const returnToTheShopMsg = 'Return to the shop';
export const ringAdministratorsMsg = 'Ring administrators';
export const safeReissaajaMsg = 'Safe use with Elisa Reissaaja outside the EU';
export const salesFormsMsg = 'Sales forms';
export const salesFormsEntryReminderMsg = 'Please remember to make a separate sales entry in Salesforce';
export const saveMsg = 'Save';
export const savingTheDataFailedMsg = 'Saving the data failed. Please retry';
export const searchInvoiceMsg = 'Search invoice...';
export const searchMsg = 'Search';
export const searchPlaceHolderMsg = 'Search...';
export const searchWithCompanyNameOrBusinessIdMsg = 'Search with company name or business ID';
export const selectCompanyMsg = 'Select the company for which you want to place the order';
export const selectCompanyHeaderMsg = 'First, select the company';
export const selectDataPackageMsg = 'Select data package';
export const selectedApproverMsg = 'Selected approver';
export const selectedBillingAccountMsg = 'Selected billing account';
export const selectEffectiveDateMsg = 'Select the date when the change will take effect';
export const selectMsg = 'Select';
export const selectProductMsg = 'Select product';
export const selectSmsPackageMsg = 'Select SMS package';
export const selectSubscriptionMsg = 'Select subscription';
export const selectedExtensionNumberingsMsg = 'Selected extension numbering spaces';
export const selfServiceMarketingBlockInvoicesBodyMsg = 'Stay up to date with your invoices and print warranty receipts and invoice copies. Manage your billing details.';
export const selfServiceMarketingBlockSubscriptionsBodyMsg = ' Manage your corporate subscriptions, barring services and additional services.';
export const selfServiceMarketingBlockWhyUseMsg = 'Why should I use Elisa self service?';
export const serviceBarringMsg = 'Service barring';
export const serviceBarringP2Msg = 'Service barring P2';
export const serviceMsg = 'Service';
export const servicesMsg = 'Services';
export const servicesNotShownMsg = 'Can’t see your services in the list? Add them on My devices and subscriptions page.';
export const settingsMsg = 'Settings';
export const shippingAddressMsg = 'Shipping Address';
export const shopMsg = 'Shop';
export const shoppingCartContentPluralMsg = 'Cart contains {} products';
export const shoppingCartContentSingularMsg = 'Cart contains 1 product';
export const shoppingCartMsg = 'Shopping cart';
export const showLessMsg = 'Show less';
export const showMoreMsg = 'Show more';
export const simCardAlreadyInUseMsg = 'SIM card is already in use with a different subscription.';
export const simCardMsg = 'SIM card';
export const simCardNumberMsg = 'SIM card number';
export const simCardOneSimPerSubscriptionMsg = 'One SIM card can only be connected to a single subscription.';
export const simCardOnlyMsg = 'SIM card only';
export const simCardSizeMsg = 'SIM card size';
export const simCardTypeMsg = 'SIM card type';
export const simChangePendingMsg = 'Additional changes to this subscription are not possible while a SIM card change is in progress.';
export const singleFaresMsg = 'Single fares';
export const smsBarringsMsg = 'SMS barrings';
export const smsPriceNordicBalticMsg = 'SMS price €0.079/message in the Nordic and Baltic countries';
export const somethingWentWrongMsg = 'Something went wrong. Please try again.';
export const speechAndSmsPerMonthMsg = '{} minutes speech and {} sms/month';
export const squareTradeDeductibleMsg = '89 €';
export const startTimeCantBeGreaterMsg = 'Start time cannot be greater than end time';
export const startingAtMsg = 'Starting at';
export const startTroubleshootingMsg = 'Select subscription to start troubleshooting';
export const statusMsg = 'Status';
export const stepsMsg = 'Steps';
export const streetAddressMsg = 'Street address';
export const subChangePendingHeaderMsg = 'Change request in progress';
export const subChangePendingMsg = 'There is a change request in progress. Other changes are not possible at the moment.';
export const subChangeRequestInProcessMsg = `You can't make new changes until the previous change request has been processed. Note that the change will become visible once the change request has been processed.`;
export const subjectMsg = 'Subject';
export const submitMsg = 'Submit';
export const submitOrderMsg = 'Submit Order';
export const submittingFormMsg = 'Submitting form';
export const subscriptionClosedByCreditMsg = 'The subscription has been closed by credit management. If necessary, please contact customer service {0} ({1})';
export const subscriptionClosedMsg = 'Unable to open subscription';
export const subscriptionCountMsg = 'Subscriptions';
export const subscriptionIdMsg = 'Subscription ID';
export const subscriptionMsg = 'Subscription';
export const subscriptionNotCompatibleWithVakioMsg = 'Subscription not compatible with Vakio solution.';
export const subscriptionNumberMsg = 'Subscription number';
export const subscriptionRedeemContactNameMsg = 'The Redeemer';
export const subscriptionReferenceMsg = 'Subscription reference';
export const subscriptionStatusMsg = 'Subscription status';
export const subscriptionSuspendPageHeaderMsg = 'Do you want to suspend the subscription or inactivate it temporarily?';
export const subscriptionSuspendTabToHyllyliittymaMsg = 'Inactivate temporarily';
export const subscriptionSuspendTabToSuspendMsg = 'Suspend';
export const subscriptionSuspendTemporarilyParagraph1Msg = 'Suspension is useful e.g. if the user has lost his/her phone and you want to prevent any kind of misusage.';
export const subscriptionSuspendTemporarilyParagraph2Msg = 'Please note that all recurring charges are running as usual during the time the subscription is suspended.';
export const subscriptionSuspendTemporarilySmallHeaderMsg = 'Suspension';
export const subscriptionSuspendToHyllyliittymaFixedTermMsg = 'Subscription type is fixed term and cannot be inactivated temporarily.';
export const subscriptionSuspendToHyllyliittymaParagraph1Msg = 'Temporary inactivation is useful e.g. if you have no use for the subscription for a longer time and you do want to keep the number.';
export const subscriptionSuspendToHyllyliittymaParagraph2Msg = 'Please remember to change subscription user details and directory information after temporary inactivation.';
export const subscriptionSuspendToHyllyliittymaSmallHeaderMsg = 'Temporary inactivation of subscription';
export const subscriptionSuspendVakioTabToHyllyliittymaBackToSubMsg = 'Return to subcription page';
export const subscriptionSuspendVakioTabToHyllyliittymaMsg = 'Temporary inactivation (changing to Hyllyliittymä subscription) is not possible for this subscription';
export const subscriptionSuspendVakioTabToHyllyliittymaParagraph1Msg = 'Subscription has such add-ons that prevent subscription change.';
export const subscriptionSuspendVakioTabToHyllyliittymaParagraph2Msg = 'Please contact Elisa customer service by calling {0} ({1}).';
export const subscriptionTroubleshootingMsg = 'In case of connection issues, you can quickly troubleshoot your subscription.';
export const subscriptionTypeMsg = 'Subscription type';
export const subscriptionsMsg = 'Subscriptions';
export const subtractMsg = 'Subtract';
export const summaryMsg = 'Summary';
export const sumPerMonthMsg = '{}/Month';
export const suomiMsg = 'Suomi';
export const supportCaseMsg = 'Support case';
export const supportCaseThankYouInfoMsg = 'We will contact you as soon as possible.';
export const supportCaseThankYouMsg = 'Thank you for your message';
export const supportCasesMsg = 'Support cases';
export const supportMsg = 'Support';
export const supportOrServiceRequestMsg = 'Support or service request';
export const supportRequestMsg = 'Support request';
export const svenskaMsg = 'Svenska';
export const swedishMsg = 'Swedish';
export const switchingCompanyToMsg = 'Switching company to';
export const systemErrorOccurredMsg = 'System error occurred, please try again after sometime.';
export const tabletsMsg = 'Tablets';
export const technicalAdviceMsg = 'Technical advice and instructions';
export const technicalDataMsg = 'Technical data';
export const technologyMsg = 'Technology';
export const temporarilyClosedMsg = 'Temporarily closed';
export const terminateAgreementAndReturnDeviceMsg = 'Terminate agreement and return device';
export const terminateSubscriptionMsg = 'Terminate subscription';
export const terminationIsBindingMsg = 'Please note that the termination of the subscription is binding, and the change can’t be cancelled.';
export const terminationMsg = 'Termination';
export const termsAndConditionsMsg = 'Terms and conditions';
export const termsOfUseMsg = 'Terms of use';
export const textMessagesMsg = 'Text messages';
export const tooManyFilesMsg = 'Too many files ({}/{})';
export const topicMsg = 'Topic';
export const totalMsg = 'Total';
export const totalPricesMsg = 'SP for you {}, total {}';
export const totalWithCurlyBracketsMsg = 'Total {}';
export const transferCurrentPhoneNumberMsg = 'Transfer current phone number';
export const transferSubToAnotherBillingAccountMsg = 'Transfer subscription to another billing account';
export const typeMsg = 'Type';
export const underMsg = 'Under';
export const unknownMsg = 'Unknown';
export const unlimitedDataInFinlandNordicBalticMsg = 'Unlimited data in Finland, the Nordic countries and the Baltics';
export const unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg = 'Unlimited internet in Finland, the Nordic countries and the Baltics, {}GB/month in the rest of the EU.';
export const unlimitedInternetInFinlandNordicBalticMsg = 'Unlimited internet in Finland, the Nordic countries and the Baltics';
export const unlimitedMsg = 'Unlimited';
export const unlistedMsg = 'Unlisted';
export const updateSubscriptionMsg = 'Update subscription';
export const uploadSpeedMsg = 'Upload speed';
export const useAbroadMsg = 'Use abroad';
export const userDetailsMsg = 'User details';
export const userInformationAddedMsg = 'User information added to the product.';
export const userInformationMsg = 'User information';
export const userInformationUpdatedMsg = 'User information updated for the product.';
export const userNameMsg = 'User name';
export const userRedemptionPriceMsg = 'Redemption price for the user';
export const userRightsAdditionalInformationMsg = `You can manage OmaElisa for companies' access rights`;
export const userRightsInstructionMsg = `The administrator gets the right to log in to OmaElisa for Companies. The administrator has the right to accept and reject orders, see all the company's subscription and company information, accept invoices, and place device orders. The user’s work email address is used as user ID and the password is delivered by SMS.`;
export const usersMsg = 'Users';
export const userTextMsg = 'User';
export const vakioConfigurationErrorMsg = 'An error occurred when updating the Vakio configuration';
export const valueMsg = 'Value';
export const vatMsg = 'VAT';
export const vatPercentageMsg = 'VAT {}%';
export const viewAllMsg = 'View all';
export const viewBlockedNumbersMsg = 'View blocked numbers';
export const viewPukCodeTitleMsg = 'View PUK codes';
export const viewPukCodeContentMsg = `You can check your subscriptions' PUK codes on My subscriptions page. Click the subscription to see the PUK2 code.`;
export const viewThePublishedVersionMsg = 'View the published version';
export const voiceAndBroadbandMsg = 'Voice and broadband';
export const voiceMsg = 'Voice';
export const voiceSubscriptionsMsg = 'Voice subscriptions';
export const waitingForActionMsg = 'Waiting for your action';
export const waitingForInputStatusDescriptionMsg = 'We have requested further information from the case reporter. We kindly ask you to reply to us as soon as possible so that we can proceed with the case.';
export const waitingForProcessingMsg = 'Waiting for processing';
export const weekMsg = 'week';
export const welcomeToManageYourBusinessMsg = 'Welcome to manage your businesses';
export const whatDoYouWantToDoMsg = 'What do you want to do next?';
export const wifiCallServiceMsg = 'Elisa WiFi calling service';
export const willPayersNameOrAddressChangeMsg = `Will payer's name or address change`;
export const willTheChangeTakeEffectImmediatelyMsg = 'Will the change take effect immediately';
export const workEmailMsg = 'Work email address';
export const workPhoneNumberMsg = 'Work phone number';
export const writeMessageMsg = 'Write a message';
export const yesMsg = 'Yes';
export const youCanMakeChangesAfterSubActivatedMsg = 'You can make changes once the subscription has been activated.';
export const yourLatestOrdersMsg = 'Your latest orders';
export const yourMessageWasSentMsg = 'Your message was sent. Refresh the page to see the newest support cases.';
export const yourOldSIMCardWillNowStopWorkingMsg = 'Your old SIM card will now stop working';
export const yourOrderHasBeenTransferredForProcessingMsg = 'Your order has been transferred for processing';
export const noSearchResults = 'No search results';
export const passwordInstructionErrorMsg = 'Your password must be at least 8 characters long, and contain at least one number, one uppercase and one lowercase character.';
export const showAllSubscriptionsMsg = 'Show all subscriptions';
export const notMandatoryMsg = '(not mandatory)';
export const notAbleToProcessContactRequestMsg = 'Unfortunately we are not able to process your request, because there is already similar contact details in our system. Please contact Elisa Customer Service.';
export const numberGSubscriptionsMsg = '{}G-subscriptions';
// Use alphabetical order.
// Note, this file has line splitting by prettier effectively disabled (max length 9999) in prettierrc.json.
// Put here all English text strings that are used in multiple places.
// All shared test strings must have `Msg` suffix.
