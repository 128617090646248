import { priceToString } from './priceUtils.js';
import type { ShoppingBasketType } from '../types/shoppingBasket.js';

// https://docs.giosg.com/tutorials/setting_up_giosg_basket/#supported-fields-on-product-object
/* eslint-disable @typescript-eslint/naming-convention */
type GiosgCartProduct = {
  name: string; // Name of the product
  price: string; // Price of the product in decimal format, for example “10.50”. Max 2 decimals is supported
  quantity: number; // Count of this kind of product in cart. (integer)
  description?: string; // Longer description of the product
  monthly_price?: string; // Monthly billable price of the product in decimal format, for example “9.90”. Max 2 decimals is supported
  monthly_plan_length?: number; // Monthly plan length (integer)
  category?: string; // Category of the product
  product_number?: string; // Product number or code. For example “313” or “SKU-234”
};
/* eslint-enable @typescript-eslint/naming-convention */

const isGiosgWindow = (w: Window | GiosgWindow): w is GiosgWindow => !!(w as GiosgWindow)._giosg;

type GiosgWindow = Window &
  typeof globalThis & {
    _giosg: (func: () => void) => void;
    giosg?: {
      api?: {
        shoppingCart?: {
          submit?: (products: GiosgCartProduct[]) => void;
        };
      };
    };
  };

function mapProducts(basket: ShoppingBasketType): GiosgCartProduct[] {
  return (basket?.items ?? []).map((item): GiosgCartProduct => {
    const { name, offer, quantity } = item;
    const { commercialProductGuid, oneTimePriceInCents, periodicPrice } = offer;
    const periodicPayments = periodicPrice ? periodicPrice.payments : 0;
    const periodicTotal =
      ((periodicPrice?.periodicPriceInCents || 0) * (periodicPayments || 12)) / (periodicPrice?.period || 1);

    return {
      name,
      price: priceToString(Math.max(oneTimePriceInCents ?? 0, periodicTotal)) || '0',
      product_number: commercialProductGuid, // eslint-disable-line @typescript-eslint/naming-convention
      quantity,
      ...(periodicPrice?.periodicPriceInCents &&
        periodicPrice?.payments && {
          monthly_price: priceToString(periodicPrice.periodicPriceInCents), // eslint-disable-line @typescript-eslint/naming-convention
          monthly_plan_length: periodicPrice.payments, // eslint-disable-line @typescript-eslint/naming-convention
        }),
    };
  });
}

export function submitGiosg(basket: ShoppingBasketType) {
  if (isGiosgWindow(window)) {
    window._giosg(function () {
      (window as GiosgWindow)?.giosg?.api?.shoppingCart?.submit?.(mapProducts(basket));
    });
  }
}
