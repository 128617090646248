import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { getTimeDisplayFormat } from '../PbxAccordionContent/PbxAccordionUtils';

export interface PbxWorkingHoursConfig {
  workingHoursWeekdayStart?: string;
  workingHoursWeekdayEnd?: string;
  workingHoursWeekendStart?: string;
  workingHoursWeekendEnd?: string;
  workingHoursSaturdayStart?: string;
  workingHoursSaturdayEnd?: string;
  workingHoursSundayStart?: string;
  workingHoursSundayEnd?: string;
}

/**
 * These PBX types have Saturday and Sunday time ranges compressed into one field, Weekend.
 * Others will have Saturday and Sunday separately.
 */
export const weekEndTypes: Readonly<Array<SubscriptionPbxDetails.PbxTypeEnum | undefined>> = [
  SubscriptionPbxDetails.PbxTypeEnum.VAKIO,
  SubscriptionPbxDetails.PbxTypeEnum.VASTAUSSARJA,
  SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU,
] as const;

export const convertValueFormatToDisplayFormat = (valueFormat?: string) => {
  if (valueFormat === undefined) {
    return undefined;
  }
  const [hours, minutes] = valueFormat.replace('.', ':').split(':');
  return getTimeDisplayFormat(parseInt(hours, 10), parseInt(minutes, 10));
};
