import { AttachVakioPbxContent } from '../AttachPbxContent/AttachVakioPbxContent';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { CATEGORY_TEXTS } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { DetailsWrapper } from '../DetailsWrapper';
import { EmptyOrError } from '../EmptyOrError';
import { Loading } from '../Loading';
import { NumberRange, PbxType, type Subscription, SubscriptionType } from '../../generated/api/models.js';
import {
  connectToVakioMsg,
  omaElisaForCompaniesMsg,
  returnMsg,
  subscriptionNotCompatibleWithVakioMsg,
  t,
} from '../../common/i18n';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionBreadcrumbName, getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { CategoryKey } from '../../common/utils/categoryUtils';
import type { CompanyInfoState } from '../../common/types/states';
import type { NumberRangeData } from '../../common/loaders';

export interface AttachVakioProps {
  category: CategoryKey;
  companyInfo?: CompanyInfoState | null;
  numberRanges: NumberRangeData[];
  subscription: Subscription;
}

const getErrorElement = (props: AttachVakioProps, onClickSubscription: () => void) => {
  const { subscription, companyInfo } = props;
  const pbxSolutions = companyInfo!.pbxSolutions;
  const vakioNotFoundError = (
    <EmptyOrError
      critical={true}
      id="vakio-not-found"
      text={t.O7OC('Your company does not have an active Vakio solution.')}
      onButtonClick={onClickSubscription}
      buttonText={t.VH59(returnMsg)}
    />
  );

  if (!pbxSolutions || pbxSolutions.length < 1) {
    return vakioNotFoundError;
  }

  const vakioPxbSolution = pbxSolutions.find(
    solution => solution.subscriptionType === SubscriptionType.MOBILE_PBX_LITE
  );
  if (!vakioPxbSolution) {
    return vakioNotFoundError;
  }

  if (
    !vakioPxbSolution.details ||
    !vakioPxbSolution.details.mobilePbx ||
    vakioPxbSolution.details.mobilePbx.numberRanges.find(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
    ) === undefined ||
    vakioPxbSolution.details.mobilePbx.numberRanges.find(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.EXTENSION
    ) === undefined
  ) {
    return vakioNotFoundError;
  }

  if (!subscription!.compatiblePbxTypes || !subscription!.compatiblePbxTypes.includes(PbxType.MOBILE_PBX_LITE)) {
    return (
      <EmptyOrError
        id="not-vakio-compatible"
        text={t.CCR4(subscriptionNotCompatibleWithVakioMsg)}
        onButtonClick={onClickSubscription}
        buttonText={t.VH59(returnMsg)}
      />
    );
  }

  if (
    subscription!.details!.mobile &&
    subscription!.details!.mobile!.pbxConfiguration &&
    subscription!.details!.mobile!.pbxConfiguration!.pbxSolutionId
  ) {
    return (
      <EmptyOrError
        id="already-vakio-connected"
        text={t.GKWF('Subscription is already connected to Vakio solution.')}
        onButtonClick={onClickSubscription}
        buttonText={t.VH59(returnMsg)}
      />
    );
  }
  return undefined;
};

export const AttachVakio = (props: AttachVakioProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  if (!props.subscription || !props.companyInfo || props.companyInfo?.companyName === undefined) {
    return <Loading />;
  }
  const onClickSubscription = () => {
    navigate(
      `${generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(props.subscription!) })}${location.search}`
    );
  };

  const errorElement = getErrorElement(props, onClickSubscription);
  if (errorElement) {
    return errorElement;
  }

  const breadCrumbs: JSX.Element = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: CATEGORY_TEXTS()[props.category], path: paths.PS_MOBILE_SUBSCRIPTIONS },
        {
          name: getSubscriptionBreadcrumbName(props.subscription),
          path:
            generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(props.subscription) }) +
            search,
        },
        { name: t.M0TS(connectToVakioMsg) },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-attach-vakio']}
      detailsTop={breadCrumbs}
      id="of-attach-vakio"
      heading={t.RL8F('Add subscription to Vakio')}
      heroPicto="pbx-lite"
    >
      <AttachVakioPbxContent {...props} onClickSubscription={onClickSubscription} />
    </DetailsWrapper>
  );
};
