import { CustomerOrderStatus, DeliveryOrder } from '../../generated/api/models.js';
import { StatusColor } from '../../common/enums.js';
import {
  backorderedMsg,
  cancelledMsg,
  deliveredMsg,
  openMsg,
  pendingApprovalMsg,
  processingMsg,
  t,
  unknownMsg,
} from '../../common/i18n/index.js';
import type { CustomerOrder } from '../../generated/api/models.js';

export const orderContainsBillingPeriod = (customerOrder: CustomerOrder) =>
  customerOrder.deliveryOrders.some(deliveryOrder => deliveryOrder.billingPeriod);

export const getCustomerOrderStatusInfo = (customerOrderStatus?: string): { color: StatusColor; text: string } => {
  switch (customerOrderStatus) {
    case CustomerOrderStatus.COMPLETED.toString():
      return {
        color: StatusColor.GREEN,
        text: t.PQGG(deliveredMsg),
      };

    case CustomerOrderStatus.FAILED.toString():
    case CustomerOrderStatus.IN_PROGRESS.toString():
    case CustomerOrderStatus.PARTIALLY_FAILED.toString():
      return {
        color: StatusColor.YELLOW,
        text: t.VZZW(processingMsg),
      };

    case CustomerOrderStatus.PARTIALLY_COMPLETED.toString():
      return {
        color: StatusColor.YELLOW,
        text: t.SJ7E('Partially delivered'),
      };

    case CustomerOrderStatus.PENDING.toString():
      return {
        color: StatusColor.GREY,
        text: t.TJ54(openMsg),
      };

    case CustomerOrderStatus.PENDING_APPROVAL.toString():
      return {
        color: StatusColor.SALMON,
        text: t.ZXA6(pendingApprovalMsg),
      };

    case CustomerOrderStatus.CANCELLED.toString():
      return {
        color: StatusColor.GREY,
        text: t.PEKP(cancelledMsg),
      };

    case undefined:
    default:
      return { color: StatusColor.GREY, text: t.QWC8(unknownMsg) };
  }
};

export const getDeliveryOrderStatusInfo = (deliveryOrderStatus?: string): { color: StatusColor; text: string } => {
  switch (deliveryOrderStatus) {
    case DeliveryOrder.StatusEnum.CANCELLED.toString():
      return {
        color: StatusColor.GREY,
        text: t.PEKP(cancelledMsg),
      };

    case DeliveryOrder.StatusEnum.COMPLETED.toString():
      return {
        color: StatusColor.GREEN,
        text: t.PQGG(deliveredMsg),
      };

    case DeliveryOrder.StatusEnum.IN_PROGRESS.toString():
      return {
        color: StatusColor.YELLOW,
        text: t.VZZW(processingMsg),
      };

    case DeliveryOrder.StatusEnum.PENDING.toString():
      return {
        color: StatusColor.GREY,
        text: t.TJ54(openMsg),
      };

    case DeliveryOrder.StatusEnum.SUBMITTED_WAITING_FOR_STOCK.toString():
      return {
        color: StatusColor.YELLOW,
        text: t.MMRF(backorderedMsg),
      };

    case undefined:
      return { color: StatusColor.GREY, text: t.QWC8(unknownMsg) };

    default:
      throw new Error(`Unknown DeliveryOrder status ${deliveryOrderStatus}`);
  }
};

/**
 * Move deliveryFees always on last row
 */
export const DeliveryOrdersComparator = (a: DeliveryOrder, b: DeliveryOrder): number => {
  if (a.productType === DeliveryOrder.ProductTypeEnum.DELIVERY_FEE) {
    return 1;
  }
  if (b.productType === DeliveryOrder.ProductTypeEnum.DELIVERY_FEE) {
    return -1;
  }
  return 0;
};
