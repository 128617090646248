import { EcommerceEventTypeV4 } from '../analytics.js';
import { FIVE_G_KEY } from '../../components/ProductGrid/ProductGridFilter.js';
import type { FilterEvent, SortEvent } from '../../common/types/analytics.js';
import type { Filters } from '../../components/ProductGrid/ProductGridFilter.js';
import type { SortType } from '../../components/ProductGrid/ProductGridSort';

export const getItemVariant = (eventType: EcommerceEventTypeV4, commercialProductName: string): string | undefined =>
  eventType !== EcommerceEventTypeV4.SELECT_ITEM && eventType !== EcommerceEventTypeV4.VIEW_ITEM_LIST
    ? commercialProductName
    : undefined;

export const createSortEvent = (sortType: SortType): SortEvent => {
  return {
    event: 'apply_sort',
    sort: sortType.valueOf(),
  };
};

export const createFilterEvent = (filters: Filters): FilterEvent => {
  return {
    event: 'apply_filter',
    filter: {
      brand: filters.FILTER_MANUFACTURER.selectedOptions,
      price: filters.FILTER_COST.selectedOptions,
      os: [...filters.FILTER_OS_COMPUTERS.selectedOptions, ...filters.FILTER_OS_PHONES.selectedOptions],
      fiveG: filters.FILTER_5G.selectedOptions.indexOf(FIVE_G_KEY) !== -1,
      offers: filters.FILTER_OFFERS.selectedOptions.length !== 0,
    },
  };
};
