import { Link, generatePath } from 'react-router-dom';
import { SubscriptionIcon } from './SubscriptionIcon.js';
import { getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import type { ReactNode } from 'react';
import type { SubscriptionHeader } from '../../generated/api/models.js';

interface SubscriptionLinkProps {
  path: string;
  subscription: SubscriptionHeader;
  children?: ReactNode;
  search?: string;
}
export const SubscriptionLink = ({ children, path, subscription, search }: SubscriptionLinkProps) => {
  if (!subscription.accountMasterId) {
    throw new Error('missing mdmId');
  }
  const effectivePath = `${generatePath(path, { subscriptionId: getSubscriptionUrlId(subscription) })}?mdmId=${subscription.accountMasterId}`;

  return (
    <Link to={effectivePath} className="of-subscription-table__text-column">
      <SubscriptionIcon subscription={subscription} />
      <div>
        {highlightKeyword(subscription.subscriptionName || '', search)}
        {children}
      </div>
    </Link>
  );
};
