import * as CL from '@design-system/component-library';
import type { ReactNode } from 'react';

export interface GridProps {
  children: ReactNode;
  colWidthL?: CL.GridColProps['colWidthL'];
  className?: string;
}

export const Grid = ({ children, colWidthL = 10, className }: GridProps) => {
  return (
    <CL.Grid className={className}>
      <CL.GridRow justifyCenter>
        <CL.GridCol colWidthXS={4} colWidthS={6} colWidthL={colWidthL}>
          {children}
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};

export const GridNarrow = ({ children }: GridProps) => <Grid colWidthL={8}>{children}</Grid>;
export const GridWide = ({ children }: GridProps) => <Grid colWidthL={12}>{children}</Grid>;
