import { DialogWrapper } from '../DialogWrapper';
import { cancelMsg, confirmMsg, t } from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses.js';

export interface YttContactChangeDialogProps {
  submitting?: boolean;
  onCloseDialog: () => void;
  onConfirm: () => void;
}

export const YttContactChangeDialog = ({ onCloseDialog, onConfirm, submitting }: YttContactChangeDialogProps) => (
  <DialogWrapper
    buttons={[
      {
        color: 'link',
        onClick: onCloseDialog,
        text: t.B2V1(cancelMsg),
      },
      {
        onClick: onConfirm,
        text: t.QVYK(confirmMsg),
        isLoading: submitting,
      },
    ]}
    closeable
    header={t.V02H('Please verify, this will change the credentials for Elisa Yritystietoturva')}
    onCloseDialog={onCloseDialog}
  >
    <div>
      {t.TXTH(
        'This user is the administrator of Elisa Yritystietoturva. Note that if you change their name, mobile phone number, or email address, the administrator credentials to Elisa Yritystietoturva will also change.'
      )}
    </div>
    <div className={dsClass.MARGIN_TOP_4}>
      {t.N2OE(
        'The administrator will then receive new credentials from F-Secure via email. They will need to use the new credentials to log back in.'
      )}
    </div>
  </DialogWrapper>
);
