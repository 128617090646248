import { ContactRole } from '../../../../generated/api/contactRole.js';
import { EmployeeHomeScene } from '../../../../components/EmployeeHomeScene/EmployeeHomeScene.js';
import { Link } from 'react-router-dom';
import { SubscriptionCategory } from '../../../../common/enums';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import {
  getEmployeeSubscriptions,
  loadCompanyInfo,
  loadCustomerOrders,
  loadSubscriptionActions,
  loadVirtualCatalogs,
} from '../../../../selfservice/actions/index.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { t } from '../../../../common/i18n/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../selfservice/common/store.js';

export const EmployeeHomePath = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();

  const isLinkedWithAccount = Boolean(authenticatedUser?.businessId);

  const selfService = useSelector((state: State) => state.selfservice, deepEqual);
  const subscriptions = useSelector((state: State) => state.user?.authenticated?.subscriptions, deepEqual);
  const subscriptionActions = selfService?.pendingSubscriptionActions?.items || [];

  useEffect(() => {
    if (authenticatedUser?.businessId) {
      dispatch(loadCompanyInfo());
      dispatch(loadVirtualCatalogs());
      dispatch(getEmployeeSubscriptions(SubscriptionCategory.DEVICE));
      dispatch(getEmployeeSubscriptions(SubscriptionCategory.BROADBAND));
      dispatch(getEmployeeSubscriptions(SubscriptionCategory.VOICE));
      // but not this -> As always load both orders and actions on orders, needed for indicator in the tab
      if (authenticatedUser?.contact?.person?.roles?.includes(ContactRole.APPROVER)) {
        dispatch(loadSubscriptionActions({ forceLoad: true }));
        dispatch(loadCustomerOrders(undefined, undefined, undefined, undefined, false, true));
      }
    }
  }, [dispatch, authenticatedUser?.businessId, authenticatedUser?.contact?.person?.roles]);

  return (
    <>
      <EmployeeHomeScene subscriptions={subscriptions} subscriptionActions={subscriptionActions} />
      {!isLinkedWithAccount && (
        <div className="of-employee__guide-notification-container">
          <div className="ea-context-notification">
            <div className="ea-context-notification__text">
              <h4 className="ea-h4">{t.I2YS('Important information')}</h4>
              {t.T58Y('Your profile is not associated with any company or business yet. You can either ')}
              <Link to={paths.EMPLOYEE_SUBSCRIPTIONS} rel="noopener noreferrer">
                {t.PDYE('link a company provided subscription ')}
              </Link>
              {t.TQFU('or ask for invitation link from your company admin to sign up to this portal.')}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
