import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { DeviceCheckoutThankYou } from '../DeviceCheckoutThankYou/DeviceCheckoutThankYou.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getContactInfo } from '../../common/utils/stateUtils.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useCheckout } from './Checkout.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { State } from '../../selfservice/common/store.js';

export const CheckoutThankYouOrError = ({
  isEmployee,
  useBasketData,
}: {
  isEmployee: boolean;
  useBasketData?: boolean;
}) => {
  const { authenticatedUser } = useAuth();
  const { onlineOrderId, cartItems, companyInfo, deliveryDetails, onlineModels } = useSelector(
    (state: State) => ({
      onlineOrderId: state.deviceCheckout?.onlineOrderId,
      cartItems: state.deviceCheckout?.cartItems || [],
      companyInfo: state.selfservice?.companyInfo || {},
      deliveryDetails: state.deviceCheckout?.deliveryDetails,
      onlineModels: state.selfservice?.onlineModels || undefined,
    }),
    deepEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirectPath } = useCheckout();
  const thankYouPageForbiddenError = [{ message: 'not allowed', type: CommonErrorType.FORBIDDEN }];

  if (onlineOrderId && authenticatedUser) {
    return (
      <DeviceCheckoutThankYou
        cartItems={cartItems}
        companyInfo={companyInfo}
        contactInfo={getContactInfo(authenticatedUser)}
        deliveryDetails={deliveryDetails!}
        isEmployee={isEmployee}
        onlineModels={onlineModels}
        useBasketData={useBasketData}
      />
    );
  } else if (hasSystemError(thankYouPageForbiddenError)) {
    return (
      <SystemError
        errors={thankYouPageForbiddenError}
        onButtonClick={() => {
          dispatch(resetErrors());
          navigate(redirectPath);
        }}
      />
    );
  } else {
    return <CL.LoadingSpinner size="l" />;
  }
};
