import { Input } from '../Input/Input.js';
import { getTextError } from '../../common/react-hook-form/utils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { t } from '../../common/i18n/index.js';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import type { State } from '../../selfservice/common/store.js';

import './ReCaptcha.scss';

export interface ReCaptchaProps {
  name: string;
  required?: boolean;
}

const ReCaptchaWidget = ({
  name,
  required = false,
  sitekey,
}: {
  name: string;
  required?: boolean;
  sitekey: string;
}) => {
  const validate = (value: string) => {
    if (!value && required) {
      return t.B5AC('ReCAPTCHA verification is required');
    }
    return undefined;
  };

  const { control, setValue } = useFormContext();
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: { required: { value: required ?? false, message: t.B5AC('ReCAPTCHA verification is required') }, validate },
  });

  return (
    <div className="of-recaptcha">
      <ReCAPTCHA
        sitekey={sitekey}
        onChange={(value: string) => setValue(name, value)}
        onExpired={() => setValue(name, '')}
      />
      <Input
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
        required={required}
        type="hidden"
        value={field.value || ''}
        error={getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error)}
      />
    </div>
  );
};

export const ReCaptcha = ({ name, required = false }: ReCaptchaProps) => {
  const reCaptchaSiteKey = useSelector((s: State) => s.config.googleReCaptchaSiteKey);

  if (!reCaptchaSiteKey || !isInBrowser()) {
    return null;
  }

  return <ReCaptchaWidget name={name} sitekey={reCaptchaSiteKey} required={required} />;
};
