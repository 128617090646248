import * as CL from '@design-system/component-library';
import { DialogType, ModelType } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../Loading/index.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { ServiceLevelAndAddons } from '../AttachRing/ServiceLevelAndAddons.js';
import { cancelMsg, confirmMsg, editMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { getOpeningFeeAddons, getPbxServiceLevelsAndAddons } from '../../common/utils/ringUtils.js';
import { terminateSubscriptionAction } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { AnyAction, Dispatch } from 'redux';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { OnlineModel, Subscription, SubscriptionPbxConfiguration } from '../../generated/api/models.js';
import type { ServiceLevel, ServiceLevelAddon } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';

import './RingSettingsAccordionContent.scss';

interface RingSettingsAccordionContentProps {
  companyInfo?: CompanyInfoState | null;
  ringUserNames: string[];
  subscriptionPbxConfiguration: SubscriptionPbxConfiguration;
  pbxServiceLevels?: ServiceLevel[];
  pbxServiceLevelAddons?: ServiceLevelAddon[];
  subscriptionId: string;
  pbxSolution: Subscription;
  scrollTo?: (elementOrY: HTMLElement | number) => void;
  editing?: boolean;
  saving?: boolean;
  addOnAssociations: AssociationRecord[];
  addOnDependencies: DependencyRecord[];
  ringModels: OnlineModel[];
  subscription: Subscription;

  onEdit: () => boolean;
  onCancel: () => void;
  onSave?: (pbxConfiguration: SubscriptionPbxConfiguration) => void;
  onShowDialog: (params: DialogParams) => void;
}

export interface RingSettingsFormValues {
  ringUserName: string;
  addonCodes: string[];
  serviceLevel?: string;
}

const RemoveButton = ({
  onShowDialog,
  subscription,
  dispatch,
}: {
  onShowDialog: (params: DialogParams) => void;
  subscription: Subscription;
  dispatch: Dispatch<AnyAction>;
}) => (
  <div className={dsClass.PADDING_TOP_4}>
    <CL.Button
      color="light"
      onClick={() => {
        onShowDialog({
          subscription,
          type: DialogType.DETACH_RING,
          onTerminateSubscription: (subscriptionId, effectiveDate, donateNumber, terminateType) =>
            dispatch(terminateSubscriptionAction(subscriptionId, effectiveDate, donateNumber, terminateType)),
        });
      }}
    >
      {t.V2IT('Remove ring from subscription')}
    </CL.Button>
  </div>
);

const RingSettingsButtons = ({
  onShowDialog,
  subscription,
  dispatch,
  editing,
  saving,
  onCancel,
  onEdit,
  onConfirm,
}: {
  onShowDialog: (params: DialogParams) => void;
  subscription: Subscription;
  dispatch: Dispatch<AnyAction>;
  editing?: boolean;
  saving?: boolean;
  onCancel: () => void;
  onEdit: () => boolean;
  onConfirm: () => void;
}) => {
  if (editing) {
    return (
      <div className={dsClass.PADDING_TOP_4}>
        <CL.Button className={dsClass.MARGIN_RIGHT_4} onClick={() => onConfirm()} disabled={saving}>
          {t.QVYK(confirmMsg)}
        </CL.Button>
        <CL.Button color="light" onClick={() => onCancel()}>
          {t.B2V1(cancelMsg)}
        </CL.Button>
        <RemoveButton onShowDialog={onShowDialog} dispatch={dispatch} subscription={subscription} />
      </div>
    );
  }

  return (
    <div className={dsClass.PADDING_TOP_4}>
      <CL.Button color="light" onClick={() => onEdit()} disabled={saving}>
        {t.NVPK(editMsg)}
      </CL.Button>
      <RemoveButton onShowDialog={onShowDialog} dispatch={dispatch} subscription={subscription} />
    </div>
  );
};

const RingUserName = ({
  activeRingUserName,
  editing,
  ringUserNames,
}: {
  activeRingUserName?: string;
  editing?: boolean;
  ringUserNames: string[];
}) => {
  return activeRingUserName || editing ? (
    <CL.Description
      items={[
        {
          title: t.H3AZ('Ring user name'),
          description: editing ? (
            <SelectRadio
              name="ringUserName"
              items={ringUserNames.map(userName => ({
                label: userName,
                value: userName,
              }))}
            />
          ) : (
            <div className={dsClass.PADDING_BOTTOM_4}>{activeRingUserName}</div>
          ),
        },
      ]}
    />
  ) : null;
};

export const RingSettingsAccordionContent = ({
  companyInfo,
  addOnAssociations,
  addOnDependencies,
  ringModels,
  ringUserNames,
  subscription,
  subscriptionPbxConfiguration,
  onEdit,
  editing,
  onCancel,
  onSave,
  saving,
  onShowDialog,
}: RingSettingsAccordionContentProps) => {
  const methods = useForm<RingSettingsFormValues>({
    values: {
      ringUserName: subscriptionPbxConfiguration.pbxConfigurationDetails.ringUserName ?? ringUserNames[0],
      addonCodes: [],
      serviceLevel: subscriptionPbxConfiguration.pbxConfigurationDetails.ringServiceLevelCode,
    },
  });

  const dispatch = useDispatch();
  const ringServiceLevelCode = methods.watch('serviceLevel');
  const activeRingUserName = methods.watch('ringUserName');

  const ringServicesModel = ringModels.find(model => model.onlineModelCode === ModelType.Ring);
  const ringServiceLevelsAndAddons = getPbxServiceLevelsAndAddons(ringServicesModel, companyInfo);
  ringServiceLevelsAndAddons.pbxServiceLevels = ringServiceLevelsAndAddons.pbxServiceLevels.map(item => {
    return { ...item, selected: item.commercialProductCode === ringServiceLevelCode };
  });

  const openingFeeAddons = getOpeningFeeAddons(
    ringServiceLevelsAndAddons.pbxServiceLevelAddons,
    addOnDependencies,
    addOnAssociations
  );

  const onSubmit = (values: RingSettingsFormValues) => {
    if (onSave) {
      const pbxConfigToSave: SubscriptionPbxConfiguration = {
        pbxSolutionId: subscriptionPbxConfiguration.pbxSolutionId,
        corporateNumber: subscriptionPbxConfiguration.corporateNumber,
        pbxConfigurationDetails: {
          pbxType: subscriptionPbxConfiguration.pbxConfigurationDetails.pbxType,
          ringUserName: values.ringUserName,
          ringServiceLevelCode: values.serviceLevel,
          ringAdditionalServiceCodes: values.addonCodes,
        },
      };
      onSave(pbxConfigToSave);
    }
  };

  if (!ringServicesModel || !ringServiceLevelsAndAddons.pbxServiceLevels?.length) {
    return <Loading />;
  }
  return (
    <div className="of-ring-settings">
      <FormProvider {...methods}>
        <form>
          <CL.Description
            items={[
              {
                title: t.QI6G('Corporate number'),
                description: formatPhoneNumber(subscriptionPbxConfiguration.corporateNumber, true),
              },
              {
                title: t.NO66('Extension number'),
                description: formatPhoneNumber(subscriptionPbxConfiguration.extensionNumber ?? ''),
              },
            ]}
          />
          <RingUserName ringUserNames={ringUserNames} activeRingUserName={activeRingUserName} editing={editing} />
          <ServiceLevelAndAddons
            associations={addOnAssociations}
            dependencies={addOnDependencies}
            ringModels={ringModels}
            inputServiceLevels={ringServiceLevelsAndAddons.pbxServiceLevels}
            inputAddons={ringServiceLevelsAndAddons.pbxServiceLevelAddons}
            openingFeeAddons={openingFeeAddons}
            editing={editing}
            updateComponentValues={(serviceLevelParams: {
              pbxServiceLevels: ServiceLevel[];
              pbxServiceLevelAddons: ServiceLevelAddon[];
            }) => {
              if (serviceLevelParams?.pbxServiceLevels && serviceLevelParams?.pbxServiceLevelAddons) {
                const selectedServiceLevel = serviceLevelParams.pbxServiceLevels.find(item => item.selected === true);

                methods.setValue('serviceLevel', selectedServiceLevel?.commercialProductCode ?? '');
                methods.setValue(
                  'addonCodes',
                  serviceLevelParams.pbxServiceLevelAddons.filter(addon => addon.selected).map(addon => addon.addonCode)
                );
              }
            }}
          />
          <div className="of-ring-settings__actions">
            <RingSettingsButtons
              dispatch={dispatch}
              subscription={subscription}
              onShowDialog={onShowDialog}
              editing={editing}
              onEdit={onEdit}
              onCancel={onCancel}
              saving={saving}
              onConfirm={methods.handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
