import { ContactStatus } from '../../generated/api/models.js';
import {
  TypeKeys,
  closeDialog,
  loadContactsFailed,
  loadContactsFulfilled,
  showDialog,
  upsertContactFailed,
  upsertContactFulfilled,
} from '../actions/index.js';
import { actionToActionState, getMdmIdHeaderFromUrlParams } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import {
  createContactPrivateMethod,
  getContactsPrivateMethod,
  updateContactPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { failedToCreateDuplicateContactMsg } from '../../common/i18n/index.js';
import { getConflictingErrors, getDuplicateContactDialogParams } from './authenticatedUserEpic.js';
import {
  getDuplicateContactInformationDialogParams,
  getPossibleDuplicateContactInformationDialogParams,
} from '../../common/utils/contactUtils.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { Contact, PutContactResponse } from '../../generated/api/models.js';
import type {
  ErrorAction,
  ErrorActionCreator,
  LoadContactsAction,
  SelfServiceActionTypes,
  UpsertContactAction,
  UpsertContactFulfilledAction,
} from '../actions/index.js';
import type { Observable } from 'rxjs';
import type { State } from '../common/store.js';

export function upsertContact<T>(
  contact: Contact,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>,
  failureAction: ErrorActionCreator<TypeKeys>,
  successAction: (response: PutContactResponse) => T,
  forceUpsert = false,
  bothNamesChanged = false
): Observable<T | ErrorAction<TypeKeys>> {
  if (contact.contactId) {
    // Update contact
    const { contactId, ...payload } = contact;
    return callUiApi({
      epicDependencies,
      failureAction,
      method: updateContactPrivateMethod(contactId, { forceUpsert, bothNamesChanged }),
      payload,
      state$,
      successAction: (response: AjaxResponse) =>
        successAction({
          ...contact,
          lastModified: response.response.lastModified,
        }),
      headers: getMdmIdHeaderFromUrlParams(),
    });
  } else {
    // Create new contact
    return callUiApi({
      epicDependencies,
      failureAction,
      method: createContactPrivateMethod({ forceUpsert }),
      payload: contact,
      state$,
      successAction: (response: AjaxResponse) =>
        successAction({
          ...contact,
          contactId: response.response.contactId,
          lastModified: response.response.lastModified,
        }),
      headers: getMdmIdHeaderFromUrlParams(),
    });
  }
}

export const loadContactsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_CONTACTS)), (action: LoadContactsAction) =>
    actionToActionState(action, state$, 'contacts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading contacts: query missing');
      }
      const query = actionAndState.state.query;
      const method = getContactsPrivateMethod({
        offset: query.offset,
        limit: query.limit,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadContactsFailed,
        method,
        state$,
        headers: getMdmIdHeaderFromUrlParams(),
        successAction: loadContactsFulfilled,
      });
    })
  );

export const upsertContactEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.UPSERT_CONTACT)), (action: UpsertContactAction) =>
    actionToActionState(action, state$, 'contacts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for upserting contact');
      }
      const upsertContactAction = actionAndState.action as UpsertContactAction;
      const forceUpsert = upsertContactAction.contact.contactStatus === ContactStatus.PASSIVE;

      const observable: Observable<UpsertContactFulfilledAction | ErrorAction<TypeKeys> | UpsertContactAction> =
        upsertContact(
          upsertContactAction.contact,
          epicDependencies,
          state$,
          upsertContactFailed,
          response => {
            return upsertContactFulfilled(
              response,
              upsertContactAction.contact,
              upsertContactAction.params,
              upsertContactAction.showNotification
            );
          },
          forceUpsert,
          upsertContactAction.bothNamesChanged
        );

      // Check if action failed and if it did because of duplicate, display dialog box with duplicate contact information
      return observable.pipe(
        concatMap(action => {
          if (
            action?.type === TypeKeys.UPSERT_CONTACT_FAILED &&
            action.errors?.[0].source?.error?.duplicateContact?.contactId
          ) {
            const dialogParams =
              action.errors?.[0].source?.error?.errorType === 'POSSIBLE_CONTACT_DUPLICATE'
                ? getPossibleDuplicateContactInformationDialogParams(action.errors[0].source.error.duplicateContact)
                : getDuplicateContactInformationDialogParams(action.errors[0].source.error.duplicateContact);
            return [
              closeDialog(),
              showDialog(dialogParams),
              upsertContactFailed(failedToCreateDuplicateContactMsg, 500),
            ];
          } else if (action?.type === TypeKeys.UPSERT_CONTACT_FAILED && getConflictingErrors(action.errors)) {
            return [
              closeDialog(),
              showDialog(getDuplicateContactDialogParams()),
              upsertContactFailed(failedToCreateDuplicateContactMsg, 500),
            ];
          } else {
            return [closeDialog(), action];
          }
        })
      );
    })
  );

export const contactEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadContactsEpic,
  upsertContactEpic
);
