import { BrowserApp } from './BrowserApp.js';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { mapEnvVarsToInitialState } from '../site/envVars.js';
import { onCLS, onINP, onLCP } from 'web-vitals/attribution';
import { pushToDataLayer } from '../../common/analytics.js';
import { randomIntFromInterval } from '../../common/utils/numberUtils';
import Cookies from 'js-cookie';
import type { MetricWithAttribution } from 'web-vitals/attribution';
import type { State } from '../../selfservice/exports.js';

/* eslint-disable */
declare global {
  interface Window {
    __SKIP_SSR__: boolean;
    __INITIAL_STATE__: State;
  }
}
let skipSsr = window['__SKIP_SSR__'];
let initialState = window['__INITIAL_STATE__'];
/* eslint-enable */

const PREVIEW_FEATURES_COOKIE_NAME = 'preview_features';
const PREVIEW_FEATURES_GROUP = 'preview_features_group';
if (process.env.NODE_ENV === 'development') {
  // This section does not end up in production release, so it's fine to use here development values
  skipSsr = true;
  const onlineUiEnvKeys: string[] = Object.keys(process.env).filter(envKey => envKey.startsWith('ONLINE_UI_'));
  const onlineUiEnvVars: [string, string][] = onlineUiEnvKeys.map(
    envKey => [envKey, process.env[envKey]! as string] as [string, string]
  );
  initialState = mapEnvVarsToInitialState(onlineUiEnvVars);
}

const pushReadyEventToDataLayer = (type: string) => {
  if (isInBrowser()) {
    pushToDataLayer({
      event: 'online-ui',
      eventAction: `${type}_FULFILLED`,
      eventCategory: 'react',
      eventNonInteraction: 1,
    });
  }
};

if (skipSsr) {
  // If server-side rendering is explicitly skipped, use createRoot and render
  createRoot(document.getElementById('root')!).render(
    <BrowserApp initialState={initialState} callback={() => pushReadyEventToDataLayer('RENDER')} />
  );
} else {
  // In production mode for most pages, use hydrateRoot for server-side rendering
  hydrateRoot(
    document.getElementById('root')!,
    <BrowserApp initialState={initialState} callback={() => pushReadyEventToDataLayer('HYDRATE')} />
  );
}

if (isInBrowser()) {
  // Initialize web vitals metrics
  const report = (webVitalsData: MetricWithAttribution) => {
    pushToDataLayer({
      webVitalsData: null,
    });
    pushToDataLayer({
      event: 'web_vitals',
      webVitalsData,
    });
  };
  onCLS(report);
  onINP(report);
  onLCP(report);
}

if (isInBrowser()) {
  const cookieValue = Cookies.get(PREVIEW_FEATURES_COOKIE_NAME);
  const prevFeatureGroupCookie = Cookies.get(PREVIEW_FEATURES_GROUP);
  const previewFeatureGroup = prevFeatureGroupCookie
    ? parseInt(prevFeatureGroupCookie, 10)
    : randomIntFromInterval(1, 100);
  const percentageOfUserForPreviewFeatures = (initialState as State).config.previewFeaturesEnabledPercentage;
  Cookies.set(PREVIEW_FEATURES_GROUP, previewFeatureGroup.toString(), { expires: 1 });

  if (cookieValue === undefined) {
    if (previewFeatureGroup <= percentageOfUserForPreviewFeatures) {
      Cookies.set(PREVIEW_FEATURES_COOKIE_NAME, JSON.stringify(['USE_SITE_BACKEND']), { expires: 1 });
    }
  } else {
    if (previewFeatureGroup > percentageOfUserForPreviewFeatures) {
      Cookies.remove(PREVIEW_FEATURES_COOKIE_NAME);
    }
  }
}
