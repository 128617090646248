import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  Table,
  TableDescription,
} from '../Table/index.js';
import { Highlight } from '../Highlight/Highlight.js';
import { Link, generatePath } from 'react-router-dom';
import { Status } from '../Status/Status.js';
import { StatusColor } from '../../common/enums.js';
import { SubscriptionAction } from '../../generated/api/subscriptionAction.js';
import {
  cancelledMsg,
  companyMsg,
  contentMsg,
  customerMsg,
  deliveredMsg,
  openMsg,
  orderDateMsg,
  orderMsg,
  pendingApprovalMsg,
  statusMsg,
  t,
  unknownMsg,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatDefinedTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { SubscriptionActionHeader } from '../../generated/api/subscriptionActionHeader.js';
import type { SubscriptionActionSearchResponse } from '../../generated/api/subscriptionActionSearchResponse.js';

export interface RedeemRequestListProps {
  redeemRequests: SubscriptionActionSearchResponse[];
  totalItems: number;
  authenticatedUser?: AuthenticatedUserState;
}
export const statusData = (status: SubscriptionActionHeader.StatusEnum): { text: string; color?: StatusColor } => {
  switch (status) {
    case SubscriptionAction.StatusEnum.COMPLETED:
      return { text: t.PQGG(deliveredMsg), color: StatusColor.GREEN };
    case SubscriptionAction.StatusEnum.PENDING:
      return { text: t.TJ54(openMsg), color: StatusColor.GREY };
    case SubscriptionAction.StatusEnum.PENDING_APPROVAL:
      return { text: t.ZXA6(pendingApprovalMsg), color: StatusColor.SALMON };
    case SubscriptionAction.StatusEnum.CANCELLED:
      return { text: t.PEKP(cancelledMsg), color: StatusColor.GREY };
    default:
      return { text: t.QWC8(unknownMsg), color: StatusColor.GREY };
  }
};

const generateSubscriptionActionUrl = (requestId: string, mdmId?: string) =>
  `${generatePath(paths.CUSTOMER_ORDER_REDEEM_REQUEST, {
    requestId: requestId,
  })}?mdmId=${mdmId}`;

export const RedeemRequestList = ({ redeemRequests, totalItems, authenticatedUser }: RedeemRequestListProps) => {
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;
  const searchTerms = search?.split(' ') || [];

  const columns = [
    {
      key: 'subscriptionDisplayId',
      label: t.C001(orderMsg),
      sortable: true,
      width: '15%',
    },
    {
      key: 'created',
      label: t.ZTPC(orderDateMsg),
      sortable: true,
      width: '10%',
    },
    {
      key: 'subscriptionContactName',
      label: t.ZC7D(customerMsg),
      sortable: true,
    },
    {
      key: 'subscriptionName',
      label: t.NBNH(contentMsg),
    },
    {
      key: 'status',
      label: t.ASQT(statusMsg),
      ref: 'status',
    },
    {
      key: 'company',
      label: t.KJTS(companyMsg),
      width: '15%',
    },
  ];

  const rows = redeemRequests.map(redeemRequest => {
    const status = statusData(redeemRequest.result.status);
    return {
      subscriptionDisplayId: (
        <Link
          to={generateSubscriptionActionUrl(
            redeemRequest.result.subscriptionActionDisplayId,
            redeemRequest.result.accountMasterId
          )}
        >
          <TableDescription
            description={t.B951('Redemption Request')}
            title={<Highlight text={redeemRequest.result.subscriptionDisplayId ?? ''} highlights={searchTerms} />}
          />
        </Link>
      ),
      created: formatDefinedTimestampToDDMMYYYY(redeemRequest.result.created),
      subscriptionContactName: (
        <Highlight text={redeemRequest.result.subscriptionContactName ?? ''} highlights={searchTerms} />
      ),
      subscriptionName: <Highlight text={redeemRequest.result.subscriptionName ?? ''} highlights={searchTerms} />,
      status: <Status textAfterBadge={status.text} color={status.color} inTable={true} />,
      company: getCompanyName(authenticatedUser, redeemRequest.result.accountMasterId),
    };
  });

  return (
    <div className="of-redeem-request-list">
      <div className={dsClass.MARGIN_TOP_4}>
        <ConsolidatedListView
          authenticatedUser={authenticatedUser}
          listElement={<Table columns={columns} noItemsText={t.SXJ5('No redeem requests')} rows={rows} />}
          listPagination={<ListPagination totalItems={totalItems} />}
          listSearch={
            <ListSearch
              onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
            />
          }
        />
      </div>
    </div>
  );
};
