import { ApproverContact, CostCenter, Reference } from '../../../common/formik/index.js';
import { CompanyInfoResponse } from '../../../generated/api/models.js';
import { DeviceCheckoutDeliveryDetails } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetails.js';
import { Form, Formik, useFormikContext } from 'formik';
import { Loading } from '../../Loading/index.js';
import { capitalize } from '../../../common/utils/stringUtils.js';
import { convertStringMapToCommonErrors } from '../../../common/utils/errorUtils.js';
import {
  costCenterAndReferenceVariableMsg,
  costCenterMsg,
  customerMsg,
  methodOfDeliveryMsg,
  notMandatoryMsg,
  productDeliveryMsg,
  referenceMsg,
  t,
} from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { getContactInfo } from '../../../common/utils/stateUtils.js';
import { hasEmployeePayables, showEppDeviceChangeStep } from '../../../common/utils/checkoutUtils.js';
import { processDeliveryDetails } from '../../../selfservice/actions/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import type { ActionsHistory, State } from '../../../selfservice/common/store.js';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { CheckoutStepItem } from '../CheckoutSteps.js';
import type { CommonError } from '../../../common/types/errors.js';
import type { Contact, ContactPerson } from '../../../generated/api/models.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { FormikErrors, FormikValues } from 'formik';
import type { SubmitOrderProps } from '../../../common/types/checkout.js';

export interface DeliveryDetailsStepContentProps {
  isEmployee: boolean;
  hideDeliveryMethodSelection?: boolean;
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  showDeliveryStep?: boolean;
  setShouldScrollToError: (value: boolean, errors?: CommonError[]) => void;
  contactPerson?: ContactPerson;
  setContactPerson: (contactPerson: ContactPerson) => void;
  contactPersonValidationErrors: { [s: string]: string };
  setContactPersonValidationErrors: (contactPersonValidationErrors: { [s: string]: string }) => void;
  approverContact?: string;
  setApproverContact: (approverContact: string) => void;
  approverContactValidationErrors: { [s: string]: string };
  setApproverContactValidationErrors: (approverContactValidationErrors: { [s: string]: string }) => void;
  shouldScrollToError: boolean;
  deviceChangeRequest?: DeviceChangeRequest;
  setDeliveryDetailsObj: (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  submitOrder: (submitOrderProps: SubmitOrderProps) => void;
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
}

export interface DeliveryDetailsFormValues extends ContactPerson {
  costCenter?: string;
  employeeNumber?: string;
  approverContact?: string;
  addressChecked: boolean;
}

const validateForm = (addressChecked: boolean, isEmployee: boolean) => {
  const errors: FormikErrors<FormikValues> = {};

  if (!addressChecked && isEmployee) {
    errors.addressChecked = t.WTWB('Please confirm that you have checked the address for correctness.');
  }

  return errors;
};

const CostCenterAndReferenceNumberDetails = ({
  costCenterMandatory,
  setContactPerson,
  setContactPersonValidationErrors,
  setRefCallback,
}: {
  costCenterMandatory: boolean;
  setContactPerson: (contactPerson: ContactPerson) => void;
  setContactPersonValidationErrors: (contactPersonValidationErrors: { [s: string]: string }) => void;
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
}) => {
  const formikContext = useFormikContext<DeliveryDetailsFormValues>();

  useEffect(() => {
    setContactPerson(formikContext.values as ContactPerson);
    setContactPersonValidationErrors(formikContext.errors);
  }, [formikContext.errors, formikContext.values, setContactPerson, setContactPersonValidationErrors]);

  return (
    <div className={`of-checkout__costCenterAndReferenceNumber ${dsClass.MARGIN_TOP_3}`}>
      <div
        id="costCenter"
        ref={(element: HTMLElement | null) => setRefCallback && setRefCallback('costCenter', element)}
      />
      <CostCenter
        name="costCenter"
        label={`${t.L2OG(costCenterMsg)}${!costCenterMandatory ? ' ' + t.NFRH(notMandatoryMsg) : ''}`}
        placeholder={t.L2OG(costCenterMsg)}
        required={costCenterMandatory}
        type="text"
      />
      <div
        id="employeeNumber"
        ref={(element: HTMLElement | null) => setRefCallback && setRefCallback('employeeNumber', element)}
      />
      <Reference label={`${t.DQHY(referenceMsg)} ${t.NFRH(notMandatoryMsg)}`} placeholder={t.DQHY(referenceMsg)} />
    </div>
  );
};

const EmployeeApproverContactSelection = ({
  deliveryDetailsStepContentProps,
  approverContacts,
}: {
  deliveryDetailsStepContentProps: DeliveryDetailsStepContentProps;
  approverContacts: Contact[];
}) => {
  const { authenticatedUser } = useAuth();
  const contacts = useSelector((state: State) => state.selfservice?.contacts?.items, deepEqual);
  const customerType = useSelector((state: State) => state.selfservice?.companyInfo?.customerType, deepEqual);
  const internalCustomer = customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;
  const {
    setApproverContact,
    setApproverContactValidationErrors,
    setContactPerson,
    setContactPersonValidationErrors,
    setRefCallback,
  } = deliveryDetailsStepContentProps;

  return (
    <div className="of-device-checkout--order-delivery-details">
      <div>
        <h4 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_BOTTOM_1}`}>{t.ZC7D(customerMsg)}</h4>
        <div>{authenticatedUser && `${authenticatedUser.firstName} ${authenticatedUser.lastName || ''}`}</div>
        <h4 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_BOTTOM_1} ${dsClass.PADDING_TOP_3}`}>
          {t.WH9D(costCenterMsg)}
        </h4>
        <div>{t.GUAE(costCenterAndReferenceVariableMsg, capitalize(t.ACH1('device')))}</div>

        <CostCenterAndReferenceNumberDetails
          costCenterMandatory={internalCustomer}
          setContactPerson={setContactPerson}
          setContactPersonValidationErrors={setContactPersonValidationErrors}
          setRefCallback={setRefCallback}
        />
      </div>
      {contacts && (
        <div className="of-device-checkout--approver-details">
          <h4 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_BOTTOM_1}`}>{t.KDIV('Order details')}</h4>
          <div
            id="approverContact"
            ref={(element: HTMLElement | null) => setRefCallback && setRefCallback('approverContact', element)}
          />
          <ApproverContact
            contacts={approverContacts}
            setApproverContact={setApproverContact}
            setApproverContactValidationErrors={setApproverContactValidationErrors}
          />
        </div>
      )}
    </div>
  );
};

const CheckoutDeliveryDetails = ({
  deliveryDetailsStepContentProps,
}: {
  deliveryDetailsStepContentProps: DeliveryDetailsStepContentProps;
}) => {
  const dispatch = useDispatch();
  const {
    isEmployee,
    deliveryDetails,
    hideDeliveryMethodSelection,
    showDeliveryStep,
    approverContact: approverContactObj,
    setShouldScrollToError,
    deviceChangeRequest,
    setDeliveryDetailsObj,
    submitOrder,
    contactPerson,
    contactPersonValidationErrors,
    setRefCallback,
  } = deliveryDetailsStepContentProps;
  const customerType = useSelector((state: State) => state.selfservice?.companyInfo?.customerType, deepEqual);
  const cartItems = useSelector((state: State) => state.deviceCheckout?.cartItems || [], deepEqual);
  const { authenticatedUser } = useAuth();
  const isEmployeePayable = hasEmployeePayables(cartItems, isEmployee, deviceChangeRequest);

  const formikContext = useFormikContext<DeliveryDetailsFormValues>();

  const onSaveDeliveryDetailsAndNext = (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => {
    formikContext.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        dispatch(
          processDeliveryDetails(
            updatedDeliveryDetails,
            Object.keys(contactPersonValidationErrors).length === 0
              ? undefined
              : convertStringMapToCommonErrors(contactPersonValidationErrors),
            customerType,
            contactPerson,
            approverContactObj,
            isEmployee,
            isEmployeePayable,
            true
          )
        );
      } else {
        formikContext.setErrors(errors);
        Object.keys(errors).forEach(field => formikContext.setFieldTouched(field, true));
        setShouldScrollToError(true, convertStringMapToCommonErrors(errors));
      }
    });
  };

  const onSaveDeliveryDetailsToState = (
    updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType,
    validationErrors?: CommonError[]
  ) => {
    dispatch(processDeliveryDetails(updatedDeliveryDetails, validationErrors));
    setDeliveryDetailsObj(updatedDeliveryDetails);
  };

  const onSubmitOrder =
    isEmployee && !isEmployeePayable
      ? (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => {
          formikContext.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
              submitOrder({
                updatedDeliveryDetails,
              });
            } else {
              formikContext.setErrors(errors);
            }
          });
        }
      : undefined;

  return (
    <DeviceCheckoutDeliveryDetails
      deliveryDetails={deliveryDetails}
      skipDeliveryMethods={hideDeliveryMethodSelection}
      deliveryAddressNeeded={showDeliveryStep}
      saveDeliveryDetailsAndNext={onSaveDeliveryDetailsAndNext}
      saveDeliveryDetailsToState={onSaveDeliveryDetailsToState}
      isEmployee={isEmployee}
      showConfirmAddress={isEmployee}
      isDeviceChangeReturn={deviceChangeRequest?.deviceChangeOption === 'RETURN'}
      footerNote={
        showEppDeviceChangeStep(cartItems, authenticatedUser)
          ? t.BHMU('On weekdays orders done before noon will mainly get shipped during next weekday delivery.')
          : undefined
      }
      onSubmitOrder={onSubmitOrder}
      setRefCallback={setRefCallback}
    />
  );
};

const initialFormValues = (user?: AuthenticatedUserState & ActionsHistory): DeliveryDetailsFormValues => {
  if (user) {
    const contactInfo: ContactPerson = getContactInfo(user);
    return {
      ...contactInfo,
      costCenter: contactInfo.costCenter || '',
      employeeNumber: contactInfo.employeeNumber || '',
      approverContact: '',
      addressChecked: false,
    };
  }
  // User may be empty only when !props.isEmployee and then these are not rendered.
  return { email: '', firstName: '', lastName: '', phoneNumber: '', addressChecked: false };
};

export const DeliveryDetailsStepContent = (props: DeliveryDetailsStepContentProps) => {
  const { authenticatedUser } = useAuth();
  const contacts = useSelector((state: State) => state.selfservice?.contacts?.items, deepEqual);

  const filteredContacts = useMemo(
    () => (contacts || []).filter(contact => contact.contactId !== authenticatedUser?.contact?.contactId),
    [contacts, authenticatedUser]
  );

  return authenticatedUser?.isFetching || (props.isEmployee && !filteredContacts) ? (
    <Loading />
  ) : (
    <div>
      <Formik
        validate={deliveryDetailsFormValues => validateForm(deliveryDetailsFormValues.addressChecked, props.isEmployee)}
        initialValues={initialFormValues(authenticatedUser)}
        onSubmit={() => {}}
      >
        <Form noValidate>
          {props.isEmployee && contacts && (
            <EmployeeApproverContactSelection
              deliveryDetailsStepContentProps={props}
              approverContacts={filteredContacts}
            />
          )}
          <CheckoutDeliveryDetails deliveryDetailsStepContentProps={props} />
        </Form>
      </Formik>
    </div>
  );
};

export const deliveryDetailsStep = (props: DeliveryDetailsStepContentProps): CheckoutStepItem => ({
  id: 'deliveryDetailsStep',
  content: <DeliveryDetailsStepContent {...props} />,
  name: props.hideDeliveryMethodSelection ? t.QUPI(productDeliveryMsg) : t.G0QN(methodOfDeliveryMsg),
});
