import { AuthenticationLevel, type SubscriptionHeader, SubscriptionType } from '../../generated/api/models.js';
import { CATEGORY_URL } from '../../common/utils/categoryUtils';
import { Link } from 'react-router-dom';
import { SubscriptionIcon } from './SubscriptionIcon.js';
import { getCategoryBySubscriptionType } from '../../public/common/util/category';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';

interface SubscriptionLinkProps {
  subscription: SubscriptionHeader;
  search?: string;
}

const createSubscriptionPath = (subscriptionId: string, authenticatedUser?: RealAuthenticatedUserState) => {
  const mfaEnabled = authenticatedUser?.authenticationLevel === AuthenticationLevel.TWO_FACTOR;
  const baseCategoryUrl = `${paths.PS_HOME}/${CATEGORY_URL[getCategoryBySubscriptionType(SubscriptionType.DOMAIN)]}`;
  return mfaEnabled ? `${baseCategoryUrl}/${subscriptionId}` : baseCategoryUrl;
};

export const DomainSubscriptionLink = ({ subscription, search }: SubscriptionLinkProps) => {
  const { authenticatedUser } = useAuth();
  const effectivePath = createSubscriptionPath(subscription.subscriptionId, authenticatedUser);

  return (
    <Link to={effectivePath} className="of-subscription-table__text-column">
      <SubscriptionIcon subscription={subscription} />
      {highlightKeyword(subscription.subscriptionName || '', search)}
    </Link>
  );
};
