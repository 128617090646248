import { Checkbox } from '../../common/formik/Fields/Checkbox';
import { Icon } from '../Icon/Icon';
import { addressConfirmationMsg, t } from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import { useField, useFormikContext } from 'formik';
import type { DeliveryDetailsFormValues } from '../Checkout/steps/DeliveryDetailsStep';

export const DeviceCheckoutAddressConfirmation = ({
  setRefCallback, // required for scrolling to error
}: {
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
}) => {
  const { values } = useFormikContext<DeliveryDetailsFormValues>();

  const [field, meta] = useField('addressChecked');

  return (
    <div
      id="addressChecked"
      ref={(element: HTMLElement | null) => {
        setRefCallback && setRefCallback('addressChecked', element);
      }}
    >
      <Checkbox
        name="addressChecked"
        label={t.T9V1(addressConfirmationMsg)}
        checked={values?.addressChecked || false}
        field={field}
      />
      {meta.touched && meta.error && (
        <div className={dsClass.INPUTERROR} style={{ marginLeft: '4px' }}>
          <Icon icon="error" size="s" type="regular" className={dsClass.INPUTERROR} color="red-600" /> {meta.error}
        </div>
      )}
    </div>
  );
};
