import { Checkbox } from '../../../common/react-hook-form/components/Checkbox.js';
import { CompanySearch } from '../../../common/react-hook-form/fields/CompanySearch.js';
import { CustomFormErrorAndButton } from '../CustomFormFields.js';
import { Dropdown } from '../../../common/react-hook-form/components/Dropdown.js';
import { Email } from '../../../common/react-hook-form/fields/Email.js';
import {
  FORM_GRID_FULL_WIDTH,
  FormGridFieldset,
} from '../../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { Lead } from '../../../generated/api/models.js';
import { Message } from '../../../common/react-hook-form/fields/Message.js';
import { Name } from '../../../common/react-hook-form/fields/Name.js';
import { PhoneNumber } from '../../../common/react-hook-form/fields/PhoneNumber.js';
import {
  additionalInformationMsg,
  contactDetailsMsg,
  customerServiceSolutionsMsg,
  devicesMsg,
  firstNameMsg,
  internetSubscriptionsMsg,
  itServicesMsg,
  lastNameMsg,
  marketingConsentMsg,
  mobileSubscriptionsMsg,
  networkAndInformationSecurityServicesMsg,
  otherMsg,
  professionalServicesOrTrainingMsg,
  subjectMsg,
  submitMsg,
  t,
  workEmailMsg,
  workPhoneNumberMsg,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses';
import { paths } from '../../../common/constants/pathVariables.js';
import classNames from 'classnames';
import type { CustomFormProps } from '.././CustomForm.js';

import './LeadForm.scss';

const InterestArea = () => (
  <Dropdown
    className={FORM_GRID_FULL_WIDTH}
    items={[
      {
        label: t.AR0B(mobileSubscriptionsMsg),
        value: Lead.InterestAreaEnum.MOBILE_SUBSCRIPTIONS,
      },
      {
        label: t.SEYV(internetSubscriptionsMsg),
        value: Lead.InterestAreaEnum.INTERNET_SUBSCRIPTIONS,
      },
      {
        label: t.JJTM(devicesMsg),
        value: Lead.InterestAreaEnum.DEVICES,
      },
      {
        label: t.JJTN(networkAndInformationSecurityServicesMsg),
        value: Lead.InterestAreaEnum.NETWORK_AND_INFORMATION_SECURITY_SERVICES,
      },
      {
        label: t.JJTO(itServicesMsg),
        value: Lead.InterestAreaEnum.IT_SERVICES,
      },
      {
        label: t.JJTP(customerServiceSolutionsMsg),
        value: Lead.InterestAreaEnum.CUSTOMER_SERVICE_SOLUTIONS,
      },
      {
        label: t.JJTQ(professionalServicesOrTrainingMsg),
        value: Lead.InterestAreaEnum.PROFESSIONAL_SERVICES_TRAINING,
      },
      {
        label: t.JJTR(otherMsg),
        value: Lead.InterestAreaEnum.OTHER,
      },
    ]}
    label={t.JJTS(subjectMsg)}
    name="interestArea"
  />
);

export const LeadForm = ({ campaignId, leadFailed, leadInProgress, title }: CustomFormProps) => (
  <div className="of-lead-form">
    {title && <h2>{title}</h2>}
    <FormGridFieldset>
      {!campaignId && <InterestArea />}
      <Message className={FORM_GRID_FULL_WIDTH} label={t.GZUK(additionalInformationMsg)} placeholder="" rows={5} />
    </FormGridFieldset>
    <FormGridFieldset className="ds-margin-top--3" legend={t.FV55(contactDetailsMsg)}>
      <div className={FORM_GRID_FULL_WIDTH}>
        <CompanySearch placeholder="" />
      </div>
      <Name label={t.AIK7(firstNameMsg)} name="firstName" placeholder="" />
      <Name label={t.Y8OY(lastNameMsg)} name="lastName" placeholder="" />
      <Email label={t.ZVQ6(workEmailMsg)} placeholder="" />
      <PhoneNumber label={t.AGIK(workPhoneNumberMsg)} placeholder="" />
      <Checkbox className={FORM_GRID_FULL_WIDTH} label={t.GZUL(marketingConsentMsg)} name="consentForMarketing" />
      <p className={classNames(FORM_GRID_FULL_WIDTH, dsClass.TEXT_XS, dsClass.MARGIN_VERTICAL_4)}>
        {t.GZUM('For more information on communication and marketing, see ')}
        <a href={paths.ELISA_PRIVACY_POLICY} rel="noreferrer" target="_blank">
          {t.GZUN('Elisa’s privacy policy')}
        </a>
        {t.GZUO(
          '. You can change your choices or prohibit marketing in OmaElisa or by contacting our customer service.'
        )}
      </p>
    </FormGridFieldset>
    <CustomFormErrorAndButton buttonLabel={t.FSWU(submitMsg)} leadFailed={leadFailed} leadInProgress={leadInProgress} />
  </div>
);
