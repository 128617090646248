import { Picture } from '../Picture/Picture.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';

import './YjaOfferBlock.scss';

// We don't have translations for any of this, so we just mark it up as finnish.
export const YjaOfferBlock = () => (
  <a className="of-yja-offer-block" href={paths.YRITTAJAJASENEN_EDUT} lang="fi-FI">
    <Picture
      alt="Elisa - Yrittäjän kotimainen kumppani jo 25 vuotta. Valitse rinnallesi kotimainen Elisa ja hyödynnä yrittäjän edut. Lue lisää."
      offerWidthAlternatives={[320, 480, 640, 960, 980]}
      renderedImageSize={{
        onPhone: 'min(100vw, 980px)',
        onLaptop: '980px',
      }}
      src={`${pictureStorageBaseUrl}17A2DzKQmJV8z5gypSztZi/i.jpg`}
    />
  </a>
);
