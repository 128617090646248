import { BreadCrumbsWithTitle } from '../../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType } from '../../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { OrderSubscriptionSelectionHeader } from './OrderSubscriptionSelectionHeader.js';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../../common/i18n/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import type {
  OrderMobileBBSubLoaderData,
  OrderSubscriptionCommonLoaderData,
} from '../../../../../../common/loaders.js';

import './OrderSelectionPath.scss';

export const OrderBroadbandSubSelectionPath = () => {
  const { companyInfo } = useRouteLoaderData('newMobileBBCommon') as OrderSubscriptionCommonLoaderData;
  const { addOnRules, mobileBroadbandModel, elisaNettiModel } = useLoaderData() as OrderMobileBBSubLoaderData;

  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };

  return (
    <>
      <OrderSubscriptionSelectionHeader
        breadCrumbs={
          <BreadCrumbsWithTitle
            breadCrumbPaths={[
              { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
              { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
              { name: t.O222('New mobile broadband subscription') },
            ]}
          />
        }
      />
      <OrderSubscriptionSelection
        addOnRules={addOnRules}
        onlineModelType={ModelType.MobileBroadband}
        onlineModels={[mobileBroadbandModel, elisaNettiModel]}
        toggles={uiOptions?.toggles}
      />
    </>
  );
};
