import { type AvailabilityAddress, type BillingAccountData, type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OF, any, has, not } from '../OpenForm.js';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../../generated/api/oFQuestionType.js';
import { type OpenForm } from './useOpenForm.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  contactInfoMsg,
  deliveryMethodMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  emailInvoiceMsg,
  invoiceLanguageMsg,
  loadingProductInformationMsg,
  payerBusinessIdMsg,
  payerDetailsMsg,
  payerNameMsg,
  referenceMsg,
  t,
} from '../../../common/i18n/index.js';
import { getAddressText, getValue } from '../OpenFormUtils.js';
import { parseCurrencyToNumber } from '../../../common/utils/priceUtils.js';
import { useEffect, useMemo, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { OpenFormCustomerNeedAdditionalInformation } from '../../../generated/api/openFormCustomerNeedAdditionalInformation.js';
import type { OpenFormCustomerNeedRequest } from '../../../generated/api/openFormCustomerNeedRequest.js';
import type { OpenFormProductSummary } from '../../../generated/api/openFormProductSummary.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';
import type { OpenFormSummaryResponse } from '../../../generated/api/openFormSummaryResponse.js';
import type { ParameterizedSearchContactResult } from '../../../generated/api/parameterizedSearchContactResult.js';

export const NEW_BILLING_ACCOUNT = '#' as const;

export class OpenFormSummary {
  readonly products: OpenFormProductSummary[];
  readonly services: OpenFormProductSummary[];
  readonly #form: OpenForm;
  readonly #answers: OpenFormAnswers;
  readonly #check: OpenFormSection[];
  readonly #order: OpenFormQuestion[];

  constructor({ products }: OpenFormSummaryResponse, form: OpenForm, answers: OpenFormAnswers) {
    this.#form = form;
    this.#answers = answers;
    this.products = products.flatMap(this.#openFormProductSummary(OFPageType.QUESTION_MAIN_PRODUCTS));
    this.services = products.flatMap(this.#openFormProductSummary(OFPageType.QUESTION_ADDITIONAL_SERVICES));
    this.#check = this.#form.sections.filter(has(OFPageType.AVAILABILITY_CHECK));
    this.#order = this.#form.sections.filter(has(OFPageType.ORDER_ENRICHMENT)).flatMap(s => s.questions);
  }

  get additionalInformation(): OpenFormCustomerNeedAdditionalInformation[] {
    return this.#form.sections
      .flatMap(s =>
        s.questions.flatMap((q: OpenFormQuestion): OpenFormCustomerNeedAdditionalInformation | [] => {
          if (!q.fieldApiName || any(OF.Billing_Account__c, OF.Installation_Address__c, OF.Product_line__c)(q)) {
            return [];
          }
          switch (q.type) {
            case 'CONTRACT_PERIOD':
              return {
                fieldLabel: q.fieldApiName,
                fieldValue: getValue(this.#form.linkedValues(q, this.#answers.get(q)?.choices)),
              };
            default:
              return {
                fieldLabel: q.fieldApiName,
                fieldValue: getValue(this.#answers.get(q)?.choices),
              };
          }
        })
      )
      .concat(this.additionalInformationBillingAccountData)
      .concat(this.additionalInformationBillingAccount)
      .concat(this.additionalInformationInstallationAddress)
      .concat(this.additionalInformationProductLine);
  }

  get additionalInformationBillingAccountData(): OpenFormCustomerNeedAdditionalInformation | [] {
    const data = this.billingAccountData;
    if (!data) {
      return [];
    }
    return {
      fieldLabel: OF.Billing_Account_Data__c,
      fieldValue: JSON.stringify(
        Object.keys(data).flatMap((key: keyof BillingAccountData) => {
          switch (key) {
            case 'billElectronicAddress':
              return { key, value: data[key], displayValue: data[key], label: eInvoicingAddressMsg };
            case 'billElectronicOperator':
              return {
                key,
                value: data[key],
                displayValue: data.billElectronicOperatorLabel,
                label: eInvoicingOperatorMsg,
              };
            case 'billLanguage':
              return { key, value: data[key], displayValue: data[key], label: invoiceLanguageMsg };
            case 'billReceiverEmail':
              return { key, value: data[key], displayValue: data[key], label: emailInvoiceMsg };
            case 'billingAccountExtensionName':
              return {
                key,
                value: data[key],
                displayValue: data.billingAccountExtensionName,
                label: billingAccountExtensionNameMsg,
              };
            case 'billingAccountName':
              return { key, value: data[key], displayValue: data[key], label: billingAccountNameMsg };
            case 'billingContactId':
              return { key, value: data[key], displayValue: data.billingContactName, label: contactInfoMsg };
            case 'customerReference1':
              return { key, value: data[key], displayValue: data[key], label: `${referenceMsg} 1` };
            case 'customerReference2':
              return { key, value: data[key], displayValue: data[key], label: `${referenceMsg} 2` };
            case 'deliveryMethod':
              return { key, value: data[key], displayValue: data[key], label: deliveryMethodMsg };
            case 'payerAddress':
              return {
                key,
                value: JSON.stringify(data[key]),
                displayValue: getAddressText(data[key]),
                label: 'Payer address',
              };
            case 'payerBusinessId':
              return { key, value: data[key], displayValue: data[key], label: payerBusinessIdMsg };
            case 'payerName':
              return { key, value: data[key], displayValue: data[key], label: payerNameMsg };
            case 'payerNameExtension':
              return { key, value: data[key], displayValue: data[key], label: payerDetailsMsg };
            default:
              return [];
          }
        })
      ),
    };
  }

  get additionalInformationBillingAccount(): OpenFormCustomerNeedAdditionalInformation {
    return {
      fieldLabel: OF.Billing_Account__c,
      fieldValue: this.billingAccount?.billingAccountId,
    };
  }

  get additionalInformationInstallationAddress(): OpenFormCustomerNeedAdditionalInformation {
    return {
      fieldLabel: OF.Installation_Address__c,
      fieldValue: getAddressText(this.installationAddress),
    };
  }

  get additionalInformationProductLine(): OpenFormCustomerNeedAdditionalInformation {
    return {
      fieldLabel: OF.Product_line__c,
      fieldValue: this.#answers.valuesOf('subscriptionTypes').flat().join(','),
    };
  }

  get billingAccount(): BillingAccount | undefined {
    const context = this.#answers.get(this.#order.find(has(OF.Billing_Account__c)));
    return getValue(context?.choices) !== NEW_BILLING_ACCOUNT ? context?.billingAccount : undefined;
  }

  get billingAccountData(): BillingAccountData | undefined {
    const context = this.#answers.get(this.#order.find(has(OF.Billing_Account__c)));
    return getValue(context?.choices) === NEW_BILLING_ACCOUNT ? context?.billingAccountData : undefined;
  }

  get contactEntries(): [OpenFormQuestion, ParameterizedSearchContactResult][] {
    return this.#answers.entriesOf('contact').flatMap(([guid, contact]) => {
      const item = this.#order.find(has(guid));
      return item ? [[item, contact]] : [];
    });
  }

  get deliveryDate(): string | undefined {
    return getValue(this.#answers.get(this.#order.find(has(OFQuestionType.DATE_OF_DELIVERY)))?.choices);
  }

  get deliveryTime(): string | undefined {
    return getValue(this.#answers.get(this.#order.find(has(OFQuestionType.TIME_OF_DELIVERY)))?.choices);
  }

  get installationAddress(): AvailabilityAddress | undefined {
    return this.#answers
      .entriesOf('address')
      .find(([guid]) => this.#order.find(has(OF.Installation_Address__c, guid)) || this.#check.some(has(guid)))?.[1];
  }

  get openFormCustomerNeedRequest(): OpenFormCustomerNeedRequest | undefined {
    const main = this.products.find(p => p.productCode);
    if (!main) {
      return undefined;
    }
    return {
      formId: this.#form.formId,
      questions: this.#form.questions.flatMap(q => (this.#answers.visible(q) && this.#answers.getAnswers(q)) || []),
      rootProducts: [
        {
          product: this.#openFormCustomerNeedProduct(main),
          childProducts: this.services.map(this.#openFormCustomerNeedProduct),
          additionalInformation: this.additionalInformation,
        },
      ],
    };
  }

  #openFormCustomerNeedProduct({ productCode, oneOffCharge, recurringCharge }: OpenFormProductSummary) {
    return {
      productCode: productCode,
      oneOffCharge: String(oneOffCharge),
      recurringCharge: String(recurringCharge),
    };
  }

  #openFormProductSummary(pageType: OFPageType) {
    const selected = this.#form.sections.filter(has(pageType)).flatMap(s => s.questions.flatMap(q => q.choices));
    return ({ choice, oneOffCharge, recurringCharge, ...props }: OpenFormProductSummary) => {
      if (!choice || selected.every(not(choice))) {
        return [];
      }
      const row = this.#answers.find(({ choices }) => choices?.includes(choice))?.row?.[choice];
      return {
        ...props,
        choice: choice,
        oneOffCharge: parseCurrencyToNumber(row?.[OF.one_time_charge__c]) ?? oneOffCharge,
        recurringCharge: parseCurrencyToNumber(row?.[OF.recurring_charge__c]) ?? recurringCharge,
      };
    };
  }
}

export const useOpenFormSummary = (form: OpenForm, answers: OpenFormAnswers) => {
  const [data, setData] = useState<OpenFormSummary | undefined>(undefined);
  const async = useOpenFormAsync();
  const choices = useMemo(
    () =>
      form.sections
        .filter(any(OFPageType.QUESTION_MAIN_PRODUCTS, OFPageType.QUESTION_ADDITIONAL_SERVICES))
        .flatMap(s => s.questions.filter(not(OFQuestionType.FREE_TEXT)))
        .flatMap(q => answers.get(q)?.choices || [])
        .join(','),
    [form, answers]
  );

  useEffect(() => {
    const { ctrl, evict } = async
      .fetchOpenFormSummary({ choices: choices.split(',') })
      .settled(response => setData(new OpenFormSummary(response, form, answers)))
      .failed(text => async.actions.notification({ text, type: 'warning' }))
      .cache(choices)
      .execute(t.VBS1(loadingProductInformationMsg));

    return () => {
      ctrl.abort();
      evict();
    };
  }, [form, answers, async, choices]);

  return data;
};
