import { ADDON_ANALYTICS_ITEM_CATEGORY } from '../SubscriptionAdditionalService/addOnAnalyticsUtils.js';
import { AnalyticsItemCategory, CURRENCY_CODE_EUR, EcommerceEventTypeV4 } from '../../common/analytics.js';
import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { DEFAULT_ITEM_BRAND, DEFAULT_PAYMENT_TYPE } from '../../selfservice/common/googleEcommerceEvent.js';
import { MobileM2MAddonCode } from '../../common/enums';
import { VAT_AS_DECIMAL } from '../../common/utils/taxUtils.js';
import { centsToEuros } from '../../common/utils/priceUtils.js';
import { getItemVariant } from '../../common/utils/analyticsUtils.js';
import { getLaitenettiCardContent } from './content/LaitenettiSubscriptionCardContent';
import { getLaitenettiMonthlyPriceFromCommercialProduct } from '../../common/utils/commercialProductUtils';
import { getMobileBroadbandSubscriptionCardContents } from './content/MobileBroadbandSubscriptionCardContent';
import { getVoiceSubscriptionCardContents } from './content/VoiceSubscriptionCardContent';
import { isFiveGPlusOffer, isLaitenettiOffer, isSalesProduct } from '../../common/utils/subscriptionUtils';
import type { AddOn } from '../../generated/api/addOn';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type {
  EcommerceAddToCartEventV4,
  EcommerceBeginCheckoutEventV4,
  EcommerceItem,
  EcommercePurchaseEventV4,
} from '../../common/analytics.js';
import type { MobileBroadbandSubCardProps, SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard';
import type { Offer } from '../../generated/api/offer';

// Max bundle ID length in analytics backend
const BUNDLE_ID_MAX_LENGTH = 500;

export enum AnalyticsListNames {
  VOICE_LIST_NAME = 'OmaElisa Yrityksille puhelinliittymät',
  MBB_LIST_NAME = 'OmaElisa Yrityksille laajakaistaliittymät',
  NETTI_LITE_LIST_NAME = 'Nettilite saatavuus',
  LAITE_NETTI_LIST_NAME = 'OmaElisa Yrityksille laitenettiliittymät',
}

// We have rather limited information about the products available at purchase stage,
//  so we have to use the product name to decide whether it's a sub or not.
const subscriptionProductCategories = [
  AnalyticsItemCategory.YRITYS_DATA_LIITTYMA,
  AnalyticsItemCategory.YRITYS_LIITTYMA,
  AnalyticsItemCategory.LAITE_NETTI,
];

export const isSubscriptionProduct = (productName: string) =>
  subscriptionProductCategories.some(name => productName.includes(name.toString()));

const getBundleId = (commercialProductCode: string, addedAddOns?: AddedAddon[]): string | undefined => {
  // Only assign a bundle ID to orders with more than one unique item (= has to have addons)
  if (!addedAddOns || addedAddOns.length === 0) {
    return undefined;
  }
  if (addedAddOns && addedAddOns.length > 0) {
    const addOnString = addedAddOns?.map(addOn => addOn.addOnCode).toString();
    // If there is huge pile of addons included, the bundle ID will be truncated to max length 500 characters
    return (commercialProductCode + addOnString).substring(0, BUNDLE_ID_MAX_LENGTH);
  }
  return commercialProductCode;
};

const getCommercialProductYearlyPrice = (
  commercialProducts?: CommercialProduct[],
  campaignAssociation?: CampaignAssociation,
  isLaitenetti?: boolean
) => {
  const totalPrice = commercialProducts?.reduce((total, commercialProduct) => {
    const monthlyRecurringCharge = isLaitenetti
      ? getLaitenettiMonthlyPriceFromCommercialProduct(commercialProduct)
      : (commercialProduct?.monthlyRecurringCharge ?? 0);
    const monthlyDiscount = campaignAssociation?.monthlyRecurringCharge ?? 0;

    // Calculate the net monthly charge after applying the campaign discount
    const netMonthlyCharge = monthlyRecurringCharge - monthlyDiscount;

    // Calculate the total yearly charge before quantity and one-time charges
    const yearlyRecurringCharge = netMonthlyCharge * 12;

    // Calculate the net one-time charge after applying the campaign discount
    const oneTimeCharge = commercialProduct?.oneTimeCharge ?? 0;
    const oneTimeDiscount = campaignAssociation?.oneTimeCharge ?? 0;
    const netOneTimeCharge = oneTimeCharge - oneTimeDiscount;

    // Calculate the total price including the one-time charge
    return total + yearlyRecurringCharge + netOneTimeCharge;
  }, 0);

  return centsToEuros(totalPrice);
};

const getAddOnYearlyPrice = (addOn: AddedAddon) => {
  const monthlyRecurringCharge = addOn?.monthlyPrice || 0;
  const oneTimeCharge = addOn?.oneTimePrice || 0;

  // Calculate the total yearly charge before quantity and one-time charges
  const yearlyRecurringCharge = monthlyRecurringCharge * 12;

  // Calculate the total price including the one-time charge and quantity
  const totalPrice = yearlyRecurringCharge + oneTimeCharge;

  return centsToEuros(totalPrice);
};

const getCumulativePrice = (eCommerceItems: EcommerceItem[]) =>
  eCommerceItems.reduce((total, item) => total + (item.price ?? 0) * (item.quantity ?? 1), 0);

const getSalesProductItemCategory = (onlineModelName: string): AnalyticsItemCategory => {
  if (onlineModelName.includes(AnalyticsItemCategory.YRITYS_DATA_LIITTYMA)) {
    return AnalyticsItemCategory.YRITYS_DATA_LIITTYMA;
  }
  if (onlineModelName.includes(AnalyticsItemCategory.YRITYS_LIITTYMA)) {
    return AnalyticsItemCategory.YRITYS_LIITTYMA;
  }
  if (onlineModelName.includes(AnalyticsItemCategory.LAITE_NETTI)) {
    return AnalyticsItemCategory.LAITE_NETTI;
  }
  return AnalyticsItemCategory.UNKNOWN_CATEGORY;
};

const getItemCategory = (
  onlineModelName: string,
  productType?: CommercialProductType,
  productSubType?: CommercialProductSubType,
  itemListName?: string
): AnalyticsItemCategory => {
  if (productType === CommercialProductType.SALES_PRODUCT) {
    return getSalesProductItemCategory(onlineModelName);
  }

  if (onlineModelName.includes(AnalyticsItemCategory.LAITE_NETTI)) {
    return AnalyticsItemCategory.LAITE_NETTI;
  }
  if (itemListName === AnalyticsListNames.NETTI_LITE_LIST_NAME) {
    return AnalyticsItemCategory.FIXED_BROADBAND;
  }

  switch (productSubType) {
    case CommercialProductSubType.MOBILE_VOICE:
      return AnalyticsItemCategory.YRITYS_LIITTYMA;
    case CommercialProductSubType.MOBILE_BROADBAND:
      return AnalyticsItemCategory.YRITYS_DATA_LIITTYMA;
    default:
      return AnalyticsItemCategory.UNKNOWN_CATEGORY;
  }
};

// Laitenetti card is a special case, the offer is same in all cards and the selectedAddon is used to determine the card
const getLaitenettiCard = (addons?: AddOn[]): SubscriptionCardProps | undefined => {
  const laiteNettiCards = getLaitenettiCardContent(false);

  // Find the first card that has a selectedAddon matching any addon code in the addons array
  return laiteNettiCards.find(card => addons?.some(addon => card.selectedAddon === addon.addOnCode));
};

const getNonLaitenettiCard = (offerCode: string) => {
  const voiceCards = getVoiceSubscriptionCardContents(false);
  const mbbCards = getMobileBroadbandSubscriptionCardContents(false);

  return [...voiceCards, ...mbbCards].find(card => card.selectedOffer === offerCode);
};

const isSubscriptionCardProps = (
  card: SubscriptionCardProps | MobileBroadbandSubCardProps
): card is SubscriptionCardProps => 'offers' in card;

const isMobileBroadbandSubCardProps = (
  card: SubscriptionCardProps | MobileBroadbandSubCardProps
): card is MobileBroadbandSubCardProps => 'salesProduct' in card;

const getPublicPageCard = (
  offerCode: string,
  analyticsItemCategory?: AnalyticsItemCategory
): SubscriptionCardProps | MobileBroadbandSubCardProps | undefined => {
  const voiceCards = getVoiceSubscriptionCardContents(true);
  const mbbCards = getMobileBroadbandSubscriptionCardContents(true);
  const laiteNettiCards = getLaitenettiCardContent(false);

  return [...voiceCards, ...mbbCards, ...laiteNettiCards].find(card => {
    if (analyticsItemCategory === AnalyticsItemCategory.YRITYS_LIITTYMA) {
      return (
        isSubscriptionCardProps(card) && card.offers?.some(offer => offer.salesProduct.newNumberOfferCode === offerCode)
      );
    }
    return isMobileBroadbandSubCardProps(card) && card.salesProduct.offerCode === offerCode;
  });
};

const getSubscriptionCardByOffer = (
  offer: Offer,
  commercialProduct?: CommercialProduct,
  analyticsItemCategory?: AnalyticsItemCategory
): SubscriptionCardProps | MobileBroadbandSubCardProps | undefined => {
  const { offerCode } = offer;

  if (isSalesProduct(commercialProduct)) {
    return getPublicPageCard(offerCode, analyticsItemCategory);
  }

  return isLaitenettiOffer(offer)
    ? getLaitenettiCard(commercialProduct?.associatedAddOns)
    : getNonLaitenettiCard(offerCode);
};

const getProductItemVariant = (
  itemCategory: AnalyticsItemCategory,
  offer: Offer,
  subscriptionCard?: SubscriptionCardProps | MobileBroadbandSubCardProps,
  selectedCommercialProduct?: ConfiguredCommercialProduct[]
): string => {
  const fiveGPlusOffer = isFiveGPlusOffer(offer.offerCode) ? '5G+ ' : '';
  if (subscriptionCard) {
    return `Elisa ${itemCategory} ${fiveGPlusOffer}${subscriptionCard.mainHeader}`;
  }
  const cpName =
    offer.commercialProducts[0]?.commercialProductName ??
    selectedCommercialProduct?.[0].commercialProduct.commercialProductName ??
    '';
  return `Elisa ${itemCategory} ${fiveGPlusOffer}${cpName}`;
};

export const getEcommerceItemFromOffer = (
  onlineModelName: string,
  offer: Offer,
  quantity: number,
  index?: number,
  coupon?: string,
  campaignAssociation?: CampaignAssociation,
  itemListName?: string,
  selectedCommercialProducts?: ConfiguredCommercialProduct[]
): EcommerceItem => {
  // Offer coming from /kassa might not include commercial products
  const commercialProduct = offer.commercialProducts[0] ?? selectedCommercialProducts?.[0]?.commercialProduct;
  const itemCategory = getItemCategory(
    onlineModelName,
    commercialProduct.productType,
    commercialProduct.productSubType,
    itemListName
  );
  const subscriptionCard = getSubscriptionCardByOffer(offer, commercialProduct, itemCategory);

  const commercialProducts = offer.commercialProducts.length
    ? offer.commercialProducts
    : selectedCommercialProducts?.map(cp => cp.commercialProduct);
  /* eslint-disable @typescript-eslint/naming-convention */
  return {
    coupon,
    ...(index != null ? { index } : {}),
    item_brand: DEFAULT_ITEM_BRAND,
    item_category: itemCategory,
    item_id:
      itemCategory === AnalyticsItemCategory.LAITE_NETTI
        ? subscriptionCard?.selectedAddon || commercialProduct.commercialProductCode // Should always exist in Laitenetti
        : commercialProduct.commercialProductCode,
    ...(itemListName != null ? { item_list_name: itemListName } : {}),
    item_name: subscriptionCard?.mainHeader || commercialProduct.commercialProductName,
    item_variant: getProductItemVariant(itemCategory, offer, subscriptionCard, selectedCommercialProducts),
    price: getCommercialProductYearlyPrice(commercialProducts, campaignAssociation, isLaitenettiOffer(offer)),
    quantity,
  };
};

const getEcommerceItemsFromAddedAddonOns = (
  quantity: number,
  eventType: EcommerceEventTypeV4,
  addOns?: AddedAddon[],
  bundleId?: string
): EcommerceItem[] =>
  addOns
    // Laitenetti is a special case which includes addon by default. However, addon should not create a separate ecommerce item.
    ?.filter(ao => !Object.values(MobileM2MAddonCode).includes(ao.addOnCode as MobileM2MAddonCode))
    ?.map(addOn => ({
      item_brand: DEFAULT_ITEM_BRAND,
      ...(bundleId != null ? { item_bundle_id: bundleId } : {}),
      item_category: ADDON_ANALYTICS_ITEM_CATEGORY,
      item_id: addOn.addOnCode,
      item_name: addOn.displayName,
      item_variant: getItemVariant(eventType, addOn.displayName),
      price: getAddOnYearlyPrice(addOn),
      quantity,
    })) || [];

const getEcommerceItems = (
  onlineModelName: string,
  offer: Offer,
  quantity: number,
  eventType: EcommerceEventTypeV4,
  addedAddOns?: AddedAddon[],
  coupon?: string,
  campaignAssociation?: CampaignAssociation,
  selectedCommercialProducts?: ConfiguredCommercialProduct[]
): EcommerceItem[] => {
  const cpCode =
    offer?.commercialProducts[0]?.commercialProductCode ??
    // If coming from /kassa, offer might not include commercial products
    selectedCommercialProducts?.[0]?.commercialProduct.commercialProductCode ??
    '';
  const bundleId = getBundleId(cpCode, addedAddOns);

  return [
    {
      ...getEcommerceItemFromOffer(
        onlineModelName,
        offer,
        quantity,
        undefined,
        coupon,
        campaignAssociation,
        undefined,
        selectedCommercialProducts
      ),
      ...(bundleId != null ? { item_bundle_id: bundleId } : {}),
    },
    ...getEcommerceItemsFromAddedAddonOns(quantity, eventType, addedAddOns, bundleId),
  ];
};

export const getSubscriptionAddToCartAnalyticsEvent = (
  onlineModelName: string,
  offer: Offer,
  quantity: number,
  addedAddOns?: AddedAddon[],
  voucher?: string,
  campaignAssociation?: CampaignAssociation
): EcommerceAddToCartEventV4 => ({
  event: EcommerceEventTypeV4.ADD_TO_CART,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: getEcommerceItems(
      onlineModelName,
      offer,
      quantity,
      EcommerceEventTypeV4.ADD_TO_CART,
      addedAddOns,
      voucher,
      campaignAssociation
    ),
  },
});

export const getBeginCheckoutAnalyticsEvent = (
  onlineModelName: string,
  offer: Offer,
  quantity: number,
  addedAddOns?: AddedAddon[],
  voucher?: string,
  campaignAssociation?: CampaignAssociation
): EcommerceBeginCheckoutEventV4 => ({
  event: EcommerceEventTypeV4.BEGIN_CHECKOUT,
  ecommerce: {
    items: getEcommerceItems(
      onlineModelName,
      offer,
      quantity,
      EcommerceEventTypeV4.BEGIN_CHECKOUT,
      addedAddOns,
      voucher,
      campaignAssociation
    ),
  },
});

export const getSubscriptionPurchaseAnalyticsEvent = (
  configuredOffer: ConfiguredOffer,
  orderId: string
): EcommercePurchaseEventV4 => {
  const { selectedCommercialProducts, offer } = configuredOffer;
  // There is no possibility to purchase different multiple different subscriptions at once, so the commercial
  // products and addons are same in all objects. Just pick the first one in the array.
  const { addedAddOns, selectedCampaignAssociation } = selectedCommercialProducts[0];
  const coupon = configuredOffer.voucher;
  const quantity = (selectedCommercialProducts && selectedCommercialProducts.length) || 1;
  const eCommerceItems: EcommerceItem[] = getEcommerceItems(
    configuredOffer.onlineModelName!,
    offer,
    quantity,
    EcommerceEventTypeV4.PURCHASE,
    addedAddOns,
    coupon,
    selectedCampaignAssociation,
    selectedCommercialProducts
  );
  const cumulativePrice = getCumulativePrice(eCommerceItems);

  return {
    event: EcommerceEventTypeV4.PURCHASE,
    payment_type: DEFAULT_PAYMENT_TYPE,
    ecommerce: {
      transaction_id: orderId,
      currency: CURRENCY_CODE_EUR,
      // cumulativePrice is already in euros
      value: cumulativePrice,
      // Not sure why we're doing the parse + toFixed here?
      tax: parseFloat((cumulativePrice * VAT_AS_DECIMAL).toFixed(2)),
      items: eCommerceItems,
      coupon,
      shipping: 0,
    },
  };
};
