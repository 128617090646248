import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { MyAccountDetails } from '../../../../../components/MyAccount/MyAccountDetails.js';
import { useAuth } from '../../../AuthProvider.js';

export const CompanyInfoMyAccountPath = () => {
  const { authenticatedUser } = useAuth();
  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.BASIC}>
      <MyAccountDetails authenticatedUser={authenticatedUser} />
    </CompanyInfoContainer>
  );
};
