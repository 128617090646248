import * as CL from '@design-system/component-library';
import { GridColumn } from './GridColumn.js';
import { cancelMsg, confirmMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useFormContext } from 'react-hook-form';
import type { BillingAccountFormValues } from '../forms/details/BillingAccountDetailsEditForm.js';

export interface SaveOrCancelProperties {
  onSubmit: (values: Partial<BillingAccountFormValues>) => void;
  setNotEditing: () => void;
  name: string;
  isSaving?: boolean;
}

export const SaveOrCancelBillingAccountButtons = ({
  onSubmit,
  setNotEditing,
  name,
  isSaving = false,
}: SaveOrCancelProperties) => {
  const { getValues, handleSubmit } = useFormContext();
  return (
    <GridColumn className="of-billing-account-details form-column edit-field button-column bottom">
      <div>
        <CL.Button
          id={`${name}Submit`}
          className={dsClass.MARGIN_RIGHT_8}
          onClick={handleSubmit(() => onSubmit(getValues()))}
          loading={isSaving}
        >
          {t.QVYK(confirmMsg)}
        </CL.Button>
        <CL.Button
          id={`${name}Cancel`}
          color="light"
          onClick={() => {
            setNotEditing();
          }}
        >
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </div>
    </GridColumn>
  );
};
