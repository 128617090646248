import { DELIVERY_MODES, getAllDeliveryMethods } from '../DeviceCheckoutDeliveryDetailsUtils.js';
import { DeviceCheckoutDeliveryMethodSelection } from './DeviceCheckoutDeliveryMethodSelection.js';
import { DeviceCheckoutDeliveryModeSelection } from './DeviceCheckoutDeliveryModeSelection.js';
import { Loading } from '../../Loading/index.js';
import { t } from '../../../common/i18n';
import { useCallback, useEffect, useState } from 'react';
import type { DeliveryMethod } from '../../../generated/api/deliveryMethod.js';
import type {
  DeliveryMethodDetails,
  DeliveryMode,
  DeviceCheckoutDeliveryDetailsBaseProps,
} from '../DeviceCheckoutDeliveryDetailsUtils.js';
import type { PickupPoint } from '../../../generated/api/pickupPoint.js';

interface DeviceCheckoutDeliveryMethodsSelectionListProps extends DeviceCheckoutDeliveryDetailsBaseProps {
  deliveryMode: DeliveryMode;
  isDirectDelivery: boolean;
  onDeliveryModeChosen: (deliveryMode: DeliveryMode) => void;
  hasVakProduct: boolean;
}

const getAvailableDeliveryModes = (deliveryMethods?: DeliveryMethod[], isEmployee?: boolean | undefined) => {
  const allDeliveryMethods = getAllDeliveryMethods(deliveryMethods, isEmployee);
  // To get a distinct list of available/visible delivery modes from all available delivery methods
  const availableDeliveryModeTypes = [...new Set(allDeliveryMethods?.map(item => item.deliveryModeType))];
  return DELIVERY_MODES.filter(deliveryMode => availableDeliveryModeTypes.includes(deliveryMode.type));
};

export const DeviceCheckoutDeliveryMethodsSelectionList = ({
  isEmployee,
  deliveryDetails,
  saveDeliveryDetailsToState,
  footerNote,
  deliveryMode,
  onDeliveryModeChosen,
  listOfDeliveryMethods,
  isDirectDelivery,
  hasVakProduct,
}: DeviceCheckoutDeliveryMethodsSelectionListProps) => {
  const [availableDeliveryMethods, setAvailableDeliveryMethods] = useState<DeliveryMethodDetails[]>();

  useEffect(() => {
    if (listOfDeliveryMethods) {
      const methods = deliveryMode?.getDeliveryMethods(listOfDeliveryMethods, isEmployee || false);
      if (methods) {
        setAvailableDeliveryMethods(methods);
      }
    }
  }, [deliveryMode, listOfDeliveryMethods, isDirectDelivery, isEmployee]);

  const availableDeliveryModes = getAvailableDeliveryModes(listOfDeliveryMethods, isEmployee);
  const selectedDeliveryMethod =
    deliveryDetails && availableDeliveryMethods?.find(method => method.type === deliveryDetails.deliveryMethodType);

  const handlePickupPointSelected = useCallback(
    (pickupPoint: PickupPoint) => {
      saveDeliveryDetailsToState({
        ...deliveryDetails,
        pickupPoint,
      });
    },
    [saveDeliveryDetailsToState] /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
  );

  return listOfDeliveryMethods ? (
    <>
      {hasVakProduct
        ? t.XWA9(
            'You have selected a product for delivery with a restricted delivery method. The reasons for the restriction are stated in the product information.'
          )
        : ''}
      <DeviceCheckoutDeliveryModeSelection
        isEmployee={isEmployee}
        deliveryMode={deliveryMode}
        onDeliveryModeChosen={onDeliveryModeChosen}
        availableDeliveryModes={availableDeliveryModes}
      />
      {availableDeliveryMethods && (
        <DeviceCheckoutDeliveryMethodSelection
          deliveryMethods={availableDeliveryMethods}
          selectedDeliveryMethod={selectedDeliveryMethod}
          deliveryDetails={deliveryDetails}
          saveDeliveryDetailsToState={saveDeliveryDetailsToState}
          handlePickupPointSelected={handlePickupPointSelected}
          isEmployee={isEmployee}
          footerNote={footerNote}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};
