import { ConfirmationDialog } from '../ConfirmationDialog';
import { confirmMsg, t } from '../../common/i18n';
import { deprecateVirtualCatalog } from '../../common/fetch';
import { paths } from '../../common/constants/pathVariables';
import { startNotification } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface DeleteDeviceListDialogProps {
  accountMasterId?: string;
  catalogName: string;
  virtualCatalogCode: string;
}

export const DeleteCatalogDialog = ({
  accountMasterId,
  catalogName,
  virtualCatalogCode,
}: DeleteDeviceListDialogProps) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onDeleteCatalog = async () => {
    try {
      setIsSubmitting(true);
      await deprecateVirtualCatalog(virtualCatalogCode, accountMasterId);
      navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
    } catch {
      dispatch(startNotification(t.L0X1('Failed to delete the catalogue'), 'error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ConfirmationDialog
      body={<div>{catalogName}</div>}
      confirmButtonText={t.QVYK(confirmMsg)}
      header={t.WGM5('Confirm deletion of device list')}
      hideClosingCross={false}
      isLoading={isSubmitting}
      onCloseDialog={() => navigate(location.search)}
      onConfirm={onDeleteCatalog}
    />
  );
};
