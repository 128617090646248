import * as CL from '@design-system/component-library';
import { AttachPbxContentConfirmationDialogContent } from '../AttachPbxContentConfirmationDialogContent/AttachPbxContentConfirmationDialogContent.js';
import { CorporateNumberRangeSelect } from './components/CorporateNumberRangeSelect.js';
import { CorporateNumberSelect } from './components/CorporateNumberSelect.js';
import { DialogType } from '../../common/enums.js';
import { ExtensionNumberAndRangeContent } from './ExtensionNumber.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../Loading/index.js';
import { NumberRange } from '../../generated/api/numberRange.js';
import { PbxSolutionSelect } from './components/PbxSolutionSelect.js';
import { ServiceLevelFields } from './components/ServiceLevelFields.js';
import { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import {
  attachSubscriptionToPbx,
  displayMoreNumbersFromRange,
  loadNumberRange,
  showDialog,
} from '../../selfservice/actions/index.js';
import { cancelMsg, confirmMsg, subscriptionMsg, t } from '../../common/i18n/index.js';
import { constructDeliveryAddress, createOfferFromSelectedAddons, getAccountId } from './prepareOrder.js';
import { findBestMatchForExtensionNumber } from '../../common/utils/pbxUtils.js';
import { forceInternationalPhoneNumberFormat, formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import {
  getCurrentPbxSolutionNumberRanges,
  getRelatedExtensionNumberRangeId,
  getSelectedPbxSolution,
  isPbxConfigComplete,
  sortNumberRangesInPbxSolutions,
} from './attachPbxUtils';
import { getOpeningFeeAddons } from '../../common/utils/ringUtils.js';
import { getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import type { AddOn } from '../../generated/api/addOn.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { BillingAccount } from '../../generated/api/billingAccount.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompanyInfoState, NumberRangesState } from '../../common/types/states.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { DeliveryAddress } from '../../generated/api/deliveryAddress.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { OnlineOrder } from '../../generated/api/onlineOrder.js';
import type { ServiceLevel, ServiceLevelAddon } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';
import type { Subscription } from '../../generated/api/subscription.js';

import './AttachPbxContent.scss';

export type RingSettingsConfiguration = SubscriptionPbxConfiguration & {
  pbxServiceLevels: ServiceLevel[];
  pbxServiceLevelAddons: ServiceLevelAddon[];
  selectedExtensionNumber: string;
};

export interface AttachPbxContentProps {
  category: CategoryKey;
  companyInfo?: CompanyInfoState | null;
  numberRanges?: NumberRangesState;
  subscription?: Subscription;
  pbxServiceLevels?: ServiceLevel[];
  pbxServiceLevelAddons?: ServiceLevelAddon[];
  hideSaving?: boolean;
  onSubmitOrder?: (
    orderItems?: ConfiguredOffer[],
    deliveryAddress?: DeliveryAddress,
    isCompanyNameRequired?: boolean,
    deliveryAddressValidationErrors?: CommonError[],
    personAccountValidationErrors?: CommonError[],
    billingAccountId?: string,
    newBillingAccount?: BillingAccount,
    newBillingAccountValidationErrors?: CommonError[],
    addOns?: AddOn[],
    setShowPOBoxWarningModal?: (isShown: boolean) => void,
    shipmentType?: OnlineOrder.ShipmentTypeEnum,
    apiVersion?: string,
    attachRingRedirect?: string
  ) => void;
  associations?: AssociationRecord[];
  dependencies?: DependencyRecord[];
  ringModels?: OnlineModel[];
  ringSettingsConfiguration?: RingSettingsConfiguration;
  blockedCorporateNumbers?: string[];
  blockedExtensionNumbers?: string[];
  onClickSubscription: (id: string) => void;
  onChangeConfig?: (configuration: ConfiguredOffer, pbxConfig: RingSettingsConfiguration) => void;
}

export interface AttachPbxContentFormValues {
  pbxSolutionId: string;
  pbxServiceLevels: ServiceLevel[];
  pbxServiceLevelAddons: ServiceLevelAddon[];
  corporateNumberRangeId?: string;
  corporateNumber?: string;
  extensionNumber?: string;
  extensionRangeId?: string;
}

// This function gets a possible "related" extension number which is the last 3 digits of corporate number. This extension
// number is returned in case it is found in the number range
const getRelatedExtensionNumber = (
  numberRanges: NumberRangesState,
  selectedExtensionRangeId: string,
  selectedCorporateNumber: string
) => {
  if (!numberRanges[selectedExtensionRangeId] || !numberRanges[selectedExtensionRangeId].displayedNumbers) {
    return undefined;
  }
  const availableExtensionNumbers = numberRanges[selectedExtensionRangeId].displayedNumbers!;
  const relatedExtensionNumber = findBestMatchForExtensionNumber(selectedCorporateNumber, availableExtensionNumbers);

  if (Number(relatedExtensionNumber) < 700 || Number(relatedExtensionNumber) >= 900) {
    return relatedExtensionNumber;
  }
  return undefined;
};

const getSelectedServiceLevel = (serviceLevels: ServiceLevel[]): ServiceLevel =>
  serviceLevels.find(serviceLevel => serviceLevel.selected) || ({} as ServiceLevel);

const createCompletePbxConfiguration = (
  configuration: AttachPbxContentFormValues,
  subscription?: Subscription
): SubscriptionPbxConfiguration => ({
  ...configuration,
  busyAction: SubscriptionPbxConfiguration.BusyActionEnum.NEXT_NUMBER_IN_CHAIN,
  corporateNumber: forceInternationalPhoneNumberFormat(configuration.corporateNumber)!,
  delayForChainCalls: 20,
  pbxConfigurationDetails: { currentActiveChain: SubscriptionPbxDetails.CurrentActiveChainEnum.WORKING_HOURS },
  subscriptionDisplayId: subscription?.subscriptionDisplayId,
  pbxSolutionId: configuration.pbxSolutionId,
  workingHoursReachabilityChain: subscription?.details?.mobile?.mobileNumber
    ? [subscription.details.mobile.mobileNumber]
    : undefined,
});

const createRingSettingsConfiguration = (
  configuration: AttachPbxContentFormValues,
  subscription?: Subscription
): RingSettingsConfiguration => ({
  ...createCompletePbxConfiguration(configuration, subscription),
  pbxServiceLevels: configuration.pbxServiceLevels,
  pbxServiceLevelAddons: configuration.pbxServiceLevelAddons,
  selectedExtensionNumber: configuration.extensionNumber ?? '',
});

const getAddonsForSelectedServiceLevel = (
  serviceLevels: ServiceLevel[],
  serviceLevelAddons: ServiceLevelAddon[],
  onlySelected: boolean
): ServiceLevelAddon[] => {
  const selectedServiceLevel = getSelectedServiceLevel(serviceLevels);
  return serviceLevelAddons.filter(addon =>
    onlySelected
      ? addon.serviceLevelId === selectedServiceLevel.id && addon.selected
      : addon.serviceLevelId === selectedServiceLevel.id
  );
};

const createOffer = (
  pbxConfig: SubscriptionPbxConfiguration,
  selectedServiceLevel: ServiceLevel,
  selectedServiceLevelAddons: ServiceLevelAddon[],
  subscription?: Subscription
) => {
  const mobilePbxServiceLevelDetails = {
    mobileVoiceSubscriptionId: subscription?.subscriptionId,
    mobilePbxSubscriptionId: pbxConfig.pbxSolutionId || '',
    corporateNumber: pbxConfig.corporateNumber || '',
    extensionNumber: pbxConfig.extensionNumber || '',
    corporateNumberRangeId: pbxConfig.corporateNumberRangeId || '',
    extensionRangeId: pbxConfig.extensionRangeId || '',
  };

  return createOfferFromSelectedAddons(selectedServiceLevel, selectedServiceLevelAddons, mobilePbxServiceLevelDetails);
};

// Gets a default extension number to use. First we try to get the "related" (see getRelatedExtensionNumber for more details)
// extension number if it exists. Otherwise we return the first number in the list taking in consideration number
// restrictions
const getDefaultExtensionNumber = (
  numberRanges: NumberRangesState,
  selectedExtensionRangeId: string,
  defaultCorporateNumber: string,
  blockedExtensionNumbers: string[]
) => {
  const defaultExtensionNumber = getRelatedExtensionNumber(
    numberRanges,
    selectedExtensionRangeId,
    defaultCorporateNumber
  );
  if (defaultExtensionNumber !== undefined) {
    return defaultExtensionNumber;
  }
  const displayedNumbers = numberRanges[selectedExtensionRangeId]?.displayedNumbers;
  if (!displayedNumbers) {
    return undefined;
  }

  const availableNumbers = displayedNumbers.filter(number => !blockedExtensionNumbers.includes(number));
  return availableNumbers?.find(number => Number(number) < 700 || Number(number) >= 900);
};

const getPbxSolutions = (pbxSolutions: Subscription[]) => {
  const filteredSolutions = pbxSolutions.filter(
    pbxSolution => pbxSolution.subscriptionType === SubscriptionType.MOBILE_PBX
  );
  return filteredSolutions.length > 0 ? filteredSolutions : pbxSolutions;
};

const getExtensionNumberRanges = (pbxSolutions: Subscription[], selectedPbxSolutionId?: string) => {
  return pbxSolutions
    .find(sub => sub.subscriptionId === selectedPbxSolutionId)!
    .details!.mobilePbx!.numberRanges.filter(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.EXTENSION
    )
    .filter(numberRange => Number(numberRange.startNumber) < 700 || Number(numberRange.startNumber) >= 900);
};

const getDefaultCorporateNumber = (
  defaultCorporateRanges: NumberRange[],
  blockedCorporateNumbers: string[],
  numberRanges?: NumberRangesState,
  rangeId?: string
) => {
  const defaultCorporateNumbers = numberRanges?.[rangeId ?? defaultCorporateRanges[0]?.rangeId]?.displayedNumbers || [];
  return defaultCorporateNumbers.find(corporateNumber => !blockedCorporateNumbers.includes(corporateNumber));
};

export const AttachRingPbxContent = ({
  companyInfo,
  subscription,
  numberRanges,
  onClickSubscription,
  pbxServiceLevels,
  pbxServiceLevelAddons,
  associations,
  dependencies,
  ringModels,
  onSubmitOrder,
  onChangeConfig,
  hideSaving,
  ringSettingsConfiguration,
  blockedCorporateNumbers = [],
  blockedExtensionNumbers = [],
}: AttachPbxContentProps) => {
  const dispatch = useDispatch();
  const isLoading = !pbxServiceLevels?.length || !pbxServiceLevelAddons?.length;
  const pbxSolutions = getPbxSolutions(companyInfo?.pbxSolutions ?? []);
  const defaultPbxSolutionId = sortNumberRangesInPbxSolutions(pbxSolutions)[0].subscriptionId;
  const defaultPbxSolution = getSelectedPbxSolution(pbxSolutions, defaultPbxSolutionId);
  const defaultCorporateRanges = defaultPbxSolution.details!.mobilePbx!.numberRanges.filter(
    numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
  );
  const defaultCorporateRange = defaultCorporateRanges[0].rangeId;

  const methods = useForm<AttachPbxContentFormValues>({
    defaultValues: {
      corporateNumber: ringSettingsConfiguration?.corporateNumber,
      corporateNumberRangeId: ringSettingsConfiguration?.corporateNumberRangeId || defaultCorporateRange,
      pbxSolutionId: ringSettingsConfiguration?.pbxSolutionId || defaultPbxSolutionId,
      pbxServiceLevelAddons,
      pbxServiceLevels,
    },
  });

  const formValue = methods.watch();

  useEffect(() => {
    if (onChangeConfig) {
      const completePbxConfiguration = createCompletePbxConfiguration(formValue);
      const offer = createOffer(
        completePbxConfiguration,
        getSelectedServiceLevel(formValue.pbxServiceLevels),
        getAddonsForSelectedServiceLevel(formValue.pbxServiceLevels, formValue.pbxServiceLevelAddons, true),
        subscription
      );
      onChangeConfig(offer, createRingSettingsConfiguration(formValue, subscription));
    }
  }, [formValue, onChangeConfig, subscription]);

  useEffect(() => {
    const defaultCorporateNumber = getDefaultCorporateNumber(
      defaultCorporateRanges,
      blockedCorporateNumbers,
      numberRanges,
      formValue.corporateNumberRangeId
    );

    if (!formValue.corporateNumber && defaultCorporateNumber) {
      methods.setValue('corporateNumber', defaultCorporateNumber);
    }
  }, [blockedCorporateNumbers, defaultCorporateRanges, formValue, methods, numberRanges]);

  useEffect(() => {
    if (formValue.corporateNumberRangeId && !numberRanges?.[formValue.corporateNumberRangeId]) {
      dispatch(loadNumberRange(formValue.corporateNumberRangeId));
    }
    if (formValue.extensionRangeId && !numberRanges?.[formValue.extensionRangeId]) {
      dispatch(loadNumberRange(formValue.extensionRangeId));
    }
  }, [dispatch, formValue.corporateNumberRangeId, formValue.extensionRangeId, numberRanges]);

  useEffect(() => {
    if (formValue.pbxSolutionId && formValue.corporateNumberRangeId === undefined) {
      methods.setValue(
        'corporateNumberRangeId',
        getCurrentPbxSolutionNumberRanges(
          pbxSolutions.find(pbxSolution => pbxSolution.subscriptionId === formValue.pbxSolutionId)
        )[0].rangeId
      );
    }
  }, [formValue.corporateNumberRangeId, formValue.pbxSolutionId, methods, pbxSolutions]);

  useEffect(() => {
    const rangeId = formValue.corporateNumberRangeId ?? defaultCorporateRanges[0]?.rangeId;
    const defaultCorporateNumbers = numberRanges?.[rangeId]?.displayedNumbers || [];
    const defaultCorporateNumber = defaultCorporateNumbers.find(
      corporateNumber => !blockedCorporateNumbers.includes(corporateNumber)
    );
    if (formValue.extensionRangeId === undefined && defaultCorporateNumber !== undefined) {
      const relatedExtensionNumberRangeId = getRelatedExtensionNumberRangeId(
        getSelectedPbxSolution(pbxSolutions, formValue.pbxSolutionId),
        defaultCorporateNumber
      );

      if (relatedExtensionNumberRangeId) {
        methods.setValue('extensionRangeId', relatedExtensionNumberRangeId);
      } else {
        const defaultExtensionRange = getSelectedPbxSolution(
          pbxSolutions,
          formValue.pbxSolutionId
        ).details!.mobilePbx!.numberRanges.find(
          numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.EXTENSION
        );
        if (defaultExtensionRange) {
          methods.setValue('extensionRangeId', defaultExtensionRange.rangeId);
        }
      }
    }
  }, [
    blockedCorporateNumbers,
    blockedExtensionNumbers,
    defaultCorporateRanges,
    formValue,
    formValue.extensionRangeId,
    formValue.pbxSolutionId,
    methods,
    numberRanges,
    pbxSolutions,
  ]);

  useEffect(() => {
    if (!formValue.extensionNumber && numberRanges && formValue.extensionRangeId && formValue.corporateNumber) {
      const defaultExtensionNumber = getDefaultExtensionNumber(
        numberRanges,
        formValue.extensionRangeId,
        formValue.corporateNumber,
        blockedExtensionNumbers
      );

      if (defaultExtensionNumber) {
        methods.setValue('extensionNumber', defaultExtensionNumber);
      }
    }
  }, [
    blockedExtensionNumbers,
    formValue.corporateNumber,
    formValue.extensionNumber,
    formValue.extensionRangeId,
    methods,
    numberRanges,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  const ringDialogOptions = (
    pbxConfig: SubscriptionPbxConfiguration,
    selectedServiceLevel: ServiceLevel,
    selectedServiceLevelAddons: ServiceLevelAddon[],
    subscriptionServiceLevelAddons: ServiceLevelAddon[]
  ): DialogParams => {
    const openingFeeAddons = getOpeningFeeAddons(selectedServiceLevelAddons, dependencies, associations);
    return {
      body: (
        <AttachPbxContentConfirmationDialogContent
          numberCategory={numberRanges![pbxConfig.corporateNumberRangeId!].numberCategory!}
          pbxConfig={pbxConfig}
          voiceSub={subscription!}
          selectedServiceLevelAddons={selectedServiceLevelAddons}
          selectedServiceLevel={selectedServiceLevel}
          subscriptionServiceLevelAddons={subscriptionServiceLevelAddons}
          openingFeeAddons={openingFeeAddons}
        />
      ),
      header: t.ZYIU('Elisa Ring solution for subscription'),
      onConfirm: () => {
        const mobilePbxServiceLevelDetails = {
          mobileVoiceSubscriptionId: subscription?.subscriptionId ?? '',
          mobilePbxSubscriptionId: pbxConfig.pbxSolutionId ?? '',
          corporateNumber: pbxConfig.corporateNumber ?? '',
          extensionNumber: pbxConfig.extensionNumber ?? '',
          corporateNumberRangeId: pbxConfig.corporateNumberRangeId ?? '',
          extensionRangeId: pbxConfig.extensionRangeId ?? '',
        };
        if (onSubmitOrder) {
          const offer = createOfferFromSelectedAddons(
            selectedServiceLevel,
            selectedServiceLevelAddons,
            mobilePbxServiceLevelDetails
          );
          const deliveryAddress = constructDeliveryAddress(companyInfo, subscription);
          const accountId = getAccountId(subscription);
          onSubmitOrder(
            [offer],
            deliveryAddress,
            undefined,
            undefined,
            undefined,
            accountId,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            'v2',
            `${paths.PS_MOBILE_SUBSCRIPTIONS}/${getSubscriptionUrlId(subscription!)}`
          );
        }
      },
      type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    };
  };

  const vakioDialogOptions = (pbxConfig: SubscriptionPbxConfiguration): DialogParams => ({
    body: (
      <AttachPbxContentConfirmationDialogContent
        numberCategory={numberRanges![pbxConfig.corporateNumberRangeId!].numberCategory!}
        pbxConfig={pbxConfig}
        voiceSub={subscription!}
      />
    ),
    header: t.DQ1Z('Vakio solution for subscription'),
    onConfirm: () => {
      dispatch(attachSubscriptionToPbx(subscription!.subscriptionDisplayId, subscription!.subscriptionId, pbxConfig));
    },
    type: DialogType.GENERIC_CONFIRMATION_DIALOG,
  });

  const onSubmit = (values: AttachPbxContentFormValues) => {
    const pbxConfig: SubscriptionPbxConfiguration = createCompletePbxConfiguration(values, subscription);
    if (!isPbxConfigComplete(pbxConfig)) {
      return false;
    }
    if (values.pbxServiceLevels && values.pbxServiceLevelAddons) {
      dispatch(
        showDialog(
          ringDialogOptions(
            pbxConfig,
            getSelectedServiceLevel(values.pbxServiceLevels),
            getAddonsForSelectedServiceLevel(values.pbxServiceLevels, values.pbxServiceLevelAddons, true),
            getAddonsForSelectedServiceLevel(values.pbxServiceLevels, values.pbxServiceLevelAddons, false)
          )
        )
      );
    } else {
      dispatch(showDialog(vakioDialogOptions(pbxConfig)));
    }
    return true;
  };

  return (
    <div className="of-attach-pbx-content">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h3>{t.RHLU('Ring settings of subscription')}</h3>
          <p>{t.BLQP(`Elisa Ring is an easy way of making mobile phones part of a company's telephone system.`)}</p>
          <CL.Description
            items={[
              {
                title: t.P674(subscriptionMsg),
                description: formatPhoneNumber(subscription?.details?.mobile?.mobileNumber || ''),
              },
            ]}
          />
          <PbxSolutionSelect
            pbxSolutions={pbxSolutions}
            isRing={true}
            onSelect={(value: string) => {
              methods.setValue('corporateNumberRangeId', undefined);
              methods.setValue('corporateNumber', undefined);
              methods.setValue('extensionNumber', undefined);
              methods.setValue('extensionRangeId', undefined);
              methods.setValue('corporateNumberRangeId', undefined);
              methods.setValue('pbxSolutionId', value);
            }}
            selectedValue={formValue.pbxSolutionId}
          />
          <ServiceLevelFields
            serviceLevels={pbxServiceLevels}
            addons={pbxServiceLevelAddons}
            associations={associations}
            dependencies={dependencies}
            ringModels={ringModels}
            onChange={(field: keyof AttachPbxContentFormValues, value: ServiceLevel[] | ServiceLevelAddon[]) =>
              methods.setValue(field, value)
            }
          />
          <CorporateNumberRangeSelect
            selectedValue={formValue.corporateNumberRangeId}
            onSelect={(value: string) => {
              methods.setValue('corporateNumberRangeId', value);
              methods.setValue('corporateNumber', undefined);
              methods.setValue('extensionNumber', undefined);
            }}
            currentPbxSolutionNumberRanges={getCurrentPbxSolutionNumberRanges(
              pbxSolutions.find(pbxSolution => pbxSolution.subscriptionId === formValue.pbxSolutionId)
            )}
          />
          <CorporateNumberSelect
            onSelect={(field: keyof AttachPbxContentFormValues, value: string) => {
              methods.setValue(field, value);
              methods.setValue('extensionNumber', undefined);
            }}
            selectedValue={formValue.corporateNumber}
            blockedNumbers={blockedCorporateNumbers}
            rangeNumbers={numberRanges?.[formValue.corporateNumberRangeId ?? '']}
            onDisplayMoreNumbers={() => dispatch(displayMoreNumbersFromRange(formValue.corporateNumberRangeId ?? ''))}
            retryAction={() => dispatch(loadNumberRange(formValue.corporateNumberRangeId ?? ''))}
          />
          <ExtensionNumberAndRangeContent
            extensionNumberRanges={getExtensionNumberRanges(pbxSolutions, formValue.pbxSolutionId)}
            selectedExtensionNumber={formValue.extensionNumber}
            selectedExtensionRangeId={formValue.extensionRangeId}
            selectedCorporateNumber={formValue.corporateNumber}
            blockedExtensionNumbers={blockedExtensionNumbers}
            numberRanges={numberRanges}
            updateExtensionRangeId={value => methods.setValue('extensionRangeId', value)}
            updateExtensionNumber={value => methods.setValue('extensionNumber', value)}
          />
          <div className="of-barrings-content__actions">
            {!hideSaving && (
              <>
                <CL.Button disabled={!isPbxConfigComplete(formValue)} size="l" color="primary" type="submit">
                  {t.QVYK(confirmMsg)}
                </CL.Button>
                <CL.Button
                  size="l"
                  color="link"
                  onClick={() => onClickSubscription(getSubscriptionUrlId(subscription!))}
                >
                  {t.B2V1(cancelMsg)}
                </CL.Button>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
