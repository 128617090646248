import * as CL from '@design-system/component-library';
import { CommercialProductType } from '../../generated/api/commercialProductType';
import { DiscontinuedProducts } from './DiscontinuedProducts';
import { Dropdown } from '../Dropdown/Dropdown';
import { ERROR_BANNER_ID, getProductImage } from './shoppingBasketUiUtils';
import { ErrorBanner } from './ErrorBanner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginBanner } from './LoginBanner.js';
import { ShoppingBasketAddon } from './ShoppingBasketAddon';
import { ShoppingBasketCompanySelector } from './ShoppingBasketCompanySelector';
import { ShoppingBasketEntry } from './ShoppingBasketEntry.js';
import {
  addMsg,
  additionalServicesMsg,
  cartIsEmptyMsg,
  checkoutMsg,
  continueShoppingMsg,
  contractPriceCapitalizedMsg,
  paymentPeriodMsg,
  quantityMsg,
  removeMsg,
  selectMsg,
  shoppingCartContentPluralMsg,
  shoppingCartContentSingularMsg,
  shoppingCartMsg,
  subtractMsg,
  t,
  totalMsg,
} from '../../common/i18n/index.js';
import {
  calculateTotalPrices,
  getAddonsDisplayData,
  getBasketItemStates,
  getDisclaimerFields,
  getOffer,
  getOnetimePaymentText,
  getOnlineModelForBasketItem,
  getPaymentLabel,
  getPaymentOptions,
  getPrice,
  getPriceFromBasketOfferItem,
  getPriceToDisplay,
  getTotalAmount,
  isItemDiscontinued,
} from './shoppingBasketUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getUserHasSeenCompanySelectionNotificationShoppingBasket } from '../../selfservice/common/localStorageUtils';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigateBack } from '../../common/hooks/useNavigateBack';
import { useTitle } from '../../common/hooks/useTitle';
import type { BasketItem, ShoppingBasketType } from '../../common/types/shoppingBasket.js';
import type { BasketItemState, ChangePaymentOptions, ErrorData } from './types';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { EppSolutionResponse } from '../../generated/api/eppSolutionResponse';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { ReactElement } from 'react';

import './ShoppingBasket.scss';

export interface ShoppingBasketProps {
  basketItems?: ShoppingBasketType;
  discountedPrices: DiscountedPrices[];
  models: OnlineModel[];
  loggedIn: boolean;
  changeQuantity: (productId: string, quantity: number) => void;
  onPaymentOptionChange: (changePaymentOptions: ChangePaymentOptions) => void;
  eppSolution: EppSolutionResponse | undefined;
  userAccounts: CL.HeaderUserAccount[];
  loginInProgress: boolean;
}

const mapItems = (
  basketItems: BasketItem[],
  models: OnlineModel[],
  discountedPrices: DiscountedPrices[],
  onPaymentOptionChange: (changePaymentOptions: ChangePaymentOptions) => void,
  onQuantityChange: (productId: string, quantity: number) => void,
  eppSolution: EppSolutionResponse | undefined,
  basketItemStates: Map<string, BasketItemState>
): React.ReactElement[] | undefined => {
  return basketItems.map((basketItem): React.ReactElement => {
    const onlineModelForItem = getOnlineModelForBasketItem(models, basketItem);
    const basketItemState = basketItemStates.get(basketItem.id);
    const isSalesProduct = onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT;

    const showContractPriceWillBeConfirmedMsg = basketItemState?.showContractPriceWillBeConfirmedMsg || false;
    const price = showContractPriceWillBeConfirmedMsg
      ? { price: getPriceFromBasketOfferItem(basketItem.offer) }
      : getPrice(basketItem, onlineModelForItem, discountedPrices);

    // NOTE: elements 1-3 for pricing texts, after those badges (orange, turquoise, and rest light-blue), use empty string to skip
    const badges = price?.isDiscountedPrice ? [t.JPKP(contractPriceCapitalizedMsg)] : [];

    const oneTimePaymentText = getOnetimePaymentText(price?.price?.oneTimeCharge, basketItem.quantity, isSalesProduct);

    const paymentOptions = getPaymentOptions(basketItem.offer, onlineModelForItem, discountedPrices, eppSolution);
    const selectedPaymentOption = paymentOptions.find(option => option.selected)?.id || '';
    const contractPeriodUnavailable =
      (!basketItemState?.showContractPriceWillBeConfirmedMsg && basketItemState?.contractPeriodUnavailable) || false;
    const paymentOptionsElement =
      paymentOptions && paymentOptions.length > (contractPeriodUnavailable ? 0 : 1) ? (
        <Dropdown
          i18n_dropdown_errorMessage={contractPeriodUnavailable ? t.JIP2('Select contract period') : undefined}
          className={dsClass.SHOPPINGCART_PRODUCT_PRICE_OPTIONS}
          items={paymentOptions.map(option => ({
            label: option.label,
            value: option.id,
          }))}
          i18n_dropdown_placeholderText={contractPeriodUnavailable ? t.QRYV(selectMsg) : undefined}
          label={t.EM2Q(paymentPeriodMsg)}
          onValueChange={paymentOption => {
            // Workaround for bug: DS dropdown triggers onValueChange if the option is same that was already selected.
            // Check that value is changed
            if (!selectedPaymentOption || !paymentOption.id.includes(selectedPaymentOption)) {
              onPaymentOptionChange({
                basketItem: basketItem,
                changeToCommercialProductCode: paymentOption.dataset.value!,
                onlineModel: onlineModelForItem,
                discountedPrices: discountedPrices,
              });
            }
          }}
          optional={false}
          selectedValue={contractPeriodUnavailable ? undefined : selectedPaymentOption}
        />
      ) : (
        <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE_OPTIONS} />
      );

    const paymentDisplayField = showContractPriceWillBeConfirmedMsg ? (
      <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE_OPTIONS}>
        <span>{getPaymentLabel(price?.price, true)}</span>
      </div>
    ) : (
      paymentOptionsElement
    );

    const quantityField = (
      <CL.Quantity
        i18n_quantity_deleteAriaLabel={t.R3VE(removeMsg)}
        i18n_quantity_minusAriaLabel={t.C2KQ(subtractMsg)}
        i18n_quantity_plusAriaLabel={t.VKFM(addMsg)}
        i18n_quantity_quantityAriaLabel={t.M0W7(quantityMsg)}
        className={dsClass.SHOPPINGCART_PRODUCT_QUANTITY}
        currentValue={basketItem.quantity}
        minValue={1}
        onChange={(quantity: number) => {
          // Comment copied from DS (v. 9.1.0):
          // remove causes an extra onChange with the previous quantity (i.e. 1), this avoids firing a change on those
          // we can't just use onChange as the change quantity is minValue on remove...
          // and if we don't use removeLessThanMin, we don't get the trashcan icon
          if (quantity !== basketItem.quantity) {
            onQuantityChange(basketItem.id, quantity);
          }
        }}
        onRemove={() => {
          onQuantityChange(basketItem.id, 0);
        }}
        removeLessThanMin
      />
    );

    const addOns =
      basketItem.offer.addOns && onlineModelForItem ? (
        <ul aria-label={t.LXSR(additionalServicesMsg)} className={dsClass.SHOPPINGCART_ADDONS}>
          <ShoppingBasketAddon
            addOns={getAddonsDisplayData(basketItem.offer.addOns, onlineModelForItem, basketItem.quantity)}
          />
        </ul>
      ) : (
        <></>
      );

    const disclaimerFields = contractPeriodUnavailable
      ? ['-', '-']
      : [...getDisclaimerFields(oneTimePaymentText, price), ...badges];

    const shoppingCartPrice = getPriceToDisplay(price, basketItem.quantity);
    const priceElement = contractPeriodUnavailable ? (
      <span>-</span>
    ) : (
      <>
        <output className={dsClass.SHOPPINGCART_PRICE_AMOUNT}>{shoppingCartPrice.amount}</output>
        <span className={dsClass.SHOPPINGCART_PRICE_UNIT}>{shoppingCartPrice.unit}</span>
      </>
    );

    const offer = getOffer(basketItem.offer.guid, onlineModelForItem);
    return (
      <ShoppingBasketEntry
        key={basketItem.id}
        id={basketItem.id}
        productImage={getProductImage(basketItem.imageUrl, basketItem.name, offer, onlineModelForItem)}
        productName={
          <Link to={onlineModelForItem?.pagePath || basketItem.imageUrl}>{offer?.offerName || basketItem.name}</Link>
        }
        paymentField={paymentDisplayField}
        quantityField={quantityField}
        price={priceElement}
        disclaimerFields={disclaimerFields}
        showContractPriceWillBeConfirmedMsg={showContractPriceWillBeConfirmedMsg}
        addOns={addOns}
      />
    );
  });
};

export const ShoppingBasket = ({
  basketItems,
  discountedPrices,
  models,
  loggedIn,
  changeQuantity,
  onPaymentOptionChange,
  eppSolution,
  userAccounts,
  loginInProgress,
}: ShoppingBasketProps) => {
  useTitle([t.BE8Q(shoppingCartMsg)]);
  const location = useLocation();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack(paths.WEB_SHOP, -1);

  const availableBasketItems = basketItems?.items?.filter(basketItem => !isItemDiscontinued(basketItem, models));
  const discontinuedBasketItems = basketItems?.items?.filter(basketItem => isItemDiscontinued(basketItem, models));

  const basketItemStates = getBasketItemStates(availableBasketItems, models, eppSolution, loggedIn);
  const errors: ErrorData[] = [...basketItemStates]
    .filter(([, basketItem]) => basketItem.contractPeriodUnavailable && !basketItem.showContractPriceWillBeConfirmedMsg)
    .map(([id]) => {
      return {
        id: id,
        message: t.JZYE('The selected contract period is not available. Select another contract period.'),
      };
    });

  const shoppingBasketRows = mapItems(
    availableBasketItems || [],
    models,
    discountedPrices,
    onPaymentOptionChange,
    changeQuantity,
    eppSolution,
    basketItemStates
  );

  const discontinuedRows = [
    discontinuedBasketItems && discontinuedBasketItems.length > 0 ? (
      <DiscontinuedProducts
        basketItems={discontinuedBasketItems}
        onlineModels={models}
        onQuantityChange={changeQuantity}
      />
    ) : undefined,
  ];

  const allRows = (shoppingBasketRows ? [...(shoppingBasketRows || []), ...discontinuedRows] : []) as
    | ReactElement[]
    | undefined;

  const totalProductQuantity = getTotalAmount(availableBasketItems);

  const showCompanySelector = loggedIn && userAccounts.length > 1;
  const paddingForPopover =
    showCompanySelector &&
    !loginInProgress &&
    !getUserHasSeenCompanySelectionNotificationShoppingBasket() &&
    window.innerWidth < 481
      ? 'of-shopping-basket-popover-padding'
      : undefined;

  return (
    <>
      <div className="of-shopping-basket-wrapper">
        <div className={`${showCompanySelector && !loginInProgress ? dsClass.BACKGROUND_COLOR_NEUTRAL_200 : ''}`}>
          <div className="of-heading-and-company-selector">
            <h1
              className={`${dsClass.H1} ${dsClass.MARGIN_BOTTOM_4}  ${dsClass.MARGIN_TOP_0} ${paddingForPopover} of-shopping-basket-header`}
            >
              {t.BE8Q(shoppingCartMsg)}
            </h1>
            {showCompanySelector && (
              <ShoppingBasketCompanySelector userAccounts={userAccounts} showInDialog={loginInProgress} />
            )}
          </div>
        </div>
        <div className="of-shopping-basket-content">
          <ErrorBanner errors={errors} />
          {!loggedIn && (
            <div className="of-with-horizontal-padding">
              <LoginBanner />
            </div>
          )}

          <div className={dsClass.MARGIN_TOP_6}>
            <CL.ShoppingCart
              ariaAddonsLabel={t.LXSR(additionalServicesMsg)}
              ariaPaymentLabel={t.EM2Q(paymentPeriodMsg)}
              ariaQuantityDeleteLabel={t.R3VE(removeMsg)}
              ariaQuantityLabel={t.M0W7(quantityMsg)}
              ariaQuantityMinusLabel={t.C2KQ(subtractMsg)}
              ariaQuantityPlusLabel={t.VKFM(addMsg)}
              ariaTotalsLabel={t.CEQ2(totalMsg)}
              checkoutUrl={paths.DEVICE_CHECKOUT}
              i18nCheckoutLabel={t.UAAP(checkoutMsg)}
              i18nContinueLabel={t.VLZR(continueShoppingMsg)}
              i18nEmptyLabel={t.PRFW(cartIsEmptyMsg)}
              onCheckout={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                if (errors && errors.length > 0) {
                  document.getElementById(ERROR_BANNER_ID)?.scrollIntoView();
                } else {
                  if (!loggedIn) {
                    navigate(paths.SHOPPING_BASKET, { state: { loginInProgress: true } });
                  } else {
                    navigate(paths.DEVICE_CHECKOUT);
                  }
                }
              }}
              onCancel={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                // If user came from checkout, navigate back to web shop main page.
                // Otherwise user could be navigated back to checkout in some cases.
                location.state?.from === 'kassa' ? navigate(paths.WEB_SHOP) : navigateBack();
              }}
              /* TODO: It would be good if this element would be optional. */
              i18nHeading="" // Leave DS Shopping Cart heading empty and use own. This results in two H1 elements, which is not a good thing SEO-wise.
              caption={
                totalProductQuantity === 1
                  ? t.I23L(shoppingCartContentSingularMsg)
                  : t.PS1E(shoppingCartContentPluralMsg, `${totalProductQuantity}`)
              }
              items={allRows}
              onPaymentOptionChange={() => {}} // Not used at the moment
              onQuantityChange={() => {}} // Not used at the moment
              totals={calculateTotalPrices(models, discountedPrices, availableBasketItems, basketItemStates)}
              totalProductQuantity={totalProductQuantity}
            />
          </div>
        </div>
      </div>
    </>
  );
};
