import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { omaElisaForCompaniesMsg, orderHistoryMsg, ordersMsg, redeemRequestsMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { ReactNode } from 'react';

import './CustomerOrders.scss';

export enum CustomerOrdersSceneTab {
  CUSTOMER_ORDERS = 'CUSTOMER_ORDERS',
  REDEEM_REQUESTS = 'REDEEM_REQUESTS',
}

interface CustomerOrdersProps {
  children: ReactNode;
  tab: CustomerOrdersSceneTab;
}

const getBreadCrumbs = (tab: CustomerOrdersSceneTab): BreadCrumbList | undefined => {
  switch (tab) {
    case CustomerOrdersSceneTab.CUSTOMER_ORDERS:
      return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.I9C0(ordersMsg) }];
    case CustomerOrdersSceneTab.REDEEM_REQUESTS:
      return [
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.Z1M1(redeemRequestsMsg) },
      ];
    default:
      return;
  }
};

export const CustomerOrders = ({ children, tab }: CustomerOrdersProps) => {
  return (
    <div className="of-customer-order-scene">
      <HeroHeading
        breadCrumbPaths={getBreadCrumbs(tab)}
        heroHeadingType={HeroHeadingType.CUSTOMER_ORDERS}
        title={t.GCO3(orderHistoryMsg)}
      />
      <LocalNavi
        categories={[
          {
            children: t.I9C0(ordersMsg),
            to: paths.CUSTOMER_ORDERS,
          },
          {
            children: t.Z1M1(redeemRequestsMsg),
            to: paths.CUSTOMER_ORDER_REDEEM_REQUESTS,
          },
        ]}
      />
      {children}
    </div>
  );
};
