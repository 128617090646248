import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { OpenFormPageAvailability } from './OpenFormPageAvailability.js';
import { OpenFormPageInformative } from './OpenFormPageInformative.js';
import { OpenFormPageQuestion } from './OpenFormPageQuestion.js';
import type { OpenFormSection } from '../../../../generated/api/openFormSection.js';

export const OpenFormPageType = ({
  section,
  answers,
  disabled,
}: {
  section: OpenFormSection;
  answers: OpenFormAnswers;
  disabled: boolean;
}) => {
  switch (section?.pageType) {
    case 'AVAILABILITY_CHECK':
      return <OpenFormPageAvailability section={section} answers={answers} disabled={disabled} />;
    case 'INFORMATIVE':
      return <OpenFormPageInformative section={section} />;
    case 'ORDER_ENRICHMENT':
    case 'QUESTION_ADDITIONAL_SERVICES':
    case 'QUESTION_MAIN_PRODUCTS':
    case 'QUESTION_SUBSCRIPTION_TYPE':
      return <OpenFormPageQuestion section={section} answers={answers} disabled={disabled} />;
  }
};
