import * as CL from '@design-system/component-library';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../../Loading/index.js';
import {
  PersonalBillingDetails,
  createPersonBillingDetailsData,
} from '../../PersonalBillingDetails/PersonalBillingDetails.js';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { findPersonBillingAccount } from '../../../common/utils/stateUtils.js';
import { loadBillingAccounts } from '../../../selfservice/actions/index.js';
import { nextMsg, t } from '../../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { PersonalBillingDetailsData } from '../../PersonalBillingDetails/PersonalBillingDetails.js';
import type { State } from '../../../selfservice/common/store.js';

type PunchoutCheckoutBillingProps = {
  onClickNext: (values: PersonalBillingDetailsData) => void;
  onSetBillingTerms: (termsAccepted: boolean) => void;
  user: AuthenticatedUserState;
  isBillingTermsAccepted?: boolean;
};

export const PunchoutCheckoutBilling = ({
  onClickNext,
  user,
  isBillingTermsAccepted,
  onSetBillingTerms,
}: PunchoutCheckoutBillingProps) => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const { baLoaded, personBillingAccount, apiErrors } = useSelector(
    (state: State) => ({
      baLoaded: !!state.selfservice?.billingAccounts?.items,
      personBillingAccount: findPersonBillingAccount(state.selfservice?.billingAccounts || undefined),
      apiErrors: state.selfservice?.billingAccounts?.errors,
    }),
    deepEqual
  );

  const personBillingDetailsData = createPersonBillingDetailsData(user, personBillingAccount);

  const methods = useForm<PersonalBillingDetailsData>({
    values: personBillingDetailsData,
  });

  useEffect(() => {
    if (!baLoaded) {
      dispatch(loadBillingAccounts());
    }
  }, [dispatch]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values: PersonalBillingDetailsData) => {
    setIsLoading(true);
    onClickNext(values);
  };
  if (!baLoaded) {
    return <Loading />;
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <PersonalBillingDetails editing={!personBillingAccount} data={personBillingDetailsData} enableEditing />
          <TermsAndConditions
            onAcceptance={() => onSetBillingTerms(!isBillingTermsAccepted)}
            isAccepted={isBillingTermsAccepted}
          />
          {apiErrors?.length && (
            <CL.Disclaimer
              className={`${dsClass.PADDING_6} ${dsClass.MARGIN_BOTTOM_3}`}
              disclaimerType="alert"
              icon={<CL.Icon icon="warning" size="l" aria-hidden="true" />}
              text={t.L7BV('We could not update your changes. Please try again later.')}
            />
          )}
          <CL.Button size="l" className={dsClass.MARGIN_TOP_2} loading={isloading} type="submit">
            {t.F0MY(nextMsg)}
          </CL.Button>
        </form>
      </FormProvider>
    </div>
  );
};
