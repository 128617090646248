import * as CL from '@design-system/component-library';
import { ApproveOrDeny } from '../ApproveOrDeny/ApproveOrDeny.js';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { Grid } from '../Grid/Grid.js';
import { Loading } from '../Loading/index.js';
import { PostReviewRequest, SubscriptionAction } from '../../generated/api/models.js';
import { StickyFooter } from '../StickyFooter/index.js';
import {
  contractEndDateMsg,
  deviceMsg,
  idNumberMsg,
  payerMsg,
  redeemRequestMsg,
  redemptionInvoiceMsg,
  subscriptionRedeemContactNameMsg,
  t,
  userRedemptionPriceMsg,
  vatPercentageMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getPriceWithTax, getVatAsString } from '../../common/utils/taxUtils.js';
import { review, showDialog } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionActionDetails, SubscriptionRedeemReview, SupportCase } from '../../generated/api/models.js';

import './SubscriptionActionDetailsView.scss';

export interface SubscriptionActionDetailsViewProps {
  subscriptionAction?: SubscriptionAction;
  isEmployee?: boolean;
  breadCrumbPaths: BreadCrumbList;
}

interface RedeemRequestInfoFieldProps {
  details?: SubscriptionActionDetails;
}

const RedeemRequestInfoFields = ({ details }: RedeemRequestInfoFieldProps) => (
  <CL.Description
    items={[
      {
        title: t.ZPXZ(subscriptionRedeemContactNameMsg),
        description: details?.subscriptionContactName,
      },
      {
        title: t.TPVQ(deviceMsg),
        description: details?.subscriptionName,
      },
      {
        title: 'IMEI',
        description: details?.eppRedeemTerminate?.serialNumber,
      },
      {
        title: t.YTH3(idNumberMsg),
        description: details?.subscriptionDisplayId,
      },
      {
        title: t.N88P(userRedemptionPriceMsg),
        description: `${formatSum(getPriceWithTax(details?.eppRedeemTerminate?.modificationPrice || 0))}
        (${t.U8AN('incl.')} ${t.YX78(vatPercentageMsg, getVatAsString())})`,
      },
      {
        title: t.PB6S(payerMsg),
        description: `${details?.orderingContact.person?.firstName} ${details?.orderingContact.person?.lastName}`,
      },
      {
        title: t.LHJ4(contractEndDateMsg),
        description: details?.eppRedeemTerminate?.commitmentEndDate
          ? formatTimestampToUTCDDMMYYYY(details.eppRedeemTerminate.commitmentEndDate)
          : undefined,
      },
    ]}
    className={`${dsClass.MARGIN_0} ${dsClass.PADDING_BOTTOM_4}`}
  />
);

export const SubscriptionActionDetailsView = ({
  subscriptionAction,
  breadCrumbPaths,
  isEmployee = false,
}: SubscriptionActionDetailsViewProps) => {
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const pendingSubscriptionActions = useSelector(
    (s: State) => s.selfservice?.pendingSubscriptionActions?.items,
    deepEqual
  );
  const [showFooter, setShowFooter] = useState(false);
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  let subscriptionActionDisplayId = '';
  let subscriptionName = '';
  let content: JSX.Element;
  let footer: JSX.Element | undefined;

  useEffect(() => {
    if (subscriptionAction && !isEmployee) {
      setShowFooter(subscriptionAction.status === SubscriptionAction.StatusEnum.PENDING_APPROVAL);
    }
  }, [isEmployee, subscriptionAction]);

  const { authenticatedUser } = useAuth();
  const companyName = getCompanyName(authenticatedUser, mdmId);
  const userEmail = authenticatedUser?.identityProviderEmail || '';

  if (subscriptionAction) {
    subscriptionActionDisplayId = subscriptionAction.subscriptionActionDisplayId || '…';
    subscriptionName = subscriptionAction.details?.subscriptionName || '…';

    switch (subscriptionAction.subscriptionActionType) {
      case 'EPP_REDEEM_TERMINATE': {
        content = (
          <div className="of-subscription-action-details__content">
            <div className="of-subscription-action-details__content--request-info">
              <h3>{t.NJHG(redeemRequestMsg)}</h3>
              <RedeemRequestInfoFields details={subscriptionAction?.details} />
              <p>
                {isEmployee
                  ? t.JZHA('You will get an invoice to your email {} when redemption request is approved.', userEmail)
                  : t.E54X(redemptionInvoiceMsg)}
              </p>
            </div>
          </div>
        );
        if (showFooter) {
          footer = (
            <StickyFooter active={true}>
              <Grid>
                {/* TODO this logic is almost same as in SubscriptionDevice.tsx and it could be combined to live in ApproveOrDeny */}
                <ApproveOrDeny
                  approveButtonLabel={t.RDZA('Approve redeem request')}
                  denyButtonLabel={t.NC6Y('Reject redeem request')}
                  onApprove={() => {
                    onShowDialog({
                      body: t.AWTH('EPP redeem request'),
                      header: t.KCZ6('Confirm Approve Redeem Request'),
                      onConfirm: () => {
                        dispatch(
                          review(
                            { subscriptionId: subscriptionAction.subscriptionId } as SubscriptionRedeemReview,
                            PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM,
                            true,
                            undefined,
                            undefined,
                            undefined,
                            pendingSubscriptionActions
                          )
                        );
                        setShowFooter(false);
                      },
                      type: DialogType.GENERIC_CONFIRMATION_DIALOG,
                    });
                  }}
                  onDeny={() => {
                    onShowDialog({
                      type: DialogType.EPP_REDEEM_REJECT,
                      onRedeemReject: (rejectReason: SupportCase) => {
                        dispatch(
                          review(
                            { subscriptionId: subscriptionAction.subscriptionId } as SubscriptionRedeemReview,
                            PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM,
                            false,
                            rejectReason.description,
                            undefined,
                            undefined,
                            pendingSubscriptionActions
                          )
                        );
                        setShowFooter(false);
                      },
                    });
                  }}
                />
              </Grid>
            </StickyFooter>
          );
        }
        break;
      }
      default: {
        // erroneous state
        throw Error('Erroneous state');
      }
    }
  } else {
    content = <Loading />;
  }

  return (
    <>
      <DetailsWrapper
        classes={['of-subscription-action-details']}
        detailsTop={<BreadCrumbsWithTitle breadCrumbPaths={breadCrumbPaths} />}
        id={`subscription-action-details-${subscriptionActionDisplayId}`}
        heading={subscriptionName}
        headingBottom={companyName}
        headingTop={t.B951('Redemption Request')}
        heroPicto="phone-filled"
      >
        {content}
      </DetailsWrapper>
      {footer}
    </>
  );
};
