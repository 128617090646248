import * as CL from '@design-system/component-library';
import { ConsolidatedListViewSelector } from '../ConsolidatedListViewSelector/ConsolidatedListViewSelector.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';

export interface ConsolidatedListViewProps {
  authenticatedUser: AuthenticatedUserState | undefined;
  listElement: JSX.Element;
  listPagination?: JSX.Element;
  listSearch: JSX.Element;
  searchFilters?: JSX.Element;
  wideLayout?: boolean;
}

export const ConsolidatedListView = ({
  authenticatedUser,
  listElement,
  listPagination,
  listSearch,
  searchFilters,
  wideLayout,
}: ConsolidatedListViewProps) => {
  return (
    <>
      {isMultiBiz(authenticatedUser) ? (
        <CL.Grid className={dsClass.MARGIN_TOP_3}>
          <CL.GridRow>
            <CL.GridCol
              className={dsClass.PADDING_0}
              colWidthXS={4}
              colWidthS={6}
              colWidthM={3}
              colWidthL={4}
              colWidthXL={wideLayout ? 3 : 4}
            >
              <ConsolidatedListViewSelector authenticatedUser={authenticatedUser} />
            </CL.GridCol>
            <CL.GridCol
              className={dsClass.PADDING_0}
              colWidthXS={4}
              colWidthS={6}
              colWidthM={3}
              colWidthL={7}
              colWidthXL={6}
            >
              {listSearch}
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      ) : (
        listSearch
      )}
      {searchFilters}
      {listElement}
      {listPagination}
    </>
  );
};
